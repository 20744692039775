import React, { useEffect, useRef } from 'react';
import { CustomPicker } from 'react-color';
import { Alpha, Hue, Saturation } from 'react-color/lib/components/common';
import * as saturation from 'react-color/lib/helpers/saturation';
import * as alpha from 'react-color/lib/helpers/alpha';
import * as hue from 'react-color/lib/helpers/hue';
import cx from './color-picker.module.scss';
import { InputFields } from './InputFields';

const Bar = ({ type, hsl }) => {
    return (
        <div
            style={{
                boxShadow: 'rgb(0 0 0 / 60%) 0px 0px 2px',
                width: 8,
                height: 8,
                border: '1px solid #fff',
                borderRadius: 9999,
                background:
                    type === 'saturation'
                        ? 'transparent'
                        : type === 'hue'
                        ? `hsl(${Math.round(hsl['h'])},100%,50%)`
                        : `hsla(${Math.round(hsl['h'])},100%,50%,${hsl['a']})`,
            }}
        />
    );
};

const BarSaturation = props => {
    return <Bar type="saturation" {...props} />;
};

const BarHue = props => {
    return <Bar type="hue" {...props} />;
};

const BarAlpha = props => {
    return <Bar type="alpha" {...props} />;
};

const CustomColorPicker = ({ hex, hsl, hsv, rgb, onChange }) => {
    const saturationRef = useRef(null);
    const hueRef = useRef(null);
    const alphaRef = useRef(null);

    const onInputsChange = data => {
        onChange(data);
    };

    const onSaturationChange = newColor => {
        onChange(newColor);
    };

    const onHueChange = newHue => {
        onChange(newHue);
    };

    const onAlphaChange = newAlpha => {
        onChange(newAlpha);
    };

    useEffect(() => {
        if (saturationRef.current) {
            saturationRef.current.handleChange = e => {
                e.preventDefault();
                e.stopPropagation();

                typeof saturationRef.current.props.onChange === 'function' &&
                    saturationRef.current.throttle(
                        saturationRef.current.props.onChange,
                        saturation.calculateChange(e, saturationRef.current.props.hsl, saturationRef.current.container),
                        e
                    );
            };
            alphaRef.current.handleChange = e => {
                e.preventDefault();
                e.stopPropagation();

                const change = alpha.calculateChange(
                    e,
                    alphaRef.current.props.hsl,
                    alphaRef.current.props.direction,
                    alphaRef.current.props.a,
                    alphaRef.current.container
                );
                change && typeof alphaRef.current.props.onChange === 'function' && alphaRef.current.props.onChange(change, e);
            };
        }

        hueRef.current.handleChange = e => {
            e.preventDefault();
            e.stopPropagation();

            const change = hue.calculateChange(e, hueRef.current.props.direction, hueRef.current.props.hsl, hueRef.current.container);
            change && typeof hueRef.current.props.onChange === 'function' && hueRef.current.props.onChange(change, e);
        };
        return () => {
            saturationRef.current = null;
            hueRef.current = null;
            alphaRef.current = null;
        };
    }, []);

    return (
        <div className={cx.colorPicker} style={{ width: '100%' }}>
            <div>
                <div className={cx.saturation}>
                    <Saturation
                        hsl={hsl}
                        hsv={hsv}
                        style={{ pointer: { cursor: 'pointer' } }}
                        pointer={BarSaturation}
                        onChange={onSaturationChange}
                        ref={saturationRef}
                    />
                </div>
                <div className={cx.hue}>
                    <Hue ref={hueRef} hsl={hsl} width="auto" height={10} pointer={BarHue} onChange={onHueChange} />
                </div>
                <div className={cx.alpha}>
                    <Alpha ref={alphaRef} rgb={rgb} hsl={hsl} pointer={BarAlpha} onChange={onAlphaChange} />
                </div>
            </div>
            <InputFields hsl={hsl} rgb={rgb} hex={hex} onChange={onInputsChange} />
        </div>
    );
};

export const ColorPicker = CustomPicker(CustomColorPicker);
