import React, { useState, useMemo, useEffect } from 'react';
import { Link } from 'shared/router';
import { ReportsComponent } from 'components/data-components/reports-component';
import { articleSearchBy, newsSearchBy, objectTypes, sortTypesOptions } from 'app/statistic/vendors/filtersOptions';
import { ReportService } from 'app/statistic/services/ReportService';
import { useMessage } from 'lib/hooks';
import { humanFileSize } from 'lib/helpers';
import cx from './statistic-reports-page.module.scss';
import api from 'api';

const StatisticReportsFilesReports = ({ setIsDownloadModal }) => {
    const { addError } = useMessage();

    const [isLoading, setIsLoading] = useState(false);

    const [reports, setReports] = useState([]);
    const [reportsTotal, setReportsTotal] = useState(0);
    const [sortByOptions, setSortByOptions] = useState([]);
    const [filters, setFilters] = useState({});

    const getArticlesInProjectsReports = async (page = 0, size = 50, filters = {}) => {
        setIsLoading(true);

        try {
            const res = await api.reports.getAttachments({ page, size, filters, cancelToken: ReportService.cancelToken.token });

            setReports(
                res.attachments.content.map((i) => {
                    return {
                        ...i,
                        fileName: i.name,
                        titlePath: i.projectPath,
                        size: humanFileSize(i.size, true),
                        login: i.author,
                        attachDate: i.createDate,
                        createDate: i.createDate,
                    };
                })
            );
            setReportsTotal(res.attachments.totalElements);

            if (!res.attachments.totalElements) {
                addError('По вашему запросу нет данных');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const onDownload = async (filters) => {
        try {
            await api.reports.downloadReports('attachments', filters, ReportService.cancelToken.token);
            setIsDownloadModal(true);
        } catch (error) {
            addError('Произошла ошибка при скачивании отчета');
        }
    };

    const onFiltersChange = (newFilters) => {
        setFilters((prevState) => {
            if (prevState['document-type-select'] && prevState['document-type-select'].value !== newFilters['document-type-select'].value) {
                setReports([]);
                setReportsTotal(0);
            }

            return newFilters;
        });
    };

    const columnsTmp = useMemo(() => {
        const data = [
            {
                Header: 'Проект',
                accessor: 'projectTitle',
                isVisible: filters['document-type-select']?.value === 'NEWS',
            },
            {
                Header: 'Новость',
                accessor: 'documentTitle',
                isVisible: filters['document-type-select']?.value === 'NEWS',
            },
            {
                Header: 'Документ',
                accessor: 'projectPath',
                isVisible: filters['document-type-select']?.value === 'ARTICLE',
            },
            {
                Header: 'Название файла',
                accessor: 'fileName',
            },
            {
                Header: 'Размер файла',
                accessor: 'size',
            },
            {
                Header: 'Тип файла',
                accessor: 'type',
            },
            {
                Header: 'Файл прикреплен',
                accessor: 'attachDate',
                Cell: (data) => ReportService.defaultDateColumn('attachDate')(data),
            },
            {
                Header: 'Автор файла',
                accessor: 'login',
                Cell: (data) => {
                    return data.row.original.activeAuthor ? (
                        <Link className={cx.link} to={'/edit-user/' + data.row.original.login}>
                            {data.row.original.author}
                        </Link>
                    ) : (
                        data.row.original.author
                    );
                },
            },
        ];

        setSortByOptions((prevState) => {
            const newState = data
                .filter((col) => col.accessor && col.isVisible !== false)
                .map((column) => ({ label: column.Header, value: column.accessor }));

            if (JSON.stringify(prevState) !== JSON.stringify(newState)) {
                setFilters((prevState) => ({ ...prevState, sortBy: newState.find(col => col.value === 'attachDate') }));
            }

            return newState;
        });
        return data;
    }, [filters]);

    const filtersTmp = useMemo(() => {
        return [
            {
                fields: [
                    {
                        labels: ['Дата создания файла', 'От', 'До'],
                        name: 'createTime',
                        type: 'date-range',
                        default: filters['createTime'] || {
                            type: {
                                label: 'За сегодня',
                                value: 1,
                            },
                            from: new Date(new Date().setHours(0, 0, 0)).toISOString(),
                            to: new Date(new Date().setHours(23, 59, 59)).toISOString()
                        },
                    },
                ],
                width: '100%',
            },
            {
                label: 'Тип документа',
                fields: [
                    {
                        name: 'document-type-select',
                        type: 'document-type-select',
                        options: objectTypes,
                        default: filters['document-type-select'] || objectTypes[0],
                    },
                ],
                width: '100%',
            },
            {
                label: '',
                fields: [
                    {
                        name: 'articles-group',
                        type: 'articles',
                        options: articleSearchBy,
                        default: filters['articles-group'] || articleSearchBy[0],
                    },
                ],
                width: '100%',
            },
            {
                label: '',
                fields: [
                    {
                        name: 'news-group',
                        type: 'news',
                        options: newsSearchBy,
                        default: filters['news-group'] || newsSearchBy[1],
                    },
                ],
                width: '100%',
            },
            {
                label: 'Автор файла',
                fields: [
                    {
                        name: 'authors',
                        type: 'user-select',
                        default: filters['authors'] || null,
                    },
                ],
                width: '100%',
            },
            {
                label: 'Тип файла',
                fields: [
                    {
                        name: 'type',
                        type: 'select',
                        options: ReportService.getFileTypes(),
                        default: filters['type'] || { label: 'Любой', value: null },
                        isMulti: true,
                    },
                ],
                width: '50%',
            },
            {
                label: '',
                fields: [
                    {
                        label: ['Размер (от)', 'Размер (до)'],
                        name: 'size',
                        type: 'range',
                        default: filters['size'] || {
                            min: 0,
                            max: 40000000000,
                            from: 0,
                            to: 40000000000,
                            type: {
                                label: 'Кб',
                                value: 0,
                            },
                        },
                        options: [
                            {
                                label: 'Кб',
                                value: 0,
                            },
                            {
                                label: 'Мб',
                                value: 1,
                            },
                            {
                                label: 'Гб',
                                value: 2,
                            },
                        ],
                    },
                ],
                width: '50%',
            },
            {
                label: 'Сортировать по',
                fields: [
                    {
                        name: 'sortBy',
                        type: 'select',
                        options: sortByOptions,
                        default: filters['sortBy'] || { label: 'Дата создания', value: 'createDate' },
                    },
                ],
                width: '25%',
            },
            {
                label: 'Порядок',
                fields: [
                    {
                        name: 'sort',
                        type: 'select',
                        options: sortTypesOptions,
                        default: filters['sort'] || sortTypesOptions[0],
                    },
                ],
                width: '25%',
            },
        ];
    }, [sortByOptions, filters]);

    useEffect(() => {
        ReportService.init('FILES_REPORTS');

        return () => {
            ReportService.destroy();
        };
    }, []);

    return (
        <ReportsComponent
            isEmpty={!reportsTotal}
            isLoading={isLoading}
            columns={columnsTmp}
            data={reports}
            total={reportsTotal}
            filters={filtersTmp}
            onDownload={onDownload}
            onPaginate={getArticlesInProjectsReports}
            onChangeFilters={onFiltersChange}
        />
    );
};

export default StatisticReportsFilesReports;
