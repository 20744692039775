import { useMemo } from 'react';

export const useCustomStyle = (error, disabled, style = {}) => useMemo(() => ({
    indicatorSeparator: (provided, state) => {
        const _indicatorSeparator = style.indicatorSeparator ? style.indicatorSeparator(state) : {};
        return {
            display: 'none',
            ..._indicatorSeparator
        }
    },
    control: (provided, state) => {
        const _control = style.control ? style.control(state, disabled) : {}
        return {
            ...provided,
            borderRadius: 5,
            minHeight: 40,
            height: 40,
            background: disabled ? '#FBFBFD' : '#ffffff',
            border: error ? '1px solid var(--color-red)' : '1px solid var(--color-input-border)',
            borderColor: state?.isFocused ? 'var(--color-input-border-focus)' : 'var(--color-input-border)',
            boxShadow: state?.isFocused ? '0 1px 3px rgba(0, 0, 0, 0.3)' : 'none',
            ':hover': {
                borderColor: error ? 'transparent' : 'var(--color-input-border-hover)',
                backgroundColor: 'var(--color-input-bg-hover)',
                'boxShadow': '0 1px 3px rgba(0, 0, 0, 0.3)',
                cursor: 'pointer'
            },
            ..._control
        };
    },
    menu: (provided, state) => {
        const _menu = style.menu ? style.menu(state, disabled) : {};
        return {
            ...provided,
            border: '1px solid #b3b3b3',
            boxShadow: 'none',
            borderRadius: '5px',
            margin: '5px 0 0',
            paddingTop: 5,
            paddingBottom: 5,
            zIndex: 106,
            width: style['menuWidth'] ? style['menuWidth'] : '100%',
            ..._menu
        }
    },
    menuPortal: (provided, state) => {
        const _menuPortal = style.menuPortal ? style.menuPortal(state, disabled) : {};

        return {
            ...provided,
            ..._menuPortal
        }
    },
    placeholder: (provided, state) => {
        const _placeholder = style.placeholder ? style.placeholder(state, disabled) : {};
        return {
            color: '#262626',
            opacity: 0.5,
            ..._placeholder
        }
    },
    indicatorsContainer: (provided, state) => {
        const _indicatorsContainer = style.indicatorsContainer ? style.indicatorsContainer(state, disabled) : {};
        return {
            display: 'flex',
            alignItems: 'center',
            ..._indicatorsContainer
        }
    },
    dropdownIndicator: (provided, state) => {
        const _dropdownIndicator = style.dropdownIndicator ? style.dropdownIndicator(state, disabled) : {};
        return {
            display: disabled ? 'none' : 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            padding: 8,
            color: error ? '#EC393D' : disabled ? 'rgba(51, 51, 51, 0.35)' : '#7B8F9C',
            marginLeft: '6px',
            ..._dropdownIndicator
        }
    },
    multiValue: (provided, state) => {
        const _multiValue = style.multiValue ? style.multiValue(state, disabled) : {};
        return {
            ...provided,
            padding: '6px',
            alignItems: 'center',
            backgroundColor: '#F5F5F5',
            borderRadius: '9999px',
            ..._multiValue
        }
    },
    multiValueLabel: (provided, state) => {
        const _multiValueLabel = style.multiValueLabel ? style.multiValueLabel(state, disabled) : {};
        return {
            ...provided,
            padding: '0 6px 0 0',
            ..._multiValueLabel
        }
    },
    multiValueRemove: (provided, state) => {
        const _multiValueRemove = style.multiValueRemove ? style.multiValueRemove(state, disabled) : {};
        return {
            ...provided,
            height: '80%',
            width: 'auto',
            padding: 0,
            borderRadius: '9999px',
            color: '#fff',
            backgroundColor: '#1280CE',
            ..._multiValueRemove
        }
    },
    Option: (provided, state) => {
        const _Option = style.Option ? style.Option(state, disabled) : {};
        return {
            ...provided,
            fontWeight: 400,
            _Option
        }
    },
    valueContainer: (provided, state) => {
        const _valueContainer = style.valueContainer ? style.valueContainer(state, disabled) : {};
        return {
            ...provided,
            ..._valueContainer
        }
    },
    singleValue: (provided, state) => {
        const _singleValue = style.singleValue ? style.singleValue(state, disabled) : {};
        return {
            ...provided,
            overflow: 'visible',
            paddingTop: 1,
            paddingBottom: 1,
            ..._singleValue
        }
    }
}), [error, disabled, style]);
