import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { FILES_TYPE_ICONS, FILES_TYPE_SHORT_NAME } from 'lib/config/constant';
import { humanFileSize } from 'lib/helpers';
import Button from 'uikit/button/button';
import IconButton from 'uikit/icon-button';
import Icon, { Icons } from 'uikit/icon';
import Checkbox from 'uikit/checkbox';
import Loading from 'uikit/loading';
import { EmptyFiles } from 'components/projects/empty';
import cx from './uploadModal.module.scss';
import api from 'api';
import { useGlobalContext } from 'lib/hooks';
import Dropzone from 'react-dropzone';

const UploadModal = ({ selectedFiles = [], onSubmitHandler, onCloseHandler, onAttachHandler }) => {
    const { platform } = useGlobalContext();

    const [tab, setTab] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const [attachedFiles, setAttachedFile] = useState([]);
    const [files, setFiles] = useState([]);

    const [filteredFiles, setFilteredFiles] = useState([]);
    const [searchText, setSearchText] = React.useState('');

    const fetchFiles = async (type = 'user') => {
        try {
            let res;

            if (type === 'user') {
                res = await api.file.getNotAttachedFileList({
                    page: 0,
                    size: 2000,
                    sort: 'modifyTime,desc',
                });
            } else if (type === 'all') {
                res = await api.file.getAttachedFileList({
                    page: 0,
                    size: 2000,
                    sort: 'modifyTime,desc',
                });
            }

            setFiles(res.content);
            setFilteredFiles(res.content);
        } catch (e) {}
    };

    const onSearch = (e) => {
        setSearchText(e);
        setFilteredFiles(files.filter((i) => String(i.name).toLowerCase().indexOf(String(e).toLowerCase()) !== -1));
    };

    const onTabChangeHandler = useCallback(async () => {
        setFiles([]);
        setIsLoading(true);

        await fetchFiles(tab === 0 ? 'user' : 'all');
        setIsLoading(false);
    }, [tab]);

    const addAttachedFiles = (file) => {
        const _files = [...attachedFiles];
        const idx = _files.findIndex((i) => i.fileId === file.fileId);

        if (idx !== -1) {
            setAttachedFile(_files.filter((i) => i.fileId !== file.fileId));
        } else {
            _files.push(file);
            setAttachedFile(_files);
        }
    };

    const submitAttachedFiles = () => {
        onAttachHandler(attachedFiles);
        onCloseHandler();
    };

    const checkIsFileSelected = (fileId) => {
        return attachedFiles.some((file) => file.fileId === fileId);
    };

    useEffect(() => {
        fetchFiles();

        if (selectedFiles) {
            setAttachedFile(selectedFiles.filter((file) => file.status === 'done').map((file) => file.serverData));
        }
    }, [selectedFiles]);

    useEffect(() => {
        onTabChangeHandler();
    }, [onTabChangeHandler]);

    const onDrop = (files) => {
        onSubmitHandler({ target: { files: files } });
    };

    return (
        <div id={'uploadModalContainer'} className={cx.overlay}>
            <div className={classNames(cx.content, { [cx.mobile]: platform === 'mobile' })} data-testid="uploadModal">
                <div className={cx.modalHeader}>
                    <h3 data-testid="uploadModalTitle">Прикрепить файл</h3>
                    <IconButton icon={<Icon type={Icons.CROSS} width={16} height={16} />} onClick={onCloseHandler} id="uploadModalCrestBtn" />
                </div>
                <div className={cx.uploadSection}>
                    <Dropzone onDrop={onDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ className: cx.dropZone })} data-testid="uploadModalDropzone">
                                <input id="uploadModalImageInput" {...getInputProps()} multiple />
                                <label className={cx.uploadLabel}>
                                    {/*<input id={'uploadModalImageInput'} type="file" onChange={onSubmitHandler} multiple/>*/}
                                    <div>
                                        <Icon type={Icons.DOWNLOAD} width={18} height={18} />
                                    </div>
                                    <div className={cx.uploadDesc}>
                                        Перетащите файл в эту область или нажмите для выбора файла с компьютера
                                    </div>
                                </label>
                            </div>
                        )}
                    </Dropzone>
                    <div className={cx.tabs} data-testid="uploadModalTabs">
                        <div className={classNames(cx.tab, { [cx.tabSelected]: tab === 0 })} onClick={() => setTab(0)}>
                            Только мои файлы
                        </div>
                        <div className={classNames(cx.tab, { [cx.tabSelected]: tab === 1 })} onClick={() => setTab(1)}>
                            Все файлы
                        </div>
                    </div>
                </div>
                <div className={cx.modalBody}>
                    {isLoading && (
                        <div className={cx.loader}>
                            <Loading withOverlay={false} />
                        </div>
                    )}
                    {!isLoading && files.length >= 1 && (
                        <>
                            <div className={cx.search}>
                                <div className={cx.searchButton} onClick={() => onSearch(searchText)}>
                                    <IconButton icon={<Icon type={Icons.SEARCH} width={13} height={13} />} />
                                </div>
                                <input
                                    type="text"
                                    placeholder="Поиск по названию"
                                    value={searchText}
                                    onChange={(e) => {
                                        onSearch(e.target.value);
                                    }}
                                    data-testid="uploadModalSearch"
                                />
                            </div>
                            <div data-testid="uploadModalFilesWrapper">
                                {filteredFiles.map((file) => {
                                    return (
                                        <div key={file.fileId} className={cx.fileWrapper}>
                                            <div>
                                                <Checkbox
                                                    type="checkbox"
                                                    checked={checkIsFileSelected(file.fileId)}
                                                    onChange={() => addAttachedFiles(file)}
                                                />
                                            </div>
                                            <div className={cx.nameCell}>
                                                <div className={cx.iconWrapper}>
                                                    <Icon type={FILES_TYPE_ICONS[file.type] || Icons.NONE_FILE} width={24} height={24} />
                                                </div>
                                                <div>
                                                    <div className={cx.fileName} onClick={() => {}}>
                                                        {file.name}
                                                    </div>
                                                    <div className={cx.fileDescription}>
                                                        <span className={cx.fileType}>{FILES_TYPE_SHORT_NAME[file.type]}</span> —{' '}
                                                        {humanFileSize(file.size, true)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )}
                    {!isLoading && files.length <= 0 && (
                        <EmptyFiles />
                    )}
                </div>
                <div className={cx.modalFooter}>
                    <Button label="Отменить" onClick={onCloseHandler} fullWidth={platform === 'mobile'} data-testid="uploadModalCancelBtn" />
                    <Button color="green" label="Подтвердить" onClick={submitAttachedFiles} fullWidth={platform === 'mobile'} data-testid="uploadModalOkBtn" />
                </div>
            </div>
        </div>
    );
};

export default UploadModal;
