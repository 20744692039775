import React, { useState, useRef, useEffect } from 'react';
import Tippy from '@tippyjs/react';
import useResizeObserver from '@react-hook/resize-observer';
import classNames from 'classnames';
import cx from './MultiClumpTooltip.module.scss';

// eslint-disable-next-line no-unused-vars
export const MultiClumpTooltip = ({
    id,
    className,
    contentClassName = '',
    label = '',
    _ellipsis = '...',
    clamp = 2,
    isContextResize = true,
}) => {
    const textRef = useRef(null);
    const [isTooltip, setIsTooltip] = useState(false);

    const isTextClamped = el => {
        if (!el) return false;

        const range = document.createRange();
        range.setStart(el, 0);
        range.setEnd(el, el.childNodes.length);

        return (range.getBoundingClientRect().height - 4) > el.clientHeight;
    };

    useResizeObserver(isContextResize ? textRef?.current : null, entry => {
        setIsTooltip(isTextClamped(entry.target));
    });

    useEffect(() => {
        if (!isContextResize && textRef?.current) {
            setIsTooltip(isTextClamped(textRef.current));
        }
    }, [isContextResize]);

    useEffect(() => {
        setIsTooltip(isTextClamped(textRef.current));
    }, []);

    return (
        <Tippy content={label} disabled={!isTooltip}>
            <div className={classNames(className)}>
                <div
                    ref={textRef}
                    id={id}
                    data-testid={id}
                    className={classNames(cx[`MultiClumpTooltipClamp`], cx[`clamp--${clamp}`], contentClassName)}
                >
                    {label}
                </div>
            </div>
        </Tippy>
    );
};
