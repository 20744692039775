import React, { useCallback } from 'react';
import { findParentNodeClosestToPos } from '@tiptap/core';
import { MenuButton } from '../Menu/MenuButton';
import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';
import cx from '../../editor.module.scss';
import { BubbleExtensions, isBubbleExtensionActive } from '../../utils/isBubbleExtensionActive';
import { isEmpty } from 'lodash';

export const BubbleMenuTable = ({ isActive, setCurrentMenu }) => {
    const editor = useEditorContext();
    const selection = editor?.state.selection;
    const view = editor?.view;

    const getItemPosition = useCallback(() => {
        if (!isActive) {
            return {};
        }

        let nearestParent = selection?.$anchor;

        if (selection?.node?.type.name !== 'table') {
            nearestParent = findParentNodeClosestToPos(nearestParent, node => {
                return node.type.name === 'table';
            });
        } else {
            nearestParent = {pos: selection.$from.pos}
        }

        if (!nearestParent) {
            return {};
        }

        const wrapperDomNode = view?.nodeDOM(nearestParent.pos);

        if (!wrapperDomNode) {
            return {};
        }

        const editorBoundingClientRect = document.getElementById('editor-content')?.getBoundingClientRect();
        const table = wrapperDomNode.querySelector('table')?.getBoundingClientRect();
        const tableWrapper = wrapperDomNode.querySelector('.tableOuterWrapper')?.getBoundingClientRect();

        if (!editorBoundingClientRect || !table || !tableWrapper) {
            return;
        }

        const width = table.width > tableWrapper.width ? tableWrapper.width : table.width;
        const height = table.height > tableWrapper.height ? tableWrapper.height : table.height;

        return {
            position: 'absolute',
            top: table.top - editorBoundingClientRect.top + height + 30 + 'px',
            left: table.left - editorBoundingClientRect.left + width / 2 - 16 + 'px',
        };
    }, [isActive, selection, view]);

    return (
        <div
            className={cx.bubbleMenu}
            style={{
                display: !isEmpty(getItemPosition()) && isBubbleExtensionActive(editor?.state.selection, BubbleExtensions.TABLE) ? 'flex' : 'none',
                boxShadow: '0 8px 20px rgba(0, 0, 0, 0.12)',
                ...getItemPosition(),
            }}
        >
            <MenuButton
                className={cx.bubbleMenuButton}
                icon={Icons.EditorIconTrash}
                onClick={() => {
                    setCurrentMenu(undefined);
                    editor.commands.deleteNode('table');
                }}
            />
        </div>
    );
};
