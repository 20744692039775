import { TextSelection } from '@tiptap/pm/state';
import { CellSelection } from '../Table/cellSelection';

export function formatLinkName(name, state) {
    let tr = state.tr;
    const selection = state.selection;
    let to = selection.from + name.length;

    if (selection instanceof CellSelection) {
        to = selection.from + 3 + name.length;
    }

    let substringFrom = 0,
        substringTo;

    state.doc.nodesBetween(selection.from, selection.to, (node, start) => {
        if (node.type.name !== 'text') {
            return;
        }

        if (selection.from + name.length < start) {
            return;
        }

        substringTo =
            substringFrom +
            (selection.from >= start
                ? start + node.nodeSize - selection.from
                : selection.to < start + node.nodeSize
                ? selection.to + 1 - node.nodeSize
                : node.nodeSize);

        const str = name.substring(substringFrom, substringTo > selection.to ? selection.to - 1 : substringTo);

        if (selection.from + name.length > start) {
            tr.setSelection(
                TextSelection.create(
                    tr.doc,
                    selection.from > start ? selection.from : start,
                    start + node.nodeSize > selection.to ? selection.to : start + node.nodeSize
                )
            );

            tr.replaceSelectionWith(state.schema.text(str), true);

            substringFrom += selection.from > start ? start + node.nodeSize - selection.from : node.nodeSize;
        }
    });

    if (selection.from + name.length < selection.to) {
        tr.delete(selection.from + name.length, tr.mapping.map(selection.to));
    }

    if (selection.from + name.length > selection.to) {
        tr.setSelection(TextSelection.create(tr.doc, selection.to, selection.to));
        tr.insertText(name.substring(substringTo));
    }

    tr.setSelection(TextSelection.create(tr.doc, selection.from, to));

    return tr;
}
