// eslint-disable-next-line no-useless-escape
export const YOUTUBE_REGEX = /^(https?:\/\/)?(www\.|music\.)?(youtube\.com|youtu\.be)\/(?!channel\/)(?!@)(.+)?$/;
// eslint-disable-next-line no-useless-escape
export const YOUTUBE_REGEX_GLOBAL = /^(https?:\/\/)?(www\.|music\.)?(youtube\.com|youtu\.be)\/(?!channel\/)(?!@)(.+)?$/g;

// eslint-disable-next-line no-useless-escape
export const VIMEO_REGEX_GLOBAL = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(?<id>\d+)(?:|\/\?)/g;
export const VKVIDEO_REGEX_GLOBAL = /^(?:(?:http(?:s?):\/\/)?(?:www\.)?vk\.com\/video)(?:\?z=video)?(-?\d{5,}_\d{5,})/g;
export const RUTUBE_REGEX_GLOBAL = [
    // eslint-disable-next-line no-useless-escape
    /^(?:(?:http(?:s?):\/\/)?(?:www\.)?rutube\.ru\/(play|video)\/(embed\/)?)(?<id>[a-zA-Z0-9_\-]+)/gi,
    // eslint-disable-next-line no-useless-escape
    /^(?:(?:http(?:s?):\/\/)?(?:www\.)?rutube\.ru\/tracks\/)(?<id>[a-zA-Z0-9_\-]+)(&.+)?/gi,
];

export const isValidYoutubeUrl = url => {
    return url?.match(YOUTUBE_REGEX);
};

export const isValidVimeoUrl = url => {
    return url?.match(VIMEO_REGEX_GLOBAL);
};

export const isValidVKUrl = url => {
    return url?.match(VKVIDEO_REGEX_GLOBAL);
};

export const isValidRutubeUrl = url => {
    return RUTUBE_REGEX_GLOBAL.some(regexp => {
        return url?.match(regexp);
    });
};

export const getYoutubeEmbedUrl = nocookie => {
    return nocookie ? 'https://www.youtube-nocookie.com/embed/' : 'https://www.youtube.com/embed/';
};

// https://vk.com/video?z=video-36507793_456243287%2Fpl_cat_trends
// https://vk.com/video_ext.php?oid=-22822305&id=456241864&hd=2
export const getVKEmbedUrl = url => {
    if (!isValidVKUrl(url)) {
        return null;
    }

    const matches = VKVIDEO_REGEX_GLOBAL.exec(url);

    if (!matches[0] && !matches[1]) {
        return null;
    }

    const ids = matches[1].split('_');

    return `https://vk.com/video_ext.php?oid=${ids[0]}&id=${ids[1]}&hd=2`;
};

// https://rutube.ru/video/b8f351b2136491599974fa29b5e7e9f3
// https://rutube.ru/play/embed/b8f351b2136491599974fa29b5e7e9f3
// allow="clipboard-write; autoplay"
// webkitAllowFullScreen
// mozallowfullscreen
// allowFullScreen
export const getRutubeEmbedUrl = url => {
    if (!isValidRutubeUrl(url)) {
        return null;
    }

    let id;

    for (let i = 0; i < RUTUBE_REGEX_GLOBAL.length; i++) {
        if (url.match(RUTUBE_REGEX_GLOBAL[i])) {
            const match = RUTUBE_REGEX_GLOBAL[i].exec(url);

            id = match.groups?.id;
            break;
        }
    }

    return 'https://rutube.ru/play/embed/' + id;
};

// https://vimeo.com/920314562
// https://player.vimeo.com/video/920314562?h=dea289fff3
// allow="autoplay; fullscreen; picture-in-picture"
// allowfullscreen
export const getVimeoEmbedUrl = url => {
    if (!isValidVimeoUrl(url)) {
        return null;
    }

    const match = VIMEO_REGEX_GLOBAL.exec(url);

    const id = match.groups.id;

    return 'https://player.vimeo.com/video/' + id;
};

export const getEmbedUrlFromYoutubeUrl = options => {
    const {
        url,
        allowFullscreen,
        autoplay,
        ccLanguage,
        ccLoadPolicy,
        controls,
        disableKBcontrols,
        enableIFrameApi,
        endTime,
        interfaceLanguage,
        ivLoadPolicy,
        loop,
        modestBranding,
        nocookie,
        origin,
        playlist,
        progressBarColor,
        startAt,
    } = options;

    if (!isValidYoutubeUrl(url)) {
        return null;
    }

    // if is already an embed url, return it
    if (url.includes('/embed/')) {
        return url;
    }

    // if is a youtu.be url, get the id after the /
    if (url.includes('youtu.be')) {
        const id = url.split('/').pop();

        if (!id) {
            return null;
        }
        return `${getYoutubeEmbedUrl(nocookie)}${id}`;
    }

    const videoIdRegex = /(?:v=|shorts\/)([-\w]+)/gm;
    const matches = videoIdRegex.exec(url);

    if (!matches || !matches[1]) {
        return null;
    }

    let outputUrl = `${getYoutubeEmbedUrl(nocookie)}${matches[1]}`;

    const params = [];

    if (allowFullscreen === false) {
        params.push('fs=0');
    }

    if (autoplay) {
        params.push('autoplay=1');
    }

    if (ccLanguage) {
        params.push(`cc_lang_pref=${ccLanguage}`);
    }

    if (ccLoadPolicy) {
        params.push('cc_load_policy=1');
    }

    if (!controls) {
        params.push('controls=0');
    }

    if (disableKBcontrols) {
        params.push('disablekb=1');
    }

    if (enableIFrameApi) {
        params.push('enablejsapi=1');
    }

    if (endTime) {
        params.push(`end=${endTime}`);
    }

    if (interfaceLanguage) {
        params.push(`hl=${interfaceLanguage}`);
    }

    if (ivLoadPolicy) {
        params.push(`iv_load_policy=${ivLoadPolicy}`);
    }

    if (loop) {
        params.push('loop=1');
    }

    if (modestBranding) {
        params.push('modestbranding=1');
    }

    if (origin) {
        params.push(`origin=${origin}`);
    }

    if (playlist) {
        params.push(`playlist=${playlist}`);
    }

    if (startAt) {
        params.push(`start=${startAt}`);
    }

    if (progressBarColor) {
        params.push(`color=${progressBarColor}`);
    }

    if (params.length) {
        outputUrl += `?${params.join('&')}`;
    }

    return outputUrl;
};
