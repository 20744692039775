import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import { Link } from 'shared/router';
import { useSelector } from 'react-redux';
import { useEventListener } from 'lib/hooks';
import { useNotifications } from 'notifications/appllication/context';
import { selectNewsPicker } from 'slice/newsPickerSlice';
import { selectMenuButtons, selectUser } from 'slice/authSlice';
import buttons from 'model/global-menu/buttons';
import { MobileMenuContext } from 'containers/menu-wrapper/menu-wrapper';
import Search from 'components/search';
import Modal from 'notifications/view/modal';
import Icon, { Icons } from 'uikit/icon';
import IconButton, { IconButtonStatus } from 'uikit/icon-button';
import cx from './header.module.scss';
import api from 'api/index';

function Header({ platform, isTicker }) {
    const { toggleMenu } = useContext(MobileMenuContext);
    const { notificationsCount } = useNotifications();

    const userData = useSelector(selectUser) ?? {};
    const menuButtons = useSelector(selectMenuButtons);
    const newsPicker = useSelector(selectNewsPicker);

    const [isNotificationsWindow, setIsNotificationsWindow] = useState(false);

    const getLinkToSettings = () => {
        if (menuButtons[buttons.SETTINGS_COMPANY]) {
            return '/global-settings/companies';
        } else if (menuButtons[buttons.SETTINGS_SUPERVISORS]) {
            return '/global-settings/managers';
        } else if (menuButtons[buttons.SETTINGS_POSITIONS]) {
            return '/global-settings/positions';
        } else if (menuButtons[buttons.SETTINGS_ROLES]) {
            return '/global-settings/custom-roles';
        } else if (menuButtons[buttons.SETTINGS_INTEGRATION]) {
            return '/global-settings/integrations';
        }

        return '/global-settings/about';
    };

    const onNotificationButtonClick = () => {
        setIsNotificationsWindow(!isNotificationsWindow);
    };

    useEventListener('click', (e) => {
        if (isNotificationsWindow && !e.composedPath()?.find?.((item) => item.id === 'notifButtonContainer')) {
            setIsNotificationsWindow(false);
        }
    });

    if (platform === 'mobile') {
        return (
            <>
                {isNotificationsWindow && (
                    <Modal
                        isMobile={true}
                        onClose={() => setIsNotificationsWindow(false)}
                    />
                )}
                <div className={classNames(cx.mobileContentWrapper, { [cx.tickerMobile]: newsPicker.count > 0 })}>
                    <div className={cx.menuButton} onClick={() => toggleMenu()}>
                        <Icon type={Icons.SANDWICH} width={20} height={14} />
                    </div>
                    <div className={cx.head}>
                        <div className={cx.search}>
                            <Search title="Поиск..." isTicker={isTicker} />
                        </div>
                        <div id="notifButtonContainer" className={cx.notifBtn} onClick={onNotificationButtonClick}>
                            <IconButton
                                buttonId="notifButton"
                                icon={<Icon type={Icons.ALARM} tooltip="Уведомления" />}
                                count={notificationsCount}
                                status={notificationsCount !== 0 ? IconButtonStatus.RED : IconButtonStatus.NONE}
                            />
                        </div>
                        <Link className={cx.userInfo} to={'/user/profile'}>
                            <img
                                id="menu-user-avatar"
                                src={userData['avatarUuid'] ? api.upload.getImage(userData['avatarUuid'], false, 128) : '/img/avatar.png'}
                                alt=""
                            />
                        </Link>
                    </div>
                </div>
            </>
        );
    }

    if (platform === 'desktop') {
        return (
            <div id="head" className={cx.desktopHead}>
                <div className={cx.search}>
                    <Search />
                </div>
                <ul>
                    <li className={cx.separator} />
                    {userData['enabledNotificationTypes'].length !== 0 && (
                        <li id="notifButtonContainer" onClick={onNotificationButtonClick}>
                            <IconButton
                                buttonId="notifButton"
                                icon={<Icon type={Icons.ALARM} tooltip="Уведомления" />}
                                count={notificationsCount}
                                status={notificationsCount !== 0 ? IconButtonStatus.RED : IconButtonStatus.NONE}
                            />
                            {isNotificationsWindow && (
                                <Modal
                                    isMobile={false}
                                    onClose={() => setIsNotificationsWindow(false)}
                                />
                            )}
                        </li>
                    )}
                    {/*<li>*/}
                    {/*    <Link to="/chat">*/}
                    {/*        <IconButton icon={<Icon type={Icons.CHAT}/>} status={IconButtonStatus.GREEN}/>*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    <li id="favoritesButtonContainer">
                        <Link to="/user/favorites/projects">
                            <IconButton buttonId="favoritesButton" icon={<Icon type={Icons.STAR} tooltip="Избранные" />} />
                        </Link>
                    </li>
                    <li className={cx.separator} />
                    <li>
                        <Link to="/user/profile" className={cx.userInfo}>
                            <img
                                id="menu-user-avatar"
                                src={userData['avatarUuid'] ? api.upload.getImage(userData['avatarUuid'], false, 128) : '/img/avatar.png'}
                                alt=""
                            />
                            <div>{userData['firstName']}</div>
                        </Link>
                    </li>
                    {menuButtons && menuButtons[buttons.SETTINGS] === true && (
                        <>
                            <li className={cx.separator} />
                            <li>
                                <Link to={getLinkToSettings()}>
                                    <IconButton buttonId="settingsButton" icon={<Icon type={Icons.SETTINGS} tooltip="Настройки" />} />
                                </Link>
                            </li>
                        </>
                    )}
                </ul>
            </div>
        );
    }
}

export default Header;
