import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useGlobalContext } from 'lib/hooks';
import { desktopStyles, mobileStyles } from 'lib/util/modalStyles';
import Input from 'uikit/input';
import { ModalBody, ModalFooter, ModalHeader } from 'components/modal/modal-components';

const cutRegexp = new RegExp(/[^а-яА-ЯёЁa-zA-Z0-9., \-"'()«»!@#№$%^&*_+=?;:|/\\]/gi);

function AddLinkModal({ isOpen = false, onClose = () => {}, onSubmit = () => {} }) {
    const { platform } = useGlobalContext();
    const [link, setLink] = useState('');
    const [linkTitle, setLinkTitle] = useState('');
    const [errors, setErrors] = useState({});

    const handleChangeLinkUrl = (value) => {
        setLink(value.replace(/\s/g, ""));
    };

    const handleChangeLinkTitle = (value) => {
        setLinkTitle(value.replace(/^\s+/g, "").replace(/\s{2,}/g, " "));
    };

    const handleBlurLinkTitle = () => {
        setLinkTitle(linkTitle => linkTitle.trim());
    };

    const onValidate = (link, linkTitle) => {
        const errors = {};

        errors["link"] = !link ? "Поле обязательно для заполнения" : undefined;
        errors["linkTitle"] = !linkTitle ? "Поле обязательно для заполнения" : undefined;

        if (link && !errors["link"]) {
            errors["link"] = link.length > 2000 ? "Превышена максимальная длина текста" : undefined;
        }

        if (linkTitle && !errors["linkTitle"]) {
            errors["linkTitle"] = linkTitle.match(cutRegexp) ? `Для ввода допустимы следующие символы - буквы, цифры, символы ., - "" '' () «»!@#№$%^&*_+=?;:|/\\` : undefined;
        }

        if (linkTitle && !errors["linkTitle"]) {
            errors["linkTitle"] = linkTitle.length > 254 ? "Превышена максимальная длина текста" : undefined;
        }

        if (Object.keys(errors).filter((p) => errors[p]).length !== 0) {
            setErrors(errors);
            return false;
        }

        setErrors({});
        return true;
    };

    const onSubmitHandler = () => {
        let saveLink = link;

        if (saveLink && !saveLink.includes('://') && saveLink.indexOf('//') !== 0) {
            saveLink = '//' + saveLink;
            setLink(saveLink);
        }

        const isValid = onValidate(saveLink, linkTitle);

        if (!isValid) {
            return;
        }

        onSubmit({ link: saveLink, linkTitle });
        onClose();
    };

    useEffect(() => {
        if (!isOpen) {
            setLink('');
            setLinkTitle('');
            setErrors({});
        }
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={
                platform === 'mobile'
                    ? mobileStyles
                    : { ...desktopStyles, content: { ...desktopStyles.content, maxWidth: 568, width: '100%' } }
            }
            testId="linkUploadModal"
        >
            <ModalHeader title="Прикрепить ссылку" onClose={onClose} />
            <ModalBody>
                <Input
                    wrapperStyle={{ paddingBottom: 24 }}
                    id="linkUrl"
                    label="URL-адрес"
                    value={link}
                    onChange={handleChangeLinkUrl}
                    error={errors["link"]}
                    data-testid="linkUploadModalLinkUrl"
                />
                <Input
                    id="linkName"
                    label="Название ссылки"
                    value={linkTitle}
                    onChange={handleChangeLinkTitle}
                    onBlur={handleBlurLinkTitle}
                    error={errors["linkTitle"]}
                    data-testid="linkUploadModalLinkName"
                />
            </ModalBody>
            <ModalFooter
                buttons={[
                    { label: 'Отмена', onClick: onClose },
                    { label: 'Подтвердить', color: 'green', onClick: onSubmitHandler },
                ]}
            />
        </Modal>
    );
}

export default AddLinkModal;
