import { lowlight } from 'lowlight/lib/core';
import StarterKit from '@tiptap/starter-kit';
import { Color } from '@tiptap/extension-color';
import Underline from '@tiptap/extension-underline';
import TextStyle from '@tiptap/extension-text-style';
import CharacterCount from '@tiptap/extension-character-count';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import FontFamily from '@tiptap/extension-font-family';
import BubbleMenu from './BubbleMenu';
import Callout from './Callout';
import { ResizableImage } from './ResizableImage/extension-resizable-image';
import { ResizableVideo } from './ResizableVideo/extension-resizable-video';
import Diagram from './Diagram/extension-diagram';
import GoogleDoc from './GoogleDoc/extension-google-doc';
import SearchAndReplace from './extension-search-and-replace';
import Column from './Column';
import { CopyFormat } from './extension-copy-format';
import { TableOfContents } from './TableOfContents/extension-table-of-contents';
import { CustomHeading } from './Heading/extension-heading';
import { CustomEmbed } from './EmbedVideo/extension-embed-video';
import { Anchor } from './Anchor/extension-anchor';
import DragAndDrop from './extension-draggable';
import CodeView from './CodeView/extension-code-view';
import codemirror from 'codemirror';
import 'codemirror/lib/codemirror.css'; // import base style
import 'codemirror/mode/xml/xml.js'; // language
import 'codemirror/addon/selection/active-line.js'; // require active-line.js
import 'codemirror/addon/edit/closetag.js'; // autoCloseTags
import apache from 'highlight.js/lib/languages/apache';
import bash from 'highlight.js/lib/languages/bash';
import coffeescript from 'highlight.js/lib/languages/coffeescript';
import cpp from 'highlight.js/lib/languages/cpp';
import csharp from 'highlight.js/lib/languages/csharp';
import css from 'highlight.js/lib/languages/css';
import diff from 'highlight.js/lib/languages/diff';
import http from 'highlight.js/lib/languages/http';
import ini from 'highlight.js/lib/languages/ini';
import java from 'highlight.js/lib/languages/java';
import js from 'highlight.js/lib/languages/javascript';
import json from 'highlight.js/lib/languages/json';
import makefile from 'highlight.js/lib/languages/makefile';
import markdown from 'highlight.js/lib/languages/markdown';
import nginx from 'highlight.js/lib/languages/nginx';
import objectivec from 'highlight.js/lib/languages/objectivec';
import perl from 'highlight.js/lib/languages/perl';
import php from 'highlight.js/lib/languages/php';
import python from 'highlight.js/lib/languages/python';
import ruby from 'highlight.js/lib/languages/ruby';
import sql from 'highlight.js/lib/languages/sql';
import vbscript from 'highlight.js/lib/languages/vbscript';
import html from 'highlight.js/lib/languages/xml';
import ts from 'highlight.js/lib/languages/typescript';
import { TrailingNode } from './extension-trailling-node';
import { TableExtensions } from './Table';
import { CopyPaste } from './CopyPaste/extension-copy-paste';
import { ListExtensions } from './List';
import { fileUpload } from '../utils/fileUpload';
import { CustomHighlight } from './extension-highlight';
import { Emoji } from './extension-emoji';
import GlobalAttributes from './GlobalAttributes';
import Placeholder from '@tiptap/extension-placeholder';
import { CustomHorizontalRule } from './extension-horizontal-rule';
import Tooltip from './extension-tooltip';
import { CustomBlockquote } from './extension-blockquote';
import Enter from './extension-enter';
import { CustomListItem } from './List/extension-list-item';
import { CustomLink } from './Link/extension-link';
import ExtensionCustomDetails from './Details/extension-custom-details';
import DetailsSummary from '@tiptap-pro/extension-details-summary';
import ExtensionCustomDetailsContent from './Details/extension-custom-details-content';
import CustomBold from './extension-custom-bold';
import { CustomParagraph } from './extension-paragraph';
import { SelectDelete } from './extension-select-delete';
import { Font } from './extension-font';
import { TableOfContentsItem } from './TableOfContents/extension-table-of-content-item';
import { TableOfContentsList } from './TableOfContents/extension-table-of-contents-list';
import { Br } from './extension-br';
import { Gapcursor } from '@tiptap/extension-gapcursor';
import Selection from './extension-selection';
import { CodeBlockWithLineNumbersExt } from './CodeBlock/code-block-with-line-numbers/extension';
import { FileStorage } from './extension-file-storage';
import { Div } from './extension-div';
import { EmbedDrawIoImageExt } from './EmbedDrawIo'
import { Monospace } from './extension-monospace';

lowlight.registerLanguage('apache', apache);
lowlight.registerLanguage('bash', bash);
lowlight.registerLanguage('coffeescript', coffeescript);
lowlight.registerLanguage('cpp', cpp);
lowlight.registerLanguage('csharp', csharp);
lowlight.registerLanguage('css', css);
lowlight.registerLanguage('diff', diff);
lowlight.registerLanguage('html', html);
lowlight.registerLanguage('http', http);
lowlight.registerLanguage('ini', ini);
lowlight.registerLanguage('java', java);
lowlight.registerLanguage('js', js);
lowlight.registerLanguage('json', json);
lowlight.registerLanguage('makefile', makefile);
lowlight.registerLanguage('markdown', markdown);
lowlight.registerLanguage('nginx', nginx);
lowlight.registerLanguage('objectivec', objectivec);
lowlight.registerLanguage('perl', perl);
lowlight.registerLanguage('php', php);
lowlight.registerLanguage('python', python);
lowlight.registerLanguage('ruby', ruby);
lowlight.registerLanguage('sql', sql);
lowlight.registerLanguage('vbscript', vbscript);
lowlight.registerLanguage('ts', ts);

export const extensions = [
    ...TableExtensions,
    Monospace,
    Gapcursor,
    Anchor,
    Br,
    GlobalAttributes,
    Tooltip,
    Emoji,
    CustomEmbed,
    CustomHighlight.configure({ multicolor: true }),
    ExtensionCustomDetails.configure({
        persist: false,
        HTMLAttributes: {
            class: 'details',
        },
    }),
    DetailsSummary,
    ExtensionCustomDetailsContent,
    Placeholder.configure({
        includeChildren: true,
        showOnlyCurrent: false,
        placeholder: ({ node }) => {
            if (node.type.name === 'detailsSummary') {
                return 'Заголовок';
            }

            return null;
        },
    }),
    CustomHorizontalRule.configure({
        HTMLAttributes: {
            class: 'tiptap',
        },
    }),
    StarterKit.configure({
        blockquote: false,
        bold: false,
        bulletList: false,
        orderedList: false,
        listItem: false,
        heading: false,
        dropcursor: {
            color: '#188FE3',
        },
        codeBlock: false,
        horizontalRule: false,
        paragraph: false,
    }),
    CustomParagraph,
    ...ListExtensions,
    Underline,
    CustomBold,
    TextStyle,
    CopyPaste.configure({
        onUpload: fileUpload,
    }),
    TrailingNode,
    Color,
    Subscript,
    Superscript,
    FontFamily,
    Callout,
    ResizableImage.configure({
        onUpload: fileUpload,
    }),
    ResizableVideo,
    SearchAndReplace,
    Column,
    GoogleDoc,
    Diagram,
    CopyFormat,
    TableOfContents,
    TableOfContentsItem,
    TableOfContentsList,
    CustomHeading,
    CodeView.configure({
        codemirror,
        codemirrorOptions: {
            styleActiveLine: true,
            autoCloseTags: true,
        },
    }),
    BubbleMenu,
    CodeBlockWithLineNumbersExt.configure({
        lowlight,
        defaultLanguage: 'javascript',
        HTMLAttributes: {
            language: 'javascript',
        },
    }),
    DragAndDrop,
    CharacterCount.configure({
        limit: 5000000,
    }),
    CustomLink.configure({
        openOnClick: false,
        linkOnPaste: false,
        autolink: true
    }),
    Enter.configure({
        preventBlockTypes: ['details'],
        appendBlockTypes: ['callout', 'detailsContent', 'blockquote'],
        appendTextTypes: ['codeBlock'],
    }),
    CustomListItem,
    SelectDelete.configure({
        extensions: [ResizableImage, ResizableVideo, GoogleDoc, CustomEmbed, Diagram],
    }),
    Font,
    Selection,
    CustomBlockquote,
    FileStorage,
    Div,
    EmbedDrawIoImageExt,
];
