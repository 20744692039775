import React, { useEffect, useMemo, useState } from 'react';
import { makeMoveable, Scalable } from 'react-moveable';
import { useClickOutside } from './use-click-outside';
const Moveable = makeMoveable([Scalable]);

const ResizableImageComponent = props => {
    const { node, extension, editor } = props;
    const imageRef = useClickOutside(() => setFocused(false));

    const [focused, setFocused] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    // const [_scaleStartSize, setScaleStartSize] = useState({ width: null, height: null });

    const attrs = node.attrs;
    const options = extension.options;
    const disabled = !editor.isEditable;

    const { width, height } = attrs;
    const keepRatio = attrs['data-keep-ratio'];

    const scaleDirection = useMemo(() => {
        if (attrs['data-align'] === 'center') {
            return [0, -1];
        } else if (attrs['data-align'] === 'right') {
            return [1, -1];
        } else {
            return [-1, -1];
        }
    }, [attrs]);

    const style = !keepRatio && {
        width,
        height,
    };
    const sharedImageProps = {
        ...attrs,
        style: {
            ...(style || {}),
            cursor: !focused || attrs.link ? 'pointer' : 'default',
            width: width,
            // maxWidth: 600,
        },
    };

    const onScale = event => {
        event.target.style.transform = event.drag.transform;
    };

    const onScaleEnd = event => {
        const rect = event.target.getBoundingClientRect();

        editor
            .chain()
            .resizeImage({ width: +rect.width.toFixed(0) })
            .setNodeSelection(editor.state.selection.from)
            .run();

        event.target.style.transform = '';
    };

    const onImageClick = () => {
        if (focused && attrs['data-link']) {
            window.open(attrs['data-link'], '_blank');
        }
        setFocused(true);
        editor.commands.setNodeSelection(props.getPos());
    };

    useEffect(() => {
        document.addEventListener('keydown', event => {
            if (event.ctrlKey && (event.key === 'a' || event.key === 'A')) {
                setFocused(true);
            }
        });
    }, []);

    if (disabled) {
        return <img alt={attrs.alt || ''} {...sharedImageProps} />;
    }

    return (
        <>
            <img
                alt={attrs.alt || ''}
                {...sharedImageProps}
                ref={imageRef}
                onClick={onImageClick}
                onDrag={() => setFocused(true)}
                onContextMenu={event => {
                    setFocused(true);
                    options.onContextMenu?.(event, {
                        setFocused,
                        ...props,
                    });
                }}
                onLoad={() => {
                    setIsLoaded(true);
                    editor.view.dispatch(editor.view.state.tr.setSelection(editor.view.state.selection));
                }}
            />

            {isLoaded && (
                <Moveable
                    target={focused ? imageRef : null}
                    scalable={true}
                    keepRatio={keepRatio}
                    origin={false}
                    throttleScale={0}
                    renderDirections={['se', 'nw', 'ne', 'sw']}
                    snappable={true}
                    onScale={onScale}
                    onScaleEnd={onScaleEnd}
                    onScaleStart={e => {
                        // const rect = e.target.getBoundingClientRect();
                        // setScaleStartSize({ width: rect.width, height: rect.height });

                        e.setFixedDirection(scaleDirection);
                    }}
                    onBeforeScale={e => {
                        e.setFixedDirection(scaleDirection);
                    }}
                    {...options.moveableProps}
                />
            )}
        </>
    );
};

export default ResizableImageComponent;
