import React, { useState, useContext, useEffect } from 'react';
import classNames from 'classnames';
import { useDialog, useGlobalContext, useMessage, usePermittedActions } from 'lib/hooks';
import { perm, RESOURCE_TYPE } from 'model/resource';
import { TreePropContext } from 'containers/side-tree-wrapper';
import { articleFormPage } from 'components/aritcle-form';
import Preview from 'components/article-preview';
import Button from 'uikit/button/button';
import Loading from 'uikit/loading';
import api from 'api/index';
import cs from './edit-article-page.module.scss';
import { useUpload } from 'components/background-upload/upload-provider';
import Confirmation from 'components/confirmation';
import Divider from 'uikit/divider';
import Switch from 'uikit/switch/switch';
import { incrementUnreadCounter } from 'slice/authSlice';
import { useDispatch } from 'react-redux';
import UploadEditorImages from 'lib/helpers/uploadEditorImages';
import { setDocumentTitle, useNavigate, useParams } from 'shared/router';

const EditArticlePage = ({ article, loading, setLoading, clearAutoSave }) => {
    const navigate = useNavigate();
    const { type = 'article' } = useParams();

    const dispatch = useDispatch();
    const { platform, setFormDataChanged } = useGlobalContext();

    const { setScrollToNode, tree, ...treePropsContext } = useContext(TreePropContext);
    const { addError, addSuccess } = useMessage();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [previewShow, setPreviewShow] = useState(false);

    const [isSubmit, setIsSubmit] = useState(false);
    const { checkPermission, ready } = usePermittedActions(
        type === 'article' ? RESOURCE_TYPE.ARTICLE : null,
        type === 'article' ? article.id : null
    );

    const { showBackgroundModal, isUploading, addGroupCallback, removeGroupCallback } = useUpload(article.id);
    const [queuedAction, setQueuedAction] = useState(null);

    const onOk = async () => {
        showBackgroundModal();

        const formData = article.getFormData();
        formData.articleBody = await UploadEditorImages(article.id ?? article.initId, formData.articleBody);

        let method;
        switch (queuedAction) {
            case 'editArticle':
                method = api.article.editArticle;
                break;
            case 'editArticleDraft':
                method = api.article.editArticleDraft;
                break;
            case 'addArticleDraft':
                method = api.article.postArticleDraft;
                break;
            case 'publishArticleDraft':
                method = api.article.publishArticleDraft;
                break;
            default:
                method = () => console.log("won't happen, but warning");
        }
        const callback = uploadedFiles => {
            const files = [...formData.files, ...uploadedFiles.filter(f => f.status === 'done').map(f => f.fileId)];
            // uniques only
            formData.files = files.filter((v, i, a) => a.indexOf(v) === i);
            method(formData);
            addSuccess('Статья изменена.');
            removeGroupCallback(callback);
        };
        addGroupCallback(callback);
        closeDialog();

        addSuccess('Действие будет выполнено после загрузки файлов. Не закрывайте вкладку.');
    };

    const onBackgroundUpload = () => {
        openDialog({
            title: 'Фоновый режим',
            text: 'Не все файлы были загружены. Загрузить их в фоновом режиме?',
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Да, загрузить',
            onSubmit: onOk,
            onClose: closeDialog,
        });
    };

    const editArticle = async () => {
        article.commit();
        article.submit();

        setIsSubmit(true);

        if (!article.validateFields()) {
            return;
        }

        const titleExists = await article.validateTitleExists();

        if (titleExists) {
            return;
        }

        if (isUploading) {
            setQueuedAction('editArticle');
            onBackgroundUpload();
            return;
        }

        setPreviewShow(false);
        setLoading(true);

        try {
            setFormDataChanged(false);

            const formData = article.getFormData();
            formData.articleBody = await UploadEditorImages(article.id ?? article.initId, formData.articleBody);

            let link;
            if (type === 'article') {
                if (!formData.insignificantChanges) {
                    const _read = !localStorage.getItem(formData.id);
                    if (_read) {
                        function traverse(node) {
                            if (node) {
                                if (node.unreadCount > 0) {
                                    tree.updateNode(node, { unreadCount: Math.max(0, node.unreadCount + 1) }, { shallowRendering: false });
                                }
                                if (node.parent) {
                                    traverse(node.parent);
                                }
                            }
                        }

                        const node = tree.getNodeById(formData.id);
                        traverse(node?.parent);
                        tree.updateNode(node, { unread: true });
                        dispatch(incrementUnreadCounter('unreadArticles'));
                    }
                }
                await api.article.editArticle(formData);
                link = `/projects/article/${article.id}`;
            } else if (type === 'delayed-article') {
                await api.article.editDelayedArticle(formData);
                link = `/user/delayed-publications/articles`;
            }

            if (type === 'article') {
                setTimeout(() => {
                    article.makeMovement(treePropsContext.treeRef.current?.tree);
                }, 1);
            }

            addSuccess('Статья изменена');
            navigate(link);

            clearAutoSave();
        } catch (error) {
            setFormDataChanged(true);
            addError('Не удалось изменить статью');
        } finally {
            setLoading(false);
        }
    };

    const addArticleDraft = async () => {
        article.commit();
        article.submit();

        setIsSubmit(true);

        if (!article.validateFields(['title', 'text'])) {
            return;
        }

        if (isUploading) {
            setQueuedAction('addArticleDraft');
            onBackgroundUpload();
            return;
        }

        setPreviewShow(false);
        setLoading(true);

        try {
            const { id, ...rest } = article.getFormData();
            rest.articleBody = await UploadEditorImages(article.id ?? article.initId, rest.articleBody);

            await api.article.postArticleDraft({ ...rest, articleId: id });

            addSuccess('Статья перемещена в черновики');
            navigate('/user/drafts/articles');

            clearAutoSave();
        } catch (error) {
            addError('Не удалось переместить статью в черновики');
        } finally {
            setLoading(false);
        }
    };

    const editArticleDraft = async () => {
        article.commit();
        article.submit();

        setIsSubmit(true);

        if (!article.validateFields(['title', 'text'])) {
            return;
        }

        if (isUploading) {
            setQueuedAction('editArticleDraft');
            onBackgroundUpload();
            return;
        }

        setPreviewShow(false);
        setFormDataChanged(false);
        setLoading(true);

        try {
            const formData = article.getFormData();
            formData.articleBody = await UploadEditorImages(article.id ?? article.initId, formData.articleBody);

            await api.article.editArticleDraft(formData);

            addSuccess('Черновик статьи изменен');
            navigate('/user/drafts/articles', 'no-check-form-data-changed');

            clearAutoSave();
        } catch (error) {
            addError('Не удалось изменить черновик статьи');
        } finally {
            setLoading(false);
        }
    };

    const publishArticleDraft = async () => {
        article.commit();
        article.submit();

        setIsSubmit(true);

        const titleExists = await article.validateTitleExists();

        if (!article.validateFields()) {
            return;
        }

        if (titleExists) {
            return;
        }

        if (isUploading) {
            setQueuedAction('publishArticleDraft');
            onBackgroundUpload();
            return;
        }

        setPreviewShow(false);
        setFormDataChanged(false);
        setLoading(true);

        try {
            const formData = article.getFormData();
            formData.articleBody = await UploadEditorImages(article.id ?? article.initId, formData.articleBody);

            const res = await api.article.publishArticleDraft(formData);

            addSuccess('Статья опубликована');

            if (formData.publicationTime) {
                navigate(`/user/delayed-publications/articles`);
            } else {
                navigate(`/projects/article/${res.id}`, 'no-check-form-data-changed');
            }

            clearAutoSave();
        } catch (error) {
            addError('Не удалось опубликовать черновик статьи');
        } finally {
            setLoading(false);
        }
    };

    const saveAndPublishDelayedArticle = async () => {
        article.commit();
        article.submit();

        setIsSubmit(true);

        const titleExists = await article.validateTitleExists();

        if (!article.validateFields()) {
            return;
        }

        if (titleExists) {
            return;
        }

        if (isUploading) {
            setQueuedAction('saveAndPublishDelayedArticle');
            onBackgroundUpload();
            return;
        }

        setPreviewShow(false);
        setLoading(true);

        try {
            const formData = article.getFormData();
            formData.articleBody = await UploadEditorImages(article.id ?? article.initId, formData.articleBody);

            await api.article.editDelayedArticle(formData);
            await api.article.publishDelayedArticle(article.id);

            addSuccess('Статья опубликована');
            setFormDataChanged(false);

            navigate(`/projects/${article.parentProjectId}/articles`);
            clearAutoSave();
        } catch (error) {
            addError('Не удалось опубликовать черновик статьи');
        } finally {
            setLoading(false);
        }
    };

    const _onPreview = () => {
        article.submit();

        setPreviewShow(true);
    };

    useEffect(() => {
        const parentsIds = article.getParentsIds();

        if (setScrollToNode && parentsIds) {
            setScrollToNode(parentsIds);
        }
    }, [article, setScrollToNode]);

    useEffect(() => {
        if (ready && !checkPermission(perm.document.ARTICLE_EDIT)) {
            navigate('/401');
        }
    }, [ready, checkPermission, navigate]);

    useEffect(() => {
        if (isSubmit && Object.values(article.errors).some(v => v)) {
            article.scrollToError();
            setIsSubmit(false);
        }
    }, [isSubmit, article, article.errors]);

    const handleCancelButton = () => {
        setFormDataChanged(false);

        if (article.publishedType === 'draft') {
            navigate('/user/drafts/articles', 'no-check-form-data-changed');
        } else {
            window.history.back();
        }
    };

    useEffect(() => {
        if (!article?.title) {
            return;
        }

        setDocumentTitle(`${article.title} — KMS Gran`);
    }, [article]);

    return (
        <>
            <Confirmation {...dialogState} />
            {loading && <Loading withOverlay={false} withRelativeOverlay={true} />}
            {article.publishedType !== 'draft' && (
                <>
                    {/* <Divider />
                    <div className={cs.blockStatus}>
                        <Switch
                            label="Принудительно уведомить участников об изменениях"
                            checked={article.notifySystem}
                            onClick={() => {
                                const state = !article.notifySystem;

                                article.notifySystem = state;
                                article.notifyEmail = state;

                                article.commit();
                                setFormDataChanged(true);
                            }}
                        />
                        <p style={{ marginBottom: '16px' }}>
                            Принудительно отправить оповещение об изменениях всем участникам, имеющим доступ к данной статье. Участники
                            должны будут подтвердить просмотр изменений
                        </p>
                        <Checkbox label="Отправить системное оповещение" checked={article.notifySystem} disabled={true} />
                        <Checkbox
                            label="Отправить оповещение участникам на указанный в профиле Email"
                            checked={article.notifyEmail}
                            onClick={() => {
                                setFormDataChanged(true);
                                return article.notifySystem
                                    ? () => {
                                          article.notifyEmail = !article.notifyEmail;
                                          article.commit();
                                      }
                                    : null;
                            }}
                        />
                    </div> */}

                    <Divider />
                    <div className={cs.blockStatus}>
                        <Switch
                            checked={article.insignificantChanges}
                            label="Не сбрасывать статус Прочитано после этого изменения"
                            onClick={() => {
                                article.insignificantChanges = !article.insignificantChanges;
                                article.commit();
                                setFormDataChanged(true);
                            }}
                        />
                        <p>
                            Применяется, если изменения в данной версии не влияют на содержание документа, например, исправление
                            орфографической ошибки, незначительное изменение форматирования и пр.
                        </p>
                    </div>
                </>
            )}
            <Divider className={cs.toolDivider} />
            <div className={classNames(cs.toolbar, { [cs.mobile]: platform === 'mobile' })}>
                <Button label="Отмена" disabled={loading} onClick={handleCancelButton} fullWidth={platform === 'mobile'} />
                {!article && type !== 'delayed-article' && (
                    <Button onClick={addArticleDraft} label="В черновик" disabled={loading} fullWidth={platform === 'mobile'} />
                )}
                <Button onClick={_onPreview} label="Предпросмотр" disabled={loading} fullWidth={platform === 'mobile'} />
                {type === 'article-draft' && (
                    <Button
                        className={cs.desktop}
                        onClick={editArticleDraft}
                        label="Сохранить и оставить в черновик"
                        disabled={loading}
                        fullWidth={platform === 'mobile'}
                    />
                )}
                {type === 'article-draft' && (
                    <Button
                        className={cs.noDesktop}
                        onClick={editArticleDraft}
                        label="Сохранить"
                        disabled={loading}
                        fullWidth={platform === 'mobile'}
                    />
                )}
                {type !== 'article-draft' && (
                    <Button onClick={editArticle} label="Сохранить" disabled={loading} color={'green'} fullWidth={platform === 'mobile'} />
                )}
                {type === 'article-draft' && (
                    <Button
                        className={cs.desktop}
                        onClick={publishArticleDraft}
                        label="Сохранить и опубликовать"
                        disabled={loading}
                        color={'green'}
                        fullWidth={platform === 'mobile'}
                    />
                )}
                {type === 'article-draft' && (
                    <Button
                        className={cs.noDesktop}
                        onClick={publishArticleDraft}
                        label="Опубликовать"
                        disabled={loading}
                        color={'green'}
                        fullWidth={platform === 'mobile'}
                    />
                )}
                {type === 'delayed-article' && (
                    <Button
                        className={cs.desktop}
                        onClick={saveAndPublishDelayedArticle}
                        label="Сохранить и опубликовать сейчас"
                        disabled={loading}
                        color={'green'}
                        fullWidth={platform === 'mobile'}
                    />
                )}
                {type === 'delayed-article' && (
                    <Button
                        className={cs.noDesktop}
                        onClick={saveAndPublishDelayedArticle}
                        label="Опубликовать"
                        disabled={loading}
                        color={'green'}
                        fullWidth={platform === 'mobile'}
                    />
                )}
            </div>

            <Preview
                show={previewShow}
                onDismiss={() => setPreviewShow(false)}
                onDraft={addArticleDraft}
                onEditDraft={type === 'article-draft' && editArticleDraft}
                onPublish={editArticle}
                article={article}
                type={type}
            />
        </>
    );
};

const EditArticlePageWrapped = articleFormPage(EditArticlePage, 'Редактировать статью', true);
export default EditArticlePageWrapped;
