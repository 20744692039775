import React, { useEffect, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { v1 } from 'uuid';
import * as validators from 'lib/util/validators.util';
import treeUtil from 'lib/util/tree.util';
import { useLocationTree } from 'components/trees/utils/useLocationTree';
import { InfiniteTree as Tree, NODE_TYPES } from 'components/trees';
import CommonDialog from 'components/trees/common-dialog';
import { useAutoNavigate } from 'components/trees/utils';
import api from 'api';
import cs from './location-tree.module.scss';

const LocationTree = ({
    open,
    onDismiss = () => {},
    onSubmit = () => {},
    autoOpenPath = [],
    withArticles,
    withSections,
    excludeNodes,
    multipleChoice = false,
    rights = [],
    fetchTreeFunc
}) => {
    const { loading, tree } = useLocationTree({ open,
                                                withArticles,
                                                withSections,
                                                fetchTreeFunc,
                                                treeInvalid: false,
                                                withRights: rights
                                              });
    // const { addError } = useMessage();
    const addError = function() {}
    const treeRef = useRef(null);

    const [curNode, setCurNode] = useState(null);

    const [internalTree, setInternalTree] = useState();
    const [nodeDraftHolder, setNodeDraftHolder] = useState(undefined);

    useAutoNavigate(autoOpenPath, treeRef);

    const _onSubmit = () => {
        if (multipleChoice) {
            const nodes = treeRef.current.tree.nodeTable.data;
            const selectedNodes = Object.keys(nodes)
                .map(key => {
                    return nodes[key];
                })
                .filter(node => node.state?.checked)
                .sort((nodeA, nodeB) => {
                    return nodeA.state.path - nodeB.state.path;
                });

            onSubmit(selectedNodes);
        } else {
            onSubmit(curNode);
        }
    };

    const _onUpdateNode = node => {
        setCurNode(node);
    };
    const _onAddNode = node => {
        const plugin = treeRef.current.tree;

        if (nodeDraftHolder && nodeDraftHolder.id !== node.id) {
            _onCancelAdd();
        }

        plugin.openNode(node);
        if (node.children.findIndex(n => n.nodeType === NODE_TYPES.ADD) < 0) {
            plugin.addChildNodes({ nodeType: NODE_TYPES.ADD, id: v1() }, 0, node);
            setNodeDraftHolder(plugin.getNodeById(node.id));
        }
    };

    const _onCancelAdd = () => {
        const plugin = treeRef.current.tree;
        if (nodeDraftHolder) {
            const draft = nodeDraftHolder.children.find(n => n.nodeType === NODE_TYPES.ADD);
            if (draft) {
                plugin.removeNode(draft);
                setNodeDraftHolder(undefined);
            }
        }
    };
    const _onAddCommit = async text => {
        const plugin = treeRef.current.tree;
        let err = validators.textValidator(text);

        if (err) {
            addError(err);
            return;
        }

        const root = treeUtil.getRoot(nodeDraftHolder);
        const data = {
            sectionTitle: text,
            parentProjectId: root.id
        };

        if (root.id !== nodeDraftHolder.id) {
            data.parentSectionId = nodeDraftHolder.id;
        }

        try {
            const newSection = await api.section.postSection(data);
            if (newSection) {
                const nd = { ...newSection, id: newSection.uuid, name: text, nodeType: NODE_TYPES.SECTION };
                if (plugin.addChildNodes(nd, 0, nodeDraftHolder)) {
                    const newNode = plugin.getNodeById(newSection.uuid);
                    if (newNode) {
                        plugin.selectNode(newNode);
                    }
                }
                _onCancelAdd();
            }
        } catch (e) {
            addError('Раздел с таким названием уже существует');
        }
    };

    // remove excluded
    useEffect(() => {
        if (internalTree && excludeNodes) {
            excludeNodes.forEach(nodeId => {
                const node = internalTree.getNodeById(nodeId);
                if (node) {
                    internalTree.removeNode(node);
                }
            });
        }
    }, [internalTree, excludeNodes]);

    return (
        <CommonDialog open={open} loading={loading} hasData={tree.length > 0} title="Выбор расположения"
                      onSubmit={_onSubmit} onDismiss={onDismiss}>
            <div className={cs.stretched} data-testid="locationTree">
                <AutoSizer key={tree}>
                    {({ width, height }) => {
                        return (
                            <Tree
                                id="locationTree"
                                width={width}
                                height={height}
                                data={tree}
                                onUpdate={_onUpdateNode}
                                multipleChoice={multipleChoice}
                                ref={treeRef}
                                autoOpen={false}
                                onAddNode={withSections ? _onAddNode : null}
                                onCancelAdd={_onCancelAdd}
                                onAddCommit={_onAddCommit}
                                onCheckedChange={() => {}}
                                onLoadTree={(tree) => {
                                    setInternalTree(tree);
                                }}
                                getNodeLink={() => {}}
                            />
                        );
                    }}
                </AutoSizer>
            </div>
        </CommonDialog>
    );
};

export default LocationTree;
