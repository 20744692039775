import React, { useState } from 'react';
import { desktopStyles } from 'lib/util/modalStyles';
import Modal from 'react-modal';
import IconButton from 'uikit/icon-button';
import Icon, { Icons } from 'uikit/icon';
import cs from './image-upload-modal.module.scss';
import Dropzone from 'react-dropzone';
import api from 'api';
import Loading from 'uikit/loading';
import { useMessage } from 'lib/hooks';

const ImageUploadModal = ({
    open,
    onCloseModal,
    onUpload,
    initId,
    maxSize,
    accept = 'image/png, image/jpeg',
    title = 'Загрузка изображения',
}) => {
    const [uploading, setUploading] = useState(false);
    const { addError } = useMessage();

    const handleChange = async files => {
        if (maxSize && files.some(file => file.size > maxSize)) {
            addError('Превышен максимальный размер файла, 50Мб');
            return;
        }

        setUploading(true);

        try {
            const res = [];
            await Promise.all(
                files.map(async file => {
                    const fileRes = await api.upload.uploadImage(initId, file);
                    res.push(fileRes);
                })
            );
            onUpload(res);
        } catch (e) {
            // TODO не удалось
            console.log(e);
        } finally {
            setUploading(false);
        }
    };

    return (
        <Modal isOpen={open} contentLabel={title} style={desktopStyles} testId="imageUploadModal">
            <div className={cs.modalContent}>
                <div className={cs.title} data-testid="imageUploadModalTitle">
                    <div>{title}</div>

                    <IconButton
                        icon={<Icon width={14} height={14} type={Icons.CROSS} />}
                        onClick={onCloseModal}
                        id="imageUploadModalCrestBtn"
                    />
                </div>

                {uploading ? (
                    <div className={cs.uploading}>
                        <Loading withOverlay={false} />
                        <div>Дождитесь окончания обработки файла...</div>
                    </div>
                ) : (
                    <Dropzone onDrop={handleChange}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ className: cs.dropZone })} data-testid="imageUploadModalDropzone">
                                <input {...getInputProps()} accept={accept} />
                                <Icon type={Icons.DOWNLOAD} width={24} height={24} color="blue" />
                                <p>Перетащите изображение в данную область или нажмите для выбора файла</p>
                            </div>
                        )}
                    </Dropzone>
                )}
            </div>
        </Modal>
    );
};

export default ImageUploadModal;
