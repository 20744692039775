import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import treeUtils from 'lib/util/tree.util';
import { useSidebarContentInfiniteScroll } from 'lib/hooks/useSidebarContentInfiniteScroll';
import { useAuthorized, useEventListener, useGlobalContext, useMessage } from 'lib/hooks';
import TreeUtils from 'lib/util/tree.util';
import UrlUtils from 'lib/util/url.util';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import { setParent, defaultParentObject } from 'slice/treeSlice';
import { TreePropContext } from 'containers/side-tree-wrapper';
import { Project, TabArticles, TabNews, TabActions, TabUsers, TabFiles } from 'components/projects';
import { Section, SectionTabArticles } from 'components/section';
import { NODE_TYPES } from 'components/trees';
import { Project as ProjectCard } from 'uikit/panel';
import Loading from 'uikit/loading';
import api from 'api';
import cx from './projects-example-page.module.scss';
import { selectInvalidProjects, invalidateProjects } from 'slice/treeSlice';
import { useSection } from 'lib/hooks/useSection';
import TabScripts from 'components/projects/tab-scripts';
import { Icons } from 'uikit/icon';
import { selectNewsPicker } from 'slice/newsPickerSlice';
import classNames from 'classnames';
import { useConfiguration } from '../../../lib/context';
import { EmptyProjectDeleted, EmptyProjects, EmptySectionDeleted } from 'components/projects/empty';
import { setDocumentTitle, useNavigate, useParams } from 'shared/router';

export default function ProjectsPage() {
    const { uuid, sectionId, tab } = useParams();
    const navigate = useNavigate();

    const projectBatchSize = 12;
    const { addError } = useMessage();

    const { isScriptingEnabled } = useConfiguration();
    const newsPicker = useSelector(selectNewsPicker);

    const dispatch = useDispatch();
    const { isAuthorizedAction } = useAuthorized();
    const isCreateAuthorized = isAuthorizedAction([GLOBAL_ACTIONS.PROJECT_CREATE]);

    const {
        setTreeProps,
        setScrollToNode,
        tree: treePlugin,
        treeRef,
        reloadTree,
        setOnReloadTreeAction,
    } = React.useContext(TreePropContext);
    const { platform } = useGlobalContext();

    const treePluginRef = useRef(treePlugin);
    const [loading, setLoading] = useState(false);

    const [projects, setProjects] = useState([]);
    const [projectsCount, setProjectsCount] = useState(0);
    const [totalProjects, setTotalProjects] = useState(9999);

    const projectCountRef = useRef(projectsCount);
    const { isFetching, finishFetching, setOnFetch } = useSidebarContentInfiniteScroll(projectsCount < totalProjects);

    const [project, setProject] = useState();

    const projectInfoRef = useRef(null);
    const scrollWrapperRef = useRef(null);
    const [isProjectInfoManuallyShow, setIsProjectInfoManuallyShow] = useState(true);
    const [isScroll, setIsScroll] = useState(false);
    const isProjectInfoHidden = useMemo(() => {
        if (!isProjectInfoManuallyShow) {
            return true;
        }

        if (isScroll || (!isScroll && !isProjectInfoManuallyShow)) {
            return true;
        }

        return false;
    }, [isProjectInfoManuallyShow, isScroll]);

    const toggleProjectManuallyShow = () => {
        if (isScroll) {
            setIsProjectInfoManuallyShow(true);
            setIsScroll(false);
        } else {
            setIsProjectInfoManuallyShow(isShow => !isShow);
        }
    };

    const invalidProjects = useSelector(selectInvalidProjects);
    const section = useSection(sectionId);

    const getTreeLink = useCallback(
        node => {
            if (node) {
                if (node.nodeType === 'PROJECT') {
                    return { link: '/projects/' + node.id + (tab ? '/' + tab : '/articles') };
                } else if (node.nodeType === 'SECTION') {
                    const parentNode = TreeUtils.getTopParentNode(node);
                    const sectionData = {
                        id: node.id,
                        projectId: node.id !== parentNode.id ? parentNode.id : uuid,
                    };

                    return {
                        link: UrlUtils.getSectionUrl(sectionData.projectId, sectionData.id, tab === 'news' ? 'articles' : tab)
                    };
                } else if (node.nodeType === 'ARTICLE') {
                    return `/projects/article/${node.id}`;
                }
            } else {
                return '/projects';
            }
        },
        [uuid, tab]
    );

    const onTabChange = tab => {
        if (uuid && sectionId) {
            navigate(`/projects/${uuid}/${sectionId}/section/${tab ?? ''}`);
        } else if (uuid) {
            navigate(`/projects/${project.id}/${tab ?? ''}`);
        }
    };

    const onDeleteProject = () => {
        reloadTree();
        navigate('/projects');
    };
    const onDeleteSection = id => {
        let url;

        const selectedNode = TreeUtils.findSectionInNode({ children: treeRef.current.state.nodes }, id);
        const parentNode = selectedNode.parent;

        if (parentNode.nodeType === NODE_TYPES.PROJECT) {
            url = `/projects/${parentNode.id}/articles`;
        } else if (parentNode.nodeType === NODE_TYPES.SECTION) {
            const parentTopNode = TreeUtils.getTopParentNode(selectedNode);
            url = UrlUtils.getSectionUrl(parentTopNode.id, parentNode.id, 'articles');
        }

        reloadTree();
        navigate(`${url}`);
    };

    const onProjectListFavoriteHandler = async (status, id) => {
        setProjects(projects.map(project => {
            if (project.id !== id) {
                return project;
            }

            return { ...project, favorite: status }
        }))
    };

    const onProjectFavoriteHandler = async status => {
        setProject({ ...project, favorite: status });
    };

    const requestProjects = useCallback(async () => {
        try {
            const projectsResponse = await api.project.getProjects(0, projectsCount);
            const _projects = projectsResponse.content.map(project => {
                return {
                    id: project['id'],
                    image: project['logoUuid'] ? api.upload.getImage(project['logoUuid'], false, 128) : '',
                    title: project['title'],
                    managerLogin: project['author']['login'],
                    managerShortName: project['author']['firstName'] + ' ' + project['author']['lastName']?.slice(0, 1) + '.',
                    manager: project['author']['firstName'] + ' ' + project['author']['lastName'],
                    managerStatus: project['author']['status'],
                    activityUserLogin: project['lastAction'] ? project['lastAction']?.['userInfo']?.['login'] : project['author']['login'],
                    activityUser: project['lastAction']
                        ? project['lastAction']?.['userInfo']?.['firstName'] + ' ' + project['lastAction']?.['userInfo']?.['lastName']
                        : project['author']['firstName'] + ' ' + project['author']['lastName'],
                    activityUserAvatar: project['lastAction']
                        ? project['lastAction']?.['userInfo']?.['avatarUuid']
                            ? api.upload.getImage(project['lastAction']?.['userInfo']?.['avatarUuid'], false, 128)
                            : ''
                        : project['author']['avatarUuid']
                        ? api.upload.getImage(project['author']['avatarUuid'], false, 128)
                        : '',
                    activityDate: project['lastAction'] ? new Date(project['lastAction']['timestamp']) : new Date(project['createTime']),
                    articles: project['numberOfArticles'],
                    news: project['numberOfNews'],
                    files: project['numberOfAttachments'],
                    members: project['numberOfMembers'],
                    favorite: project['favorite'],
                };
            });

            setTotalProjects(parseInt(projectsResponse.totalElements));
            setProjects(_projects);
        } catch {
            setProjects([]);
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
        }
    }, [addError, projectsCount]);

    useEffect(() => {
        treePluginRef.current = treePlugin;
    }, [treePlugin]);

    useEffect(() => {
        projectCountRef.current = projectsCount;
    }, [projectsCount]);
    useEffect(() => {
        if (projectsCount > 0) {
            requestProjects();
        }
    }, [projectsCount, requestProjects]);

    useEffect(() => {
        if (invalidProjects) {
            if (projectsCount > 0) {
                requestProjects();
            }

            dispatch(invalidateProjects(false));
        }
    }, [invalidProjects, projectsCount, dispatch, requestProjects]);

    useEffect(() => {
        setLoading(false);
        finishFetching();
    }, [projects, finishFetching]);
    useEffect(() => {
        setOnFetch(() => {
            const newCount =
                projectCountRef.current + projectBatchSize < totalProjects ? projectCountRef.current + projectBatchSize : totalProjects;
            setProjectsCount(newCount);
        });
    }, [setOnFetch, totalProjects, projectBatchSize]);

    useEffect(() => {
        if (!uuid) {
            setLoading(true);
            setProjectsCount(projectBatchSize);
        }
    }, [uuid, projectBatchSize]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (uuid && sectionId) {
                    setProject(undefined);
                } else if (uuid) {
                    const projectData = await api.project.getProject(uuid);

                    setProject({
                        id: projectData['id'],
                        title: projectData['title'],
                        avatar: projectData['logoUuid'] ? api.upload.getImage(projectData['logoUuid'], false, 0) : '',
                        description: projectData['description'],
                        manager: projectData['author']['firstName'] + ' ' + projectData['author']['lastName'],
                        managerLogin: projectData['author']['login'],
                        favorite: projectData['favorite'],
                        empty: projectData['empty'],
                        archived: projectData['archived'],
                    });
                }
            } catch (e) {
                setProject(null);
            }
        };
        fetchData();
    }, [addError, uuid, sectionId]);

    useEffect(() => {
        setTreeProps({ getTreeLink });
    }, [setTreeProps, getTreeLink]);

    useEffect(() => {
        let handler;

        const rememberLocation = () => {
            if (!treeRef.current) {
                handler = setTimeout(rememberLocation, 1000);
                return;
            }

            const parents = { ...defaultParentObject };
            const node = treeRef.current.state.nodes.find(node => node.id === (sectionId || uuid));

            if (!node) {
                handler = setTimeout(rememberLocation, 1000);
                return;
            }

            parents.visualPath = TreeUtils.getVisualPathTo(node);

            if (node.nodeType === NODE_TYPES.PROJECT) {
                parents.parentProjectId = node.id;
            } else {
                parents.parentProjectId = TreeUtils.getTopParentNode(node).id;
                parents.parentSectionId = node.id;
            }

            dispatch(setParent(parents));

            const pathIds = treeUtils.getPathIdsTo(node);
            setScrollToNode(pathIds);
        };

        rememberLocation();

        return () => {
            if (handler) {
                clearTimeout(handler);
            }
        };
    }, [uuid, sectionId, treeRef, dispatch, setScrollToNode]);
    useEffect(() => {
        if (!project && !section) {
            return;
        }

        if (section && section['archived']) {
            navigate('/archive/' + section['projectId'] + '/' + section['uuid'] + '/section/articles');
        } else if (!section && project['archived']) {
            navigate('/archive/' + project['id'] + '/articles');
        }
    }, [navigate, project, section]);

    const handleDeleteFromFavorites = async id => {
        await api.favorites.deleteProjectFromFavorites(id);
        setProjects(
            projects.map(project => {
                if (project.id !== id) {
                    return project;
                }

                return { ...project, favorite: false };
            })
        );
    };

    useEventListener(
        'scroll',
        () => {
            if (!scrollWrapperRef.current) {
                return;
            }

            if (
                scrollWrapperRef.current.scrollTop > 0 &&
                !isScroll &&
                scrollWrapperRef.current.clientHeight + projectInfoRef.current.clientHeight < scrollWrapperRef.current.scrollHeight
            ) {
                setIsScroll(true);
                return;
            }

            if (scrollWrapperRef.current.scrollTop === 0 && isScroll) {
                setIsScroll(false);
            }
        },
        scrollWrapperRef.current
    );

    useEffect(() => {
        if (!uuid && !sectionId) {
            setDocumentTitle('Проекты — KMS Gran');
        }

        if (section) {
            setDocumentTitle(`${section.parentTitle} — KMS Gran`);
        } else if (project) {
            setDocumentTitle(`${project.title} — KMS Gran`);
        }
    }, [uuid, sectionId, project, section]);

    return (
        <>
            {loading && (
                <div className={cx.loader}>
                    <Loading withOverlay={false} />
                </div>
            )}
            {!loading && (
                <div
                    className={classNames(cx.content, {
                        [cx.withNewsTicker]: newsPicker.count > 0,
                    })}
                >
                    {!uuid && !project && !sectionId && !section && (
                        <div className={cx.projects}>
                            {projects?.length ? (
                                <>
                                    <div className={cx.pageTitle}>Ваши проекты</div>
                                    <div className={cx.projectCards}>
                                        {projects.map(item => {
                                            return (
                                                <div key={item.id} className={cx.projectCardWrapper}>
                                                    <ProjectCard
                                                        id={item.id}
                                                        image={item.image}
                                                        title={item.title}
                                                        managerLogin={item.managerLogin}
                                                        manager={item.manager}
                                                        activityUser={item.activityUser}
                                                        favorite={item.favorite}
                                                        activityUserAvatar={item.activityUserAvatar}
                                                        managerStatus={item.managerStatus}
                                                        activityDate={item.activityDate}
                                                        managerShortName={item.managerShortName}
                                                        activityUserLogin={item.activityUserLogin}
                                                        articles={item.articles}
                                                        news={item.news}
                                                        files={item.files}
                                                        members={item.members}
                                                        link={'/projects/' + item.id + '/articles'}
                                                        onDeleteFromFavorites={handleDeleteFromFavorites}
                                                        onFavoritesHandler={onProjectListFavoriteHandler}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            ) : (
                                <EmptyProjects
                                    buttons={
                                        isCreateAuthorized
                                            ? [
                                                  {
                                                      icon: Icons.FOLDER_THEME,
                                                      title: 'Добавить проект',
                                                      onClick: () => navigate('/add/project'),
                                                  },
                                              ]
                                            : null
                                    }
                                />
                            )}
                            {isFetching && (
                                <div className={cx.smallLoader}>
                                    <Loading withOverlay={false} small />
                                </div>
                            )}
                        </div>
                    )}
                    {((sectionId && section === null) || (!sectionId && project === null)) && (
                        <>
                            {!sectionId && <EmptyProjectDeleted />}
                            {sectionId && <EmptySectionDeleted />}
                        </>
                    )}
                    {project && (
                        <Project
                            id={project.id}
                            avatar={project.avatar}
                            title={project.title}
                            favorite={project.favorite}
                            description={project.description}
                            manager={project.manager}
                            managerLogin={project.managerLogin}
                            onDelete={onDeleteProject}
                            onAdd={() => {}}
                            onFavoriteHandler={onProjectFavoriteHandler}
                            onEdit={() => navigate(`/projects/project/${project.id}/edit`)}
                            onMore={() => {}}
                            isProjectInfoHidden={isProjectInfoHidden}
                            onInfoShow={toggleProjectManuallyShow}
                            tab={tab}
                            onTabChange={onTabChange}
                            handleOnDelete={onDeleteProject}
                            isMobile={platform === 'mobile'}
                            empty={project.empty}
                            projectInfoRef={projectInfoRef}
                        />
                    )}
                    {section && (
                        <Section
                            id={sectionId}
                            title={section?.title}
                            projectId={uuid}
                            tab={tab}
                            onTabChange={onTabChange}
                            handleOnDelete={onDeleteSection}
                            isMobile={platform === 'mobile'}
                            empty={section?.empty}
                        />
                    )}
                    <div ref={scrollWrapperRef} className={cx.scrollWrapper}>
                        {project && tab === 'articles' && (
                            <TabArticles
                                projectId={uuid}
                                reloadTree={reloadTree}
                                setOnReloadTreeAction={setOnReloadTreeAction || (() => {})}
                            />
                        )}
                        {project && tab === 'news' && <TabNews projectId={uuid} />}
                        {project && tab === 'actions' && isAuthorizedAction([GLOBAL_ACTIONS.ACTIVITY_PAGE]) && (
                            <TabActions uuid={project.id} />
                        )}
                        {project && tab === 'users' && <TabUsers projectId={uuid} />}
                        {project && tab === 'files' && <TabFiles projectId={uuid} />}
                        {project && tab === 'scripts' && isScriptingEnabled && <TabScripts projectId={uuid} />}
                    </div>
                    {section && tab === 'articles' && (
                        <SectionTabArticles
                            sectionId={sectionId}
                            reloadTree={reloadTree}
                            setOnReloadTreeAction={setOnReloadTreeAction || (() => {})}
                        />
                    )}
                    {section && tab === 'actions' && isAuthorizedAction([GLOBAL_ACTIONS.ACTIVITY_PAGE]) && <TabActions uuid={sectionId} />}
                    {section && tab === 'users' && <TabUsers projectId={uuid} sectionId={sectionId} />}
                    {section && tab === 'files' && <TabFiles projectId={uuid} sectionId={sectionId} />}
                    {section && tab === 'scripts' && isScriptingEnabled && <TabScripts projectId={uuid} sectionId={sectionId} />}
                </div>
            )}
        </>
    );
}
