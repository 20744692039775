import React, { useState, useMemo, useEffect } from 'react';
import { Link } from 'shared/router';
import { ReportsComponent } from 'components/data-components/reports-component';
import cx from './statistic-reports-page.module.scss';
import api from 'api';
import { documentStatusOptions, sortTypesOptions } from 'app/statistic/vendors/filtersOptions';
import { ReportService } from 'app/statistic/services/ReportService';
import { useMessage } from 'lib/hooks';

const StatisticReportsArticlesInProjects = ({ setIsDownloadModal }) => {
    const { addError } = useMessage();

    const [isLoading, setIsLoading] = useState(false);

    const [reports, setReports] = useState([]);
    const [reportsTotal, setReportsTotal] = useState(0);
    const [sortByOptions, setSortByOptions] = useState([]);

    const getArticlesInProjectsReports = async (page = 0, size = 50, filters = {}) => {
        setIsLoading(true);

        try {
            const res = await api.reports.getArticles({ page, size, filters, cancelToken: ReportService.cancelToken.token });

            setReports(
                res.articles.content.map((i) => {
                    return {
                        ...i,
                        projectPath: i.projectPath,
                        createDate: i.createDate,
                        status: i.state === 'ACTIVE' ? 'Активные' : i.state === 'ARCHIVED' ? 'Архивные' : 'Удаленные',
                    };
                })
            );
            setReportsTotal(res.articles.totalElements);

            if (!res.articles.totalElements) {
                addError('По вашему запросу нет данных');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const onDownload = async (filters) => {
        try {
            await api.reports.downloadReports('articles', filters, ReportService.cancelToken.token);
            setIsDownloadModal(true);
        } catch (error) {
            addError('Произошла ошибка при скачивании отчета');
        }
    };

    const columnsTmp = useMemo(() => {
        const data = [
            {
                Header: 'Проект',
                accessor: 'projectTitle',
            },
            {
                Header: 'Название статьи',
                accessor: 'title',
                Cell: (data) => {
                    return (
                        <>
                            {data.row.original.state === 'DELETED' && <div>{data.row.original.title}</div>}
                            {data.row.original.state !== 'DELETED' && (
                                <Link
                                    className={cx.link}
                                    to={
                                        (data.row.original.state === 'ARCHIVED' ? '/archive/article/' : '/projects/article/') +
                                        data.row.original.uuid
                                    }
                                >
                                    {data.row.original.title}
                                </Link>
                            )}
                        </>
                    );
                },
            },
            {
                Header: 'Дата создания',
                accessor: 'createDate',
                Cell: (data) => ReportService.defaultDateColumn('createDate')(data),
            },
            {
                Header: 'Автор',
                accessor: 'author',
                Cell: (data) => {
                    return data.row.original.activeAuthor ? (
                        <Link className={cx.link} to={'/edit-user/' + data.row.original.login}>
                            {data.row.original.author}
                        </Link>
                    ) : (
                        data.row.original.author
                    );
                },
            },
            {
                Header: 'Статус',
                accessor: 'status',
            },
        ];

        setSortByOptions(
            data
                .filter((col) => col.accessor && col.isVisible !== false)
                .map((column) => ({ label: column.Header, value: column.accessor }))
        );

        return data;
    }, []);

    const filtersTmp = useMemo(() => {
        return [
            {
                fields: [
                    {
                        labels: ['Дата создания статьи', 'От', 'До'],
                        name: 'createTime',
                        type: 'date-range',
                        default: {
                            type: {
                                label: 'За сегодня',
                                value: 1,
                            },
                            from: new Date(new Date().setHours(0, 0, 0)).toISOString(),
                            to: new Date(new Date().setHours(23, 59, 59)).toISOString()
                        },
                    },
                ],
                width: '100%',
            },
            {
                label: 'Проекты',
                fields: [
                    {
                        name: 'projectPaths',
                        type: 'project',
                        withSections: false,
                        withArticles: false,
                        checkArticleExists: true,
                        default: null,
                    },
                ],
                width: '100%',
            },
            {
                label: 'Автор статьи',
                fields: [
                    {
                        name: 'authors',
                        type: 'user-select',
                        default: null,
                    },
                ],
                width: '100%',
            },
            {
                label: 'Статус статьи',
                fields: [
                    {
                        name: 'status',
                        type: 'select',
                        options: documentStatusOptions,
                        default: documentStatusOptions[0],
                    },
                ],
                width: '50%',
            },
            {
                label: 'Сортировать по',
                fields: [
                    {
                        name: 'sortBy',
                        type: 'select',
                        options: sortByOptions,
                        default: { label: 'Дата создания', value: 'createDate' },
                    },
                ],
                width: '25%',
            },
            {
                label: 'Порядок',
                fields: [
                    {
                        name: 'sort',
                        type: 'select',
                        options: sortTypesOptions,
                        default: sortTypesOptions[0],
                    },
                ],
                width: '25%',
            },
        ];
    }, [sortByOptions]);

    useEffect(() => {
        ReportService.init('ARTICLES_IN_PROJECTS');

        return () => {
            ReportService.destroy();
        };
    }, []);

    return (
        <ReportsComponent
            isEmpty={!reportsTotal}
            isLoading={isLoading}
            columns={columnsTmp}
            data={reports}
            total={reportsTotal}
            filters={filtersTmp}
            onDownload={onDownload}
            onPaginate={getArticlesInProjectsReports}
        />
    );
};

export default StatisticReportsArticlesInProjects;
