import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUsers } from 'slice/authSlice';
import classNames from 'classnames';
import Modal from 'react-modal';
import { v1 } from 'uuid';
import { setLastLockUser } from 'slice/appSlice';
import { selectSession } from 'slice/authSlice';
import LockTree from 'components/lock/lock-tree';
import Icon, { Icons } from 'uikit/icon';
import { Select } from 'uikit/select';
import Button from 'uikit/button';
import Loading from 'uikit/loading';
import RadioButton, { RadioGroup } from 'uikit/radio-button';
import api from 'api';
import cx from './lock.module.scss';
import IconButton from 'uikit/icon-button';
import { useDialog } from 'lib/hooks';
import Confirmation from 'components/confirmation';

const sorting = [
    {
        id: 1,
        label: 'По ролям',
        value: 'roles'
    },
    {
        id: 2,
        label: 'По алфавиту',
        value: 'alphabet'
    }
];
const sortingDirection = [
    {
        id: 1,
        label: 'По убыванию',
        value: true
    },
    {
        id: 2,
        label: 'По возрастанию',
        value: false
    }
];

const formatSearch = (search, text) => {
    if (!text) {
        return text;
    }

    const result = [];
    const data = text.replace(new RegExp(search, 'gi'), (text) => `<b>${text}</b>`);

    let str = '';

    for (let i = 0; i < data.length; i++) {
        if (data[i] === '<' && str[0] !== '<') {
            result.push(str);
            str = '';
        }

        str += data[i];

        if (data[i] === '>' && data[i - 2] === '/') {
            result.push(<b key={i}>{str.replace(/<\/?[^>]+>/g, '')}</b>);
            str = '';
        }

        if (i === data.length - 1) {
            result.push(str);
        }
    }

    return result;
};

const SearchResult = ({
    searchResult,
    onSearchSelect,
    search,
}) => {
    const groups = searchResult.filter((item) => !item.login);
    const users = searchResult.filter((item) => item.login);
    const searchResultBlocks = [
        {
            title: 'Группы',
            type: 'groups',
            items: groups,
            id: v1()
        },
        {
            title: 'Участники',
            type: 'users',
            items: users,
            id: v1()
        },
    ]

    return (
        <div className={cx.searchResult}>
            {
                searchResultBlocks
                    .filter(({ items }) => {
                        return items.length > 0;
                    })
                    .map(({ title, type, items, id }) => {
                        return (
                            <Fragment key={id}>
                                <p className={cx.title}>{title}</p>
                                <ul>
                                    {items.map((item) => {
                                        return (
                                            <li key={item['login'] ? item['login'] : item['id']}
                                                onClick={() => onSearchSelect(item)}>
                                                <div className={cx.avatar}>
                                                    {type === 'groups' ? (
                                                        <div className={cx.icon}>
                                                            <Icon type={Icons.GROUP} width={11} height={8}/>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {item['avatar'] &&
                                                            <img src={item['avatar']}
                                                                alt="" />}
                                                        </>
                                                    )}
                                                </div>
                                                <div>
                                                    <p>
                                                        {item['title']
                                                            ? formatSearch(search, item['title'])
                                                            : formatSearch(search, item['firstName'] + ' ' + item['lastName'])}
                                                    </p>
                                                    <span>{item['email'] ? formatSearch(search, item['email']) : ''}</span>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Fragment>
                        )
                    }
                )
            }
        </div>
    )
}

const Lock = ({
    isDocument = false,
    title = '',
    onClose = () => {},
    emptyTitle = '',
    emptyDescription = '',
    projectId = '',
    resourceId = '',
    resourceType = '',
    projectName,
    empty
}) => {
    const dispatch = useDispatch();
    const selector = useSelector(state => state);

    const { dialogState, openDialog, closeDialog } = useDialog();
    const [isLoading, setIsLoading] = useState(true);

    const session = useSelector(selectSession);
    const currentUserLogin = session?.login;

    const [users, setUsers] = useState([]);
    const [groups, setGroups] = useState([]);

    const [search, setSearch] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const [searchItem, setSearchItem] = useState(null);

    const [roles, setRoles] = useState([]);
    const [permittedRoles, setPermittedRoles] = useState([]);
    const [role, setRole] = useState(null);

    const [contentSorting, setContentSorting] = useState(sorting[0]);
    const [contentSortingDirection, setContentSortingDirection] = useState(sortingDirection[0]);
    const [contentSearch, setContentSearch] = useState(null);

    const [resourceUsersData, setResourceUsersData] = useState(null);
    const [resourceUsers, setResourceUsers] = useState(null);

    const [resourceGroupsData, setResourceGroupsData] = useState(null);
    const [resourceGroups, setResourceGroups] = useState(null);

    const [lockTree, setLockTree] = useState(null);

    const [isConflict, setIsConflict] = useState(false);
    const [conflictItem, setConflictItem] = useState(null);
    const [conflictRole, setConflictRole] = useState(null);
    const [conflictType, setConflictType] = useState(0);

    const getResourceUsers = async (resourceId, resourceType, currentUserLogin, roles, search, sort, sortDirection) => {
        let resourceUsers = await api.role.getResourceUsers(resourceId, resourceType);
        setResourceUsersData(Object.assign([], resourceUsers));

        resourceUsers = resourceUsers.sort((a, b) => {
            if (a.object.login === currentUserLogin) {
                return -1;
            } else if (b.object.login === currentUserLogin) {
                return 1;
            }

            if (sort.value === 'roles') {
                if (sortDirection.value) {
                    return a.role.id < b.role.id ? 1 : -1;
                } else {
                    return a.role.id < b.role.id ? -1 : 1;
                }
            } else if (sort.value === 'alphabet') {
                if (sortDirection.value) {
                    return a.object.firstName + a.object.lastName < b.object.firstName + b.object.lastName ? 1 : -1;
                } else {
                    return a.object.firstName + a.object.lastName < b.object.firstName + b.object.lastName ? -1 : 1;
                }
            }

            return 0;
        });

        if (search !== null) {
            resourceUsers = resourceUsers.filter((resourceUser) => {
                return (resourceUser.object.firstName + ' ' + resourceUser.object.lastName).toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1
                    || (resourceUser.object.lastName + ' ' + resourceUser.object.firstName).toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1
                    || (resourceUser.object.login.toLowerCase().indexOf(search.toLowerCase()) !== -1);
            });
        }

        setResourceUsers(resourceUsers);
    };
    const getResourceGroups = async (resourceId, resourceType, currentUserLogin, roles, search, sort, sortDirection) => {
        let resourceGroups = await api.role.getResourceGroups(resourceId, resourceType);
        setResourceGroupsData(Object.assign([], resourceGroups));

        resourceGroups = resourceGroups?.sort((a, b) => {
            if (sort.value === 'roles') {
                const aRole = roles.find((role) => a.role.name === role.value);
                const bRole = roles.find((role) => b.role.name === role.value);

                if (sortDirection.value) {
                    return aRole?.id < bRole?.id ? 1 : -1;
                } else {
                    return aRole?.id < bRole?.id ? -1 : 1;
                }
            } else if (sort.value === 'alphabet') {
                if (sortDirection.value) {
                    return a.object.name < b.object.name ? 1 : -1;
                } else {
                    return a.object.name < b.object.name ? -1 : 1;
                }
            }

            return 0;
        });

        if (search !== null) {
            resourceGroups = resourceGroups.filter((resourceGroup) => {
                return resourceGroup.object.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
            });
        }

        setResourceGroups(resourceGroups);
    };

    const onSearch = (e) => {
        const searchResult = [];

        users.map((user) => {
            if (!e.target.value) {
                return user;
            }

            if (user['login'] !== session['login']
                && ((user['firstName'] && user['firstName'].toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1)
                    || (user['lastName'] && user['lastName'].toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1)
                    || (user['email'] && user['email'].toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1)
                    || ((user['firstName'] && user['lastName']) && (user['firstName'] + ' ' + user['lastName'])
                        .toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1)
                    || ((user['firstName'] && user['lastName']) && (user['lastName'] + ' ' + user['firstName'])
                        .toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1)
                    || (user['login'] && user['login'].toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1))) {
                searchResult.push(user);
            }

            return user;
        });

        groups.forEach((group) => {
            if (!e.target.value) {
                return group;
            }

            if (group.title.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1) {
                searchResult.push(group);
            }
        });

        setSearch(e.target.value);
        setSearchResult(searchResult);

        onContentFilter(contentSorting, contentSortingDirection, e.target.value);
        setContentSearch(null);
    };
    const onSearchSelect = (searchItem) => {
        setSearch('');
        setSearchResult([]);
        setSearchItem(searchItem);
        let searchItemValue = null;
        if (searchItem) {
            if (searchItem.login) {
                searchItemValue = `${searchItem.firstName ?? ''} ${searchItem.lastName ?? ''}`
            };
            if (searchItem.title) {
                searchItemValue = searchItem.title;
            }
        }

        onContentFilter(contentSorting, contentSortingDirection, searchItemValue, searchItem);
        setContentSearch(null);
    };
    // const onSearchClear = () => {
    //     setSearch('');
    //     setSearchResult([]);

    //     onContentFilter(contentSorting, contentSortingDirection, searchItem ? searchItem.firstName + ' ' + searchItem.lastName : null);
    //     setContentSearch(null);
    // };

    const onAddRole = async (item, role, type) => {
        setIsLoading(true);

        if (item['login']) {
            await api.role.addUserPermissions(resourceType, resourceId, item['login'], type, role['id']);
        } else {
            await api.role.addGroupPermissions(resourceType, resourceId, item['id'], type, role['id']);
        }

        dispatch(setLastLockUser({
            isFullAccess: true,
            resourceId: resourceId,
            resourceType: resourceType,
            roleId: role['id']
        }));
        setSearchItem(null);

        await getResourceUsers(resourceId, resourceType, currentUserLogin, roles, contentSearch, contentSorting, contentSortingDirection);
        await getResourceGroups(resourceId, resourceType, currentUserLogin, roles, contentSearch, contentSorting, contentSortingDirection);

        setIsLoading(false);
    };
    const onGroupToggle = (groupId) => {
        const groups = resourceGroups.map((group) => {
            if (group['object']['id'] === groupId) {
                group['isOpen'] = group['isOpen'] ? !group['isOpen'] : true;
            }
            return group;
        });
        setResourceGroups(groups);
    };

    const removeRole = async (item) => {
        closeDialog()
        setIsLoading(true);

        if (item['login']) {
            await api.project.removePermissionsToResource(resourceId, [item['id']], []);
        } else {
            await api.project.removePermissionsToResource(resourceId, [], [item['id']]);
        }

        await getResourceUsers(resourceId, resourceType, currentUserLogin, roles, contentSearch, contentSorting, contentSortingDirection);
        await getResourceGroups(resourceId, resourceType, currentUserLogin, roles, contentSearch, contentSorting, contentSortingDirection);

        setSearchItem(null);
        setIsLoading(false);
    }
    const onRemoveRole = async (item) => {
        openDialog({
            title: 'Доступ',
            contentType: 'TEXT',
            text: item.name
                ? 'Вы действительно хотите закрыть группе участников ' + item.name + ' доступ к ресурсу ' + projectName + '?'
                : 'Вы действительно хотите закрыть участнику ' + item.firstName + ' ' + item.lastName + ' доступ к ресурсу ' + projectName + '?',
            color: 'red',
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Да, закрыть доступ',
            onSubmit: () => removeRole(item),
            onClose: () => closeDialog(),
        })

    };

    const onContentFilter = (sort, sortDirection, search, searchItem = null) => {
        let users = Object.assign([], resourceUsersData);
        let groups = Object.assign([], resourceGroupsData);

        users = users.sort((a, b) => {
            if (session && a.object.login === session.login) {
                return -1;
            } else if (session && b.object.login === session.login) {
                return 1;
            }

            if (sort.value === 'roles') {
                const aRole = roles.find((role) => a.role.name === role.value);
                const bRole = roles.find((role) => b.role.name === role.value);

                if (sortDirection.value) {
                    return aRole?.id < bRole?.id ? 1 : -1;
                } else {
                    return aRole?.id < bRole?.id ? -1 : 1;
                }
            } else if (sort.value === 'alphabet') {
                if (sortDirection.value) {
                    return a.object.firstName + a.object.lastName < b.object.firstName + b.object.lastName ? 1 : -1;
                } else {
                    return a.object.firstName + a.object.lastName < b.object.firstName + b.object.lastName ? -1 : 1;
                }
            }

            return 0;
        });
        groups = groups.sort((a, b) => {
            if (sort.value === 'roles') {
                const aRole = roles.find((role) => a.role.name === role.value);
                const bRole = roles.find((role) => b.role.name === role.value);

                if (sortDirection.value) {
                    return aRole?.id < bRole?.id ? 1 : -1;
                } else {
                    return aRole?.id < bRole?.id ? -1 : 1;
                }
            } else if (sort.value === 'alphabet') {
                if (sortDirection.value) {
                    return a.object.name < b.object.name ? 1 : -1;
                } else {
                    return a.object.name < b.object.name ? -1 : 1;
                }
            }

            return 0;
        });
        const groupsToToggle = {};

        if (search !== null) {
            users = !searchItem?.title
                ? users.filter(({ object: { firstName, lastName, login } }) => {
                    return [`${firstName} ${lastName}`, login]
                        .map(item => item.toLowerCase())
                        .some(item => item === search.toLowerCase());
                  })
                : [];
            groups = groups.filter(({object: { name, members, id }}) => {
                const nameIncludes = searchItem?.title ? name.toLowerCase().includes(search.toLowerCase()) : false;
                const membersIncludes = members.some(({ firstName, lastName, login }) => {
                    return [`${firstName} ${lastName}`, login]
                        .map(item => item.toLowerCase())
                        .some(item => item === search.toLowerCase());
                });
                if (membersIncludes) {
                    groupsToToggle[id] = true;
                }
                return nameIncludes || membersIncludes;
            });
        };

        // const usersFromGroups = !searchItem?.title ? groups.reduce((acc, cur) => {
        //     return acc
        //         .concat(cur.object.members)
        //         .filter(({ firstName, lastName }) => {
        //             return search ? `${firstName} ${lastName}`.toLowerCase() === search.toLowerCase() : false;
        //         })
        //         .map((member) => {
        //             return {
        //                 object: member,
        //                 role: cur.role
        //             }
        //         })
        //         .filter(({ object: { id } }) => {
        //             return !users.find(({ object: { id: userId } }) => userId === id)
        //         })
        // }, []) : [];

        // setResourceUsers(search ? [...users, ...usersFromGroups] : resourceUsersData);
        setResourceUsers(search ? [...users] : resourceUsersData);

        setResourceGroups(search ? groups.map((group) => {
            const updatedObject = {
                ...group.object,
                members: search
                    ? searchItem?.title
                        ? group.object.members
                        : group.object.members.filter(({ firstName, lastName }) => {
                            return `${firstName} ${lastName}`.toLowerCase() === search.toLowerCase();
                        })
                    : resourceGroupsData.find(({ object: { id } }) => {
                        return id === group.object.id
                    })?.object?.members ?? []
            };

            return {
                ...group,
                object: updatedObject,
                isOpen: Object.keys(groupsToToggle).includes(String(group.object.id))
            }
        }) : resourceGroupsData);

        setContentSorting(sort);
        setContentSortingDirection(sortDirection);
        setContentSearch(search);
    };

    const checkConflict = async (item, role) => {
        if (resourceType === 'ARTICLE' || resourceType === 'NEWS' || empty) {
            onAddRole(item, role, 2);
            return;
        }

        if (item['login'] && (
            (resourceUsersData.find((user) => user['object']['login'] === item['login']) !== undefined) ||
            (
                resourceGroupsData.find(({ object: { members } }) => {
                    return members.some(({ login }) => login === item['login'])
                })
            ))
        ) {
            setConflictItem(item);
            setConflictRole(role);

            setIsConflict(true);
            return;
        } else if (item['id'] && resourceGroupsData.find((group) => group['object']['id'] === item['id']) !== undefined) {
            setConflictItem(item);
            setConflictRole(role);

            setIsConflict(true);
            return;
        }

        onAddRole(item, role, 2);
    };
    const onConflictAccept = async () => {
        setIsConflict(false);
        onAddRole(conflictItem, conflictRole, conflictType);
        setConflictType(0);
    };

    useEffect(() => {
        const fetchData = async () => {
            const users = await selectUsers(selector);
            setUsers(users.content.map((user) => {
                return {
                    id: user['id'],
                    login: user['login'],
                    avatar: user['avatar'] ? api.upload.getImage(user['avatar'], false, 128) : '',
                    firstName: user['firstName'],
                    lastName: user['lastName'],
                    email: user['email']
                };
            }));

            const groups = await api.group.getGroups(0, 2000);
            setGroups(groups.data.content.map((group) => {
                const users = group.members ? group.members.map((user) => {
                    return {
                        id: user['id'],
                        login: user['login'],
                        avatar: user['avatar'] ? api.upload.getImage(user['avatar'], false, 128) : '',
                        firstName: user['firstName'],
                        lastName: user['lastName'],
                        email: user['email']
                    };
                }) : [];
                return {
                    id: group['id'],
                    title: group['name'],
                    description: group['description'],
                    users: users
                };
            }));
        };
        if (users.length === 0) {
            fetchData();
        }
    }, [projectId, resourceId, selector, users]);
    useEffect(() => {
        if (roles.length === 0) {
            return;
        }

        const fetchData = async () => {
            await getResourceUsers(resourceId, resourceType, currentUserLogin, roles, null, sorting[0], sortingDirection[0]);
            await getResourceGroups(resourceId, resourceType, currentUserLogin, roles, null, sorting[0], sortingDirection[0]);

            setIsLoading(false);
        };
        fetchData();
    }, [resourceId, resourceType, currentUserLogin, roles]);
    useEffect(() => {
        const fetchData = async () => {
            let roles = await api.customRoles.getRoleList();
            roles = roles.map((item) => {
                return { id: item['id'], label: item['title'], value: item['name'] };
            });

            let permittedRoles = await api.role.getPermittedRoles(resourceId || projectId, currentUserLogin);
            permittedRoles = permittedRoles.map((item) => {
                return { id: item['id'], label: item['title'], value: item['name'] };
            });

            setRoles(roles);
            setPermittedRoles(permittedRoles);
            setRole(permittedRoles[0]);
        };
        fetchData();
    }, [resourceId, projectId, currentUserLogin]);

    const getWarning = (searchItem) => {
        // console.log('lock getWarning searchItem', searchItem);
        // console.log('lock getWarning resourceGroups', resourceGroups);
        // console.log('lock getWarning resourceUsers', resourceUsers);
        if (searchItem && (
            resourceGroups.filter((group) => searchItem.id === group.object.id).length !== 0
        )) {
            return 'Внимание! У этой группы уже есть доступы'
        }
        if (searchItem && (
            resourceGroups.filter(({ object: { members } }) => {
                return members.some((member) => searchItem.id === member.id)
            }).length !== 0
        ) && (resourceUsers.filter((user) => searchItem.id === user.object.id).length !== 0)) {
            return 'Внимание! У этого участника уже есть индивидуальный и групповой доступ'
        }
        if (searchItem && (
            resourceGroups.filter(({ object: { members } }) => {
                return members.some((member) => searchItem.id === member.id)
            }).length !== 0
        )) {
            return 'Внимание! У этого участника уже есть доступ в составе группы'
        }
        if (searchItem && (
            resourceUsers.filter((user) => searchItem.id === user.object.id).length !== 0
        )) {
            return 'Внимание! У этого участника уже есть доступы'
        }
        return null;
    }

    return (
        <Modal isOpen={true} className={cx.lock} overlayClassName={cx.lockOverlay}>
            <Confirmation {...dialogState} />

            <Modal isOpen={isConflict} className={cx.lockConflictModal} overlayClassName={cx.lockOverlay}>
                <div className={cx.lockConflictModalHeader}>
                    <h3>Конфликт ролей</h3>
                    <IconButton icon={<Icon type={Icons.CROSS} width={14} height={14}/>}
                                onClick={() => setIsConflict(false)}/>
                </div>
                <div className={cx.lockConflictModalBody}>
                    <p className={cx.lockConflictModalBodyDescription}>Выберите вариант перезаписи ролей:</p>
                    <RadioGroup value={conflictType} onChange={v => setConflictType(v)}>
                        <RadioButton className={cx.lockConflictModalBodyItem} value={0} label={'Применить доступ только для корня ресурса'}
                                     desc={'Доступ будет применен только для корневой директории. Содержимое раздела останется без изменений'}/>
                        <RadioButton className={cx.lockConflictModalBodyItem} value={1} label={'Заменить только существующие доступы участника'}
                                     desc={'Будут изменены доступы только к тем подразделам и документам, к которым участник уже имеет доступ'}/>
                        <RadioButton className={cx.lockConflictModalBodyItem} value={2} label={'Перезаписать роли всего раздела и его содержимого'}
                                     desc={'При полной перезаписи доступа к разделу или проекту, участник получает доступ к разделу и всему его содержимому относительно выбранной роли'}/>
                    </RadioGroup>
                </div>
                <div className={cx.lockConflictModalFooter}>
                    <Button label="Отмена" onClick={() => setIsConflict(false)}/>
                    <Button label="Подтвердить" color="green" onClick={onConflictAccept}/>
                </div>
            </Modal>
            {lockTree &&
            <LockTree projectId={projectId} resourceId={resourceId} resourceType={resourceType}
                      objectId={lockTree['id']} objectRoleId={lockTree?.['role']?.['id']}
                      objectRoleLabel={lockTree['role']?.['label']} objectTitle={lockTree['title']}
                      objectAvatar={lockTree['avatar']} onClose={() => setLockTree(null)}/>}
            <div className={cx.header}>
                <div className={cx.top}>
                    <h3>{title}</h3>
                    <IconButton icon={<Icon type={Icons.CROSS} width={14} height={14}/>}
                                onClick={onClose}/>
                </div>
                <div className={cx.bottom}>
                    <div className={cx.search}>
                        {!searchItem &&
                        <input type="text" placeholder="Имя, email или группа" value={search}
                               onChange={(e) => onSearch(e)}/>}
                        {searchItem &&
                        <div className={cx.resultUser}>
                            <div className={cx.avatar}>
                                {searchItem.title ? (
                                    <div className={cx.icon}>
                                        <Icon type={Icons.GROUP} width={11} height={8}/>
                                    </div>
                                ) : (
                                    <>
                                        { searchItem['avatar'] && <img src={searchItem['avatar']} alt="" /> }
                                    </>
                                )}
                            </div>
                            <p>
                                {searchItem['title']
                                    ? searchItem['title']
                                    : searchItem['firstName'] + ' ' + searchItem['lastName']}
                            </p>
                            <IconButton icon={<Icon type={Icons.CROSS} width={6} height={6}/>}
                                        onClick={() => onSearchSelect(null)}/>
                        </div>}
                        {searchResult.length !== 0 && (
                            <SearchResult
                                searchResult={searchResult}
                                onSearchSelect={onSearchSelect}
                                search={search}
                            ></SearchResult>
                        )}
                        <Select className={cx.roles} options={permittedRoles} value={role}
                                onChange={(item) => setRole(item)}/>
                    </div>
                    <Button label="Дать доступ" color="green" className={cx.button} disabled={!searchItem}
                            onClick={() => checkConflict(searchItem, role)}/>
                </div>
                <p className={cx.warning}>{getWarning(searchItem)}</p>
            </div>
            <div className={cx.body} /* onClick={() => onSearchClear()} */>
                {isLoading &&
                <div className={cx.lockLoader}>
                    <Loading withOverlay={false}/>
                </div>}
                {!contentSearch && (resourceUsersData && resourceUsersData.length === 0 && resourceGroupsData
                    && resourceGroupsData.length === 0) &&
                <div className={cx.empty}>
                    <Icon type={Icons.LOCK} tooltip={'Доступы'} width={28} height={40}/>
                    <h4>{emptyTitle}</h4>
                    <p>{emptyDescription}</p>
                </div>}
                {(contentSearch || ((resourceUsersData && resourceUsersData.length !== 0) || (resourceGroupsData
                    && resourceGroupsData.length !== 0))) &&
                <div className={cx.filter}>
                    <div className={cx.sort} onClick={(e) => e.stopPropagation()}>
                        <span>Сортировать:</span>
                        <Select options={sorting} value={contentSorting}
                                onChange={(value) => onContentFilter(value, contentSortingDirection, contentSearch)}
                                style={{menuWidth: '120px'}} showCaret={false}/>
                        <div className={cx.sortSeparator}/>
                        <Select options={sortingDirection} value={contentSortingDirection}
                                onChange={(value) => onContentFilter(contentSorting, value, contentSearch)}
                                style={{menuWidth: '140px'}} showCaret={false}/>
                    </div>
                </div>}
                {resourceGroups && resourceGroups.length === 0 && resourceUsers && resourceUsers.length === 0 &&
                <div className={cx.searchEmpty}>
                    <Icon type={Icons.SEARCH} width={18} height={18}/>
                    <p>Поиск не дал результатов</p>
                </div>}
                {resourceGroups && resourceGroups.length !== 0 &&
                <div className={cx.groups}>
                    <h4>Группы</h4>
                    {resourceGroups.map((group) => {
                        const isPermitted = permittedRoles.find((role) => role.value === group['role']['name']) === undefined;
                        return (
                            <div key={group['object']['id']}
                                 className={classNames(cx.group, group['isOpen'] && cx.groupOpen)}>
                                <div className={cx.row}>
                                    <div className={cx.left} onClick={() => onGroupToggle(group['object']['id'])}>
                                        <Icon type={Icons.ARROW} width={10} height={6} className={cx.arrow}/>
                                        <div className={cx.icon}>
                                            <Icon type={Icons.GROUP} width={11} height={8}/>
                                        </div>
                                        <p>{group['object']['name']}</p>
                                    </div>
                                    <div className={cx.right}>
                                        <Select className={classNames(cx.roles, isPermitted ? cx.disabled : '')}
                                                options={isPermitted ? roles : permittedRoles}
                                                value={roles.find((role) => role.value === group['role']['name'])}
                                                onChange={(role) => checkConflict(group['object'], role)}
                                                disabled={isPermitted}/>
                                        <div className={cx.separator}/>
                                        {!isDocument &&
                                        <Icon type={Icons.EYE} width={16} height={16} className={cx.treeIcon}
                                              onClick={() => {
                                                  setLockTree({
                                                      'id': group['object']['id'],
                                                      'title': group['object']['name'],
                                                      'avatar': '',
                                                      'role': roles.find((role) => {
                                                          return role.value === group['role']['name'];
                                                      })
                                                  });
                                              }}/>}
                                        <IconButton icon={<Icon type={Icons.TRASH} tooltip={'Удалить'} width={16} height={16} />}
                                                    onClick={() => onRemoveRole(group['object'])} hoverColor={'red'}/>
                                    </div>
                                </div>
                                {group['isOpen'] &&
                                <div className={cx.items}>
                                    {group['object']['members'] && group['object']['members'].map((user) => {
                                        return (
                                            <div key={user['id']} className={cx.item}>
                                                <div className={cx.avatar}>
                                                    {user['avatar'] &&
                                                    <img src={api.upload.getImage(user['avatar'], false, 128)}
                                                         alt="" />}
                                                </div>
                                                <p>{user['firstName'] + ' ' + user['lastName']}</p>
                                            </div>
                                        );
                                    })}
                                </div>}
                            </div>
                        );
                    })}
                </div>}
                {resourceUsers && resourceUsers.length !== 0 &&
                <div className={cx.users}>
                    <h4>Участники</h4>
                    {resourceUsers.map((user) => {
                        const isPermitted = permittedRoles.find((role) => role.value === user['role']['name']) === undefined;

                        return (
                            <div key={user['object']['login']} className={cx.user}>
                                <div className={cx.left}>
                                    <div className={cx.avatar}>
                                        {user['object']['avatar'] &&
                                        <img src={api.upload.getImage(user['object']['avatar'], false, 128)}
                                             alt="" />}
                                    </div>
                                    <p>{user['object']['firstName'] + ' ' + user['object']['lastName']}</p>
                                </div>
                                <div className={cx.right}>
                                    {session && session['login'] !== user['object']['login'] && !isPermitted &&
                                    <Select className={classNames(cx.roles, isPermitted ? cx.disabled : '')}
                                            options={isPermitted ? roles : permittedRoles}
                                            value={roles.find((role) => role.value === user['role']['name'])}
                                            onChange={(role) => checkConflict(user['object'], role)}
                                            disabled={isPermitted}/>
                                        }
                                    {session && session['login'] !== user['object']['login'] && !user['object']['root'] && isPermitted && (
                                        <span>{user['role']['title']}</span>
                                    )}
                                    {session && (session['login'] === user['object']['login'] || user['object']['root']) && (
                                        <span>{roles.find((role) => role.value === user['role']['name']).label}</span>
                                    )}
                                    {session && session['login'] !== user['object']['login'] && !user['object']['root'] && (
                                        <div className={cx.separator}/>
                                    )}
                                    {!isDocument && session && session['login'] !== user['object']['login'] && !user['object']['root'] && (
                                        <Icon type={Icons.EYE} width={16} height={16} className={cx.treeIcon}
                                              onClick={() => {
                                                  setLockTree({
                                                      'id': user['object']['login'],
                                                      'title': user['object']['firstName'] + ' ' + user['object']['lastName'],
                                                      'avatar': user['object']['avatar']
                                                          ? api.upload.getImage(user['object']['avatar'], false, 128)
                                                          : '',
                                                      'role': {
                                                          'id': user['role']['id'],
                                                          'label': user['role']['title'],
                                                          'value': user['role']['name']
                                                      }
                                                  });
                                              }}/>
                                    )}
                                    {session && session['login'] !== user['object']['login'] && !user['object']['root'] && (
                                        <IconButton icon={<Icon type={Icons.TRASH} tooltip={'Удалить'} width={16} height={16} />}
                                                    onClick={() => onRemoveRole(user['object'])} hoverColor={'red'}/>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>}
            </div>
            <div className={cx.footer}>
                <Button label="Готово" onClick={onClose}/>
            </div>
        </Modal>
    );
};

export default Lock;
