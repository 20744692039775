import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useGlobalContext } from 'lib/hooks';
import { desktopStyles, mobileStyles } from 'lib/util/modalStyles';
import classNames from 'classnames';
import cx from './selection-users.module.scss';
import IconButton from 'uikit/icon-button';
import Icon, { Icons } from 'uikit/icon';
import Divider from 'uikit/divider';
import Checkbox from 'uikit/checkbox/checkbox';
import api from 'api/index';
import Button from 'uikit/button';
import Select, { components } from 'react-select';

const ValueContainer = (props) => {
    const { label } = props.selectProps;

    return (
        <components.ValueContainer {...props}>
            <label className={cx.trainingAccessModalBodyFiltersLabel}>{label}</label>
            {props.selectProps.value && props.selectProps.value.length > 0 &&
            <span className={cx.trainingAccessModalBodyFiltersSpan}>{props.selectProps.value.length}</span>}
        </components.ValueContainer>
    );
};

const SelectionUsers = ({ isOpen = true, close, onChange, value }) => {
    const { platform } = useGlobalContext();

    const [filtersUsers, setFiltersUsers] = useState([]);
    const [search, setSearch] = useState('');

    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);

    const [offices, setOffices] = useState([]);
    const [selectedOffices, setSelectedOffices] = useState([]);

    const [position, setPosition] = useState([]);
    const [selectedPosition, setSelectedPosition] = useState([]);

    const [role, setRole] = useState([]);
    const [selectedRole, setSelectedRole] = useState([]);

    const [isFilter, setIsFilter] = useState(false);
    const [selectFilterUser, setSelectFilterUser] = useState([]);

    useEffect(() => {
        if (selectedRole.length !== 0 || selectedOffices.length !== 0 || selectedGroups.length !== 0 || selectedPosition.length !== 0) {
            setIsFilter(true);
        } else {
            setIsFilter(false)
        }
    }, [selectedRole, selectedOffices, selectedGroups, selectedPosition])

    // const MenuList = (props) => {
    //
    //     const _select = (item, index) => {
    //         if (item.type === 'role') {
    //             let _selectedRole = JSON.parse(JSON.stringify(selectedRole));
    //
    //             _selectedRole.splice(index, 1);
    //
    //             setSelectedRole(_selectedRole);
    //         } else if (item.type === 'group') {
    //             let _selectedGroups = JSON.parse(JSON.stringify(selectedGroups));
    //
    //             _selectedGroups.splice(index, 1);
    //
    //             setSelectedGroups(_selectedGroups);
    //         } else if (item.type === 'office') {
    //             let _selectedOffices = JSON.parse(JSON.stringify(selectedOffices));
    //
    //             _selectedOffices.splice(index, 1);
    //
    //             setSelectedOffices(_selectedOffices);
    //         } else if (item.type === 'position') {
    //             let _selectedPosition = JSON.parse(JSON.stringify(selectedPosition));
    //
    //             _selectedPosition.splice(index, 1);
    //
    //             setSelectedPosition(_selectedPosition);
    //         }
    //
    //         props.selectProps.onMenuClose();
    //     }
    //
    //     return (
    //         <components.MenuList {...props}>
    //             {
    //                 props.selectProps.value.map((item, i) => {
    //                     return (
    //                         <div className={cx.customMenuListItem} onClick={() => _select(item, i)} >
    //                             <Checkbox className={cx.checkbox} label={item.label} checked={true} />
    //                         </div>
    //                     )
    //                 })
    //             }
    //             {props.children}
    //         </components.MenuList>
    //     );
    // };

    const Option = (props) => {
        return (
            <components.Option {...props}>
                <Checkbox className={cx.checkbox} checked={props.isSelected} onChange={() => null} label={props.children} />
            </components.Option>
        );
    };

    useEffect(() => {
        const getUsers = async () => {
            let users = await api.user.getUsers(0, 9999);

            setUsers(users.data.content);
            setFiltersUsers(users.data.content);
        };
        if (users.length === 0) {
            getUsers();
        }
    }, [users]);

    useEffect(() => {
        setSelectedUsers(value);
    }, [value])

    useEffect(() => {
        const fetchData = async () => {
            const officesResponse = await api.office.getOffices(0, 2000);
            setOffices(officesResponse.content.map((office, i) => {
                return { label: office.name, value: office.id, i: i, type: 'office' };
            }));

            const groupsResponse = await api.groups.getGroups(0, 2000);
            setGroups(groupsResponse.data.content.map((group, i) => {
                return { label: group.name, value: group.id, i: i, type: 'group' };
            }));

            const rolesResponse = await api.customRoles.getRoleList();
            setRole(rolesResponse.filter(role => !role.name.includes('system-')).map((role, i) => {
                return { label: role.title, value: role.id, i: i, type: 'role' };
            }));

            const positionResponse = await api.userPosition.getUserPosition(0, 2000);
            setPosition(positionResponse.content.map((position, i) => {
                return { label: position.title, value: position.id, i: i, type: 'position' };
            }));
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const groupsData = await Promise.all(selectedGroups.map(async (group) => {
                return await api.groups.getGroupById(group.value);
            }));

            setFiltersUsers(users.filter((user) => {
                if ((user.firstName === null || user.firstName.toLowerCase().indexOf(search.toLowerCase()) === -1)
                    && (user.lastName === null || user.lastName.toLowerCase().indexOf(search.toLowerCase()) === -1)
                    && (user.middleName === null || user.middleName.toLowerCase().indexOf(search.toLowerCase()) === -1)
                    && (user.login === null || user.login.toLowerCase().indexOf(search.toLowerCase()) === -1)
                    && (user.firstName === null || user.lastName === null
                        || (user.firstName + ' ' + user.lastName).toLowerCase().indexOf(search.toLowerCase()) === -1)) {
                    return false;
                }

                if (selectedOffices.length !== 0) {
                    if (user.office === null) {
                        return false;
                    }

                    if (selectedOffices.findIndex((office) => office.label === user.office) === -1) {
                        return false;
                    }
                }

                if (selectedGroups.length !== 0) {
                    if (groupsData.findIndex((group) => group.members.findIndex((member) => member.login === user.login) !== -1) === -1) {
                        return false;
                    }
                }

                if (selectedRole.length !== 0) {
                    if (user.roleList === null) {
                        return false;
                    }

                    if (!selectedRole.some(role => user.roleList.some(i => i.id === role.value))) {
                      return false
                    }
                }

                if (selectedPosition.length !== 0) {

                    if (user.position === '') {
                        return false;
                    }

                    if (!selectedPosition.some(position => position.label === user.position)) {
                      return false;
                    }
                }

                return true;
            }));
        };
        fetchData();
    }, [users, search, selectedOffices, selectedGroups, selectedRole, selectedPosition]);

    const onUsersSearch = (e) => {
        const { target: { value } } = e;
        setSearch(value);
        const searchResult = [];
        const valueNames = value.split(" ").filter(item => item).map((val) => val.toLowerCase());

        users.map((user) => {
            if (!value) {
                setFiltersUsers(users);
            }

            const userNames = [user['firstName']?.toLowerCase()];
            if (user['lastName']){
                userNames.push(user['lastName']?.toLowerCase())
            };

            if (
                (valueNames.length > 1
                    // todo: в будущем более гибко переписать на какой-то цикл
                    ? ((valueNames[0] === userNames[0] && valueNames[1] === userNames[1])
                        || (valueNames[0] === userNames[1] && valueNames[1] === userNames[0]))
                    : valueNames.some((name) => userNames.includes(name)))
                || (user['lastName'] && user['lastName'].toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1)
                || (user['email'] && user['email'].toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1)) {
                searchResult.push(user);
            }

            return user;
        });

        setFiltersUsers(searchResult);

    };

    const customStyles = {
        indicatorSeparator: (_state) => {
            return { display: 'none' }
        },
        control: (_state) => {
            return {..._state, minWidth: 150 }
        },
        container: (_state) => {
            return {..._state, minWidth: 150 }
        },
        option: (_state) => {
            return {..._state, ':hover': {backgroundColor: 'white'}, backgroundColor: 'white', color: 'inherit', cursor: 'pointer' }
        }
    }

    const selectUser = (user) => {
        for (let i = 0; i < selectedUsers.length; i++) {
            if (selectedUsers[i]['id'] === user['id']) {
                selectedUsers.splice(i, 1);
                const tmp = JSON.stringify(selectedUsers);
                setSelectedUsers(JSON.parse(tmp));
                return;
            }
        }

        selectedUsers.push(user);
        const tmp = JSON.stringify(selectedUsers);
        setSelectedUsers(JSON.parse(tmp));
    };

    const selectAll = () => {
        if (users.length === filtersUsers.length) {

            if (users.length === selectedUsers.length) {
                setSelectedUsers([]);
            } else {
                const tmp = JSON.stringify(users)
                setSelectedUsers(JSON.parse(tmp));
            }

        } else {

            if (filtersUsers.length === selectFilterUser.length) {
                setSelectedUsers(selectedUsers.filter(n => !selectFilterUser.includes(n)));

            } else {
                setSelectedUsers(selectedUsers.concat(filtersUsers).reduce((a, b) => {
                    if (a[0].indexOf(b.login) < 0 ) {
                        a[1].push(b);
                        a[0].push(b.login);
                    }
                    return a;
                },[[], []])[1]);
            }
        }
    }

    useEffect(() => {
        if (isFilter) {
            let _selectFilterUser = [];

            for (let i = 0; i < filtersUsers.length; i++) {
                if (selectedUsers.findIndex(el => el.id === filtersUsers[i].id) !== -1) {
                    _selectFilterUser.push(selectedUsers.find((el) => {
                        if (el.id === filtersUsers[i].id) {
                            return el;
                        }

                        return null;
                    }));
                }
            }

            setSelectFilterUser(_selectFilterUser);
        }

    }, [selectedUsers, isFilter, filtersUsers])

    const isCheckedUser = (user) => {
        for (let i = 0; i < selectedUsers.length; i++) {
            if (selectedUsers[i]['id'] === user['id'])
                return true;
        }

        return false;
    };

    return (
        <Modal isOpen={isOpen} contentLabel={'Добавление в группу'}
               style={platform === 'mobile' ? mobileStyles : desktopStyles}>

            <div className={classNames(cx.modalContent, {[cx.modalMobile]: platform === 'mobile'})}>
                <div className={cx.title}>
                    <div>Выбор участников</div>
                    <IconButton icon={<Icon width={14} height={14} type={Icons.CROSS}/>} onClick={() => {
                        close();
                    }}/>
                </div>
                <div className={cx.searchContainer}>
                    <div className={cx.search}>
                        <div className={cx.searchButton} onClick={() => {}}>
                            <IconButton icon={<Icon type={Icons.SEARCH} width={13} height={13}/>}/>
                        </div>
                        <input type="text" placeholder={'Поиск по имени...'}
                               onChange={(e) => onUsersSearch(e)} value={search}/>
                    </div>
                </div>
                <Divider className={cx.divider}/>
                <div className={cx.container}>
                    <div className={cx.trainingAccessModalBody}>
                        <div className={cx.trainingAccessModalBodyFilters}>
                            <Select styles={customStyles} hideSelectedOptions={false} isMulti={true} label="Офисы" options={offices} value={selectedOffices}
                                    components={{ ValueContainer, Option }} onChange={(item) => setSelectedOffices(item ? item : [])}/>
                            <Select styles={customStyles} hideSelectedOptions={false} isMulti={true} label="Группы" options={groups} value={selectedGroups}
                                    components={{ ValueContainer, Option }} onChange={(item) => setSelectedGroups(item ? item : [])}/>
                            <Select styles={customStyles} hideSelectedOptions={false} isMulti={true} label="Роли" options={role} value={selectedRole}
                                    components={{ ValueContainer, Option }} onChange={(item) => setSelectedRole(item ? item : [])}/>
                            <Select styles={customStyles} hideSelectedOptions={false} isMulti={true} label="Должности" options={position} value={selectedPosition}
                                    components={{ ValueContainer, Option }} onChange={(item) => setSelectedPosition(item ? item : [])}/>
                        </div>
                    </div>
                    <div className={cx.selection}>
                        <div className={cx.left}>
                            Выбрано: {selectedUsers?.length} участника (-ов)
                        </div>
                        <div className={cx.right} onClick={selectAll}>
                            {
                                isFilter ?
                                    selectFilterUser.length !== filtersUsers.length ? 'Выбрать всех' : 'Убрать всех':
                                    users.length !== selectedUsers.length ? 'Выбрать всех' : 'Убрать всех'
                            }
                        </div>
                    </div>
                    <div className={cx.content}>
                        {filtersUsers.map((item, i) => (
                            <div key={i} onClick={() => selectUser(item)}
                                 className={cx.userContainer}>
                                <Checkbox onClick={() => selectUser(item)} checked={isCheckedUser(item)}/>
                                <div className={cx.userInfo}>
                                    <img src={item['avatar'] == null || item['avatar'] === ''
                                        ? '/img/avatar.png' : api.user.getUserSmallLogo(item['avatar'])} alt=""/>
                                    <p>{item['firstName']} {item['lastName']}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={cx.buttons}>
                    <Button label={'Отмена'} onClick={() => {
                        setSelectedUsers(value);
                        close();
                    }}
                            fullWidth={platform === 'mobile'}/>
                    <Button color={'green'} label={'Далее'} fullWidth={platform === 'mobile'} onClick={() => {
                        onChange(selectedUsers);
                        close();
                    }}/>
                </div>
            </div>
        </Modal>
    );
};

export default SelectionUsers;
