import React from 'react';
import hot from 'containers/hot-container';
import { contentManagementSectionFormPage } from 'components/content-management-project-form';
import Button from 'uikit/button';
import { useGlobalContext, useMessage } from 'lib/hooks';
import api from 'api/index';
import { useNavigate } from 'shared/router';

const ContentManagementSectionPage = ({ section, loading, setLoading }) => {
    const navigate = useNavigate();

    const { platform } = useGlobalContext();
    const { addSuccess } = useMessage();

    const save = async () => {
        setLoading(true);

        if (section.users?.length > 0) {
            await api.errorReportResource.addErrorReportManagers(section.users, section.id, 'SECTION');
        }

        if (section.managersRemove?.length > 0) {
            await api.errorReportResource.deleteErrorReportManagers(section.managersRemove, section.id, 'SECTION');
        }


        setLoading(false);

        navigate(`/projects/${section.parentProjectId}/${section.id}/${section.sectionTitle}/articles`);
        addSuccess('Контент-менеджеры обновлены');
    }

    return (
        <>
            <Button label={'Сохранить'} disabled={loading} color={'green'}
                    fullWidth={platform === 'mobile'} onClick={save}/>
        </>
    );
};

export default hot(contentManagementSectionFormPage(ContentManagementSectionPage, 'Контент-менеджмент'));
