import { createSlice } from '@reduxjs/toolkit';
import NotificationConnect from 'notifications/infrastructure/notification-wss';
import { ChatConnect } from 'api/chat-wss';
import api from 'api/index';
import ApiAxios from 'api/api-axios';

let isInitialized = false;

const initialState = {
    session: null,
    sessionLoading: false,
    sessionHasBeenFetched: false,
    roles: null,
    user: null,
    users: null,
    globalPermActions: [],
    globalRootPermActions: [],
    menuButtons: null,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setSession: (state, action) => {
            state.session = action.payload;
        },
        setSessionLoading: (state, action) => {
            state.sessionLoading = action.payload;
        },
        setSessionHasBeenFetched: (state, action) => {
            if (!action.payload) {
                isInitialized = false;
            }

            state.sessionHasBeenFetched = action.payload;
        },
        setRoles: (state, action) => {
            state.roles = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setUsers: (state, action) => {
            state.users = action.payload;
        },
        setLogoutUrl: (state, action) => {
            state.logoutUrl = action.payload;
        },
        setGlobalPermActions: (state, action) => {
            state.globalPermActions = action.payload;
        },
        setGlobalRootPermActions: (state, action) => {
            state.globalRootPermActions = action.payload;
        },
        setMenuButtons: (state, action) => {
            state.menuButtons = action.payload;
        },
        invalidateState: () => initialState,
        decrementUnreadCounter: (state, action) => {
            state.menuButtons[action.payload] = Math.max(0, state.menuButtons[action.payload] - 1);
        },
        incrementUnreadCounter: (state, action) => {
            state.menuButtons[action.payload] = state.menuButtons[action.payload] + 1;
        },
    },
});

export const {
    setSession,
    setSessionLoading,
    setSessionHasBeenFetched,
    setRoles,
    setUser,
    setUsers,
    invalidateState,
    setGlobalPermActions,
    setGlobalRootPermActions,
    setMenuButtons,
    decrementUnreadCounter,
    incrementUnreadCounter,
    setLogoutUrl
} = authSlice.actions;

export const selectSession = state => state.auth.session;
export const selectSessionLoading = state => state.auth.sessionLoading;
export const selectSessionHasBeenFetched = state => state.auth.sessionHasBeenFetched;
export const selectRoles = state => state.auth.roles;
export const selectUser = state => state.auth.user;
export const selectUsers = state => state.auth.users;
export const selectGlobalPermActions = state => state.auth.globalPermActions;
export const selectGlobalRootPermActions = state => state.auth.globalRootPermActions;
export const selectMenuButtons = state => state.auth.menuButtons;
export const selectLogoutUrl = state => state.auth.logoutUrl;

export const getSession = () => async dispatch => {
    try {
        if (isInitialized) {
            // console.log('getSession isInitialized')
            return;
        }

        // console.log('getSession isInitialized NONE')

        isInitialized = true;

        dispatch(setSessionLoading(true));

        const res = await api.auth.getAccount();

        NotificationConnect(res.data.login);
        ChatConnect();

        const data = await Promise.all([
            api.role.getUserRolesInfo(),
            api.user.getUserByLogin(res.data.login),
            api.user.getUsersInitList(),
            api.user.info(),
            api.globalMenu.getMenuButtons(),
            api.resource.getGlobalPermittedActions(),
            api.resource.getGlobalRootPermittedActions(),
        ]);

        dispatch(setRoles(data[0]));
        dispatch(setUser(data[1]));
        dispatch(setUsers({ content: data[2] }));

        ApiAxios.logoutUrl = data[3];
        dispatch(setLogoutUrl(data[3]));
        dispatch(setMenuButtons(data[4]));
        dispatch(setGlobalPermActions(data[5]));
        dispatch(setGlobalRootPermActions(data[6]));

        sessionStorage.removeItem('alreadyRedirected');

        dispatch(setSessionHasBeenFetched(true));
        dispatch(setSession(res.data));

        setInterval(async () => {
            api.globalMenu.getMenuButtons().then(result => dispatch(setMenuButtons(result)));
            api.resource.getGlobalPermittedActions().then(result => dispatch(setGlobalPermActions(result)));
            api.resource.getGlobalRootPermittedActions().then(result => dispatch(setGlobalRootPermActions(result)));
        }, 60000);
    } catch (error) {
        if (!error.response) {
            console.log('Auth Slice Redirect', error, ApiAxios.logoutUrl);
            // if (ApiAxios.logoutUrl) {
            //     window.location.replace(ApiAxios.logoutUrl);
            // } else {
            //     window.location.reload();
            // }

            // if (window.location.pathname !== '/login') {
            //     if (process.env.NODE_ENV === 'development') {
            //         document.location.replace('/login');
            //     } else {
            //         if (ApiAxios.logoutUrl) {
            //             document.location.replace(ApiAxios.logoutUrl);
            //         } else {
            //             document.location.reload();
            //         }
            //     }
            // }
        }

        dispatch(setSessionHasBeenFetched(true));
    } finally {
        dispatch(setSessionLoading(false));
    }
};

export const clearSession = () => async dispatch => {
    console.log('- clearSession')
    try {
        await api.auth.logout();
        dispatch(invalidateState());
        window.location.replace('/');
    } catch (error) {
        console.log(error);
    }
};

export const fetchMenuButtons = () => async dispatch => {
    dispatch(setMenuButtons(await api.globalMenu.getMenuButtons()));
};

export default authSlice.reducer;
