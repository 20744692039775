import { findParentNodeClosestToPos } from '@tiptap/core';
import { NodeSelection, Selection } from '@tiptap/pm/state';

export const BubbleExtensions = {
    CALLOUT: 'callout',
    DETAILS: 'details',
    CODE_BLOCK: 'codeBlock',
    ANCHOR: 'anchor',
    IMAGE: 'resizableImage',
    TEMPLATE: 'columnBlock',
    TABLE: 'table',
} as const;

export const isBubbleExtensionActive = (
    selection: Selection,
    extension: typeof BubbleExtensions[keyof typeof BubbleExtensions]
): boolean => {
    if (!selection) {
        return false;
    }

    if (selection instanceof NodeSelection && selection.node) {
        return selection.node.type.name === extension;
    }

    const extensions = Object.values<string>(BubbleExtensions);
    const nearestParent = findParentNodeClosestToPos(selection.$anchor, node => {
        return extensions.indexOf(node.type.name) !== -1;
    });

    return nearestParent?.node.type.name === extension;
};
