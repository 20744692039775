import { mergeAttributes } from '@tiptap/core';
import Bold from '@tiptap/extension-bold';
import { Plugin, PluginKey } from '@tiptap/pm/state';

const CustomBold = Bold.extend({
    addAttributes() {
        return {
            style: {
                default: null,
            },
        };
    },
    parseHTML() {
        return [
            {
                tag: 'strong',
            },
            {
                tag: 'b',
                getAttrs: node => node.style.fontWeight !== 'normal' && null,
            },
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ['strong', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },

    addKeyboardShortcuts() {
        return {};
    },

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey(this.name),
                props: {
                    handleDOMEvents: {
                        keydown: (_, event) => {
                            if (event.key === 'b' && (event.ctrlKey || event.metaKey) && !event.shiftKey) {
                                this.editor.commands.toggleBold();
                            }
                        },
                    },
                },
            }),
        ];
    },
});

export default CustomBold;
