import React, { useEffect, useState } from 'react';
import { useCombinedRefs } from 'lib/hooks';
import { useAudioPlayer } from 'lib/hooks/use-audio-player';
import Bar from 'uikit/audio/bar';
import Icon, { Icons } from 'uikit/icon';
import Volume from 'uikit/audio/volume';
import cx from './audio-player.module.scss';

const AudioPlayer = React.forwardRef(({ src = '', ...props }, ref) => {
    const { volume, curTime, duration, playing, setPlaying, setClickedTime, setClickedVolume } = useAudioPlayer();
    const innerRef = useCombinedRefs(ref);

    const [source, setSource] = useState(null);

    const togglePlay = () => {
        setPlaying(!playing);
    };

    const replay = () => {
        setClickedTime('0');
    };

    useEffect(() => {
        fetch(src).then(response => response.blob()).then(blob => setSource(URL.createObjectURL(blob)));
    }, [src]);

    return (
        <div className={cx.player}>
            <audio id="audioPlayer" ref={innerRef} {...props}>
                {source && <source src={source} />}
                Your browser does not support the <code>audio</code> element.
            </audio>
            <div className={cx.controls}>
                <div className={cx.controlsBtn}>
                    <button className={cx.replayButton} onClick={replay}>
                        <Icon type={Icons.REWIND_BACK} width={20} height={20}/>
                    </button>
                    <button className={cx.playButton} onClick={togglePlay}>
                        {playing
                            ? <Icon type={Icons.PAUSE} width={20} height={20}/>
                            : <Icon type={Icons.PLAY} width={20} height={20}/>
                        }
                    </button>
                </div>
                <Bar curTime={curTime} duration={duration} onTimeUpdate={(time) => setClickedTime(time)}/>
                <Volume volume={volume} onVolumeUpdate={(volume) => setClickedVolume(volume)}/>
            </div>
        </div>
    );
});

export default AudioPlayer;
