import React, { useRef } from 'react';
import classNames from 'classnames';
import { findParentNodeOfType } from 'prosemirror-utils';
import Icon, { Icons } from 'uikit/icon';
import { useEditorContext } from '../../context';
import { MenuButtonAlignJustify } from './MenuButtonAlignJustify';
import { MenuButtonAlignCenter } from './MenuButtonAlignCenter';
import { MenuButtonAlignLeft } from './MenuButtonAlignLeft';
import { MenuButtonAlignRight } from './MenuButtonAlignRight';
import { Menu, MenuItem } from '../DropdownMenu/DropdownMenu';
import cx from '../../editor.module.scss';
import Tippy from '@tippyjs/react';

export const MenuButtonAlign = ({ mode, currentMenu, setCurrentMenu }) => {
    const editor = useEditorContext();
    const alignMenuRef = useRef(null);

    const textAlign = editor?.getAttributes('paragraph')?.textAlign;
    const alignClass = editor?.getAttributes('listItem')?.class;

    const onChangeAlign = (e, align) => {
        if (mode === 'MOBILE') {
            e.stopPropagation();
            setCurrentMenu(undefined);
        } else {
            alignMenuRef.current?.hidemenu?.();
        }

        const listItemNode = findParentNodeOfType(editor.state.schema.nodes.listItem)(editor.state.selection);

        if (listItemNode) {
            editor.chain().focus().updateAttributes('listItem', { class: `list-${align}` }).run();
        } else {
            
            editor?.isActive({ textAlign: align })
                ? editor?.chain().focus().unsetTextAlign().run()
                : editor?.chain().focus().setTextAlign(align).run();
        }

        alignMenuRef.current?.hidemenu?.();
    };

    return (
        <Menu
            nested={mode === 'MOBILE'}
            mode={mode}
            name="align"
            ref={alignMenuRef}
            currentMenu={currentMenu}
            setCurrentMenu={name => {
                if (mode === 'MOBILE' && name !== 'align') {
                    setCurrentMenu('sub');
                } else {
                    setCurrentMenu(name);
                }
            }}
            label={
                <Tippy content="Выравнивание" disabled={mode !== 'DESKTOP'}>
                    {mode !== 'MOBILE' ? (
                        <div className={classNames('editor-button', cx.editorMenuButton)}>
                            <Icon type={Icons.EditorIconJustify} width={18} height={18} />
                            <Icon type={Icons.EditorIconArrowDown} width={12} height={12} />
                        </div>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <div className={classNames('editor-button', cx.editorMenuButton)}>
                                <Icon type={Icons.EditorIconJustify} width={18} height={18} />
                                <span className="editor-button-label" style={{ paddingLeft: 6 }}>
                                    Выравнивание
                                </span>
                            </div>
                            <span aria-hidden style={{ display: 'flex', marginRight: 6 }}>
                                <Icon type={Icons.EditorIconArrowRight} width={12} height={12} />
                            </span>
                        </div>
                    )}
                </Tippy>
            }
            menuButtonStyles={mode === 'MOBILE' ? { width: '100%' } : null}
            menuContentStyles={mode === 'MOBILE' ? { overflow: 'initial', minWidth: 254 } : null}
        >
            <MenuItem
                selected={alignClass ? alignClass === 'list-center' : textAlign === 'center'}
                label={<MenuButtonAlignCenter />}
                onClick={(e) => onChangeAlign(e, 'center')}
            />
            <MenuItem
                selected={alignClass ? alignClass === 'list-justify' : textAlign === 'justify'}
                label={<MenuButtonAlignJustify />}
                onClick={(e) => onChangeAlign(e, 'justify')}
            />
            <MenuItem
                selected={alignClass ? alignClass === 'list-left' : textAlign === 'left'}
                label={<MenuButtonAlignLeft />}
                onClick={(e) => onChangeAlign(e, 'left')}
            />
            <MenuItem
                selected={alignClass ? alignClass === 'list-right' : textAlign === 'right'}
                label={<MenuButtonAlignRight />}
                onClick={(e) => onChangeAlign(e, 'right')}
            />
        </Menu>
    );
};
