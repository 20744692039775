import { Node, mergeAttributes } from '@tiptap/core';
import { Plugin, PluginKey, NodeSelection } from '@tiptap/pm/state';
import { v4 } from 'uuid';

export const CalloutNode = Node.create({
    name: 'callout',
    content: 'block+',
    group: 'block',
    defining: true,
    isolating: true,
    allowGapCursor: true,

    addOptions() {
        return {
            types: {
                aqua: 'aquablock',
                red: 'redblock',
                green: 'greenblock',
                blue: 'blueblock',
                yellow: 'yellowblock',
                pirple: 'pirpleblock',
            },
            HTMLAttributes: {},
        };
    },

    addAttributes() {
        return {
            type: {
                default: 'green',
                parseHTML: el => {
                    const cs = Array.from(el.classList).filter(cs => cs.includes('block'))?.[0];

                    return cs?.replace('block', '');
                },
                renderHTML: attributes => {
                    return {
                        class: this.options.types[attributes.type] ?? '',
                    };
                },
            },
            id: {
                default: v4(),
                rendered: false,
            },
            'data-background': {
                default: null,
                parseHTML: el => el.style.backgroundColor,
                renderHTML: attrs => {
                    if (attrs['data-background']) {
                        return { style: 'background-color:' + attrs['data-background'] };
                    } else {
                        return {};
                    }
                },
            },
            'data-emoji': {
                default: null,
                parseHTML: el => el.getAttribute('data-emoji'),
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'section.callout',
            },
            {
                tag: 'section.aquablock',
            },
            {
                tag: 'section.redblock',
            },
        ];
    },

    renderHTML({ node, HTMLAttributes }) {
        return [
            'section',
            mergeAttributes(HTMLAttributes, { class: 'callout' }),
            ['span', !node.attrs['data-emoji'] ? { class: 'icon ' + node.attrs.type } : { 'data-emoji': node.attrs['data-emoji'] }],
            ['div', {}, 0],
        ];
    },

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey('callout-active'),
                props: {
                    handleClick(view, pos) {
                        const { state, dispatch } = view;
                        const { doc, tr } = state;

                        const node = doc.nodeAt(pos - 1);

                        if (node && node.type.name === 'callout') {
                            tr.setSelection(NodeSelection.create(doc, pos - 1));
                            dispatch(tr);

                            return true;
                        }

                        return false;
                    },
                },
            }),
            new Plugin({
                appendTransaction: (_transactions, _oldState, newState) => {
                    const tr = newState.tr;

                    newState.doc.descendants((node, pos) => {
                        if (node.type.name === 'callout' && node.childCount === 0) {
                            tr.insert(pos + 1, newState.schema.nodes.paragraph.create());
                        }
                    });

                    return tr.steps.length ? tr : null;
                },
            }),
        ];
    },

    addKeyboardShortcuts() {
        const onDelete = ({ editor }) =>
            editor.state.selection instanceof NodeSelection && editor.state.selection.node.type.name === 'callout';

        return {
            Backspace: onDelete,
            Delete: onDelete
        }
    }
});
