import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Mark from 'mark.js';
import Modal from 'react-modal';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';
import { Link, setDocumentTitle, useLocation, useNavigate, useParams } from 'shared/router';
import { parse } from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import UrlUtils from 'lib/util/url.util';
import { download } from 'lib/util/download';
import { useArticle, useDialog, useGlobalContext, useEventListener, useMessage } from 'lib/hooks';
import { ArticleFormModel } from 'model/article';
import { perm } from 'model/resource';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import { selectSession, decrementUnreadCounter } from 'slice/authSlice';
import { setTreeInvalid } from 'slice/treeSlice';
import { selectNewsPicker } from 'slice/newsPickerSlice';
import GlossaryService from 'services/GlossaryService';
import Lock from 'components/lock';
import LikesComponent from 'components/like';
import Comments from 'components/commenents';
import SendEmail from 'components/send-email';
import Attachments from 'components/attachments';
import ReportError from 'components/report-error';
import Confirmation from 'components/confirmation';
import PostingHeader from 'components/posting-header';
import PostingSearch from 'components/posting-search';
import ResourceLogList from 'components/resource-log-list';
import SubscribeUpdates from 'components/subscribe-updates';
import { TreePropContext } from 'containers/side-tree-wrapper';
import { DOCUMENT_TYPE } from 'components/posting-header/posting-header';
import Button from 'uikit/button';
import Divider from 'uikit/divider';
import Loading from 'uikit/loading';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import HtmlViewer from 'editors/html-viewer';
import ButtonTimer from 'uikit/button-timer';
import api from 'api';
import cs from './article-posting.module.scss';
import ScrollTopBtn from 'components/scroll-top-btn';
import { NODE_TYPES } from 'components/trees';
import { fetchMenuButtons } from 'slice/authSlice';
import { DateTime, Interval } from 'luxon';
import { TagList } from 'components/tag-list';
import { useWindowSize } from 'lib/hooks/useWindowSize';
import { getDiffs } from 'shared/utils/diff/diffHelper';
import { EmptyArticle, EmptyArticleDeleted } from 'components/projects/empty';
import { preparePrint } from 'lib/util/preparePrint.ts';

const ArticlePosting = ({ archive = false }) => {
    const navigate = useNavigate();
    const { id, snapshotId, type = 'article' } = useParams();

    const { platform, locationUrl } = useGlobalContext();
    const dispatch = useDispatch();
    const session = useSelector(selectSession);
    const newsPicker = useSelector(selectNewsPicker);
    const treePropsContext = React.useContext(TreePropContext);
    const location = useLocation();
    const { width } = useWindowSize();

    const { setScrollToNode } = treePropsContext;
    const { loading, article, setArticle, parents, breadCrumbs, onDelete, projectId, viewersCount, state } = useArticle(id, type, archive);
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [downloading, setDownloading] = useState(false);

    const [lock, setLock] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);

    const [isReportError, setIsReportError] = useState(false);
    const { addSuccess, addError } = useMessage();

    const [sendEmail, setSendEmail] = useState(false);
    const [subscribeUpdates, setSubscribeUpdates] = useState(false);

    const [logsShow, setLogsShow] = useState(false);

    const [searchInstance, setSearchInstance] = useState(null);
    const [searchText, setSearchText] = useState('');

    const [markElements, setMarkElements] = useState([]);
    const [markIterator, setMarkIterator] = useState(0);

    const [searchContent, setSearchContent] = useState(null);

    const [isScrollTopButton, setIsScrollTopButton] = useState(false);
    const [isReadButtonActive, setIsReadButtonActive] = useState(false);

    const [isReadInfo, setIsReadInfo] = useState(true);

    // History fields;
    const [isHistory, setIsHistory] = useState(false);
    const [isSnapshotRestore, setIsSnapshotRestore] = useState(false);
    const [isSnapshotRestoreSubmitting, setIsSnapshotRestoreSubmitting] = useState(false);

    const [snapshots, setSnapshots] = useState([]);
    const [snapshotIndex, setSnapshotIndex] = useState(0);

    const [snapshot, setSnapshot] = useState(null);
    const [snapshotContent, setSnapshotContent] = useState('');

    const [articleContainer, setArticleContainer] = useState(null);
    const body = useMemo(() => {
        if (snapshot) {
            return snapshotContent;
        }

        if (article) {
            return GlossaryService.format(article['text'], projectId);
        }

        return '';
    }, [snapshot, snapshotContent, article, projectId]);

    const onSnapshotClick = item => {
        // Check is current snapshot;
        if (snapshot === item) {
            return;
        }

        // Navigate to snapshot;
        navigate(`/projects/article/${article['id']}/history/${item['id']}`);
    };
    const onSnapshotRestore = async () => {
        if (isSnapshotRestoreSubmitting) {
            return;
        }

        setIsSnapshotRestoreSubmitting(true);
        await api.snapshot.applyArticleSnapshot(snapshot['snapshot']['id']);
        setIsSnapshotRestore(false);
        navigate(`/projects/article/${id}`);
        setIsSnapshotRestoreSubmitting(false);
    };

    const onScroll = e => {
        setIsScrollTopButton(e.target.scrollTop >= e.target.clientHeight);
        _readButtonOnScroll();
    };

    const archiveArticle = async () => {
        try {
            await api.archive.moveArticleToArchive(id);
            const { tree, reloadTree } = treePropsContext;

            if (!tree) {
                navigate(`/projects/${projectId}/articles`);
                return;
            }

            const node = tree.getNodeById(id);

            if (node) {
                // tree.removeNode(node);
                reloadTree();

                dispatch(setTreeInvalid(true));
                dispatch(fetchMenuButtons());
                navigate(UrlUtils.getSectionUrl(projectId, node.parent.id));
            } else {
                navigate(`/projects/${projectId}/articles`);
            }
            addSuccess('Статья перемещена в архив');
        } catch (e) {
            addError('Не удалось отправить в архив');
        } finally {
            closeDialog();
        }
    };
    const onArticleArchive = () => {
        openDialog({
            title: 'Архивирование',
            text: (
                <span>
                    Вы действительно хотите переместить в архив статью <span style={{ color: '#279BD9' }}>{article?.title}</span>? Документы
                    в архиве могут быть восстановлены.
                </span>
            ),
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Подтвердить',
            onClose: closeDialog,
            onSubmit: archiveArticle,
            contentType: 'TEXT',
        });
    };
    const onRemoveArticle = () => {
        openDialog({
            title: 'Удаление',
            text: (
                <span>
                    Вы действительно хотите удалить статью <span style={{ color: '#279BD9' }}>{article?.title}</span>? Этот процесс нельзя
                    будет отменить
                </span>
            ),
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Удалить',
            color: 'red',
            onClose: closeDialog,
            onSubmit: () => {
                try {
                    onDelete();
                    navigate(`/archive/${projectId}/articles`);
                } catch (e) {
                    addError('Не удалось удалить');
                } finally {
                    closeDialog();
                }
            },
            contentType: 'TEXT',
        });
    };

    const unArchiveArticle = async () => {
        try {
            await api.archive.moveArticleFromArchive(id);
            const { tree, reloadTree } = treePropsContext;

            if (!tree) {
                navigate(`/archive/${projectId}/articles`);
                return;
            }

            const node = tree.getNodeById(id);

            if (node) {
                // tree.removeNode(node);
                reloadTree();
                dispatch(fetchMenuButtons());
            }

            navigate(`/archive/${projectId}/articles`);
        } catch (e) {
            addError('Не удалось разархивировать');
        } finally {
            closeDialog();
        }
    };
    const onUnArchiveArticle = () => {
        openDialog({
            title: 'Восстановление',
            text: (
                <span>
                    Вы действительно хотите восстановить из архива статью <span style={{ color: '#279BD9' }}>{article?.title}</span>?
                </span>
            ),
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Подтвердить',
            onClose: closeDialog,
            onSubmit: unArchiveArticle,
            contentType: 'TEXT',
        });
    };

    const onRead = async time => {
        if (type === 'article') {
            await api.article.readArticle(article.id, time);
        } else if (type === 'article-draft') {
            await api.article.readArticleDraft(article.id, time);
        }

        function traverse(node) {
            if (node) {
                if (node.unreadCount > 0) {
                    tree.updateNode(node, { unreadCount: Math.max(0, node.unreadCount - 1) }, { shallowRendering: false });
                }
                if (node.parent) {
                    traverse(node.parent);
                }
            }
        }

        const { tree } = treePropsContext;
        const node = tree.getNodeById(id);

        if (node && node.nodeType === NODE_TYPES.ARTICLE && node.unread) {
            tree.updateNode(node, { unread: false });
        }

        traverse(node?.parent);
        dispatch(decrementUnreadCounter('unreadArticles'));

        setArticle(article => ({ ...article, read: true }));
    };
    const handleSetFavorite = async () => {
        if (isFavorite) {
            try {
                await api.favorites.deleteArticleFromFavorites(id);
                addSuccess('Статья удалена из избранного');

                setIsFavorite(false);
            } catch (e) {
                addError('Не удалось удалить статью из избранного');
            }
        } else {
            try {
                await api.favorites.addArticleToFavorites(id);
                addSuccess('Статья добавлена в избранное');

                setIsFavorite(true);
            } catch (e) {
                addError('Не удалось добавить статью в избранное');
            }
        }
    };

    const scrollToFind = useCallback(
        (iter, previousIterator) => {
            if (markElements.length === 0) {
                return;
            }

            if (previousIterator !== -1) {
                markElements[previousIterator].classList.remove('activeSearchMark');
            }

            markElements[iter].classList.add('activeSearchMark');

            requestAnimationFrame(() => {
                // markElements[iter].scrollIntoView({ block: 'center', behavior: 'smooth' });
                const _id = 'articleContent';
                const frame = document.getElementById(_id);
                if (!frame) {
                    return;
                }

                const doc = frame.contentDocument;

                if (!doc) {
                    return;
                }

                const scrollingContainer = document.querySelector('.' + cs.articleContainer);
                if (scrollingContainer) {
                    const elements = doc.getElementsByClassName('activeSearchMark');
                    if (elements && elements.length > 0) {
                        const rect = elements[0].getBoundingClientRect();
                        scrollingContainer.scrollTo(0, rect.top);
                    }
                }
            });
        },
        [markElements]
    );

    const onEmailSent = () => {
        setSendEmail(false);
        addSuccess('Отправлено!');
    };
    const onSubscribeUpdates = () => {
        setSubscribeUpdates(false);
        addSuccess('Подписка изменена!');
    };

    const searchChange = e => {
        searchInstance.unmark();
        setSearchText(e.target.value);

        const punctuation = '.,-"\'«»!_?;:|\\/ '.split('');
        const search = e.target.value
            .split('')
            .map(p => (punctuation.indexOf(p) !== -1 ? '' : p))
            .join('');

        searchInstance.mark(search, {
            separateWordSearch: false,
            ignorePunctuation: punctuation,
        });

        const findDetails = searchContent.getElementsByTagName('details');

        for (let i = 0; i < findDetails.length; i++) {
            const findContent = findDetails[i].getElementsByTagName('div');
            const findMark = findContent[0].getElementsByTagName('mark');

            if (findMark.length > 0) {
                findDetails[i].setAttribute('open', '');
            }
        }

        const findElements = searchContent.getElementsByTagName('mark');
        setMarkElements(findElements);
        setMarkIterator(0);
    };
    const onInitIframe = () => {
        const content = document.getElementById('articleContent').contentWindow.document.body;

        if (content) {
            setSearchContent(content);
            const instance = new Mark(content);
            setSearchInstance(instance);
        }
    };
    const searchOnKeyPress = e => {
        if (markElements.length === 0) {
            return;
        }

        let keyCode = e.code || e.key;

        if (keyCode === 'Enter') {
            const prevIter = markIterator;

            if (markElements.length - 1 === markIterator) {
                setMarkIterator(0);
                scrollToFind(0, prevIter);
            } else if (markElements.length !== 0) {
                setMarkIterator(markIterator + 1);
                scrollToFind(markIterator + 1, prevIter);
            }
        }
    };

    const onNext = () => {
        const prevIter = markIterator;

        if (markElements.length - 1 === markIterator) {
            setMarkIterator(0);
            scrollToFind(0, prevIter);
        } else {
            setMarkIterator(markIterator + 1);
            scrollToFind(markIterator + 1, prevIter);
        }
    };
    const onPrevious = () => {
        const prevIter = markIterator;

        if (markIterator === 0) {
            setMarkIterator(markElements.length - 1);
            scrollToFind(markElements.length - 1, prevIter);
        } else {
            setMarkIterator(markIterator - 1);
            scrollToFind(markIterator - 1, prevIter);
        }
    };
    const onCancel = () => {
        setMarkIterator(0);
        setSearchText('');

        setMarkElements([]);
        searchInstance.unmark();
    };

    const _articleToFormat = async format => {
        try {
            setDownloading(true);
            const res = await api.contentConverter.articleToFormat(id, format);
            download(res, article.title + `.${format}`, format);
        } catch (error) {
            console.log('ERROR', error);
        } finally {
            setDownloading(false);
        }
    };
    const _readButtonOnScroll = useCallback(() => {
        const commentsContainer = document.getElementById('js-comments');

        if (!commentsContainer) {
            return;
        }

        const sidebarContent = document.getElementById('articleContainer');
        const heightFromPageBottom = commentsContainer.offsetTop;

        if (sidebarContent.scrollTop + sidebarContent.clientHeight + 300 >= heightFromPageBottom) {
            setIsReadButtonActive(true);
        }
    }, []);
    const goToComment = () => {
        const el = document.getElementById('js-comments');
        el.scrollIntoView({ block: 'center', behavior: 'smooth' });
    };

    const actions = [
        {
            icon: Icons.EDIT_PEN,
            title: 'Редактировать',
            id: 'articlePostingEditButton',
            visible: !archive && !snapshotId && article?.permittedActions?.includes(GLOBAL_ACTIONS.ARTICLE_EDIT),
            display: ['desktop_toolbar'],
            link: !article
                ? ''
                : type === 'article'
                ? archive
                    ? `/archive/article/${article.id}/edit`
                    : `/projects/article/${article.id}/edit`
                : type === 'article-draft'
                ? `/user/article-draft/${article.id}/article-draft/edit`
                : '',
            width: 15,
            height: 15,
        },
        {
            icon: Icons.PRINTER,
            title: 'Печать',
            id: 'articlePostingPrintButton',
            visible: platform !== 'mobile' && article?.permittedActions?.includes(GLOBAL_ACTIONS.DOCUMENT_PRINT),
            display: ['desktop_toolbar'],
            onClick: () => {
                preparePrint({
                    contentID: 'articleContent',
                    titleID: 'postingHeaderTitle',
                    descID: 'articlePostingDescription'
                })
                .catch(err => addError(err));
            },
            width: 15,
            height: 15,
        },
        {
            icon: Icons.LOCK,
            title: 'Доступы',
            id: 'articlePostingLockButton',
            visible: !archive && !snapshotId && article?.permittedActions?.includes(GLOBAL_ACTIONS.LOCK),
            display: ['desktop_toolbar'],
            onClick: () => setLock(true),
            width: 15,
            height: 15,
        },
        {
            icon: isFavorite ? Icons.STAR_FILLED : Icons.STAR,
            title: isFavorite ? 'Убрать из избранного' : 'В избранное',
            id: 'articlePostingFavoriteButton',
            visible: !archive && !snapshotId,
            display: ['desktop_toolbar', 'mobile_toolbar'],
            onClick: handleSetFavorite,
            className: isFavorite ? cs.favoriteActive : null,
            width: 15,
            height: 15,
        },
        {
            icon: Icons.COMMENTS,
            title: 'К комментариям',
            id: 'articlePostingGoToCommentButton',
            visible: !snapshotId,
            display: ['desktop_toolbar', 'mobile_toolbar'],
            onClick: () => goToComment(),
            width: 15,
            height: 15,
        },
        {
            icon: Icons.TIME,
            title: 'История',
            id: 'articlePostingHistoryButton',
            visible: !snapshotId && !archive,
            display: ['desktop_toolbar', 'mobile_toolbar'],
            onClick: () => navigate(`/projects/article/${article['id']}/history/${snapshots[0]['id']}`),
            width: 15,
            height: 15,
        },
        // {
        //     icon: Icons.LETTER,
        //     title: 'Отправить по электронной почте',
        //     id: 'articlePostingEmailButton',
        //     visible: article?.permittedActions?.includes(GLOBAL_ACTIONS.DOCUMENT_SEND_TO_EMAIL) && !snapshotId,
        //     onClick: () => setSendEmail(true),
        //     width: 15,
        //     height: 15,
        // },
        {
            icon: Icons.DOWNLOAD,
            title: 'Скачать документ',
            id: 'articlePostingDownloadButton',
            visible: article?.permittedActions?.includes(GLOBAL_ACTIONS.FILE_READ) && !snapshotId,
            width: 15,
            height: 15,
            submenu: [
                {
                    title: 'Microsoft Word (DOCX)',
                    onClick: () => _articleToFormat('docx').then(() => {}),
                },
                {
                    title: 'Документ PDF',
                    onClick: () => _articleToFormat('pdf').then(() => {}),
                },
                // {
                //     title: 'Текст в формате TXT',
                //     onClick: () => _articleToFormat('txt').then(() => {}),
                // },
                // {
                //     title: 'Текст в формате RTF',
                //     onClick: () => _articleToFormat('rtf').then(() => {}),
                // },
            ],
        },
        {
            icon: Icons.ARCHIVE,
            title: 'В архив',
            id: 'articlePostingArchiveButton',
            visible: !archive && !snapshotId && article?.permittedActions?.includes(GLOBAL_ACTIONS.RESOURCE_ARCHIVE_MOVE),
            onClick: () => onArticleArchive(),
            width: 15,
            height: 15,
        },
        {
            icon: Icons.RELOAD,
            title: 'Разархивировать',
            id: 'articlePostingUnArchiveButton',
            visible: archive && !snapshotId && article?.permittedActions?.includes(GLOBAL_ACTIONS.RESOURCE_ARCHIVE_MOVE),
            onClick: () => onUnArchiveArticle(),
            width: 15,
            height: 15,
        },
        // {
        //     icon: Icons.BUG,
        //     title: 'Сообщить об ошибке',
        //     id: 'articlePostingReportErrorButton',
        //     visible: !archive && !snapshotId && article?.permittedActions?.includes(GLOBAL_ACTIONS.ERROR_REPORT_ADD),
        //     onClick: () => setIsReportError(true),
        //     width: 15,
        //     height: 15,
        // },
        {
            icon: Icons.INFO,
            title: 'Действия в документе',
            id: 'articlePostingActionsButton',
            visible: article?.permittedActions?.includes(GLOBAL_ACTIONS.ACTIVITY_PAGE) && !snapshotId,
            onClick: () => setLogsShow(true),
            width: 15,
            height: 15,
        },
        // {
        //     icon: Icons.BELL,
        //     title: 'Подписаться на обновления',
        //     id: 'articlePostingSubscribeButton',
        //     visible: !archive && !snapshotId && article?.permittedActions?.includes(GLOBAL_ACTIONS.NOTIFICATION_SUBSCRIBE_ADD),
        //     onClick: () => setSubscribeUpdates(true),
        //     width: 15,
        //     height: 15,
        // },
        // {
        //     icon: Icons.SETTINGS,
        //     title: 'Настройки',
        //     id: 'articlePostingSettingsButton',
        //     visible: !snapshotId,
        //     onClick: () => navigate(`/projects/article/${article.id}/content-management`)
        // },
        {
            id: 'articlePostingDeleteButton',
            icon: Icons.TRASH,
            title: 'Удалить статью',
            visible: archive && article?.permittedActions?.includes(GLOBAL_ACTIONS.ARTICLE_DELETE),
            onClick: () => onRemoveArticle(),
        },
    ];

    useEffect(() => {
        if (!article) {
            return;
        }

        setDocumentTitle(`${article.title} — KMS Gran`);
    }, [article]);

    const renderHeader = () => {
        return (
            <>
                <PostingHeader
                    className={cs.header}
                    title={article.title}
                    authorId={article.author.login}
                    authorName={article.author.firstName + ' ' + article.author.lastName}
                    authorStatus={article.author.status}
                    authorImage={article['author']['avatarUuid'] && api.upload.getImage(article['author']['avatarUuid'], false, 128)}
                    documentType={DOCUMENT_TYPE.ARTICLE}
                    date={article.createTime}
                    isArchive={archive}
                    lastAction={article.lastAction}
                    viewersCount={viewersCount || 0}
                    crumbs={(breadCrumbs || []).map(item => {
                        return {
                            name: item.title,
                            linkTo:
                                article.id === item.id
                                    ? ''
                                    : archive
                                    ? item.type === 'PROJECT'
                                        ? `/archive/${item.id}/articles`
                                        : `/archive/${breadCrumbs[0].id}/${item.id}/section/articles`
                                    : item.type === 'PROJECT'
                                    ? `/projects/${item.id}/articles`
                                    : `/projects/${breadCrumbs[0].id}/${item.id}/section/articles`,
                        };
                    })}
                />
                <PostingSearch
                    className={cs.search}
                    isMobile={platform === 'mobile'}
                    placeholder="Поиск по статье"
                    value={searchText}
                    actions={actions}
                    archive={archive}
                    currentPosition={markIterator + 1}
                    allSearchResult={markElements.length}
                    onCancel={onCancel}
                    onNext={onNext}
                    onPrevious={onPrevious}
                    onKeyPress={searchOnKeyPress}
                    onChange={searchChange}
                >
                    {!archive && !snapshotId && (
                        <ButtonTimer
                            id={id}
                            className={platform === 'mobile' ? cs.readBtnMobile : null}
                            onRead={onRead}
                            disabled={!isReadButtonActive}
                            readTime={article?.time}
                            externalRead={article?.read ?? false}
                        />
                    )}
                    {snapshots.length !== 0 && snapshotId && article?.permittedActions?.includes(GLOBAL_ACTIONS.ARTICLE_EDIT) && (
                        <Button
                            className={cs.versionButton}
                            color="blue"
                            label="Восстановить версию"
                            disabled={snapshots[0]['id'] === snapshotId}
                            onClick={() => setIsSnapshotRestore(true)}
                        />
                    )}
                </PostingSearch>
            </>
        );
    };

    const goTop = () => {
        const el = document.getElementById('postingHeader');
        el?.scrollIntoView({ block: 'center', behavior: 'auto' });
    };

    useEventListener('scroll', onScroll, articleContainer);
    useEventListener('keydown', e => {
        if ((e.ctrlKey || e.metaKey) && e.keyCode === 80) {
            e.preventDefault();

            const iframe = document.getElementById('newsContent');
            iframe.contentWindow.print();
        }
    });

    useEffect(() => {
        if (setScrollToNode && parents) {
            setScrollToNode(parents);
        }
    }, [parents, setScrollToNode]);
    useEffect(() => {
        setSearchText('');
        setIsReadButtonActive(false);

        setMarkIterator(0);
        setMarkElements([]);

        if (!article) {
            return;
        }

        setIsFavorite(article.favorite);
        setArticleContainer(document.getElementById('articleContainer'));

        goTop();
    }, [article]);

    useEffect(() => {
        scrollToFind(0, -1);
    }, [scrollToFind, searchText]);
    useEffect(() => {
        if (markIterator === 0) {
            scrollToFind(0, -1);
        }
    }, [scrollToFind, markIterator]);

    useEffect(() => {
        const fetchData = async () => {
            const snapshots = await api.snapshot.getArticlesSnapshots(id);
            setSnapshots(snapshots);
        };
        fetchData();
    }, [id]);

    useEffect(() => {
        const fetchData = async () => {
            const snapshotIndex = snapshots.findIndex(item => item['id'] === snapshotId);
            const snapshot = await api.snapshot.getArticleSnapshot(snapshotId);

            setSnapshotIndex(snapshotIndex);
            setSnapshot(snapshot);

            setIsHistory(true);
            setSnapshotContent(getDiffs(snapshot['origin'], snapshot['revised']));

            setDownloading(false);
        };
        if (snapshotId && snapshots.length !== 0) {
            setDownloading(true);
            fetchData();
        }
    }, [snapshotId, snapshots]);

    useEffect(() => {
        if (!article) {
            return;
        }

        if (!archive && article['state'] === 'ARCHIVED') {
            navigate(`/archive/article/${article.id}`);
        } else if (archive && article['state'] === 'ACTIVE') {
            navigate(`/projects/article/${article.id}`);
        }
    }, [navigate, addError, archive, article]);

    useEffect(() => {
        GlossaryService.init();

        // Отслеживаем наличие пар-ров запроса для подсветки совпадений из поиска
        if (locationUrl !== location.pathname && locationUrl.includes(location.pathname)) {
            const { highlights } = parse(`?${locationUrl.split('?')[1]}`);
            if (highlights) {
                // window.history.pushState(null, '', `${location.pathname}?highlights=${highlights}`);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setTimeout(() => _readButtonOnScroll(), 3000);
    }, [_readButtonOnScroll]);

    let showSnapshotInfo = false;

    if (article && !article['read'] && article['version'] && isReadInfo && !snapshot && article['wasRead']) {
        const articleCreateTime = DateTime.fromISO(article.createTime);
        const versionCreateTime = DateTime.fromISO(article.version.createTime);

        if (Interval.fromDateTimes(articleCreateTime, versionCreateTime).length() > 10000) {
            showSnapshotInfo = true;
        }
    }

    if (state === 'ERROR') {
        return (
            <div className={cs.article}>
                <EmptyArticle />
            </div>
        );
    }

    if (state === 'DELETED') {
        return (
            <div className={cs.article}>
                <EmptyArticleDeleted />
            </div>
        );
    }

    return (
        <>
            {isSnapshotRestore && (
                <Modal isOpen={true} className={cs.restoreModal} overlayClassName={cs.restoreModalOverlay}>
                    <div className={cs.restoreModalHeader}>
                        <h2>Восстановление версии</h2>
                        <IconButton icon={<Icon type={Icons.CROSS} width={14} height={14} />} onClick={() => setIsSnapshotRestore(false)} />
                    </div>
                    <div className={cs.restoreModalBody}>
                        <p>Вы действительно хотите установить выбранную версию как актуальную версию документа?</p>
                        <p>Восстановление создаст новую актуальную версию документа и сохранит все существующие.</p>
                    </div>
                    <div className={cs.restoreModalFooter}>
                        <Button label="Нет, закрыть" onClick={() => setIsSnapshotRestore(false)} />
                        <Button label="Да, восстановить" color="green" onClick={() => onSnapshotRestore()} />
                    </div>
                </Modal>
            )}
            <Confirmation {...dialogState} />
            <SendEmail
                isOpen={sendEmail}
                onDismiss={() => setSendEmail(false)}
                onOk={onEmailSent}
            />
            <ResourceLogList
                isOpen={logsShow}
                onDismiss={() => setLogsShow(false)}
                uuid={id}
            />
            <SubscribeUpdates
                isOpen={subscribeUpdates}
                onDismiss={() => setSubscribeUpdates(false)}
                onOk={onSubscribeUpdates}
            />
            <ReportError
                isOpen={isReportError}
                onDismiss={() => setIsReportError(false)}
                onRequestSubmit={async data => {
                    await api.errorReportResource.errorReport('ARTICLE', id, data);
                    addSuccess('Отправлено!');
                    setIsReportError(false);
                }}
            />
            {type !== 'article-draft' && lock && (
                <Lock
                    title="Доступ к статье"
                    onClose={() => setLock(false)}
                    emptyTitle="Статья в закрытом доступе"
                    emptyDescription="Пригласите первого участника для работы со статьёй"
                    projectId={projectId}
                    resourceId={id}
                    resourceType="ARTICLE"
                    projectName={article.title}
                    isDocument={true}
                />
            )}
            <Loading
                active={downloading || loading || article?.id !== id}
                withOverlay={false}
                withRelativeOverlay={true}
            />
            {article && (
                <div className={cs.article}>
                    {isHistory && width < 768 && (
                        <div className={cs.mobileHeader}>{renderHeader()}</div>
                    )}
                    <div className={cs.articleWrapper}>
                        <div
                            id="articleContainer"
                            className={classNames(cs.articleContainer, 'js-scroll-wrapper', {
                                [cs.withNewsTicker]: newsPicker.count > 0,
                            })}
                        >
                            {(!isHistory || width >= 768) && renderHeader()}
                            {!archive && showSnapshotInfo && (
                                <div className={cs.postingReadVersion}>
                                    <div className={cs.postingReadVersionContent}>
                                        <div className={cs.postingReadVersionContainer}>
                                            <div>
                                                <Icon className={cs.desktop} type={Icons.INFO} width={16} height={16} />
                                                <Icon className={cs.mobile} type={Icons.INFO_HIGHLIGHT} width={24} height={24} />
                                            </div>
                                            <p>В документ были внесены изменения относительно последней прочитанной версии</p>
                                        </div>
                                        <div
                                            className={cs.postingReadVersionShow}
                                            onClick={() => navigate(`/projects/article/${article['id']}/history/${snapshots[0]['id']}`)}
                                        >
                                            <Icon type={Icons.EYE} width={20} height={20} />
                                            <span>Показать изменения</span>
                                        </div>
                                    </div>
                                    <div className={cs.postingReadVersionClose}>
                                        <Icon width={18} height={18} type={Icons.CLOSE_CIRCLE} onClick={() => setIsReadInfo(false)} />
                                    </div>
                                </div>
                            )}
                            {snapshot && (
                                <div id="postingSnapshot" className={cs.postingSnapshot}>
                                    <p>
                                        Версия {snapshots.length - snapshotIndex} {snapshotIndex === 0 ? '(Текущая)' : ''}
                                    </p>
                                    <ul>
                                        <li>
                                            <label>Дата публикации версии:</label>
                                            <span>{new Date(snapshot['snapshot']['createTime']).toLocaleString()}</span>
                                        </li>
                                        <li>
                                            <label>Автор версии:</label>
                                            <Link to={'/edit-user/' + snapshot['snapshot']['authorLogin']}>
                                                {snapshot['snapshot']['authorName']}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            )}
                            <div className={classNames(cs.postingContent, { [cs.mobileContent]: platform === 'mobile' })}>
                                {article.id === 'a958fc32-76ec-437c-8e94-6efc50a9acc3' && (
                                    <iframe
                                        src=" https://petr.gran-soft.ru/contacts/map-bases/"
                                        title="Article Content"
                                        id="iframeId__2"
                                        frameBorder="0"
                                        scrolling="yes"
                                        style={{ width: '100%', height: 'calc(100vh - 164px)' }}
                                    />
                                )}
                                {article.logoUuid && (
                                    <img
                                        id="articlePostingCover"
                                        className={cs.cover}
                                        style={{ marginTop: !archive && showSnapshotInfo ? '-83px' : '0' }}
                                        src={api.upload.getImage(article.logoUuid, false, window.innerWidth)}
                                        alt=""
                                    />
                                )}
                                {article.description && (
                                    <div id="articlePostingDescription" className={cs.desc}>
                                        {article.description}
                                    </div>
                                )}
                                {article.attachments.length !== 0 && (
                                    <div id="files" className={cs.fileBlock}>
                                        <div className={cs.blockFiles}>
                                            <Attachments
                                                files={ArticleFormModel.asFiles(article.attachments)}
                                                noDownload={!article?.permittedActions?.includes(perm.file.FILE_READ)}
                                                noAdd
                                                noDelete
                                                canCollapse
                                                defaultCollapsed={article.attachmentsCollapsed}
                                            />
                                        </div>
                                    </div>
                                )}
                                <Divider style={{ marginBottom: 0, marginTop: 0 }} />
                                <HtmlViewer
                                    id="articleContent"
                                    documentUuid={article.id}
                                    onInit={onInitIframe}
                                    onAfterLoad={() => setTimeout(() => _readButtonOnScroll(), 1000)}
                                    body={body}
                                    isOpenDropdowns={isHistory}
                                />
                                {article.documentTags?.length > 0 && (
                                    <div className={cs.fileBlock}>
                                        <Divider />
                                        <div className={cs.blockText}>Применённые тэги</div>
                                        <div className={cs.blockFiles}>
                                            <TagList tags={article.documentTags} maxTags={20} />
                                        </div>
                                    </div>
                                )}
                                {article.keywords && (
                                    <div id="keywords" className={cs.keywordsBlock}>
                                        <Divider className={cs.divider} />
                                        <div className={cs.blockText}>Примененные теги</div>
                                        <div className={cs.blockKeywords}>
                                            {article.keywords.split('%2C').map((keyword, index) => {
                                                if (!keyword) {
                                                    return null;
                                                }

                                                return (
                                                    <Tippy content={'Искать по тэгу: ' + keyword}>
                                                        <div
                                                            key={index}
                                                            className={cs.blockKeyword}
                                                            onClick={() => navigate('/search?mode=MORPHOLOGY&tags=' + keyword)}
                                                        >
                                                            {keyword}
                                                        </div>
                                                    </Tippy>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                                <Divider style={{ marginBottom: 0, marginTop: 0 }} />
                                {!snapshotId && !article.fovorite && (
                                    <>
                                        <LikesComponent
                                            id={article.id}
                                            {...article.likes}
                                            getDislikesFunction={api.dislike.getArticleDislikes}
                                            getLikesFunction={api.like.getArticleLikes}
                                            addDislikeFunction={api.dislike.addArticleDislike}
                                            addLikeFunction={api.like.addArticleLike}
                                            sessionLogin={session?.login}
                                            isArchive={archive}
                                        />
                                        <Divider style={{ marginBottom: 0, marginTop: 0 }} />
                                    </>
                                )}
                            </div>
                            {!snapshotId && <Comments id={article.id} of={Comments.ARTICLES} session={session} isArchive={archive} />}
                            <ScrollTopBtn isSeen={isScrollTopButton} scrollId={'articleContainer'} />
                        </div>
                        {isHistory && (
                            <div
                                id="postingHistory"
                                className={classNames(cs.articleHistory, {
                                    [cs.withNewsTicker]: newsPicker.count > 0,
                                })}
                            >
                                <div className={cs.articleHistoryHeader}>
                                    <h4>История версий</h4>
                                    <div
                                        className={cs.articleHistoryHeaderClose}
                                        onClick={() => navigate(`/projects/article/${article['id']}`)}
                                    >
                                        <Icon type={Icons.CROSS} width={10} height={10} />
                                    </div>
                                </div>
                                <div className={cs.articleHistoryBody}>
                                    {snapshots.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                onClick={() => onSnapshotClick(item)}
                                                className={classNames(
                                                    cs.articleHistoryBodyItem,
                                                    snapshot['snapshot']['id'] === item['id'] ? cs.articleHistoryBodyItemActive : null
                                                )}
                                            >
                                                <h5>
                                                    Версия {snapshots.length - index} {index === 0 ? '(Текущая)' : ''}
                                                </h5>
                                                <div className={cs.articleHistoryBodyItemContainer}>
                                                    <img
                                                        src={
                                                            item['authorAvatarUuid']
                                                                ? api.upload.getImage(item['authorAvatarUuid'])
                                                                : '/img/avatar.png'
                                                        }
                                                        alt={item['authorName']}
                                                    />
                                                    <span>{item['authorName']}</span>
                                                </div>
                                                <p>{new Date(item['createTime']).toLocaleString()}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default ArticlePosting;
