const parseMargin = (value) => {
    return sideShorthand(value, (width) => {
        // eslint-disable-next-line no-useless-escape
        return width.match(/(?:\-?[\.\d]+(?:%|\w*)|auto|inherit|initial|unset|revert)/g) || ['0px'];
    });
};
const sideShorthand = (value, split) => {
    const mapStyles = (map) => {
        ret.top = parts[map[0]];
        ret.right = parts[map[1]];
        ret.bottom = parts[map[2]];
        ret.left = parts[map[3]];
    };

    const ret = {};
    const parts = split ? split(value) : value.split(/\s+/);

    switch (parts.length) {
        case 1:
            mapStyles([0, 0, 0, 0]);
            break;
        case 2:
            mapStyles([0, 1, 0, 1]);
            break;
        case 3:
            mapStyles([0, 1, 2, 1]);
            break;
        case 4:
            mapStyles([0, 1, 2, 3]);
            break;
        default:
            break;
    }

    return ret;
};
const parseShorthandMargins = (style) => {
    const marginCase = style.margin ? 'margin' : style.MARGIN ? 'MARGIN' : false;
    let margin;

    if (marginCase) {
        margin = parseMargin(style[marginCase]);

        for (let key in margin) {
            style['margin-' + key] = margin[key];
        }

        delete style[marginCase];
    }
};

const parseFiltered = [
    'break-before',
    'break-after',
    'break-inside',
    'page-break',
    'page-break-before',
    'page-break-after',
    'page-break-inside'
];
const parseFilter = (stylesObj) => {
    const newObj = {};

    for (let style in stylesObj) {
        if (parseFiltered.indexOf(style) === -1) {
            newObj[style] = stylesObj[style];
        }
    }

    return newObj;
};

const parseStyles = (styles) => {
    const getStyles = (cssText) => {
        const startIndex = cssText.indexOf('{');
        const endIndex = cssText.indexOf('}');

        return parseCssText(cssText.substring(startIndex + 1, endIndex), true);
    };
    const parsedStyles = [];

    let rules;

    if (styles.sheet) {
        rules = styles.sheet.cssRules;

        for (let i = 0; i < rules.length; i++) {
            if (rules[i].type === window.CSSRule.STYLE_RULE) {
                parsedStyles.push({
                    selector: rules[i].selectorText,
                    styles: parseFilter(getStyles(rules[i].cssText))
                });
            }
        }
    }

    return parsedStyles;
};
const sortStyles = (stylesArray) => {
    const isClassSelector = (selector) => {
        return ('' + selector).indexOf('.') !== -1;
    };
    const getCompareFunction = (styles) => {
        const order = styles.map(item => item.selector);

        return function(style1, style2) {
            const value1 = isClassSelector(style1.selector) ? 1 : 0;
            const value2 = isClassSelector(style2.selector) ? 1 : 0;

            const result = value2 - value1;
            return result !== 0 ? result : order.indexOf(style2.selector) - order.indexOf(style1.selector);
        };
    };

    return stylesArray.sort(getCompareFunction(stylesArray));
};
function inlineExtend(target) {
    let argsLength = arguments.length;

    let overwrite;
    let propertiesList;

    if (typeof (overwrite = arguments[argsLength - 1]) == 'boolean') {
        argsLength--;
    } else if (typeof (overwrite = arguments[argsLength - 2]) == 'boolean') {
        propertiesList = arguments[argsLength - 1];
        argsLength -= 2;
    }

    for (let i = 1; i < argsLength; i++) {
        let source = arguments[i] || {};

        Object.keys(source).forEach(propertyName => {
            if (overwrite === true || target[propertyName] == null) {
                if (!propertiesList || (propertyName in propertiesList))
                    target[propertyName] = source[propertyName];
            }
        });
    }

    return target;
}

const inlineStyles = (document) => {
    const parseStyleTags = (stylesTags) => {
        let styles = [];

        for (let i = 0; i < stylesTags.length; i++) {
            styles = styles.concat(parseStyles(stylesTags[i]));
        }

        return styles;
    }
    const applyStyle = (document, selector, style) => {
        const elements = document.querySelectorAll(selector);
        let element;

        let oldStyle;
        let newStyle;

        parseShorthandMargins(style);

        for (let i = 0; i < elements.length; i++) {
            element = elements[i];
            oldStyle = parseCssText(element.getAttribute('style'));

            parseShorthandMargins(oldStyle);
            newStyle = inlineExtend({}, oldStyle, style);

            element.style = writeCssText(newStyle);
        }
    };

    let stylesTags = document.querySelectorAll('style');
    let stylesArray = sortStyles(parseStyleTags(stylesTags));

    stylesArray.forEach(style => {
        applyStyle(document, style.selector, style.styles);
    });

    return document;
};

const isListItem = (element) => {
    // eslint-disable-next-line no-useless-escape
    return element.attributes.style && element.attributes.style.value.match(/mso\-list:\s?l\d/) && element.parentNode.tagName !== 'li';
};
const parseCssText = (styleText, normalize, nativeNormalize) => {
    const response = {};

    if (nativeNormalize) {
        let temp = document.createElement('span');

        temp.setAttribute('style', styleText);
        styleText = temp.getAttribute('style') || '';
    }

    if (!styleText || styleText === ';') {
        return response;
    }

    styleText.replace(/&quot;/g, '"').replace(/\s*([^:;\s]+)\s*:\s*([^;]+)\s*(?=;|$)/g, (match, name, value) => {
        if (normalize) {
            name = name.toLowerCase();

            if (name === 'font-family') {
                value = value.replace(/\s*,\s*/g, ',');
            }

            value = value.trim();
        }

        response[name] = value;
    });

    return response;
};
const writeCssText = (styles, sort) => {
    let name;
    const stylesArr = [];

    for (name in styles) {
        stylesArr.push(name + ':' + styles[name]);
    }

    if (sort) {
        stylesArr.sort();
    }

    return stylesArr.join('; ');
};

const checkListItemSymbol = (element) => {
    let symbol;

    Array.from(element.querySelectorAll('*')).forEach((element) => {
        if (!symbol && element.name === 'img' && element.attributes['cke-ignored'] && element.attributes.alt === '*') {
            symbol = '·';
            element.remove();
        }
    }, 1);

    Array.from(element.querySelectorAll('*')).forEach((element) => {
        if (!symbol && element.innerText.length <= 3 && !element.innerText.match(/^ /)) {
            symbol = element.innerText;
        }
    }, 3);

    if (typeof symbol == 'undefined') {
        return;
    }

    element.attributes['symbol'] = symbol.replace(/(?: |&nbsp;).*$/, '');

    const node = Array.from(element.querySelectorAll('*')).find((node) => {
        return node.innerText && node.innerText.indexOf(element.attributes['symbol']) > -1;
    }, true);

    let parent;

    if (node) {
        node.innerText = node.innerText.replace(element.attributes['symbol'], '');
        parent = node.parentNode;

        if (parent.outerHTML.match(/^(\s|&nbsp;)*$/) && parent !== element) {
            parent.remove();
        } else if (!node.innerText) {
            node.remove();
        }
    }
};
const checkListItemLevel = (element) => {
    const propValue = parseCssText(element.attributes.style?.value)['mso-list'];
    element.attributes['level'] = propValue.match(/level(.+?)\s+/)[1];
};

const groupLists = (listElements) => {
    const lists = [[listElements[0]]];

    let lastList = lists[0];
    let element = listElements[0];

    for (let i = 1; i < listElements.length; i++) {
        const previous = listElements[i - 1];

        element = listElements[i];

        if (element.previousElementSibling !== previous) {
            lists.push(lastList = []);
        }

        lastList.push(element);
    }

    return lists;
};
const createList = (element) => {
    if ((element.attributes['symbol'].match(/([\da-np-zA-NP-Z]).?/) || [])[1]) {
        return document.createElement('ol');
    }

    return document.createElement('ul');
};

const getSubsectionSymbol = (symbol) => {
    return (symbol.trim().match(/([\da-zA-Z]+).?$/) || ['placeholder', '1'])[1];
};
const removeRedundancies = (style, level) => {
    if ((level === 1 && style['list-style-type'] === 'disc') || style['list-style-type'] === 'decimal') {
        delete style['list-style-type'];
    }
};
const setListSymbol = (list, symbol, level) => {
    level = level || 1;
    const style = parseCssText(list.attributes.style?.value);

    if (list.tagName.toLowerCase() === 'ol') {
        if (list.attributes.type || style['list-style-type']) {
            return;
        }

        const typeMap = {
            '[ivx]': 'lower-roman',
            '[IVX]': 'upper-roman',
            '[a-z]': 'lower-alpha',
            '[A-Z]': 'upper-alpha',
            '\\d': 'decimal'
        };

        for (let type in typeMap) {
            if (getSubsectionSymbol(symbol).match(new RegExp(type))) {
                style['list-style-type'] = typeMap[type];
                break;
            }
        }

        list.attributes['cke-list-style-type'] = style['list-style-type'];
    } else {
        const symbolMap = {
            '·': 'disc',
            'o': 'circle',
            '§': 'square'
        };

        if (!style['list-style-type'] && symbolMap[symbol]) {
            style['list-style-type'] = symbolMap[symbol];
        }

    }

    removeRedundancies(style, level);
    (list.style = writeCssText(style)) || delete list.style;
};

export default function pasteFromWord(html) {
    html.replace(/<!\[/g, '<!--[').replace(/\]>/g, ']-->');

    const parser = new DOMParser();
    let document = parser.parseFromString(html, 'text/html');

    const listElements = [];

    document.body.querySelectorAll("*").forEach((element) => {
        if (!isListItem(element)) {
            return;
        }

        checkListItemSymbol(element);
        checkListItemLevel(element);

        listElements.push(element);
    });

    const lists = listElements.length !== 0 ? groupLists(listElements) : [];

    lists.forEach((list) => {
        const containerStack = [createList(list[0])];
        let innermostContainer = containerStack[0];

        list[0].parentNode.insertBefore(innermostContainer, list[0]);

        for (let i = 0; i < list.length; i++) {
            const element = list[i];
            const level = element.attributes['level'];

            while (level > containerStack.length) {
                const content = createList(element);
                const children = innermostContainer.children;

                if (children.length > 0) {
                    children[children.length - 1].append(content);
                } else {
                    const container = document.createElement('li', {
                        style: 'list-style-type:none'
                    });

                    container.append(content);
                    innermostContainer.append(container);
                }

                containerStack.push(content);
                innermostContainer = content;

                if (+level === containerStack.length) {
                    setListSymbol(content, element.attributes['symbol'], level);
                }
            }

            while (level < containerStack.length) {
                containerStack.pop();
                innermostContainer = containerStack[containerStack.length - 1];

                if (+level === containerStack.length) {
                    setListSymbol(innermostContainer, element.attributes['symbol'], level);
                }
            }

            element.remove();
            innermostContainer.append(element);
        }
    });

    listElements.forEach((element) => {
        element.outerHTML = element.outerHTML.replaceAll('<p','<li').replaceAll('p>', 'li>');
    });

    document = inlineStyles(document);
    document.body.querySelectorAll("*").forEach((element) => {
        if (element.tagName.toLowerCase() === 'table') {
            let isBorder = false;

            element.style = '';
            element.className = '';
            element.width = 'auto';

            element.querySelectorAll('tr').forEach(tr => {
                tr.style = '';
            });

            element.querySelectorAll('td').forEach(td => {
                if (td.style.border !== 'none') {
                    isBorder = true;
                }

                td.className = '';
                td.width = 'auto';

                td.style.removeProperty('width');
                td.style.removeProperty('height');

                td.style.removeProperty('border');
                td.style.borderColor = '#D8D8D8';
            });

            if (isBorder) {
                element.border = 1;
            }
        }

        if (element.tagName.toLowerCase() === 'li') {
            element.style.removeProperty('text-indent');
            element.style.removeProperty('margin');
        }
    });

    return document.body.outerHTML;
};
