import React from 'react';
import { useEditorContext } from '../../context';
import { EEmbedDrawIoMode, useEmbedDrawIoDialogStore, EmbedDrawIoDialogModal } from '../../extensions/EmbedDrawIo/index'
import { MenuButton } from './MenuButton';
import { Icons } from 'uikit/icon';

export const MenuButtonEmbedDrawIo = ({ mode, setCurrentMenu, isTooltip }) => {

    const editor = useEditorContext();
    const { isEditorDialogOpen } = useEmbedDrawIoDialogStore();
    
    const setDialogMode = useEmbedDrawIoDialogStore(state => state.setMode);

    const invokeDialog = () => {
        setCurrentMenu(undefined);
        setDialogMode(EEmbedDrawIoMode.EDIT_MODE);
        editor?.commands.involveEmbedDrawIoDialog(true, null);
    }

    return (
        <div>
            <MenuButton
                icon={ Icons.EditorIconDiagram }
                tooltip={ isTooltip === false ? null : 'Создать диаграмму Draw.io'}
                label='Создать диаграмму Draw.io'
                onClick={ invokeDialog }
            />
            {
                isEditorDialogOpen && (
                    <EmbedDrawIoDialogModal 
                        isOpen={ isEditorDialogOpen } 
                        device={ mode } 
                    />
                )
            }
        </div>
    );
};
