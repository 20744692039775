import React, { useCallback, useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { NODE_TYPES, ScriptingTree } from 'components/trees';
import { useGlobalContext } from 'lib/hooks';
import TreeUtils from 'lib/util/tree.util';
import { selectNewsPicker } from 'slice/newsPickerSlice';
import cx from './scripts-wrapper.module.scss';
import { Outlet, useNavigate, useParams } from 'shared/router';
import Icon, { Icons } from 'uikit/icon';

// export const ScriptsTreeContext = React.createContext(null);
export const ScriptsTreeContext = React.createContext({
    reloadTree: () => {},
    setOnReloadTreeAction: () => {},
});

function ScriptsWrapper({ children }) {
    const { platform } = useGlobalContext();
    const newsPicker = useSelector(selectNewsPicker);

    const { projectUuid, sectionUuid } = useParams();
    const navigate = useNavigate();

    const treeRef = useRef(null);
    const [node, setNode] = useState(null);
    const [showTree, setShowTree] = useState(platform !== 'mobile' ? true : false);

    const [onReloadTreeAction, setOnReloadTreeAction] = useState(() => () => {});
    const [isReload, setIsReload] = useState(false);

    const reloadTree = (withAction = true) => {
        setIsReload(prev => !prev);

        if (withAction && onReloadTreeAction) {
            onReloadTreeAction();
        }
    };

    const onNavigate = useCallback(
        treeNode => {
            if (!treeNode) {
                navigate('/scripting', { replace: true });
            } else if (treeNode.nodeType === NODE_TYPES.PROJECT) {
                navigate(`/scripting/${treeNode.id}`, { replace: true });
            } else if (treeNode.nodeType === NODE_TYPES.SECTION) {
                navigate('/scripting/' + TreeUtils.getTopParentNode(treeNode).id + '/' + treeNode.id.toLowerCase().replace(/_/g, '-'), { replace: true });
            } else if (treeNode.nodeType === NODE_TYPES.SCRIPTS) {
                navigate('/scripting/script/' + treeNode.id, { replace: true });
            } else if (treeNode.nodeType === NODE_TYPES.ARTICLE) {
                navigate(`/projects/article/${treeNode.id}`, { replace: true });
            }
        },
        [navigate]
    );

    const getNodeLink = useCallback(treeNode => {
        if (!treeNode) {
            return '/scripting';
        } else if (treeNode.nodeType === NODE_TYPES.PROJECT) {
            return '/scripting/' + treeNode.id;
        } else if (treeNode.nodeType === NODE_TYPES.SECTION) {
            return '/scripting/' + TreeUtils.getTopParentNode(treeNode).id + '/' + treeNode.id.toLowerCase().replace(/_/g, '-');
        } else if (treeNode.nodeType === NODE_TYPES.SCRIPTS) {
            return '/scripting/script/' + treeNode.id;
        } else if (treeNode.nodeType === NODE_TYPES.ARTICLE) {
            return `/projects/article/${treeNode.id}`;
        }
    }, []);

    useEffect(() => {
        if (sectionUuid) {
            if (node !== sectionUuid) {
                setNode(sectionUuid);
            }
        } else if (projectUuid) {
            if (node !== projectUuid) {
                setNode(projectUuid);
            }
        }
    }, [projectUuid, sectionUuid, node]);

    return (
        <ScriptsTreeContext.Provider value={{ tree: treeRef, reloadTree, setOnReloadTreeAction }}>
            <div className={classNames(cx.pageWrapper, { [cx.mobile]: platform === 'mobile' })}>
                {platform === 'desktop' && (
                    <div
                        className={classNames(cx.tree, {
                            [cx.withNewsTicker]: newsPicker.count > 0,
                            [cx.treeHidden]: !showTree,
                        })}
                    >
                        <div className={cx.hideButton} onClick={() => setShowTree(isShow => !isShow)}>
                            <Icon className={cx.icon} type={Icons.ARROW_RIGHT} width={14} height={14} color="blue" />
                        </div>
                        <ScriptingTree
                            scrollToNode={node ? node : null}
                            onNavigate={onNavigate}
                            ref={treeRef}
                            getNodeLink={getNodeLink}
                            isReload={isReload}
                            reloadTree={reloadTree}
                            setShowTree={setShowTree}
                        />
                    </div>
                )}
                <section
                    className={classNames(cx.contentWrapper, {
                        [cx.withNewsTicker]: newsPicker.count > 0,
                        [cx.contentWithoutTree]: !showTree,
                    })}
                >
                    {!showTree && (
                        <span className={cx.showButton} onClick={() => setShowTree(isShow => !isShow)}>
                            <Icon className={cx.icon} type={Icons.ARROW_RIGHT} width={14} height={14} />
                        </span>
                    )}
                    <Outlet>{children}</Outlet>
                </section>
            </div>
        </ScriptsTreeContext.Provider>
    );
}

export default ScriptsWrapper;
