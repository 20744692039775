import React, { useContext, useEffect } from 'react';
import { ContentWrapper } from 'containers/content-wrapper';
import { TrainingTabOpen, TrainingTabAll, TrainingTabCatalog, TrainingTheme, TrainingQuestion, TrainingTest } from 'components/training';
import { MENU_CONTENT_TYPE, MobileMenuContext } from 'containers/menu-wrapper/menu-wrapper';
import { RouteGroup, Route } from 'shared/router';

export default function TrainingPage() {
    const { setMenuContentType } = useContext(MobileMenuContext);
    useEffect(() => {
        if (!setMenuContentType) {
            return;
        }

        setMenuContentType(MENU_CONTENT_TYPE.MAIN_MENU);
    }, [setMenuContentType]);

    return (
        <ContentWrapper>
            <RouteGroup>
                <Route isDefault component={<TrainingTabOpen />} />
                <Route to="/all" component={<TrainingTabAll />} />
                <Route to="/catalog" component={<TrainingTabCatalog />}>
                    <Route to="/theme" component={<TrainingTheme />} />
                    <Route to="/theme/:id" component={<TrainingTheme />} />
                    <Route to="/theme/:id/edit" component={<TrainingTheme isEdit={true}/>} />
                    <Route to="/question" component={<TrainingQuestion />} />
                    <Route to="/question/:id" component={<TrainingQuestion />} />
                    <Route to="/question/:id/edit" component={<TrainingQuestion isEdit={true}/>} />
                </Route>
                <Route to="/test" component={<TrainingTest />} />
                <Route to="/test/:id" component={<TrainingTest />} />
                <Route to="/test/:id/users" component={<TrainingTest />} />
                <Route to="/test/:id/reports" component={<TrainingTest />} />
                <Route to="/test/:id/edit" component={<TrainingTest isEdit={true}/>} />
            </RouteGroup>
        </ContentWrapper>
    );
};
