import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'shared/router';
import { useSelector } from 'react-redux';
import { selectUsers } from 'slice/authSlice';
import { useEventListener, useMessage } from 'lib/hooks';
import ScrollTopBtn from 'components/scroll-top-btn';
import { EmptyActions } from 'components/projects/empty';
import Loading from 'uikit/loading';
import Filter from 'uikit/filter/filter';
import { ActivityTypes, Months, Days } from 'uikit/activity';
import api from 'api';
import cx from './action-log.module.scss';

const eventTypesOptions = [
    { label: 'Создание', value: 'CREATE' },
    { label: 'Изменение', value: 'EDIT' },
    { label: 'Удаление', value: 'DELETE' },
    { label: 'Аутентификация', value: 'AUTHENTICATION' },
    { label: 'Восстановление', value: 'RESTORE_SNAPSHOT' }
];

const actionTypesOptions = [
    { label: 'Системные', value: 'SYSTEM' },
    { label: 'Пользовательские', value: 'USER' },
    { label: 'Все', value: 'ALL' },
];

export default function ActionLog ({ treeRef }) {
    const navigate = useNavigate();
    const { id } = useParams();

    const selector = useSelector(state => state);

    const { addError } = useMessage();

    const [isTreeInit, setIsTreeInit] = useState(false);
    const [isFetched, setIsFetched] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const [logs, setLogs] = useState([]);
    const [users, setUsers] = useState(null);
    const [logsData, setLogsData] = useState([]);
    const [page, setPage] = useState(0);

    const isInitRef = useRef(false);

    const [scrollTop ,setScrollTop] = useState(0);
    const [filtersData, setFiltersData] = useState({});

    const loadUsers = useCallback(async (search) => {
        let options = [];

        if (search) {
            options = users.filter(user => {
                return user.label.toString().toLowerCase().includes(search.toLowerCase());
            });
        }

        return { options };
    }, [users]);
    const filters = useMemo(() => [
        {
            'label': 'Дата создания',
            'fields': [
                {
                    'name': 'createTime',
                    'type': 'date-select',
                    'default': {
                        'type': {
                            label: 'Все время',
                            value: 0
                        },
                        'from': null,
                        'to': null
                    }
                }
            ]
        },
        {
            'label': 'Активности',
            'fields': [
                {
                    'name': 'eventTypes',
                    'type': 'select',
                    'default': null,
                    'isMulti': true,
                    'options': eventTypesOptions
                }
            ]
        },
        {
            'label': 'Участники',
            'fields': [
                {
                    'name': 'users',
                    'type': 'search',
                    'default': null,
                    'isMulti': true,
                    'disabled': false,
                    'loadOptions': loadUsers
                }
            ]
        },
        {
            'label': 'Тип действия',
            'fields': [
                {
                    'name': 'actionType',
                    'type': 'select',
                    'default': {
                        label: 'Все',
                        value: 'ALL'
                    },
                    'isMulti': false,
                    'options': actionTypesOptions
                }
            ]
        },
    ], [loadUsers]);

    const onChangeFilters = (data) => {
        navigate('?page=0&scroll=0');

        setPage(0);
        setLogs([]);

        setFiltersData(data);
    };
    const onScroll = (e) => {
      const scrollTop = Math.round(e.target.scrollTop);
      const delta = e.target.scrollHeight - e.target.clientHeight;

      if (Math.abs(scrollTop - delta) <= 1 && !isFetching) {
            navigate('?page=' + page + '&scroll=' + scrollTop);
            setPage(page + 1);
        }

        setScrollTop(scrollTop);
    };

    const onTreeUpdate = useCallback(async () => {
        document.getElementById('content').scrollTo(0, 0);

        setLogs([]);
        setLogsData([]);
        setPage(0);
    }, []);

    useEventListener('scroll', onScroll, document.getElementById('content'));
    useEffect(() => {
        if (isInitRef.current === false) {
            isInitRef.current = true;
            return;
        }

        const items = [];
        const current = new Date();

        const currentYear = current.getFullYear();
        const currentMonth = current.getMonth();
        const currentDay = current.getDate();

        let lastItem;
        // let lastKey;

        logs.map((item) => {
            const itemDate = new Date(item['timestamp']);

            const itemYear = itemDate.getFullYear();
            const itemMonth = itemDate.getMonth();
            const itemDay = itemDate.getDate();

            const timestamp = new Date(itemYear, itemMonth, itemDay).getTime() / 1000;
            let key = items.findIndex((item) => item.timestamp === timestamp);

            if (key === -1) {
                if (itemYear === currentYear && itemMonth === currentMonth && itemDay === currentDay) {
                    items.push({
                        timestamp: timestamp,
                        title: 'Сегодня',
                        blocks: [{
                            id: item['userInfo']?.['id'],
                            login: item['userInfo']?.['login'],
                            firstName: item['userInfo']?.['firstName'] ?? 'System',
                            lastName: item['userInfo']?.['lastName'] ?? 'System',
                            avatarUuid: item['userInfo']?.['avatarUuid'],
                            activated: item['userInfo']?.['activated'],
                            items: []
                        }]
                    });
                } else {
                    items.push({
                        timestamp: timestamp,
                        title: Days[itemDate.getDay()] + ', ' + itemDay + ' ' + Months[itemMonth],
                        blocks: [{
                            id: item['userInfo']?.['id'],
                            login: item['userInfo']?.['login'],
                            firstName: item['userInfo']?.['firstName'] ?? 'System',
                            lastName: item['userInfo']?.['lastName'] ?? 'System',
                            avatarUuid: item['userInfo']?.['avatarUuid'],
                            activated: item['userInfo']?.['activated'],
                            items: []
                        }]
                    });
                }

                key = items.length - 1;
            } else {
                if (lastItem && lastItem['userInfo']?.['id'] !== item['userInfo']?.['id']) {
                    items[key]['blocks'].push({
                        id: item['userInfo']?.['id'],
                        login: item['userInfo']?.['login'],
                        firstName: item['userInfo']?.['firstName'] ?? 'System',
                        lastName: item['userInfo']?.['lastName'] ?? 'System',
                        avatarUuid: item['userInfo']?.['avatarUuid'],
                        activated: item['userInfo']?.['activated'],
                        items: []
                    });
                }
            }
            lastItem = item;
            // lastKey = key;

            // Add log to user;
            let hours = itemDate.getHours();
            item['hours'] = hours.toString().length === 1 ? '0' + hours : hours;

            let minutes = itemDate.getMinutes();
            item['minutes'] = minutes.toString().length === 1 ? '0' + minutes : minutes;

            items[key]['blocks'][items[key]['blocks'].length - 1]['items'].push(item);
            return item;
        });
        setLogsData(items);

        setIsFetching(false);
        setIsFetched(true);
    }, [logs]);

    useEffect(() => {
        if (!isInitRef.current) {
          return;
        }

        let cancelToken = axios.CancelToken.source();

        async function fetchData() {
            try {
                setIsFetching(true);
                const response = await api.logging.getLogs({
                    size: 20,
                    page: page,
                    filters: filtersData,
                    objectUuid: id,
                    cancelToken: cancelToken?.token,
                });

                if (response) {
                    setLogs((logs) => logs.concat(response));
                }
            } catch (e) {
                console.log(e);
                addError('Сервис недоступен. Пожалуйста попробуйте позже.');
            }
        }

        if (cancelToken?.token) {
            fetchData();
        }

        return () => {
            if (cancelToken) {
                setIsFetching(true);
                cancelToken.cancel();
            }
        };
    }, [addError, id, page, filtersData]);
    useEffect(() => {
        const fetch = () => {
            if (!id) {
                return;
            }

            const tree = treeRef?.current?.tree;

            if (tree && tree.nodes.length !== 0) {
                let node = tree.getNodeById(id);
                let nodes = [];

                while (true) {
                    if (!node.id) {
                        break;
                    }
                    nodes.push(node);
                    node = node.parent;
                }

                nodes.reverse().forEach((node) => {
                    tree.openNode(node, { silent: true });
                    tree.selectNode(node, { silent: true });
                });
            } else {
                setTimeout(fetch, 300);
            }
        };
        fetch();
    }, [treeRef, id]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await selectUsers(selector);
            setUsers(response.content.map((user) => {
                return { label: user.firstName + ' ' + user.lastName, value: user.login };
            }));
        };
        fetchData();
    }, [selector]);

    /*useEffect(() => {
        const changeFilterParam = (fieldName, paramName, paramValue) => {
            setFilters((prevState) => {
                const filtersCopy = Object.assign([], prevState);

                filtersCopy.map(filter => {
                    filter['fields'] = filter['fields'].map(field => {
                        return field['name'] === fieldName ? {...field, [paramName]: paramValue } : field
                    });

                    return filter;
                });

                return filtersCopy;
            });
        };

        if (filtersData['actionType'] && filtersData['actionType']['value'] === 'SYSTEM') {
            filtersData['users'] = [];
            changeFilterParam('users', 'disabled', true)

            setFiltersData(filtersData);
        } else {
            changeFilterParam('users', 'disabled', false)
        }
    }, [filtersData]);*/
    useEffect(() => {
        const fetchData = async () => {
            setIsFetching(true);

            const params = new URLSearchParams(document.location.search);

            const requestPage = +params.get('page');
            const requestScroll = +params.get('scroll');

            if (!requestPage || !requestScroll) {
                return;
            }

            const response = await api.logging.getLogs({
                size: 20 * requestPage,
                page: 0,
                filters: filtersData,
                objectUuid: id
            });

            setLogs((logs) => logs.concat(response));
            setPage(requestPage);

            setTimeout(() => {
                document.getElementById('content').scrollTop = requestScroll;
            }, 1000);
        };

        fetchData();
    }, [id, filtersData]);

    if (!isTreeInit && treeRef?.current?.tree) {
        treeRef.current.tree.on('selectNode', onTreeUpdate);
        setIsTreeInit(true);
    }

    return (
        <div className={cx.container}>
            {!isFetched &&
            <Loading withOverlay={false} withRelativeOverlay={true}/>}

            {isFetched && logsData.length === 0 && (
                <EmptyActions />
            )}

            {isFetched && (logsData.length !== 0 || Object.keys(filtersData).length !== 0) &&
            <>
                <div className={cx.filter}>
                    <Filter id={'actionsFilters'} filters={filters} filtersData={filtersData}
                            onFilters={(data) => onChangeFilters(data)}/>
                </div>
                <div className={cx.items}>
                    {logsData.map((item, itemIndex) => {
                        return (
                            <div key={itemIndex} className={cx.item}>
                                <h3>{item.title}</h3>
                                <div className={cx.users}>
                                    {item.blocks.map((user, userIndex) => {
                                        return (
                                            <div key={userIndex} className={cx.user}>
                                                <div className={cx.head}>
                                                    <div className={cx.avatar}>
                                                        <img src={user['avatarUuid'] ? api.upload.getImage(user['avatarUuid'], false, 128) : '/img/avatar.png'}
                                                             alt=""/>
                                                    </div>
                                                    {user['activated'] && (
                                                        <Link to={'/edit-user/' + user.login}>
                                                            {user.firstName} {user.lastName}
                                                        </Link>
                                                    )}
                                                    {!user['activated'] && (
                                                        <span style={{ opacity: 1 }}>
                                                            {user.firstName} {user.lastName}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className={cx.records}>
                                                    {user.items.map((record, recordIndex) => {
                                                        return (
                                                            <div key={recordIndex} className={cx.record}>
                                                                <span className={cx.recordTime}>{record.hours}:{record.minutes}</span>
                                                                {ActivityTypes[record.resourceType + '.' + record.type]
                                                                    ? ActivityTypes[record.resourceType + '.' + record.type](record)
                                                                    : record.resourceType + '.' + record.type}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </>}
            {isFetched && isFetching &&
            <div className={cx.loader}>
                <Loading withOverlay={false} small/>
            </div>}
            <ScrollTopBtn isSeen={isFetched && scrollTop > 20} scrollId={'content'}/>
        </div>
    );
};
