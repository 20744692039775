import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import TagsInput from 'react-tagsinput';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { selectParent } from 'slice/treeSlice';
import { useMessage } from 'lib/hooks/useMessage';
import { useGlobalContext, useManualFormDataChange } from 'lib/hooks';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import { ArticleFormModel, getInitialExactQueryGroupsItem } from 'model/article';
import { TreePropContext } from 'containers/side-tree-wrapper';
import Card from 'components/card';
import { NODE_TYPES } from 'components/trees';
import InputImage from 'components/input-image';
import Attachments from 'components/attachments';
import LocationChange from 'components/location-change';
import Input from 'uikit/input';
import Button from 'uikit/button';
import Switch from 'uikit/switch';
import Divider from 'uikit/divider';
import TextArea from 'uikit/textarea';
import Icon, { Icons } from 'uikit/icon';
import Editor from 'editors/editor';
import IconButton from 'uikit/icon-button';
import { DateTimePicker } from 'uikit/datetime';
import api from 'api/index';
import cx from './article-form.module.scss';
import { cloneDeepWith } from 'lodash';
import { useConfiguration } from '../../lib/context';

const ArticleForm = ({ article, loading = false, children, edit = false }) => {
    const articleRef = useRef(article);
    const treePropsContext = React.useContext(TreePropContext);
    const editorRef = useRef(null);

    const { addError } = useMessage();
    const parent = useSelector(selectParent);

    const { isAiChatEnabled, isEditorExperimentalSwitch, getEditorType } = useConfiguration();

    const { platform, formDataChanged, setFormDataChanged, setFormDataChangedSubmitHandler } = useGlobalContext();
    const { isFormDataTouched, handleManualFormDataChange } = useManualFormDataChange(edit);

    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [isLocationClearedManually, setIsLocationClearedManually] = useState(false);

    const [isLogoEdit, setIsLogoEdit] = useState(false);
    const [dueTo, setDueTo] = useState(!!article.from);
    const [aiIndexingEnabled, setAiIndexingEnabled] = useState(!!article.chatAIIndexingEnabled);

    const _onDestinationChange = node => {
        handleManualFormDataChange();
        if (node.nodeType !== NODE_TYPES.SECTION) {
            addError('Для выбора доступны только разделы.');
        } else {
            let sectionId = node.id;
            let projectId;

            function traverseNode(_node) {
                if (_node.nodeType === NODE_TYPES.PROJECT) {
                    projectId = _node.id;
                } else {
                    traverseNode(_node.parent);
                }
            }

            traverseNode(node);

            article.parentProjectId = projectId;
            article.parentSectionId = sectionId;

            article.setParentsIds(node);
            article.setVisualPath(node);

            article.setTargetNodeId(node.id);
            article.commit();

            article.validateTitleExists();
            article.validateFields(['location']);
        }
    };

    const onClearPath = () => {
        handleManualFormDataChange();
        article.clearLocation();
        article.commit();
        setIsLocationClearedManually(true);
    };

    const onLogoRemove = () => {
        article.logo = '';
        article.logoUuid = '';
        article.commit();
    };
    const onLogoChange = logo => {
        handleManualFormDataChange();
        article.logo = logo;
        article.commit();
        setIsLogoEdit(false);
    };

    const handleMinimize = () => {
        const { reloadTree } = treePropsContext;
        reloadTree();
    };

    const onexactQueryGroupsItemDelete = index => {
        if (article.exactQueryGroups.length === 1) {
            article.exactQueryGroups = [getInitialExactQueryGroupsItem()];
        } else {
            article.exactQueryGroups.splice(index, 1);
        }
        article.commit();
    };
    const onexactQueryGroupsAdd = () => {
        article.exactQueryGroups.push(getInitialExactQueryGroupsItem());
        article.commit();
    };

    const onTitleFocus = () => {
        article.touched.title = true;
    };
    const onTitleBlur = () => {
        if (article.parentSectionId) {
            article.validateTitleExists();
        }
    };
    const onTitleChange = value => {
        handleManualFormDataChange();

        const re = new RegExp(String.fromCharCode(160), 'g');
        article.title = value.replace(re, ' ');

        article.validateFields(['title']);
        article.commit();
    };

    const onAiIndexingChange = e => {
        console.log(e.target.checked);
        setAiIndexingEnabled(e.target.checked);
        article.chatAIIndexingEnabled = e.target.checked;
        article.commit();
    };

    const onDescriptionFocus = () => {
        article.touched.description = true;
    };
    const onDescriptionChange = value => {
        handleManualFormDataChange();

        const re = new RegExp(String.fromCharCode(160), 'g');
        article.description = value.replace(re, ' ');

        article.validateFields(['description']);
        article.commit();
    };

    const onEditorSaveInstance = editor => {
        article.setEditorInstance(editor);
    };

    const onPickerClick = () => {
        setIsPickerOpen(!isPickerOpen);
    };
    const onAttachmentsChange = files => {
        handleManualFormDataChange();

        article.setFiles(files);
        article.commit();
    };

    const onAttachmentsCollapsedChange = () => {
        article.changeAttachmentsCollapsed();
        article.commit();
    };

    useEffect(() => {
        if (!loading) {
            articleRef.current = cloneDeepWith(article, (value, key) => {
                if (key === 'editor') {
                    return value;
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);
    useEffect(() => {
        if (!loading) {
            const {
                logo: refLogo,
                title: refTitle,
                description: refDescription,
                meta: { visualPath: refVisualPath },
                from: refFrom,
                originalLifeTime: refOriginalLifeTime,
            } = articleRef.current;
            const {
                logo,
                title,
                description,
                meta: { visualPath },
                from,
                originalLifeTime,
            } = article;
            const checkedRefParams = [refLogo, refTitle, refDescription, refVisualPath, refFrom, refOriginalLifeTime];
            const checkedParams = [logo, title, description, visualPath, from, originalLifeTime];

            setFormDataChanged(
                !isEqual(checkedParams, checkedRefParams)
                || !isEqual(article.exactQueryGroups, articleRef.current.exactQueryGroups)
                || !isEqual(article.meta.files, articleRef.current.meta.files)
                || !isEqual(article.minusWords, articleRef.current.minusWords)
                || !isEqual(article.documentTags, articleRef.current.documentTags)
                || isFormDataTouched
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        article.title,
        article.description,
        article.exactQueryGroups,
        article.meta.files,
        article.meta.visualPath,
        article.from,
        article.lifeTime,
        article.originalLifeTime,
        article.minusWords,
        article.documentTags,
        article.logo,
    ]);
    useEffect(() => {
        if (article.exactQueryGroups.length === 0) {
            article.exactQueryGroups.push(getInitialExactQueryGroupsItem());
            article.commit();
        }
        if (article.exactQueryGroups.length !== 0 && article.exactQueryGroups.some(({ exactAnswer }) => !!exactAnswer)) {
            setIsPickerOpen(true);
        }

        setAiIndexingEnabled(!!article.chatAIIndexingEnabled);
    }, [article]);

    // Установка пути песторасположения при создании статьи, когда есть проект/раздел, но путь пустой; И если поле месторасположения не очищено вручную
    useEffect(() => {
        if (
            !article.id &&
            !isLocationClearedManually &&
            article.getVisualPath().length === 0 &&
            parent.visualPath &&
            parent.parentSectionId
        ) {
            article.meta.visualPath = parent.visualPath;
            article.parentProjectId = parent.parentProjectId;
            article.parentSectionId = parent.parentSectionId;
            article.commit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [article, parent]);

    // Сброс до инициализирующих данных при попытке уйти с формы добавления статьи, нажав на тот же пункт в меню - "Статью"
    function resetToInitialValues() {
        return () => {
            article?.editor?.setData?.(null);

            const initialEntity = new ArticleFormModel();
            const initialEntityKeys = Object.keys(initialEntity);
            initialEntityKeys.forEach(key => {
                article[key] = initialEntity[key];
            });
            article.commit();
        };
    }
    useEffect(() => {
        if (!edit) {
            setFormDataChangedSubmitHandler(resetToInitialValues);
        }
        return () => {
            if (!edit) {
                setFormDataChangedSubmitHandler(null);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setDueTo(!!article.from);
    }, [article.from]);

    return (
        <>
            <div className={classNames({ [cx.mobile]: platform === 'mobile' })}>
                <div className={cx.field}>
                    {(article.logo || article.logoUuid) && !isLogoEdit && (
                        <div className={cx.logoWrapper}>
                            <div className={cx.logoOverlay} />
                            <div className={cx.cover} style={{ backgroundImage: `url(${article.logo})` }} />
                            <div className={cx.logoBtnWrapper}>
                                <Button className={cx.logoBtn} color="transparent" size="small" onClick={onLogoRemove}>
                                    Удалить обложку
                                </Button>
                                <InputImage
                                    title="Изменить обложку"
                                    btn={true}
                                    btnClassName={cx.logoBtn}
                                    btnColor="transparent"
                                    btnSize="small"
                                    onChange={onLogoChange}
                                />
                            </div>
                        </div>
                    )}
                    {((!article.logo && !article.logoUuid) || isLogoEdit) && <InputImage onChange={onLogoChange} title="Обложка статьи" />}
                </div>
                <Card id="articleFormCard" className={cx.form}>
                    <Input
                        id="articleFormTitleInput"
                        className={cx.field}
                        type="text"
                        label="Заголовок"
                        value={article.title}
                        onChange={onTitleChange}
                        disabled={loading}
                        required
                        error={article.errors.titleExists || article.errors.title}
                        onFocus={onTitleFocus}
                        onBlur={onTitleBlur}
                    />
                    <Input
                        id="articleFormDescriptionField"
                        className={cx.field}
                        type="text"
                        label="Краткое описание"
                        value={article.description}
                        disabled={loading}
                        error={article.errors.description}
                        onChange={onDescriptionChange}
                        onFocus={onDescriptionFocus}
                    />
                    <Editor
                        ref={editorRef}
                        key={article.id}
                        id="articleFormEditorContainer"
                        label="Полный текст статьи"
                        editorExperimentalSwitch={isEditorExperimentalSwitch}
                        type={getEditorType()}
                        entity={article}
                        attachments={article.getFiles().filter(f => f.status !== 'deleted')}
                        onEditorSaveInstance={onEditorSaveInstance}
                        initId={article.id ?? article.initId}
                        value={article.text}
                        error={article.errors['text']}
                        onMinimize={handleMinimize}
                        handleManualFormDataChange={handleManualFormDataChange}
                        formDataChanged={formDataChanged}
                        setFormDataChanged={setFormDataChanged}
                    />
                    <Divider />
                    <div className={classNames(cx.blockText, cx.fieldGroup, cx.pickerTitle)} onClick={onPickerClick}>
                        <div>
                            {!isPickerOpen && <Icon type={Icons.ARROW_DOWN} width={12} height={12} />}
                            {isPickerOpen && <Icon type={Icons.CHEVRON_UP} width={12} height={12} />}
                        </div>
                        Точные поисковые ответы
                    </div>
                    {isPickerOpen && (
                        <div className={classNames(cx.exactQueryGroupsBlock)}>
                            {article.exactQueryGroups.map((item, itemIndex) => (
                                <div key={`exactQueryGroups_${itemIndex}`} className={cx.exactQueryGroupsItemBlock}>
                                    <div className={cx.exactQueryGroupsIconBlock}>
                                        <IconButton
                                            icon={<Icon type={Icons.DELETE} width={16} height={16} />}
                                            onClick={() => {
                                                onexactQueryGroupsItemDelete(itemIndex);
                                            }}
                                        />
                                    </div>
                                    <div className={cx.exactQueryGroupsDataBlock}>
                                        <div>
                                            <p className={classNames(cx.fieldLabel, cx.fieldLabelTop)}>Запросы</p>
                                            <TagsInput
                                                inputProps={{ className: 'react-tagsinput-input', placeholder: 'Запросы' }}
                                                value={item.exactQueries}
                                                onChange={exactQueries => {
                                                    handleManualFormDataChange();
                                                    item.exactQueries = exactQueries;
                                                    article.exactQueryGroups[itemIndex] = item;
                                                    article.commit();
                                                    setFormDataChanged(true);
                                                }}
                                            />
                                            <p className={classNames(cx.fieldLabel, cx.fieldLabelBottom)}>
                                                Нажмите Enter чтобы применить ввод в поле
                                            </p>
                                        </div>
                                        <div>
                                            <TextArea
                                                label="Точный ответ на запросы"
                                                value={item.exactAnswer}
                                                onChange={exactAnswer => {
                                                    handleManualFormDataChange();
                                                    item.exactAnswer = exactAnswer;
                                                    article.exactQueryGroups[itemIndex] = item;
                                                    article.commit();
                                                    setFormDataChanged(true);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className={cx.exactQueryGroupsAdd}>
                                <Button className={cx.exactQueryGroupsAddBtn} type="link" onClick={onexactQueryGroupsAdd}>
                                    <Icon type={Icons.PLUS_ADD_FILLED} width={16} height={16} />
                                    <span>Добавить группу запрос-ответ</span>
                                </Button>
                            </div>
                        </div>
                    )}
                    <Divider />
                    <Attachments
                        uploadsId={article.id || article.timeId}
                        files={article.getFiles()}
                        onChange={onAttachmentsChange}
                    />
                    <Divider />
                    <Switch
                        id="articleFormAttachmentsCollapsedSwitch"
                        className={cx.switch}
                        checked={article.attachmentsCollapsed}
                        label="Свернуть блок файлов при просмотре статьи"
                        onClick={onAttachmentsCollapsedChange}
                    />
                    <Divider />
                    <LocationChange
                        title="Выберите месторасположение"
                        onClearPath={onClearPath}
                        visualPath={article.getVisualPath()}
                        onChange={_onDestinationChange}
                        autoOpenPath={article.getParentsIds(true)}
                        error={article.errors.location}
                        required
                        rights={[GLOBAL_ACTIONS.ARTICLE_CREATE]}
                        fetchTreeFunc={api.project.getUsersDocumentTree}
                    />
                    <Divider />
                    <div className={classNames(cx.blockText, cx.fieldGroup)}>
                        <Switch
                            id="articleFormDueToSwitch"
                            className={cx.switch}
                            checked={dueTo}
                            label="Срок действия статьи"
                            onClick={e => {
                                setDueTo(e.target.checked);
                                setFormDataChanged(true);
                            }}
                        />
                    </div>
                    {dueTo && (
                        <div className={cx.blockDates}>
                            <div>
                                <DateTimePicker
                                    disabled={article.publishedType === 'article'}
                                    value={article.from}
                                    label="От"
                                    pickerProps={{ minDate: new Date(), maxDate: new Date('9999-01-01') }}
                                    onChange={from => {
                                        handleManualFormDataChange();
                                        article.from = from;
                                        article.commit();
                                        setFormDataChanged(true);
                                    }}
                                />
                            </div>
                            <div>
                                <DateTimePicker
                                    value={article.lifeTime}
                                    error={article.errors.to}
                                    label="До"
                                    pickerProps={{
                                        minDate: article.from ?? new Date(),
                                        maxDate: new Date('9999-01-01'),
                                    }}
                                    onChange={lifeTime => {
                                        handleManualFormDataChange();
                                        article.lifeTime = lifeTime;
                                        article.originalLifeTime = new Date();
                                        article.commit();
                                        setFormDataChanged(true);
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {isAiChatEnabled && (
                        <>
                            <Divider />
                            <div className={classNames(cx.blockText, cx.fieldGroup)}>
                                <Switch
                                    id="articleFormAiIndexingSwitch"
                                    className={cx.switch}
                                    checked={aiIndexingEnabled}
                                    label="Отправлять на индексацию интерактивному помощнику"
                                    onClick={onAiIndexingChange}
                                />
                            </div>
                        </>
                    )}
                    {children}
                </Card>
            </div>
        </>
    );
};

export default ArticleForm;
