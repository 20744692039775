import { useContext, useState } from 'react';
import { RouterContext } from '../routerProvider';
import { Location } from '../routers/router';

export const useLocation = (): Location => {
    const context = useContext(RouterContext);
    const [location, setLocation] = useState<Location>(context.router.getLocation());

    const currentLocation = context.router.getLocation();

    if (currentLocation.pathname !== location.pathname) {
        setLocation(currentLocation);
    }

    if (!context) {
        throw new Error('useLocation must be used within a RouterProvider');
    }

    return location;
};
