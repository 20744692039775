import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import Modal from 'react-modal';
import { Link } from 'shared/router';
import Button from 'uikit/button';
import { ActivityTypes, Days, Months } from 'uikit/activity';
import Loading from 'uikit/loading';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import api from 'api';
import cs from './resource-log-list.module.scss';
import { useGlobalContext } from 'lib/hooks';
import { desktopStyles, mobileStyles } from 'lib/util/modalStyles';

const ResourceLogList = ({ isOpen, onDismiss, uuid }) => {
    const { platform } = useGlobalContext();

    const [isFetched, setIsFetched] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const logs = useRef([]);
    const [logsData, setLogsData] = useState([]);

    const page = 0;

    React.useEffect(() => {
        async function fetchData() {
            setIsFetching(true);

            const response = await api.logging.getLogs({ size: 20, page: page, objectUuid: uuid });
            const data = page === 0 ? response : logs.current.concat(response);
            const items = [];

            const current = new Date();
            const currentYear = current.getFullYear();
            const currentMonth = current.getMonth();
            const currentDay = current.getDate();

            data.map((item) => {
                const itemDate = new Date(item['timestamp']);
                const itemYear = itemDate.getFullYear();
                const itemMonth = itemDate.getMonth();
                const itemDay = itemDate.getDate();

                const timestamp = new Date(itemYear, itemMonth, itemDay).getTime() / 1000;
                let key = items.findIndex((item) => item.timestamp === timestamp);

                if (key === -1) {
                    if (itemYear === currentYear && itemMonth === currentMonth && itemDay === currentDay) {
                        items.push({
                            timestamp: timestamp,
                            title: 'Сегодня',
                            users: []
                        });
                    } else {
                        items.push({
                            timestamp: timestamp,
                            title: Days[itemDate.getDay()] + ', ' + itemDay + ' ' + Months[itemMonth],
                            users: []
                        });
                    }

                    key = items.length - 1;
                }

                // Check user;
                let userIndex = items[key]['users'].findIndex((user) => user['id'] === item?.['userInfo']?.['id']);

                if (userIndex === -1) {
                    items[key]['users'].push({
                        id: item?.['userInfo']?.['id'],
                        login: item?.['userInfo']?.['login'],
                        firstName: item?.['userInfo']?.['firstName'],
                        lastName: item?.['userInfo']?.['lastName'],
                        avatar: api.upload.getImage(item?.['userInfo']?.['avatarUuid'], false, 128),
                        items: []
                    });
                    userIndex = items[key]['users'].length - 1;
                }

                // Add log to user;
                let hours = itemDate.getHours();
                item['hours'] = hours.toString().length === 1 ? '0' + hours : hours;

                let minutes = itemDate.getMinutes();
                item['minutes'] = minutes.toString().length === 1 ? '0' + minutes : minutes;

                items[key]['users'][userIndex]['items'].push(item);
                return item;
            });

            logs.current = data;
            setLogsData(items);

            setIsFetching(false);
            setIsFetched(true);
        }

        if (isOpen) {
            fetchData();
        }
    }, [uuid, page, isOpen, logs]);

    return (
        <Modal isOpen={isOpen} onRequestClose={onDismiss} contentLabel={'Действия над объектом'} style={platform === 'mobile' ? mobileStyles : desktopStyles}>
            <div className={classNames(cs.modal, {[cs.mobile]: platform === 'mobile'})}>
                <div className={cs.head}>
                    <div className={cs.text}>Действия над объектом</div>
                    <IconButton icon={<Icon type={Icons.CROSS} width={16} height={16}/>}
                                onClick={onDismiss}/>
                </div>
                <div className={cs.content}>
                    {isFetched && logsData.length !== 0 &&
                    <div className={cs.items}>
                        {logsData.map((item, itemIndex) => {
                            return (
                                <div key={itemIndex} className={cs.item}>
                                    <h3>{item.title}</h3>
                                    <div className={cs.users}>
                                        {item.users.map((user, userIndex) => {
                                            return (
                                                <div key={userIndex} className={cs.user}>
                                                    <div className={cs.head}>
                                                        <div className={cs.avatar}
                                                             style={{ backgroundImage: `url(${user.avatar})` }}/>
                                                        <Link to={'/edit-user/' + user.login}>
                                                            {user.firstName} {user.lastName}
                                                        </Link>
                                                    </div>
                                                    <div className={cs.records}>
                                                        {user.items.map((record, recordIndex) => {
                                                            return (
                                                                <div key={recordIndex} className={cs.record}>
                                                                    <span>{record.hours}:{record.minutes}</span>
                                                                    {ActivityTypes[record.resourceType + '.' + record.type]
                                                                        ? ActivityTypes[record.resourceType + '.' + record.type](record)
                                                                        : record.resourceType + '.' + record.type}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>}
                    {isFetching &&
                    <div className={cs.loader}>
                        <Loading withOverlay={false} small/>
                    </div>}
                </div>
                <div className={cs.footer}>
                    <Button onClick={onDismiss} label={'Отмена'} fullWidth={platform === 'mobile'}/>
                </div>
            </div>
        </Modal>
    );
};

export default ResourceLogList;
