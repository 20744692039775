import { Fragment } from '@tiptap/pm/model';
import { NodeSelection, TextSelection } from '@tiptap/pm/state';
import { CellSelection } from './cellSelection';
import { TableMap } from './tableMap';
import {
    addColSpan,
    cellAround,
    cellWrapping,
    columnIsHeader,
    getRow,
    isInTable,
    isRowLastInSection,
    moveCellForward,
    removeColSpan,
    rowPos,
    selectionCell,
    tableDepth,
    tableHasCaption,
    tableHasFoot,
    tableHasHead,
    tableSectionsCount,
} from './utils';
import { isTableSection } from './extension-table/utilities/isTableSection';
import { tableNodeTypes } from './extension-table/utilities/getTableNodeTypes';
import { stringToBoolean } from '../../utils/stringToBool';

export function selectedRect(state) {
    const sel = state.selection;
    const $pos = selectionCell(state);

    if (!$pos) {
        return null;
    }
    const table = $pos.node(-2);
    const tableStart = $pos.start(-2);

    const map = TableMap.get(table);
    const rect =
        sel instanceof CellSelection
            ? map.rectBetween(sel.$anchorCell.pos - tableStart, sel.$headCell.pos - tableStart)
            : map.findCell($pos.pos - tableStart);

    return { ...rect, tableStart, map, table };
}

export function recalculateRowCellsWidth(tr, { map, tableStart, table }, row, width = 45) {
    if (stringToBoolean(table.attrs['data-responsive'] && !table.attrs.width?.includes('%'))) {
        return false;
    }

    const cellsPos = [];

    let totalWidth = 0;
    let totalAvailableWidth = 0;

    for (let c = 0; c < map.width; c++) {
        const index = row * map.width + c;
        const cell = table.nodeAt(map.map[index]);

        if (cell.attrs.colwidth && cell.attrs.colwidth.some(w => w > 45)) {
            for (const w of cell.attrs.colwidth) {
                const width = Number(w);

                if (width > 45) {
                    totalWidth += width;
                    totalAvailableWidth += width - 45;
                }
            }

            cellsPos.push({ cell, pos: map.map[index] });
        }
    }

    if (totalAvailableWidth <= width) {
        return false;
    }

    if (!cellsPos.length) {
        return false;
    }

    cellsPos.forEach(({ cell, pos }) => {
        const colwidth = cell.attrs.colwidth.map(w => {
            w = Number(w);

            if (!w || w <= 45) {
                return w;
            }

            const percentOfTotalWidth = Math.round((w * 100) / totalWidth);
            return Number(w - (width * percentOfTotalWidth) / 100).toFixed(2);
        });

        tr.setNodeMarkup(tr.mapping.map(tableStart + pos), null, { ...cell.attrs, colwidth });
    });

    return true;
}

export function addColumn(tr, { map, tableStart, table }, col) {
    let refColumn = col > 0 ? -1 : 0;

    if (columnIsHeader(map, table, col + refColumn)) {
        refColumn = col === 0 || col === map.width ? null : 0;
    }

    for (let row = 0; row < map.height; row++) {
        const colIndex = row * map.width + col;

        if (!stringToBoolean(table.attrs['data-responsive']) && table.attrs.width?.includes('%')) {
            const isValid = recalculateRowCellsWidth(tr, { map, tableStart, table }, row);

            if (!isValid) {
                return tr;
            }
        }

        if (col > 0 && col < map.width && map.map[colIndex - 1] === map.map[colIndex]) {
            const pos = map.map[colIndex];
            const cell = table.nodeAt(pos);

            tr.setNodeMarkup(tr.mapping.map(tableStart + pos), null, addColSpan(cell.attrs, col - map.colCount(pos)));
            row += cell.attrs.rowspan - 1;
        } else {
            const type = refColumn == null ? tableNodeTypes(table.type.schema).cell : table.nodeAt(map.map[colIndex + refColumn]).type;
            const pos = map.positionAt(row, col, table);
            const prevCell = table.nodeAt(map.map[row * map.width + (col > 0 ? col - 1 : col)]);

            tr.insert(
                tr.mapping.map(tableStart + pos),
                type.createAndFill(
                    stringToBoolean(table.attrs['data-responsive'])
                        ? { 'data-border-color': prevCell.attrs['data-border-color'] }
                        : { colwidth: [45], 'data-border-color': prevCell.attrs['data-border-color'] }
                )
            );
        }
    }

    return tr;
}

export function addColumnBefore(state, dispatch) {
    if (!isInTable(state)) {
        return false;
    }

    if (dispatch) {
        const rect = selectedRect(state);
        dispatch(addColumn(state.tr, rect, rect.left));
    }

    return true;
}

export function addColumnAfter(state, dispatch) {
    if (!isInTable(state)) {
        return false;
    }

    if (dispatch) {
        const rect = selectedRect(state);
        dispatch(addColumn(state.tr, rect, rect.right));
    }

    return true;
}

export function removeColumn(tr, { map, table, tableStart }, col) {
    const mapStart = tr.mapping.maps.length;

    for (let row = 0; row < map.height; ) {
        const index = row * map.width + col;
        const pos = map.map[index];

        const cell = table.nodeAt(pos);
        const attrs = cell.attrs;

        if ((col > 0 && map.map[index - 1] === pos) || (col < map.width - 1 && map.map[index + 1] === pos)) {
            tr.setNodeMarkup(tr.mapping.slice(mapStart).map(tableStart + pos), null, removeColSpan(attrs, col - map.colCount(pos)));
            row += attrs.rowspan;
        } else {
            const start = tr.mapping.slice(mapStart).map(tableStart + pos);

            tr.delete(start, start + cell.nodeSize);

            row += attrs.rowspan;

            if (!cell.attrs.colwidth?.length) {
                continue;
            }

            if (col + 1 === map.width) {
                const prevCell = table.nodeAt(map.map[index - 1]);
                const colwidth = prevCell.attrs.colwidth.map(w => {
                    return Number(w) + cell.attrs.colwidth.reduce((acc, w) => acc + Number(w), 0) / prevCell.attrs.colwidth.length;
                });
                tr.setNodeMarkup(tr.mapping.map(tableStart + map.map[index - 1]), null, {
                    ...prevCell.attrs,
                    colwidth,
                });
            } else {
                const nextCell = table.nodeAt(map.map[index + 1]);
                const colwidth = nextCell.attrs.colwidth.map(w => {
                    return Number(w) + cell.attrs.colwidth.reduce((acc, w) => acc + Number(w), 0) / nextCell.attrs.colwidth.length;
                });
                tr.setNodeMarkup(tr.mapping.map(tableStart + map.map[index + 1]), null, {
                    ...nextCell.attrs,
                    colwidth,
                });
            }
        }
    }
}

export function deleteColumn(state, dispatch) {
    if (!isInTable(state)) {
        return false;
    }

    if (dispatch) {
        const rect = selectedRect(state);
        const tr = state.tr;

        if (rect.left === 0 && rect.right === rect.map.width) {
            return false;
        }

        for (let i = rect.right - 1; ; i--) {
            const isLastCol = rect.left === rect.map.width - 1;
            const left = isLastCol ? rect.left - 1 : rect.left;
            const index = rect.top * rect.map.width + left;
            const pos = rect.map.map[index] + rect.tableStart + 1;

            tr.setSelection(TextSelection.create(tr.doc, pos));

            removeColumn(tr, rect, i);

            if (i === rect.left) {
                break;
            }

            const table = rect.tableStart ? tr.doc.nodeAt(rect.tableStart - 1) : tr.doc;

            if (!table) {
                throw RangeError('No table found');
            }

            rect.table = table;
            rect.map = TableMap.get(table);
        }

        dispatch(tr);
    }

    return true;
}

export function rowIsHeader(map, table, row) {
    const headerCell = tableNodeTypes(table.type.schema).header_cell;

    for (let col = 0; col < map.width; col++) {
        if (table.nodeAt(map.map[col + row * map.width])?.type !== headerCell) {
            return false;
        }
    }

    return true;
}

export function addRow(tr, { bottom, map, tableStart, table }, row) {
    let rPos = tableStart + rowPos(table, row);

    if (bottom === row && isRowLastInSection(table, row - 1)) {
        rPos -= 2;
    }

    const cells = [];
    let refRow = row > 0 ? -1 : 0;

    if (rowIsHeader(map, table, row + refRow)) {
        refRow = row === 0 || row === map.height ? null : 0;
    }

    const srows = map.sectionRows;

    for (let s = 0, acc = 0; s < srows.length; s++) {
        acc += srows[s];

        if (row < acc || s === srows.length - 1) {
            srows[s]++;
            break;
        }
    }

    for (let col = 0, index = map.width * row; col < map.width; col++, index++) {
        if (row > 0 && row < map.height && map.map[index] === map.map[index - map.width]) {
            const pos = map.map[index];
            const attrs = table.nodeAt(pos).attrs;

            tr.setNodeMarkup(tableStart + pos, null, {
                ...attrs,
                rowspan: attrs.rowspan + 1,
            });

            col += attrs.colspan - 1;
        } else {
            const type = refRow == null ? tableNodeTypes(table.type.schema).cell : table.nodeAt(map.map[index + refRow * map.width])?.type;
            const prevCell = table.nodeAt(map.map[row > 0 ? index - map.width : index]);

            if (prevCell.attrs.rowspan > 1) {
                continue;
            }

            let colwidth = prevCell.attrs.colwidth?.reduce((acc, w) => acc + w, 0);
            colwidth = colwidth ? [colwidth / prevCell.attrs.colspan] : undefined;
            const node = type?.createAndFill({
                'data-border-color': prevCell.attrs['data-border-color'] || '#D8D8D8',
                colwidth,
            });

            if (node) {
                cells.push(node);
            }
        }
    }

    tr.insert(rPos, tableNodeTypes(table.type.schema).row.create(null, cells));
    return tr;
}

export function addRowBefore(state, dispatch) {
    if (!isInTable(state)) return false;
    if (dispatch) {
        const rect = selectedRect(state);
        dispatch(addRow(state.tr, rect, rect.top));
    }
    return true;
}

export function addRowAfter(state, dispatch) {
    if (!isInTable(state)) return false;
    if (dispatch) {
        const rect = selectedRect(state);
        dispatch(addRow(state.tr, rect, rect.bottom));
    }
    return true;
}

export function addRowCopyAfter(state, dispatch) {
    if (!isInTable(state)) return false;
    if (dispatch) {
        const tr = state.tr;
        const { tableStart, bottom, top, table, map } = selectedRect(state);
        const rowsCopyCount = bottom - top;
        const rows = [];

        for (let row = 0; row < rowsCopyCount; row++) {
            const cells = [];

            for (let col = 0; col < map.width; col++) {
                const cellIndex = (row + top) * map.width + col;
                const prevRowColIndex = (row + top - 1) * map.width + col;

                const cell = table.nodeAt(map.map[cellIndex]);

                if (map.map[cellIndex - 1] === map.map[cellIndex]) {
                    continue;
                } else if (map.map[prevRowColIndex] === map.map[cellIndex]) {
                    continue;
                }

                cells.push(cell.type.createAndFill({ ...cell.attrs }, cell.content));
            }

            rows.push(tableNodeTypes(table.type.schema).row.create(null, cells));
        }

        tr.insert(tableStart + rowPos(table, bottom), rows);

        dispatch(tr);
    }
}

export function addColumnCopyAfter(state, dispatch) {
    if (!isInTable(state)) return false;
    if (dispatch) {
        const { left, right, tableStart, table, map } = selectedRect(state);
        const colsCopyCount = right - left;
        const tr = state.tr;

        for (let row = 0; row < map.height; row++) {
            const cells = [];

            for (let col = 0; col < colsCopyCount; col++) {
                const startCol = left + col;

                let refColumn = startCol > 0 ? -1 : 0;

                if (columnIsHeader(map, table, startCol + refColumn)) {
                    refColumn = startCol === 0 || startCol === map.width ? null : 0;
                }
                const prevRowColIndex = (row - 1) * map.width + startCol;
                const colIndex = row * map.width + startCol;

                if (!stringToBoolean(table.attrs['data-responsive']) && table.attrs.width?.includes('%')) {
                    const isValid = recalculateRowCellsWidth(tr, { map, tableStart, table }, row);

                    if (!isValid) {
                        return tr;
                    }
                }
                if (map.map[colIndex - 1] === map.map[colIndex]) {
                    continue;
                } else if (map.map[prevRowColIndex] === map.map[colIndex]) {
                    continue;
                } else {
                    const cell = table.nodeAt(map.map[colIndex]);

                    cells.push(cell);
                }
            }

            const pos = map.positionAt(row, right, table);

            tr.insert(tr.mapping.map(tableStart + pos), cells);
        }

        dispatch(tr);
    }
}

export function removeRow(tr, { map, table, tableStart }, row) {
    const { node: rNode, pos: rPos } = getRow(table, row);

    const mapFrom = tr.mapping.maps.length;
    const from = rPos + tableStart;
    const to = from + rNode.nodeSize - 1;
    tr.delete(from, to);

    for (let col = 0, index = row * map.width; col < map.width; col++, index++) {
        const pos = map.map[index];
        if (row > 0 && pos === map.map[index - map.width]) {
            const attrs = table.nodeAt(pos).attrs;
            tr.setNodeMarkup(tr.mapping.slice(mapFrom).map(pos + tableStart), null, {
                ...attrs,
                rowspan: attrs.rowspan - 1,
            });
            col += attrs.colspan - 1;
        } else if (row < map.height && pos === map.map[index + map.width]) {
            const cell = table.nodeAt(pos);
            const attrs = cell.attrs;
            const copy = cell.type.create({ ...attrs, rowspan: cell.attrs.rowspan - 1 }, cell.content);
            const newPos = map.positionAt(row + 1, col, table);
            tr.insert(tr.mapping.slice(mapFrom).map(tableStart + newPos), copy);
            col += attrs.colspan - 1;
        }
    }
}

export function removeSection(tr, { table, tableStart }, section) {
    let pos = 0;
    let s = -1;
    for (let i = 0; i < table.childCount; i++) {
        const child = table.child(i);
        if (isTableSection(child)) {
            s++;
            if (s === section) {
                tr.delete(tableStart + pos, tableStart + pos + child.nodeSize);
                return;
            }
        }
        pos += child.nodeSize;
    }
}

export function deleteRow(state, dispatch) {
    if (!isInTable(state)) return false;
    if (dispatch) {
        const rect = selectedRect(state),
            tr = state.tr;

        if (rect.top === 0 && rect.bottom === rect.map.height) {
            return false;
        }

        const sectionRows = rect.map.sectionRows;
        const sectionBottom = [sectionRows[0] || 0];

        for (let s = 1; s < sectionRows.length; s++) {
            sectionBottom[s] = sectionBottom[s - 1] + sectionRows[s];
        }

        let s = sectionRows.length - 1;

        while (s > 0 && sectionBottom[s] > rect.bottom) {
            s--;
        }

        for (let i = rect.bottom - 1; ; i--) {
            const firstRowOfSection = sectionBottom[s] - sectionRows[s];

            if (i + 1 === sectionBottom[s] && rect.top <= firstRowOfSection) {
                removeSection(tr, rect, s);
                state.apply(tr);
                i = firstRowOfSection;
                s--;
            } else {
                removeRow(tr, rect, i);
                state.apply(tr);
            }

            if (i <= rect.top) {
                break;
            }

            const table = rect.tableStart ? tr.doc.nodeAt(rect.tableStart - 1) : tr.doc;

            if (!table) {
                throw RangeError('No table found');
            }

            rect.table = table;
            rect.map = TableMap.get(rect.table);
        }

        const isLastRow = rect.top === rect.map.height - 1;
        const top = isLastRow ? rect.top - 1 : rect.top;
        const index = top * rect.map.width + rect.left;
        const pos = rect.map.map[index] + rect.tableStart + 1;

        tr.setSelection(TextSelection.create(tr.doc, pos));

        dispatch(tr);
    }
    return true;
}

export function addCaption(state, dispatch) {
    const $anchor = state.selection.$anchor;
    const d = tableDepth($anchor);
    if (d < 0) return false;
    const table = $anchor.node(d);
    if (tableHasCaption(table)) return false;
    if (dispatch) {
        let pos = $anchor.start(d);
        const types = tableNodeTypes(state.schema);
        const caption = types.caption.createAndFill();
        dispatch(state.tr.insert(pos, caption));
    }
    return true;
}

export function deleteCaption(state, dispatch) {
    const $anchor = state.selection.$anchor;
    const d = tableDepth($anchor);
    if (d < 0) return false;
    const table = $anchor.node(d);
    if (!tableHasCaption(table)) return false;
    if (dispatch) {
        let pos = $anchor.start(d);
        const size = table.firstChild.nodeSize;
        dispatch(state.tr.delete(pos, pos + size));
    }
    return true;
}

export function createCell(cellType, cellAttrs = null, cellContent) {
    if (cellContent) {
        return cellType.createChecked(cellAttrs, cellContent);
    }

    return cellType.createAndFill();
}

function createSection(schema, role, width, cellRole) {
    const types = tableNodeTypes(schema);
    const cells = [];
    const cellType = (cellRole && types[cellRole]) || types.cell || types.header_cell;

    for (let i = 0; i < width; i++) {
        cells.push(cellType.createAndFill());
    }

    return types[role].createAndFill(null, types.row.createAndFill(null, cells));
}

function getFirstSections(table) {
    let thead, tbody;
    for (let i = 0; i < table.childCount; i++) {
        if (table.child(i).type.spec.tableRole === 'table_head') {
            thead = table.child(i);

            return { thead, tbody };
        }

        if (table.child(i).type.spec.tableRole === 'table_body') {
            tbody = table.child(i);

            return { thead, tbody };
        }
    }
}

function toggleTableHeaderRow(state) {
    const { tr, schema, selection } = state;
    const $anchor = selection.$anchor;
    const d = tableDepth($anchor);

    if (d < 0) {
        return false;
    }

    const types = tableNodeTypes(schema);
    const table = $anchor.node(d);
    const rect = selectedRect(state);
    let { thead, tbody } = getFirstSections(table);

    const firstRow = !thead ? tbody.firstChild : thead.firstChild;

    let cells = [];

    const cellType = !thead ? types.header_cell : types.cell;
    for (let i = 0; i < firstRow.childCount; i++) {
        const cell = firstRow.child(i);

        if (i === 0 && isHeaderEnabledByType('column', rect, types)) {
            cells.push(createCell(types.header_cell, cell.attrs, cell.content));
        } else {
            cells.push(createCell(cellType, cell.attrs, cell.content));
        }
    }

    const content = !thead
        ? types.table_head.createAndFill(null, types.row.createAndFill(null, cells))
        : types.row.createAndFill(null, cells);

    let pos = rect.tableStart;

    for (let i = 0; i < table.childCount; i++) {
        if (table.child(i).type.spec.tableRole === 'table_title') {
            pos += table.child(i).nodeSize;
        }
        if (table.child(i).type.spec.tableRole === 'table_head') {
            pos += 1;
        }
        if (table.child(i).type.spec.tableRole === 'table_colgroup') {
            pos += table.child(i).nodeSize;
        }
    }

    console.log(pos);
    console.log(table);

    thead ? removeSection(tr, rect, 0) : removeRow(tr, rect, 0);
    tr.insert(pos, content);

    const from = thead ? tr.selection.from : selection.from + 1;
    const to = thead ? tr.selection.to : selection.to + 1;
    tr.setSelection(TextSelection.create(tr.doc, from, to));
}

export function toggleTableHead(state, dispatch) {
    toggleTableHeaderRow(state);

    if (dispatch) {
        dispatch(state.tr);
    }

    return true;
}

export function addTableFoot(state, dispatch) {
    const $anchor = state.selection.$anchor;
    const d = tableDepth($anchor);
    if (d < 0) return false;
    const table = $anchor.node(d);
    if (tableHasFoot(table)) return false;
    if (dispatch) {
        const pos = $anchor.end(d);
        const map = TableMap.get(table);
        const foot = createSection(state.schema, 'foot', map.width, 'header_cell');
        dispatch(state.tr.insert(pos, foot));
    }
    return true;
}

export function addBodyBefore(state, dispatch) {
    if (!isInTable(state)) return false;
    const rect = selectedRect(state);
    const { map, table, tableStart } = rect;
    const firstSection = map.sectionsInRect(rect)[0];
    if (firstSection === undefined || (firstSection === 0 && tableHasHead(table))) return false;
    if (dispatch) {
        let pos = tableStart,
            s = -1;
        for (let i = 0; i < table.childCount; i++) {
            const child = table.child(i);
            if (child.type.spec.tableRole !== 'table_title') s++;
            if (s === firstSection) break;
            pos += child.nodeSize;
        }
        const map = TableMap.get(table);
        const body = createSection(state.schema, 'table_body', map.width);
        dispatch(state.tr.insert(pos, body));
    }
    return true;
}

export function addBodyAfter(state, dispatch) {
    if (!isInTable(state)) return false;
    const rect = selectedRect(state);
    const { map, table, tableStart } = rect;
    const sections = map.sectionsInRect(rect);
    const lastSection = sections[sections.length - 1];
    if (lastSection === map.sectionRows.length - 1 && tableHasFoot(table)) return false;
    if (dispatch) {
        let pos = tableStart - 1,
            s = -1;
        for (let i = 0; i < table.childCount; i++) {
            const child = table.child(i);
            pos += child.nodeSize;
            if (child.type.spec.tableRole !== 'table_title') s++;
            if (s === lastSection) break;
        }
        const map = TableMap.get(table);
        const body = createSection(state.schema, 'table_body', map.width);
        dispatch(state.tr.insert(pos, body));
    }
    return true;
}

function fixRowCells(row, headerCellType) {
    const newCells = [];
    for (let i = 0; i < row.childCount; i++) {
        const cell = row.child(i);
        newCells.push(cell.type.spec.tableRole === 'header_cell' ? cell : headerCellType.create(cell.attrs, cell.content));
    }
    return row.copy(Fragment.from(newCells));
}

function makeSection(role, state, dispatch) {
    if (!isInTable(state)) return false;
    const rect = selectedRect(state);
    const { map, table, tableStart, top, bottom } = rect;
    if (role === 'table_head' && top > 0) return false;
    if (role === 'foot' && bottom < map.height) return false;
    const tableTypes = tableNodeTypes(state.schema);
    const newSectionType = tableTypes[role];
    if (!newSectionType) return false;
    const fixCellsType = (role === 'table_head' || role === 'foot') && tableTypes.cell && tableTypes.header_cell;
    if (dispatch) {
        let newTableContents = Fragment.empty;
        let refSection = null;
        let rowIndex = 0;
        let inSelection = false;
        let accSectionRows = Fragment.empty;

        for (let i = 0; i < table.childCount; i++) {
            const section = table.child(i);
            const sectionRole = section.type.spec.tableRole;
            if (isTableSection(section)) {
                const sectionRowsCount = section.childCount;
                const lastRow = rowIndex + sectionRowsCount - 1;
                if (rowIndex === top && lastRow + 1 === bottom && sectionRole === role) {
                    return false;
                }
                if (rowIndex >= bottom || lastRow < top) {
                    newTableContents = newTableContents.addToEnd(section);
                } else {
                    if (!refSection) refSection = section;
                    for (let j = 0; j < section.childCount; j++) {
                        if (rowIndex + j === top) {
                            if (accSectionRows.childCount > 0) {
                                newTableContents = newTableContents.addToEnd(refSection.copy(accSectionRows));
                                accSectionRows = Fragment.empty;
                            }
                            inSelection = true;
                        }
                        const row = inSelection && fixCellsType ? fixRowCells(section.child(j), tableTypes.header_cell) : section.child(j);
                        accSectionRows = accSectionRows.addToEnd(row);
                        if (rowIndex + j === bottom - 1) {
                            if (refSection.type.spec.tableRole !== role) refSection = section;
                            const newSection =
                                refSection.type.spec.tableRole !== role
                                    ? newSectionType.create(null, accSectionRows)
                                    : refSection.copy(accSectionRows);
                            newTableContents = newTableContents.addToEnd(newSection);
                            accSectionRows = Fragment.empty;
                            refSection = section;
                            inSelection = false;
                        }
                    }
                    if (!inSelection && accSectionRows.childCount > 0) {
                        newTableContents = newTableContents.addToEnd(refSection.copy(accSectionRows));
                        accSectionRows = Fragment.empty;
                    }
                }
                rowIndex = lastRow + 1;
            } else {
                newTableContents = newTableContents.addToEnd(section);
            }
        }
        const { doc, tr } = state;
        tr.setSelection(new NodeSelection(doc.resolve(tableStart - 1)));
        const newTable = table.copy(newTableContents);
        tr.replaceSelectionWith(newTable);
        const cellsPositions = TableMap.get(newTable).cellsInRect(rect);
        const $anchorCell = tr.doc.resolve(tableStart + cellsPositions[0]);
        const $headCell = tr.doc.resolve(tableStart + cellsPositions[cellsPositions.length - 1]);
        tr.setSelection(new CellSelection($anchorCell, $headCell));
        dispatch(tr);
    }
    return true;
}

export function makeBody(state, dispatch) {
    return makeSection('table_body', state, dispatch);
}

export function makeHead(state, dispatch) {
    return makeSection('table_head', state, dispatch);
}

export function makeFoot(state, dispatch) {
    return makeSection('foot', state, dispatch);
}

export function deleteSection(state, dispatch) {
    if (!isInTable(state)) return false;
    const rect = selectedRect(state),
        tr = state.tr;
    if (rect.top === 0 && rect.bottom === rect.map.height) return false;
    if (dispatch) {
        const { map, table, tableStart } = rect;
        const sections = map.sectionsInRect(rect);
        if (sections.length >= tableSectionsCount(table) || sections.length === 0) return false;
        const firstSectionIndex = tableHasCaption(table) ? 1 : 0;
        const sectionPosAndSize = [];
        let pos = tableStart;
        for (let i = 0; i < table.childCount; i++) {
            const size = table.child(i).nodeSize;
            if (i >= firstSectionIndex) sectionPosAndSize.push([pos, size]);
            pos += size;
        }
        for (let i = sections.length - 1; i >= 0; i--) {
            const [pos, size] = sectionPosAndSize[sections[i]];
            tr.delete(pos, pos + size);
        }
        dispatch(tr);
    }
    return true;
}

function isEmpty(cell) {
    const c = cell.content;

    return c.childCount === 1 && c.child(0).isTextblock && c.child(0).childCount === 0;
}

function cellsOverlapRectangle({ width, height, map }, rect) {
    let indexTop = rect.top * width + rect.left,
        indexLeft = indexTop;
    let indexBottom = (rect.bottom - 1) * width + rect.left,
        indexRight = indexTop + (rect.right - rect.left - 1);
    for (let i = rect.top; i < rect.bottom; i++) {
        if ((rect.left > 0 && map[indexLeft] === map[indexLeft - 1]) || (rect.right < width && map[indexRight] === map[indexRight + 1]))
            return true;
        indexLeft += width;
        indexRight += width;
    }
    for (let i = rect.left; i < rect.right; i++) {
        if (
            (rect.top > 0 && map[indexTop] === map[indexTop - width]) ||
            (rect.bottom < height && map[indexBottom] === map[indexBottom + width])
        )
            return true;
        indexTop++;
        indexBottom++;
    }
    return false;
}

export function mergeCells(state, dispatch) {
    const sel = state.selection;
    if (!(sel instanceof CellSelection) || sel.$anchorCell.pos === sel.$headCell.pos) return false;
    const rect = selectedRect(state),
        { map } = rect;
    if (!map.rectOverOneSection(rect)) return false;
    if (cellsOverlapRectangle(map, rect)) return false;
    if (dispatch) {
        const tr = state.tr;
        const seen = {};
        let content = Fragment.empty;
        let mergedPos;
        let mergedCell;
        let colwidth = [];
        for (let row = rect.top; row < rect.bottom; row++) {
            for (let col = rect.left; col < rect.right; col++) {
                const cellPos = map.map[row * map.width + col];
                const cell = rect.table.nodeAt(cellPos);
                if (seen[cellPos] || !cell) continue;
                seen[cellPos] = true;
                console.log(cell);

                colwidth = cell.attrs.colwidth ? colwidth.concat(cell.attrs.colwidth) : null;
                if (mergedPos == null) {
                    mergedPos = cellPos;
                    mergedCell = cell;
                } else {
                    if (!isEmpty(cell)) content = content.append(cell.content);
                    const mapped = tr.mapping.map(cellPos + rect.tableStart);
                    console.log(cell.attrs.colwidth);
                    tr.delete(mapped, mapped + cell.nodeSize);
                }
            }
        }
        if (mergedPos == null || mergedCell == null) {
            return true;
        }
        console.log(colwidth);
        console.log(mergedCell);

        tr.setNodeMarkup(mergedPos + rect.tableStart, null, {
            ...addColSpan(mergedCell.attrs, mergedCell.attrs.colspan, rect.right - rect.left - mergedCell.attrs.colspan),
            rowspan: rect.bottom - rect.top,
            colwidth,
        });
        if (content.size) {
            const end = mergedPos + 1 + mergedCell.content.size;
            const start = isEmpty(mergedCell) ? mergedPos + 1 : end;
            tr.replaceWith(start + rect.tableStart, end + rect.tableStart, content);
        }
        tr.setSelection(new CellSelection(tr.doc.resolve(mergedPos + rect.tableStart)));
        dispatch(tr);
    }
    return true;
}

export function splitCell(state, dispatch) {
    const nodeTypes = tableNodeTypes(state.schema);
    return splitCellWithType(({ node }) => {
        return nodeTypes[node.type.spec.tableRole];
    })(state, dispatch);
}

export function splitCellWithType(getCellType) {
    return (state, dispatch) => {
        const sel = state.selection;
        let cellNode;
        let cellPos;
        if (!(sel instanceof CellSelection)) {
            cellNode = cellWrapping(sel.$from);
            if (!cellNode) return false;
            cellPos = cellAround(sel.$from)?.pos;
        } else {
            if (sel.$anchorCell.pos !== sel.$headCell.pos) return false;
            cellNode = sel.$anchorCell.nodeAfter;
            cellPos = sel.$anchorCell.pos;
        }
        if (cellNode == null || cellPos == null) {
            return false;
        }
        if (cellNode.attrs.colspan === 1 && cellNode.attrs.rowspan === 1) {
            return false;
        }
        if (dispatch) {
            let baseAttrs = cellNode.attrs;
            const attrs = [];
            const colwidth = baseAttrs.colwidth;
            if (baseAttrs.rowspan > 1) baseAttrs = { ...baseAttrs, rowspan: 1 };
            if (baseAttrs.colspan > 1) baseAttrs = { ...baseAttrs, colspan: 1 };
            const rect = selectedRect(state),
                tr = state.tr;
            for (let i = 0; i < rect.right - rect.left; i++)
                attrs.push(
                    colwidth
                        ? {
                              ...baseAttrs,
                              colwidth: colwidth && colwidth[i] ? [colwidth[i]] : null,
                          }
                        : baseAttrs
                );
            let lastCell;
            for (let row = rect.top; row < rect.bottom; row++) {
                let pos = rect.map.positionAt(row, rect.left, rect.table);
                if (row === rect.top) pos += cellNode.nodeSize;
                for (let col = rect.left, i = 0; col < rect.right; col++, i++) {
                    if (col === rect.left && row === rect.top) continue;
                    tr.insert(
                        (lastCell = tr.mapping.map(pos + rect.tableStart, 1)),
                        getCellType({ node: cellNode, row, col }).createAndFill(attrs[i])
                    );
                }
            }
            tr.setNodeMarkup(cellPos, getCellType({ node: cellNode, row: rect.top, col: rect.left }), attrs[0]);
            if (sel instanceof CellSelection)
                tr.setSelection(new CellSelection(tr.doc.resolve(sel.$anchorCell.pos), lastCell ? tr.doc.resolve(lastCell) : undefined));
            dispatch(tr);
        }
        return true;
    };
}

export function setCellAttr(name, value) {
    return function (state, dispatch) {
        if (!isInTable(state)) return false;
        const $cell = selectionCell(state);
        if ($cell.nodeAfter.attrs[name] === value) return false;
        if (dispatch) {
            const tr = state.tr;
            if (state.selection instanceof CellSelection)
                state.selection.forEachCell((node, pos) => {
                    if (node.attrs[name] !== value)
                        tr.setNodeMarkup(pos, null, {
                            ...node.attrs,
                            [name]: value,
                        });
                });
            else
                tr.setNodeMarkup($cell.pos, null, {
                    ...$cell.nodeAfter.attrs,
                    [name]: value,
                });
            dispatch(tr);
        }
        return true;
    };
}
function toggleTableHeader(type, state) {
    const types = tableNodeTypes(state.schema);
    const rect = selectedRect(state),
        tr = state.tr;

    const isHeaderRowEnabled = isHeaderEnabledByType('row', rect, types);
    const isHeaderColumnEnabled = isHeaderEnabledByType('column', rect, types);

    const isHeaderEnabled = type === 'column' ? isHeaderRowEnabled : type === 'row' ? isHeaderColumnEnabled : false;

    const selectionStartsAt = isHeaderEnabled ? 1 : 0;
    const cellsRect =
        type === 'column'
            ? {
                  left: 0,
                  top: selectionStartsAt,
                  right: 1,
                  bottom: rect.map.height,
              }
            : type === 'row'
            ? {
                  left: selectionStartsAt,
                  top: 0,
                  right: rect.map.width,
                  bottom: 1,
              }
            : rect;

    const newType =
        type === 'column'
            ? isHeaderColumnEnabled
                ? types.cell
                : types.header_cell
            : type === 'row'
            ? isHeaderRowEnabled
                ? types.cell
                : types.header_cell
            : types.cell;

    rect.map.cellsInRect(cellsRect).forEach(relativeCellPos => {
        const cellPos = relativeCellPos + rect.tableStart;
        const cell = tr.doc.nodeAt(cellPos);

        if (cell) {
            tr.setNodeMarkup(cellPos, newType, cell.attrs);
        }
    });
}
function deprecated_toggleHeader(type) {
    return function (state, dispatch) {
        if (!isInTable(state)) return false;
        if (dispatch) {
            toggleTableHeader(type, state);
            dispatch(state.tr);
        }
        return true;
    };
}

export function isHeaderEnabledByType(type, rect, types) {
    const cellPositions = rect.map.cellsInRect({
        left: 0,
        top: 0,
        right: type === 'row' ? rect.map.width : 1,
        bottom: type === 'column' ? rect.map.height : 1,
    });

    for (let i = 0; i < cellPositions.length; i++) {
        const cell = rect.table.nodeAt(cellPositions[i]);
        if (cell && cell.type !== types.header_cell) {
            return false;
        }
    }

    return true;
}

export function toggleTableHeaders({ row = true, column = true }) {
    return (state, dispatch) => {
        if (row) {
            toggleTableHeaderRow(state);
        }
        state.apply(state.tr);
        if (column) {
            toggleTableHeader('column', state);
        }

        if (dispatch) {
            dispatch(state.tr);
        }

        return true;
    };
}

export function toggleHeader(type, options) {
    options = options || { useDeprecatedLogic: false };

    if (options.useDeprecatedLogic) return deprecated_toggleHeader(type);

    return function (state, dispatch) {
        if (!isInTable(state)) return false;
        if (dispatch) {
            toggleTableHeader(type, state);
            dispatch(state.tr);
        }
        return true;
    };
}

export const toggleHeaderRow = toggleHeader('row', {
    useDeprecatedLogic: true,
});

export const toggleHeaderColumn = toggleHeader('column', {
    useDeprecatedLogic: true,
});

export const toggleHeaderCell = toggleHeader('cell', {
    useDeprecatedLogic: true,
});

function findNextCell($cell, dir) {
    const table = $cell.node(-2);

    if (dir < 0) {
        const before = $cell.nodeBefore;
        if (before) return $cell.pos - before.nodeSize;
        for (let row = $cell.index(-2) - 1, rowEnd = $cell.before(); row >= 0; row--) {
            const rowNode = $cell.node(-2).child(row);
            const lastChild = rowNode.lastChild;
            if (lastChild) {
                return rowEnd - 1 - lastChild.nodeSize;
            }
            rowEnd -= rowNode.nodeSize;
        }
    } else {
        if ($cell.index() < $cell.parent.childCount - 1) {
            return $cell.pos + $cell.nodeAfter.nodeSize;
        }
        for (let row = $cell.indexAfter(-2), rowStart = $cell.after(); row < table.childCount; row++) {
            const rowNode = table.child(row);
            if (rowNode.childCount) return rowStart + 1;
            rowStart += rowNode.nodeSize;
        }
    }
    return null;
}

export function goToNextCell(direction) {
    return function (state, dispatch) {
        if (!isInTable(state)) return false;
        const cell = findNextCell(selectionCell(state), direction);
        if (cell == null) return false;
        if (dispatch) {
            const $cell = state.doc.resolve(cell);
            dispatch(state.tr.setSelection(TextSelection.between($cell, moveCellForward($cell))).scrollIntoView());
        }
        return true;
    };
}

export function deleteTable(state, dispatch) {
    const $pos = state.selection.$anchor;
    for (let d = $pos.depth; d > 0; d--) {
        const node = $pos.node(d);
        if (node.type.spec.tableRole === 'table') {
            if (dispatch) dispatch(state.tr.delete($pos.before(d), $pos.after(d)).scrollIntoView());
            return true;
        }
    }
    return false;
}

function sameWidths(w1, w2) {
    if (w1.length !== w2.length) return false;
    for (let i = 0; i < w1.length; i++) {
        if (w1[i] !== w2[i]) return false;
    }
    return true;
}

function updateColumnWidthsTransaction(state, table, tableStart, left, widths, _map) {
    const doc = state.state;
    const tr = state.tr;
    const map = _map || TableMap.get(table);
    const right = Math.min(left + widths.length, map.width);
    const updated = [];
    for (let r = 0; r < map.height; r++) {
        for (let c = left; c < right; c++) {
            const pos = map.positionAt(r, c, table) + tableStart;
            if (updated.indexOf(pos) >= 0) continue;
            else updated.push(pos);
            const cell = doc.nodeAt(pos);
            if (cell) {
                const attrs = cell.attrs;
                const colspan = attrs.colspan || 1;
                const colwidth = widths.slice(c - left, c - left + colspan);
                if (!attrs.colwidth || !sameWidths(colwidth, attrs.colwidth)) {
                    tr.setNodeMarkup(pos, null, { ...attrs, colwidth });
                }
            }
        }
    }
    return tr;
}

export function setComputedStyleColumnWidths(state, dispatch, view) {
    if (!isInTable(state)) return false;
    if (view && dispatch) {
        const { doc, selection } = state;
        let table;
        let tableStart;
        let map;
        let left;
        let right;
        if (selection instanceof CellSelection && selection.isColSelection()) {
            const { $anchorCell, $headCell } = selection;
            table = $anchorCell.node(-2);
            tableStart = $anchorCell.start(-2);
            map = TableMap.get(table);
            const rect = map.rectBetween($anchorCell.pos - tableStart, $headCell.pos - tableStart);
            left = rect.left;
            right = rect.right;
        } else {
            const $head = selection.$head;
            const d = tableDepth($head);
            table = $head.node(d);
            tableStart = $head.start(d);
            map = TableMap.get(table);
            left = 0;
            right = map.width;
        }
        let computedWidths = Array(right - left).fill(null);
        for (let row = 0; row < map.height; row++) {
            for (let col = left; col < right; col++) {
                const pos = map.positionAt(row, col, table) + tableStart;
                const cell = doc.nodeAt(pos);
                const domNode = view.nodeDOM(pos);
                if (domNode) {
                    const colwidth = Math.round(parseFloat(window.getComputedStyle(domNode).width));
                    if (colwidth) {
                        const colspan = cell?.attrs?.colspan;
                        if (colspan > 1) {
                            const aw = -colwidth / colspan;
                            for (let i = 0; i < colspan; i++) computedWidths[col - left + i] = aw;
                            col += colspan - 1;
                        } else {
                            computedWidths[col - left] = colwidth;
                        }
                    }
                }
            }
            if (computedWidths.every(aw => aw !== null && aw >= 0)) break;
        }
        computedWidths = computedWidths.map(aw => (aw !== null && aw < 0 ? -aw : aw));
        const tr = updateColumnWidthsTransaction(state, table, tableStart, left, computedWidths, map);
        if (tr.docChanged) view.dispatch(tr);
        return true;
    }
    return true;
}

function getComputedTableWidth(view) {
    if (view) {
        const state = view.state;
        if (!isInTable(state)) return;
        const { selection } = state;
        let tableStart;
        const $head = selection.$head;
        const d = tableDepth($head);
        tableStart = $head.start(d);
        let domNode = view.nodeDOM(tableStart - 1);
        try {
            if (domNode) {
                domNode = domNode.firstChild;
                while (domNode) {
                    if (domNode.nodeName === 'TABLE') break;
                    domNode = domNode.nextSibling;
                }
                if (domNode && domNode.nodeName === 'TABLE') {
                    const tableWidth = Math.round(parseFloat(window.getComputedStyle(domNode).width));
                    if (!isNaN(tableWidth)) return tableWidth;
                }
            }
        } catch {}
    }
}

export function setRelativeColumnWidths(relwidths, minwidth) {
    return (state, dispatch, view) => {
        if (!isInTable(state)) return false;
        if (view && dispatch) {
            const tableWidth = getComputedTableWidth(view);
            if (!tableWidth) return false;
            const $head = state.selection.$head;
            const depth = tableDepth($head);
            if (depth < 0) return false;
            const table = $head.node(depth);
            const tableStart = $head.start(depth);
            let widths = relwidths.map(rw => Math.round(rw * tableWidth));
            if (minwidth) widths = widths.map(w => (w < minwidth ? minwidth : w));
            const tr = updateColumnWidthsTransaction(state, table, tableStart, 0, widths);
            if (tr.docChanged) dispatch(tr);
        }
        return true;
    };
}
