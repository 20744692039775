import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Modal from 'react-modal';
import PrivatePage from 'containers/private-page-wrapper';
import BackButton from 'components/back-button';
import Icon, { Icons } from 'uikit/icon';
import Input from 'uikit/input';
import { Select } from 'uikit/select';
import { DatePicker, TimePicker } from 'uikit/datetime';
import Button from 'uikit/button';
import cx from './integrations-naumen-page.module.scss';
import IconButton from 'uikit/icon-button';
import { useGlobalContext } from 'lib/hooks';
import { Link, useNavigate } from 'shared/router';

const integrationTime = 900;
const intervalOptions = [
    {
        value: 0,
        label: 'Каждый день'
    },
    {
        value: 1,
        label: 'Каждую неделю'
    },
    {
        value: 2,
        label: 'Каждый месяц'
    }
];

const IntegrationsNaumenPage = ({ hasAnyAuthorities }) => {
    const navigate = useNavigate();
    const { platform } = useGlobalContext();

    const [isApprove, setIsApprove] = useState(false);

    const [integrationDate, setIntegrationDate] = useState(null);
    const [integrationPercent, setIntegrationPercent] = useState(0);
    const [integrationLast, setIntegrationLast] = useState(0);

    const [host, setHost] = useState('');
    const [port, setPort] = useState('');

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const [component, setComponent] = useState(1);
    const [interval, setInterval] = useState(intervalOptions[0]);

    const [time, setTime] = useState(null);

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const isValidate = () => {
        return host.length !== 0 && port.length !== 0 && login.length !== 0 && password.length !== 0 && time !== null
            && fromDate !== null && toDate !== null;
    };
    const onApprove = (type) => {
        const date = Math.floor(Date.now() / 1000);

        localStorage.setItem('integrationNaumen', JSON.stringify({
            'host': host,
            'port': port,
            'login': login,
            'password': password,
            'component': component,
            'interval': interval,
            'time': time,
            'fromDate': fromDate,
            'toDate': toDate,
            'type': type,
            'date': date
        }));

        setIntegrationDate(date);
        setIsApprove(false);
    };

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('integrationNaumen'));

        if (!data) {
            return;
        }

        setHost(data['host']);
        setPort(data['port']);

        setLogin(data['login']);
        setPassword(data['password']);

        setComponent(data['component']);

        setInterval(data['interval']);
        setTime(data['time']);

        setFromDate(new Date(data['fromDate']));
        setToDate(new Date(data['toDate']));

        if ((data['date'] + integrationTime) > Math.floor(Date.now() / 1000)) {
            setIntegrationDate(data['date']);
        }
    }, []);
    useEffect(() => {
        if (!integrationDate) {
            return;
        }

        setTimeout(() => {
            const difference = (integrationDate + integrationTime) - Math.floor(Date.now() / 1000);

            setIntegrationPercent((integrationTime - difference) * 100 / integrationTime);
            setIntegrationLast(difference);

            if (difference <= 0) {
                setIntegrationDate(null);
            }
        }, 1000);
    });

    if (integrationDate) {
        return (
            <div className={cx.contentWrapper}>
                <div className={cx.integrationsTitle}>
                    <BackButton className={cx.integrationsTitleIcon} link={'/global-settings/integrations'}/>
                    <h1>Интеграция NAUMEN</h1>
                </div>
                <div className={cx.integrationsWrapperProcess}>
                    <div className={cx.integrationsWrapperNotification}>
                        <Icon type={Icons.INFO} width={15} height={15}/>
                        <span>Интеграция выполняется в фоновом режиме. Вы можете переходить на другие разделы системы без потери данных</span>
                    </div>
                    <h3>Процесс интеграции</h3>
                    <div className={cx.integrationsWrapperLoader}>
                        <span style={{ width: integrationPercent + '%' }}/>
                    </div>
                    <p>Завершено {Math.floor(integrationPercent)}% -
                        осталось {Math.floor(integrationLast / 60)} мин. {integrationLast % 60} с.</p>
                    <div className={cx.integrationsWrapperButtons}>
                        <Link to={'/global-settings/integrations'}>
                            <Button label="Закрыть и продолжить в фоновом режиме"/>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <PrivatePage hasAnyAuthorities={hasAnyAuthorities}>
            <div className={classNames(cx.contentWrapper, {[cx.mobile]: platform === 'mobile'})}>
                <Modal isOpen={isApprove} className={cx.integrationsModal}
                       overlayClassName={cx.integrationsModalOverlay}>
                    <div className={cx.integrationsModalHeader}>
                        <h3>Начало интеграции</h3>
                        <div className={cx.integrationsModalHeaderClose} onClick={() => setIsApprove(false)}>
                            <IconButton icon={<Icon type={Icons.CROSS} width={14} height={14}/>}
                                        onClick={() => setIsApprove(false)}/>
                        </div>
                    </div>
                    <div className={cx.integrationsModalBody}>
                        <div onClick={() => onApprove(0)}>
                            <Icon type={Icons.ZIPPER} width={17} height={28}/>
                            <span>Прямо сейчас</span>
                        </div>
                        <div onClick={() => onApprove(1)}>
                            <Icon type={Icons.TIME} width={25} height={25}/>
                            <span>По графику</span>
                        </div>
                    </div>
                </Modal>
                <div className={cx.integrationsTitle}>
                    <BackButton className={cx.integrationsTitleIcon}
                                onClick={() => navigate('/global-settings/integrations')}/>
                    <h1>Интеграция NAUMEN</h1>
                </div>
                <div className={cx.integrationsWrapper}>
                    <div className={cx.integrationsWrapperBlock}>
                        <h2>Настройки</h2>
                        <div className={cx.integrationsWrapperBlockRow}>
                            <Input className={cx.host} label="Хост" description="Укажите доменное имя или IP адрес"
                                   value={host} onChange={(value) => setHost(value)}/>
                            <Input className={cx.port} label="Порт" value={port} onChange={(value) => setPort(value)}/>
                        </div>
                        <div className={cx.integrationsWrapperBlockRow}>
                            <Input className={cx.login} label="Логин" value={login}
                                   onChange={(value) => setLogin(value)}/>
                            <Input className={cx.password} label="Пароль" value={password}
                                   onChange={(value) => setPassword(value)}/>
                        </div>
                    </div>
                    <div className={cx.integrationsWrapperBlock}>
                        <h2>Выберите компоненты для интеграции</h2>
                        <ul className={cx.integrationsComponents}>
                            <li className={component === 1 ? cx.active : ''} onClick={() => setComponent(1)}>
                                <div>
                                    <Icon type={Icons.FOLDER} width={26} height={24}/>
                                </div>
                                <span>Структура проектов</span>
                            </li>
                            <li className={component === 2 ? cx.active : ''} onClick={() => setComponent(2)}>
                                <div>
                                    <Icon type={Icons.FOLDER} width={26} height={24}/>
                                    <Icon type={Icons.LIST} width={23} height={23}/>
                                </div>
                                <span>Структура проектов и Содержимое</span>
                            </li>
                            <li className={component === 3 ? cx.active : ''} onClick={() => setComponent(3)}>
                                <div>
                                    <Icon type={Icons.FOLDER} width={26} height={24}/>
                                    <Icon type={Icons.LIST} width={23} height={23}/>
                                    <Icon type={Icons.USER} width={25} height={23}/>
                                </div>
                                <span>Структура проектов, Содержимое и Участники</span>
                            </li>
                        </ul>
                    </div>
                    <div className={cx.integrationsWrapperBlock}>
                        <h2>График интеграции</h2>
                        <div className={cx.integrationsWrapperNotification}>
                            <Icon type={Icons.INFO} width={15} height={15}/>
                            <span>Вы можете настроить время начала интеграции и параметры периодичности выполняемых операций в будущем</span>
                        </div>
                        <div className={cx.integrationsWrapperBlockRow}>
                            <Select className={cx.interval} label="Повторять операцию" options={intervalOptions}
                                    value={interval} onChange={(value) => setInterval(value)}/>
                            <TimePicker className={cx.time} label="Выберите время интеграции" value={time}
                                        onChange={(value) => setTime(value)}/>
                        </div>
                        <div className={cx.integrationsWrapperBlockRow}>
                            <DatePicker className={cx.fromDate} label="Начальный день интеграции" value={fromDate}
                                        onChange={(value) => setFromDate(value)}/>
                            <DatePicker className={cx.toDate} label="Последний день интеграции" value={toDate}
                                        onChange={(value) => setToDate(value)}/>
                        </div>
                    </div>
                    <div className={cx.integrationsWrapperButtons}>
                        <Button label="Отмена" onClick={() => navigate('/global-settings/integrations')}
                                fullWidth={platform === 'mobile'}/>
                        <Button color="green" label="Подтвердить" disabled={!isValidate()}
                                onClick={() => setIsApprove(true)} fullWidth={platform === 'mobile'}/>
                    </div>
                </div>
            </div>
        </PrivatePage>
    );
};

export default IntegrationsNaumenPage;
