import { useContext } from 'react';
import { RouterContext } from '../routerProvider';

export const useParams = (): Object => {
    const context = useContext(RouterContext);

    if (!context) {
        throw new Error('useParams must be used within a RouterProvider');
    }

    return context.router.getParams()();
};
