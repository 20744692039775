import React, { useRef } from 'react';
import { ReportsProjectsTree } from 'components/trees';

function AddDocumentModal({ isOpen, onClose, onSubmit, articles }) {
    const treeRef = useRef(null);

    return (
        <ReportsProjectsTree
            open={isOpen}
            multipleChoice={true}
            withArticles={true}
            allButtonTitle="Все проекты"
            ref={treeRef}
            onDismiss={onClose}
            onSubmit={onSubmit}
            articles={articles}
        />
    );
}

AddDocumentModal.propTypes = {};

export default AddDocumentModal;
