import React, { useEffect, useState } from 'react';
import { ModalBody, ModalFooter, ModalHeader } from 'components/modal/modal-components';
import { desktopStyles } from 'lib/util/modalStyles';
import Input from 'uikit/input/input';
import ReactModal from 'react-modal';

export const ImageLinkModal = ({ data, show, onSubmit, onClose }) => {
    const [linkUrl, setLinkUrl] = useState('');

    const handleClose = () => {
        setLinkUrl('');
        onClose();
    };

    const handleSubmit = () => {
        onSubmit({ url: linkUrl.trim() ? linkUrl.trim() : null });

        setLinkUrl('');
    };

    useEffect(() => {
        if (data) {
            setLinkUrl(data.linkUrl || '');
        }
    }, [data]);

    return (
        <ReactModal
            isOpen={show}
            preventScroll
            style={{ ...desktopStyles, content: { ...desktopStyles.content, maxWidth: 570, width: '100%', border: 'none' } }}
        >
            <ModalHeader title="Добавление ссылки" onClose={handleClose} />

            <ModalBody>
                <Input
                    wrapperStyle={{ paddingBottom: 20 }}
                    label="Ссылка"
                    value={linkUrl}
                    placeholder="Вставьте ссылку"
                    onChange={setLinkUrl}
                    required={true}
                />
            </ModalBody>

            <ModalFooter
                buttons={[
                    { label: 'Отмена', onClick: handleClose },
                    {
                        label: 'Сохранить',
                        color: 'blue',
                        onClick: handleSubmit,
                    },
                ]}
            />
        </ReactModal>
    );
};
