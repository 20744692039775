import { useState, useCallback, useEffect } from 'react';
import { KeyValueStorage } from '../storage';

export enum AutoSaveGroup {
    // eslint-disable-next-line no-unused-vars
    ARTICLE = 'article',
    // eslint-disable-next-line no-unused-vars
    NEWS = 'news',
    // eslint-disable-next-line no-unused-vars
    SCRIPT = 'script'
}

const useAutoSave = (
    login: string | undefined,
    group: AutoSaveGroup,
    saveId: string | undefined,
    saveFunc: () => string,
    storage: KeyValueStorage
) => {
    const [autoSave, setAutoSave] = useState<string | null>(null);
    const [isAutoSave, setIsAutoSave] = useState(false);

    const onSave = useCallback(() => {
        const data = saveFunc();

        if (!data) {
            return;
        }

        storage.setItem(`${login}_${group}_${saveId}`, data);
    }, [login, group, saveId, saveFunc, storage]);

    const clearAutoSave = useCallback(() => {
        storage.removeItem(`${login}_${group}_${saveId}`);
    }, [login, group, saveId, storage]);

    useEffect(() => {
        if (isAutoSave) {
            return;
        }

        const interval = setInterval(onSave, 10000);

        return () => {
            clearInterval(interval);
        };
    }, [isAutoSave, onSave]);
    useEffect(() => {
        storage.getItem(`${login}_${group}_${saveId}`).then(autoSave => {
            setAutoSave(autoSave);
            setIsAutoSave(!!autoSave);
        });

        return () => {
            clearAutoSave();
        };
    }, [login, group, saveId, clearAutoSave, storage]);

    return {
        autoSave,
        isAutoSave,
        setIsAutoSave,
        clearAutoSave
    };
};

export default useAutoSave;
