import React, { useState } from 'react';
import Checkbox, { CheckboxWrapper } from 'uikit/checkbox';

export const CheckboxList = ({ list = [], onChange = () => {} }) => {
    const [selected, setSelected] = useState(list);

    const isSelected = (id) => {
        return selected.some(i => i.id === id);
    }

    const onCheck = (item) => {
        if (selected.some(i => i.id === item.id)) {
            setSelected(selected.filter(i => i.id !== item.id));
        } else {
            setSelected(prevState => ([...prevState, item]))
        }

        onChange(item);
    }

    return Array.isArray(list) ?
        list.map(item => (
            <CheckboxWrapper key={item.id}>
                <Checkbox
                    onChange={() => onCheck(item)}
                    checked={isSelected(item.id)}
                />
                <div>{item.text}</div>
            </CheckboxWrapper>
        )) : null;
}
