import { mergeAttributes } from '@tiptap/core';
import Blockquote from '@tiptap/extension-blockquote';
import { Plugin, PluginKey } from '@tiptap/pm/state';

export const CustomBlockquote = Blockquote.extend({
isolating: true,
renderHTML({ HTMLAttributes }) {
    return ['blockquote', mergeAttributes(this.options.HTMLAttributes, { ...HTMLAttributes, class: 'tiptap-blockquote' }), 0];
},
addKeyboardShortcuts() {
    return {
        Backspace: ({ editor }) => {
            try {
                const { selection } = editor.state;
                const { $from } = selection;

                const parent = $from.node(-1);

                if (parent?.type.name === this.name && parent.textContent.length === 0) {
                    const tr = editor.state.tr.delete($from.before(-1), $from.after(-1));
                    editor.view.dispatch(tr);

                    return true;
                }

                if ($from.parent.type.name !== 'paragraph' || $from.parentOffset !== 0) {
                    return false;
                }

                const parentPos = $from.before($from.depth);
                const beforeParentPos = parentPos - 1;

                if (beforeParentPos >= 0) {
                    const resolvedPos = editor.state.doc.resolve(beforeParentPos);
                    const nodeBeforeParent = resolvedPos.node();

                    if (nodeBeforeParent && nodeBeforeParent.type.name === this.name) {
                        const tr = editor.state.tr;
                        const paragraphNode = $from.parent;

                        tr.delete(parentPos, parentPos + paragraphNode.nodeSize);
                        tr.insert(beforeParentPos, paragraphNode.copy(paragraphNode.content));

                        editor.view.dispatch(tr);
                        return true;
                    }
                }
            } catch {
                // TODO: Here we should log in sentry;
            }

            return false;
        },
    };
},
addProseMirrorPlugins() {
    return [
        new Plugin({
            key: new PluginKey(this.name),
            props: {
                handleDOMEvents: {
                    keydown: (_, event) => {
                        if (event.key.toLowerCase() === 'b' && (event.ctrlKey || event.metaKey) && event.shiftKey) {
                            this.editor.commands.toggleBlockquote();
                        }
                    },
                },
            },
        }),
    ];
},
});
