import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import formatDateTime from 'lib/helpers/formatDateTime';
import { fetchMenuButtons } from 'slice/authSlice';
import { Link, useNavigate } from 'shared/router';
import { useAuthorized, useDialog, useGlobalContext, useMessage } from 'lib/hooks';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import Confirmation from 'components/confirmation';
import { TableComponent } from 'components/data-components';
import { Icons } from 'uikit/icon';
import { EditRowToolbar } from 'uikit/table';
import api from 'api/index';
import cx from './tab-scripts.module.scss';
import {USER_ROLES} from "model/role";
import TableTitleCell from 'components/table-title-cell';
import Loading from 'uikit/loading';
import { EmptyScripts, EmptySectionsScript } from './empty';

const TabScripts = ({ projectId = '', sectionId }) => {
    const tableRef = useRef(null);
    // const selector = useSelector((state) => state);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { addSuccess, addError } = useMessage();

    const { dialogState, openDialog, closeDialog } = useDialog();
    const { platform, isAuthorizedRole } = useGlobalContext();
    const { isAuthorizedAction } = useAuthorized();

    // const [users, setUsers] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [, setIsInit] = useState(false);

    const [isReLoading, setIsReLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    const [isSectionsLoading, setIsSectionsLoading] = useState(false);
    const [isSections, setIsSections] = useState(false);

    const [scriptsTotal, setScriptTotal] = useState(0);
    const [scripts, setScripts] = useState([]);

    const [checkedCheckbox, setCheckedCheckbox] = useState([]);
    const checkedCheckboxRef = useRef(checkedCheckbox);

    const isCreateAuthorized = isAuthorizedAction([
        isSections ? GLOBAL_ACTIONS.SCRIPT_CREATE : GLOBAL_ACTIONS.SECTION_CREATE
    ]);

    const addScriptUrlRoot = `/scripting/create`;
    let addScriptUrl = `${addScriptUrlRoot}`;

    if (projectId) {
        addScriptUrl += `?projectId=${projectId}`
    }

    if (sectionId) {
        addScriptUrl += `&sectionId=${sectionId}`
    }

    const requestScript = useCallback(
        async (offset, count, sort = { by: '', desc: false }, search = '', filters = {}) => {
            setIsLoading(true);

            const scriptResponse = !sectionId
                ? await api.scripting.getProjectScripts(
                      projectId,
                      offset / count,
                      count,
                      sort.by ? sort.by + ',' + (sort.desc ? 'desc' : 'asc') : 'modifyTime,desc',
                    search
                  )
                : await api.scripting.getPublicSectionScripts(
                      sectionId,
                      offset / count,
                      count,
                      sort.by ? sort.by + ',' + (sort.desc ? 'desc' : 'asc') : 'modifyTime,desc',
                    search
                  );


            setScriptTotal(scriptResponse.totalElements);
            setScripts(scriptResponse.content);

            setIsEmpty(scriptResponse.totalElements === 0 && _.isEmpty(filters) && !search);

            setIsLoading(false);
            setIsReLoading(false);
        },
        [projectId, sectionId]
    );
    const checkboxHandler = useCallback((node) => {
        setCheckedCheckbox((prevValue) => {
            if (prevValue.some((c) => c.id === node.id)) {
                return prevValue.filter((c) => c.id !== node.id);
            } else {
                return [...prevValue, node];
            }
        });
    }, []);

    const archiveScripts = useCallback(
        async (data) => {
            try {
                setIsLoading(true);
                closeDialog();

                if (data?.id) {
                    await api.archive.moveToArchive([data.id]);
                } else if (checkedCheckboxRef.current.length) {
                    await api.archive.moveToArchive(data.map((script) => script.id));
                }

                dispatch(fetchMenuButtons());
                tableRef?.current?.reload();

                addSuccess(data?.id ? 'Скрипт перемещен в архив' : 'Выбранные скрипты перенесены в архив');
            } catch (error) {
                addError('При архивировании произошла ошибка');
            } finally {
                setIsLoading(false);
            }

            setIsLoading(true);
            closeDialog();
        },
        [dispatch, closeDialog, addSuccess, addError]
    );
    const onScriptArchive = useCallback(
        async (data) => {
            if (data?.length) {
                setCheckedCheckbox(data.map((d) => d.original));
            }

            let text = '';

            if (data?.length) {
                text = data.map((item) => {
                    return {
                        ...item.original,
                        text: (
                            <>
                                {item?.original?.parents?.projectTitle ? item.original.parents.projectTitle + ' / ' + item.original.parents.sections[0].title + ' / '
                                    : 'Общая Скрипт / '}
                                <span style={{ color: '#279BD9' }} onClick={() => navigate(`scripting/edit/${item.original['id']}`)}>
                                    {item?.original?.title}
                                </span>
                            </>
                        ),
                    };
                });
            } else {
                text = (
                    <span>
                        Вы действительно хотите перенести в архив Скрипт&nbsp;
                        <span style={{ color: '#279BD9' }} onClick={() => navigate(`scripting/edit/${data['id']}`)}>
                            {data?.title}
                        </span>
                        &nbsp;? Объекты в архиве могут быть восстановлены
                    </span>
                );
            }

            openDialog({
                title: 'Архивирование',
                subTitle: data?.length
                    ? 'Вы действительно хотите переместить в архив нижеперечисленные объекты? Объекты в архиве могут быть восстановлены'
                    : null,
                text,
                contentType: data?.length ? 'CHECKBOX_LIST' : 'TEXT',
                color: 'green',
                closeBtnText: 'Нет, закрыть',
                submitBtnText: 'Подтвердить',
                onChange: (news) => checkboxHandler(news),
                onSubmit: () => archiveScripts(data?.length ? checkedCheckboxRef.current : data),
                onClose: closeDialog,
            });
        },
        [checkboxHandler, archiveScripts, navigate, openDialog, closeDialog]
    );

    const scriptsColumns = useMemo(
        () => [
            {
                Header: 'Содержание',
                accessor: 'title',
                width: 300,
                maxWidth: 300,
                Cell: (data) => (
                    <TableTitleCell
                        title={data.row.original.title}
                        link={`/scripting/script/${data.row.original['id']}`}
                        description={data.row.original['description']}
                    />
                ),
            },
            {
                Header: 'Дата изменения',
                accessor: 'modifyTime',
                Cell: (data) => {
                    const modifyTime = data.row.original['modifyTime'];
                    const createTime = data.row.original['createTime'];

                    return (
                        <div>
                            {data.isMobile && <div className={cx.subheader}>Дата изменения</div>}
                            <div>{formatDateTime(modifyTime ? modifyTime : createTime, true)}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Автор',
                accessor: 'author',
                Cell: (data) => {
                    return (
                        <div>
                            {data.isMobile && <div className={cx.subheader}>Автор</div>}
                            {data.row.original['author']['status'] === 'DELETED' && (
                                <span style={{ opacity: 1 }}>
                                {data.row.original['author']['firstName'] + ' ' + data.row.original['author']['lastName']}
                            </span>
                            )}
                            {data.row.original['author']['status'] !== 'DELETED' && (
                                <Link to={'/edit-user/' + data.row.original['author']['login']}>
                                    {data.row.original['author']['firstName'] + ' ' + data.row.original['author']['lastName']}
                                </Link>
                            )}
                        </div>
                    );
                },
            },
            {
                id: 4,
                settings: platform === 'mobile' ? ['no_td_wrap'] : [],
                Cell: function(data) {
                    let toolbar = [];

                    toolbar.push({
                        icon: Icons.EDIT_PEN,
                        tooltip: 'Редактировать',
                        link: `scripting/edit/${data.row.original.id}/edit`,
                        onClick: () => navigate(`/scripting/edit/${data.row.original.id}/edit`),
                        isHidden: (original) => !original?.permissions?.canEdit
                    });

                    // toolbar.push({
                    //     icon: data.row.original['favorite'] ? Icons.STAR_FILLED : Icons.STAR,
                    //     iconActive: data.row.original['favorite'],
                    //     tooltip: 'В избранное',
                    //     onClick: async (data) => {
                    //         if (data.favorite) {
                    //             await api.favorites.deleteNewsFromFavorites(data.id);
                    //             addSuccess('Скрипт удален из избранного');
                    //         } else {
                    //             await api.favorites.addNewsToFavorites(data.id);
                    //             addSuccess('Скрипт добавлен в избранное');
                    //         }

                    //         const newsCopy = Object.assign([], scripts);
                    //         newsCopy.find((item) => item.id === data.id).favorite = !data.favorite;
                    //         setScripts(newsCopy);
                    //     },
                    // });
                    // if (data.row.original.permittedActions.includes(GLOBAL_ACTIONS.RESOURCE_ARCHIVE_MOVE)) { /* ... */ }
                    toolbar.push({
                        icon: Icons.ARCHIVE,
                        tooltip: 'В архив',
                        width: 15,
                        height: 15,
                        onClick: (data) => onScriptArchive(data),
                        isHidden: (original) => !original?.permissions?.canArchive
                    });

                    return EditRowToolbar(toolbar)(data);
                },
            },
        ],
        [platform, navigate, onScriptArchive]
    );

    const scriptsActions = useMemo(() => {
        const actions = [];

        if (isAuthorizedRole([USER_ROLES.ROLE_ROOT, USER_ROLES.ROLE_ADMIN, USER_ROLES.ROLE_SUPER_USER])) {
            actions.push({
                icon: Icons.ARCHIVE,
                label: 'В архив',
                onClick: (rows) => onScriptArchive(rows),
            });
        }

        return actions;
    }, [isAuthorizedRole, onScriptArchive]);

    // const scriptsFilters = [
    //     {
    //         label: 'Дата создания',
    //         fields: [
    //             {
    //                 name: 'createTime',
    //                 type: 'date-select',
    //                 default: {
    //                     type: {
    //                         label: 'Все время',
    //                         value: 0,
    //                     },
    //                     from: null,
    //                     to: null,
    //                 },
    //             },
    //         ],
    //     },
    //     {
    //         label: 'Автор',
    //         fields: [
    //             {
    //                 name: 'authorLogin.in',
    //                 type: 'select',
    //                 default: null,
    //                 isMulti: true,
    //                 options: users.map((user) => {
    //                     return {
    //                         label: user.firstName + ' ' + user.lastName,
    //                         value: user.login,
    //                     };
    //                 }),
    //             },
    //         ],
    //     },
    //     {
    //         label: 'Приоритет',
    //         fields: [
    //             {
    //                 name: 'priority.in',
    //                 type: 'select',
    //                 default: null,
    //                 options: [
    //                     { label: 'Любой', value: '' },
    //                     { label: 'Срочные новости', value: 'HIGH' },
    //                     { label: 'Обычные новости', value: 'MEDIUM' },
    //                 ],
    //             },
    //         ],
    //     },
    //     {
    //         label: 'Срок действия скрипта',
    //         fields: [
    //             {
    //                 name: 'expireTime.greaterOrEqualThan',
    //                 type: 'date',
    //                 default: null,
    //             },
    //             {
    //                 name: 'expireTime.lessOrEqualThan',
    //                 type: 'date',
    //                 default: null,
    //             },
    //         ],
    //     },
    // ];

    useEffect(() => {
        checkedCheckboxRef.current = checkedCheckbox;
    }, [checkedCheckbox]);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         const usersResponse = await selectUsers(selector);
    //         setUsers(usersResponse.content);
    //     };
    //     fetchData();
    // }, [selector]);

    useEffect(() => {
        const fetchSections = async () => {
            setIsSectionsLoading(true);

            const response = await api.section.getRootSections(projectId);
            setIsSections(response.length !== 0);

            setIsSectionsLoading(false);
        };

        fetchSections();
    }, [projectId, sectionId]);

    useEffect(() => {
        setIsReLoading(true);
        setIsEmpty(false);

        setScriptTotal(0);
        setIsInit(prev => {
            if (!prev) {
                return !prev;
            }

            tableRef?.current?.reload();
            return prev;
        });
    }, [projectId, sectionId]);

    return (
        <div className={cx.tabScripts}>
            <Confirmation {...dialogState} />
            {((isLoading && isReLoading) || isSectionsLoading) && (
                <div className={cx.loader}>
                    <Loading withOverlay={false} />
                </div>
            )}
            {isEmpty && !isLoading && !isSectionsLoading && (
                <>
                    {!isSections && (
                        <EmptySectionsScript buttons={isCreateAuthorized ? [
                            {
                                icon: Icons.EDIT_PEN,
                                title: 'Добавить раздел',
                                link: '/add/section',
                            },
                        ] : null} />
                    )}
                    {isSections && (
                        <EmptyScripts buttons={isCreateAuthorized ? [
                            {
                                icon: Icons.EDIT_PEN,
                                title: 'Добавить скрипт',
                                link: addScriptUrl,
                            },
                        ] : null} />
                    )}
                </>
            )}
            {!isEmpty && (
                <div className={cx.scripts}>
                    <TableComponent
                        innerRef={tableRef}
                        id={'tabScriptsTableComponent'}
                        isLoading={isLoading}
                        name="scripts"
                        title="Скрипты"
                        searchTitle="Поиск по автору и названию"
                        addTitle="Добавить скрипт"
                        addTitleMobile="Добавить"
                        columns={scriptsColumns}
                        actions={scriptsActions}
                        total={scriptsTotal}
                        data={scripts}
                        onPaginate={requestScript}
                        // filters={scriptsFilters}
                        isMobile={platform === 'mobile'}
                        onAdd={isAuthorizedAction([GLOBAL_ACTIONS.SCRIPT_CREATE]) ? () => navigate(addScriptUrl) : undefined}
                        // sortOptions={[
                        //     { Header: 'По дате создания', accessor: 'createTime' },
                        //     { Header: 'По дате изменения (по умолчанию)', accessor: 'modifyTime' },
                        //     { Header: 'По названию (по алфавиту)', accessor: 'title' },
                        //     { Header: 'По автору (по алфавиту по фамилии)', accessor: 'author' },
                        // ]}
                        defaultSort={{ by: 'modifyTime', desc: true }}
                    />
                </div>
            )}
        </div>
    );
};

export default TabScripts;
