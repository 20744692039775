import { Node, mergeAttributes } from '@tiptap/core';
import { findParentNodeOfType } from 'prosemirror-utils';

const getListNestedCount = selection => {
    let nestedCount = 0;
    for (let depth = selection.$from.depth; depth > 0; depth -= 1) {
        const node = selection.$from.node(depth);
        if (node.type.name === 'bulletList' || node.type.name === 'orderedList') {
            nestedCount += 1;
        }
    }

    return nestedCount;
};

export const CustomListItem = Node.create({
    name: 'listItem',
    content: 'paragraph* block*',
    defining: true,

    addOptions() {
        return {
            HTMLAttributes: {},
            bulletListTypeName: 'bulletList',
            orderedListTypeName: 'orderedList',
        };
    },

    addAttributes() {
        return {
            style: {
                default: null,
            },
            class: {
                default: null,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'li',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['li', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },

    addKeyboardShortcuts() {
        return {
            Enter: () => this.editor.commands.splitListItem(this.name),
            Tab: () => {
                let nestedCount = getListNestedCount(this.editor.state.selection);

                if (nestedCount >= 9) {
                    return;
                }

                console.log(nestedCount);

                return this.editor
                    .chain()
                    .sinkListItem(this.name)
                    .command(({ tr }) => {
                        console.log(getListNestedCount(tr.selection));

                        return true;
                    })
                    .run();
            },
            'Shift-Tab': () => this.editor.commands.liftListItem(this.name),
            Backspace: ({ editor }) => {
                const listItem = findParentNodeOfType(editor.schema.nodes.listItem)(editor.state.selection);

                if (listItem && !editor.state.selection.$anchor.nodeBefore) {
                    return editor.commands.joinTextblockBackward();
                }

                return false;
            },
        };
    },
});
