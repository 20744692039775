import React from 'react';
import { NodeViewProps, NodeViewWrapper, NodeViewContent } from '@tiptap/react';
import Icon from 'uikit/icon/icon';
import { Icons } from 'uikit/icon';
import { EEmbedDrawIoMode, useEmbedDrawIoDialogStore } from '../../index';
import classNames from 'classnames';
import cn from './styles.module.scss';

export const EmbedDrawIoImageNodeView: React.FC<NodeViewProps> = ({ editor, node, getPos }) => {

    const menuIconProps = { iconColor: '#1280CE' as 'blue', iconSize: 18 }

    const { EDIT_MODE } = EEmbedDrawIoMode;

    const { isEditorDialogOpen, mode } = useEmbedDrawIoDialogStore();

    const setDialogMode = useEmbedDrawIoDialogStore(state => state.setMode);
    const resetDialogMainState = useEmbedDrawIoDialogStore(state => state.resetDialogMainState);

    const [nodePos, setNodePos] = React.useState(getPos());
    const [nodeID] = React.useState<string | null>(node?.attrs?.id ? node.attrs.id : null);

    const invokeDialog = (mode: EEmbedDrawIoMode): void => {
        setDialogMode(mode);
        const newNodePos = nodePos !== undefined && nodePos !== -1 ? nodePos : getPos();
        if (editor) (editor.commands as any).involveEmbedDrawIoDialog(false, newNodePos);
    };

    const handleDeleteNode = (): void => {
        editor.commands.command(({ tr }) => {
            const newNodePos = nodePos !== undefined && nodePos !== -1 ? nodePos : getPos();
            tr.delete(newNodePos, newNodePos + node.nodeSize);
            return true;
        });
        resetDialogMainState();
    };

    React.useEffect(() => {
        const transactionHandler = () => {
            const newNodePos = getPos();
            if (nodePos !== newNodePos) setNodePos(newNodePos);
        }
        editor.on('transaction', transactionHandler);
        return () => {
            editor.off('transaction', transactionHandler);
        }
      }, [nodePos, editor, mode, node, getPos, nodeID]);


    return (
        <NodeViewWrapper
            className={classNames(cn.embedDrawIoImageWrapper, {
                [cn.locked]: isEditorDialogOpen
            })}
        >
            <div className={ cn.embedDrawIoImageMenu }>
                <span onClick={() => invokeDialog(EDIT_MODE)}>
                    <Icon
                        type={ Icons.EditorIconEdit } color={ menuIconProps.iconColor }
                        width={ menuIconProps.iconSize } height={ menuIconProps.iconSize }
                    />
                </span>
                {/* <span onClick={() => invokeDialog(VIEWER_MODE)}>
                    <Icon
                        type={ Icons.EditorIconImage } color={ menuIconProps.iconColor }
                        width={ menuIconProps.iconSize } height={ menuIconProps.iconSize }
                    />
                </span> */}
                <span onClick={ handleDeleteNode }>
                    <Icon
                        type={ Icons.EditorIconTrash } color={ menuIconProps.iconColor }
                        width={ menuIconProps.iconSize } height={ menuIconProps.iconSize }
                    />
                </span>
            </div>
                {
                    node?.attrs?.src && (
                        <NodeViewContent
                            as='img'
                            src={ node.attrs.src }
                            className={ cn.embedDrawIoImage }
                        />
                    )
                }
        </NodeViewWrapper>
    );
};
