import { DateTime } from 'luxon';
import { collectArticleParentsIds, visualPathFromParentNodes } from 'lib/helpers';
import TreeUtils from 'lib/util/tree.util';
import { normalizeSpaces } from '../../lib/helpers/normalizeSpaces';

export const getInitialExactQueryGroupsItem = () => {
    return {
        exactAnswer: '',
        exactQueries: [],
    };
};

export class ArticleFormModel {
    id = null;
    initId = null;
    logo = '';
    title = '';
    text = '';
    description = '';
    priority = 'MEDIUM';
    from = null;
    lifeTime = null;
    originalLifeTime = null;
    createTime = null;
    parentProjectId = null;
    parentSectionId = null;
    errors = {
        title: null,
        titleExists: null,
        description: null,
        text: null,
        location: null,
        to: null,
    };
    keywords = [];
    minusWords = [];
    files = [];
    attachments = [];
    logoUuid = null;
    meta = {
        parentProject: null,
        parentSection: null,
        sections: [],
        parentsIds: [],
        visualPath: [],
        targetNodeId: null,
        title: null,
        files: [],
    };
    touched = {};
    oldTitle = null;
    oldParentSectionId = null;
    timeId = null;
    insignificantChanges = false;
    notifySystem = false;
    notifyEmail = false;
    exactQueryGroups = [getInitialExactQueryGroupsItem()];
    documentTags = [];
    chatAIIndexingEnabled = false;
    attachmentsCollapsed = false;

    constructor(js, fromApi = false) {
        const article = js?.article;

        if (article?.id) {
            this.id = article.id;
            this.initId = article.initId;
            this.publishedType = js.publishedType || 'article';
            this.title = article.title;
            this.oldTitle = fromApi ? article.title : article.oldTitle;
            this.meta.title = article.title;
            this.description = article.description;
            this.text = article.text;
            this.priority = article.priority;
            this.priority = article.priority || 'MEDIUM';
            this.lifeTime = article.lifeTime ? new Date(Date.parse(article.lifeTime)) : DateTime.local().plus({ years: 10 }).toJSDate();
            this.createTime = (article.createTime && new Date(article.createTime)) || null;
            this.files = article.files || [];
            this.attachments = article.attachments || [];
            this.logo = article.logo || '';
            this.logoUuid = article.logoUuid;
            this.minusWords = article.minusWords?.split(',') || [];
            this.keywords = article.keywords?.split('%2C') || [];
            this.exactQueryGroups = article.exactQueryGroups || [getInitialExactQueryGroupsItem()];
            this.documentTags = article.documentTags ?? [];
            this.chatAIIndexingEnabled = article.chatAIIndexingEnabled;
            this.attachmentsCollapsed = !!article.attachmentsCollapsed;

            if (article.publicationTime) {
                this.from = new Date(Date.parse(article.publicationTime));
            } else {
                this.from = article.from;
            }
        } else {
            this.priority = 'MEDIUM';
            this.initId = article?.initId;
            this.lifeTime = DateTime.local().plus({ years: 10 }).toJSDate();
            this.timeId = Date.now().toString();
        }

        const parents = js?.parents;

        if (parents?.projectId) {
            this.parentProjectId = parents.projectId;
            this.meta.parentProject = {
                projectId: parents.projectId,
                projectTitle: parents.projectTitle,
            };

            const sections = parents.sections?.reverse();

            if (sections && sections.length > 0) {
                const parentSection = sections[0];

                this.parentSectionId = parentSection.uuid;
                if (fromApi) {
                    this.oldParentSectionId = parentSection.uuid;
                }
                this.meta.parentSection = { ...parentSection };
                this.meta.sections = [...sections];
            }

            this.meta.parentsIds = collectArticleParentsIds(this.id, parents);
            this.meta.visualPath = visualPathFromParentNodes(parents);
        }
    }

    copy() {
        return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
    }

    setTargetNodeId(id) {
        this.meta.targetNodeId = id;
    }

    makeMovement(tree) {
        if (!tree) {
            return;
        }

        const thisNode = tree.getNodeById(this.id);

        if (thisNode.name !== this.title) {
            tree.updateNode(thisNode, { name: this.title });
        }

        if (tree && this.meta.targetNodeId) {
            const targetNode = tree.getNodeById(this.meta.targetNodeId);
            tree.moveNodeTo(thisNode, targetNode, 0);
            tree.selectNode(thisNode);
        }
    }

    getParentsIds(excludeSelf) {
        const parentsIds = this.meta.parentsIds;

        if (excludeSelf && parentsIds.length > 0) {
            return parentsIds.slice(0, parentsIds.length - 1);
        } else {
            return parentsIds;
        }
    }

    setParentsIds(node) {
        function traverseNodes(path, node) {
            if (!node) {
                return path;
            } else {
                return traverseNodes([...path, node.id], node.parent);
            }
        }

        this.meta.parentsIds = traverseNodes([this.id], node).reverse();
    }

    getVisualPath() {
        return this.meta.visualPath;
    }

    setVisualPath(node) {
        this.meta.visualPath = TreeUtils.getVisualPathTo(node);
    }

    clearLocation() {
        this.parentProjectId = null;
        this.parentSectionId = null;

        this.meta.parentsIds = [];
        this.meta.visualPath = [];

        this.meta.targetNodeId = null;
    }

    getFormData() {
        const data = {};

        if (this.id) {
            data.id = this.id;
        }

        data.initId = this.initId;
        data.articleTitle = normalizeSpaces(this.title?.trim());
        data.articleBody = this.text;
        data.description = normalizeSpaces(this.description?.trim());
        data.priority = this.priority;
        data.lifeTime = this.lifeTime instanceof Date ? this.lifeTime?.toISOString() : this.lifeTime;
        data.parentProjectId = this.parentProjectId;
        data.parentSectionId = this.parentSectionId;
        data.files = this.getFiles()
            .filter(f => f.status === 'done')
            .map(f => f.fileId);
        data.minusWords = this.minusWords.join();
        data.keywords = this.keywords.join('%2C');
        data.insignificantChanges = this.insignificantChanges;
        data.notifySystem = this.notifySystem;
        data.notifyEmail = this.notifyEmail;
        data.exactQueryGroups = this.exactQueryGroups;
        data.documentTags = this.documentTags;
        data.chatAIIndexingEnabled = this.chatAIIndexingEnabled;

        if (this.from instanceof Date && this.from > Date.now()) {
            data.publicationTime = this.from?.toISOString();
        }

        data.logoType = this.logo !== null && this.logo.length > 0 ? this.logo.split(';')[0].split(':')[1] : null;
        data.logo = this.logo !== null ? (this.logo.length > 0 ? this.logo.split(',')[1] : '') : null;

        data.attachmentsCollapsed = this.attachmentsCollapsed;
        return data;
    }

    static asFiles(attachments) {
        return attachments.map(att => ({
            status: 'done',
            fileId: att.id || att.fileId,
            serverData: { ...att },
        }));
    }

    getFiles() {
        return this.meta.files.length > 0 ? this.meta.files : ArticleFormModel.asFiles(this.attachments);
    }

    setFiles(files) {
        this.meta.files = files;
    }

    changeAttachmentsCollapsed() {
        this.attachmentsCollapsed = !this.attachmentsCollapsed;
    }
}
