import ApiClient from './api-axios';
import { PAGE_SIZE } from 'lib/config/constant';

export default class FileApi extends ApiClient {
    uploadFile = async (name, formData, onUploadProgress, cancelToken) => {
        return this.upload('/services/kms/api/v1/files/upload?name=' + encodeURIComponent(name), formData, onUploadProgress, cancelToken);
    };

    uploadEditorFile = async (formData, onUploadProgress, cancelToken) => {
        return this.upload('/services/upload/api/v1/editor/image', formData, onUploadProgress, cancelToken);
    };

    getFileByUuid = async (uuid, cancelToken) => {
      console.log(cancelToken);
        return this.get('/services/kms/api/v1/files/' + uuid, {}, false, '', {}, cancelToken);
    };

    getFileUsage = async ({ uuid, page = 0, size = PAGE_SIZE, sort = '' }) => {
        const params = new URLSearchParams({ fileId: uuid, page, size, sort });

        return this.get(`/services/kms/api/v1/files/${uuid}/usage?` + params.toString());
    };

    getUserFileList = async ({ page = 0, size = PAGE_SIZE, sort = '', filter }) => {
        const params = new URLSearchParams({ page, size, sort });

        return await this.get('/services/kms/api/v1/files/', params, { filter });
    };

    getFileListByProjectNew = async (project, page = 0, size = 20, sort = 'modifyTime,desc', search = '', filters = {}) => {
        const params = new URLSearchParams({ page, size, sort });

        if (search) {
            params.append('name.contains', search);
            params.append('orSearch.specified', 'true');
        }

        if (filters['type.in']) {
            params.append('type.in', filters['type.in'].map((filter) => filter.value));
        }

        if (filters['fileSize']) {
            const size = filters['fileSize']['type']['value'] === 0
                ? 1000 : filters['fileSize']['type']['value'] === 1
                    ? 1000 * 1000 : 1000 * 1000 * 1000;

            params.append('fileSize.greaterOrEqualThan', (Math.floor(filters['fileSize']['from'] * size)).toString());
            params.append('fileSize.lessOrEqualThan', (Math.ceil(filters['fileSize']['to'] * size)).toString());
        }

        if (filters['userLogin.in']) {
            params.append('userLogin.in', filters['userLogin.in'].map((filter) => filter.value));
        }

        if (filters['createTime']) {
            if (filters['createTime']['from'] !== null) {
                params.append('createTime.greaterOrEqualThan', new Date(filters['createTime']['from']).toISOString());
            }
            if (filters['createTime']['to'] !== null) {
                params.append('createTime.lessOrEqualThan', new Date(filters['createTime']['to']).toISOString());
            }
        }

        return await this.get(`/services/kms/api/v1/files/list/${project}?` + params);
    };

    getFileListByProject = async ({ projectUuid, page = 0, size = PAGE_SIZE, sort = '', filter }) => {
        return await this.get(`/services/kms/api/v1/files/list/${projectUuid}?page=${page}&size=${size}&sort=${sort}`, { filter });
    };

    getFileListBySectionNew = async (project, section, page = 0, size = 20, sort = 'modifyTime,desc', search = '', filters = {}) => {
        const params = new URLSearchParams({ page, size, sort });

        if (search) {
            params.append('name.contains', search);
            params.append('orSearch.specified', 'true');
        }

        if (filters['type.in']) {
            params.append('type.in', filters['type.in'].map((filter) => filter.value));
        }

        if (filters['fileSize']) {
            const size = filters['fileSize']['type']['value'] === 0
                ? 1000 : filters['fileSize']['type']['value'] === 1
                    ? 1000 * 1000 : 1000 * 1000 * 1000;

            params.append('fileSize.greaterOrEqualThan', (Math.floor(filters['fileSize']['from'] * size)).toString());
            params.append('fileSize.lessOrEqualThan', (Math.ceil(filters['fileSize']['to'] * size)).toString());
        }

        if (filters['userLogin.in']) {
            params.append('userLogin.in', filters['userLogin.in'].map((filter) => filter.value));
        }

        if (filters['createTime']) {
            if (filters['createTime']['from'] !== null) {
                params.append('createTime.greaterOrEqualThan', new Date(filters['createTime']['from']).toISOString());
            }
            if (filters['createTime']['to'] !== null) {
                params.append('createTime.lessOrEqualThan', new Date(filters['createTime']['to']).toISOString());
            }
        }

        return await this.get(`/services/kms/api/v1/files/list/${project}/${section}?` + params);
    };

    getFileListBySection = async ({ projectUuid, sectionUuid, page = 0, size = PAGE_SIZE, sort = '', filter }) => {
        return await this.get(`/services/kms/api/v1/files/list/${projectUuid}/${sectionUuid}?page=${page}&size=${size}&sort=${sort}`, { filter });
    };

    getAttachedFileList = async ({ page = 0, size = PAGE_SIZE, sort = '', search, filters = {} }) => {
        const params = new URLSearchParams();

        if (search) {
            params.append('name.contains', search);
            params.append('orSearch.specified', 'true');
        }

        if (filters['type.in']) {
            params.append('type.in', filters['type.in'].map((filter) => filter.value));
        }

        if (filters['fileSize']) {
            const size = filters['fileSize']['type']['value'] === 0
                ? 1000 : filters['fileSize']['type']['value'] === 1
                    ? 1000 * 1000 : 1000 * 1000 * 1000;

            params.append('fileSize.greaterOrEqualThan', (Math.floor(filters['fileSize']['from'] * size)).toString());
            params.append('fileSize.lessOrEqualThan', (Math.ceil(filters['fileSize']['to'] * size)).toString());
        }

        if (filters['userLogin.in']) {
            params.append('userLogin.in', filters['userLogin.in'].map((filter) => filter.value));
        }

        if (filters['createTime']) {
            if (filters['createTime']['from'] !== null) {
                params.append('createTime.greaterOrEqualThan', new Date(filters['createTime']['from']).toISOString());
            }
            if (filters['createTime']['to'] !== null) {
                params.append('createTime.lessOrEqualThan', new Date(filters['createTime']['to']).toISOString());
            }
        }

        return await this.get(`/services/kms/api/v1/files/attached?page=${page}&size=${size}&sort=${sort}&` + params);
    };

    getNotAttachedFileList = async ({ page = 0, size = PAGE_SIZE, sort = '', search, filters = {} }) => {
        const params = new URLSearchParams({ page, size });

        if (search) {
            params.append('orSearch.specified', 'true');
            params.append('name.contains', search);
            /*params.append('userLogin.contains', search);
            params.append('userFirstName.contains', search);
            params.append('userLastName.contains', search);
            params.append('userMiddleName.contains', search);*/
        }

        if (filters['type.in']) {
            params.append('type.in', filters['type.in'].map((filter) => filter.value));
        }

        if (filters['fileSize']) {
            const size = filters['fileSize']['type']['value'] === 0
                ? 1000 : filters['fileSize']['type']['value'] === 1
                    ? 1000 * 1000 : 1000 * 1000 * 1000;

            params.append('fileSize.greaterOrEqualThan', (Math.floor(filters['fileSize']['from'] * size)).toString());
            params.append('fileSize.lessOrEqualThan', (Math.ceil(filters['fileSize']['to'] * size)).toString());
        }

        if (filters['createTime']) {
            if (filters['createTime']['from'] !== null) {
                params.append('createTime.greaterOrEqualThan', new Date(filters['createTime']['from']).toISOString());
            }
            if (filters['createTime']['to'] !== null) {
                params.append('createTime.lessOrEqualThan', new Date(filters['createTime']['to']).toISOString());
            }
        }
        return await this.get(`/services/kms/api/v1/files/not-attached?page=${page}&size=${size}&sort=${sort}&` + params);
    };

    changeFileName = async (data) => {
        return this.post('/services/kms/api/v1/files/edit-name', data);
    };

    removeFile = async (fileId) => {
        return this.post(`/services/kms/api/v1/files/delete/${fileId}`);
    };

    replaceFile = async (fileId, formData, onUploadProgress, cancelToken) => {
        return this.upload(`/services/kms/api/v1/files/edit-file/${fileId}`, formData, onUploadProgress, cancelToken);
    };

    download = async (fileId) => {
        return this.get(`/services/kms/api/v1/files/download/${fileId}`, {}, false, 'blob');
    };

    getTempFile = async (file) => {
        return await this.post('/services/kms/api/v1/files/temp/create', JSON.stringify({ fileId: file.fileId }));
    };

    downloadTempFile = async (temp_id) => {
        return await this.post(`/services/kms/api/v1/files/temp/download/${temp_id}`);
    };

    validateFileName = async (name, projectIds) => {
      const params = new URLSearchParams({name});

      if (projectIds) {
        params.append('projectIds', projectIds);
      }

      return await this.get('/services/kms/api/v1/files/validate/name?' + params)
    }

    checkFileExistInDocument = async (fileId, articleId) => {
        if (!fileId || !articleId) {
            return;
        };

        return await this.get(`/services/kms/api/v1/files/is-attached/${fileId}/${articleId}`)
    }
}
