import { useEffect, useState } from 'react';
import Modal from 'react-modal';

import { desktopStyles } from 'lib/util/modalStyles';
import { ModalBody, ModalFooter, ModalHeader } from 'components/modal/modal-components';
import Checkbox from 'uikit/checkbox/checkbox';
import { stringToBoolean } from 'editors/tiptap-editor/utils/stringToBool';

export const VideoSettingsModal = ({ data, show, onClose, onSubmit }) => {
    const [autoplay, setAutoplay] = useState('');
    const [responsive, setResponsive] = useState('');

    const handleSubmit = () => {
        onSubmit({ autoplay, responsive });
    };

    useEffect(() => {
        if (data) {
            setAutoplay(data.autoplay);
            setResponsive(stringToBoolean(data.responsive));
        }
    }, [data]);

    return (
        <Modal
            isOpen={show}
            preventScroll
            contentLabel={'Добавление видео'}
            style={{ ...desktopStyles, content: { ...desktopStyles.content, maxWidth: 568, width: '100%' } }}
        >
            <ModalHeader title="Добавление видео" onClose={onClose} />
            <ModalBody>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Checkbox checked={autoplay} label="Автовоспроизведение" onChange={e => setAutoplay(e.target.checked)} />
                    <Checkbox checked={responsive} label="Адаптивная ширина" onChange={e => setResponsive(e.target.checked)} />
                </div>
            </ModalBody>
            <ModalFooter
                buttons={[
                    { label: 'Отмена', onClick: onClose },
                    { label: 'Сохранить', color: 'blue', onClick: handleSubmit },
                ]}
            />
        </Modal>
    );
};
