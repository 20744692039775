import React from 'react';
import classNames from 'classnames';
import { Link } from 'shared/router';
import formatDateTime from 'lib/helpers/formatDateTime';
import cs from './user.module.scss';

const User = ({ image = '', name = '', id = '', login, date, className, stackReversed }) => {
    const cls = classNames(cs.user, className);

    return (
        <Link id={id} className={cls} to={'/edit-user/' + login}>
            <div className={cs.image} style={{ backgroundImage: `url(${image})` }}/>
            <div className={classNames(cs.desc, { [cs.descReverse]: !date || stackReversed })}>
                <div className={cs.text}>{!date ? 'Автор' : formatDateTime(date)}</div>
                <div className={cs.link}>{name}</div>
            </div>
        </Link>
    );
};

export default User;
