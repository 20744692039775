import React from 'react';
import { CurrentRouter } from 'shared/router';
import { Icons } from 'uikit/icon';
import { EmptyError } from '../components/projects/empty';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        console.log(error, info);
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return (
                <EmptyError buttons={[{
                    icon: Icons.RELOAD,
                    title: 'Перезагрузить',
                    onClick: () => window.location.reload()
                }, {
                    icon: Icons.HOME,
                    title: 'На главную',
                    onClick: () => {
                        CurrentRouter.getNavigateFunction()('/');
                        window.location.reload();
                    }
                }]} />
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
