import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Button from 'uikit/button';
import Icon, { Icons } from 'uikit/icon';
import { useGlobalContext } from 'lib/hooks';
import { FILES_TYPE_ICONS } from 'lib/config/constant';
import { FileViewDialog } from 'components/file/file-view-dialog';
import { MultiClumpTooltip } from 'components/MultiClumpTooltip/MultiClumpTooltip';
import { ImagePreviewModal, useImagePreviewModal } from 'components/ImagePreviewModal/index';
import cs from './script-playback.module.scss';
import api from 'api';
import Tippy from '@tippyjs/react';
import { useSelector } from 'react-redux';
import { selectNewsPicker } from '../../slice/newsPickerSlice';

function ScriptPlayback({ elements, preview, onComplete }) {
    const newsPicker = useSelector(selectNewsPicker);
    const { setGotScriptProgress } = useGlobalContext();

    const [steps, setSteps] = useState([]);
    const [isComplete, setIsComplete] = useState(false);

    const [isViewFileModal, setIsViewFileModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const {
        openImageModal,
        isOpen,
        setIsOpen,
        imageRef,
        imageSrc,
        imageLoading,
        setImageLoading,
        imageZoomInitial,
        imageZoomLimit,
        imageDownload,
        downloadAction
    } = useImagePreviewModal();

    const buttonType = {
        positive: 'green',
        negative: 'red',
    };

    const onStepRefresh = (stepIdx) => {
        setSteps((prevState) => {
            const state = JSON.parse(JSON.stringify(prevState));

            state[stepIdx]['isComplete'] = false;
            state[stepIdx]['conditionNodes'] = state[stepIdx]['conditionNodes'].map((n) => ({ ...n, selected: false }));
            return state.slice(0, stepIdx + 1);
        });
    };

    const setNextStep = (condition, stepId) => {
        setSteps((prevState) => {
            const state = JSON.parse(JSON.stringify(prevState));
            const currentStepIndex = state.findIndex((s) => s.id === stepId);

            if (currentStepIndex !== -1) {
                const conditionStepIndex = state[currentStepIndex]['conditionNodes'].findIndex((i) => i.id === condition.id);

                state[currentStepIndex]['isComplete'] = true;
                state[currentStepIndex]['conditionNodes'][conditionStepIndex]['selected'] = true;

                return [...state, ...elements.filter((el) => el.id === condition.targetId)];
            }
        });
    };
    const onFileClickHandler = (file) => {
        setSelectedFile(file);
        setIsViewFileModal(true);
    };

    const onScriptComplete = () => {
        onComplete?.();

        setIsComplete(true);
        setGotScriptProgress(false);
    };
    const onScriptRefreshHandler = () => {
        setSteps((prevState) => {
            const state = JSON.parse(JSON.stringify(prevState));

            state[0]['isComplete'] = false;
            state[0]['conditionNodes'] = state[0]['conditionNodes'].map((n) => ({ ...n, selected: false }));

            return state.slice(0, 1);
        });

        setTimeout(() => {
            const scrollPostingContainer = document.getElementById('scriptPostingWrapper');
            const scrollPreviewContainer = document.getElementById('scriptPreviewModalContent');

            (scrollPostingContainer || scrollPreviewContainer).scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }, 100);

        setIsComplete(false);
    };

    const isShowButtons = () => {
        return steps.length > 1;
    };
    const onScrollBtnClick = () => {
        const scrollPostingContainer = document.getElementById('scriptPostingWrapper');
        const scrollPreviewContainer = document.getElementById('scriptPreviewModalContent');

        const items = document.querySelectorAll('.step-content-item');

        let item;

        for (let i = items.length - 1; i >= 0; i--) {
            if ((scrollPostingContainer || scrollPreviewContainer).scrollTop + 50 < items[i].offsetTop) {
                continue;
            }

            item = items[i];
            break;
        }

        if (!item) {
            return;
        }

        if (scrollPostingContainer) {
            scrollPostingContainer.scrollTo({
                top: item.offsetTop - 96,
                behavior: "smooth"
            });
        } else {
            item.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };
    const onRefreshBtnClick = () => {
        onStepRefresh(steps.length - 2);

        const scrollPostingContainer = document.getElementById('scriptPostingWrapper');
        const items = document.querySelectorAll('.step-content-item');

        if (scrollPostingContainer) {
            scrollPostingContainer.scrollTo({
                top: items[items.length - 2].offsetTop - 96,
                behavior: "smooth"
            });
        } else {
            items[items.length - 2].scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    useEffect(() => {
        if (elements?.length) {
            setSteps([elements.find(p => p.root) || elements[0]]);
            setIsComplete(false);
        }
    }, [elements]);
    useEffect(() => {
        if (!isViewFileModal) {
            setSelectedFile(null);
        }
    }, [isViewFileModal]);

    useEffect(() => {
        if (preview) {
            return;
        }

        setGotScriptProgress(steps.length > 1);
    }, [steps, setGotScriptProgress, preview])
    useEffect(() => {
        if (isComplete) {
            const stepsComplete = document.getElementById("blockStepsComplete");

            if (stepsComplete) {
                stepsComplete.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }
    }, [isComplete]);

    useEffect(() => {
        const wrapperBlock = document.querySelectorAll(".step-content-item");

        if (wrapperBlock.length < 2) {
            return;
        }

        wrapperBlock[wrapperBlock.length - 1].scrollIntoView({ behavior: "smooth", block: "start" });
    }, [steps]);
    useEffect(() => {
        if (steps.length) {
            const images = document.querySelectorAll('.imagesWrapper img');

            images.forEach((image) => {
                image.addEventListener('click', (e) => openImageModal(e.target));
            });
        }
    }, [steps, openImageModal]);

    return (
        <>
            {isViewFileModal && (
                <FileViewDialog
                    file={selectedFile}
                    isNoButtons={true}
                    isNoActions={true}
                    handleClose={() => setIsViewFileModal(false)}
                />
            )}
            <ImagePreviewModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                imageRef={imageRef}
                imageSrc={imageSrc}
                imageLoading={imageLoading}
                setImageLoading={setImageLoading}
                imageZoomInitial={imageZoomInitial}
                imageZoomLimit={imageZoomLimit}
                imageDownload={imageDownload}
                downloadAction={downloadAction}
            />
            {isShowButtons() && (
                <>
                    {!isComplete && (
                        <Tippy content="Пройти предыдущий шаг заново" placement="left">
                            <div className={cs.stepRefreshBtn} onClick={onRefreshBtnClick}>
                                <Icon type={Icons.REFRESH} width={20} height={20} color='white' />
                            </div>
                        </Tippy>
                    )}
                    <Tippy content="Вверх к началу шага" placement="left">
                        <div className={cs.stepScrollBtn} onClick={onScrollBtnClick}>
                            <Icon type={Icons.ARROW_FULL_UP} width={20} height={20} color='white' />
                        </div>
                    </Tippy>
                </>
            )}
            <div className={classNames(cs.stepWrapper, {
                [cs.stepWrapperPreview]: preview,
                [cs.withNewsTicker]: newsPicker.count > 0
            })} data-testid='scriptPlaybackStepsWrapper'>
                {steps.map((step, idx, arr) => {
                    return (
                        <div
                            key={step.id}
                            className={classNames(cs.step, "step-content-item")}
                            data-testid="scriptPlaybackStep"
                        >
                            <div>
                                <div className={cs.stepTitle} data-testid="scriptPlaybackStepTitle">
                                    {step.data.text}
                                </div>
                                {idx < arr.length - 1 && !isComplete && (
                                    <div
                                        className={cs.stepRefresh}
                                        onClick={() => onStepRefresh(idx)}
                                        data-testid="scriptPlaybackStepBackBtn"
                                    >
                                        <Icon color="blue" className={cs.stepRefresh} type={Icons.REFRESH} />
                                        Пройти шаг заново
                                    </div>
                                )}
                            </div>
                            {!!step.articles.length && (
                                <div className={cs.articlesWrapper} data-testid="scriptPlaybackStepArticlesWrapper">
                                    {step.articles.map((article) => (
                                        <a
                                            key={article.articleId}
                                            target="_blank"
                                            rel="nofollow noreferrer"
                                            href={`/projects/article/${article['articleId']}`}
                                        >
                                            <img
                                                src={article['logoUuid']
                                                    ? api.upload.getImage(article['logoUuid'], false, 512)
                                                    : '/img/article-card-cover.jpg'}
                                                alt={article.articleTitle}
                                            />
                                            <MultiClumpTooltip clamp={2} label={article.articleTitle} />
                                        </a>
                                    ))}
                                </div>
                            )}
                            {!!step.links.length && (
                                <div className={cs.linksWrapper} data-testid="scriptPlaybackStepLinksWrapper">
                                    {step.links.map((link) => (
                                        <a
                                            key={link.link}
                                            href={link.link}
                                            target="_blank"
                                            rel="nofollow noreferrer"
                                        >
                                            <Icon type={Icons.LINK} width={16} height={16} color="blue" />
                                            <MultiClumpTooltip clamp={2} label={link.linkTitle} />
                                        </a>
                                    ))}
                                </div>
                            )}
                            {!!step.files.length && (
                                <div className={cs.filesWrapper} data-testid="scriptPlaybackStepFilesWrapper">
                                    {step.files.map((file) => (
                                        <div
                                            key={file.fileId}
                                            onClick={() => onFileClickHandler(file)}
                                        >
                                            <Icon
                                                type={
                                                    FILES_TYPE_ICONS[file.serverData.fileType || file.serverData.type]
                                                    || Icons.NONE_FILE
                                                }
                                                width={20}
                                                height={20}
                                            />
                                            <MultiClumpTooltip
                                                clamp={2}
                                                label={file.serverData.fileName || file.serverData.name}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                            {!!step.images.length && (
                                <div className={classNames(cs.imagesWrapper, 'imagesWrapper')}
                                     data-testid="scriptPlaybackStepImagesWrapper">
                                    {step.images.map((image) => (
                                        <div key={image.imageLink}>
                                            <img
                                                src={image.imageLink}
                                                alt=''
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div className={cs.stepButtons}>
                                {step.conditionNodes.map((condition) => {
                                    return (
                                        <Button
                                            color={buttonType[condition.type]}
                                            key={condition.id}
                                            disabled={isComplete || condition.selected || step.conditionNodes.some((condition) => condition.selected)}
                                            selected={condition.selected}
                                            onClick={() => {
                                                setNextStep(condition, step.id);
                                            }}
                                            data-testid="scriptPlaybackStepNextStepBtn"
                                        >
                                            {condition.data.text}
                                        </Button>
                                    );
                                })}
                                {!step.conditionNodes.length && (
                                    <Button
                                        color="blue"
                                        disabled={isComplete}
                                        onClick={onScriptComplete}
                                        data-testid="scriptPlaybackStepFinishBtn"
                                    >
                                        Завершить скрипт
                                    </Button>
                                )}
                            </div>
                        </div>
                    );
                })}
                {isComplete && (
                    <div id="blockStepsComplete" className={classNames(cs.blockStepsComplete, cs.step)}>
                        <div data-testid="scriptPlaybackScriptComplete">
                            Скрипт завершен
                        </div>
                        <div
                            className={cs.stepRefresh}
                            onClick={() => onScriptRefreshHandler()}
                            data-testid="scriptPlaybackScriptRefresh"
                        >
                            <Icon color="blue" className={cs.stepRefresh} type={Icons.REFRESH} />
                            Начать заново
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default ScriptPlayback;
