import React, { useEffect } from 'react';
import { findParentNodeClosestToPos, posToDOMRect } from '@tiptap/core';

import { Icons } from 'uikit/icon';
import { MenuButton } from '../Menu/MenuButton';
import { Menu, MenuItem } from '../DropdownMenu/DropdownMenu';
import Icon from 'uikit/icon/icon';
import { languages } from '../../extensions/CodeBlock/languages';
import { useEditorContext } from '../../context';
import { BubbleMenu } from '@tiptap/react';
import { findParentNodeOfType } from 'prosemirror-utils';

import cx from '../../editor.module.scss';
import { BubbleExtensions, isBubbleExtensionActive } from '../../utils/isBubbleExtensionActive';

export const BubbleMenuCodeBlock = ({ mode, currentMenu, setCurrentMenu }) => {
    const editor = useEditorContext();
    const attrs = editor?.getAttributes('codeBlock');
    const [language, setLanguage] = React.useState(languages.find(l => l.value === attrs?.language));

    const changeLanguage = item => {
        setLanguage(item);
        editor.commands.updateAttributes('codeBlock', { language: item.value });
    };

    const copyCode = () => {
        setCurrentMenu(undefined);
        const node = findParentNodeOfType(editor.state.schema.nodes.codeBlock)(editor.state.selection);
        if (node) {
            navigator.clipboard.writeText(node.node.textContent);
        }
    };

    useEffect(() => {
        const lang = editor?.getAttributes('codeBlock')?.language;

        if (lang) {
            setLanguage(languages.find(l => l.value === lang));
        }
    }, [editor]);

    return (
        <BubbleMenu
            tippyOptions={{
                onBeforeUpdate: () => {
                    const lang = editor?.getAttributes('codeBlock')?.language;

                    if (lang) {
                        setLanguage(languages.find(l => l.value === lang));
                    }
                },
                placement: 'bottom',
                arrow: false,
                getReferenceClientRect: () => {
                    const nearestParent = findParentNodeClosestToPos(editor?.state.selection.$anchor, node => {
                        return node.type.name === 'codeBlock';
                    });

                    if (nearestParent) {
                        const wrapperDomNode = editor?.view.nodeDOM(nearestParent.pos);

                        if (wrapperDomNode) {
                            return wrapperDomNode.getBoundingClientRect();
                        }
                    }
                    const { ranges } = editor?.state.selection;
                    const from = Math.min(...ranges.map(range => range.$from.pos));
                    const to = Math.max(...ranges.map(range => range.$to.pos));
                    return posToDOMRect(editor?.view, from, to);
                },
            }}
            editor={editor}
            shouldShow={({ editor }) => isBubbleExtensionActive(editor?.state.selection, BubbleExtensions.CODE_BLOCK)}
        >
            <div style={{ display: 'flex', alignItems: 'center', gap: 6, padding: 6 }}>
                <Menu
                    mode={mode}
                    name="languages"
                    currentMenu={currentMenu}
                    setCurrentMenu={setCurrentMenu}
                    label={
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 6,
                                backgroundColor: 'rgba(39, 155, 217, 0.1)',
                                borderRadius: 2,
                                padding: '1px 4px',
                            }}
                        >
                            <div style={{ fontSize: 13, color: '#262626', lineHeight: '19.5px' }}>{language?.label}</div>
                            <Icon type={Icons.EditorIconArrowDown} width={12} height={12} />
                        </div>
                    }
                >
                    {languages.map(l => {
                        return (
                            <MenuItem
                                key={l.label}
                                label={l.label}
                                selected={l.label === language?.label}
                                onClick={() => changeLanguage(l)}
                            />
                        );
                    })}
                </Menu>

                <div style={{ height: 17, width: 1, borderRight: '1px solid rgba(39, 155, 217, 0.15)' }}></div>
                <MenuButton className={cx.bubbleMenuButton} icon={Icons.EditorIconCopy} onClick={copyCode} />
                <div style={{ height: 17, width: 1, borderRight: '1px solid rgba(39, 155, 217, 0.15)' }}></div>
                <MenuButton
                    className={cx.bubbleMenuButton}
                    icon={Icons.EditorIconTrash}
                    onClick={() => {
                        setCurrentMenu(undefined);
                        editor.commands.deleteNode('codeBlock');
                    }}
                />
            </div>
        </BubbleMenu>
    );
};
