import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';
import axios from 'axios';
import { desktopStyles, mobileStyles } from 'lib/util/modalStyles';
import IconButton from 'uikit/icon-button';
import Icon, { Icons } from 'uikit/icon';
import Button from 'uikit/button';
import { FileViewDialog } from 'components/file/file-view-dialog';
import cx from './statistic-reports-page.module.scss';
import { useGlobalContext } from 'lib/hooks';
import StatisticReportsArticlesInProjects from './statistic-reports-articles-in-projects';
import StatisticReportsArticlesInSections from './statistic-reports-articles-in-sections';
import StatisticReportsNewsInProject from './statistic-reports-news-in-project';
import StatisticReportsGeneralNews from './statistic-reports-general-news';
import StatisticReportsFilesInProjects from './statistic-reports-files-in-projects';
import StatisticReportsFilesInSections from './statistic-reports-files-in-sections';
import StatisticReportsFilesInDocuments from './statistic-reports-files-in-documents';
import StatisticReportsActionsInProjects from './statistic-reports-actions-in-projects';
import StatisticReportsActionsInSections from './statistic-reports-actions-in-sections';
import StatisticReportsActionsInArticles from './statistic-reports-actions-in-articles';
import StatisticReportsActionsInNews from './statistic-reports-actions-in-news';
import StatisticReportsSearchRequests from './statistic-reports-search-requests';
import StatisticReportsDetailedSearchRequests from './statistic-reports-detailed-search-requests';
import StatisticReportsUsersInProjects from './statistic-reports-users-in-projects';
import StatisticReportsUsersInSections from './statistic-reports-users-in-sections';
import StatisticReportsUsersInDocuments from './statistic-reports-users-in-documents';
import StatisticReportsReads from './statistic-reports-reads';
import StatisticReportsDocumentsEvaluation from './statistic-reports-documents-evaluation';
import StatisticReportsContentReports from './statistic-reports-content-reports';
import StatisticReportsFilesReports from './statistic-reports-files-reports';
import StatisticReportsActionsReports from './statistic-reports-actions-reports';
import StatisticReportsPopularityByRating from './statistic-reports-popularity-by-rating';
import StatisticReportsUsersReports from './statistic-reports-users-reports';
import { RouteGroup, Route } from 'shared/router';

const StatisticReportsPage = ({ title = '', sectionId }) => {
    const { platform } = useGlobalContext();

    const [isDownloadModal, setIsDownloadModal] = useState(false);
    const [isViewFileModal, setIsViewFileModal] = useState(false);
    const [viewFile, setViewFile] = useState(null);

    const [cancelToken, setCancelToken] = useState(null);

    const onViewFile = (file) => {
        setViewFile(file);
        setIsViewFileModal(true);
    };

    const cancelDownload = () => {
        cancelToken.cancel();
        setCancelToken(null);
        setIsDownloadModal(false);
    };

    useEffect(() => {
        if (!cancelToken) {
            setCancelToken(axios.CancelToken.source());
        }
        return () => {
            if (cancelToken) {
                cancelToken.cancel();
                setCancelToken(null);
            }
        };
    }, [sectionId, cancelToken]);

    return (
        <div className={cx.pageWrapper}>
            {isViewFileModal && <FileViewDialog file={viewFile} handleClose={() => setIsViewFileModal(false)} />}

            <div className={cx.header}>
                <h1>{title}</h1>
            </div>
            <RouteGroup className={cx.routerWrapper} primary={false}>
                <Route
                    to="articles-in-projects"
                    component={<StatisticReportsArticlesInProjects setIsDownloadModal={setIsDownloadModal} />}
                />
                <Route
                    to="articles-in-sections"
                    component={<StatisticReportsArticlesInSections setIsDownloadModal={setIsDownloadModal} />}
                />
                <Route
                    to="news-in-projects"
                    component={<StatisticReportsNewsInProject setIsDownloadModal={setIsDownloadModal} />}
                />
                <Route
                    to="general-news"
                    component={<StatisticReportsGeneralNews setIsDownloadModal={setIsDownloadModal} />}
                />
                <Route
                    to="content-report"
                    component={<StatisticReportsContentReports setIsDownloadModal={setIsDownloadModal} />}
                />
                <Route
                    to="files-in-projects"
                    component={<StatisticReportsFilesInProjects
                        onViewFile={onViewFile}
                        setIsDownloadModal={setIsDownloadModal}
                    />}
                />
                <Route
                    to="files-in-sections"
                    component={<StatisticReportsFilesInSections
                        onViewFile={onViewFile}
                        setIsDownloadModal={setIsDownloadModal}
                    />}
                />
                <Route
                    to="files-in-documents"
                    component={<StatisticReportsFilesInDocuments
                        onViewFile={onViewFile}
                        setIsDownloadModal={setIsDownloadModal}
                    />}
                />
                <Route
                    to="files-reports"
                    component={<StatisticReportsFilesReports
                        onViewFile={onViewFile}
                        setIsDownloadModal={setIsDownloadModal}
                    />}
                />
                <Route
                    to="documents-popularity-by-rating"
                    component={<StatisticReportsPopularityByRating setIsDownloadModal={setIsDownloadModal} />}
                />
                <Route
                    to="documents-evaluation"
                    component={<StatisticReportsDocumentsEvaluation setIsDownloadModal={setIsDownloadModal} />}
                />
                <Route
                    to="user-in-projects"
                    component={<StatisticReportsUsersInProjects setIsDownloadModal={setIsDownloadModal} />}
                />
                <Route
                    to="user-in-sections"
                    component={<StatisticReportsUsersInSections setIsDownloadModal={setIsDownloadModal} />}
                />
                <Route
                    to="user-in-documents"
                    component={<StatisticReportsUsersInDocuments setIsDownloadModal={setIsDownloadModal} />}
                />
                <Route
                    to="users-report"
                    component={<StatisticReportsUsersReports setIsDownloadModal={setIsDownloadModal} />}
                />
                <Route
                    to="actions-in-projects"
                    component={<StatisticReportsActionsInProjects setIsDownloadModal={setIsDownloadModal} />}
                />
                <Route
                    to="actions-in-sections"
                    component={<StatisticReportsActionsInSections setIsDownloadModal={setIsDownloadModal} />}
                />
                <Route
                    to="actions-in-articles"
                    component={<StatisticReportsActionsInArticles setIsDownloadModal={setIsDownloadModal} />}
                />
                <Route
                    to="actions-in-news"
                    component={<StatisticReportsActionsInNews setIsDownloadModal={setIsDownloadModal} />}
                />
                <Route
                    to="actions-report"
                    component={<StatisticReportsActionsReports setIsDownloadModal={setIsDownloadModal} />}
                />
                <Route
                    to="reads"
                    component={<StatisticReportsReads setIsDownloadModal={setIsDownloadModal} />}
                />
                <Route
                    to="search-requests"
                    component={<StatisticReportsSearchRequests setIsDownloadModal={setIsDownloadModal} />}
                />
                <Route
                    to="detailed-search-request"
                    component={<StatisticReportsDetailedSearchRequests setIsDownloadModal={setIsDownloadModal} />}
                />
            </RouteGroup>

            <Modal isOpen={isDownloadModal} style={platform === 'mobile' ? mobileStyles : desktopStyles}>
                <div className={classNames(cx.modal, { [cx.mobile]: platform === 'mobile' })}>
                    <div className={cx.head}>
                        <div className={cx.text}>Скачивание отчета</div>
                        <IconButton icon={<Icon type={Icons.CROSS} width={16} height={16} />} onClick={cancelDownload} />
                    </div>
                    <div className={cx.content}>Формирование отчета началось. После завершения, ссылка придет вам в уведомления</div>
                    <div className={cx.footer}>
                        <Button onClick={cancelDownload}>Закрыть</Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default StatisticReportsPage;
