import React, { useEffect, useState } from 'react';
import Button from 'uikit/button';
import Icon, { Icons } from 'uikit/icon';

const ButtonTimer = ({
    id = null,
    className = '',
    readTime = 0,
    externalRead = false,
    onRead = () => {},
    disabled = false
}) => {
    const [read, setRead] = useState(externalRead);
    const [time, setTime] = useState(0);

    let minutes = Math.floor(time / 60);
    minutes = minutes.toString().length === 1 ? '0' + minutes.toString() : minutes;

    let seconds = time % 60;
    seconds = seconds.toString().length === 1 ? '0' + seconds.toString() : seconds;

    const _onRead = () => {
        if (!read) {
            localStorage.removeItem(id);
            setRead(true);

            onRead(time);
        }
    };

    useEffect(() => {
        const handle = !read && setInterval(() => setTime(t => t + 1), 1000);
        return () => handle > 0 && clearInterval(handle);
    }, [read]);

    useEffect(() => {
        if (localStorage.getItem(id)) {
            setTime(Number(localStorage.getItem(id)));
        } else {
            setTime(0);
        }
    }, [id]);
    useEffect(() => {
        if (!read) {
            localStorage.setItem(id, time.toString());
        }
    }, [id, time, read]);

    useEffect(() => { readTime && setTime(readTime) }, [readTime]);
    useEffect(() => { setRead(externalRead) }, [externalRead]);

    return (
        <Button
            id="markReadButton"
            className={className}
            style={read ? {color: 'black'} : {color: 'white'}}
            color={read ? 'gray' : 'green'}
            onClick={_onRead}
            disabled={disabled}
        >
            <Icon type={!read ? Icons.CHECK_VOID : Icons.CHECK_FILLED} color={read ? 'gray' : 'white'} width={20} height={20}/>
            <p>{!read ? 'Прочитать' : 'Прочитано'}</p>
            <span>({minutes}:{seconds})</span>
        </Button>
    );
};

export default ButtonTimer;
