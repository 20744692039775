import React from 'react';
import classNames from 'classnames';
import { useGlobalContext } from 'lib/hooks';
import StatisticDashboardsActivities from './statistic-dashboards-activities';
import StatisticDashboardsDocuments from './statistic-dashboards-documents';
import StatisticDashboardsGeneral from './statistic-dashboards-general';
import StatisticDashboardsSearch from './statistic-dashboards-search';
import StatisticDashboardsUsers from './statistic-dashboards-users';
import cx from './statistic-total-stats-page.module.scss';
import { RouteGroup, Route } from 'shared/router';

export default function StatisticDashboardsWrapper() {
    const { platform } = useGlobalContext();

    return (
        <div className={classNames(cx.body, { [cx.mobile]: platform === 'mobile' })}>
            <RouteGroup>
                <Route to="general" component={<StatisticDashboardsGeneral />} />
                <Route to="documents" component={<StatisticDashboardsDocuments />} />
                <Route to="users" component={<StatisticDashboardsUsers />} />
                <Route to="activities" component={<StatisticDashboardsActivities />} />
                <Route to="search" component={<StatisticDashboardsSearch />} />
            </RouteGroup>
        </div>
    );
}
