import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';
import { TextSelection } from '@tiptap/pm/state';

const Enter = Extension.create({
    name: 'enter',

    addOptions() {
        return {
            preventBlockTypes: [],
            appendBlockTypes: [],
            appendTextTypes: [],
        };
    },

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey('handleEnterKey'),
                props: {
                    handleKeyDown: (view, event) => {
                        if (event.key !== 'Enter') {
                            return false;
                        }

                        event.preventDefault();
                        event.stopPropagation();

                        const { state, dispatch } = view;
                        const { selection, schema } = state;
                        const { $from } = selection;

                        const currentNode = $from.node();
                        const parentNode = $from.node(-1);

                        const isRestrictedNode = (node) => {
                            return node && this.options.appendBlockTypes.includes(node.type.name);
                        };
                        const isRestrictedText = (node) => {
                            return node && this.options.appendTextTypes.includes(node.type.name);
                        };

                        if (isRestrictedNode(parentNode) || isRestrictedNode(currentNode)) {
                            if (currentNode.textContent) {
                                return false;
                            }

                            const position = state.selection.$from.pos + 1;
                            const paragraph = schema.nodes.paragraph.create();

                            const tr = state.tr.insert(position, paragraph);
                            const newPos = tr.doc.resolve(position + 1);

                            tr.setSelection(TextSelection.near(newPos));
                            tr.scrollIntoView();

                            dispatch(tr);
                            return true;
                        }

                        if (isRestrictedText(parentNode) || isRestrictedText(currentNode)) {
                            const tr = state.tr.insertText('\n');

                            dispatch(tr);
                            return true;
                        }

                        if (parentNode && this.options.preventBlockTypes.includes(parentNode.type.name)) {
                            return true;
                        }

                        return false;
                    },
                },
            }),
        ];
    },
});

export default Enter;
