import React, { useEffect } from 'react';
import { useCancelBehavior, useGlobalContext, useMessage, usePermittedActions } from 'lib/hooks';
import UrlUtils from 'lib/util/url.util';
import { perm, RESOURCE_TYPE } from 'model/resource';
import hot from 'containers/hot-container';
import { TreePropContext } from 'containers/side-tree-wrapper';
import { sectionFormPage } from 'components/section-form/section-form-page.hoc';
import Button from 'uikit/button';
import Loading from 'uikit/loading';
import api from 'api';
import { setDocumentTitle, useNavigate } from 'shared/router';

const EditSectionPage = ({ section, loading, setLoading }) => {
    const navigate = useNavigate();
    const { platform, setFormDataChanged } = useGlobalContext();

    const treePropsContext = React.useContext(TreePropContext);
    const { addError, addSuccess } = useMessage();

    const { cancel } = useCancelBehavior();
    const { checkPermission, ready } = usePermittedActions(RESOURCE_TYPE.SECTION, section.id);

    const editSection = async () => {
        if (!section.validateFields()) {
            return;
        }

        setLoading(true);

        try {
            setFormDataChanged(false);

            const formData = section.getFormData();
            await api.section.editSection(formData);

            section.applyTreeChanges(treePropsContext.tree);
            section.makeMovement(treePropsContext.tree);

            addSuccess('Раздел изменён');
            navigate(UrlUtils.getSectionUrl(formData.parentProjectId, formData.id));
        } catch (error) {
            setFormDataChanged(true);
            addError('Не удалось изменить раздел');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const parentsIds = section.getParentsIds();

        if (parentsIds) {
            treePropsContext.setScrollToNode(parentsIds);
        }
    }, [section, treePropsContext]);

    useEffect(() => {
        if (ready && !checkPermission(perm.document.SECTION_EDIT)) {
            navigate('/401');
        }
    }, [ready, checkPermission, navigate]);

    useEffect(() => {
        if (!section?.title) {
            return;
        }

        setDocumentTitle(`${section.title} — KMS Gran`);
    }, [section]);

    return (
        <>
            {loading && (
                <Loading
                    withOverlay={false}
                    withRelativeOverlay={true}
                />
            )}
            <Button
                label="Отмена"
                onClick={cancel}
                fullWidth={platform === 'mobile'}
            />
            <Button
                onClick={editSection}
                label="Сохранить"
                disabled={loading}
                color="green"
                fullWidth={platform === 'mobile'}
            />
        </>
    );
};

export default hot(sectionFormPage(EditSectionPage, 'Редактировать раздел', true));
