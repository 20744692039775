import { Node, mergeAttributes } from '@tiptap/core';
import { NodeSelection } from '@tiptap/pm/state';

import { Column } from './Column';
import { ColumnSelection } from './ColumnSelection';
import { buildColumn, buildNColumns, buildColumnBlock, findParentNodeClosestToPos, buildParagraph } from './utils';
import { findParentNodeOfType } from 'prosemirror-utils';

export const ColumnBlock = Node.create({
    name: 'columnBlock',
    group: 'block',
    content: 'column{2,}',
    selectable: true,
    defining: true,
    isolating: true,
    draggable: true,

    allowGapCursor: true,

    addOptions() {
        return {
            nestedColumns: false,
            columnType: Column,
        };
    },

    addAttributes() {
        return {
            'data-type': {
                default: null,
                parseHTML: el => el.getAttribute('data-type'),
                renderHTML: attrs => ({ 'data-type': attrs['data-type'] }),
            },
            cols: {
                default: null,
                parseHTML: el => el.getAttribute('data-cols'),
                renderHTML: attrs => {
                    return {
                        'data-cols': attrs.cols || 3,
                    };
                },
            },
            template: {
                default: null,
                parseHTML: el => el.getAttribute('data-template'),
                renderHTML: attrs => {
                    return {
                        'data-template': attrs.template || null,
                    };
                },
            },
            visible: {
                default: 0,
                parseHTML: el => el.getAttribute('data-visible'),
                renderHTML: attrs => {
                    return {
                        'data-visible': attrs.visible,
                    };
                },
            },
        };
    },

    parseHTML() {
        return [{ tag: 'div[data-type="columns"]' }];
    },

    renderHTML({ HTMLAttributes }) {
        const attrs = mergeAttributes(HTMLAttributes, { 'data-type': 'columns', class: 'columnBlock' });
        return ['div', attrs, 0];
    },

    addCommands() {
        const unsetColumns =
            () =>
            ({ tr, dispatch }) => {
                try {
                    if (!dispatch) {
                        return;
                    }

                    // find the first ancestor
                    const pos = tr.selection.$from;
                    const where = ({ node }) => {
                        if (!this.options.nestedColumns && node.type === this.type) {
                            return true;
                        }
                        return node.type === this.type;
                    };
                    const firstAncestor = findParentNodeClosestToPos(pos, where);
                    if (firstAncestor === undefined) {
                        return;
                    }

                    // find the content inside of all the columns
                    let nodes = [];
                    firstAncestor.node.descendants((node, _, parent) => {
                        if (parent?.type.name === Column.name) {
                            nodes.push(node);
                        }
                    });
                    nodes = nodes.reverse().filter(node => node.content.size > 0);

                    // resolve the position of the first ancestor
                    const resolvedPos = tr.doc.resolve(firstAncestor.pos);
                    const sel = new NodeSelection(resolvedPos);

                    // insert the content inside of all the columns and remove the column layout
                    tr = tr.setSelection(sel);
                    tr = tr.deleteSelection();
                    return dispatch(tr);
                } catch (error) {
                    console.error(error);
                }
            };

        const setColumns =
            ({ cols, keepContent }) =>
            ({ tr, dispatch, chain }) => {
                try {
                    const { doc, selection } = tr;
                    if (!dispatch) {
                        return;
                    }

                    const sel = new ColumnSelection(selection);
                    sel.expandSelection(doc);

                    const { openStart, openEnd } = sel.content();
                    if (openStart !== openEnd) {
                        console.warn('failed depth check');
                        return;
                    }

                    let columnBlock;
                    if (keepContent) {
                        const content = sel.content().toJSON();
                        const firstColumn = buildColumn(content);
                        const otherColumns = buildNColumns(cols - 1);
                        columnBlock = buildColumnBlock({
                            content: [firstColumn, ...otherColumns],
                        });
                    } else {
                        const columns = buildNColumns(cols);
                        columnBlock = buildColumnBlock({ content: columns });
                    }
                    const newNode = doc.type.schema.nodeFromJSON(columnBlock);
                    if (newNode === null) {
                        return;
                    }

                    const parent = sel.$anchor.parent.type;
                    const canAcceptColumnBlockChild = par => {
                        if (!par.contentMatch.matchType(this.type)) {
                            return false;
                        }

                        if (!this.options.nestedColumns && par.name === Column.name) {
                            return false;
                        }

                        return true;
                    };
                    if (!canAcceptColumnBlockChild(parent)) {
                        console.warn('content not allowed');
                        return;
                    }

                    chain()
                        .focus()
                        .insertContent(columnBlock)
                        .focus(selection.$head.pos + 1)
                        .run();
                } catch (error) {
                    console.error(error);
                }
            };

        const changeColumns =
            ({ cols, template }) =>
            ({ tr, dispatch, editor }) => {
                try {
                    if (!dispatch) {
                        return;
                    }

                    // find the first ancestor
                    const pos = tr.selection.$from;
                    const where = ({ node }) => {
                        if (!this.options.nestedColumns && node.type === this.type) {
                            return true;
                        }
                        return node.type === this.type;
                    };

                    const firstAncestor = findParentNodeClosestToPos(pos, where);
                    if (firstAncestor === undefined) {
                        return;
                    }

                    const start = firstAncestor.start;

                    // find the content inside of all the columns
                    let nodes = [];
                    firstAncestor.node.descendants((node, pos) => {
                        if (node?.type.name === Column.name) {
                            nodes.push({ node, pos });
                        }
                    });

                    // Удалить последнюю ноду
                    if (nodes.length > cols) {
                        nodes.forEach((n, idx) => {
                            if (idx + 1 > cols) {
                                const linkStart = start + n.pos;
                                const linkEnd = start + n.pos + n.node.nodeSize;

                                tr = tr.delete(linkStart, linkEnd);
                            }
                        });
                    }

                    // Добавить ноду в конец
                    if (nodes.length < cols) {
                        for (let i = 0; i < cols - nodes.length; i++) {
                            const lastNode = nodes[nodes.length - 1];
                            const newNode = editor.state.doc.type.schema.nodeFromJSON(buildColumn({ content: [buildParagraph({})] }));

                            tr = tr.insert(start + lastNode.pos + lastNode.node.nodeSize, newNode);
                        }
                    }

                    tr = tr.setNodeMarkup(firstAncestor.pos, null, { cols, template });
                    return dispatch?.(tr);
                } catch (error) {
                    console.error(error);
                }
            };

        const toggleColumnsVisible =
            () =>
            ({ tr, state, dispatch }) => {
                const columnBlock = findParentNodeOfType(state.schema.nodes.columnBlock)(tr.selection);

                if (!columnBlock) {
                    return;
                }
                const status = Number(columnBlock.node.attrs.visible) === 1 ? 0 : 1;
                dispatch(tr.setNodeMarkup(columnBlock.pos, null, { ...columnBlock.node.attrs, visible: status }));
            };

        return {
            unsetColumns,
            setColumns,
            changeColumns,
            toggleColumnsVisible,
        };
    },

    addKeyboardShortcuts() {
        const onDelete = ({ editor }) => {
            const sel = editor.state.selection;
            const column = findParentNodeOfType(editor.state.schema.nodes.column)(editor.state.selection);

            if (sel.$from.node(-1)?.type.name === 'column' && sel.$from.pos === sel.$to.pos && sel.$from.pos === column.start + 1) {
                return true;
            }
            if (sel.$from.node(-1)?.type.name === 'blockquote' && !sel.$from.nodeBefore && !sel.$from.nodeAfter) {
                return editor.chain().toggleNode('blockquote', 'paragraph').run();
            }
            return false;
        };
        return {
            Backspace: onDelete,
            Delete: onDelete,
        };
    },
});
