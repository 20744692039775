import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMessage } from 'lib/hooks/useMessage';
import * as validators from 'lib/util/validators.util';
import { NewsFormModel } from 'model/news/news-form-model';
import { selectParent } from 'slice/treeSlice';
import api from 'api';
import decodeHtmlEntities from 'lib/helpers/decodeHtmlEntities';

export function useNewsFormModel(id, newsType = 'news') {
    const parent = useSelector(selectParent);
    const { addError } = useMessage();

    const [loading, setLoading] = useState(false);
    const [permissionsOk, setPermissionsOk] = useState(true);

    const [editor, setEditor] = useState(null);
    const [news, setNews] = useState(new NewsFormModel());

    useEffect(() => {
        if (news.getVisualPath().length === 0 && parent.visualPath && !id) {
            news.meta.visualPath = [parent?.visualPath?.[0] ?? ''];
            news.parentProjectId = parent.parentProjectId;

            news.commit();
        }
    }, [news, parent, id]);

    useEffect(() => {
        async function getNews() {
            setLoading(true);

            if (id) {
                let logo, data;

                try {
                    if (newsType === 'news') {
                        data = await api.news.getNewsById(id);
                    } else if (newsType === 'news-draft') {
                        data = await api.news.getNewsDraftById(id);
                    } else if (newsType === 'delayed-news') {
                        data = await api.news.getDelayedNewsById(id);
                    }
                } catch (e) {
                    if (e.errorData.title === 'error.news.has-no-permission.read') {
                        setPermissionsOk(false);
                    } else {
                        throw e;
                    }
                }

                try {
                  if (data.logoUuid) {
                    logo = await api.upload.getImage(data.logoUuid, true, 0)();

                    logo = logo ? 'data:image/png;base64,' + Buffer.from(logo, 'binary').toString('base64') : '';
                }
                } catch (error) {
                  console.log(error);
                }

                setNews(new NewsFormModel({ ...data, logo, publishedType: newsType === 'news-draft' ? 'draft' : newsType === 'delayed-news' ? 'delayed' : 'news' }));
            } else {
              try {
                  const initRes = await api.news.initNews();

                  news.initId = initRes?.initializeId;
                  news.commit();
              } catch (e) {
                  console.log(e);
                  addError('Сервис недоступен. Пожалуйста попробуйте позже.');
              }
            }

            setLoading(false);
        }

        getNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addError, id, newsType]);

    function commit() {
        const copy = this.copy();
        copy.commit = commit;

        setNews(copy);
    }

    news.commit = commit;

    function validateFields(fields) {
        if (!fields || fields.length === 0) {
            fields = ['title', 'text', 'to', 'location'];
        }

        let errors = {};

        if (fields.includes('title')) {
            errors['title'] = validators.textValidator(news.title);
        }

        if (fields.includes('text')) {
            errors['text'] = validators.htmlValidator(news.text);
        }

        if (fields.includes('to')) {
            errors['to'] = news.originalExpireTime > news.expireTime ? 'Нельзя выбрать дату в прошлом' : null;
        }

        if (fields.includes('location')) {
            errors['location'] = !news.parentProjectId && !news.global ? 'Необходимо выбрать расположение для новости' : null;
        }

        news.errors = {
            ...news.errors,
            ...errors
        };
        news.commit();
        return Object.values(errors).every(v => v === null);
    }

    news.validateFields = validateFields;

    function scrollToError() {
        const { title, text } = news.errors;

        if (title) {
            document.getElementById('newsFormTitleInput').scrollIntoView(false);
        } else if (text) {
            document.querySelector('[id^="cke_editor"], [id^="editor"]')?.scrollIntoView(false);
        }
    }

    news.scrollToError = scrollToError;

    function validateExternalText(text) {
        const result = validators.htmlValidator(text);
        news.errors = {
            ...news.errors,
            text: result
        }
        news.commit();
        return !!result;
    }

    news.validateExternalText = validateExternalText;

    function clearNews() {
        const _new = new NewsFormModel();
        _new.commit = commit;

        setNews(_new);
    }

    function setEditorInstance(editor) {
      setEditor(editor)
      news.editor = editor;
    }

    news.setEditorInstance = setEditorInstance;

    function submit() {
        // getData() - CKEditor, getHTML() - TiptapEditor
        const data = decodeHtmlEntities(editor?.getData?.() || editor?.getHTML?.()).replaceAll('open=""', '');

        news.text = data;
        news.title = news.title?.trim()?.replace(/[\s]+/ig, ' ');

        news.commit();
        news.validateExternalText(data);
    }

    news.submit = submit;

    return { editor, news, setNews, clearNews, loading, setLoading, permissionsOk };
}
