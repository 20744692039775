import React from 'react';
import cn from 'classnames';
import cs from './divider.module.scss';

const Divider = ({ className = '', style = {} }) => {
    return (
        <div className={cn(cs.divider, className)} style={style}/>
    );
};

export default Divider;
