import React from 'react';

export const RadioGroup = ({
    id = '',
    className = '',
    value = '',
    onChange = () => {},
    children
}) => {
    return (
        <div id={id} className={className}>
            {React.Children.map(children, radio => {
                if (React.isValidElement(radio)) {
                    return React.cloneElement(radio, {
                        checked: value === radio.props.value,
                        onChange: () => onChange(radio.props.value)
                    });
                }

                return radio;
            })}
        </div>
    )
}
