import React from 'react';
import { useCombinedRefs } from 'lib/hooks';
import { idFromString } from 'lib/helpers';
import cx from './switch.module.scss';
import classNames from 'classnames';

const Switch = ({
    id = null,
    label,
    checked,
    onClick = () => {},
    forwardedRef,
    className = '',
    ...attrs
}) => {
    const key = id || idFromString(attrs.placeholder);
    const innerRef = useCombinedRefs(forwardedRef);

    return (
        <div className={classNames(cx.switch, className)}>
            <input id={key} ref={innerRef} type="checkbox" className={cx.input} checked={checked}
                   onChange={e => onClick(e)}/>
            <label className={cx.label} htmlFor={key} data-testid={key}>
                <span className={cx.toggler}/>
            </label>
            {label && <span className={cx.description}>{label}</span>}
        </div>
    );
};

export default React.forwardRef((props, ref) => <Switch {...props} forwardedRef={ref}/>);
