import { Mark } from '@tiptap/core';

export const Font = Mark.create({
    name: 'font',

    addAttributes() {
        return {
            color: {
                default: null,
                parseHTML: element => element.getAttribute('color'),
                renderHTML: attributes => {
                    if (!attributes.color) {
                        return {};
                    }

                    return {
                        style: `color: ${attributes.color}`
                    };
                },
            },
            face: {
                default: null,
                parseHTML: element => element.getAttribute('face'),
                renderHTML: attributes => {
                    if (!attributes.face) {
                        return {};
                    }

                    return {
                        style: `font-family: ${attributes.face}`
                    };
                },
            },
            style: {
                default: null,
                parseHTML: element => element.getAttribute('style'),
                renderHTML: attributes => {
                    if (!attributes.style) {
                        return {};
                    }

                    return {
                        style: attributes.style
                    };
                },
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'font',
                getAttrs: element => ({
                    color: element.getAttribute('color'),
                    face: element.getAttribute('face'),
                    style: element.getAttribute('style'),
                }),
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        const { style, ...otherAttributes } = HTMLAttributes;

        return [
            'font',
            {
                ...otherAttributes,
                style: [
                    style || '',
                    HTMLAttributes.color ? `color: ${HTMLAttributes.color};` : '',
                    HTMLAttributes.face ? `font-family: ${HTMLAttributes.face};` : '',
                ].filter(Boolean).join(' '),
            },
            0,
        ];
    },
});
