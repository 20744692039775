import HtmlDiff from 'htmldiff-js';

const excludeElements = ['THEAD', 'TBODY', 'TR', 'TH', 'TD', 'SECTION'];
const ignoreElements = ['A'];
const ignoreInnerChangesClassNames = ['code-block-with-line-numbers'];

const getDiffElement = (element, type) => {
    if (excludeElements.includes(element.tagName)) {
        const node = element.cloneNode(true);

        if (node.tagName === 'TD' || node.tagName === 'TH') {
            node.innerHTML = `<${type}>${node.innerHTML}</${type}>`
        } else {
            node.querySelectorAll('td, th').forEach(element => element.innerHTML = `<${type}>${element.innerHTML}</${type}>`);
        }

        return node;
    } else {
        const diffElement = document.createElement(type);
        diffElement.append(element);
        return diffElement;
    }
};
const getMaxLength = (arr1, arr2) => {
    return arr1.length > arr2.length ? arr1.length : arr2.length;
};

const isElementExist = (arr, element, startIndex) => {
    return arr.slice(startIndex).filter(p => p?.outerHTML === element?.outerHTML).length !== 0;
};
const isTextNode = (element) => {
    if (element.childNodes.length === 1 && element.childNodes[0].nodeType !== Node.ELEMENT_NODE) {
        return true;
    }

    if (element.tagName === 'P') {
        return true;
    }

    return false;
};

const getTextNodeDiff = (originalHtml, revisedHtml, byWords = true) => {
    const wordsOriginal = originalHtml.match(/\S+|\s+/g) || [];
    const wordsRevised = revisedHtml.match(/\S+|\s+/g) || [];

    const diff = HtmlDiff.execute(byWords ? wordsOriginal : originalHtml, byWords ? wordsRevised : revisedHtml);

    const parser = new DOMParser();
    const dom = parser.parseFromString(diff, 'text/html');

    dom.querySelectorAll('ins').forEach(ins => {
        if (ins.className !== 'diffmod') {
            return;
        }

        dom.querySelectorAll('del').forEach(del => {
            if (ins.textContent !== del.textContent) {
                return;
            }

            del.remove();
            ins.outerHTML = ins.innerHTML;
        });
    });

    return dom.body.innerHTML;
};

const adaptElements = (originalElements, revisedElements) => {
    let length = getMaxLength(originalElements, revisedElements);

    let oe = [...originalElements];
    let re = [...revisedElements];

    for (let i = 0; i < length; i++) {
        if (!oe[i] || !re[i] || oe[i].outerHTML === re[i].outerHTML || oe[i].innerHTML === re[i].innerHTML) {
            continue;
        }

        if (excludeElements.includes(re[i].tagName)) {
            continue;
        }

        if (!re[i].textContent) {
            oe = [...oe.slice(0, i), null, ...oe.slice(i)];
            length = getMaxLength(oe, re);

            continue;
        }

        if (!oe[i].textContent) {
            re = [...re.slice(0, i), null, ...re.slice(i)];
            length = getMaxLength(oe, re);

            continue;
        }

        if (isElementExist(oe, re[i], i)) {
            re = [...re.slice(0, i), null, ...re.slice(i)];
            length = getMaxLength(oe, re);

            continue;
        }

        if (isElementExist(re, oe[i], i)) {
            oe = [...oe.slice(0, i), null, ...oe.slice(i)];
            length = getMaxLength(oe, re);

            continue;
        }
    }

    return { oe, re };
};
const getElementsDiff = (originalElements, revisedElements, elementMaxDiffsShowCount) => {
    const result = [];
    let length = getMaxLength(originalElements, revisedElements);

    if (length === 0) {
        return result;
    }

    const { oe, re } = adaptElements(originalElements, revisedElements);
    length = getMaxLength(oe, re);

    for (let i = 0; i < length; i++) {
        if (!oe[i]
            && re[i].childNodes.length === 1
            && ignoreElements.includes(re[i].childNodes[0].tagName)
            && !re[i].childNodes[0].textContent
        ) {
            result.push(re[i]);
            continue;
        }

        if (!re[i]
            && oe[i].childNodes.length === 1
            && ignoreElements.includes(oe[i].childNodes[0].tagName)
            && !oe[i].childNodes[0].textContent
        ) {
            continue;
        }

        if (!oe[i]) {
            result.push(getDiffElement(re[i], 'ins'));
            continue;
        }

        if (!re[i]) {
            result.push(getDiffElement(oe[i], 'del'));
            continue;
        }

        if (ignoreInnerChangesClassNames.includes(oe[i].className)
            && ignoreInnerChangesClassNames.includes(re[i].className)
        ) {
            result.push(re[i]);
            continue;
        }

        if (oe[i].outerHTML === re[i].outerHTML
            || oe[i].innerHTML === re[i].innerHTML
            || (isTextNode(oe[i]) && oe[i].textContent === re[i].textContent)) {
            result.push(re[i]);
            continue;
        }

        if (oe[i].tagName !== re[i].tagName) {
            if (excludeElements.includes(re[i].tagName)) {
                result.push(re[i]);
            } else {
                result.push(getDiffElement(oe[i], 'del'));
                result.push(getDiffElement(re[i], 'ins'));
            }

            continue;
        }

        if (isTextNode(oe[i])) {
            const node = re[i].cloneNode(true);
            const byWords = node.querySelectorAll(ignoreElements.map(p => p.toLowerCase()).join(',')).length === 0;

            node.innerHTML = getTextNodeDiff(oe[i].innerHTML, re[i].innerHTML, byWords);

            if (node.querySelectorAll('ins, del').length > elementMaxDiffsShowCount) {
                result.push(getDiffElement(oe[i], 'del'));
                result.push(getDiffElement(re[i], 'ins'));
            } else {
                result.push(node);
            }

            continue;
        }

        const diffElement = (oe[i].childNodes.length > re[i].childNodes.length ? oe[i] : re[i]).cloneNode(false);
        const diffArr = getElementsDiff(
            Array.from(oe[i].childNodes),
            Array.from(re[i].childNodes)
        );

        diffArr.forEach(p => diffElement.append(p));
        const multiplier = Array.from(diffElement.querySelectorAll('*'))
            .filter(p => Array.from(p.childNodes).filter(p1 => p1.nodeType === Node.TEXT_NODE).length !== 0).length;

        if (diffElement.querySelectorAll('ins, del').length > elementMaxDiffsShowCount * multiplier) {
            result.push(getDiffElement(oe[i], 'del'));
            result.push(getDiffElement(re[i], 'ins'));
        } else {
            result.push(diffElement);
        }
    }

    return result;
};

export const getStyles = () => {
    return `
        <style>
            ins, del {
                display: block;
                padding: 2px 8px;
                border-radius: 3px;
                text-decoration: none;
            }
            ins {
                background: rgba(80, 182, 120, 0.35);
            }
            del {
                background: rgba(255, 105, 115, 0.35);
                text-decoration: line-through;
            }
            .diffmod, .diffins, .diffdel {
                display: inline-block;
            }
        </style>
    `;
};
export const getDiffs = (original, revised, elementMaxDiffsShowCount = 4) => {
    const parser = new DOMParser();

    const originDom = parser.parseFromString(original, 'text/html');
    const revisedDom = parser.parseFromString(revised, 'text/html');

    let originalElements = Array.from(originDom.body.childNodes);
    let revisedElements = Array.from(revisedDom.body.childNodes);

    const diffArr = getElementsDiff(originalElements, revisedElements, elementMaxDiffsShowCount);
    const body = document.createElement('body');

    diffArr.forEach(p => body.append(p));
    return getStyles() + body.outerHTML;
};
