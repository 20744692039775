import { mergeAttributes } from '@tiptap/core';
import Details from '@tiptap-pro/extension-details';
import { NodeSelection, Plugin } from '@tiptap/pm/state';
import { keydownHandler } from '@tiptap/pm/keymap';
import { GapCursor } from '@tiptap/pm/gapcursor';
import { findParentNodeOfType } from 'prosemirror-utils';

const ExtensionCustomDetails = Details.extend({
    addNodeView() {
        return ({ HTMLAttributes, editor }) => {
            const toggleDetailsContent = () => {
                container.classList.toggle(this.options.openClassName);

                const event = new Event('toggleDetailsContent');
                const element = content.querySelector(':scope > div[data-type="detailsContent"]');
                const details = findParentNodeOfType(editor.state.schema.nodes.details)(editor.state.selection);

                if (details && !element.hidden) {
                    editor.state.tr.setSelection(NodeSelection.create(editor.state.doc, details.pos));
                    editor.view.dispatch(editor.state.tr);
                }

                if (element) {
                    element.dispatchEvent(event);
                    
                }
            };

            const container = document.createElement('div');
            const attributes = mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
                'data-type': this.name,
            });

            Object.entries(attributes).forEach(([t, e]) => container.setAttribute(t, e));
            const button = document.createElement('button');

            button.type = 'button';
            button.addEventListener('click', toggleDetailsContent);

            container.append(button);

            const content = document.createElement('div');
            container.append(content);

            return {
                dom: container,
                contentDOM: content,
                ignoreMutation: t => 'selection' !== t.type && (!container.contains(t.target) || container === t.target),
                update: t => t.type === this.type,
            };
        };
    },

    addKeyboardShortcuts() {
        const onDelete = ({ editor }) =>
            editor.state.selection instanceof NodeSelection && editor.state.selection.node.type.name === 'details';

        return {
            Backspace: onDelete,
            Delete: onDelete,
        };
    },

    addProseMirrorPlugins() {
        return [
            new Plugin({
                props: {
                    handleKeyDown,
                },
            }),
        ];
    },
});

const handleKeyDown = keydownHandler({
    ArrowDown: arrowDown(),
});

function arrowDown() {
    return (state, dispatch, view) => {
        const details = findParentNodeOfType(state.schema.nodes.details)(state.selection);

        if (!details) return false;

        const detailsSummary = state.selection.$from.node();
        const detailsClass = view.nodeDOM(details.pos).classList;

        if (detailsSummary.type.name !== 'detailsSummary' || detailsClass.contains('is-open')) return false;

        const next = details.pos + details.node.nodeSize - 1;

        return dispatch(state.tr.setSelection(new GapCursor(state.doc.resolve(next))));
    };
}

export default ExtensionCustomDetails;
