import React, { useEffect, useState } from 'react';
import { NodeViewWrapper } from '@tiptap/react';
import { getHeadings } from './utils';

export const TOCView = ({ node, editor, ...props }) => {
    const [headings, setHeading] = useState([]);

    const onNodeSelected = () => {
        try {
            editor.commands.setNodeSelection(props.getPos() + 1);
        } catch (e) {}
    };

    const onClick = el => {
        const editorContent = document.querySelector('.editor-content');
        const anchor = document.querySelector(`[data-uniq-id="${el.attrs['data-uniqid']}"]`);

        let parentElement = anchor.parentElement;

        while (true) {
            if (!parentElement || parentElement.id === 'editor-content') {
                break;
            }

            if (parentElement.className.indexOf('details') !== -1 && parentElement.className.indexOf('is-open') === -1) {
                parentElement.firstElementChild.addEventListener('click', (e) => e.preventDefault());
                parentElement.firstElementChild.click();
            }

            parentElement = parentElement.parentElement;
        }

        const editorContentRect = editorContent.getBoundingClientRect();
        const anchorRect = anchor.getBoundingClientRect();

        editorContent.scrollTo({
            top: anchorRect.top - editorContentRect.top + editorContent.scrollTop,
            behavior: 'smooth',
        });
    };

    const handleUpdate = () => {
        setHeading(getHeadings(editor));

        const state = editor.view.state;
        const $from = state.selection.$from;

        const currentNode = $from.parent;

        if (currentNode.type.name === 'tableOfContents') {
            editor.commands.focus($from.start() - 3 || 'start');
        }
    };

    useEffect(() => {
        handleUpdate();
        editor.on('update', handleUpdate);

        return () => editor.off('update', handleUpdate);
        // eslint-disable-next-line
    }, []);

    return (
        <NodeViewWrapper>
            <div className="table-of-contents" data-type="toc" contentEditable={false} {...node.attrs} onClick={onNodeSelected}>
                <ul data-type="toc-list">
                    {headings.map(el => {
                        const text = el.content[0]?.content[0]?.text;
                        let style;

                        if (el.attrs['data-mark'] === 'italic') {
                            style = { 'fontStyle': 'italic' };
                        }

                        if (el.attrs['data-mark'] === 'bold') {
                            style = { 'fontWeight': '600' };
                        }

                        if (el.attrs['data-mark'] === 'underline') {
                            style = { 'textDecoration': 'underline' };
                        }

                        return (
                            <li
                                key={el.attrs['data-uniqid']}
                                className={el.attrs.class}
                                style={style}
                                data-type="toc-item"
                                data-level={el.attrs['data-level']}
                                data-uniqid={el.attrs['data-uniqid']}
                                data-mark={el.attrs['data-mark']}
                                onClick={() => onClick(el)}
                            >
                                {text}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </NodeViewWrapper>
    );
};
