import { Role } from 'model/role';

export class UserFormModel {
    id = null;
    systemRoleId = Role.defaultRole.id;
    login = '';
    password = undefined;
    firstName = '';
    lastName = '';
    middleName = '';
    email = '';
    activated = false;
    createdBy = null;
    createdDate = null;
    lastModifiedDate = null;
    birthDate = null;
    employmentDate = null;
    phone = '';
    office = null;
    position = null;
    avatar = null;
    avatarUuid = null;
    smallAvatarUuid = null;
    permittedActions = [];
    status = '';
    supervisorId = null;
    enabledNotificationTypes = {
        'PUSH': false,
        'EMAIL': false,
        'WEBSOCKET': false
    };
    enabledNotificationActionTypes = {
        'ARTICLE': { 'READ': false, 'CREATE': false, 'EDIT': false, 'DELETE': false, 'ARCHIVE': false },
        'NEWS': { 'READ': false, 'CREATE': false, 'EDIT': false, 'DELETE': false, 'ARCHIVE': false },
        'FILE': { 'READ': false, 'CREATE': false, 'EDIT': false, 'DELETE': false },
        'USER': { 'READ': false, 'CREATE': false, 'EDIT': false, 'DELETE': false },
        'PROJECT': { 'READ': false, 'CREATE': false, 'EDIT': false, 'DELETE': false, 'ARCHIVE': false },
        'SECTION': { 'READ': false, 'CREATE': false, 'EDIT': false, 'DELETE': false },
        'SETTING': { 'READ': false, 'CREATE': false, 'EDIT': false, 'DELETE': false },
        'CHAT': { 'READ': false, 'CREATE': false, 'EDIT': false, 'DELETE': false },
        'ARCHIVE': { 'READ': false, 'CREATE': false, 'EDIT': false, 'DELETE': false },
        'TRAINING': { 'READ': false, 'CREATE': false, 'EDIT': false, 'DELETE': false },
        'SCRIPT': { 'READ': false, 'CREATE': false, 'EDIT': false, 'DELETE': false, 'ARCHIVE': false }
    };
    hasSystemPermissions = false;
    roles = [];
    rolesWithGroups = [];
    rolesChanged = false;
    avatarChanged = false;
    errors = {
        login: null,
        loginExists: false,
        firstName: null,
        middleName: null,
        lastName: null,
        password: null,
        email: null,
        birthDate: null,
        employmentDate: null
    };
    userAndGroupRoles = [];
    groupList = [];
    permissions = [];

    constructor(user) {
        if (user) {
            this.id = user.id;
            this.login = user.login;
            this.password = user.password ? user.password : '';
            this.firstName = user.firstName;
            this.lastName = user.lastName;
            this.middleName = user.middleName ? user.middleName : '';
            this.email = user.email;
            this.activated = user.activated;
            this.createdBy = user.createdBy;
            this.createdDate = user.createdDate;
            this.lastModifiedDate = user.lastModifiedDate;
            this.birthDate = user.birthDate;
            this.employmentDate = user.employmentDate;
            this.phone = user.phone;
            this.office = user.office;
            this.position = user.position;
            this.avatar = user.avatar;
            this.avatarUuid = user.avatarUuid;
            this.smallAvatarUuid = user.smallAvatarUuid;
            this.permittedActions = user.permittedActions;
            this.status = user.status;
            this.supervisorId = user.supervisorId;
            this.hasSystemPermissions = user.hasSystemPermissions;
            this.rolesChanged = !!user.rolesChanged;
            this.avatarChanged = !!user.avatarChanged;
            this.systemRoleId = user.systemRoleId;
            this.userAndGroupRoles = user.userAndGroupRoles;
            this.groupList = user.groupList;
            this.permissions = user.permissions;

            this.errors = {
                ...user.errors
            };

            if (user.userRoles) {
                this._setRoles(user.userRoles, user.userAndGroupRoles);
            } else {
                this.roles = user.roles;
            }

            this._setEnabledNotificationTypes(user.enabledNotificationTypes);
            this._setEnabledNotificationActionTypes(user.enabledNotificationActionTypes);
        }
    }

    _setEnabledNotificationTypes(data) {
        if (!data) {
            return;
        }

        if (!Array.isArray(data)) {
            this.enabledNotificationTypes = data;
            return;
        }

        const result = {
            'PUSH': false,
            'EMAIL': false,
            'WEBSOCKET': false
        };

        for (let i of data) {
            result[i] = true;
        }

        this.enabledNotificationTypes = result;
    }

    _getEnabledNotificationTypes() {
        return Object.keys(this.enabledNotificationTypes)
            .filter((key) => this.enabledNotificationTypes[key] === true);
    }

    _setEnabledNotificationActionTypes(data) {
        if (!data) {
            return;
        }

        if (!data.actionTypes) {
            this.enabledNotificationActionTypes = data;
            return;
        }

        for (let i of data.actionTypes) {
            this.enabledNotificationActionTypes[i.objectType] = {
                'READ': i.actionTypes.includes('READ'),
                'CREATE': i.actionTypes.includes('CREATE'),
                'EDIT': i.actionTypes.includes('EDIT'),
                'DELETE': i.actionTypes.includes('DELETE'),
                'ARCHIVE': i.actionTypes.includes('ARCHIVE')
            };
        }
    }

    _getEnabledNotificationActionTypes() {
        const result = [];
        const data = Object.keys(this.enabledNotificationActionTypes)
            .filter((v) => Object.values(this.enabledNotificationActionTypes[v]).some(v => v === true));

        for (let i of data) {
            result.push({
                objectType: i,
                actionTypes: Object.keys(this.enabledNotificationActionTypes[i])
                    .filter((v) => this.enabledNotificationActionTypes[i][v] === true)
            });
        }

        return { actionTypes: result };
    }

    copy() {
        return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
    }

    _setRoles(userRoles, userGroupRoles) {
        if (!userRoles) {
            return;
        }

        if (!Array.isArray(userRoles)) {
            this.roles = userRoles;
            this.rolesWithGroups = userGroupRoles;
            return;
        }

        let roles = [];
        let rolesWithGroups = [];

        for (const p of userRoles) {
            const project = {
                projectId: p.uuid,
                projectName: p.title,
                roleId: p.role.id,
                roleName: p.role.title,
                logo: p.logoUuid
            };
            roles.push(project);
        }

        for (const p of userGroupRoles) {
            const project = {
                projectId: p.uuid,
                projectName: p.title,
                roleId: p.role.id,
                roleName: p.role.title,
                logo: p.logoUuid
            };
            rolesWithGroups.push(project);
        }

        this.roles = roles;
        this.rolesWithGroups = rolesWithGroups;
    }

    _getRoles() {
        let resourceList = [];

        for (const i of this.roles) {
            resourceList.push({
                resourceId: i.projectId,
                roleId: i.roleId
            });
        }

        return {
            resourceList: resourceList,
            update: this.rolesChanged,
            roleId: this.systemRoleId
        };
    }

    getFormData() {
        const data = {};

        data.id = this.id;
        data.login = this.login;
        data.password = this.password ? this.password : null;
        data.avatarType = this.avatarChanged ? this.avatar ? this.avatar.split(';')[0].split(':')[1] : '' : null;
        data.avatar = this.avatarChanged ? this.avatar ? this.avatar.split(',')[1] : '' : null;
        data.firstName = this.firstName;
        data.middleName = this.middleName ? this.middleName : null;
        data.lastName = this.lastName;
        data.email = this.email ? this.email : null;
        data.birthDate = new Date(this.birthDate).toISOString();
        data.employmentDate = new Date(this.employmentDate).toISOString();
        data.office = this.office;
        data.phone = this.phone;
        data.position = this.position;
        data.supervisorId = this.supervisorId;
        data.enabledNotificationTypes = this._getEnabledNotificationTypes();
        data.enabledNotificationActionTypes = this._getEnabledNotificationActionTypes();
        data.roles = this._getRoles();
        data.hasSystemPermissions = this.hasSystemPermissions;

        return data;
    }
}
