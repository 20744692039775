import React, { useCallback, useEffect, useState } from 'react';
import { setDocumentTitle, useNavigate } from 'shared/router';
import { useMessage } from 'lib/hooks';
import PrivatePage from 'containers/private-page-wrapper';
import Loading from 'uikit/loading';
import ImportUsersEdit from 'app/import-users/import-users-edit';
import ImportUsersXls from 'app/import-users/import-users-xls';
import api from 'api/index';
import cs from './import-users-page.module.scss';

const errorTranslate = {
    'error.project.not-found.name': '• Указанный проект не существует. Выберите проект из списка, либо укажите привязку «Без проекта».',
    'error.role.not-found.name': '• Указанной роли не существует в системе. Выберите роль из списка.',
    'error.user.has-higher-project-role': '• Пользователь существует и имеет в указанном проекте роль с большим набором прав.',
    'error.user.user-already-exists-and-add-role-resource': '• Участник уже существует в  системе. Будет предоставлен доступ к указанному проекту.',
    'error.user.login-already-used': ['• Указанный логин уже зарегистрирован в системе на профиль ', '. Данные аккаунта не совпадают с существующим профилем с данным логином.'],
    'error.user.email-already-used': ['• Указанный email уже зарегистрирован в системе на профиль ', '. Данные аккаунта не совпадают с существующим профилем с данным эл. почты.'],
    'error.user.login-cant-be-changed': [' ', '• Невозможно изменить логин существующего участника. Измените логин на ', ' или выберите другой email.']
}
const backToFrontFieldNames = {
    'FIRST_NAME': 'firstName',
    'MIDDLE_NAME': 'middleName',
    'LAST_NAME': 'lastName',
    'LOGIN': 'login',
    'PASSWORD': 'password',
    'EMAIL': 'email',
    'ROLE_NAME': 'roleTitle',
    'PROJECT_TITLE': 'projectTitle',
}
const fieldNameErrorTranslate = {
    'firstName': 'Имя',
    'lastName': 'Фамилия',
    'middleName': 'Отчество',
    'email': 'Почта',
    'login': 'Логин',
    'password': 'Пароль'
}

const ImportUsersPage = ({ hasAnyAuthorities }) => {
    const navigate = useNavigate();
    const { addSuccess, addError } = useMessage();

    const [stage, setStage] = useState(0);
    const [loading, setLoading] = useState(false);

    const [users, setUsers] = useState([]);
    const [errorsCount, setErrorsCount] = useState(0);

    const _setUsers = useCallback((users) => {
        const newUsers = [];
        let errorsCount = 0;

        for (const u of users) {
            const errors = [];

            for (const e of u.errorMessages) {
                const field = Object.keys(backToFrontFieldNames).includes(e.field) ? backToFrontFieldNames[e.field] : e.field;

                if (errors.includes(`• ${fieldNameErrorTranslate[field]} участника не соответствует требованиям.`)) {
                    continue;
                }

                const message = Object.keys(errorTranslate).includes(e.message) ? errorTranslate[e.message] : `• ${fieldNameErrorTranslate[field]} участника не соответствует требованиям.`;
                errors.push({...e, 'field': field, 'message': message, 'deleted': false});

                if (e.typeName === 'error') {
                    errorsCount++;
                }
            }

            newUsers.push({...u, 'errorMessages': errors});
        }

        setErrorsCount(errorsCount);
        setUsers(newUsers);
    }, [setErrorsCount, setUsers]);

    const onUpdateUser = (index, field, value) => {
        let newUsers = [...users];
        newUsers[index] = {...newUsers[index], [field]: value, 'errorMessages': [...newUsers[index].errorMessages.filter(e => e.field !== field)]};
        setUsers(newUsers);
    }
    const onDeleteUser = (index) => {
        let newUsers = [...users];
        newUsers[index].deleted = true;
        setUsers(newUsers);
    }

    const onSubmit = async () => {
        setLoading(true);
        const response = await api.user.uploadUsers(users.filter(u => !u.deleted));

        if (response.usersWithErrors.length === 0) {
            addSuccess('Все участники успешно добавлены');
            navigate('/users');
        } else {
            addError('Некоторые участники не были добавлены. Исправьте выделенные поля.')
            _setUsers(response.usersWithErrors);
        }

        setLoading(false);
    }
    const onProceed = useCallback((users) => {
        _setUsers(users);
        setStage(1);
        setLoading(false);
    }, [_setUsers, setStage, setLoading]);

    useEffect(() => {
        setDocumentTitle('Импорт участников — KMS Gran');
    }, []);

    return (
        <PrivatePage hasAnyAuthorities={hasAnyAuthorities}>
            {loading && <Loading withOverlay={false} withRelativeOverlay={true}/>}
            <div className={cs.container}>
                <div className={cs.title}>Импорт участников</div>
                {stage === 0 && <ImportUsersXls setLoading={setLoading} onProceed={onProceed}/>}
                {stage === 1 &&
                <ImportUsersEdit setLoading={setLoading} users={users} onUpdateUser={onUpdateUser}
                                 onCancel={() => setStage(0)} onSubmit={onSubmit} onDeleteUser={onDeleteUser}
                                 errorsCount={errorsCount}/>}
            </div>
        </PrivatePage>
    )
}

export default ImportUsersPage;
