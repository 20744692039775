import React, { useCallback, useEffect, useState } from 'react';
import { findParentNodeClosestToPos } from '@tiptap/core';
import { useEditorContext } from '../../context';
import Icon, { Icons } from 'uikit/icon';
import { Menu, MenuItem } from '../DropdownMenu/DropdownMenu';
import { getTableNodeTypes } from '../../extensions/Table/extension-table/utilities/getTableNodeTypes';
import { isHeaderEnabledByType, selectedRect } from '../../extensions/Table/commands';
import { tableHasHead } from '../../extensions/Table/utils';
import { findParentNodeOfType } from 'prosemirror-utils';
import { BubbleExtensions, isBubbleExtensionActive } from '../../utils/isBubbleExtensionActive';
import { isEmpty } from 'lodash';

export const BubbleMenuTableLeftMenu = ({ mode, isActive, currentMenu, setCurrentMenu }) => {
    const editor = useEditorContext();
    const selection = editor?.state.selection;
    const view = editor?.view;

    const [selectedHeadersLock, setSelectedHeadersLock] = useState('NONE');

    // const widthRange = new Array(10).fill(10).map((v, idx) => (idx + 1) * v);
    const [tableWidth, setTableWidth] = useState(null);
    const onTableAlignChange = (e, align) => {
        e.stopPropagation();
        setCurrentMenu(undefined);

        const { $from } = editor.state.selection;
        const tr = editor.state.tr;

        const tablePos = findParentNodeClosestToPos($from, node => node.type.name === 'table');

        tr.setNodeMarkup(tablePos.pos, undefined, {
            ...tablePos.node.attrs,
            'data-align': align,
        });

        editor.view.dispatch(tr);
    };
    const onTableHeadersLock = (e, type) => {
        e.stopPropagation();
        setCurrentMenu(undefined);

        const types = getTableNodeTypes(editor.view.state.schema);
        let rect = selectedRect(editor.view.state);
        let isHeaderRowEnabled = isHeaderEnabledByType('row', rect, types);
        let isHeaderColumnEnabled = isHeaderEnabledByType('column', rect, types);

        editor.commands.updateAttributes('table', { 'data-headers-lock': type });

        switch (type) {
            case 'NONE':
                editor?.chain().focus().toggleTableHeaders({ row: isHeaderRowEnabled, column: isHeaderColumnEnabled }).run();
                break;
            case 'LEFT':
                editor?.chain().focus().toggleTableHeaders({ row: isHeaderRowEnabled, column: !isHeaderColumnEnabled }).run();
                break;
            case 'TOP':
                editor?.chain().focus().toggleTableHeaders({ row: !isHeaderRowEnabled, column: isHeaderColumnEnabled }).run();
                break;
            case 'TOP_LEFT':
                editor?.chain().focus().toggleTableHeaders({ row: !isHeaderRowEnabled, column: !isHeaderColumnEnabled }).run();
                break;
            default:
                return;
        }
    };
    const onTableBorder = (e, border) => {
        e.stopPropagation();
        setCurrentMenu(undefined);
        editor.commands.command(({ tr, dispatch, state }) => {
            const table = findParentNodeOfType(state.schema.nodes.table)(state.selection);
            if (table) {
                tr.setNodeMarkup(table.pos, undefined, {
                    ...table.node.attrs,
                    'data-border': border,
                });
                return dispatch(tr);
            }
        });
    };

    const onTableWidthChange = width => {
        const table = findParentNodeOfType(editor.state.schema.nodes.table)(editor.state.selection);
        setTableWidth(table.node.attrs.width === '100%' ? null : width);

        const { $from } = editor.state.selection;
        const tablePos = findParentNodeClosestToPos($from, node => node.type.name === 'table');

        editor.commands.command(({ tr, dispatch }) => {
            tr.setNodeMarkup(tablePos.pos, undefined, {
                ...tablePos.node.attrs,
                width: table.node.attrs.width === '100%' ? null : width + '%',
            });
            return dispatch(tr);
        });

        editor.commands.command(({ tr, dispatch }) => {
            tablePos.node.descendants((node, pos) => {
                if (node.type.name !== 'tableCell' && node.type.name !== 'tableHeader') {
                    return;
                }

                const cellPos = tablePos.pos + pos + 1;
                const cellDom = editor.view.nodeDOM(cellPos);

                tr.setNodeMarkup(cellPos, null, {
                    ...node.attrs,
                    colwidth: tablePos.node.attrs['data-responsive'] ? null : [cellDom.offsetWidth],
                });
            });

            return dispatch(tr);
        });
    };

    const getItemPosition = useCallback(() => {
        if (!isActive) {
            return {};
        }

        let nearestParent = selection?.$anchor;

        if (selection?.node?.type.name !== 'table') {
            nearestParent = findParentNodeClosestToPos(nearestParent, node => {
                return node.type.name === 'table';
            });
        } else {
            nearestParent = { pos: selection.$from.pos };
        }

        if (!nearestParent) {
            return {};
        }

        const wrapperDomNode = view?.nodeDOM(nearestParent.pos);

        if (!wrapperDomNode) {
            return {};
        }

        const editorBoundingClientRect = document.getElementById('editor-content')?.getBoundingClientRect();
        const boundingClientRect = wrapperDomNode.querySelector('table')?.getBoundingClientRect();

        if (!editorBoundingClientRect || !boundingClientRect) {
            return;
        }

        return {
            position: 'absolute',
            top: boundingClientRect.top - editorBoundingClientRect.top + 24 + 'px',
            left: boundingClientRect.left - editorBoundingClientRect.left - 25 + 'px',
        };
    }, [isActive, selection, view]);

    useEffect(() => {
        if (editor?.view?.state) {
            try {
                const types = getTableNodeTypes(editor.view.state.schema);
                let rect = selectedRect(editor.view.state);
                let isHeaderRowEnabled = tableHasHead(rect.table);
                let isHeaderColumnEnabled = isHeaderEnabledByType('column', rect, types);

                if (rect.table.attrs.width?.includes('%')) {
                    setTableWidth(rect.table.attrs.width);
                } else {
                    setTableWidth(null);
                }
                if (isHeaderColumnEnabled && !isHeaderRowEnabled) {
                    setSelectedHeadersLock('LEFT');
                } else if (isHeaderRowEnabled && !isHeaderColumnEnabled) {
                    setSelectedHeadersLock('TOP');
                } else if (isHeaderColumnEnabled && isHeaderRowEnabled) {
                    setSelectedHeadersLock('TOP_LEFT');
                } else {
                    setSelectedHeadersLock('NONE');
                }
            } catch (_) {
                setSelectedHeadersLock('NONE');
            }
        }
    }, [editor?.view?.state]);

    return (
        <div
            style={{
                display:
                    !isEmpty(getItemPosition()) && isBubbleExtensionActive(editor?.state.selection, BubbleExtensions.TABLE)
                        ? 'flex'
                        : 'none',
                alignItems: 'center',
                ...getItemPosition(),
            }}
        >
            <Menu
                mode={mode}
                name="table-left"
                currentMenu={currentMenu}
                setCurrentMenu={setCurrentMenu}
                menuContentStyles={{ overflow: 'initial' }}
                label={<Icon type={Icons.EditorIconPlus} width={18} height={18} />}
            >
                <Menu
                    menuStyles={{ minWidth: 248 }}
                    label={
                        <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                            <Icon type={Icons.EditorIconTable} width={18} height={18} /> <span>Ширина границ</span>
                        </div>
                    }
                >
                    <MenuItem
                        selected={parseInt(editor?.getAttributes('table')?.['data-border'], 10) === 0}
                        onClick={e => onTableBorder(e, 0)}
                        label="0"
                    ></MenuItem>
                    <MenuItem
                        selected={parseInt(editor?.getAttributes('table')?.['data-border'], 10) === 1}
                        onClick={e => onTableBorder(e, 1)}
                        label="1"
                    ></MenuItem>
                </Menu>
                <Menu
                    menuStyles={{ minWidth: 248 }}
                    label={
                        <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                            <Icon type={Icons.EditorIconBubbleAlignCenter} width={18} height={18} /> <span>Выравнивание таблицы</span>
                        </div>
                    }
                >
                    <MenuItem
                        selected={editor?.getAttributes('table')?.['data-align'] === 'center'}
                        onClick={e => onTableAlignChange(e, 'center')}
                        label={
                            <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                                <Icon type={Icons.EditorIconBubbleAlignCenter} width={18} height={18} /> <span>Выравнивание по центру</span>
                            </div>
                        }
                    />
                    <MenuItem
                        selected={editor?.getAttributes('table')?.['data-align'] === 'left'}
                        onClick={e => onTableAlignChange(e, 'left')}
                        label={
                            <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                                <Icon type={Icons.EditorIconBubbleAlignLeft} width={18} height={18} /> <span>Выравнивание слева</span>
                            </div>
                        }
                    />
                    <MenuItem
                        selected={editor?.getAttributes('table')?.['data-align'] === 'right'}
                        onClick={e => onTableAlignChange(e, 'right')}
                        label={
                            <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                                <Icon type={Icons.EditorIconBubbleAlignRight} width={18} height={18} /> <span>Выравнивание справа</span>
                            </div>
                        }
                    />
                </Menu>
                <Menu
                    menuStyles={{ minWidth: 248 }}
                    label={
                        <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                            <Icon type={Icons.EditorIconLock} width={18} height={18} /> <span>Закрепление</span>
                        </div>
                    }
                >
                    <MenuItem
                        selected={selectedHeadersLock === 'NONE'}
                        onClick={e => onTableHeadersLock(e, 'NONE')}
                        label="Без закрепления"
                    ></MenuItem>
                    <MenuItem
                        selected={selectedHeadersLock === 'TOP'}
                        onClick={e => onTableHeadersLock(e, 'TOP')}
                        label="Верхняя строка"
                    ></MenuItem>
                    <MenuItem
                        selected={selectedHeadersLock === 'LEFT'}
                        onClick={e => onTableHeadersLock(e, 'LEFT')}
                        label="Левая колонка"
                    ></MenuItem>
                    <MenuItem
                        selected={selectedHeadersLock === 'TOP_LEFT'}
                        onClick={e => onTableHeadersLock(e, 'TOP_LEFT')}
                        label="Сверху и слева"
                    ></MenuItem>
                </Menu>
                <Menu
                    menuStyles={{ minWidth: 248 }}
                    label={
                        <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                            <Icon type={Icons.EditorIconLock} width={18} height={18} /> <span>Ширина</span>
                        </div>
                    }
                >
                    {[100].map(width => {
                        return (
                            <MenuItem
                                key={width}
                                selected={tableWidth}
                                onClick={() => onTableWidthChange(width)}
                                label={width + '%'}
                            ></MenuItem>
                        );
                    })}
                </Menu>
            </Menu>
        </div>
    );
};
