import React, { useState } from 'react';
import Modal from 'react-modal';
import Dropzone from 'react-dropzone';
import { useMessage } from 'lib/hooks';
import { desktopStyles } from 'lib/util/modalStyles';
import Loading from 'uikit/loading';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import api from 'api';
import cs from './LicenseUploadModal.module.scss';

const LicenseUploadModal = ({ open, onCloseModal, onUpload }) => {
    const { addError } = useMessage();
    const [uploading, setUploading] = useState(false);

    const handleChange = async (files) => {
        const file = files[0];
        setUploading(true);

        try {
            const uploadedLicense = await api.license.uploadNewLicense(file);
            onUpload?.(uploadedLicense);
        } catch (e) {
            if (e.errorData.message.indexOf('expired') !== -1) {
                addError('Данная лицензия уже истекла.');
            } else if (e.errorData.message.indexOf('exists') !== -1) {
                addError('Данная лицензия уже загружена.');
            } else {
                addError('Неверный формат файла.');
            }

            onCloseModal();
        } finally {
            setUploading(false);
        }
    };

    return (
        <Modal isOpen={open} contentLabel="Загрузка лицензии" style={desktopStyles}>
            <div className={cs.modalContent}>
                <div className={cs.title}>
                    <div>Загрузка лицензии</div>
                    <IconButton icon={<Icon width={14} height={14} type={Icons.CROSS}/>} onClick={onCloseModal}/>
                </div>
                {uploading &&
                <div className={cs.uploading}>
                    <Loading withOverlay={false} />
                    <div>Дождитесь окончания обработки файла...</div>
                </div>}
                {!uploading &&
                <Dropzone onDrop={handleChange}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: cs.dropZone })}>
                            <input {...getInputProps()}/>
                            <Icon type={Icons.DOWNLOAD} width={24} height={24} color="blue"/>
                            <p>Перетащите файл в эту область или нажмите для выбора файла с компьютера</p>
                        </div>
                    )}
                </Dropzone>}
            </div>
        </Modal>
    );
};

export default LicenseUploadModal;
