import Tippy from '@tippyjs/react';
import React, { useEffect, useRef, useState } from 'react';
import { components } from 'react-select';
import cx from './option.module.scss';
const Option = props => {
    const textRef = useRef(null);
    const [isTooltip, setIsTooltip] = useState(false);

    const isTextClamped = elm => {
        return elm.scrollWidth > elm.clientWidth + 1;
    };

    useEffect(() => {
        if (props.data.label) {
            setIsTooltip(isTextClamped(textRef.current));
        }
    }, [props.data.label]);

    return (
        <Tippy content={props.data.label} disabled={!isTooltip}>
            <div>
                <components.Option {...props}>
                    <div className={cx.optionWrapper} ref={textRef}>
                        {props.data.label}
                    </div>
                </components.Option>
            </div>
        </Tippy>
    );
};

export default Option;
