import React, { useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { useUpload } from 'components/background-upload/upload-provider';
import PrivatePage from 'containers/private-page-wrapper';
import cx from './add-file-page.module.scss';
import Icon, { Icons } from 'uikit/icon';
import { setDocumentTitle } from '../../../shared/router';

const AddFilePage = ({ hasAnyAuthorities = [] }) => {
    const { upload } = useUpload();

    const onChange = (files) => {
        for (const f of files) {
            upload({ file: f }).start({ inBackground: false })
        }
    };

    useEffect(() => {
        setDocumentTitle('Загрузка файлов — KMS Gran');
    }, []);

    return (
        <PrivatePage hasAnyAuthorities={hasAnyAuthorities}>
            <div className={cx.base}>
                <div className={cx.uploadTitle}>Загрузка файлов</div>
                <Dropzone onDrop={onChange}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: cx.dropZone })}>
                            <input {...getInputProps()} />
                            <div className={cx.mobileView}>
                                <Icon type={Icons.UPLOAD} width={54} height={54} />
                                <p>Нажмите здесь, чтобы начать загрузку файлов с вашего устройства</p>
                            </div>
                            <div className={cx.desktopView}>
                                <p>Перетащите файл в эту область или нажмите для выбора файла с компьютера</p>
                            </div>
                        </div>
                    )}
                </Dropzone>
            </div>
        </PrivatePage>
    );
};

export default AddFilePage;
