import React from 'react';
import Modal from 'react-modal';
import { ModalBody } from 'components/modal/modal-components';
import Icon from 'uikit/icon/icon';
import { Icons } from 'uikit/icon';
import Loading from 'uikit/loading';
import {
    TDevice,
    EEmbedDrawIoAttr,
    EEmbedDrawIoMode,
    useEmbedDrawIoDialogStore,
    isEqualStr,
    downloadSvgFromBase64Image
} from '../../index';
import classNames from 'classnames';
import cn from './styles.module.scss';


interface IProps {
    isOpen: boolean,
    device?: TDevice | undefined;
}

export const EmbedDrawIoDialogModal: React.FC<IProps> = ({ isOpen, device = 'DESKTOP' }) => {

    const menuIconProps = { iconColor: '#1280CE' as 'blue', iconSize: 24 }

    const { EDIT_MODE, VIEWER_MODE } = EEmbedDrawIoMode;

    const {
        mode,
        isDialogLoaded,
        isPreviewDialogOpen,
        currentBase64Image,
        options: op
    } = useEmbedDrawIoDialogStore();

    const [showPreLoader, setShowPreLoader] = React.useState<boolean>(true);

    const setDialogLoaded = useEmbedDrawIoDialogStore(state => state.setDialogLoaded);
    const resetDialogMainState = useEmbedDrawIoDialogStore(state => state.resetDialogMainState);

    const getIFrameSrc = (mode: EEmbedDrawIoMode, device: TDevice): string => {

        const url = {
            viewerMode:
                `${ op.drawIoOriginLink }${ op.drawIoParamsForViewerMode }`,
            editModeDesktop:
                `${ op.drawIoOriginLink }${ op.drawIoParamsForEditMode }${ op.drawIoParamsForDesktop }`,
            editModeMobile:
                `${ op.drawIoOriginLink }${ op.drawIoParamsForEditMode }${ op.drawIoParamsForMobile }`
        }

        switch(mode) {

            case VIEWER_MODE:
                return url.viewerMode;

            case EDIT_MODE:
                if (isEqualStr(device, 'DESKTOP')) {
                    return url.editModeDesktop;
                }
                if (isEqualStr(device, 'TABLET') || isEqualStr(device, 'MOBILE')) {
                    return url.editModeMobile;
                }
                return url.editModeDesktop;

            default:
                return url.editModeDesktop;
        }
    }

    const handleTransitionEnd = (): void => {
        setShowPreLoader(false);
        if (!isDialogLoaded) {
            resetDialogMainState();
            setShowPreLoader(true);
        }
    }

    const handleCloseViewer = (): void => {
        setDialogLoaded(false);
    }

    return (
        // @ts-ignore
        <Modal
            isOpen={ isOpen }
            className={ cn.embedDrawIoDialogModal }
            style={{ overlay: { backgroundColor: 'rgba(0, 0, 0, 0)', zIndex: 99999 } }}
            preventScroll
            >
            <ModalBody className={ cn.embedDrawIoDialogBody }>
                <>
                    {
                        showPreLoader && !isDialogLoaded && (
                            <Loading
                                withOverlay={ false }
                                className={ cn.embedDrawIoDialogPreloader }
                                style={{}}
                            />
                        )
                    }
                    <iframe
                        title={ EEmbedDrawIoAttr.TITLE_IFRAME }
                        src={ getIFrameSrc(mode, device) }
                        sandbox={ op.iframeSandbox }
                        className={classNames(cn.embedDrawIoDialogIframe, {
                            [cn.editMode]: mode === EDIT_MODE, [cn.viewerMode]: mode === VIEWER_MODE,
                            [cn.animIn]: isDialogLoaded, [cn.animOut]: !isDialogLoaded,
                        })}
                        onTransitionEnd={ handleTransitionEnd }
                    />
                    {
                        mode === VIEWER_MODE && (
                            <div className={classNames(cn.embedDrawIoDialogMenu, {
                                    [cn.animIn]: isDialogLoaded, [cn.animOut]: !isDialogLoaded
                                })}
                            >
                                {
                                    isPreviewDialogOpen && (
                                        <span onClick={ () => downloadSvgFromBase64Image(currentBase64Image) }>
                                            <Icon
                                                type={ Icons.EditorIconListDown } color={ menuIconProps.iconColor }
                                                width={ menuIconProps.iconSize } height={ menuIconProps.iconSize }
                                            />
                                        </span>
                                    )
                                }
                                <span onClick={ handleCloseViewer }>
                                    <Icon
                                        type={ Icons.EditorIconClose } color={ menuIconProps.iconColor }
                                        width={ menuIconProps.iconSize } height={ menuIconProps.iconSize }
                                    />
                                </span>
                            </div>
                        )
                    }
                </>
            </ModalBody>
        </Modal>
    )
}
