import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useEventListener } from 'lib/hooks';
import { NotificationTypes } from 'notifications/view/types';
import { useNotifications } from 'notifications/appllication/context';
import { isManualNotification } from 'notifications/utils/isManualNotification';
import ScrollTopBtn from 'components/scroll-top-btn';
import Button from 'uikit/button';
import Loading from 'uikit/loading';
import api from 'api';
import cx from './page.module.scss';

const NotificationsPage = () => {
    const { readNotification, getUnreadNotifications, getAllNotifications, lastNotification } = useNotifications();

    const [isLoading, setIsLoading] = useState(true);
    const [hasUnreadNotifications, setHasUnreadNotifications] = useState(true);

    const [unreadNotificationsPage, setUnreadNotificationsPage] = useState(0);
    const [unreadNotifications, setUnreadNotifications] = useState([]);

    const [readNotificationsPage, setReadNotificationsPage] = useState(0);
    const [readNotifications, setReadNotifications] = useState([]);

    const [scrollTop, setScrollTop] = useState(0);
    const [isLastNotification, setIsLastNotification] = useState(false);

    const onRead = (notification) => {
        setUnreadNotifications(prev => {
            const newNotifications = Object.assign([], prev);
            const notificationIndex = newNotifications.findIndex((item) => item['id'] === notification['id']);

            if (notificationIndex !== -1) {
                newNotifications[notificationIndex]['readByUser'] = true;
            }

            readNotification(notification['id']);
            return newNotifications;
        });
    };
    const onScroll = (e) => {
        const nextNotifications = Object.assign([], unreadNotifications);
        let isChanged = false;

        for (let i = 0; i < unreadNotifications.length; i++) {
            if (isManualNotification(nextNotifications[i])) {
                continue;
            }

            const el = document.getElementById('unread-' + i);

            if (unreadNotifications[i]['readByUser'] || el.getBoundingClientRect().top > document.body.offsetHeight) {
                continue;
            }

            nextNotifications[i].readByUser = true;
            isChanged = true;

            readNotification(nextNotifications[i]?.id);
        }

        if (!isLastNotification && !isLoading && e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 50) {
            if (hasUnreadNotifications) {
                setUnreadNotificationsPage(prev => prev + 1);
            } else {
                setReadNotificationsPage(prev => prev + 1);
            }
        }

        if (isChanged) {
            setUnreadNotifications(nextNotifications);
        }

        setScrollTop(e.target.scrollTop);
    };

    useEventListener('scroll', onScroll, document.getElementById('content'));

    useEffect(() => {
        setIsLoading(true);

        getUnreadNotifications(unreadNotificationsPage).then(response => {
            setIsLoading(false);
            setUnreadNotifications(prev => {
                if (response.content.length < 20) {
                    setHasUnreadNotifications(false);
                }

                return [...prev || [], ...response.content];
            });
        });
    }, [unreadNotificationsPage, getUnreadNotifications]);
    useEffect(() => {
        if (hasUnreadNotifications) {
            return;
        }

        setIsLoading(true);

        getAllNotifications(readNotificationsPage).then(response => {
            setIsLoading(false);

            if (response.content.length === 0) {
                setIsLastNotification(true);
            }

            setReadNotifications(prev => {
                return [...prev || [], ...response.content.filter(p => p.readByUser)];
            });
        });
    }, [readNotificationsPage, getAllNotifications, hasUnreadNotifications]);
    useEffect(() => {
        if (!lastNotification || lastNotification.readByUser) {
            return;
        }

        setUnreadNotifications(prev => {
            if (prev.length === 0 || prev.findIndex(p => p.id === lastNotification.id) !== -1) {
                return prev;
            }

            return [lastNotification, ...prev];
        });
    }, [lastNotification]);

    return (
        <div className={cx.content}>
            {unreadNotifications.length > 0 && (
                <div className={cx.title}>
                    <h1>Непрочитанные</h1>
                </div>
            )}
            {unreadNotifications?.map((item, i) => {
                return (
                    <div
                        id={'unread-' + i}
                        key={i}
                        className={classNames(cx.notificationUnread, item['readByUser'] ? cx.notificationRead : null)}
                    >
                        <div className={cx.notificationContent}>
                            {item['author'] && (
                                <img
                                    src={
                                        item['author']['avatarUuid']
                                            ? api.upload.getImage(item['author']['avatarUuid'], false, 128)
                                            : '/img/avatar.png'
                                    }
                                    alt=""
                                />
                            )}
                            <p>
                                {NotificationTypes[item['objectType'] + '.' + item['eventType']]
                                    ? NotificationTypes[item['objectType'] + '.' + item['eventType']](null, item)
                                    : item['objectType'] + '.' + item['eventType']}
                            </p>
                        </div>
                        {(item['objectType'] === 'ARTICLE_COMMENT' || item['objectType'] === 'NEWS_COMMENT') && (
                            <p className={cx.notificationComment}>{item['data']['text']}</p>
                        )}
                        {isManualNotification(item) && (
                            <Button color="green" disabled={item['readByUser']} onClick={() => onRead(item)}>
                                {item['readByUser'] ? 'Прочитано' : 'Прочитал (-а) уведомление'}
                            </Button>
                        )}
                        <p className={cx.notificationTime}>{new Date(item.timestamp).toLocaleString().slice(0, -3)}</p>
                    </div>
                );
            })}
            {readNotifications.length > 0 && (
                <div className={cx.title}>
                    <h1>Прочитанные</h1>
                </div>
            )}
            {readNotifications.map((item, i) => {
                return (
                    <div key={i} className={cx.notification}>
                        <div className={cx.notificationContent}>
                            {item['author'] && (
                                <img
                                    src={
                                        item['author']['avatarUuid']
                                            ? api.upload.getImage(item['author']['avatarUuid'], false, 128)
                                            : '/img/avatar.png'
                                    }
                                    alt=""
                                />
                            )}
                            <p>
                                {NotificationTypes[item['objectType'] + '.' + item['eventType']]
                                    ? NotificationTypes[item['objectType'] + '.' + item['eventType']](null, item)
                                    : item['objectType'] + '.' + item['eventType']}
                            </p>
                        </div>
                        {(item['objectType'] === 'ARTICLE_COMMENT' || item['objectType'] === 'NEWS_COMMENT') && (
                            <p className={cx.notificationComment}>{item['data']['text']}</p>
                        )}
                        {isManualNotification(item) && (
                            <Button color="green" disabled>
                                Прочитано
                            </Button>
                        )}
                        <p className={cx.notificationTime}>{new Date(item.timestamp).toLocaleString().slice(0, -3)}</p>
                    </div>
                );
            })}
            {isLoading && (
                <div className={cx.loader}>
                    <Loading withOverlay={false} small />
                </div>
            )}
            <ScrollTopBtn isSeen={scrollTop > 20} scrollId="content" />
        </div>
    );
};

export default NotificationsPage;
