import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import { textValidator } from 'lib/util/validators.util';
import BackButton from 'components/back-button';
import Button from 'uikit/button';
import Input from 'uikit/input';
import TextArea from 'uikit/textarea';
import cx from './companies-page-edit-office.module.scss';
import { useGlobalContext, useMessage } from 'lib/hooks';
import Divider from 'uikit/divider';
import api from 'api';
import { normalizeSpaces } from '../../../lib/helpers/normalizeSpaces';
import { setDocumentTitle, useNavigate, useParams } from '../../../shared/router';

const CompaniesPageEditOffice = () => {
    const navigate = useNavigate();
    const { officeId } = useParams();

    const { platform } = useGlobalContext();
    const { addError, addSuccess } = useMessage();

    const [name, setName] = useState('');
    const [building, setBuilding] = useState('');
    const [block, setBlock] = useState('');
    const [description, setDescription] = useState('');
    const [fullAddress, setFullAddress] = useState('');

    const [errors, setErrors] = useState({});

    const fetchOffice = useCallback(async () => {
        if (!officeId) {
            return;
        }

        try {
            const res = await api.office.getOfficeById(officeId);

            setName(res.name);
            setFullAddress(res.address);
            setBuilding(res.building);
            setBlock(res.block);
            setDescription(res.description);
        } catch (error) {}
    }, [officeId]);

    const onSubmit = async () => {
        if (!name) {
            setErrors((errors) => ({ ...errors, name: 'Поле обязательно для заполнения' }));
        }

        if (!fullAddress) {
            setErrors((errors) => ({ ...errors, address: 'Поле обязательно для заполнения' }));
        }

        if (!name || !fullAddress) {
            return;
        }

        try {
            if (!officeId) {
                await api.office.createOffice({
                    officeId,
                    address: fullAddress.trim(),
                    name: normalizeSpaces(name).trim(),
                    building: building.trim(),
                    block: block.trim(),
                    description,
                });
                addSuccess('Офис добавлен');
            } else {
                await api.office.updateOffice({
                    officeId,
                    address: fullAddress.trim(),
                    name: normalizeSpaces(name).trim(),
                    building: building.trim(),
                    block: block.trim(),
                    description,
                });
                addSuccess('Офис изменен');
            }

            goBack();
        } catch (e) {
            addError(!officeId ? 'Ошибка при создании офиса' : 'Ошибка при изменении данных офиса');
        }
    };

    const onOfficeNameChange = (name) => {
        setName(name);
        setErrors((errors) => ({ ...errors, name: textValidator(name) }));
    };

    const onOfficeAddressChange = (address) => {
        setFullAddress(address);
        setErrors((errors) => ({ ...errors, address: textValidator(address) }));
    };

    const goBack = () => {
        navigate('/global-settings/companies');
    };

    useEffect(() => {
        fetchOffice();
    }, [fetchOffice]);
    useEffect(() => {
        setDocumentTitle('Компании и офисы — KMS Gran');
    }, []);

    return (
        <div className={classNames(cx.contentWrapper, { [cx.mobile]: platform === 'mobile' })}>
            <div className={cx.header}>
                <BackButton onClick={goBack} />
                <h1>{officeId ? 'Редактирование офиса' : 'Создание офиса'}</h1>
            </div>
            <div className={cx.formContainer}>
                <div className={cx.formHeader}>
                    <Input
                        className={cx.name}
                        label="Название офиса"
                        value={name}
                        onChange={onOfficeNameChange}
                        error={errors.name}
                        id={'officeFormTitle'}
                        required
                    />
                </div>
                <Divider />
                <div className={cx.formBody}>
                    <h2>Расположение</h2>
                    <div className={cx.formContent}>
                        <div>
                            <Input
                                className={cx.address}
                                value={fullAddress}
                                id="suggest"
                                label="Адрес локации"
                                onChange={onOfficeAddressChange}
                                error={errors.address}
                                required
                                description=""
                            />
                            <div className={cx.additionalInfo}>
                                <Input id={'officeFormBuilding'} label="Строение" value={building} onChange={setBuilding} />
                                <Input id={'officeFormOfficeNumber'} label="Номер офиса" value={block} onChange={setBlock} />
                            </div>
                            <TextArea
                                label="Дополнительная информация"
                                rows="2"
                                description="Например, «вход через главные ворота»"
                                value={description}
                                onChange={setDescription}
                                id={'officeFormAdditionalInformation'}
                            />
                        </div>
                    </div>
                </div>
                <div className={cx.formFooter}>
                    <Button label="Отмена" onClick={goBack} fullWidth={platform === 'mobile'} />
                    <Button label="Подтвердить" color="green" onClick={onSubmit} fullWidth={platform === 'mobile'} />
                </div>
            </div>
        </div>
    );
};

export default CompaniesPageEditOffice;
