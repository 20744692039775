import ApiClient from './api-axios';

export default class ParserApi extends ApiClient {
    convertToHtml = (formData) => {
        return this.upload('/services/parser/api/v1/file-converter/html/task', formData);
    };
    getConvertStatus = (taskId) => {
        return this.get('/services/parser/api/v1/file-converter/html/task/' + taskId);
    };
}
