import React, { useCallback, useEffect, useRef, useState } from 'react';
import cs from 'app/import-users/import-users-edit.module.scss';
import Divider from 'uikit/divider';
import Input from 'uikit/input';
import Button from 'uikit/button';
import Card from 'components/card';
import { Select } from 'uikit/select';
import api from 'api';
import { useMessage } from 'lib/hooks';
import { Link } from 'shared/router';
import Icon, { Icons } from 'uikit/icon';
import Loading from 'uikit/loading';

const ImportUsersEdit = ({ users, onUpdateUser, onDeleteUser, onCancel, onSubmit, errorsCount }) => {
    const { addError } = useMessage();

    const [projects, setProjects] = useState([]);
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);

    const isProjectsLoaded = useRef(false);

    const hasErrors = useCallback(() => {
        for (const u of users.filter(u => !u.deleted)) {
            if (u.errorMessages.filter(e => e.typeName === 'error').length > 0) {
                return true;
            }
        }
        return false;
    }, [users]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const projects = await api.project.getProjects(0, 1000);
                setProjects(projects.content.map(v => {
                    return {
                        label: v.title,
                        value: v.id
                    }
                }));
                const roles = await api.role.getRolesList();
                setRoles(roles.map(v => {
                    return {
                        label: v.title,
                        value: v.id
                    }
                }));
            } catch (e) {
                console.log(e);
                addError('Не удалось загрузить списки проектов и ролей');
            } finally {
                setLoading(false);
                isProjectsLoaded.current = true;
            }
        }

        if (!isProjectsLoaded.current) {
            fetchData();
        }
    }, [addError, setProjects, setRoles]);

    return (
        <Card className={cs.card}>
            <Loading active={loading} withOverlay={false} withRelativeOverlay={true}/>

            <div className={cs.blockText}>Результат импорта: </div>
            <div className={cs.found}>
                <span className={cs.foundName}>Найдено участников: </span>
                <span className={cs.foundValue}>{users.length}</span>
            </div>
            <div className={cs.found}>
                <span className={cs.foundName}>Найдено ошибок: </span>
                <span className={cs.foundValue}>{errorsCount}</span>
            </div>

            {users.map((user, index) => {
                if (user.deleted) {
                    return null;
                }
                return (
                    <div key={index} className={cs.user}>
                        <Divider/>
                        <div className={cs.userTitle}>
                            <h4>{`${user.firstName} ${user.middleName ? user.middleName + ' ' : ''}${user.lastName}`}</h4>
                            <div className={cs.deleteBtn} onClick={() => onDeleteUser(index)}><Icon type={Icons.TRASH} height={14.5} width={14.5}/> Удалить участника</div>
                        </div>
                        <div className={cs.inputsContainer}>
                            <Input type='text'
                                   value={user.firstName}
                                   onChange={str => {
                                       onUpdateUser(index, 'firstName', str);
                                   }}
                                   isError={user.errorMessages.filter(e => e.field === 'firstName' && e.typeName === 'error').length > 0}
                                   placeholder={'Имя'}
                                   label='Имя'/>

                            <Input type='text'
                                   value={user.middleName}
                                   onChange={str => {
                                       onUpdateUser(index, 'middleName', str);
                                   }}
                                   isError={user.errorMessages.filter(e => e.field === 'middleName' && e.typeName === 'error').length > 0}
                                   placeholder={'Отчество'}
                                   label='Отчество'/>

                            <Input type='text'
                                   value={user.lastName}
                                   onChange={str => {
                                       onUpdateUser(index, 'lastName', str);
                                   }}
                                   isError={user.errorMessages.filter(e => e.field === 'lastName' && e.typeName === 'error').length > 0}
                                   placeholder={'Фамилия'}
                                   label='Фамилия'/>

                            <Input type='text'
                                   value={user.login}
                                   onChange={str => {
                                       onUpdateUser(index, 'login', str);
                                   }}
                                   isError={user.errorMessages.filter(e => e.field === 'login' && e.typeName === 'error').length > 0}
                                   placeholder={'Логин'}
                                   label='Логин'/>

                            <Input type='password'
                                   value={user.password}
                                   onChange={str => {
                                       onUpdateUser(index, 'password', str);
                                   }}
                                   isError={user.errorMessages.filter(e => e.field === 'password' && e.typeName === 'error').length > 0}
                                   placeholder={'Пароль'}
                                   label='Пароль'/>

                            <Input type='text'
                                   value={user.email}
                                   onChange={str => {
                                       onUpdateUser(index, 'email', str);
                                   }}
                                   isError={user.errorMessages.filter(e => e.field === 'email' && e.typeName === 'error').length > 0}
                                   placeholder={'Эл. почта'}
                                   label='Почта'/>

                            <Select label={'Проект'} placeholder="Проект"
                                    value={projects.find(v => v.label === user.projectTitle)} options={projects}
                                    onChange={v => {
                                        onUpdateUser(index, 'projectTitle', v.label);
                                    }}
                                    isError={user.errorMessages.filter(e => e.field === 'projectTitle' && e.typeName === 'error').length > 0}
                            />

                            <Select label={'Роль'} placeholder="Роль"
                                    value={roles.find(v => v.label === user.roleTitle)} options={roles}
                                    onChange={(v) => {
                                        onUpdateUser(index, 'roleTitle', v.label);
                                    }}
                                    isError={user.errorMessages.filter(e => e.field === 'roleTitle' && e.typeName === 'error').length > 0}
                            />
                        </div>

                        {user.errorMessages.map((error, i) => {
                            const errorsMessages = user.errorMessages.map(e => e.message);
                            if (errorsMessages.filter(m => m === error.message).length > 1 && errorsMessages.indexOf(error.message) !== i) {
                                return null;
                            }

                            if (error.message.length === 2) {
                                return <div className={cs.error}>
                                    {error.message[0]}
                                    <Link className={cs.existingUserLink} to={`/edit-user/${error.existingUser.login}`}>{`${error.existingUser.firstName} ${error.existingUser.lastName}`}</Link>
                                    {error.message[1]}
                                </div>
                            } else if (error.message.length === 3) {
                                return <div className={cs.error}>
                                    {error.message[1]}
                                    <Link className={cs.existingUserLink} to={`/edit-user/${error.existingUser.login}`}>{error.existingUser.login}</Link>
                                    {error.message[2]}
                                </div>
                            } else {
                                return <div className={error.typeName === 'error' ? cs.error : cs.warning}>{error.message}</div>
                            }
                        })}
                    </div>
                )
            })}

            <div className={cs.controlsContainer}>
                <Button label={'Отмена'} onClick={() => onCancel()}/>
                <Button onClick={onSubmit} label={'Завершить импорт'} color={'green'} disabled={hasErrors()}/>
            </div>
        </Card>
    )
}

export default ImportUsersEdit;
