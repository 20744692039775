import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useMessage } from 'lib/hooks';
import {
    IMAGE_ZOOM_INITIAL,
    IMAGE_ZOOM_LIMIT,
    IMAGE_MESSAGES,
    IImagePreviewModalState,
    checkImageSrc,
    checkImageComplete
} from '../index';


export const useImagePreviewModal = (
    imageZoomInitial: number = IMAGE_ZOOM_INITIAL,
    imageZoomLimit: [number, number] = IMAGE_ZOOM_LIMIT
): IImagePreviewModalState => {

    const { addError } = useMessage();
    const { error } = IMAGE_MESSAGES;

    const imageRef = React.useRef<{ src: string | null | undefined, alt: string }>({ src: undefined, alt: '' });

    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [imageLoading, setImageLoading] = React.useState<boolean>(false);
    const [imageSrc, setImageSrc] = React.useState<string>('');
    const [imageDownload, setImageDownload] = React.useState<boolean>(false);

    const openImageModal = React.useCallback(async (imageTarget: HTMLImageElement) => {

        if (!checkImageComplete(imageTarget)) {
            addError(error.unavailable);
            return;
        }

        setImageLoading(true);
        setIsOpen(true);

        try {
            imageRef.current.src = imageTarget.src;
            imageRef.current.alt = imageTarget.alt;
        }
        catch (err) {
            addError(error.unavailable);
            console.warn(`${error.unavailable} [${imageTarget?.src}]:`, err);
        }
    }, [addError, error.unavailable]);

    const openSrcModal = () => {

        if (imageSrc) {

            setImageLoading(true);
            setIsOpen(true);

            checkImageSrc(imageSrc, (isValid) => {
                if (!isValid) {
                    setImageLoading(false);
                    setIsOpen(false);
                    addError(error.unavailable);
                    return;
                }
            })
        };
    };

    const downloadAction = async (imageSrc: string, imageName: string) => {

        setImageDownload(true);

        function isRasterFormatContains(inputString: string): boolean {
            const rasterFormatsRegex: RegExp = /\.(jpe?g|png|gif|bmp|tiff?|heif|heic|webp)$/i;
            return rasterFormatsRegex.test(inputString);
        }

        try {
            const response = await fetch(imageSrc);
            const blob = await response.blob();
            const objectURL = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = objectURL;
            link.download = imageName && isRasterFormatContains(imageName)
                ? imageName
                : `image_${uuidv4().replace(/-/g, '').slice(0, 16)}.png`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            URL.revokeObjectURL(objectURL);
        }
        catch (err) {
            addError(error.undownloadable);
            console.warn(`${error.undownloadable} [${imageSrc}]:`, err);
        }
        finally {
            setImageDownload(false);
        }
    };

    return {
        openImageModal,
        openSrcModal,
        isOpen,
        setIsOpen,
        imageRef,
        imageSrc,
        setImageSrc,
        imageLoading,
        setImageLoading,
        imageZoomInitial,
        imageZoomLimit,
        imageDownload,
        downloadAction
    }
}
