import React, { useMemo } from 'react';
import ReactFlow, {Background, Controls, MarkerType, MiniMap} from 'react-flow-renderer';
import { useGlobalContext } from 'lib/hooks';
import { CustomNodeComponent, ConditionNodeComponent } from './node-components';
import cs from './script-editor.module.scss';

const EDGE_TYPE_PREVIEW = 'preview';
const EDGE_TYPE_FROM = 'link_from';
const EDGE_TYPE_TO = 'link_to';

// Соед. линии для связывания двух шагов
const getCustomEdge = (type) => ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    // _sourcePosition,
    // _targetPosition,
    style = {},
    // data,
    markerEnd,
}) => {
    let d = '';
    const dDefault = `M${sourceX},${sourceY} C ${2*sourceX} ${sourceY + 0.9*(targetY - sourceY)} ${targetX - 200} ${sourceY + 0.6*(targetY - sourceY)} ${targetX},${targetY}`;

    switch (type) {
        // Предпросмотр (штрих)
        case EDGE_TYPE_PREVIEW:
            d = dDefault;
            break;
        // Связывание (сплошная)- от
        case EDGE_TYPE_FROM:
            d = `M${sourceX},${sourceY} Q${1.3*sourceX},${sourceY} ${targetX},${targetY}`;
            break;
        // Связывание (сплошная) - до
        case EDGE_TYPE_TO:
            d = `M${sourceX},${sourceY} C ${sourceX} ${sourceY + 0.75*(targetY - sourceY)} ${targetX - 200} ${sourceY + 0.35*(targetY - sourceY)} ${targetX},${targetY}`;
            break;
        default:
            d = dDefault;
            break;
    }

    return (
        <g>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={d}
                markerEnd={markerEnd}
            />
        </g>
    );
}

export const formatEdges = (_edges, node, setEdges) => {
    const connectedEdges = _edges.filter((edge) => {
        return edge.target === node.id || edge.source === node.id;
    });

    connectedEdges.forEach((edge) => {
        if (!edge.style) {
            edge.style = {};
        }

        if (edge.source === node.id) {
            if (edge.type === EDGE_TYPE_FROM) {
                edge.sourceHandle = 'handle-bottom-right';
            } else {
                edge.sourceHandle = 'handle-bottom';
            }
        }
        if (edge.target === node.id) {
            if (edge.type === EDGE_TYPE_TO) {
                edge.targetHandle = 'handle-right-center';
            } else {
                edge.targetHandle = 'handle-center-top';
            }
        }

        switch (node.type) {
            case 'neutral':
                edge.style.stroke = 'var(--color-blue)';
                if (edge.markerEnd || edge.source === node.id) {
                    edge.markerEnd = {
                        type: MarkerType.ArrowClosed,
                        color: '#1280ce',
                        width: edge.type === EDGE_TYPE_TO ? 10 : 20,
                        height: edge.type === EDGE_TYPE_TO ? 10 : 20
                    };
                }
                break;
            case 'positive':
                edge.style.stroke = 'var(--color-green)';
                if (edge.markerEnd || edge.source === node.id) {
                    edge.markerEnd = {
                        type: MarkerType.ArrowClosed,
                        color: '#50b678',
                        width: edge.type === EDGE_TYPE_TO ? 10 : 20,
                        height: edge.type === EDGE_TYPE_TO ? 10 : 20
                    };
                }
                break;
            case 'negative':
                edge.style.stroke = 'var(--color-red)';
                if (edge.markerEnd || edge.source === node.id) {
                    edge.markerEnd = {
                        type: MarkerType.ArrowClosed,
                        color: '#ff6973',
                        width: edge.type === EDGE_TYPE_TO ? 10 : 20,
                        height: edge.type === EDGE_TYPE_TO ? 10 : 20
                    };
                }
                break;
            default:
                if (!edge.style.stroke) {
                    edge.style.stroke = 'var(--color-blue)';
                    if (edge.markerEnd) {
                        edge.markerEnd.color = '#1280ce';
                    }
                }
                break;
        }
        setEdges(_edges);
    })
}

function ScriptEditor({onNodeClick, onConditionNodeClick, onAddStep, activeNodeId, onLinkClick, ...rest}) {
    const { platform } = useGlobalContext();
    const { edges } = rest;

    const nodeTypes = useMemo(() => {
        return {
            special: (props) => {
                const { id, data: { isLinked, isHovered } } = props;

                return (
                    <CustomNodeComponent {...props}
                        onClick={onNodeClick}
                        onAdd={onAddStep}
                        active={id === activeNodeId}
                        isBindable={!!(edges.find(edge => edge.target === id || edge.source === id))}
                        isLinked={isLinked} isHovered={isHovered}
                        onLinkClick={onLinkClick}
                    />
                )
            },
            positive: ({ id, data: { text } }) => {
                const props = {
                    text,
                    nodeId: id,
                    active: id === activeNodeId,
                    onClick: onConditionNodeClick,
                    type: 'positive'
                }
                return (
                    <ConditionNodeComponent {...props} />
                )
            },
            negative: ({ id, data: { text } }) => {
                const props = {
                    text,
                    nodeId: id,
                    active: id === activeNodeId,
                    onClick: onConditionNodeClick,
                    type: 'negative'
                }
              return (
                  <ConditionNodeComponent {...props} />
              )
            },
            neutral: ({ id, data: { text } }) => {
                const props = {
                    text,
                    nodeId: id,
                    active: id === activeNodeId,
                    onClick: onConditionNodeClick,
                    type: 'neutral'
                }
              return (
                  <ConditionNodeComponent {...props} />
              )
            },
        };
    }, [onAddStep, onNodeClick, onConditionNodeClick, activeNodeId, edges, onLinkClick]);

    const edgeTypes = useMemo(() => {
        return {
            preview: getCustomEdge(EDGE_TYPE_PREVIEW),
            link_from: getCustomEdge(EDGE_TYPE_FROM),
            link_to: getCustomEdge(EDGE_TYPE_TO),
        };
    }, []);

    return (
        <ReactFlow nodeTypes={nodeTypes} edgeTypes={edgeTypes} deleteKeyCode={null} {...rest}>
            <Controls />
            <div className={cs.generalControlContainer}>
                {platform !== 'mobile' && (
                    <MiniMap
                        nodeColor={(node) => {
                            switch (node.type) {
                                case 'input':
                                    return 'red';
                                case 'default':
                                    return '#00ff00';
                                case 'output':
                                    return 'rgb(0,0,255)';
                                default:
                                    return '#eee';
                            }
                        }}
                        nodeStrokeWidth={3}
                    />
                )}
            </div>
            <Background />
        </ReactFlow>
    );
}

export default ScriptEditor;
