import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Mark from 'mark.js';
import Modal from 'react-modal';
import classNames from 'classnames';
import { Link, setDocumentTitle, useLocation, useNavigate, useParams } from 'shared/router';
import { parse } from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import TreeUtils from 'lib/util/tree.util';
import { download } from 'lib/util/download';
import { tickerColorOptions, tickerTimeRangeOptions } from 'lib/config/constant';
import { useDialog, useGlobalContext, useEventListener, useMessage } from 'lib/hooks';
import { useNews } from './use-news';
import { perm } from 'model/resource';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import { NewsFormModel } from 'model/news/news-form-model';
import { decrementUnreadCounter, selectSession } from 'slice/authSlice';
import { selectNewsBeingRemoved, selectNewsPicker, setNewsBeingRemoved, updateNewsTicker } from 'slice/newsPickerSlice';
import GlossaryService from 'services/GlossaryService';
import { TreePropContext } from 'containers/side-tree-wrapper';
import Lock from 'components/lock';
import Comments from 'components/commenents';
import SendEmail from 'components/send-email';
import Attachments from 'components/attachments';
import LikesComponent from 'components/like/like';
import ReportError from 'components/report-error';
import Confirmation from 'components/confirmation';
import PostingHeader from 'components/posting-header';
import PostingSearch from 'components/posting-search';
import ResourceLogList from 'components/resource-log-list';
import SubscribeUpdates from 'components/subscribe-updates';
import { DOCUMENT_TYPE } from 'components/posting-header/posting-header';
import Button from 'uikit/button';
import Divider from 'uikit/divider';
import Loading from 'uikit/loading';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import HtmlViewer from 'editors/html-viewer';
import ButtonTimer from 'uikit/button-timer';
import { SelectOption } from 'uikit/select/select';
import LabelWithColor from 'uikit/select/components/label-with-color';
import api from 'api/index';
import cs from './news-posting.module.scss';
import ScrollTopBtn from 'components/scroll-top-btn';
import { fetchMenuButtons } from 'slice/authSlice';
import { useWindowSize } from 'lib/hooks/useWindowSize';
import { getDiffs } from 'shared/utils/diff/diffHelper';
import { EmptyNews, EmptyNewsDeleted } from 'components/projects/empty';
import { preparePrint } from 'lib/util/preparePrint.ts';


const NewsPosting = ({ archive = false }) => {
    const navigate = useNavigate();
    const { id, snapshotId, type = 'news' } = useParams();

    const { platform, locationUrl } = useGlobalContext();
    const { addSuccess, addError } = useMessage();
    const location = useLocation();
    const { width } = useWindowSize();

    const newsPicker = useSelector(selectNewsPicker);
    const session = useSelector(selectSession);
    const dispatch = useDispatch();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const { loading, news, onDelete, viewersCount, state } = useNews(id, type);

    const [lock, setLock] = useState(false);
    const [downloading, setDownloading] = useState(false);

    const [isFavorite, setIsFavorite] = useState(false);
    const [isNewsTickerModal, setIsNewsTickerModal] = useState(false);

    const [logsShow, setLogsShow] = useState(false);
    const [isReportError, setIsReportError] = useState(false);

    const [sendEmail, setSendEmail] = useState(false);
    const [subscribeUpdates, setSubscribeUpdates] = useState(false);

    const [tickerTime, setTickerTime] = useState(1);
    const [tickerColor, setTickerColor] = useState('FFE989');

    const [searchInstance, setSearchInstance] = useState(null);
    const [searchText, setSearchText] = useState('');

    const [markElements, setMarkElements] = useState([]);
    const [markIterator, setMarkIterator] = useState(0);

    const [searchContent, setSearchContent] = useState(null);
    const { setScrollToNode, tree: treePlugin } = React.useContext(TreePropContext);

    const newsBeingRemovedFromTicker = useSelector(selectNewsBeingRemoved);
    const [wasRemovedFromTicker, setWasRemovedFromTicker] = useState(false);

    const [isScrollTopButton, setIsScrollTopButton] = useState(false);
    const [isReadButtonActive, setIsReadButtonActive] = useState(false);

    const [isReadInfo, setIsReadInfo] = useState(true);

    // History fields;
    const [isHistory, setIsHistory] = useState(false);
    const [isSnapshotRestore, setIsSnapshotRestore] = useState(false);

    const [snapshots, setSnapshots] = useState([]);
    const [snapshotIndex, setSnapshotIndex] = useState(0);

    const [snapshot, setSnapshot] = useState(null);
    const [snapshotContent, setSnapshotContent] = useState('');
    const [isSnapshotRestoreSubmitting, setIsSnapshotRestoreSubmitting] = useState(false);

    const [newsContainer, setNewsContainer] = useState(null);
    const body = useMemo(() => {
        if (snapshot) {
            return snapshotContent;
        }

        if (news) {
            return GlossaryService.format(news['text'], news['project'] ? news['project']['id'] : null);
        }

        return '';
    }, [snapshot, snapshotContent, news]);

    const onSnapshotClick = (item) => {
        // Check is current snapshot;
        if (snapshot === item) {
            return;
        }

        // Navigate to snapshot;
        navigate((!news['projectId'] ? '' : '/projects') + `/news/${news['id']}/history/${item['id']}`);
    };
    const onSnapshotRestore = async () => {
        if (isSnapshotRestoreSubmitting) {
            return;
        }

        setIsSnapshotRestoreSubmitting(true);
        await api.snapshot.applyNewsSnapshot(snapshot['snapshot']['id']);
        setIsSnapshotRestore(false);
        navigate((!news['projectId'] ? '' : '/projects') + `/news/${id}`);
        setIsSnapshotRestoreSubmitting(false);
    };

    // Начинает работать только в обычных статьях, а не в черновиках
    // const { checkPermission } = usePermittedActions(
    //     type === 'news' ? RESOURCE_TYPE.NEWS : null, type === 'news' ? id : null
    // );

    const onScroll = (e) => {
        setIsScrollTopButton(e.target.scrollTop >= e.target.clientHeight);
        _readButtonOnScroll();
    }

    const onRead = async (t) => {
        if (type === 'news') {
            await api.news.readNews(news.id, t);
            dispatch(decrementUnreadCounter('unreadNews'));
        } else if (type === 'news-draft') {
            console.log('news-posting 102');
        }
    };
    const handleSetFavorite = async () => {
        try {
            if (isFavorite) {
                await api.favorites.deleteNewsFromFavorites(id);
                addSuccess('Новость удалена из избранного');
            } else {
                await api.favorites.addNewsToFavorites(id);
                addSuccess('Новость добавлена в избранное');
            }
            setIsFavorite(!isFavorite);
        } catch (e) {
            if (isFavorite) {
                addError('Не удалось удалить из избранного');
            } else {
                addError('Не удалось добавить в избранное');
            }
        }
    };

    const toggleNewsTickerModal = () => {
        setIsNewsTickerModal(isOpen => !isOpen);
    };
    const scrollToFind = useCallback((iter, previousIterator) => {
        if (markElements.length === 0) {
            return;
        }

        if (previousIterator !== -1) {
            markElements[previousIterator].classList.remove('activeSearchMark');
        }

        markElements[iter].classList.add('activeSearchMark');

        requestAnimationFrame(() => {
            const _id = 'newsContent';
            const frame = document.getElementById(_id);
            if (!frame) {
                return;
            }

            const doc = frame.contentDocument;

            if (!doc) {
                return;
            }

            const scrollingContainer = document.querySelector('.' + cs.newsContainer);
            if (scrollingContainer) {
                const elements = doc.getElementsByClassName('activeSearchMark');
                if (elements && elements.length > 0) {
                    const rect = elements[0].getBoundingClientRect();
                    scrollingContainer.scrollTo(0, rect.top);
                }
            }
        });
    }, [markElements]);

    const onEmailSent = () => {
        setSendEmail(false);
        addSuccess('Отправлено!');
    };
    const onSubscribeUpdates = () => {
        setSubscribeUpdates(false);
        addSuccess('Подписка изменена!');
    };

    const onInitIframe = () => {
        const content = document.getElementById('newsContent').contentWindow.document.body;

        if (content) {
            setSearchContent(content);
            const instance = new Mark(content);
            setSearchInstance(instance);
        }
    };
    const searchChange = (e) => {
        searchInstance.unmark();
        setSearchText(e.target.value);

        const punctuation = ".,-\"'«»!_?;:|\\/ ".split("");
        const search = e.target.value.split('').map(p => punctuation.indexOf(p) !== -1 ? '' : p).join('');

        searchInstance.mark(search, {
            separateWordSearch: false,
            ignorePunctuation: punctuation
        });

        const findDetails = searchContent.getElementsByTagName('details');

        for (let i = 0; i < findDetails.length; i++) {
            const findContent = findDetails[i].getElementsByTagName('div');
            const findMark = findContent[0].getElementsByTagName('mark');

            if (findMark.length > 0) {
                findDetails[i].setAttribute('open', '');
            }
        }

        const findElements = searchContent.getElementsByTagName('mark');
        setMarkElements(findElements);
        setMarkIterator(0);
    };
    const searchOnKeyPress = (e) => {
        if (markElements.length === 0) {
            return;
        }

        let keyCode = e.code || e.key;

        if (keyCode === 'Enter') {
            const prevIter = markIterator;

            if (markElements.length - 1 === markIterator) {
                setMarkIterator(0);
                scrollToFind(0, prevIter);
            } else if (markElements.length !== 0) {
                setMarkIterator(markIterator + 1);
                scrollToFind(markIterator + 1, prevIter);
            }
        }
    };

    const onNext = () => {
        const prevIter = markIterator;

        if (markElements.length - 1 === markIterator) {
            setMarkIterator(0);
            scrollToFind(0, prevIter);
        } else {
            setMarkIterator(markIterator + 1);
            scrollToFind(markIterator + 1, prevIter);
        }
    };
    const onPrevious = () => {
        const prevIter = markIterator;

        if (markIterator === 0) {
            setMarkIterator(markElements.length - 1);
            scrollToFind(markElements.length - 1, prevIter);
        } else {
            setMarkIterator(markIterator - 1);
            scrollToFind(markIterator - 1, prevIter);
        }
    };
    const onCancel = () => {
        setSearchText('');
        setIsReadButtonActive(false);

        setMarkIterator(0);
        setMarkElements([]);

        searchInstance.unmark();
    };

    const _newsToFormat = async (format) => {
        try {
            setDownloading(true);
            const res = await api.contentConverter.newsToFormat(id, format);
            download(res, `${news.title}.${format}`, format);
        } catch (error) {
            console.log('ERROR', error);
        } finally {
            setDownloading(false);
        }
    };
    const onModalTickerSave = async () => {
        let formData = (new NewsFormModel(news)).getFormData();
        delete formData.logo;

        if (news.ticker) {
            formData.tickerRequest = null;
            setTickerColor(tickerColorOptions[0].color);
            setTickerTime(tickerTimeRangeOptions[0].value);
        } else {
            formData.tickerRequest = {
                timeToLive: tickerTime * 24,
                color: tickerColor
            };

            dispatch(setNewsBeingRemoved(null));
            setWasRemovedFromTicker(false);
        }

        setDownloading(true);
        try {
            await api.news.editNews(formData);
            addSuccess('Новость изменена');

            news.ticker = formData.tickerRequest;
            dispatch(updateNewsTicker(true));

            toggleNewsTickerModal();
        } catch (e) {
            addError('Не удалось изменить новость');
        }

        setDownloading(false);
    };

    const archiveNews = async () => {
        try {
            await api.archive.moveNewsToArchive(id);
            addSuccess('Новость перемещена в архив');
            dispatch(updateNewsTicker(true));
            dispatch(fetchMenuButtons());
            navigate(news.global ? '/news' : `/projects/${news.project.id}/news`);
        } catch (e) {
            addError('Не удалось отправить новость в архив');
        } finally {
            closeDialog();
        }
    };
    const unArchiveNews = async () => {
        try {
            await api.archive.moveNewsFromArchive(id);
            addSuccess('Новость успешно разархивирована!');
            dispatch(updateNewsTicker(true));
            dispatch(fetchMenuButtons());
            navigate('/archive/news');
        } catch (e) {
            addError('Не удалось разархиваровать!');
        } finally {
            closeDialog();
        }
    };

    const onArchiveNews = () => {
      openDialog({
        title: 'Архивирование',
        text: (
            <span>
                Вы действительно хотите переместить в архив новость <span style={{color: '#279BD9'}}>{news?.title}</span> ? Документы в архиве могут быть восстановлены.
            </span>
        ),
        closeBtnText: 'Нет, отменить',
        submitBtnText: 'Подтвердить',
        onClose: closeDialog,
        onSubmit: archiveNews,
        contentType: 'TEXT'
      })
    }
    const onUnArchiveNews = () => {
      openDialog({
        title: 'Восстановление',
        text: (
            <span>
                Вы действительно хотите восстановить из архива новость <span style={{color: '#279BD9'}}>{news?.title}</span> ?
            </span>
        ),
        closeBtnText: 'Нет, отменить',
        submitBtnText: 'Подтвердить',
        onClose: closeDialog,
        onSubmit: unArchiveNews,
        contentType: 'TEXT'
      })
    }
    const onRemoveNews = () => {
      openDialog({
        title: 'Удаление',
        text: (
            <>
                <span>
                    Вы действительно хотите удалить новость <span style={{color: '#279BD9'}}>{news?.title}</span>? Этот процесс нельзя будет отменить
                </span>
            </>
        ),
        closeBtnText: 'Нет, отменить',
        submitBtnText: 'Удалить',
        color: 'red',
        onClose: closeDialog,
        onSubmit: onDelete,
        contentType: 'TEXT'
      })
    }

    const _readButtonOnScroll = useCallback(() => {
        const commentsContainer = document.getElementById('js-comments');

        if (!commentsContainer) {
            return;
        }

        const sidebarContent = document.getElementById('newsContainer');
        const heightFromPageBottom = commentsContainer.offsetTop;

        if (sidebarContent.scrollTop + sidebarContent.clientHeight + 300 >= heightFromPageBottom) {
            setIsReadButtonActive(true);
        }
    }, []);
    const goToComment = () => {
        const el = document.getElementById('js-comments');
        el.scrollIntoView({ block: 'center', behavior: 'smooth' });
    };

    const getNewsLink = useCallback(() => {
        if (!news) {
            return '';
        }
        if (type === 'news') {
            return archive ? `/archive/news/${id}/edit` : (!news['project'] ? '' : '/projects') + `/news/${id}/edit`;
        } else if (type === 'news-draft') {
            return `/user/news-draft/${id}/news-draft/edit`;
        } else if (type === 'delayed-news') {
            return `/user/delayed-news/${id}/delayed-news/edit`;
        }
    }, [news, id, type, archive]);

    const actions = [
        {
            icon: Icons.EDIT_PEN,
            title: 'Редактировать',
            id: 'newsPostingEditButton',
            visible: !archive && !snapshotId && (news?.permittedActions?.includes(GLOBAL_ACTIONS.NEWS_EDIT)
                || (news?.global && news?.permittedActions?.includes(GLOBAL_ACTIONS.GLOBAL_NEWS_EDIT))),
            display: ['desktop_toolbar'],
            link: getNewsLink(),
            width: 15,
            height: 15,
        },
        {
            icon: Icons.PRINTER,
            title: 'Печать',
            id: 'newsPostingPrintButton',
            visible: platform !== 'mobile' && news?.permittedActions?.includes(GLOBAL_ACTIONS.DOCUMENT_PRINT),
            display: ['desktop_toolbar'],
            onClick: () => {
                preparePrint({
                    contentID: 'newsContent',
                    titleID: 'postingHeaderTitle',
                })
                .catch(err => addError(err));
            },
            width: 15,
            height: 15,
        },
        {
            icon: Icons.LOCK,
            title: 'Доступы',
            id: 'newsPostingLockButton',
            visible: !news?.global && !archive && !snapshotId && news?.permittedActions?.includes(GLOBAL_ACTIONS.LOCK),
            display: ['desktop_toolbar'],
            onClick: () => setLock(true),
            width: 15,
            height: 15,
        },
        {
            icon: isFavorite ? Icons.STAR_FILLED: Icons.STAR,
            title: isFavorite ? 'Убрать из избранного' : 'В избранное',
            id: 'newsPostingFavoriteButton',
            visible: !archive && !snapshotId,
            display: ['desktop_toolbar', 'mobile_toolbar'],
            onClick: () => handleSetFavorite(),
            className: isFavorite ? cs.favoriteActive : null,
            width: 15,
            height: 15,
        },
        {
            icon: Icons.COMMENTS,
            id: 'newsPostingGoToCommentButton',
            title: 'К комментариям',
            visible: !snapshotId,
            display: ['desktop_toolbar', 'mobile_toolbar'],
            onClick: () => goToComment(),
            width: 15,
            height: 15,
        },
        {
            icon: Icons.TIME,
            title: 'История',
            id: 'articlePostingHistoryButton',
            visible: !archive && !snapshotId,
            display: ['desktop_toolbar', 'mobile_toolbar'],
            onClick: () => navigate((!news['projectId'] ? '' : '/projects') + `/news/${news['id']}/history/${snapshots[0]['id']}`),
            width: 15,
            height: 15,
        },
        // {
        //     icon: Icons.LETTER,
        //     title: 'Отправить по электронной почте',
        //     id: 'newsPostingEmailButton',
        //     visible: news?.permittedActions?.includes(GLOBAL_ACTIONS.DOCUMENT_SEND_TO_EMAIL) && !snapshotId,
        //     onClick: () => setSendEmail(true),
        //     width: 15,
        //     height: 15,
        // },
        {
            icon: Icons.DOWNLOAD,
            title: 'Скачать документ',
            id: 'newsPostingDownloadButton',
            visible: news?.permittedActions?.includes(GLOBAL_ACTIONS.FILE_READ) && !snapshotId,
            width: 15,
            height: 15,
            submenu: [
                {
                    title: 'Microsoft Word (DOCX)',
                    onClick: () => _newsToFormat('docx').then(() => {})
                },
                {
                    title: 'Документ PDF',
                    onClick: () => _newsToFormat('pdf').then(() => {})
                },
                // {
                //     title: 'Текст в формате TXT',
                //     onClick: () => _newsToFormat('txt').then(() => {})
                // },
                // {
                //     title: 'Текст в формате RTF',
                //     onClick: () => _newsToFormat('rtf').then(() => {})
                // }
            ],
        },
        {
            icon: Icons.ARCHIVE,
            title: 'В архив',
            id: 'newsPostingArchiveButton',
            visible: !archive && !snapshotId && (news?.permittedActions?.includes(GLOBAL_ACTIONS.RESOURCE_ARCHIVE_MOVE)
                || (news?.global && news?.permittedActions?.includes(GLOBAL_ACTIONS.GLOBAL_NEWS_ARCHIVE_MOVE))),
            onClick: () => onArchiveNews(),
            width: 15,
            height: 15,
        },
        {
            icon: Icons.RELOAD,
            title: 'Разархивировать',
            id: 'newsPostingUnArchiveButton',
            visible: archive && (news?.permittedActions?.includes(GLOBAL_ACTIONS.RESOURCE_ARCHIVE_MOVE)
                || (news?.global && news?.permittedActions?.includes(GLOBAL_ACTIONS.GLOBAL_NEWS_ARCHIVE_MOVE))),
            onClick: () => onUnArchiveNews(),
            width: 15,
            height: 15,
        },
        // {
        //     icon: Icons.BUG,
        //     title: 'Сообщить об ошибке',
        //     id: 'newsPostingReportErrorButton',
        //     visible: !archive && news?.permittedActions?.includes(GLOBAL_ACTIONS.ERROR_REPORT_ADD) && !snapshotId,
        //     onClick: () => setIsReportError(true),
        //     width: 15,
        //     height: 15,
        // },
        {
            icon: Icons.INFO,
            title: 'Действия в документе',
            id: 'newsPostingActionsButton',
            visible: news?.permittedActions?.includes(GLOBAL_ACTIONS.ACTIVITY_PAGE) && !snapshotId,
            onClick: () => setLogsShow(true),
            width: 15,
            height: 15,
        },
        {
            icon: Icons.LIGHTNING,
            title: !news?.ticker || wasRemovedFromTicker ?
                'Опубликовать в бегущей строке' : 'Удалить из бегущей строки',
            id: 'newsPostingTickerButton',
            visible: !archive && !snapshotId && (news?.permittedActions?.includes(GLOBAL_ACTIONS.NEWS_EDIT)
                || (news?.global && news?.permittedActions?.includes(GLOBAL_ACTIONS.GLOBAL_NEWS_EDIT))),
            onClick: () => toggleNewsTickerModal(),
            width: 15,
            height: 15,
        },
        // {
        //     icon: Icons.BELL,
        //     title: 'Подписаться на обновления',
        //     id: 'newsPostingSubscribeButton',
        //     visible: !archive && news?.permittedActions?.includes(GLOBAL_ACTIONS.NOTIFICATION_SUBSCRIBE_ADD) && !snapshotId,
        //     onClick: () => setSubscribeUpdates(true),
        //     width: 15,
        //     height: 15,
        // },
        // {
        //     icon: Icons.SETTINGS,
        //     title: 'Настройки',
        //     id: 'newsPostingSettingsButton',
        //     visible: !snapshotId,
        //     onClick: () => navigate(`/projects/news/${news.id}/content-management`)
        // },
        {
            icon: Icons.TRASH,
            title: 'Удалить новость',
            id: 'newsPostingDeleteButton',
            visible: archive && news?.permittedActions?.includes(GLOBAL_ACTIONS.NEWS_DELETE),
            onClick: () => onRemoveNews(),
        }
    ];

    const renderHeader = () => (
        <>
            <PostingHeader
                className={cs.header}
                authorId={news.author.login}
                authorName={news.author.firstName + ' ' + news.author.lastName}
                authorStatus={news.author.status}
                authorImage={news['author']['avatarUuid'] && api.upload.getImage(news['author']['avatarUuid'], false, 128)}
                title={news.title}
                isFavorite={news.favorite}
                date={news.createTime}
                isArchive={archive}
                documentType={DOCUMENT_TYPE.NEWS}
                isHighPriority={news.priority === 'HIGH'}
                lastAction={news.lastAction}
                viewersCount={viewersCount}
                crumbs={[
                    {
                        name: news.project ? news.project.title : 'Новости',
                        linkTo: news.project
                            ? archive
                                ? '/archive/' + news.project.id + '/news'
                                : '/projects/' + news.project.id + '/news'
                            : archive ? '/archive/news' : '/news'
                    },
                    {
                        name: news.title
                    }
                ]}
            />
            <PostingSearch
                onCancel={onCancel}
                onNext={onNext}
                onPrevious={onPrevious}
                allSearchResult={markElements.length}
                currentPosition={markIterator + 1}
                value={searchText}
                onKeyPress={searchOnKeyPress}
                onChange={searchChange}
                className={cs.search}
                placeholder={'Поиск по новости'}
                isMobile={platform === 'mobile'}
                actions={actions}
                archive={archive
            }>
                {!archive && !snapshot && (
                    <ButtonTimer
                        id={id}
                        readTime={news?.time}
                        externalRead={news?.read ?? false}
                        onRead={onRead}
                        className={platform === 'mobile' ? cs.readBtnMobile : null}
                        disabled={!isReadButtonActive}
                    />
                )}
                {snapshots.length !== 0 && snapshotId && news?.permittedActions?.includes(GLOBAL_ACTIONS.NEWS_EDIT) && (
                    <Button
                        className={cs.versionButton}
                        color="blue"
                        label="Восстановить версию"
                        disabled={snapshots[0]['id'] === snapshotId}
                        onClick={() => setIsSnapshotRestore(true)}
                    />
                )}
            </PostingSearch>
        </>
    );

    const goTop = () => {
        const el = document.getElementById('postingHeader');
        el?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    };

    useEventListener('scroll', onScroll, newsContainer);
    useEventListener('keydown', (e) => {
      if ((e.ctrlKey || e.metaKey) && e.keyCode === 80) {
        e.preventDefault();

        const iframe = document.getElementById('newsContent');
        iframe.contentWindow.print();
      }
    });

    useEffect(() => {
        if (type === 'news' && treePlugin && news && news.project) {
            const selectedNode = TreeUtils.findSectionInNode({children: treePlugin.nodes}, news.project.id)
            const path = TreeUtils.getPathIdsTo(selectedNode);
            setScrollToNode(path);
        }
    }, [treePlugin, news, type, setScrollToNode]);
    useEffect(() => {
        if (news && newsBeingRemovedFromTicker === news.id) {
            news.ticker = null;
            setWasRemovedFromTicker(true);
        }
    }, [newsBeingRemovedFromTicker, news]);

    useEffect(() => {
        if (!news) {
            return;
        }

        setIsFavorite(news.favorite);
        setNewsContainer(document.getElementById('newsContainer'));

        if (!news.ticker) {
            setTickerColor(tickerColorOptions[0].value);
            setTickerTime(tickerTimeRangeOptions[0].value);
        }
        goTop();
    }, [news]);

    useEffect(() => {
        scrollToFind(0, -1);
    }, [scrollToFind, searchText]);
    useEffect(() => {
        if (markIterator === 0)
            scrollToFind(0, -1);
    }, [scrollToFind, markIterator]);

    useEffect(() => {
        const fetchData = async () => {
            const snapshots = await api.snapshot.getNewsSnapshots(id);
            setSnapshots(snapshots);
        };
        fetchData();
    }, [id]);

    useEffect(() => {
        const fetchData = async () => {
            const snapshotIndex = snapshots.findIndex((item) => item['id'] === snapshotId);
            const snapshot = await api.snapshot.getNewsSnapshot(snapshotId);

            setSnapshotIndex(snapshotIndex);
            setSnapshot(snapshot);

            setIsHistory(true);
            setSnapshotContent(getDiffs(snapshot['origin'], snapshot['revised']));

            setDownloading(false);
        };
        if (snapshotId && snapshots.length !== 0) {
            setDownloading(true);
            fetchData();
        }
    }, [snapshotId, snapshots]);

    useEffect(() => {
        if (!news) {
            return;
        }

        if (!archive && news['state'] === 'ARCHIVED') {
            navigate(`/archive/news/${news.id}`);
        } else if (archive && news['state'] === 'ACTIVE') {
            navigate(`/projects/news/${news.id}`);
        } else if (news['state'] === 'ACTIVE' && news['project'] === null && document.location.href.indexOf('projects') !== -1) {
            navigate(`/news/${news.id}`);
        }
    }, [navigate, addError, archive, news]);

    useEffect(() => {
        GlossaryService.init();

        // Отслеживаем наличие пар-ров запроса для подсветки совпадений из поиска
        if (locationUrl !== location.pathname && locationUrl.includes(location.pathname)) {
            const { highlights } = parse(`?${locationUrl.split('?')[1]}`);
            if (highlights) {
                // window.history.pushState(null, '', `${location.pathname}?highlights=${highlights}`);
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => { setTimeout(() => _readButtonOnScroll(), 3000) }, [_readButtonOnScroll]);

    useEffect(() => {
        if (!news) {
            return;
        }

        setDocumentTitle(`${news.title} — KMS Gran`);
    }, [news]);

    if (state === 'ERROR') {
        return (
            <div className={cs.news}>
                <EmptyNews />
            </div>
        );
    }

    if (state === 'DELETED') {
        return (
            <div className={cs.news}>
                <EmptyNewsDeleted />
            </div>
        );
    }

    return (
        <>
            {isSnapshotRestore && (
                <Modal isOpen={true} className={cs.restoreModal} overlayClassName={cs.restoreModalOverlay}>
                    <div className={cs.restoreModalHeader}>
                        <h2>Восстановление версии</h2>
                        <IconButton
                            icon={<Icon type={Icons.CROSS} width={14} height={14}/>}
                            onClick={() => setIsSnapshotRestore(false)}
                        />
                    </div>
                    <div className={cs.restoreModalBody}>
                        <p>Вы действительно хотите установить выбранную версию как актуальную версию документа?</p>
                        <p>Восстановление создаст новую актуальную версию документа и сохранит все существующие.</p>
                    </div>
                    <div className={cs.restoreModalFooter}>
                        <Button label="Нет, закрыть" onClick={() => setIsSnapshotRestore(false)}/>
                        <Button
                            label="Да, восстановить"
                            color="green"
                            onClick={() => onSnapshotRestore()}
                        />
                    </div>
                </Modal>
            )}
            <Confirmation {...dialogState} />
            <SendEmail isOpen={sendEmail} onDismiss={() => setSendEmail(false)} onOk={onEmailSent} />
            <ResourceLogList isOpen={logsShow} onDismiss={() => setLogsShow(false)} uuid={id} />
            <SubscribeUpdates
                isOpen={subscribeUpdates}
                onDismiss={() => setSubscribeUpdates(false)}
                onOk={onSubscribeUpdates}
            />
            {news && (
                <Modal
                    isOpen={isNewsTickerModal}
                    className={cs.modal}
                    onRequestClose={toggleNewsTickerModal}
                    overlayClassName={cs.modalOverlay}
                >
                    <div className={cs.modalContent}>
                        <div className={cs.modalHeader}>
                            <h3>{news.ticker ? 'Удаление из бегущей строки' : 'Публикация в бегущей строке'}</h3>
                            <IconButton
                                icon={<Icon type={Icons.CROSS} width={16} height={16}/>}
                                onClick={toggleNewsTickerModal}
                            />
                        </div>
                        <div className={cs.modalBody}>
                            {news.ticker && (
                                <div className={cs.deleteTickerText}>
                                    Вы действительно хотите удалить новость из бегущей строки?
                                </div>
                            )}
                            {!news.ticker && (
                                <>
                                    <SelectOption
                                        label='Срок нахождения в бегущей строке'
                                        defaultValue={tickerTimeRangeOptions[0]}
                                        options={tickerTimeRangeOptions}
                                        onChange={(item) => setTickerTime(item.value)}
                                    />
                                    <SelectOption
                                        className={cs.modalSelectColor}
                                        label="Цвет строки"
                                        components={{ SingleValue: LabelWithColor, Option: LabelWithColor }}
                                        options={tickerColorOptions}
                                        onChange={(item) => setTickerColor(item.value)}
                                        defaultValue={news.ticker
                                            ? tickerColorOptions.find(v => v.color === news.ticker.color)
                                            : tickerColorOptions[0]}
                                    />
                                </>
                            )}
                        </div>
                        <div className={cs.modalFooter}>
                            <Button label="Отменить" onClick={toggleNewsTickerModal} />
                            <Button
                                color="green"
                                label="Подтвердить"
                                onClick={() => onModalTickerSave()}
                            />
                        </div>
                    </div>
                </Modal>
            )}
            <ReportError
                isOpen={isReportError}
                onDismiss={() => setIsReportError(false)}
                onRequestSubmit={async (data) => {
                    await api.errorReportResource.errorReport('NEWS', id, data);

                    addSuccess('Отправлено!');
                    setIsReportError(false);
                }}
            />
            {news && lock && (
                <Lock
                    title="Доступ к новости"
                    onClose={() => setLock(false)}
                    emptyTitle="Новость в закрытом доступе"
                    emptyDescription="Пригласите первого участника для работы с новостью"
                    projectId={news?.project?.id}
                    resourceId={news?.id}
                    resourceType="NEWS"
                    projectName={news.title}
                    isDocument={true}
                />
            )}
            <Loading active={downloading || (!news && loading)} withOverlay={false} withRelativeOverlay={true} />
            {news && (
                <div className={cs.news}>
                    {isHistory && width < 768 && (
                        <div className={cs.mobileHeader}>
                            {renderHeader()}
                        </div>
                    )}
                    <div className={cs.newsWrapper}>
                        <div id='newsContainer' className={classNames(cs.newsContainer, 'js-scroll-wrapper', {
                            [cs.withNewsTicker]: newsPicker.count > 0
                        })}>
                            {(!isHistory || width >= 768) && renderHeader()}
                            {!archive && !news['read'] && news['version'] && isReadInfo && !snapshot && news['wasRead'] && (
                                <div className={cs.postingReadVersion}>
                                    <div className={cs.postingReadVersionContent}>
                                        <div className={cs.postingReadVersionContainer}>
                                            <div>
                                                <Icon className={cs.desktop} type={Icons.INFO} width={16} height={16} />
                                                <Icon className={cs.mobile} type={Icons.INFO_HIGHLIGHT} width={24} height={24} />
                                            </div>
                                            <p>
                                                В документ были внесены изменения относительно последней прочитанной
                                                версии
                                            </p>
                                        </div>
                                        <div
                                            className={cs.postingReadVersionShow}
                                            onClick={() => navigate((!news['projectId'] ? '' : 'projects') + `/news/${news['id']}/history/${snapshots[0]['id']}`)}
                                        >
                                            <Icon type={Icons.EYE} width={20} height={20} />
                                            <span>Показать изменения</span>
                                        </div>
                                    </div>
                                    <div className={cs.postingReadVersionClose}>
                                        <Icon
                                            width={18}
                                            height={18}
                                            type={Icons.CLOSE_CIRCLE}
                                            onClick={() => setIsReadInfo(false)}
                                        />
                                    </div>
                                </div>
                            )}
                            {snapshot && (
                                <div id='postingSnapshot' className={cs.postingSnapshot}>
                                    <p>Версия {snapshots.length - snapshotIndex} {snapshotIndex === 0 ? '(Текущая)' : ''}</p>
                                    <ul>
                                        <li>
                                            <label>Дата публикации версии:</label>
                                            <span>{new Date(snapshot['snapshot']['createTime']).toLocaleString()}</span>
                                        </li>
                                        <li>
                                            <label>Автор версии:</label>
                                            <Link to={'/edit-user/' + snapshot['snapshot']['authorLogin']}>
                                                {snapshot['snapshot']['authorName']}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            )}
                            <div className={classNames(cs.postingContent, { [cs.mobileContent]: platform === 'mobile' })}>
                                {news.logoUuid && (
                                    <img
                                        id='newsPostingCover'
                                        alt=''
                                        className={cs.cover}
                                        src={api.upload.getImage(news.logoUuid, false, 2130)}
                                        style={{ marginTop: !news['read'] && news['version'] && isReadInfo && !snapshot && news['wasRead'] && !archive ? '-83px' : '0' }}
                                    />
                                )}
                                {news.description && (
                                    <div className={cs.desc}>{news.description}</div>
                                )}
                                {news.attachments.length !== 0 && (
                                    <div id='files' className={cs.fileBlock}>
                                        <div className={cs.blockFiles}>
                                            <Attachments
                                                files={NewsFormModel.asFiles(news.attachments)}
                                                noDownload={!news?.permittedActions?.includes(perm.file.FILE_READ)}
                                                noAdd
                                                noDelete
                                                canCollapse
                                                defaultCollapsed={news.attachmentsCollapsed}
                                            />
                                        </div>
                                    </div>
                                )}
                                <Divider style={{ marginBottom: 0, marginTop: 0 }} />
                                <HtmlViewer
                                    id='newsContent'
                                    documentUuid={news.id}
                                    onInit={onInitIframe}
                                    onAfterLoad={() => setTimeout(() => _readButtonOnScroll(), 1000)}
                                    body={body}
                                    isOpenDropdowns={isHistory}
                                />
                                <Divider style={{ marginBottom: 0, marginTop: 0 }} />
                                {!snapshotId && (
                                    <>
                                        <LikesComponent
                                            id={news.id}
                                            {...news}
                                            getDislikesFunction={api.dislike.getNewsDislikes}
                                            getLikesFunction={api.like.getNewsLikes}
                                            addDislikeFunction={api.dislike.addNewsDislike}
                                            addLikeFunction={api.like.addNewsLike} sessionLogin={session?.login}
                                            isArchive={archive}
                                        />
                                        <Divider style={{ marginBottom: 0, marginTop: 0 }} />
                                    </>
                                )}
                            </div>
                            {!snapshotId && (
                                <Comments id={news.id} of={Comments.NEWS} session={session} isArchive={archive} />
                            )}
                            <ScrollTopBtn scrollId='newsContainer' isSeen={isScrollTopButton} />
                        </div>
                        {isHistory && (
                            <div id='postingHistory' className={classNames(cs.newsHistory, {
                                [cs.withNewsTicker]: newsPicker.count > 0
                            })}>
                                <div className={cs.newsHistoryHeader}>
                                    <h4>История версий</h4>
                                    <div
                                        className={cs.newsHistoryHeaderClose}
                                        onClick={() => navigate((!news['projectId'] ? '' : '/projects') + `/news/${news['id']}`)}
                                    >
                                        <Icon type={Icons.CROSS} width={10} height={10} />
                                    </div>
                                </div>
                                <div className={cs.newsHistoryBody}>
                                    {snapshots.map((item, index) => (
                                        <div
                                            key={index}
                                            onClick={() => onSnapshotClick(item)}
                                            className={classNames(
                                                cs.newsHistoryBodyItem,
                                                snapshot['snapshot']['id'] === item['id']
                                                    ? cs.newsHistoryBodyItemActive : null
                                            )}
                                        >
                                            <h5>Версия {snapshots.length - index} {index === 0 ? '(Текущая)' : ''}</h5>
                                            <div className={cs.newsHistoryBodyItemContainer}>
                                                <img
                                                    src={item['authorAvatarUuid']
                                                        ? api.upload.getImage(item['authorAvatarUuid'])
                                                        : '/img/avatar.png'
                                                    }
                                                    alt={item['authorName']}
                                                />
                                                <span>{item['authorName']}</span>
                                            </div>
                                            <p>{new Date(item['createTime']).toLocaleString()}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default NewsPosting;
