import React from 'react';
import classNames from 'classnames';
import cx from './document-label.module.scss';

const DocumentLabel = ({ id, className, text, type = 'default', size = 'default' }) => {
    const sizeClass = classNames({
        [cx.defaultSize]: size === 'default',
        [cx.small]: size === 'small'
    })

    return (
        <div id={id} className={classNames(cx.documentLabel, cx[type], sizeClass, className)}>
            <span className={cx.text}>{text}</span>
        </div>
    )
};

export default DocumentLabel;
