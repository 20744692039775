import React from 'react';
import { useDialog, useMessage, usePermittedActions } from 'lib/hooks';
import { perm, RESOURCE_TYPE } from 'model/resource';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button/icon-button';
import Confirmation from 'components/confirmation';
import api from 'api/index';
import cx from './section.module.scss';
import { decodeURIComponentSafe } from 'lib/helpers';
import { useSelector } from 'react-redux';
import { selectGlobalRootPermActions } from 'slice/authSlice';
import { useConfiguration } from '../../lib/context';

const Section = ({ id = '', title = '', tab, onTabChange, handleOnDelete }) => {
    const { dialogState, openDialog, closeDialog } = useDialog();
    const { addError, addSuccess } = useMessage();

    const { isScriptingEnabled } = useConfiguration();

    const { checkPermission } = usePermittedActions(RESOURCE_TYPE.SECTION, id);
    const globalRootPermActions = useSelector(selectGlobalRootPermActions);

    const p = {
        delete: checkPermission(perm.document.SECTION_DELETE_REQUEST),
        unarchive: checkPermission(perm.document.RESOURCE_ARCHIVE_RESTORE)
    }

    const onUnArchive = async () => {
        const text = (
          <span>
              Вы действительно хотите восстановить из архива раздел&nbsp;
              <span style={{ color: '#279BD9' }}>
                  {title}
              </span> ?
          </span>
        )

        openDialog({
            title: 'Восстановление',
            text,
            onSubmit: () => unArchive(),
            onClose: closeDialog,
            contentType: 'TEXT'
        });
    };
    const unArchive = async () => {
        try {
            await api.archive.moveSectionFromArchive(id);
            addSuccess('Раздел восстановлен из архива');
            handleOnDelete(id);
        } catch (e) {
            addError('Не удалось восстановить раздел из архива');
        }
    };

    const remove = async () => {
        try {
            await api.section.deleteSection(id);
            addSuccess('Раздел удалён');
            handleOnDelete(id);
        } catch (e) {
            addError('Не удалось удалить раздел');
        }
    };
    const onRemove = async () => {
        const text = (
          <span>
              Вы действительно хотите удалить раздел&nbsp;
              <span style={{ color: '#279BD9' }}>
                  {title}
              </span> ?
              Этот процесс нельзя будет отменить
          </span>
        )

        openDialog({
            color: 'red',
            title: 'Удаление',
            text,
            onSubmit: () => remove(),
            onClose: closeDialog,
            contentType: 'TEXT'
        });
    };

    return (
        <>
            <Confirmation {...dialogState}/>

            <div className={cx.section}>
                <div className={cx.title}>
                    <h1>{decodeURIComponentSafe(title)}</h1>
                    <div className={cx.navigation}>
                        {p.unarchive && (
                            <IconButton
                                icon={<Icon type={Icons.RELOAD} tooltip='Разархивировать' width={13} height={12} />}
                                onClick={onUnArchive}
                            />
                        )}
                        {p.delete && globalRootPermActions?.find(action => action === 'SECTION_DELETE') && (
                            <IconButton
                                icon={<Icon type={Icons.TRASH} tooltip='Удалить' width={14} height={15} />}
                                onClick={onRemove}
                            />
                        )}
                    </div>
                </div>

            </div>

            <ul id={'archiveSectionMenu'} className={cx.sectionMenu}>
                <li className={tab === 'articles' ? cx.active : ''}
                    onClick={() => onTabChange('articles')}>Статьи
                </li>
                {isScriptingEnabled && (
                    <li id={cx.scriptsTab} className={tab === 'scripts' ? cx.active : ''}
                        onClick={() => onTabChange('scripts')}>Скрипты
                    </li>
                )}
                {/*<li className={tab === 'news' ? cx.active : ''}*/}
                {/*    onClick={() => onTabChange('news')}>Новости</li>*/}
            </ul>
        </>
    );
};

export default Section;
