import { Extension } from '@tiptap/core';
import { FontWeightMark } from './font-weight-mark';
import { LineHeight } from './line-height-mark';
import { FontSizeMark } from './font-size-mark';
import { TextAlignMark } from './text-align-mark';

export default Extension.create({
    name: 'global-attributes',

    addExtensions() {
        return [FontWeightMark, LineHeight, FontSizeMark, TextAlignMark];
    },

    addOptions() {
        return { defaultHeight: '1.5', defaultAlignment: 'left' };
    },

    addGlobalAttributes() {
        return [
            {
                types: ['paragraph', 'textStyle', 'div'],
                attributes: {

                    lineHeight: {
                        default: this.options.defaultHeight,
                        parseHTML: element => element.style.lineHeight || this.options.defaultHeight,
                        renderHTML: attributes => {
                            if (attributes.lineHeight === this.options.defaultHeight) {
                                return {};
                            }

                            return { style: `line-height: ${attributes.lineHeight}` };
                        },
                    },
                    marginTop: {
                        default: null,
                        parseHTML: element => element.style.marginTop,
                        renderHTML: attrs => (attrs.marginTop ? { style: `margin-left: ${attrs.marginTop}` } : {}),
                    },
                    marginBottom: {
                        default: null,
                        parseHTML: element => element.style.marginBottom,
                        renderHTML: attrs => (attrs.marginBottom ? { style: `margin-left: ${attrs.marginBottom}` } : {}),
                    },
                },
            },
            {
                types: ['paragraph', 'customHeading'],
                attributes: {
                    marginLeft: {
                        default: null,
                        parseHTML: el => el.style.marginLeft,
                        renderHTML: attrs => (attrs.marginLeft ? { style: `margin-left: ${attrs.marginLeft}` } : {}),
                    },
                    textAlign: {
                        default: null,
                        parseHTML: element => {
                            const alignment = element.style.textAlign;

                            return alignment;
                        },
                        renderHTML: attrs => (attrs.textAlign ? { style: `text-align: ${attrs.textAlign}` } : {}),
                    },
                },
            },
            {
                types: ['div'],
                attributes: {
                    style: {
                        default: null,
                    },
                }
            },
            {
                types: ['textStyle'],
                attributes: {
                    fontWeight: {
                        default: null,
                        parseHTML: element => element.style.fontWeight?.replace(/['"]+/g, ''),
                        renderHTML: attributes => {
                            if (!attributes.fontWeight) {
                                return {};
                            }

                            return {
                                style: `font-weight: ${attributes.fontWeight}`,
                            };
                        },
                    },
                    fontSize: {
                        default: null,
                        parseHTML: element => element.style.fontSize.replace(/['"]+/g, ''),
                        renderHTML: attrs => {
                            if (!attrs.fontSize) {
                                return {};
                            }
                            let fontSize = String(attrs.fontSize).replace(/\d+(\.\d+)?pt/g, match => {
                                return (match.replace('pt', '') * 4) / 3;
                            });
                            fontSize = fontSize.replace(/[^1-90.]{1,}/g, '');

                            return {
                                style: `font-size: ${fontSize || attrs.fontSize}px`,
                            };
                        },
                    },
                    backgroundColor: {
                        default: null,
                        parseHTML: element => element.style.backgroundColor,
                        renderHTML: attrs => {
                            if (!attrs.backgroundColor) {
                                return {};
                            }

                            return {
                                style: `background-color: ${attrs.backgroundColor}`,
                            };
                        },
                    },
                },
            },
        ];
    },
});
