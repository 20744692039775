import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import AutoSizer from 'react-virtualized-auto-sizer';
import treeUtils from 'lib/util/tree.util';
import { useCombinedRefs, useDebounceValue } from 'lib/hooks';
import Tree from '../infinite-tree/tree';
import { useAutoNavigate, useLocationTree } from 'components/trees/utils';
import Loading from 'uikit/loading';
import Icon, { Icons } from 'uikit/icon';
import cs from './scripting-tree.module.scss';
import api from 'api';
import { AdaptiveLink } from 'containers/adaptive-link/adaptive-link';
import { getSwitchedLayout } from 'lib/util/language.util';

const toggleTreeNodes = ({ tree }, collapse) => {
    function toggleNode(node) {
        node.children.forEach(toggleNode);
        if ((!collapse && !node.state.open) || (collapse && node.state.open)) {
            tree.toggleNode(node, { silent: true });
        }
    }

    if (tree) {
        for (let node of tree.nodes) {
            toggleNode(node);
        }
    }
};

const ScriptingTree = ({ forwardedRef, onNavigate, scrollToNode, getNodeLink, isReload, ...props }) => {
    const { loading, tree } = useLocationTree({
        open: true,
        withScripts: true,
        withSections: true,
        withArticles: false,
        fetchTreeFunc: api.project.getUsersDocumentTree,
        isReload,
    });

    const treeRef = useCombinedRefs(forwardedRef);
    const [query, setQuery] = useState('');

    const [debouncedValue] = useDebounceValue(query.trim().replace(/\s{2,}/g, ' '), 300);
    const [idsToOpen, setIdsToOpen] = useState([]);

    useAutoNavigate(idsToOpen, treeRef);

    // traverse tree and consistently find nodes which are path to the 'scrollToNode'
    useEffect(() => {
        let handle;

        function findIdsToOpen() {
            if (handle > 0) {
                clearTimeout(handle);
            }

            if (!treeRef.current?.tree) {
                handle = setTimeout(findIdsToOpen, 500);
            } else {
                if (!scrollToNode) {
                    return;
                }

                const tree_ = treeRef.current?.tree;
                const target = tree_.getNodeById(scrollToNode);

                if (target) {
                    const path = treeUtils.getPathIdsTo(target);
                    setIdsToOpen(path); // <------
                } else {
                    // so as nodes were not have loaded yet
                    handle = setTimeout(findIdsToOpen, 500);
                }
            }
        }

        findIdsToOpen();

        return () => {
            if (handle) {
                clearTimeout(handle);
            }
        };
    }, [scrollToNode, treeRef]);

    const expandAll = () => {
        toggleTreeNodes(treeRef.current, false);
    };

    const collapseAll = () => {
        toggleTreeNodes(treeRef.current, true);
    };

    useEffect(() => {
        if (treeRef?.current?.tree) {
            const { tree } = treeRef.current;
            tree.filter(function (node) {
                const name = node.name.toLowerCase();
                const value = debouncedValue.toLowerCase().trim();
                return name.includes(value) || name.includes(getSwitchedLayout(value));
            });
            toggleTreeNodes(treeRef.current, debouncedValue.trim().length === 0);
        }
    }, [debouncedValue, treeRef]);

    return (
        <div id={'tree-container'} className={cs.tree}>
            {!loading && tree?.length ? (
                <>
                    <div className={cs.search}>
                        <input type="text" placeholder="Поиск по дереву" value={query} onChange={e => setQuery(e.target.value)} />
                    </div>
                    <div className={cs.toolbar}>
                        {loading && <Loading withOverlay={false} small active={loading} />}
                        {!loading && (
                            <>
                                <div className={cs.btn} onClick={collapseAll}>
                                    <Icon type={Icons.CHEVRONS_UP} width={6.77} />
                                    Свернуть всё
                                </div>
                                <div className={cs.btn} onClick={expandAll}>
                                    <Icon type={Icons.CHEVRON_DOWN} width={6.77} />
                                    Развернуть всё
                                </div>
                            </>
                        )}
                    </div>
                    {!loading && (
                        <AdaptiveLink
                            link={'/scripting'}
                            onClick={() => {
                                treeRef.current.tree.selectNode(null);
                            }}
                        >
                            <button className={classNames(cs.allBtn, !scrollToNode ? cs.allBtnActive : '')}>
                                <Icon type={Icons.LIST} />
                                Все проекты
                            </button>
                        </AdaptiveLink>
                    )}
                    <div className={cs.sizerContainer}>
                        <AutoSizer>
                            {({ width, height }) => {
                                return (
                                    <Tree
                                        width={width}
                                        height={height}
                                        data={tree}
                                        onUpdate={onNavigate}
                                        autoOpen={false}
                                        ref={treeRef}
                                        getNodeLink={getNodeLink}
                                        scrollToNode={idsToOpen}
                                        highlight={[debouncedValue, getSwitchedLayout(debouncedValue)]}
                                        {...props}
                                    />
                                );
                            }}
                        </AutoSizer>
                    </div>
                </>
            ) : loading ? (
                <Loading withOverlay={false} small active={loading} />
            ) : (
                <div className={cs.noTree}>
                    <Icon type={Icons.TEXT_ALIGN_LEFT} width={24} height={24} />
                    <div>Дерево пустое</div>
                </div>
            )}
        </div>
    );
};

export default React.forwardRef((props, ref) => <ScriptingTree {...props} forwardedRef={ref} />);
