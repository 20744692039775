import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useMessage } from 'lib/hooks';
import DelayedPublicationsTabArticles from 'app/user-profile/delayed-publications-page/delayed-publications-page-tab-articles';
import cx from './delayed-publications-page.module.scss';
import DelayedPublicationsTabNews from 'app/user-profile/delayed-publications-page/delayed-publications-page-tab-news';
import api from 'api';
import { RouteGroup, Route, useNavigate, useLocation, useParams, setDocumentTitle } from 'shared/router';

const DelayedPublicationsPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const params = useParams();
    const { addError } = useMessage();

    const [tab, setActiveTab] = useState(null);
    const [documentsCounts, setDocumentsCounts] = useState({ articleCount: 0, newsCount: 0 });

    const propTab = params['*'];

    const onTabChange = (tab) => {
        navigate('/user/delayed-publications' + (tab ? '/' + tab : ''));
        setActiveTab(tab);
    };

    const getTabCounts = useCallback(async () => {
        try {
            const articlesCount = await api.article.getDelayedArticlesCount();
            const newsCount = await api.news.getDelayedNewsCount();
            setDocumentsCounts({articleCount: articlesCount, newsCount: newsCount});
        } catch (e) {
            console.log(e);
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
        }
    }, [addError]);

    useEffect(() => {
        setActiveTab('articles');
        getTabCounts();
    }, [getTabCounts]);

    useEffect(() => {
        setActiveTab(propTab);
    }, [location.pathname, propTab]);

    useEffect(() => {
        setDocumentTitle('Отложенные публикации — KMS Gran');
    }, []);

    return (
        <div className={cx.content}>
            <div className={cx.title}>Отложенная публикация</div>
            <ul className={cx.tabs}>
                <li className={classNames(cx.tab, { [cx.active]: tab === 'articles' })}
                    onClick={() => onTabChange('articles')}>
                    Статьи
                    <div className={cx.count}>{documentsCounts.articleCount.count}</div>
                </li>
                <li className={classNames(cx.tab, { [cx.active]: tab === 'news' })}
                    onClick={() => onTabChange('news')}>
                    Новости
                    <div className={cx.count}>{documentsCounts.newsCount.count}</div>
                </li>
            </ul>
            <div className={cx.tableWrapper}>
                <RouteGroup className={cx.router}>
                    <Route
                        to="articles"
                        component={<DelayedPublicationsTabArticles
                            onCountChangeHandler={getTabCounts}
                            totalCount={documentsCounts.articleCount}
                        />}
                    />
                    <Route
                        to="news"
                        component={<DelayedPublicationsTabNews
                            onCountChangeHandler={getTabCounts}
                            totalCount={documentsCounts.newsCount}
                        />}
                    />
                </RouteGroup>
            </div>
        </div>
    );
};

export default DelayedPublicationsPage;
