import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSession, useGlobalContext } from 'lib/hooks';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import { selectSession } from 'slice/authSlice';
import { MenuWrapper } from 'containers/menu-wrapper';
import { SplashScreen } from 'containers/splash-screen';
import { SideTreeWrapper } from 'containers/side-tree-wrapper';
import NoAuthorizedPage from 'components/no-authorized';
import MultiplyErrorPage from 'components/multiply-error';
import { AddPage } from 'app/add-page';
import { AddProjectPage } from 'app/add-page/add-project-page';
import { AddSectionPage } from 'app/add-page/add-section-page';
import { AddArticlePage } from 'app/add-page/add-article-page';
import { AddNewsPage } from 'app/add-page/add-news-page';
import { LoginPage, LoginLicensePage } from 'app/auth';
import { HomePage } from 'app/home';
import { ProjectsExamplePage, ArchivePage } from 'app/projects';
import { Users } from 'app/users';
import { AddUserPage } from 'app/add-page/add-user-page';
import { ActionsLog } from 'app/action-log';
import { EditArticlePage } from 'app/article/pages/edit-article-page';
import EditUserPage from 'app/edit-user/pages/edit-user-page';
import EditGroupPage from 'app/edit-group/edit-group-page';
import { UserProfilePage } from 'app/user-profile';
import { FilesPage } from 'app/files';
import { NewsPage } from 'app/news';
import { SearchResultPage } from 'app/search-result';
import ArticlePosting from 'app/article/pages/article-posting';
import NewsPosting from 'app/news/pages/news-posting';
import AddFilePage from 'app/add-page/add-file-page/add-file-page';
import { EditProjectPage } from 'app/projects';
import DraftsPage from 'app/user-profile/drafts-page/drafts-page';
import FavoritesPage from 'app/user-profile/favorites-page/favorites-page';
import GlobalSettingsPage from 'app/global-settings/global-settings-page';
import { CompaniesPage } from 'app/global-settings/companies-page';
import { ManagersPage } from 'app/global-settings/managers-page';
import { PositionsPage } from 'app/global-settings/positions-page';
import GlossaryPage from 'app/global-settings/glossary-page/glossary-page';
import { RolesPage } from 'app/global-settings/roles-page';
import { IntegrationsPage, IntegrationsNaumenPage, Integrations1CBitrixPage } from 'app/global-settings/integrations-page';
import { AboutPage } from 'app/global-settings/about-page';
import { ChatPage } from 'app/chat';
import { EditSectionPage } from 'app/section';
import EditNewsPage from 'app/news/pages/edit-news-page/edit-news-page';
import { AddGroupPage } from 'app/add-page/add-group-page';
import StatisticWrapperPage from 'app/statistic/pages/statistic-wrapper-page';
import CustomRoles from 'app/global-settings/custom-roles/custom-roles';
import CustomRoleForm from 'app/global-settings/custom-roles/custom-role-form';
import CustomRolesList from 'app/global-settings/custom-roles/custom-roles-list';
import UserPage from 'app/users/user-page/user-page';
import ProfilePageEdit from 'app/user-profile/profile-page-edit/profile-page-edit';
import ProfilePage from 'app/user-profile/profile-page/profile-page';
import { TrainingPage } from 'app/training';
import CreateScript from 'app/scripting/pages/create-script/create-script';
import ScriptingPage from 'app/scripting/pages/scripting-page/scripting-page';
import TrainingTestStart from 'components/training/training-test/training-test-start/training-test-start';
import TrainingTestQuestion from 'components/training/training-test/training-test-question/training-test-question';
import TrainingTestEnd from 'components/training/training-test/training-test-end/training-test-end';
import ContentManagementNewsPage from 'app/news/pages/content-management-news-page';
import ContentManagementProjectPage from 'app/projects/pages/content-management-project-page';
import ContentManagementSectionPage from 'app/section/content-management-section-page';
import ContentManagementArticlePage from 'app/article/pages/content-management-acticle-page';
import ImportUsersPage from 'app/import-users';
import DelayedPublicationsPage from 'app/user-profile/delayed-publications-page/delayed-publications-page';
import ErrorBoundary from './error-boundary';
import LicensePage from 'app/global-settings/license-page/LicensePage';
import ScriptPosting from './scripting/pages/script-posting/script-posting';
import ScriptsWrapper from 'containers/scripts-wrapper/scripts-wrapper';
import CompaniesPageEditCompany from './global-settings/companies-page/companies-page-edit-company';
import CompaniesPageEditOffice from './global-settings/companies-page/companies-page-edit-office';
import { TagsPage } from 'app/global-settings/tags-page/TagsPage';
import { ChatBot } from './chat-bot/chat-bot';
import { useConfiguration } from 'lib/context';
import { NotificationsProvider } from 'notifications/appllication/context';
import { RouteGroup, Route, useLocation } from 'shared/router';

export default function Routes() {
    const location = useLocation();
    const session = useSelector(selectSession);

    const { isScriptingEnabled } = useConfiguration();
    useSession();

    const { locationUrl, setLocationUrl, setPrevLocationUrl } = useGlobalContext();
    const [, setCurrentLocation] = React.useState(null);

    // Сохранение url с параметрами запроса для случаев, когда происходит переход не через вызов navigate(...)
    // Например, при открытии ссылки на статью/новость из результатов поиска в новой вкладке, когда необходима подсветка совпадений (берётся из параметров)
    useEffect(() => {
        if (locationUrl !== location.pathname && !locationUrl.includes(location.pathname)) {
            setLocationUrl(`${location.pathname}${location.search}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationUrl]);

    useEffect(() => {
        setCurrentLocation(prev => {
            setPrevLocationUrl(prev);
            return location.pathname;
        });
    }, [location, setPrevLocationUrl]);

    return (
        <ErrorBoundary>
            {!session && (
                <RouteGroup>
                    {/* <Route to="/login" component={<LoginPage />} /> */}
                    <Route to="/login-license" component={<LoginLicensePage />} />
                    <Route to="*" component={<SplashScreen />} />
                </RouteGroup>
            )}
            {session && (
                <NotificationsProvider>
                    <RouteGroup>
                        <Route to="/training/test/:id/process" component={<TrainingTestStart />} />
                        <Route to="/training/test/:testId/process/:questionId" component={<TrainingTestQuestion />} />
                        <Route to="/training/test/:id/process/end" component={<TrainingTestEnd />} />
                        <Route to="*" component={<MenuWrapper />}>
                            <Route to="*" component={<HomePage />} />
                            <Route to="add" component={<AddPage />}>
                                <Route to="project" component={<AddProjectPage />} />
                                <Route to="section" component={<AddSectionPage />} />
                                <Route to="article" component={<AddArticlePage />} />
                                <Route to="news" component={<AddNewsPage />} />
                                <Route to="user" component={<AddUserPage />} />
                                <Route to="file" component={<AddFilePage />} />
                                <Route to="group" component={<AddGroupPage />} />
                                <Route
                                    to="importUsers"
                                    component={<ImportUsersPage hasAnyAuthorities={[GLOBAL_ACTIONS.USER_CREATE]} />}
                                />
                            </Route>
                            <Route
                                to="projects"
                                component={<SideTreeWrapper multipleChoice={false} allButtonTitle="Все проекты" />}
                            >
                                <Route to="*" component={<ProjectsExamplePage />} />
                                <Route to=":uuid/:tab" component={<ProjectsExamplePage />} />
                                <Route to="section/:sectionId/:tab" component={<ProjectsExamplePage />} />
                                <Route to=":uuid/:sectionId/section/:tab" component={<ProjectsExamplePage />} />
                                <Route to="article/:id" component={<ArticlePosting />} />
                                <Route to="article/:id/history/:snapshotId" component={<ArticlePosting />} />
                                <Route to="news/:id" component={<NewsPosting />} />
                                <Route to="news/:id/history/:snapshotId" component={<NewsPosting />} />
                                <Route to="article/:uuid/edit" component={<EditArticlePage />} />
                                <Route to="project/:uuid/edit" component={<EditProjectPage />} />
                                <Route to="section/:uuid/edit" component={<EditSectionPage />} />
                                <Route to="news/:uuid/edit" component={<EditNewsPage />} />
                                <Route
                                    to="project/:uuid/content-management"
                                    component={<ContentManagementProjectPage />}
                                />
                                <Route
                                    to="section/:uuid/content-management"
                                    component={<ContentManagementSectionPage />}
                                />
                                <Route
                                    to="article/:uuid/content-management"
                                    component={<ContentManagementArticlePage />}
                                />
                                <Route
                                    to="news/:uuid/content-management"
                                    component={<ContentManagementNewsPage />}
                                />
                            </Route>
                            <Route to="users/*" component={<Users />} />
                            <Route
                                to="statistic/*"
                                component={<StatisticWrapperPage hasAnyAuthorities={[GLOBAL_ACTIONS.STATS_PAGE]} />}
                            />
                            <Route
                                to="archive"
                                component={<SideTreeWrapper
                                    multipleChoice={false}
                                    archive={true}
                                    allButtonTitle="Все проекты"
                                />}
                            >
                                <Route to="*" component={<ArchivePage />} />
                                <Route to=":tab" component={<ArchivePage />} />
                                <Route to=":projectId/:tab" component={<ArchivePage />} />
                                <Route to=":projectId/:sectionId/section/:tab" component={<ArchivePage />} />
                                <Route to="article/:id" component={<ArticlePosting archive={true} />} />
                                <Route to="article/:uuid/edit" component={<EditArticlePage />} />
                                <Route to="news/:id" component={<NewsPosting archive={true} />} />
                                <Route to="script/:scriptUuid" component={<ScriptPosting archive={true} />} />
                                <Route to="news/:uuid/edit" component={<EditNewsPage />} />
                            </Route>
                            <Route to="action-log/*" component={<ActionsLog />} />
                            <Route to="edit-user/:userLogin" component={<UserPage />} />
                            <Route
                                to="edit-user/:userLogin/edit"
                                component={<EditUserPage hasAnyAuthorities={[GLOBAL_ACTIONS.USER_EDIT]} />}
                            />
                            <Route to="edit-group/:groupId" component={<EditGroupPage />} />
                            <Route to="edit-group/:groupId/edit" component={<EditGroupPage mode="edit" />} />
                            <Route to="user" component={<UserProfilePage />}>
                                <Route to="profile" component={<ProfilePage />} />
                                <Route to="profile/edit" component={<ProfilePageEdit />} />
                                <Route to="drafts/:tab" component={<DraftsPage />} />
                                <Route to="article-draft/:uuid/:type/edit" component={<EditArticlePage />} />
                                <Route to="news-draft/:uuid/:type/edit" component={<EditNewsPage />} />
                                <Route to="article-draft/:id/:type" component={<ArticlePosting />} />
                                <Route to="script-draft/:scriptUuid/:type" component={<ScriptPosting />} />
                                <Route to="news-draft/:id/:type" component={<NewsPosting />} />
                                <Route to="favorites/*" component={<FavoritesPage />} />
                                <Route to="delayed-publications/*" component={<DelayedPublicationsPage />} />
                                <Route to="delayed-article/:uuid/:type/edit" component={<EditArticlePage />} />
                                <Route to="delayed-news/:uuid/:type/edit" component={<EditNewsPage />} />
                            </Route>
                            <Route to="global-settings" component={<GlobalSettingsPage />}>
                                <Route
                                    to="license"
                                    component={<LicensePage hasAnyAuthorities={[GLOBAL_ACTIONS.COMPANY_PAGE]} />}
                                />
                                <Route
                                    to="companies"
                                    component={<CompaniesPage hasAnyAuthorities={[GLOBAL_ACTIONS.COMPANY_PAGE]} />}
                                />
                                <Route
                                    to="company/create"
                                    component={<CompaniesPageEditCompany
                                        hasAnyAuthorities={[GLOBAL_ACTIONS.COMPANY_PAGE]}
                                    />}
                                />
                                <Route
                                    to="company/edit/:companyId"
                                    component={<CompaniesPageEditCompany
                                        hasAnyAuthorities={[GLOBAL_ACTIONS.COMPANY_PAGE]}
                                    />}
                                />
                                <Route
                                    to="office/create"
                                    component={<CompaniesPageEditOffice
                                        hasAnyAuthorities={[GLOBAL_ACTIONS.COMPANY_PAGE]}
                                    />}
                                />
                                <Route
                                    to="office/edit/:officeId"
                                    component={<CompaniesPageEditOffice
                                        hasAnyAuthorities={[GLOBAL_ACTIONS.COMPANY_PAGE]}
                                    />}
                                />
                                <Route
                                    to="managers"
                                    component={<ManagersPage hasAnyAuthorities={[GLOBAL_ACTIONS.SUPERVISOR_PAGE]} />}
                                />
                                <Route
                                    to="positions"
                                    component={<PositionsPage hasAnyAuthorities={[GLOBAL_ACTIONS.POSITION_PAGE]} />}
                                />
                                <Route to="roles" component={<RolesPage />} />
                                <Route
                                    to="integrations"
                                    component={<IntegrationsPage
                                        hasAnyAuthorities={[GLOBAL_ACTIONS.INTEGRATION_PAGE]}
                                    />}
                                />
                                <Route
                                    to="integrations/naumen"
                                    component={<IntegrationsNaumenPage
                                        hasAnyAuthorities={[GLOBAL_ACTIONS.INTEGRATION_PAGE]}
                                    />}
                                />
                                <Route
                                    to="integrations/1cbitrix"
                                    component={<Integrations1CBitrixPage
                                        hasAnyAuthorities={[GLOBAL_ACTIONS.INTEGRATION_PAGE]}
                                    />}
                                />
                                <Route to="about" component={<AboutPage />} />
                                <Route to="glossary" component={<GlossaryPage />} />
                                <Route to="custom-roles" component={<CustomRoles />}>
                                    <Route
                                        to="add"
                                        component={<CustomRoleForm
                                            hasAnyAuthorities={[GLOBAL_ACTIONS.CUSTOM_ROLE_CREATE]}
                                        />}
                                    />
                                    <Route
                                        to="role/:id"
                                        component={<CustomRoleForm
                                            hasAnyAuthorities={[GLOBAL_ACTIONS.CUSTOM_ROLE_EDIT]}
                                        />}
                                    />
                                    <Route
                                        to="*"
                                        component={<CustomRolesList
                                            hasAnyAuthorities={[GLOBAL_ACTIONS.CUSTOM_ROLE_READ]}
                                        />}
                                    />
                                </Route>
                                <Route to="tags" component={<TagsPage />} />
                            </Route>
                            <Route to="files/*" component={<FilesPage />} />
                            <Route to="news" component={<NewsPage />} />
                            <Route
                                to="news"
                                component={<SideTreeWrapper multipleChoice={false} allButtonTitle="Все проекты" />}
                            >
                                <Route to=":id" component={<NewsPosting archive={false} />} />
                                <Route to=":id/history/:snapshotId" component={<NewsPosting />} />
                                <Route to=":uuid/edit" component={<EditNewsPage />} />
                            </Route>
                            <Route to="search" component={<SearchResultPage />} />
                            <Route to="chat/*" component={<ChatPage />} />
                            <Route to="training/*" component={<TrainingPage />} />
                            {isScriptingEnabled && (
                                <>
                                    <Route to="scripting" component={<ScriptsWrapper />}>
                                        <Route to=":projectUuid" component={<ScriptingPage />} />
                                        <Route to=":projectUuid/:sectionUuid" component={<ScriptingPage />} />
                                        <Route
                                            to=":projectUuid/:sectionUuid/:scriptUuid"
                                            component={<ScriptingPage />}
                                        />
                                        <Route to="script/:scriptUuid" component={<ScriptPosting />} />
                                        <Route to="*" component={<ScriptingPage />} />
                                    </Route>
                                    <Route to="scripting/create" component={<CreateScript />} />
                                    <Route to="scripting/edit/:uuid" component={<CreateScript edit={true} />} />
                                    <Route to="scripting/edit/:uuid/:type" component={<CreateScript edit={true} />} />
                                </>
                            )}
                            {/* <Route to="401" component={<NoAuthorizedPage />} /> */}
                            {/* <Route to="multiply" component={<MultiplyErrorPage />} /> */}
                            <Route to="add/script" component={<CreateScript />} />
                            <Route to="ai" component={<ChatBot />} />
                        </Route>
                    </RouteGroup>
                </NotificationsProvider>
            )}
        </ErrorBoundary>
    );
}
