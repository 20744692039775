import React, { useCallback } from 'react';
import { findParentNodeClosestToPos } from '@tiptap/core';
import { useEditorContext } from '../../context';
import Icon, { Icons } from 'uikit/icon';
import { BubbleExtensions, isBubbleExtensionActive } from '../../utils/isBubbleExtensionActive';
import { isEmpty } from 'lodash';

export const BubbleMenuTableBottomMenu = ({ isActive, setCurrentMenu }) => {
    const editor = useEditorContext();
    const state = editor?.state;
    const view = editor?.view;

    const addRowAfter = e => {
        e.preventDefault();
        e.stopPropagation();

        setCurrentMenu(undefined);
        editor?.chain().addRowToEnd().run();
    };

    const getItemPosition = useCallback(() => {
        if (!isActive) {
            return {};
        }



        let nearestParent = state?.selection.$anchor;

        if (state?.selection.node?.type.name !== 'table') {
            nearestParent = findParentNodeClosestToPos(nearestParent, node => {
                return node.type.name === 'table';
            });
        } else {
            nearestParent = {pos: state.selection.$from.pos}
        }

        if (!nearestParent) {
            return {};
        }

        const wrapperDomNode = view?.nodeDOM(nearestParent.pos);

        if (!wrapperDomNode) {
            return {};
        }

        const editorBoundingClientRect = document.getElementById('editor-content')?.getBoundingClientRect();
        const table = wrapperDomNode.querySelector('table')?.getBoundingClientRect();
        const tableWrapper = wrapperDomNode.querySelector('.tableOuterWrapper')?.getBoundingClientRect();

        if (!editorBoundingClientRect || !table || !tableWrapper) {
            return;
        }

        const width = table.width > tableWrapper.width ? tableWrapper.width : table.width;
        const height = table.height > tableWrapper.height ? tableWrapper.height : table.height;

        return {
            position: 'absolute',
            top: table.top - editorBoundingClientRect.top + height + 6 + 'px',
            left: table.left - editorBoundingClientRect.left + width / 2 - 8 + 'px',
        };
    }, [isActive, state, view]);

    return (
        <div
            style={{
                display: !isEmpty(getItemPosition()) && isBubbleExtensionActive(editor?.state.selection, BubbleExtensions.TABLE) ? 'flex' : 'none',
                alignItems: 'center',
                cursor: 'pointer',
                ...getItemPosition(),
            }}
        >
            <Icon type={Icons.EditorIconTableAdd} width={16} height={16} onClick={addRowAfter} />
        </div>
    );
};
