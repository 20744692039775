import React from 'react';
import { createPortal } from 'react-dom';
import { BubbleMenu } from '@tiptap/react';
import { findParentNodeClosestToPos, posToDOMRect } from '@tiptap/core';
import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';
import { MenuButton } from '../Menu/MenuButton';
import { LinkModal } from '../Menu/MenuButtonLink';
import { FileViewDialog } from 'components/file/file-view-dialog';
import { useMessage } from 'lib/hooks';

import cx from '../../editor.module.scss';

export const BubbleMenuLink = ({ setCurrentMenu }) => {
    const editor = useEditorContext();
    const {addError} = useMessage();

    const [isEdit, setIsEdit] = React.useState(false);
    const [linkData, setLinkData] = React.useState({ linkUrl: '', linkName: '' });

    const [selectedFile, setSelectedFile] = React.useState(null);
    const [isFileViewOpen, setIsFileViewOpen] = React.useState(false);

    const toggleEdit = () => {
        if (isEdit) {
            setIsEdit(false);
        } else {
            const link = editor?.getAttributes('link');
            editor.commands.extendMarkRange('link');

            const { from, to } = editor.state.selection;
            const text = editor.state.doc.textBetween(from, to);

            setLinkData({ linkUrl: link.href.toString(), linkName: text, fileId: link['data-file-id'] });
            setIsEdit(true);
        }
    };

    const openFileModal = fileId => {
        if (!editor?.storage.fileStorage.files.some(f => f.fileId === fileId || f?.serverData?.id === fileId)) {
            addError('Файл удалён из документа')
            return;
        }

        setSelectedFile({ fileId });
        setIsFileViewOpen(true);
    };

    const onFileViewModalClose = () => {
        setSelectedFile(null);
        setIsFileViewOpen(false);
    };

    const navigateLink = () => {
        const link = editor?.getAttributes('link');

        if (link['data-file-id']) {
            openFileModal(link['data-file-id']);
            return;
        }
        if (link.href?.toString()?.includes('#')) {
            const editorContent = document.querySelector('.editor-content');
            const anchors = document.querySelectorAll('[data-type="anchor"]');

            for (let i = 0; i < anchors.length; i++) {
                if (anchors[i].getAttribute('id') === link.href.toString().slice(1)
                    || anchors[i].getAttribute('name') === link.href.toString().slice(1)
                ) {
                    const editorRect = editorContent.getBoundingClientRect();
                    const anchorRect = anchors[i].getBoundingClientRect();

                    editorContent.scrollTo({
                        top: editorContent.scrollTop + anchorRect.top - editorRect.top - 5,
                        behavior: 'smooth',
                    });

                    editor.commands.blur();
                    break;
                }
            }
        } else {
            window.open(link.href.toString(), '_blank');
        }
    };

    const handleSubmit = (name, url, fileId) => {
        editor.commands.updateLink(name, { href: url, 'data-file-id': fileId });

        setIsEdit(false);
    };

    return (
        <>
            <BubbleMenu
                tippyOptions={{
                    placement: 'bottom',
                    arrow: false,
                    getReferenceClientRect: () => {
                        const nearestParent = findParentNodeClosestToPos(editor?.state.selection.$anchor, node => {
                            return node.type.name === 'link';
                        });

                        if (nearestParent) {
                            const wrapperDomNode = editor?.view.nodeDOM(nearestParent.pos);

                            if (wrapperDomNode) {
                                return wrapperDomNode.getBoundingClientRect();
                            }
                        }
                        const { ranges } = editor?.state.selection;
                        const from = Math.min(...ranges.map(range => range.$from.pos));
                        const to = Math.max(...ranges.map(range => range.$to.pos));
                        return posToDOMRect(editor?.view, from, to);
                    },
                }}
                editor={editor}
                shouldShow={({ editor }) => editor.isActive('link')}
            >
                <div className={cx.bubbleMenu}>
                    <MenuButton
                        className={cx.bubbleMenuButton}
                        icon={Icons.EditorIconEdit}
                        onClick={e => {
                            setCurrentMenu(undefined);
                            toggleEdit(e);
                        }}
                    />
                    <MenuButton
                        className={cx.bubbleMenuButton}
                        icon={Icons.EditorIconLinkUnset}
                        onClick={() => {
                            setCurrentMenu(undefined);
                            editor?.chain().focus().extendMarkRange('link').unsetLink().run();
                        }}
                    />
                    <MenuButton
                        className={cx.bubbleMenuButton}
                        icon={Icons.EditorIconLinkOpen}
                        onClick={e => {
                            setCurrentMenu(undefined);
                            navigateLink(e);
                        }}
                    />
                    <div style={{ height: 17, width: 1, borderRight: '1px solid rgba(39, 155, 217, 0.15)' }}></div>
                    <MenuButton
                        className={cx.bubbleMenuButton}
                        icon={Icons.EditorIconTrash}
                        onClick={() => {
                            setCurrentMenu(undefined);
                            editor.chain().focus().extendMarkRange('link').unsetLink().deleteSelection().run();
                        }}
                    />
                </div>
                {isEdit && <LinkModal data={linkData} show={isEdit} onClose={toggleEdit} onSubmit={handleSubmit} />}
                {isFileViewOpen &&
                    createPortal(
                        <FileViewDialog file={selectedFile} isNoActions={true} handleClose={onFileViewModalClose} />,
                        document.body
                    )}
            </BubbleMenu>
        </>
    );
};
