import BulletList from '@tiptap/extension-bullet-list';
import { MULTI_LIST_OPTIONS } from '../../constants';

import { findKey } from 'lodash';

export const CustomBulletList = BulletList.extend({
    content: 'listItem*',
    // content: '(listItem|multiListItem?)*',

    addAttributes() {
        return {
            ...this.parent?.(),
            style: {
                default: null,
                parseHTML: element => element.getAttribute('style'),
                renderHTML: attributes => {
                    if (!attributes.style) {
                        return {};
                    }

                    return {
                        style: attributes.style
                    };
                },
            },
            listStyleType: {
                default: null,
                parseHTML: el => el.style.listStyleType,
                renderHTML: attrs => {
                    return (attrs.listStyleType ? { style: `list-style-type: ${attrs.listStyleType}` } : {});
                },
            },
            'data-type': {
                default: 'bullet-list',
            },
            'data-list-type': {
                default: null,
                parseHTML: el => {
                    return el.getAttribute('data-list-type') || findKey(MULTI_LIST_OPTIONS, o => o === el.style.listStyleType);
                },
                renderHTML: attrs => {
                    if (!attrs['data-list-type']) {
                        return {};
                    }

                    return {
                        style: `list-style-type: ${MULTI_LIST_OPTIONS[attrs['data-list-type']]} !important`,
                    };
                },
            },
            'data-level': {
                default: null,
            },
            'data-padding': {
                default: 1,
                parseHTML: el => el.getAttribute('data-padding') || parseFloat(el.style.paddingLeft) / 16,
                renderHTML: attrs => {
                    if (!attrs['data-padding']) {
                        return {};
                    }

                    return {
                        style: `padding-left: ${attrs['data-padding'] * 16}px`,
                    };
                },
            },
        };
    },

    addInputRules() {
        return [];
    },
});
