import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';

import { useEditorContext } from '../../context';
import { useControlledInputOnChangeCursorFix } from 'lib/hooks';
import { Icons } from 'uikit/icon';
import Input from 'uikit/input';
import { MenuButton } from '../Menu/MenuButton';
import { DropdownMenuButton } from '../Menu/DropdownMenuButton';
import { ImageLinkModal } from '../Modals/ImageLinkModal';
import { useImagePreviewModal, ImagePreviewModal } from 'components/ImagePreviewModal/index';

import cx from '../../editor.module.scss';
import cs from './BubbleMenuImage.module.scss';

const propsEqualCompare = (prevProps, nextProps) => {
    if (prevProps.selection?.node?.type.name !== 'resizableImage' && nextProps.selection?.node?.type.name !== 'resizableImage') return true;

    if (prevProps.isActive !== nextProps.isActive) return false;
    if (prevProps.selection.from !== nextProps.selection.from) return false;
    if (prevProps.selection.node?.type?.name !== nextProps.selection.node?.type?.name) return false;
    if (prevProps.selection.node.attrs !== nextProps.selection.node.attrs) return false;
    if (prevProps.setCurrentMenu !== nextProps.setCurrentMenu) return false;

    return true;
};

export const BubbleMenuImage = React.memo(({ isActive, selection, setCurrentMenu }) => {
    const editor = useEditorContext();
    const imgAttrs = editor?.getAttributes('resizableImage');

    const [menuPosition, setMenuPosition] = useState({});

    const [linkModalShow, setLinkModalShow] = useState(false);

    const [imgNodePos, setImgNodePos] = useState(null);
    const sizeInputRef = useRef(null);
    const [sizeInputFocus, setSizeInputFocus] = useState(false);
    const [imageSize, setImageSize] = useState(null);
    const [imageNaturalSize, setImageNaturalSize] = useState(null);

    const [alignPosition, setAlignPosition] = useState(null);
    const [floatPosition, setFloatPosition] = useState(null);

    const {
        openSrcModal,
        isOpen,
        setIsOpen,
        imageRef,
        imageSrc,
        setImageSrc,
        imageLoading,
        setImageLoading,
        imageZoomInitial,
        imageZoomLimit,
        imageDownload,
        downloadAction
    } = useImagePreviewModal();

    const isCustomSize = useMemo(() => {
        return Number(imgAttrs?.width) !== Number(imageNaturalSize?.width);
    }, [imgAttrs, imageNaturalSize]);

    const onInitPositions = useCallback(() => {
        setAlignPosition(!imgAttrs?.['data-float'] ? imgAttrs?.['data-align'] : null);
        setFloatPosition(imgAttrs?.['data-float']);
    }, [imgAttrs]);

    useEffect(() => {
        const dom = editor?.view.domAtPos(editor?.state.selection.from);

        const img = dom?.node?.nodeType !== 3 ? dom?.node?.querySelector('img') : null;

        if (img) {
            setImageSrc(img.src);
            setImageNaturalSize({ width: img.naturalWidth, height: img.naturalHeight });
        }
    }, [editor, editor?.state.selection, setImageSrc]);

    useEffect(() => {
        onInitPositions();
    }, [onInitPositions]);

    const toggleLinkModal = () => {
        setCurrentMenu(undefined);
        setLinkModalShow(show => !show);
    };

    const onAlignChangeHandler = position => {
        setAlignPosition(position);
        setFloatPosition(null);
        editor
            ?.chain()
            .focus()
            .updateAttributes('resizableImage', { 'data-align': position, 'data-float': null })
            .setNodeSelection(selection.$from.pos)
            .run();
        setMenuPosition(getItemPosition(isActive, editor, selection));
    };

    const applyImgSize = useCallback(
        imageSize => {
            if (imgNodePos) {
                const node = editor.state.doc.nodeAt(imgNodePos);

                if (node?.type.name === 'resizableImage') {
                    editor.commands.resizeImage({ width: Number(imageSize) }, imgNodePos);
                    sizeInputRef.current.style.width = Math.max(String(imageSize).length * 1.9, 6) + 'ch';
                }
            }
        },
        [editor, imgNodePos]
    );

    const changeInputSizeFocus = useCallback(
        isFocus => {
            if (!isFocus && +imageSize < 16) {
                setImageSize(16);
                applyImgSize(16);
            }
            setSizeInputFocus(isFocus);
        },
        [imageSize, applyImgSize]
    );

    const closeMenu = useCallback(
        event => {
            if (sizeInputRef.current && !sizeInputRef.current.contains(event.target)) {
                setImageSize(prevSize => {
                    if (+prevSize > 16) {
                        applyImgSize(+prevSize);
                    }
                });
                changeInputSizeFocus(false);
                document.removeEventListener('click', closeMenu);
            }
        },
        [changeInputSizeFocus, applyImgSize]
    );

    const onSizePlaceholderClick = () => {
        setImageSize(editor?.getAttributes('resizableImage')?.width);
        changeInputSizeFocus(true);
    };

    const onSizeChange = useControlledInputOnChangeCursorFix(
        useCallback(width => {
            width = width.replace(/\D+/g, '');

            setImageSize(width);
            if (width < 16) {
                return;
            }
        }, [])
    );

    const onSizeSubmit = useCallback(
        e => {
            if (e.keyCode === 13) {
                applyImgSize(imageSize);
                changeInputSizeFocus(false);
                document.removeEventListener('click', closeMenu);
            }
        },
        [closeMenu, imageSize, applyImgSize, changeInputSizeFocus]
    );

    const setDefaultSize = () => {
        const node = editor?.view.nodeDOM(editor.state.selection.from);
        const editorRect = document.getElementById('editor-content').getBoundingClientRect();

        const isImgBigThenContentArea = Number(node.querySelector('img')?.naturalWidth) > editorRect.width;

        editor
            ?.chain()
            .focus()
            .resizeImage({
                width: Number(node.querySelector('img')?.naturalWidth),
                'data-align': isImgBigThenContentArea && imgAttrs?.['data-align'] === 'center' ? 'left' : imgAttrs?.['data-align'],
            })
            .setNodeSelection(selection.$from.pos)
            .run();
    };

    const onFloatChange = pos => {
        setFloatPosition(pos);
        setAlignPosition(null);

        editor
            ?.chain()
            .focus()
            .updateAttributes('resizableImage', { 'data-align': null, 'data-float': pos })
            .setNodeSelection(selection.$from.pos)
            .run();
    };

    const handleSubmit = ({ url }) => {
        editor?.commands.updateAttributes('resizableImage', { 'data-link': url });
        toggleLinkModal();
    };

    const removeImage = () => {
        setCurrentMenu(undefined);

        let { tr, selection, schema } = editor?.view.state;
        const from = Math.min(...selection.ranges.map(range => range.$from.pos));
        const to = Math.max(...selection.ranges.map(range => range.$to.pos));
        const type = schema.nodes['paragraph'];
        tr = tr.replaceWith(from, to, type.create());

        editor.view.dispatch(tr);
    };

    const getItemPosition = useCallback(() => {
        if (!isActive || !editor) {
            return {};
        }

        if (selection.node?.type?.name !== 'resizableImage') {
            return {};
        }

        const wrapperDomNode = editor?.view.nodeDOM(selection.$from.pos);

        if (!wrapperDomNode) {
            return {};
        }

        const editorRect = document.getElementById('editor-content').getBoundingClientRect();
        const imgRect = wrapperDomNode.firstChild.querySelector('img').getBoundingClientRect();
        const bubbleMenuRect = document.getElementById('editorBubbleMenuImage').getBoundingClientRect();
        const contentLeftPos = imgRect.left - editorRect.left;
        const contentRightPos = editorRect.right - imgRect.right;
        const halfImgWidth = imgRect.width / 2;
        const halfBubbleMenuWidth = bubbleMenuRect.width / 2;
        const top = imgRect.top - editorRect.top + imgRect.height + 6 + 'px';

        if (imgRect.width > bubbleMenuRect.width) {
            return {
                top,
                left: contentLeftPos + halfImgWidth - halfBubbleMenuWidth,
            };
        }

        if (contentLeftPos + halfImgWidth - halfBubbleMenuWidth <= 30) {
            return {
                top,
                left: contentLeftPos,
            };
        }

        if (contentRightPos + halfImgWidth - halfBubbleMenuWidth <= 30) {
            return {
                top,
                right: contentRightPos,
            };
        }

        return {
            top,
            left: contentLeftPos + halfImgWidth - halfBubbleMenuWidth,
        };
    }, [selection, editor, isActive]);

    useEffect(() => {
        if (isActive) {
            setMenuPosition(getItemPosition());
        }
    }, [isActive, getItemPosition]);

    useEffect(() => {
        if (isActive) {
            setImgNodePos(selection.from);
        }
    }, [isActive, selection]);

    useEffect(() => {
        if (imageSize === undefined && selection?.node?.type.name === 'resizableImage' && editor?.getAttributes('resizableImage')?.width) {
            setImageSize(editor?.getAttributes('resizableImage')?.width);
        }
    }, [editor, imageSize, selection]);

    useEffect(() => {
        if (sizeInputFocus) {
            sizeInputRef.current.style.width = `${Math.max(String(sizeInputRef.current.value).length * 1.9, 6)}ch`;
            document.addEventListener('click', closeMenu);
        }

        return () => {
            document.removeEventListener('click', closeMenu);
        };
    }, [editor, sizeInputFocus, closeMenu, applyImgSize, changeInputSizeFocus]);

    return (
        <div
            id="editorBubbleMenuImage"
            className={cx.bubbleMenu}
            style={{
                position: 'absolute',
                display: 'flex',
                opacity: menuPosition.top && isActive ? 1 : 0,
                pointerEvents: menuPosition.top && isActive ? 'initial' : 'none',
                boxShadow: '0 8px 20px rgba(0, 0, 0, 0.12)',
                alignItems: 'center',
                cursor: 'pointer',
                ...menuPosition,
            }}
        >
            <div className={cs.mobileWrapper}>
                {!sizeInputFocus && (
                    <Tippy content="Установить ширину картинки, высота изменится пропорционально" placement="top">
                        <div
                            className={classNames(cs.inputPlaceholder, {
                                [cs.active]: isCustomSize,
                            })}
                            onClick={onSizePlaceholderClick}
                        >
                            {imgAttrs?.width}px
                        </div>
                    </Tippy>
                )}
                {sizeInputFocus && (
                    <Input ref={sizeInputRef} className={cs.input} value={imageSize} onChange={onSizeChange} onKeyDown={onSizeSubmit} />
                )}
                <div style={{ height: 17, width: 1, borderRight: '1px solid rgba(39, 155, 217, 0.15)' }}></div>
                <MenuButton
                    tooltip="Установить исходный размер картинки"
                    className={cx.bubbleMenuButton}
                    icon={Icons.EditorIconDefaultSize}
                    hover
                    selected={!isCustomSize}
                    onClick={setDefaultSize}
                />
                <div style={{ height: 17, width: 1, borderRight: '1px solid rgba(39, 155, 217, 0.15)' }}></div>
                <DropdownMenuButton
                    content={[
                        { id: 'left', icon: Icons.EditorIconFloatLeft, tooltip: 'В тексте слева' },
                        { id: 'inline', icon: Icons.EditorIconFloatCenter, tooltip: 'Картинка в тексте' },
                        { id: 'right', icon: Icons.EditorIconFloatRight, tooltip: 'В тексте справа' },
                    ]}
                    inline
                    tooltip="Картинка в тексте"
                    button={
                        <MenuButton
                            className={cx.bubbleMenuButton}
                            icon={Icons.EditorIconFloatCenter}
                            onClick={() => onFloatChange('inline')}
                        />
                    }
                    onChange={onFloatChange}
                    selected={floatPosition}
                />
                <div style={{ height: 17, width: 1, borderRight: '1px solid rgba(39, 155, 217, 0.15)' }}></div>
            </div>
            <div className={cs.mobileWrapper}>
                <DropdownMenuButton
                    content={[
                        { id: 'left', icon: Icons.EditorIconBubbleAlignLeft, tooltip: 'Слева' },
                        { id: 'center', icon: Icons.EditorIconBubbleAlignCenter, tooltip: 'По центру' },
                        { id: 'right', icon: Icons.EditorIconBubbleAlignRight, tooltip: 'Справа' },
                    ]}
                    inline
                    tooltip="Картинка отдельный элемент"
                    button={
                        <MenuButton
                            className={cx.bubbleMenuButton}
                            icon={Icons.EditorIconBubbleAlignCenter}
                            onClick={() => onAlignChangeHandler('center')}
                        />
                    }
                    onChange={onAlignChangeHandler}
                    selected={alignPosition}
                />
                <div style={{ height: 17, width: 1, borderRight: '1px solid rgba(39, 155, 217, 0.15)' }}></div>
                <MenuButton
                    tooltip="Прикрепить ссылку"
                    className={cx.bubbleMenuButton}
                    icon={Icons.EditorIconLinkAttach}
                    hover
                    onClick={toggleLinkModal}
                    selected={imgAttrs?.['data-link']}
                />
                <MenuButton
                    tooltip="На весь экран"
                    className={cx.bubbleMenuButton}
                    hover
                    icon={Icons.EditorIconExpand}
                    onClick={() => {
                        setCurrentMenu(undefined);
                        openSrcModal();
                    }}
                />
                <div style={{ height: 17, width: 1, borderRight: '1px solid rgba(39, 155, 217, 0.15)' }}></div>
                <MenuButton tooltip="Удалить" className={cx.bubbleMenuButton} hover icon={Icons.EditorIconTrash} onClick={removeImage} />
            </div>
            {
                isOpen && (
                    <ImagePreviewModal
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        imageRef={imageRef}
                        imageSrc={imageSrc}
                        imageLoading={imageLoading}
                        setImageLoading={setImageLoading}
                        imageZoomInitial={imageZoomInitial}
                        imageZoomLimit={imageZoomLimit}
                        imageDownload={imageDownload}
                        downloadAction={downloadAction}
                    />
                )
            }
            {linkModalShow && (
                <ImageLinkModal
                    show={linkModalShow}
                    data={{ linkUrl: editor?.getAttributes('resizableImage')?.['data-link'] }}
                    onSubmit={handleSubmit}
                    onClose={toggleLinkModal}
                />
            )}
        </div>
    );
}, propsEqualCompare);
