import React, { useEffect } from 'react';
import classNames from 'classnames';
import { SubMenu } from './SubMenu';
import { MenuTable } from './MenuTable';
import { MenuHeading } from './MenuHeading';
import { MenuFontSize } from './MenuFontSize';
import { MenuFontFamily } from './MenuFontFamily';
import { MenuButtonBold } from './MenuButtonBold';
import { MenuButtonUndo } from './MenuButtonUndo';
import { MenuButtonRedo } from './MenuButtonRedo';
import { MenuButtonList } from './MenuButtonList';
import { MenuButtonLink } from './MenuButtonLink';
import { MenuButtonAlign } from './MenuButtonAlign';
import { MenuButtonImage } from './MenuButtonImage';
import { MenuButtonIndex } from './MenuButtonIndex';
import { MenuButtonEmoji } from './MenuButtonEmoji';
import { MenuButtonVideo } from './MenuButtonVideo';
import { MenuButtonItalic } from './MenuButtonItalic';
import { MenuButtonFormat } from './MenuButtonFormat';
import { MenuButtonAnchor } from './MenuButtonAnchor';
import { MenuButtonDiagram } from './MenuButtonDiagram';
import { MenuButtonYoutube } from './MenuButtonYoutube';
import { MenuButtonDetails } from './MenuButtonDetails';
import { MenuButtonPageCode } from './MenuButtonPageCode';
import { MenuButtonTemplate } from './MenuButtonTemplate';
import { MenuButtonSelectAll } from './MenuButtonSelectAll';
import { MenuButtonColorText } from './MenuButtonColorText';
import { MenuButtonSubscript } from './MenuButtonSubscript';
import { MenuButtonUnderline } from './MenuButtonUnderline';
import { MenuButtonGoogleDoc } from './MenuButtonGoogleDoc';
import { MenuListTableButton } from './MenuListTableButton';
import { MenuButtonCodeBlock } from './MenuButtonCodeBlock';
import { MenuButtonFullScreen } from './MenuButtonFullscreen';
import { MenuButtonBlockqoute } from './MenuButtonBlockquote';
import { MenuButtonLineHeight } from './MenuButtonLineHeight';
import { MenuButtonSuperscript } from './MenuButtonSuperscript';
import { MenuButtonCalloutInfo } from './MenuButtonCalloutInfo';
import { MenuButtonStrikethrough } from './MenuButtonStrikethrough';
import { MenuButtonHighlightColor } from './MenuButtonHighlightColor';
import { MenuButtonHorizontalRule } from './MenuButtonHorizontalRule';
import { MenuButtonSearchAndReplace } from './MenuButtonSearchAndReplace';
import { MenuButtonEmbedDrawIo } from './MenuButtonEmbedDrawIo'
import { useEditorContext } from '../../context';
import cx from '../../editor.module.scss';

export const Menu = ({
    initId,
    cmSelectAll,
    isCodeViewMode,
    toggleIsCodeViewMode,
    isFullScreen,
    setIsFullScreen,
    mode,
    setMode,
    currentMenu,
    setCurrentMenu,
}) => {
    const menuRef = React.useRef(null);

    const editor = useEditorContext();
    const isTableActive = editor?.isActive('table');

    const toggleFullScreen = () => {
        setCurrentMenu(undefined);
        setIsFullScreen(prev => !prev);
    };

    const subMenusList = [
        {
            id: 1,
            label: 'Отменить',
            component: <MenuButtonUndo setCurrentMenu={setCurrentMenu} />,
            type: 'DEFAULT',
            isFullScreen: true,
        },
        {
            id: 2,
            label: 'Повторить',
            component: <MenuButtonRedo setCurrentMenu={setCurrentMenu} />,
            divider: true,
            type: 'DEFAULT',
            isFullScreen: true,
        },
        {
            id: 3,
            label: 'Заголовок',
            component: <MenuHeading mode={mode} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} />,
            type: 'DEFAULT',
            isFullScreen: true,
        },
        {
            id: 4,
            label: 'Выбор шрифта',
            component: <MenuFontFamily mode={mode} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} />,
            type: 'DEFAULT',
            isFullScreen: true,
        },
        {
            id: 5,
            label: 'Выбор размера шрифта',
            component: <MenuFontSize mode={mode} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} />,
            divider: mode !== 'MOBILE',
            type: 'DEFAULT',
            isFullScreen: true,
        },
        {
            id: 6,
            label: 'Полужирный',
            component: <MenuButtonBold setCurrentMenu={setCurrentMenu} />,
            type: 'DEFAULT',
            isFullScreen: true,
        },
        {
            id: 7,
            label: 'Курсив',
            component: <MenuButtonItalic setCurrentMenu={setCurrentMenu} />,
            type: 'DEFAULT',
            isFullScreen: true,
        },
        {
            id: 8,
            label: 'Зачеркнутый',
            component: <MenuButtonStrikethrough setCurrentMenu={setCurrentMenu} />,
            type: 'DEFAULT',
            isFullScreen: true,
        },
        {
            id: 9,
            label: 'Подчеркнутый',
            component: <MenuButtonUnderline setCurrentMenu={setCurrentMenu} />,
            divider: mode !== 'DESKTOP',
            type: 'DEFAULT',
            isFullScreen: true,
        },
        {
            id: 10,
            label: 'Подстрочный',
            component: <MenuButtonSubscript setCurrentMenu={setCurrentMenu} />,
            type: 'DEFAULT',
            isFullScreen: true,
        },
        {
            id: 11,
            label: 'Надстрочный',
            component: <MenuButtonSuperscript setCurrentMenu={setCurrentMenu} />,
            divider: true,
            type: 'DEFAULT',
            isFullScreen: true,
        },
        {
            id: 12,
            label: 'Цвет текста',
            component: <MenuButtonColorText mode={mode} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} />,
            type: 'DEFAULT',
            isFullScreen: true,
        },
        {
            id: 13,
            label: 'Цвет фона текста',
            component: <MenuButtonHighlightColor mode={mode} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} />,
            divider: true,
            type: 'DEFAULT',
            isFullScreen: true,
        },
        {
            id: 14,
            label: 'Выравнивание',
            component: <MenuButtonAlign mode={mode} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} />,
            type: mode === 'MOBILE' ? 'COMMON' : 'DEFAULT',
            isFullScreen: true,
        },
        {
            id: 15,
            label: 'Списки',
            component: <MenuButtonList mode={mode} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} />,
            type: mode === 'MOBILE' ? 'COMMON' : 'DEFAULT',
            isFullScreen: true,
        },
        {
            id: 16,
            label: 'Межстрочный интервал',
            component: <MenuButtonLineHeight mode={mode} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} />,
            type: mode === 'MOBILE' ? 'COMMON' : 'DEFAULT',
            isFullScreen: true,
        },
        {
            id: 17,
            label: 'На весь экран',
            component: <MenuButtonFullScreen isFullScreen={isFullScreen} onChange={toggleFullScreen} />,
            type: 'DEFAULT',
            isFullScreen: false,
        },
        {
            id: 18,
            label: 'Макеты',
            component: <MenuButtonTemplate mode={mode} setCurrentMenu={setCurrentMenu} />,
            divider: false,
            type: mode === 'TABLET' ? 'DEFAULT' : 'COMMON',
            isFullScreen: true,
        },
        {
            id: 19,
            label: 'Разделитель',
            component: <MenuButtonHorizontalRule setCurrentMenu={setCurrentMenu} />,
            type: 'COMMON',
            isFullScreen: true,
        },
        {
            id: 20,
            label: 'Информационный блок',
            component: <MenuButtonCalloutInfo setCurrentMenu={setCurrentMenu} />,
            type: 'COMMON',
            isFullScreen: true,
        },
        {
            id: 21,
            label: 'Раскрывающиеся списки',
            component: <MenuButtonDetails setCurrentMenu={setCurrentMenu} />,
            type: 'COMMON',
            isFullScreen: true,
        },
        {
            id: 22,
            label: 'Цитата',
            component: <MenuButtonBlockqoute setCurrentMenu={setCurrentMenu} />,
            type: 'COMMON',
            isFullScreen: true,
        },
        {
            id: 23,
            label: 'Оглавление',
            component: <MenuButtonIndex mode={mode} setCurrentMenu={setCurrentMenu} />,
            divider: mode === 'TABLET',
            type: mode === 'TABLET' ? 'DEFAULT' : 'COMMON',
            isFullScreen: true,
        },
        {
            id: 24,
            label: 'На весь экран',
            component: <MenuButtonFullScreen isFullScreen={isFullScreen} onChange={toggleFullScreen} />,
            type: 'DEFAULT',
            isFullScreen: true,
        },
        {
            id: 25,
            label: 'Форматирование',
            component: (
                <MenuButtonFormat mode={mode} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} isFullScreen={isFullScreen} />
            ),
            type: 'COMMON',
            isFullScreen: true,
        },
        {
            id: 26,
            label: 'Таблица',
            component: <MenuTable mode={mode} setCurrentMenu={setCurrentMenu} />,
            type: mode === 'TABLET' ? 'DEFAULT' : 'COMMON',
            isFullScreen: true,
        },
        {
            id: 27,
            label: 'Замена',
            component: <MenuButtonSearchAndReplace mode={mode} setCurrentMenu={setCurrentMenu} />,
            type: 'COMMON',
            isFullScreen: true,
        },
        {
            id: 28,
            label: 'Выделить все',
            component: <MenuButtonSelectAll cmSelectAll={cmSelectAll} isCodeViewMode={isCodeViewMode} setCurrentMenu={setCurrentMenu} />,
            type: 'COMMON',
            isFullScreen: true,
        },
        {
            id: 29,
            label: 'Якорь',
            component: <MenuButtonAnchor setCurrentMenu={setCurrentMenu} />,
            divider: true,
            type: 'COMMON',
            isFullScreen: true,
        },
        {
            id: 30,
            label: 'Ссылка',
            component: <MenuButtonLink setCurrentMenu={setCurrentMenu} />,
            type: 'MEDIA',
            isFullScreen: true,
        },
        {
            id: 31,
            label: 'Видео',
            component: <MenuButtonVideo mode={mode} initId={initId} setCurrentMenu={setCurrentMenu} />,
            type: 'MEDIA',
            isFullScreen: true,
        },
        {
            id: 32,
            label: 'Картинка',
            component: <MenuButtonImage initId={initId} setCurrentMenu={setCurrentMenu} />,
            type: 'MEDIA',
            isFullScreen: true,
        },
        {
            id: 33,
            label: 'Смайлик',
            component: <MenuButtonEmoji mode={mode} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} isFullScreen={isFullScreen} />,
            type: mode === 'DESKTOP' ? 'MEDIA' : 'DEFAULT',
            isFullScreen: true,
        },
        {
            id: 34,
            label: 'Добавить код',
            component: <MenuButtonCodeBlock setCurrentMenu={setCurrentMenu} />,
            type: 'MEDIA',
            isFullScreen: true,
        },
        {
            id: 35,
            label: 'Cоздать диаграмму Draw.io',
            component: <MenuButtonEmbedDrawIo mode={mode} setCurrentMenu={setCurrentMenu} />,
            divider: true,
            type: 'MEDIA',
            isFullScreen: true,
        },
        {
            id: 36,
            label: 'Код страницы',
            component: (
                <MenuButtonPageCode
                    toggleIsCodeViewMode={() => {
                        toggleIsCodeViewMode();
                        setCurrentMenu(undefined);
                    }}
                />
            ),
            divider: true,
            type: 'ADDITIONAL',
            isFullScreen: true,
        },
        {
            id: 37,
            label: 'Добавить видео с Youtube',
            component: <MenuButtonYoutube setCurrentMenu={setCurrentMenu} />,
            type: 'EXTERNAL',
            isFullScreen: true,
        },
        {
            id: 38,
            label: 'Добавить файл из Google Docs',
            component: <MenuButtonGoogleDoc mode={mode} setCurrentMenu={setCurrentMenu} />,
            type: 'EXTERNAL',
            isFullScreen: true,
        },
        {
            id: 39,
            label: 'Добавить диаграмму Draw.io',
            component: <MenuButtonDiagram mode={mode} setCurrentMenu={setCurrentMenu} />,
            type: 'EXTERNAL',
            isFullScreen: true,
        },
    ];

    const defaultMenu = subMenusList.filter(i => i.isFullScreen && (isFullScreen || i.type === 'DEFAULT'));

    const renderMenuItem = item => (
        <React.Fragment key={item.id}>
            {item.component}
            {item.divider && <div style={{ height: 17, borderRight: '1px solid rgba(39, 155, 217, 0.15)' }} />}
        </React.Fragment>
    );

    useEffect(() => {
        const menuWidth = menuRef.current.getBoundingClientRect().width;
        setMode(menuWidth > 920 ? 'DESKTOP' : menuWidth > 550 ? 'TABLET' : 'MOBILE');

        const onResize = () => {
            if (!menuRef.current) {
                return;
            }

            const menuWidth = menuRef.current.getBoundingClientRect().width;
            setMode(menuWidth > 920 ? 'DESKTOP' : menuWidth > 550 ? 'TABLET' : 'MOBILE');
        };

        window.addEventListener('resize', onResize, true);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [setMode]);
    useEffect(() => {
        const editorWrapper = document.getElementById('editor');

        if (isFullScreen) {
            editorWrapper.classList.add('editor--fullscreen');

            if (mode !== 'DESKTOP') {
                editorWrapper.classList.add('editor--fullscreen--not--desktop');
            }
        } else {
            editorWrapper.classList.remove('editor--fullscreen');
            editorWrapper.classList.remove('editor--fullscreen--not--desktop');
        }
    }, [isFullScreen, mode]);

    return (
        <div ref={menuRef} className={classNames('editor-menu', cx.editorMenu)}>
            {mode === 'DESKTOP' && isFullScreen && (
                <>
                    <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 25, 26, 23, 18].map(id => {
                            const menuItem = subMenusList.find(p => p.id === id);

                            if (!isTableActive && [25, 26, 18].some(id => menuItem.id === id)) {
                                return null;
                            }

                            return renderMenuItem(menuItem);
                        })}
                        {isTableActive && (
                            <>
                                <div style={{ height: 17, borderRight: '1px solid rgba(39, 155, 217, 0.15)' }} />
                                <SubMenu mode={mode} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} subMenuList={subMenusList} />
                            </>
                        )}
                        <div style={{ height: 17, borderRight: '1px solid rgba(39, 155, 217, 0.15)' }} />
                        <MenuButtonFullScreen isFullScreen={isFullScreen} onChange={toggleFullScreen} />
                    </div>
                    {!isTableActive && (
                        <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'center' }}>
                            {[25, 26, 18, 19, 20, 21, 22, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38].map(id => {
                                const menuItem = subMenusList.find(p => p.id === id);
                                return renderMenuItem(menuItem);
                            })}
                        </div>
                    )}
                </>
            )}
            {mode === 'DESKTOP' && !isFullScreen && (
                <div>
                    {defaultMenu.map(renderMenuItem)}
                    <SubMenu mode={mode} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} subMenuList={subMenusList} />
                </div>
            )}
            {mode === 'TABLET' && (
                <>
                    <div style={{ flexWrap: 'nowrap' }}>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 17].map(item => {
                            const menuItem = subMenusList.find(p => p.id === item);
                            return renderMenuItem(menuItem);
                        })}
                    </div>
                    <div style={{ flexWrap: 'nowrap' }}>
                        {[12, 13, 14, 15, 16, 23, 26, 18].map(item => {
                            const menuItem = subMenusList.find(p => p.id === item);
                            return renderMenuItem(menuItem);
                        })}
                        <SubMenu mode={mode} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} subMenuList={subMenusList} />
                    </div>
                </>
            )}
            {mode === 'MOBILE' && (
                <>
                    <div style={{ flexWrap: 'nowrap' }}>
                        {[1, 2, 3, 4, 5].map(item => {
                            const menuItem = subMenusList.find(p => p.id === item);
                            return renderMenuItem(menuItem);
                        })}
                    </div>
                    <div style={{ flexWrap: 'nowrap' }}>
                        {[6, 7, 8, 9, 12, 13, 17].map(item => {
                            const menuItem = subMenusList.find(p => p.id === item);
                            return renderMenuItem(menuItem);
                        })}
                        <SubMenu mode={mode} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} subMenuList={subMenusList} />
                    </div>
                </>
            )}
            {mode === 'DESKTOP' && <MenuListTableButton />}
        </div>
    );
};
