import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import React, { forwardRef, MouseEventHandler } from 'react';
import Icon from 'uikit/icon/icon';
import cx from '../../editor.module.scss';

interface MenuButtonProps {
    icon?: React.ReactNode | Function;
    iconColor?: 'gray' | 'red' | 'blue' | 'green' | 'white';
    onClick?: MouseEventHandler<HTMLDivElement>;
    className?: string;
    style?: React.CSSProperties;
    selected?: boolean;
    hover?: boolean;
    label?: React.ReactNode;
    tooltip?: React.ReactNode;
    disabled?: boolean;
}

export const MenuButton = forwardRef<HTMLDivElement, MenuButtonProps>(({ icon, iconColor, onClick, className, style, selected, hover, label, tooltip, disabled }, ref) => {
    const click: MouseEventHandler<HTMLDivElement> = (e) => {
        onClick?.(e);
    };

    return (
        <Tippy content={tooltip} disabled={!tooltip} placement="top">
            <div
                ref={ref}
                className={classNames('editor-button', cx.editorMenuButton, className, {
                    [cx.selected]: selected,
                    [cx.grow]: label,
                    [cx.disabled]: disabled,
                    [cx.hover]: hover
                })}
                onMouseDown={
                    document.body.offsetWidth > 1024
                        ? e => {
                              e.preventDefault();
                              e.stopPropagation();

                              return false;
                          }
                        : undefined
                }
                style={style}
                onClick={click}
            >
                {icon instanceof Function ? (
                    icon()
                ) : (
                    <Icon type={icon} color={iconColor} width={18} height={18} />
                )}
                {label && (
                    <span className="editor-button-label" style={{ paddingLeft: 6 }}>
                        {label}
                    </span>
                )}
            </div>
        </Tippy>
    );
});
