import React from 'react';
import Modal from 'react-modal';
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import classNames from 'classnames';
import Loading from 'uikit/loading';
import Icon, { Icons } from 'uikit/icon';
import cx from './styles.module.scss';
import { ICONS_PROPS, IImagePreviewModalState } from '../index';


export const ImagePreviewModal: React.FC<IImagePreviewModalState> = ({
    isOpen, setIsOpen,
    imageRef,
    imageSrc,
    imageLoading,
    setImageLoading,
    imageZoomLimit,
    imageZoomInitial,
    imageDownload,
    downloadAction
}) => {

    const { header, footer } = ICONS_PROPS;

    const transformComponentRef = React.useRef<ReactZoomPanPinchRef | null>(null);

    return (
        // @ts-ignore
        <Modal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            overlayClassName={cx.termModalOverlay}
            className={cx.imagePreviewModal}
            testId="imagePreviewModal"
        >
            {
                imageLoading && (
                    <div className={cx.preloaderWrapper}>
                        <Loading withOverlay={false} className="" style={{}} />
                    </div>
                )
            }
            <TransformWrapper
                initialScale={imageZoomInitial}
                minScale={imageZoomLimit[0]}
                maxScale={imageZoomLimit[1]}
                centerOnInit={true}
                disablePadding={true}
                onInit={(ref: ReactZoomPanPinchRef) => {
                    transformComponentRef.current = ref;
                }}
            >
                {({ zoomIn, zoomOut, resetTransform }) => (
                    <>
                        <div className={cx.headerToolbarWrapper}>
                            <div className={classNames(cx.headerToolbar, {
                                [cx.headerToolbarLoaded]: !imageLoading
                            })}>
                                <div className={cx.text}></div>
                                <span onClick={() => downloadAction(imageRef?.current?.src, imageRef?.current?.alt)}>
                                {
                                    imageDownload
                                        ? (
                                            <div className={cx.preloaderWrapper}>
                                                <Loading
                                                    withOverlay={false}
                                                    className={cx.preloaderIcon}
                                                    style={{}}
                                                    />
                                            </div>
                                        )
                                        : (
                                            <Icon
                                                type={ Icons.DOWNLOAD } color={ header.color }
                                                width={ header.size } height={ header.size }
                                            />
                                        )
                                }
                                </span>
                                <span onClick={() => setIsOpen(false)}>
                                    <Icon
                                        type={ Icons.CROSS } color={ header.color }
                                        width={ header.size } height={ header.size }
                                    />
                                </span>
                            </div>
                        </div>
                        <TransformComponent wrapperClass={cx.transformWrapper}>
                            <div className={classNames(cx.content, {
                                [cx.contentLoaded]: !imageLoading
                            })}>
                                <img
                                    onLoad={() => setImageLoading(false)}
                                    ref={imageRef}
                                    src={imageSrc}
                                    alt=""
                                />
                            </div>
                        </TransformComponent>
                        <div className={cx.footerToolbarWrapper}>
                            <div className={classNames(cx.footerToolbar, {
                                [cx.footerToolbarLoaded]: !imageLoading
                            })}>
                                <span onClick={() => zoomOut()}>
                                    <Icon
                                        type={ Icons.MINUS } color={ footer.color }
                                        width={ footer.size } height={ footer.size }
                                        />
                                </span>
                                <span onClick={() => resetTransform()}>
                                    <Icon
                                        type={ Icons.Refresh } color={ footer.color }
                                        width={ footer.size } height={ footer.size }
                                        />
                                </span>
                                <span onClick={() => zoomIn()}>
                                    <Icon
                                        type={ Icons.PLUS_ADD } color={ footer.color }
                                        width={ footer.size } height={ footer.size }
                                        />
                                </span>
                            </div>
                        </div>
                    </>
                )}
            </TransformWrapper>
        </Modal>
    )
}
