import { ReactNodeViewRenderer } from '@tiptap/react';
import { Image } from '@tiptap/extension-image';
import { NodeSelection } from '@tiptap/pm/state';
import { Plugin } from 'prosemirror-state';
import { v4 as uuidv4 } from 'uuid';
import {
    IEmbedDrawIoImageExtOptions,
    EEmbedDrawIoAction,
    EEmbedDrawIoError,
    EEmbedDrawIoAttr,
    useEmbedDrawIoDialogStore,
    isIFrame,
    isEqualStr,
    encodeBase64,
    decodeBase64,
    decodeXmlSvgBase64ToSvg,
} from '../index';
import { EmbedDrawIoImageNodeView } from '../ui/image-node-view';

export const EmbedDrawIoImageExt = Image.extend<IEmbedDrawIoImageExtOptions>({

    name: EEmbedDrawIoAttr.NAME_EXTENSION,

    addAttributes() {
        return {
            ...this.parent?.(),
            id: {
                default: '',
            },
            src: {
                default: '',
            },
            xml: {
                default: '',
            },
            svg: {
                default: '',
            },
        };
    },

    addOptions() {
        return {
            inline: false,
            allowBase64: false,
            HTMLAttributes: {},
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(EmbedDrawIoImageNodeView);
    },

    renderHTML({ node }) {
        const svgSel = `div[${EEmbedDrawIoAttr.DATA_EXPORTED_SVG}] svg`;
        return [
            'div',
            {
                [`${EEmbedDrawIoAttr.DATA_EXPORTED_SVG}`]: '',
                [`${EEmbedDrawIoAttr.DATA_ENCODED_XML}`]: `${node.attrs.xml || ''}`,
                [`${EEmbedDrawIoAttr.DATA_IMAGE_SRC}`]: `${node.attrs.src || ''}`,
            },
            `<style>
                div[${EEmbedDrawIoAttr.DATA_EXPORTED_SVG}] { padding: 10px 0px !important; }
                ${svgSel} { max-width: 100%; border-radius: 0.5rem; padding: 0px 5px; transition: 0.25s ease-out; }
                ${svgSel}:hover { background-color: #F4FAFD; }
                ${svgSel} a g { transition: 0.25s ease-out; }
                ${svgSel} a g:hover { stroke-width: 4px; stroke-linejoin: round; }
                ${svgSel} a g *:hover { stroke: #2577EA; }
                ${svgSel} a text:hover { stroke-width: initial !important; stroke: initial !important; text-decoration: underline; }
            </style>
            ${node.attrs.svg || decodeXmlSvgBase64ToSvg(node.attrs.src)}`,
        ];
    },

    parseHTML() {
        return [
            {
                tag: `div[${EEmbedDrawIoAttr.DATA_EXPORTED_SVG}]`,
                getAttrs: (dom: string | HTMLElement) => ({
                    xml: (dom as HTMLElement).getAttribute(EEmbedDrawIoAttr.DATA_ENCODED_XML),
                    src: (dom as HTMLElement).getAttribute(EEmbedDrawIoAttr.DATA_IMAGE_SRC),
                }),
            },
        ];
    },

    // @ts-ignore
    addCommands() {
        return {
            ...this.parent?.(),
            involveEmbedDrawIoDialog:
                (isNewNode: boolean, nodePosition: number | null) =>
                ({ commands }: { commands: any }) => {

                    const { INIT, LOAD, SAVE, EXPORT, EXIT } = EEmbedDrawIoAction;

                    const { options: op, setIsEditorDialog, setDialogLoaded, resetDialogMainState } = useEmbedDrawIoDialogStore.getState();

                    const { selection } = this.editor.state;

                    let currentNodePosition = isNewNode
                        ? this.editor.view.state.selection.$anchor.pos - 1 !== -1
                            ? this.editor.view.state.selection.$anchor.pos - 1
                            : 0
                        : nodePosition
                            ? nodePosition
                            : 0;

                    if (isNewNode) {

                        if (selection instanceof NodeSelection && selection.node.type.name === this.name) {
                            currentNodePosition = selection.$anchor.pos + selection.node.nodeSize;
                            commands.insertContentAt(currentNodePosition, {
                                type: this.name,
                                attrs: {
                                    id: uuidv4(),
                                },
                            });
                        }
                        else {
                            commands.insertContent({
                                type: this.name,
                                attrs: {
                                    id: uuidv4(),
                                },
                            });
                        }
                    }

                    setIsEditorDialog(true);

                    const purgeMessageDialog = (): void => {
                        window.removeEventListener('message', handleMessageDialog);
                    };

                    const handleMessageDialog = (event: MessageEvent): void => {

                        if (!isEqualStr(event.origin, op.drawIoOriginLink)) {
                            // console.warn(EEmbedDrawIoError.INVALID_MESSAGE_SOURCE, event.origin);
                            return;
                        }

                        try {
                            const iframe = document.querySelector(`iframe[title=${EEmbedDrawIoAttr.TITLE_IFRAME}]`);
                            const msg = JSON.parse(event.data);

                            switch (msg.event) {
                                case INIT:
                                    if (isIFrame(iframe)) {
                                        const node = this.editor.view.state.doc.nodeAt(currentNodePosition);
                                        iframe.contentWindow?.postMessage(
                                            JSON.stringify({
                                                action: LOAD,
                                                xml: decodeBase64(node?.attrs?.xml ? node?.attrs?.xml : op.baseEncodedXml),
                                            }),
                                            op.drawIoOriginLink
                                        );
                                    } else {
                                        purgeMessageDialog();
                                        resetDialogMainState();
                                        console.warn(EEmbedDrawIoError.ERROR_PROCESSING_MESSAGE, msg.event);
                                    }
                                    break;

                                case LOAD:
                                    setDialogLoaded(true);
                                    break;

                                case SAVE:
                                    if (isIFrame(iframe)) {
                                        iframe.contentWindow?.postMessage(
                                            JSON.stringify({
                                                action: EXPORT,
                                                format: 'xmlsvg',
                                                embedImages: true,
                                                includeForeignObjects: true,
                                                allPages: false,
                                                xml: false,
                                                includeDiagram: false,
                                                spin: 'saving-xmlsvg',
                                            }),
                                            op.drawIoOriginLink
                                        );
                                    } else {
                                        purgeMessageDialog();
                                        resetDialogMainState();
                                        console.warn(EEmbedDrawIoError.ERROR_PROCESSING_MESSAGE, msg.event);
                                    }
                                    break;

                                case EXPORT:
                                    const node = this.editor.view.state.doc.nodeAt(currentNodePosition);
                                    this.editor.view.dispatch(
                                        this.editor.view.state.tr.setNodeMarkup(currentNodePosition, undefined, {
                                            ...(node && node?.attrs ? node.attrs : {}),
                                            xml: encodeBase64(msg.xml),
                                            svg: decodeXmlSvgBase64ToSvg(msg.data),
                                            src: msg.data,
                                        })
                                    );
                                    purgeMessageDialog();
                                    setDialogLoaded(false);
                                    break;

                                case EXIT:
                                    purgeMessageDialog();
                                    setDialogLoaded(false);
                                    break;

                                default:
                                    purgeMessageDialog();
                                    resetDialogMainState();
                                    console.warn(EEmbedDrawIoError.UNKNOW_ACTION, msg.event);
                            }
                        } catch (error) {
                            console.error(EEmbedDrawIoError.UNKNOW_ACTION, error);
                        }
                    };

                    window.addEventListener('message', handleMessageDialog);

                    return true;
                },
        };
    },

    addKeyboardShortcuts() {
        return {
            ...this.parent?.(),
            Backspace: () => {
                const { selection } = this.editor.state;
                return (
                    (selection instanceof NodeSelection && selection.node.type.name === this.name) ||
                    (
                        selection.$anchor.parentOffset === 0 &&
                        selection.$anchor.parent.type.name === 'paragraph' &&
                        this.editor.view.state.doc.nodeAt(selection.$anchor.pos - 2)?.type.name === this.name
                    )
                );
            },
            Delete: () => {
                const { selection } = this.editor.state;
                return (
                    (selection instanceof NodeSelection && selection.node.type.name === this.name) ||
                    (
                        selection.$anchor.parentOffset === selection.$anchor.parent.content.size &&
                        selection.$anchor.parent.type.name === 'paragraph' &&
                        this.editor.view.state.doc.nodeAt(selection.$anchor.pos + 1)?.type.name === this.name
                    )
                );
            },
        };
    },

    addProseMirrorPlugins() {
        return [
            new Plugin({
                filterTransaction: (transaction) => {
                    
                    const { selection } = this.editor.state;

                    if (selection instanceof NodeSelection && selection.node.type.name === this.name) {

                        const isInsertingParagraph = transaction.steps.some(step => {

                            if ('slice' in step) {
                                const slice = (step as any).slice;
                                return (
                                    slice?.content?.childCount === 1 &&
                                    slice.content.firstChild?.type?.name === 'paragraph'
                                );
                            }
                            return false;
                        });

                        if (isInsertingParagraph) return false;
                    }

                    return true;
                },
            }),
        ];
    }
});
