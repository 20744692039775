export class NotificationCounter {
    private count: number = 0;

    constructor(count: number) {
        if (count < 0) {
            throw new Error('Notification count must be greater than 0');
        }

        this.count = count;
    }

    public getCount() {
        return this.count;
    }

    public readNotification() {
        if (this.count - 1 < 0) {
            throw new Error('Notification count must be greater than 0');
        }

        this.count -= 1;
    }

    public addNotification() {
        this.count += 1;
    }

    public readAllNotifications() {
        this.count = 0;
    }
}
