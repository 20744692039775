import React from 'react';
import classNames from 'classnames';
import Icon, { Icons } from 'uikit/icon';
import { AdaptiveLink } from 'containers/adaptive-link/adaptive-link';
import cx from './empty.module.scss';

const EMPTY_DEFAULT_INDENT = '210px';

const EmptyError = ({ buttons }) => (
    <Empty
        icon={Icons.INFO}
        title="Упс. Произошла ошибка."
        description="Пожалуйста перезагрузите страницу или перейдите на главную."
        buttons={buttons}
    />
);
const EmptyDevelopment = () => (
    <Empty
        title="Страница в разработке"
        description="Сейчас страница разрабатывается, но скоро здесь что-то будет"
    />
);
const EmptyHome = ({ buttons }) => (
    <Empty
        title="Тут сейчас пусто"
        description="Создайте свой первый проект"
        buttons={buttons}
    />
);
const EmptyProjects = ({ buttons }) => (
    <Empty
        title="Проекты не найдены"
        description={buttons ? 'Воспользуйтесь кнопкой ниже для создания' : ''}
        buttons={buttons}
    />
);
const EmptyProjectsSection = ({ buttons }) => (
    <Empty
        title="Нет проектов для расположения раздела"
        description="Для создания раздела, сперва необходимо создать проект в системе"
        buttons={buttons}
    />
);
const EmptyProject = ({ buttons }) => (
    <Empty
        title="Проект пуст"
        description={buttons ? 'Воспользуйтесь кнопками ниже для создания' : ''}
        buttons={buttons}
    />
);
const EmptyProjectDeleted = () => (
    <Empty
        icon={Icons.FOLDER}
        title="Проект удален"
        description="Проект, который вы ищете, был удален"
    />
);
const EmptySectionsArticle = ({ buttons }) => (
    <Empty
        title="Не найдены разделы для создания статьи"
        description={buttons ? 'Воспользуйтесь кнопкой ниже для создания' : ''}
        buttons={buttons}
    />
);
const EmptySectionsScript = ({ buttons }) => (
    <Empty
        title="Не найдены разделы для создания скрипта"
        description={buttons ? 'Воспользуйтесь кнопкой ниже для создания' : ''}
        buttons={buttons}
    />
);
const EmptySectionDeleted = () => (
    <Empty
        icon={Icons.FOLDER}
        title="Раздел удален"
        description="Раздел, который вы ищете, был удален"
    />
);
const EmptyArticles = ({ buttons }) => (
    <Empty
        title="Статьи не найдены"
        description={buttons ? 'Воспользуйтесь кнопкой ниже для создания' : ''}
        buttons={buttons}
    />
);
const EmptyArticle = () => (
    <Empty
        icon={Icons.ARTICLE}
        title="Ошибка загрузки статьи"
        description="Статья, которую вы ищете, не может быть загружена"
    />
);
const EmptyArticleDeleted = () => (
    <Empty
        icon={Icons.ARTICLE}
        title="Статья была удалена"
    />
);
const EmptyNews = ({ buttons }) => (
    <Empty
        title="Новости не найдены"
        description={buttons ? 'Воспользуйтесь кнопкой ниже для создания' : ''}
        buttons={buttons}
    />
);
const EmptyNewsDeleted = () => (
    <Empty
        icon={Icons.NEWS}
        title="Новость была удалена"
    />
);
const EmptyScripts = ({ buttons }) => (
    <Empty
        title="Скрипты не найдены"
        description={buttons ? 'Воспользуйтесь кнопкой ниже для создания' : ''}
        buttons={buttons}
        className={cx['empty--mt0']}
    />
);
const EmptyScript = () => (
    <Empty
        icon={Icons.SCRIPTING}
        title="Ошибка загрузки скрипта"
        description="Скрипт, который вы ищете, не может быть загружен"
    />
);
const EmptyScriptDeleted = () => (
    <Empty
        icon={Icons.SCRIPTING}
        title="Скрипт был удален"
        description="Скрипт, который вы ищете, был удален"
    />
);
const EmptyFiles = () => (
    <Empty
        title="Файлы не найдены"
    />
);
const EmptyActions = () => (
    <Empty
        title="Действия отсутствуют"
    />
);
const EmptyUsers = ({ buttons }) => (
    <Empty
        title="Участники не найдены"
        description={buttons ? 'Воспользуйтесь кнопкой ниже для добавления' : ''}
        buttons={buttons}
    />
);
const EmptyUserDeleted = () => (
    <Empty
        icon={Icons.USER_2}
        title="Участник удален"
        description="Участник, которого вы ищете, был удален"
    />
);
const EmptyGroup = () => (
    <Empty
        icon={Icons.GROUP}
        title="Группа участников удалена"
        description="Группа, которую вы ищете, была удалена"
    />
);
const EmptyOffices = ({ buttons }) => (
    <Empty
        icon={Icons.MAP_PIN}
        title="Офисы отсутствуют"
        buttons={buttons}
    />
);
const EmptyLicense = ({ buttons }) => (
    <Empty
        icon={Icons.LOCK}
        title="Не найдена лицензия для Вашего аккаунта"
        description="Пожалуйста перезагрузите страницу или войдите под другим аккаунтом. Если вы уверены в правильности
        авторизационных данных - обратитесь к своему администратору."
        buttons={buttons}
    />
);
const EmptyTrainingThemeOrTest = ({ buttons }) => (
    <Empty
        icon={Icons.LIST}
        title="Выберите тему или вопрос"
        description="Либо воспользуйтесь кнопками ниже для создания"
        buttons={buttons}
    />
);
const EmptyTrainingTestAccess = ({ buttons }) => (
    <Empty
        icon={Icons.LOCK}
        title="Доступ к тесту не открыт"
        description="Используйте кнопку ниже  для открытия доступа"
        buttons={buttons}
    />
);

const Empty = ({ title = '', icon, description = '', buttons = [], className }) => {
    return (
        <div
            className={classNames(cx.empty, [className])}
            style={{ marginTop: EMPTY_DEFAULT_INDENT }}
        >
            <Icon
                type={icon || Icons.FOLDER}
                width={56}
                height={50}
                color="gray"
            />
            <h3 data-testid="emtyPageTitle">
                {title}
            </h3>
            <p data-testid="emtyPageDescription">
                {description}
            </p>
            {buttons && buttons.length > 0 && (
                <div className={cx.buttons}>
                    {buttons.map((button) => {
                        return (
                            <AdaptiveLink
                                key={button.title}
                                link={button.link}
                            >
                                <button
                                    onClick={button.onClick}
                                    data-testid="emptyPageBtns"
                                >
                                    <Icon
                                        type={button.icon}
                                        width={20}
                                        height={20}
                                    />
                                    <span>
                                        {button.title}
                                    </span>
                                </button>
                            </AdaptiveLink>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default Empty;
export {
    EmptyError,
    EmptyDevelopment,
    EmptyHome,
    EmptyProjects,
    EmptyProjectsSection,
    EmptyProject,
    EmptyProjectDeleted,
    EmptySectionsArticle,
    EmptySectionsScript,
    EmptySectionDeleted,
    EmptyArticles,
    EmptyArticle,
    EmptyArticleDeleted,
    EmptyNews,
    EmptyNewsDeleted,
    EmptyScripts,
    EmptyScript,
    EmptyScriptDeleted,
    EmptyFiles,
    EmptyActions,
    EmptyUsers,
    EmptyUserDeleted,
    EmptyGroup,
    EmptyOffices,
    EmptyLicense,
    EmptyTrainingThemeOrTest,
    EmptyTrainingTestAccess
};
