import { useEffect } from 'react';
import { useNavigate } from 'shared/router';
import { useAuthorized } from '../../lib/hooks';

const useCheckGlobalPermission = (uuid: string, permission: string) => {
    const navigate = useNavigate();
    const { isAuthorizedAction } = useAuthorized();

    useEffect(() => {
        if (uuid) {
            return;
        }

        if (!isAuthorizedAction([permission])) {
            navigate('/401');
        }
    }, [uuid, permission, isAuthorizedAction, navigate]);
};

export default useCheckGlobalPermission;
