import React from 'react';
import DropdownMenu from 'uikit/dropdown-menu';
import { useGlobalContext } from 'lib/hooks';
import MobileDropdownMenu from 'uikit/dropdown-menu/mobile-dropdown-menu';
import DropdownMenuItem from '../dropdown-menu/dropdown-menu-item';

const ButtonMenu = ({
    tooltip = '',
    position = 'right',
    items = [],
    alwaysFilled = false,
    toggler,
    submenuPosition = 'right',
    id = '',
    ...rest
}) => {
    const { platform } = useGlobalContext();
    return (
        <div onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} id={id}>
            {platform !== 'mobile' &&
                <DropdownMenu
                    buttonId={`${id}Button`}
                    tooltip={tooltip}
                    position={position}
                    alwaysFilled={alwaysFilled}
                    toggler={toggler}
                    {...rest}
                >
                    {items.map((item, i) => {
                        if (item.isShow === false) {
                            return null;
                        }

                        if (item.submenu) {
                            return (
                                <DropdownMenuItem
                                    key={i}
                                    id={item.id}
                                    className={item.className}
                                    iconType={item.icon}
                                    iconWidth={item.width}
                                    iconHeight={item.height}
                                    text={item.title}
                                    submenuPosition={submenuPosition}>
                                    {item.submenu.map((submenuItem, j) => (
                                        <DropdownMenuItem
                                            key={j}
                                            text={submenuItem.title}
                                            onClick={submenuItem.onClick}
                                            link={submenuItem.link}
                                        />
                                    ))}
                                </DropdownMenuItem>
                            );
                        } else {
                            return (
                                <DropdownMenuItem
                                    key={i} id={item.id}
                                    className={item.className}
                                    iconType={item.icon}
                                    text={item.title}
                                    onClick={item.onClick}
                                    link={item.link}
                                    iconWidth={item.width}
                                    iconHeight={item.height}
                                />
                            );
                        }
                    })}
                </DropdownMenu>}
            {platform === 'mobile' &&
                <MobileDropdownMenu items={items} alwaysFilled={alwaysFilled} toggler={toggler} {...rest}/>}
        </div>
    );
};

export default ButtonMenu;
