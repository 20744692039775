import React, { useContext } from 'react';
import { RouterContext } from '../routerProvider';
import { RenderRouteProps } from '../routers/router';

export const Route = (props: RenderRouteProps): React.ReactNode => {
    const context = useContext(RouterContext);

    if (!context) {
        throw new Error('Route must be used within a RouterProvider');
    }

    return context.router.getRoute(props);
};
