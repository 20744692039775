import { Extension } from '@tiptap/core';

export const FileStorage = Extension.create({
    name: 'fileStorage',

    addStorage() {
        return {
            files: [],
        };
    },

    addCommands() {
        return {
            addFilesToStorage:
                files =>
                ({ editor }) => {
                    editor.storage.fileStorage.files = files;
                },
        };
    },
});
