import React, { forwardRef, useState } from 'react';
import { isEqual } from './utils';
import { EDITOR_TIPTAP } from './constants';
import Icon, { Icons } from '../uikit/icon';
// eslint-disable-next-line
import CKEditor from './ck-editor';
import ParsingForm from './parsing-form/parsing-form';
import TiptapEditor from './tiptap-editor/Editor';
import cn from './editor.module.scss';

export const Editor = forwardRef((props, ref) => {
    const { id, label, type, entity, attachments, onEditorSaveInstance, setFormDataChanged, formDataChanged } = props;

    const [parsingForm, setParsingForm] = useState(false);
    const [previousText, setPreviousText] = useState(null);

    const onDocumentParsed = (html, isClearAll) => {
        entity.text = html;
        entity.commit();

        if (isEqual(type.toString(), EDITOR_TIPTAP)) {
            entity.editor?.commands.execPasteTest(
                {
                    text: '',
                    html: html,
                    rtf: '',
                    items: [],
                    types: ['text/html'],
                },
                isClearAll
            );
        }

        setParsingForm(false);
    };

    const onChange = html => {
        entity.text = html;

        if (formDataChanged) {
            return;
        }

        if (previousText !== null && previousText !== html) {
            setFormDataChanged(true);
        }

        setPreviousText(html);
    };

    if (props.initId === undefined) {
        return null;
    }

    return (
        <div id={id} className={cn.editor}>
            <ParsingForm isOpen={parsingForm} onDismiss={() => setParsingForm(false)} onOk={onDocumentParsed} />
            <div className={cn.header}>
                <div className={cn.label}>
                    {label}
                    <span className={cn.required}>*</span>
                </div>
                <div className={cn.menu}>
                    <div className={cn.import} onClick={() => setParsingForm(true)}>
                        <Icon type={Icons.DOWNLOAD} width={16} height={16} />
                        <span className={cn.desktop}>Импортировать из документа</span>
                        <span className={cn.mobile}>Импорт</span>
                    </div>
                </div>
            </div>
            <TiptapEditor {...props} onChange={onChange} attachments={attachments} onInstanceReady={onEditorSaveInstance} ref={ref} />
        </div>
    );
});

export default Editor;
