import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'shared/router';
import { setSessionHasBeenFetched } from 'slice/authSlice';
import { Icons } from 'uikit/icon';
import { EmptyLicense } from 'components/projects/empty';

const LoginLicensePage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onReloadClick = () => {
        navigate('/');
        window.location.reload();
    };
    const onLoginClick = () => {
        dispatch(setSessionHasBeenFetched(true));

        if (process.env.NODE_ENV === 'development' && window.location.pathname !== '/login') {
            window.location.href = window.location.origin + '/login';
        }

        if (process.env.NODE_ENV !== 'development' && window.location.pathname !== '/') {
            window.location.href = window.location.origin + '/';
        }
    };

    return (
        <EmptyLicense buttons={[{
            icon: Icons.RELOAD,
            title: 'Перезагрузить',
            onClick: () => onReloadClick()
        }, {
            icon: Icons.USER,
            title: 'Авторизоваться',
            onClick: () => onLoginClick()
        }]} />
    );
};

export default LoginLicensePage;
