import React, { useEffect, useState } from 'react';
import { useNavigate } from 'shared/router';
import { useDialog, useMessage } from 'lib/hooks';
import { TRAINING_QUESTION_TYPES } from 'lib/config/constant';
import {
    TrainingQuestionPreview,
    TrainingQuestionAnswerMany,
    TrainingQuestionAnswerMatch,
    TrainingQuestionAnswerNext,
    TrainingQuestionAnswerOne,
    TrainingQuestionAnswerOpen,
    TrainingQuestionAnswerSort,
    TrainingQuestionAvatar,
} from 'components/training';
import { Confirmation } from 'components/confirmation/confirmation';
import Icon, { Icons } from 'uikit/icon';
import IconButton, { IconButtonType } from 'uikit/icon-button';
import Input from 'uikit/input/input';
import { Select } from 'uikit/select';
import Button from 'uikit/button';
import api from 'api';
import cx from './training-question.module.scss';

export default function TrainingQuestion({ id, isEdit, reloadTree }) {
    const navigate = useNavigate();

    const { addError } = useMessage();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [isInit, setIsInit] = useState(false);
    const [isQuestionPreview, setIsQuestionPreview] = useState(false);

    const [themes, setThemes] = useState([]);

    const [questionImage, setQuestionImage] = useState(null);
    const [questionTitle, setQuestionTitle] = useState('');
    const [questionMark, setQuestionMark] = useState('0');
    const [questionType, setQuestionType] = useState(TRAINING_QUESTION_TYPES[0]);
    const [questionTheme, setQuestionTheme] = useState(null);
    const [questionAnswers, setQuestionAnswers] = useState([
        { value1: '', value2: '', isRight: false },
        { value1: '', value2: '', isRight: false },
    ]);

    const onCreateQuestion = async () => {
        try {
            await api.training.postQuestion(questionImage, questionTitle, questionMark, questionType, questionTheme, questionAnswers);
            reloadTree();
            navigate('/training/catalog');
        } catch (e) {
            console.log(e);
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
        }
    };
    const onUpdateQuestion = async () => {
        try {
            closeDialog();
            await api.training.putQuestion(id, questionImage, questionTitle, questionMark, questionType, questionTheme, questionAnswers);

            reloadTree();
            navigate('/training/catalog');
        } catch (e) {
            console.log(e);
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
        }
    };
    const onDeleteQuestion = async () => {
        openDialog({
            title: 'Удаление вопроса',
            text: <span>Вы действительно хотите удалить вопрос?</span>,
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Подтвердить',
            onClose: closeDialog,
            onSubmit: async () => {
                try {
                    await api.training.deleteQuestion(id);
                    reloadTree();
                    navigate('/training/catalog');
                } catch (e) {
                    console.log(e);
                    addError('Сервис недоступен. Пожалуйста попробуйте позже.');
                }
            },
        });
    };

    useEffect(() => {
        if (!isInit || !id) {
            return;
        }

        const fetchData = async () => {
            try {
                const response = await api.training.getQuestion(id);

                setQuestionImage(response['image']);
                setQuestionTitle(response['title']);
                setQuestionMark(response['mark']);
                setQuestionType(TRAINING_QUESTION_TYPES[TRAINING_QUESTION_TYPES.findIndex(p => p.value === response['type'])]);
                setQuestionTheme(themes[themes.findIndex(p => p.value === response['themeId'])]);
                setQuestionAnswers(response['answers']);
            } catch (e) {
                console.log(e);
                addError('Сервис недоступен. Пожалуйста попробуйте позже.');
            }
        };
        fetchData();
    }, [addError, themes, isInit, id]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.training.getThemes();

                setThemes(response);
                setQuestionTheme(response.length === 0 ? null : response[0]);

                setIsInit(true);
            } catch (e) {
                console.log(e);
                addError('Сервис недоступен. Пожалуйста попробуйте позже.');
            }
        };
        fetchData();
    }, [addError]);

    return (
        <div className={cx.trainingQuestion}>
            <Confirmation {...dialogState} />
            <TrainingQuestionPreview
                isOpen={isQuestionPreview}
                index={null}
                avatar={questionImage}
                title={questionTitle}
                mark={questionMark}
                type={questionType}
                answers={questionAnswers}
                onClose={() => setIsQuestionPreview(false)}
            />
            {(!id || (id && isEdit)) && (
                <div className={cx.trainingQuestionCreate}>
                    <h1>{id && isEdit ? 'Редактирование вопроса' : 'Создание вопроса'}</h1>
                    <div className={cx.trainingQuestionCreateContainer}>
                        <div className={cx.trainingQuestionCreateTop}>
                            <TrainingQuestionAvatar avatar={questionImage} onAvatarSave={avatar => setQuestionImage(avatar)} />
                            <Input
                                id="trainingQuestionCreateTopTitle"
                                name="questionTitle"
                                type="text"
                                className={cx.trainingQuestionCreateTopField}
                                label="Заголовок"
                                value={questionTitle}
                                required
                                onChange={str => setQuestionTitle(str)}
                            />
                            <Input
                                id="trainingQuestionCreateTopMark"
                                name="questionMark"
                                type="number"
                                className={cx.trainingQuestionCreateTopField}
                                label="Количество баллов"
                                value={questionMark}
                                required
                                onChange={str => setQuestionMark(str)}
                            />
                            <div className={cx.trainingQuestionCreateTopDelimiter} />
                            {!isEdit && (
                                <>
                                    <h3>Месторасположение</h3>
                                    <Select
                                        className={cx.trainingQuestionCreateTopField}
                                        label="Месторасположение"
                                        options={themes}
                                        value={questionTheme}
                                        onChange={value => setQuestionTheme(value)}
                                    />
                                    <div className={cx.trainingQuestionCreateTopDelimiter} />
                                </>
                            )}
                            <h3>Ответы</h3>
                            <Select
                                label="Тип ответов"
                                options={TRAINING_QUESTION_TYPES}
                                value={questionType}
                                onChange={value => {
                                    setQuestionType(value);
                                    if (value.value === 2) {
                                        setQuestionAnswers([]);
                                    } else if (value.value === 0 || value.value === 1) {
                                        setQuestionAnswers([
                                            { value1: '', value2: '', isRight: false },
                                            { value1: '', value2: '', isRight: false },
                                        ]);
                                    } else {
                                        setQuestionAnswers([{ value1: '', value2: '', isRight: false }]);
                                    }
                                }}
                            />
                            {questionType.value === 0 && (
                                <TrainingQuestionAnswerMany answers={questionAnswers} onChange={answers => setQuestionAnswers(answers)} />
                            )}
                            {questionType.value === 1 && (
                                <TrainingQuestionAnswerOne answers={questionAnswers} onChange={answers => setQuestionAnswers(answers)} />
                            )}
                            {questionType.value === 2 && <TrainingQuestionAnswerOpen />}
                            {questionType.value === 3 && (
                                <TrainingQuestionAnswerNext answers={questionAnswers} onChange={answers => setQuestionAnswers(answers)} />
                            )}
                            {questionType.value === 4 && (
                                <TrainingQuestionAnswerMatch answers={questionAnswers} onChange={answers => setQuestionAnswers(answers)} />
                            )}
                            {questionType.value === 5 && (
                                <TrainingQuestionAnswerSort answers={questionAnswers} onChange={answers => setQuestionAnswers(answers)} />
                            )}
                        </div>
                        <div className={cx.trainingQuestionCreateBottom}>
                            <Button label="Отмена" onClick={() => navigate('/training/catalog')} />
                            <Button label="Предпросмотр" onClick={() => setIsQuestionPreview(true)} />
                            <Button label="Сохранить" color="green" onClick={() => (id ? onUpdateQuestion() : onCreateQuestion())} />
                        </div>
                    </div>
                </div>
            )}
            {id && !isEdit && (
                <div className={cx.trainingQuestionView}>
                    <div className={cx.trainingQuestionViewContainer}>
                        <div className={cx.trainingQuestionViewTop}>
                            {questionImage && <img src={questionImage} alt="" />}
                            <h3>{questionTitle}</h3>

                            {questionType.value !== 2 && <p className={cx.trainingQuestionViewTopMark}>{questionMark} балла(-ов)</p>}
                            {questionType.value === 2 && <p className={cx.trainingQuestionViewTopMark}>Без оценивания</p>}

                            <div className={cx.trainingQuestionViewTopSeparator} />

                            {questionType.value === 0 && (
                                <div className={cx.trainingQuestionViewTopMany}>
                                    <p>Выберите несколько правильных ответов:</p>
                                    <ul>
                                        {questionAnswers.map(answer => {
                                            return (
                                                <li key={answer['value1']}>
                                                    <label>{answer['value1']}</label>
                                                    {answer['isRight'] && <span>Правильный ответ</span>}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            )}
                            {questionType.value === 1 && (
                                <div className={cx.trainingQuestionViewTopOne}>
                                    <p>Выберите один правильный ответ:</p>
                                    <ul>
                                        {questionAnswers.map(answer => {
                                            return (
                                                <li key={answer['value1']}>
                                                    <label>{answer['value1']}</label>
                                                    {answer['isRight'] && <span>Правильный ответ</span>}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            )}
                            {questionType.value === 2 && (
                                <div className={cx.trainingQuestionViewTopOpen}>
                                    <Icon type={Icons.INFO} width={15} height={15} />
                                    <span>Открытый ответ не имеет правильного ответа</span>
                                </div>
                            )}
                            {questionType.value === 3 && (
                                <div className={cx.trainingQuestionViewTopNext}>
                                    <p>Продолжить фразу:</p>
                                    <ul>
                                        {questionAnswers.map(answer => (
                                            <li key={answer['value1']}>{answer['value1']}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {questionType.value === 4 && (
                                <div className={cx.trainingQuestionViewTopMatch}>
                                    <p>Сопоставление (варианты ответов будут перемешаны):</p>
                                    <ul>
                                        {questionAnswers.map(answer => (
                                            <li key={answer['value1']}>
                                                {answer['value1']} - {answer['value2']}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {questionType.value === 5 && (
                                <div className={cx.trainingQuestionViewTopSort}>
                                    <p>Сортировка:</p>
                                    <ul>
                                        {questionAnswers.map(answer => (
                                            <li key={answer['value1']}>{answer['value1']}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                        <div className={cx.trainingQuestionViewBottom}>
                            <IconButton
                                type={IconButtonType.TABLE}
                                label="Удалить вопрос"
                                onClick={() => onDeleteQuestion()}
                                icon={<Icon type={Icons.TRASH} width={14} height={15} />}
                            />
                            <IconButton
                                type={IconButtonType.TABLE}
                                label="Редактировать вопрос"
                                onClick={() => navigate(`/training/catalog/question/${id}/edit`)}
                                icon={<Icon type={Icons.EDIT_PEN} width={14} height={15} />}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
