import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'shared/router';
import { useDialog, useGlobalContext, useNewsFormModel } from 'lib/hooks';
import NewsForm from './news-form';
import cs from './news-form-page.module.scss';
import Confirmation from '../confirmation';
import useAutoSave, { AutoSaveGroup } from '../../lib/hooks/useAutoSave';
import { useSelector } from 'react-redux';
import { selectUser } from '../../slice/authSlice';
import { IndexedDbKeyValueStorage } from '../../lib/storage';

export const newsFormPage = (Component, pageTitle, edit = false) => {
    const FormPage = (props) => {
        const navigate = useNavigate();
        const { dialogState, openDialog, closeDialog } = useDialog();

        const currentUser = useSelector(selectUser);
        const { uuid, type } = useParams();

        const model = useNewsFormModel(uuid, type);
        const { editor, news, loading, permissionsOk } = model;

        const { formDataChanged } = useGlobalContext();

        const onAutoSave = useCallback(() => {
            if (!formDataChanged) {
                return null;
            }

            return JSON.stringify({
                logo: news.logo,
                logoUuid: news.logoUuid,
                title: news.title,
                text: news.editor?.getData?.() || news.editor?.getHTML?.(),
                parentProjectId: news.parentProjectId,
                meta: news.meta,
                exactQueryGroups: news.exactQueryGroups,
                from: news.from,
                expireTime: news.expireTime,
                global: news.global,
                priority: news.priority,
                isTicker: news.isTicker,
                ticker: news.ticker,
                tickerColor: news.tickerColor,
                tickerTime: news.tickerTime
            });
        }, [formDataChanged, news]);

        const storage = useMemo(() => {
            return new IndexedDbKeyValueStorage();
        }, []);
        const { autoSave, isAutoSave, setIsAutoSave, clearAutoSave } = useAutoSave(
            currentUser.login,
            AutoSaveGroup.NEWS,
            uuid,
            onAutoSave,
            storage
        );

        const [isEditorInstanceReady, setIsEditorInstanceReady] = useState(false);

        useEffect(() => {
            if (!permissionsOk) {
                navigate('/401');
            }
        }, [navigate, permissionsOk]);
        useEffect(() => {
          if (editor?.instanceReady || editor?.isEditable) {
            setIsEditorInstanceReady(true);
          }
        }, [editor])

        useEffect(() => {
            if (!autoSave || !editor || !isAutoSave) {
                return;
            }

            openDialog({
                title: 'У вас есть несохраненная версия новости.',
                subTitle: 'Хотите продолжить работу с несохраненной версией документа?',
                color: 'green',
                closeBtnText: 'Нет, закрыть',
                submitBtnText: 'Продолжить работу',
                onSubmit: () => {
                    setIsAutoSave(false);
                    const data = JSON.parse(autoSave);

                    news.logo = data.logo;
                    news.logoUuid = data.logoUuid;
                    news.title = data['title'];

                    if (editor.getData) {
                        editor.insertHtml(data.text);
                    } else {
                        editor.commands.setContent(data.text);
                    }

                    news.parentProjectId = data['parentProjectId'];
                    news.meta = data['meta'];
                    news.exactQueryGroups = data['exactQueryGroups'];

                    if (data['from']) {
                        news.from = new Date(data['from']);
                    }

                    if (data['lifeTime']) {
                        news.expireTime = new Date(data['expireTime']);
                    }

                    news.global = data.global;
                    news.priority = data.priority;
                    news.isTicker = data.isTicker;
                    news.ticker = data.ticker;
                    news.tickerColor = data.tickerColor;
                    news.tickerTime = data.tickerTime;

                    news.commit();
                    closeDialog();
                },
                onClose: () => {
                    setIsAutoSave(false);

                    clearAutoSave();
                    closeDialog();
                }
            });
        }, [isAutoSave, setIsAutoSave, uuid, editor, news, autoSave, clearAutoSave, closeDialog, openDialog]);

        return (
            <div className={cs.content}>
                <Confirmation {...dialogState} />
                <div id={'newsFormPageTitle'} className={cs.pageTitle}>{pageTitle}</div>
                <NewsForm news={news} loading={loading || !isEditorInstanceReady} edit={edit}>
                    <Component {...props} {...model} clearAutoSave={clearAutoSave} />
                </NewsForm>
            </div>
        );
    };

    return FormPage;
};
