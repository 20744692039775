import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'shared/router';
import { useDialog, useGlobalContext, useMessage } from 'lib/hooks';
import { Icons } from 'uikit/icon';
import { EditRowToolbar } from 'uikit/table';
import { TableComponent } from 'components/data-components';
import api from 'api/index';
import cx from './section-tab-articles.module.scss';
import { Confirmation } from 'components/confirmation/confirmation';
import { USER_ROLES } from 'model/role';
import { useDispatch } from 'react-redux';
import { fetchMenuButtons } from 'slice/authSlice';
import TableTitleCell from 'components/table-title-cell';
import Loading from 'uikit/loading';
import { EmptyScripts } from '../projects/empty';

const ArchiveSectionTabScripts = ({ sectionId = '', tree, reloadTree, setOnReloadTreeAction }) => {
    const tableRef = useRef(null);
    const treeRef = useRef(tree);

    const { platform, isAuthorizedRole } = useGlobalContext();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {addSuccess, addError} = useMessage();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [isLoading, setIsLoading] = useState(true);
    const [, setIsInit] = useState(false);

    const [isReLoading, setIsReLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    const [scriptsTotal, setScriptsTotal] = useState(0);
    const [scripts, setScripts] = useState([]);

    const [checkedCheckbox, setCheckedCheckbox] = useState([]);
    const checkedCheckboxRef = useRef(checkedCheckbox);

    const requestScripts = useCallback(async (offset, count, sort = { by: '', desc: false }, search = '') => {
        setIsLoading(true);

        const scriptsResponse = await api.archive.getSectionScripts(sectionId, offset / count, count,
            sort.by ? (sort.by + ',' + (sort.desc ? 'desc' : 'asc')) : 'modifyTime,desc', search);

        setScriptsTotal(scriptsResponse.totalElements);
        setScripts(scriptsResponse.content);

        setIsEmpty(scriptsResponse.totalElements === 0 && !search);

        setIsLoading(false);
        setIsReLoading(false);
    }, [sectionId]);

    const checkboxHandler = useCallback((node) => {
        setCheckedCheckbox((prevValue) => {
            if (prevValue.some(c => c.id === node.id)) {
                return prevValue.filter(c => c.id !== node.id);
            } else {
                return [...prevValue, node]
            }
        })
    }, [])

    const deleteScripts = useCallback(async (data) => {
        try {
            setIsLoading(true);
            closeDialog();

            if (data?.id) {
                await api.scripting.removeScript(data.id);
            }  else if (checkedCheckboxRef.current.length) {
                for (let i = 0; i < checkedCheckboxRef.current.length; i++) {
                    await api.scripting.removeScript(checkedCheckboxRef.current[i].id);
                }
            }

            tableRef?.current?.reload();
            reloadTree();

            addSuccess(data.length ? 'Выбранные скрипты удалены' : 'Скрипт удален');
        } catch (error) {
            addError('При удалении возникла ошибка');
        } finally {
            setIsLoading(false);
        }
    }, [closeDialog, addSuccess, addError, reloadTree]);

    const onDeleteScripts = useCallback((data) => {
        if (data?.length) {
            setCheckedCheckbox(data.map(d => d.original))
        }

        let text = '';

        if (data?.length) {
            text = data.map((item) => {
                return {
                    ...item.original,
                    text: (
                        <>
                            <span onClick={() => navigate(`/archive/script/${item.original.id}`)} style={{ color: '#279BD9', cursor: 'pointer' }}>
                                {item?.original?.title}
                            </span>
                        </>
                    )
                }
            });
        } else {
            text = (
                <span>
                    Вы действительно хотите удалить скрипт&nbsp;
                    <span style={{ color: '#279BD9', cursor: 'pointer' }} onClick={() => navigate(`/archive/script/${data.id}`)}>
                        {data?.title}
                    </span>
                    &nbsp;? Этот процесс нельзя будет отменить
                </span>
            );
        }

        openDialog({
            title: 'Удаление',
            text,
            subTitle: data?.length ? 'Вы действительно хотите удалить нижеперечисленные документы? Этот процесс нельзя будет отменить' : null,
            color: 'red',
            contentType: data?.length ? 'CHECKBOX_LIST' : 'TEXT',
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Да, удалить',
            onChange: (scripts) => checkboxHandler(scripts),
            onSubmit: () => deleteScripts(data),
            onClose: closeDialog
        });
    }, [closeDialog, openDialog, deleteScripts, checkboxHandler, navigate]);

    const unArchiveScripts = useCallback(async (data) => {
        try {
            setIsLoading(true);
            closeDialog();

            if (data?.id) {
                await api.archive.moveScriptFromArchive(data.id);
            }  else if (checkedCheckboxRef.current.length) {
                for (let i = 0; i < checkedCheckboxRef.current.length; i++) {
                    await api.archive.moveScriptFromArchive(checkedCheckboxRef.current[i].id);
                }
            }

            tableRef?.current?.reload();
            reloadTree();

            dispatch(fetchMenuButtons());
            addSuccess(data.length ? 'Выбранные скрипты восстановлены' : 'Скрипт восстановлена');
        } catch (error) {
            addError('При восстановлении возникла ошибка');
        } finally {
            setIsLoading(false);
        }
    }, [dispatch, closeDialog, addSuccess, addError, reloadTree]);

    const onUnArchiveScripts = useCallback((data) => {
        if (data?.length) {
            setCheckedCheckbox(data.map(d => d.original))
        }

        let text = '';

        if (data?.length) {
            text = data.map((item) => {
                return {
                    ...item.original,
                    text: (
                        <>
                            <span onClick={() => navigate(`/archive/script/${item.original.id}`)} style={{ color: '#279BD9', cursor: 'pointer' }}>
                                {item?.original?.title}
                            </span>
                        </>
                    )
                }
            });
        } else {
            text = (
                <span>
                    Вы действительно хотите восстановить из архива скрипт&nbsp;
                    <span style={{ color: '#279BD9', cursor: 'pointer' }} onClick={() => navigate(`/archive/script/${data.id}`)}>
                        {data?.title}
                    </span>?
                </span>
            );
        }

        openDialog({
            title: 'Восстановление',
            subTitle: data?.length ? 'Вы действительно хотите восстановить из архива нижеперечисленные документы?' : null,
            text,
            contentType: data?.length ? 'CHECKBOX_LIST' : 'TEXT',
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Подтвердить',
            onChange: (scripts) => checkboxHandler(scripts),
            onSubmit: () => unArchiveScripts(data),
            onClose: closeDialog
        });
    }, [closeDialog, openDialog, unArchiveScripts, checkboxHandler, navigate]);

    const scriptsColumns = useMemo(() => [
        {
            Header: 'Содержание',
            accessor: 'title',
            Cell: (data) => (
                <TableTitleCell
                    title={data.row.original.title}
                    link={`/archive/script/${data.row.original['id']}`}
                />
            ),
        },
        {
            Header: 'Дата изменения',
            accessor: 'modifyTime',
            Cell: (data) => {
                const date = new Date().toLocaleString();
                return (
                    <div>
                        {data.isMobile && <div className={cx.subheader}>Дата изменения</div>}
                        {date.substr(0, date.length - 3)}
                    </div>
                )
                // return data.row.original['script']['lastAction']
                //     ? new Date(data.row.original['script']['lastAction']['timestamp']).toLocaleString()
                //     : new Date(data.row.original['script']['createTime']).toLocaleString();
            }
        },
        {
            Header: 'Автор',
            accessor: 'multiName',
            Cell: (data) => {
                return (
                    <div>
                        {data.isMobile && <div className={cx.subheader}>Автор</div>}
                        {data.row.original['author']['status'] === 'DELETED' && (
                            <span style={{ opacity: 1 }}>
                                {data.row.original['author']['firstName'] + ' ' + data.row.original['author']['lastName']}
                            </span>
                        )}
                        {data.row.original['author']['status'] !== 'DELETED' && (
                            <Link to={'/edit-user/' + data.row.original['author']['login']}>
                                {data.row.original['author']['firstName'] + ' ' + data.row.original['author']['lastName']}
                            </Link>
                        )}
                    </div>
                );
            },
        },
        {
            id: 4,
            width: 75,
            maxWidth: 75,
            settings: platform === 'mobile' ? ['no_td_wrap'] : [],
            Cell: function(data) {
                const toolbar = [];

                toolbar.push({
                    icon: Icons.RELOAD,
                    tooltip: 'Разархивировать',
                    onClick: (data) => onUnArchiveScripts(data),
                    isHidden: original => !original?.permissions?.canRestore,
                });

                toolbar.push({
                    icon: Icons.TRASH,
                    iconHoverColor: 'red',
                    tooltip: 'Удалить',
                    onClick: (data) => onDeleteScripts(data),
                    isHidden: original => !original?.permissions?.canDelete,
                });

                return EditRowToolbar(toolbar)(data);
            }
        }
    ], [onDeleteScripts, onUnArchiveScripts, platform]);

    const scriptsActions = useMemo(() => {
        const actions = [];

        if (isAuthorizedRole([USER_ROLES.ROLE_ROOT, USER_ROLES.ROLE_ADMIN, USER_ROLES.ROLE_SUPER_USER])) {
            actions.push({
                icon: Icons.RELOAD,
                label: 'Разархивировать',
                onClick: (data) => onUnArchiveScripts(data)
            });
        }

        if (isAuthorizedRole([USER_ROLES.ROLE_ROOT])) {
            actions.push({
                icon: Icons.TRASH,
                className: cx.deleteActionButton,
                label: 'Удалить',
                onClick: (data) => onDeleteScripts(data)
            });
        }

        return actions;
    }, [onDeleteScripts, onUnArchiveScripts, isAuthorizedRole]);

    useEffect(() => {
        checkedCheckboxRef.current = checkedCheckbox;
    }, [checkedCheckbox]);

    useEffect(() => {
        setIsReLoading(true);
        setIsEmpty(false);

        setScriptsTotal(0);
        setIsInit(prev => {
            if (!prev) {
                return !prev;
            }

            tableRef?.current?.reload();
            return prev;
        });
    }, [sectionId]);

    useEffect(() => {
        if (tree) {
            treeRef.current = tree;
        }
    }, [tree]);

    useEffect(() => { setOnReloadTreeAction(() => () => tableRef?.current?.reload()) }, [setOnReloadTreeAction]);

    return (
        <div className={cx.tabArticles}>
            <Confirmation {...dialogState} />
            {isLoading && isReLoading && (
                <div className={cx.loader}>
                    <Loading withOverlay={false} />
                </div>
            )}
            {isEmpty && !isLoading && (
                <EmptyScripts />
            )}
            {!isEmpty && (
                <TableComponent
                    innerRef={tableRef}
                    isLoading={isLoading}
                    archive={true}
                    title="Скрипты"
                    searchTitle="Поиск по автору и названию"
                    columns={scriptsColumns}
                    actions={scriptsActions}
                    total={scriptsTotal}
                    data={scripts}
                    onPaginate={requestScripts}
                    isMobile={platform === 'mobile'}
                    defaultSort={{ by: 'modifyTime', desc: true }}
                />
            )}
        </div>
    );
};

export default ArchiveSectionTabScripts;
