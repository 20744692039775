import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { findParentNodeClosestToPos } from '@tiptap/core';
import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';
import Icon from 'uikit/icon/icon';
import { Menu, MenuItem } from '../DropdownMenu/DropdownMenu';
import { MenuButtonColorPicker } from '../Menu/MenuButtonColorPicker';
import cx from '../../editor.module.scss';
import { CellSelection } from '../../extensions/Table/cellSelection';
import { selectedRect } from 'editors/tiptap-editor/extensions/Table/commands';
import { findParentNode } from 'editors/tiptap-editor/utils/findParentNode';
import { TextSelection } from '@tiptap/pm/state';
import { PREVENT_COL_CLICK_RESET_META } from '../../extensions/Table/extension-col-click-reset';

export const BubbleMenuTableCell = ({ isActive, currentMenu, setCurrentMenu }) => {
    const bubbleMenuRef = useRef(null);
    const colorPickerMenuRef = useRef(null);

    const editor = useEditorContext();

    const selection = editor?.state.selection;
    const view = editor?.view;

    const [isMergeCellShow, setIsMergeCellShow] = useState(false);
    const [mode, setMode] = useState('DESKTOP');

    const [bgColor, setBgColor] = useState('rgba(0, 0, 0, 1)');
    const [borderColor, setBorderColor] = useState('rgba(0, 0, 0, 1)');

    const isAddColumnBlock = useMemo(() => {
        if (!editor?.state) {
            return false;
        }
        const rect = selectedRect(editor.state);

        if (!rect) {
            return false;
        }
        const tableHeader = findParentNode(editor.state, 'tableHeader');

        return tableHeader && rect.left === 0 ? true : false;
    }, [editor?.state]);
    const isAddRowBlock = useMemo(() => {
        if (!editor?.state) {
            return false;
        }
        const rect = selectedRect(editor.state);

        if (!rect) {
            return false;
        }

        const tableHeader = findParentNode(editor.state, 'tableHeader');

        return tableHeader && rect.top === 0 ? true : false;
    }, [editor?.state]);

    const [isMenuDown, setIsMenuDown] = useState(false);

    const cellButtonsConfig = useMemo(
        () => [
            {
                name: 'Объединить ячейки',
                action: editor => editor.chain().focus().mergeCells().run(),
                icon: Icons.EditorIconTableJoinCell,
                type: 'joinCells',
            },
            {
                name: 'Добавить столбец справа',
                action: editor => editor.chain().addColumnAfter().run(),
                icon: Icons.EditorIconTableAddColumnLeft,
            },
            {
                name: 'Добавить столбец слева',
                action: editor => !isAddColumnBlock && editor.chain().addColumnBefore().run(),
                icon: Icons.EditorIconTableAddColumnRight,
                disabled: isAddColumnBlock,
            },
            {
                name: 'Добавить строку выше',
                action: editor => !isAddRowBlock && editor.chain().addRowBefore().run(),
                icon: Icons.EditorIconTableAddRowBottom,
                disabled: isAddRowBlock,
            },
            {
                name: 'Добавить строку ниже',
                action: editor => editor.chain().addRowAfter().run(),
                icon: Icons.EditorIconTableAddRowTop,
            },
            {
                name: 'Вертикальное выравнивание',
                action: (editor, align) => editor.chain().changeVerticalAlign(align).run(),
                icon: Icons.EditorIconJustify,
                type: 'verticalAlign',
            },
            {
                name: 'Очистить содержимое',
                action: editor => {
                    editor.chain().focus().clearCell().run();
                },
                icon: Icons.EditorIconCleanFormat,
                type: 'clearCell',
            },
            {
                name: 'Цвет границ',
                action: (editor, color) => editor.chain().setBorderColor(color).run(),
                icon: Icons.EditorIconTableTextColor,
                type: 'borderColor',
            },
            {
                name: 'Цвет ячейки',
                action: (editor, color) => editor.chain().setCellAttribute('bgcolor', color).run(),
                icon: Icons.EditorIconTableCellColor,
                type: 'bgcolor',
            },
            {
                name: 'Удалить столбец',
                action: editor => editor.chain().focus().deleteColumn().run(),
                icon: Icons.EditorIconTableRemove,
            },
            {
                name: 'Удалить строку',
                action: editor => editor.chain().focus().deleteRow().run(),
                icon: Icons.EditorIconTableRemove,
            },
        ],
        [isAddColumnBlock, isAddRowBlock]
    );

    const onClick = (btn, color) => {
        if (btn.type === 'clearCell') {
            const { pos, node } = findParentNodeClosestToPos(editor?.state.selection.$anchor, node => {
                return node.type.name === 'tableCell';
            });

            const startPosition = pos;
            const endPosition = pos + node.nodeSize;

            let {
                state: { tr },
                dispatch,
            } = editor.view;

            tr.replaceWith(
                startPosition - 1,
                endPosition,
                editor.state.schema.nodes.tableCell.create(
                    { rowspan: node.attrs.rowspan, colspan: node.attrs.colspan, 'data-border-color': node.attrs['data-border-color'] },
                    editor.schema.nodes.paragraph.create()
                )
            );

            tr.setSelection(TextSelection.create(tr.doc, pos + 1, pos + 1));
            dispatch(tr);
            editor.commands.focus();
        } else if (btn.type === 'borderColor') {
            setBorderColor(color);
            btn.action(editor, color);
        } else if (btn.type === 'bgcolor') {
            setBgColor(color);
            btn.action(editor, color);
        } else {
            btn.action(editor);
        }
    };
    const onVerticalAlignChange = align => {
        editor.commands.changeVerticalAlign(align);
    };

    const onMenuOpen = () => {
        const sel = editor.state.selection;

        setIsMergeCellShow(sel instanceof CellSelection && sel.$anchorCell.pos !== sel.$headCell.pos);
    };
    const getItemPosition = useCallback(() => {
        if (!isActive) {
            return {};
        }

        let nearestParent;

        if (selection?.node?.type.name !== 'cell' || selection.node.type.name !== 'header_cell') {
            nearestParent = findParentNodeClosestToPos(selection.$anchor, node => {
                return node.type.spec.tableRole === 'cell' || node.type.spec.tableRole === 'header_cell';
            });
        } else {
            nearestParent = { pos: selection.$from.pos };
        }

        if (!nearestParent) {
            return {};
        }

        const wrapperDomNode = view?.nodeDOM(nearestParent.pos);

        if (!wrapperDomNode) {
            return {};
        }

        const contentRect = document.getElementById('editor-content').getBoundingClientRect();
        const cellRect = wrapperDomNode.getBoundingClientRect();

        const isBtnOutsideContent = cellRect.right - contentRect.left - 12 > contentRect.width - 30;

        const top =
            mode === 'DESKTOP'
                ? cellRect.top - contentRect.top + 'px'
                : cellRect.top - contentRect.top + cellRect.height + 8 + 'px';

        const left =
            mode === 'DESKTOP'
                ? cellRect.right - contentRect.left - 12 + 'px'
                : cellRect.right - contentRect.left - cellRect.width / 2 - bubbleMenuRef.current?.clientWidth / 2 + 'px';

        return {
            position: 'absolute',
            top,
            left,
            opacity: isBtnOutsideContent ? 0 : 1,
            pointerEvents: isBtnOutsideContent ? 'none' : 'auto',
        };
    }, [isActive, selection, view, mode]);

    useEffect(() => {
        setMode(window.innerWidth > 920 ? 'DESKTOP' : window.innerWidth > 550 ? 'TABLET' : 'MOBILE');

        const onResize = () => {
            setMode(window.innerWidth > 920 ? 'DESKTOP' : window.innerWidth > 550 ? 'TABLET' : 'MOBILE');
        };

        window.addEventListener('resize', onResize, true);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    return (
        <div
            ref={bubbleMenuRef}
            style={{
                display: editor?.isActive('tableCell') || editor?.isActive('tableHeader') ? 'flex' : 'none',
                alignItems: 'center',
                gap: 6,
                ...getItemPosition(),
            }}
            onMouseDown={() => {
                setIsMenuDown(true);
            }}
            onMouseUp={e => {
                e.preventDefault();

                if (isMenuDown) {
                    setIsMenuDown(false);
                    return;
                }

                editor.view.dispatch(editor.state.tr.setMeta(PREVENT_COL_CLICK_RESET_META, true));
            }}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <Menu
                mode={mode}
                name="table-cell"
                currentMenu={currentMenu}
                setCurrentMenu={setCurrentMenu}
                menuContentStyles={{ overflow: 'initial' }}
                label={
                    <div className="trigger-button" onClick={onMenuOpen}>
                        <Icon type={Icons.EditorIconMore} width={18} height={18} />
                    </div>
                }
            >
                {cellButtonsConfig.map(btn => {
                    if (btn.type === 'joinCells' && !isMergeCellShow) {
                        return null;
                    }

                    if (btn.type === 'verticalAlign') {
                        return (
                            <Menu
                                mode={mode}
                                name="table-cell"
                                currentMenu={currentMenu}
                                setCurrentMenu={setCurrentMenu}
                                key={btn.name}
                                menuStyles={{ overflow: 'initial' }}
                                menuContentStyles={{ padding: 0, overflow: 'initial' }}
                                one={true}
                                label={
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                                        <Icon type={btn.icon} width={18} height={18} /> <span>{btn.name}</span>
                                    </div>
                                }
                            >
                                <MenuItem
                                    styles={{ height: 'auto' }}
                                    label={onClose => <div onClick={onClose}>Сверху</div>}
                                    onClick={() => onVerticalAlignChange('top')}
                                />
                                <MenuItem
                                    styles={{ height: 'auto' }}
                                    label={onClose => <div onClick={onClose}>По центру</div>}
                                    onClick={() => onVerticalAlignChange('middle')}
                                />
                                <MenuItem
                                    styles={{ height: 'auto', marginBottom: mode !== 'DESKTOP' ? '41px' : 0 }}
                                    label={onClose => <div onClick={onClose}>Снизу</div>}
                                    onClick={() => onVerticalAlignChange('bottom')}
                                />
                            </Menu>
                        );
                    }
                    if (btn.type === 'borderColor' || btn.type === 'bgcolor') {
                        return (
                            <Menu
                                mode={mode}
                                name="table-cell"
                                currentMenu={currentMenu}
                                setCurrentMenu={setCurrentMenu}
                                ref={colorPickerMenuRef}
                                key={btn.name}
                                menuStyles={{ overflow: 'initial' }}
                                menuContentStyles={{ padding: 0, overflow: 'initial' }}
                                one={true}
                                label={
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                                        <Icon type={btn.icon} width={18} height={18} /> <span>{btn.name}</span>
                                    </div>
                                }
                            >
                                <MenuItem
                                    className={cx.menuItemColorPicker}
                                    label={onClose => {
                                        return (
                                            <MenuButtonColorPicker
                                                onChange={color => onClick(btn, color)}
                                                onClose={onClose}
                                                value={btn.type === 'bgcolor' ? bgColor : borderColor}
                                            />
                                        );
                                    }}
                                />
                            </Menu>
                        );
                    }

                    return (
                        <MenuItem
                            key={btn.name}
                            onClick={() => onClick(btn)}
                            disabled={btn.disabled}
                            label={onClose => (
                                <div style={{ display: 'flex', alignItems: 'center', gap: 6 }} onClick={onClose}>
                                    <Icon type={btn.icon} width={18} height={18} /> <span>{btn.name}</span>
                                </div>
                            )}
                        />
                    );
                })}
            </Menu>
        </div>
    );
};
