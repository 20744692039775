import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useAuthorized, useGlobalContext } from 'lib/hooks';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import { selectNewsPicker } from 'slice/newsPickerSlice';
import { ContentWrapper } from 'containers/content-wrapper';
import { FoldersTree, MobileTree, NODE_TYPES } from 'components/trees';
import { FilesPageOwnFilesTab } from './components/files-page-own-files-tab';
import { FilesPageUnpublishedFilesTab } from './components/files-page-unpublished-files-tab';
import Icon, { Icons } from 'uikit/icon';
import cs from './files-page.module.scss';
import { useCustomMobileMenu } from 'lib/hooks/useCustomMobileMenu';
import TreeUtils from 'lib/util/tree.util';
import { useWindowSize } from 'lib/hooks/useWindowSize';
import { RouteGroup, Route, useNavigate, useParams, setDocumentTitle } from 'shared/router';

export const FilesPage = () => {
    const navigate = useNavigate();
    const params = useParams();

    const { width } = useWindowSize();

    const { platform } = useGlobalContext();
    const treeRef = useRef(null);
    const { isAuthorizedAction } = useAuthorized();

    const [showTree, setShowTree] = useState(platform !== 'mobile' ? true : false);
    const [tab, setActiveTab] = useState(null);
    const newsPicker = useSelector(selectNewsPicker);

    const onTabChange = tab => {
        let url = '/files' + (tab ? '/' + tab : '');
        navigate(url);
        setActiveTab(tab);
    };

    const onNavigate = useCallback(
        treeNode => {
            if (!treeNode) {
                navigate('/files/all', { replace: true });
            } else if (treeNode.nodeType === NODE_TYPES.PROJECT) {
                navigate(`/files/all/${treeNode.id}`, { replace: true });
            } else if (treeNode.nodeType === NODE_TYPES.SECTION) {
                navigate('/files/all/' + TreeUtils.getTopParentNode(treeNode).id + '/' + treeNode.id.toLowerCase().replace(/_/g, '-'), { replace: true });
            }
        },
        [navigate]
    );

    const getNodeLink = useCallback(treeNode => {
        if (!treeNode) {
            return '/files/all';
        } else if (treeNode.nodeType === NODE_TYPES.PROJECT) {
            return '/files/all/' + treeNode.id;
        } else if (treeNode.nodeType === NODE_TYPES.SECTION) {
            return '/files/all/' + TreeUtils.getTopParentNode(treeNode).id + '/' + treeNode.id.toLowerCase().replace(/_/g, '-');
        }
    }, []);

    useCustomMobileMenu(
        useCallback(
            onClose => {
                return (
                    <MobileTree
                        ref={treeRef}
                        allButtonTitle="Все проекты"
                        withArticles={false}
                        getNodeLink={node => {
                            if (node) {
                                return `/files/all/${node.id}`;
                            } else {
                                return '/files/all';
                            }
                        }}
                        onSelect={node => {
                            onClose();

                            if (node !== null) {
                                return;
                            }

                            treeRef?.current?.tree.selectNode(node, { silent: true });
                            navigate('/files/all');
                        }}
                    />
                );
            },
            [navigate, treeRef]
        )
    );

    useEffect(() => {
        if (!params['*']) {
            navigate('/files/all', { replace: true });
            setActiveTab('all');

            if (treeRef?.current?.tree) {
                treeRef.current.tree.selectNode(null);
            }
        } else {
            const index = params['*'].indexOf('/');

            setActiveTab(index !== -1 ? params['*'].slice(0, index) : params['*']);
        }
    }, [navigate, params, treeRef]);

    useEffect(() => {
        setDocumentTitle('Файлы — KMS Gran');
    }, []);

    return (
        <ContentWrapper>
            <div className={cs.toggleTreeButton} onClick={() => setShowTree(!showTree)}>
                <Icon type={Icons.SANDWICH} width={20} height={14} />
            </div>
            <div className={classNames(cs.base, { [cs.mobile]: platform === 'mobile' })}>
                {platform === 'desktop' && (
                    <div
                        className={classNames(cs.treeContainer, {
                            [cs.treeContainerHidden]: width >= 768 && width <= 1024 && !showTree,
                            [cs.treeHidden]: width > 1024 && !showTree,
                        })}
                        onClick={() => setShowTree(!showTree)}
                    >
                        <div
                            className={classNames(cs.tree, { [cs.withNewsTicker]: newsPicker.count > 0 })}
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <div className={cs.hideButton} onClick={() => setShowTree(isShow => !isShow)}>
                                <Icon className={cs.icon} type={Icons.ARROW_RIGHT} width={14} height={14} color="blue" />
                            </div>
                            {tab !== 'unpublished' && (
                                <FoldersTree
                                    ref={treeRef}
                                    path="files/all"
                                    allButtonTitle="Все проекты"
                                    getNodeLink={getNodeLink}
                                    onNavigate={onNavigate}
                                    scrollToNode={params['*'].replace('all', '')}
                                />
                            )}
                            {tab === 'unpublished' && (
                                <div className={cs.noTree}>
                                    <Icon type={Icons.TEXT_ALIGN_LEFT} width={24} height={24} />
                                    <div>Иерархия отсутствует</div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div
                    className={classNames(cs.content, {
                        [cs.contentWithoutTree]: !showTree && width > 1024,
                    })}
                >
                    {!showTree && (
                        <span className={cs.showButton} onClick={() => setShowTree(isShow => !isShow)}>
                            <Icon className={cs.icon} type={Icons.ARROW_RIGHT} width={14} height={14} />
                        </span>
                    )}
                    <ul>
                        <li className={tab === 'all' ? cs.active : ''} onClick={() => onTabChange('all')}>
                            Общие файлы
                        </li>
                        {isAuthorizedAction([GLOBAL_ACTIONS.FILE_UNATTACHED_LIST]) && (
                            <li className={tab === 'unpublished' ? cs.active : ''} onClick={() => onTabChange('unpublished')}>
                                Неприкрепленные
                            </li>
                        )}
                    </ul>
                    <div className={cs.tableWrapper}>
                        <RouteGroup className={cs.router}>
                            <Route to="all" component={<FilesPageOwnFilesTab treeRef={treeRef} />} />
                            <Route to="all/:projectUuid" component={<FilesPageOwnFilesTab treeRef={treeRef} />} />
                            <Route
                                to="all/:projectUuid/:sectionUuid"
                                component={<FilesPageOwnFilesTab treeRef={treeRef} />}
                            />
                            {isAuthorizedAction([GLOBAL_ACTIONS.FILE_UNATTACHED_LIST]) && (
                                <Route to="unpublished" component={<FilesPageUnpublishedFilesTab />} />
                            )}
                        </RouteGroup>
                    </div>
                </div>
            </div>
        </ContentWrapper>
    );
};
