import React, { useEffect, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';

import { MenuButton } from './MenuButton';
import Icon, { Icons } from 'uikit/icon';
import cn from './DropdownMenuButton.module.scss';
import cx from '../../editor.module.scss';

type DropdownListItem = {
    id: string;
    icon: React.ReactNode;
    tooltip: string;
};

interface DropdownListMenuButtonProps {
    tooltip: React.ReactNode;
    button: React.ReactNode;
    inline: boolean;
    content: DropdownListItem[];
    selected: string;
    onChange: (_id: string) => void;
}

export const DropdownMenuButton = ({ content, selected, inline, tooltip, button, onChange }: DropdownListMenuButtonProps) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(isOpen => !isOpen);
    };

    const onClick = (id: string) => {
        onChange(id);
        setIsOpen(false);
    };

    useEffect(() => {
        function closeMenu(e: MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target as HTMLElement)) {
                setIsOpen(false);
                document.removeEventListener('click', closeMenu);
            }
        }

        if (isOpen) {
            document.addEventListener('click', closeMenu);
        }

        return () => {
            document.removeEventListener('click', closeMenu);
        };
    }, [isOpen]);

    return (
        <div>
            <Tippy content={tooltip} disabled={!tooltip} placement="top">
                <Tippy
                    placement="bottom"
                    interactive={true}
                    arrow={false}
                    visible={isOpen}
                    content={
                        <div
                            ref={dropdownRef}
                            className={classNames(cn.dropdownMenuList, {
                                [cn.inline]: inline,
                            })}
                        >
                            {content.map(i => {
                                return (
                                    <MenuButton
                                        key={i.id}
                                        tooltip={i.tooltip}
                                        icon={i.icon}
                                        hover
                                        className={cx.bubbleMenuButton}
                                        selected={i.id === selected}
                                        onClick={() => onClick(i.id)}
                                    />
                                );
                            })}
                        </div>
                    }
                >
                    <div
                        className={classNames(cn.dropdownMenuButton, {
                            [cn.active]: selected,
                        })}
                    >
                        {button}
                        <div onClick={toggleDropdown}>
                            <Icon type={Icons.EditorIconArrowDown} width={12} height={12} />
                        </div>
                    </div>
                </Tippy>
            </Tippy>
        </div>
    );
};
