import React from 'react';
import Modal from 'react-modal';
import { MenuButton } from './MenuButton';
import { useEditorContext, useEditorNotificationContext } from '../../context';
import { Icons } from 'uikit/icon';
import Input from 'uikit/input/input';
import { desktopStyles } from 'lib/util/modalStyles';
import { ModalBody, ModalFooter, ModalHeader } from 'components/modal/modal-components';
import Checkbox from 'uikit/checkbox/checkbox';

export const MenuButtonSearchAndReplace = ({ mode, setCurrentMenu, isTooltip }) => {
    const editor = useEditorContext();
    const { pushNotification } = useEditorNotificationContext();

    const [searchTerm, setSearchTerm] = React.useState('');
    const [replaceTerm, setReplaceTerm] = React.useState('');

    const [sensitive, setSensitive] = React.useState(false);
    const [wholeWord, setWholeWord] = React.useState(false);

    const [isOpen, setIsOpen] = React.useState(false);

    const clearForm = () => {
        editor.commands.resetSearch();
        setSearchTerm('');
        setReplaceTerm('');
        setSensitive(false);
        setWholeWord(false);
    };

    const toggleModal = () => {
        clearForm();

        setCurrentMenu(undefined);
        setIsOpen(prev => !prev);
    };

    const replaceAll = () => {
        if (editor.storage.searchAndReplace.results.length) {
            pushNotification({ message: 'Произведено замен: ' + editor.storage.searchAndReplace.results.length });
            editor.commands.replaceAll();
        }
        toggleModal();
    };

    const onSearchTermChange = term => {
        setSearchTerm(term);
        editor.commands.setSearchTerm(term);
    };

    const onReplaceTermChange = term => {
        setReplaceTerm(term);
        editor.commands.setReplaceTerm(term);
    };
    const onSensitiveChange = checked => {
        setSensitive(checked);
        editor.commands.setCaseSensitive(checked);
    };

    const onWholeWordChange = checked => {
        setWholeWord(checked);
        editor.commands.setCaseWholeWord(checked);
    };

    return (
        <>
            <MenuButton
                icon={Icons.EditorIconSearchAndReplace}
                onClick={toggleModal}
                tooltip={isTooltip === false ? null : 'Замена'}
                label="Замена"
            ></MenuButton>

            <Modal
                isOpen={isOpen}
                preventScroll
                style={{
                    ...desktopStyles,
                    content: { ...desktopStyles.content, maxWidth: 570, width: 'calc(100% - 20px)', border: 'none' },
                }}
            >
                <ModalHeader title="Замена" onClose={toggleModal} />

                <ModalBody>
                    <Input
                        wrapperStyle={{ paddingBottom: 20 }}
                        style={{ fontSize: mode === 'MOBILE' ? '13px' : '14px' }}
                        label="Найти"
                        value={searchTerm}
                        placeholder="Введите слово, которое хотите заменить"
                        onChange={onSearchTermChange}
                    />
                    <Input
                        wrapperStyle={{ paddingBottom: 20 }}
                        style={{ fontSize: mode === 'MOBILE' ? '13px' : '14px' }}
                        label="Заменить на"
                        value={replaceTerm}
                        placeholder="Введите слово, на которое хотите заменить"
                        onChange={onReplaceTermChange}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Checkbox checked={sensitive} label="Учитывать регистр" onChange={e => onSensitiveChange(e.target.checked)} />
                        <Checkbox checked={wholeWord} label="Только слово целиком" onChange={e => onWholeWordChange(e.target.checked)} />
                    </div>
                </ModalBody>

                <ModalFooter
                    buttons={[
                        { label: 'Отмена', onClick: toggleModal },
                        { label: 'Заменить', color: 'blue', onClick: replaceAll },
                    ]}
                />
            </Modal>
        </>
    );
};
