import create from 'zustand';
import { IEmbedDrawIoDialogStore, EEmbedDrawIoMode } from '../index';


const initialMainState = {
    mode: EEmbedDrawIoMode.EDIT_MODE,
    isDialogLoaded: false,
    isEditorDialogOpen: false,
    isPreviewDialogOpen: false,
    currentBase64Image: '',
} as const;

const initialOptions = {
    baseEncodedXml: // alpha=0 1x1 px
        'PG14ZmlsZT4KPGRpYWdyYW0gbmFtZT0iLi4uIj4KPG14R3JhcGhNb2RlbCBwYWdlPSIwIj4KPHJvb3Q+CjxteENlbGwgaWQ9IjAiIC8+CjxteENlbGwgaWQ9IjEiIHBhcmVudD0iMCIgLz4KPC9yb290Pgo8L214R3JhcGhNb2RlbD4KPC9kaWFncmFtPgo8L214ZmlsZT4=',
    iframeSandbox: 
        'allow-scripts allow-same-origin allow-popups allow-forms',
    drawIoOriginLink: 
        'https://drawio.gran-soft.ru',
    drawIoParamsForEditMode:
        '/?embed=1&offline=1&lang=ru&pv=0&ruler=1&modified=1&hide-pages=1&nav=1&proto=json&modified=unsavedChanges&saveAndExit=1&noSaveBtn=1&splash=0',
    drawIoParamsForViewerMode: 
        '/?embed=1&offline=1&lang=ru&proto=json&lightbox=1&nav=1',
    drawIoParamsForDesktop: 
        '&ui=min',
    drawIoParamsForMobile: 
        '&ui=sketch',
} as const;

export const useEmbedDrawIoDialogStore = create<IEmbedDrawIoDialogStore>(set => ({
    ...initialMainState,
    options: { ...initialOptions },
    setMode: mode => set({ mode }),
    setDialogLoaded: isDialogLoaded => set({ isDialogLoaded }),
    setIsEditorDialog: isEditorDialogOpen => set({ isEditorDialogOpen }),
    setIsPreviewDialog: isPreviewDialogOpen => set({ isPreviewDialogOpen }),
    setCurrentBase64Image: currentBase64Image => set({ currentBase64Image }),
    updateOptions: options => set(state => ({ options: { ...state.options, ...options } })),
    resetDialogMainState: () => set(initialMainState),
}));
