import React, { useEffect, useState, useRef, useMemo } from 'react';
import classNames from 'classnames';
import { useCombinedRefs } from 'lib/hooks';
import { idFromString } from 'lib/helpers';
import cx from './input-multi-value.module.scss';
import Icon, { Icons } from 'uikit/icon';
import { v1 } from 'uuid';

const InputMultiValue = ({
    id = null,
    label = '',
    placeholder = '',
    error,
    className = '',
    value = [],
    onClick = () => {},
    onChange = () => {},
    onBlur = () => {},
    type = 'text',
    forwardedRef,
    required,
    disabled,
    children: _,
    ...attrs
}) => {
    const key = useMemo(() => id || idFromString(label) || idFromString(attrs.placeholder), [id, label, attrs.placeholder]);

    const [inputValue, setInputValue] = useState('');
    const [values, setValues] = useState(Array.isArray(value) && value.length ? value : []);
    const [inputWidth, setInputWidth] = useState(5);

    const clsName = classNames(cx.base, className, {
        [cx.error]: error,
    });

    const bufferRef = useRef();
    const innerRef = useCombinedRefs(forwardedRef);

    const renderDescription = () => {
        if (typeof attrs.description === 'function') {
            return attrs.description();
        }

        return attrs.description;
    };

    const _onChange = (value) => {
        const _value = value.trim();

        if (_value && _value.indexOf(',') !== -1) {
            parseInputValue(_value);
        } else {
            setInputValue(value);
        }
    };

    const _onBlur = () => {
        parseInputValue(inputValue);
        onBlur();
    };

    const _onKeyDown = (e) => {
        if (e.keyCode === 13) {
            parseInputValue(inputValue);
        }
    };

    const parseInputValue = (value) => {
        let str;

        if (value.indexOf(',') !== -1) {
            str = value.length > 1 && value.slice(0, value.indexOf(',')).trim();
        } else {
            str = value.trim();
        }

        if (str) {
            setValues((prevState) => {
                const newValue = [...prevState, { label: str, value: v1() }];

                onChange(newValue);

                return newValue;
            });
        }

        setInputValue('');
    };

    const removeValue = (e, value) => {
        e.stopPropagation();
        setValues((prevState) => {
            const newValue = prevState.filter((v) => v.value !== value);

            onChange(newValue);

            return newValue;
        });
    };

    const removeAll = (e) => {
      e.stopPropagation();
      setValues([]);
      onChange([]);
    }

    useEffect(() => {
        setValues(value);
    }, [value]);

    useEffect(() => {
        setInputWidth(bufferRef.current.clientWidth);
    }, [bufferRef, inputValue]);

    return (
        <div className={clsName}>
            {label.length > 1 ? (
                <label
                    htmlFor={key}
                    className={classNames(cx.label, {
                        [cx.required]: required,
                    })}
                >
                    {label}
                </label>
            ) : (
                ''
            )}
            <div
                className={classNames(cx.inputWrapper, {
                    [cx.withDesc]: attrs.description,
                    [cx.disabled]: disabled,
                    [cx.error]: error,
                })}
                onClick={() => {
                    onClick();
                    innerRef.current.focus();
                }}
            >
                <div className={cx.valuesWrapper}>
                    {values.map((v, idx) => {
                        return (
                            <div key={idx} className={cx.valueWrapper}>
                                {v.img && (
                                    <div className={cx.imgWrapper}>
                                        <img src={v.img} alt="" />
                                    </div>
                                )}
                                <span>{v.label}</span>
                                {!disabled && (
                                    <div className={cx.remove} onClick={(e) => removeValue(e, v.value)}>
                                        <Icon type={Icons.CROSS} width={6} height={6} color={'white'} />
                                    </div>
                                )}
                            </div>
                        );
                    })}
                    <input
                        id={key}
                        ref={innerRef}
                        value={inputValue}
                        className={classNames(cx.input, { 'field-invalid': !!error })}
                        autoComplete={attrs.type === 'password' ? 'on' : 'off'}
                        onChange={(e) => _onChange(e.target.value)}
                        onBlur={_onBlur}
                        onKeyDown={_onKeyDown}
                        type={type}
                        placeholder={placeholder}
                        disabled={disabled}
                        style={{
                            ...attrs?.style,
                            width: inputWidth,
                        }}
                        {...attrs}
                    />
                    <div ref={bufferRef} className={cx.buffer}>
                        {inputValue}
                    </div>
                </div>
                {values?.length ? <div className={cx.removeAll} onClick={(e) => removeAll(e)}>
                    <Icon type={Icons.CROSS} />
                </div> : null}
            </div>
            {!!attrs.description && !error && <span className={cx.description}>{renderDescription()}</span>}
            {!!error && <span className={cx['error-message']}>{error}</span>}
        </div>
    );
};

export default React.forwardRef((props, ref) => <InputMultiValue {...props} forwardedRef={ref} />);
