import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { selectRoles } from 'slice/authSlice';
import CommonDialog from 'components/trees/common-dialog';
import { Select } from 'uikit/select';
import api from 'api/index';
import cs from './user-access-dialog.module.scss';
import { useGlobalContext } from 'lib/hooks';

const UserAccessDialog = ({ open, onDismiss = () => {}, onSubmit = () => {}, activeProjectData, mode }) => {
    const { platform } = useGlobalContext();
    const [init, setInit] = useState(false);

    const rolesData = useSelector(selectRoles);
    const isRoot = rolesData['hasSuperUserRole'] || rolesData['hasAdminRole'] || rolesData['hasRootRole'];

    const [projects, setProjects] = useState([]);
    const [activeProject, setActiveProject] = useState(null);

    const [roles, setRoles] = useState([]);
    const [activeRule, setActiveRule] = useState(null);

    const [projectError, setProjectError] = useState('');
    const [ruleError, setRuleError] = useState('');

    const _onSubmit = () => {
        if (activeProject == null) {
            setProjectError('Поле не может быть пустым');
            return;
        }
        if (activeRule == null) {
            setRuleError('Поле не может быть пустым');
            return;
        }

        onSubmit(null, activeRule, activeProject, activeProjectData, projects);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const tmpProjects = await api.lock.getLockableProjects();
                const projectsFormat = tmpProjects.filter(v => v.resourceType === 'PROJECT').map(item => {
                    return {
                        id: item.resourceId,
                        label: item.title,
                        value: item.resourceId,
                        logo: item.smallLogoUuid,
                        permittedRoles: item.permittedRoles.map(item => {
                            return { label: item['title'], value: item['id'] };
                        })
                    };
                }).sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 0);

                let roles = projectsFormat.length !== 0 ? projectsFormat[0]['permittedRoles'] : [];

                projectsFormat.map((project) => {
                    return roles.filter((r) => {
                        return project['permittedRoles'].includes(r);
                    });
                });

                projectsFormat.unshift({
                    id: 0,
                    label: 'Все доступные проекты',
                    value: 0,
                    logo: null,
                    permittedRoles: roles
                });
                setRoles(roles);

                setProjects(projectsFormat);
                setInit(true);
            } catch {

            }
        };
        fetchData();
    }, [isRoot]);
    useEffect(() => {
        if (activeProjectData !== null) {
            let tmpSelectedProject = projects.find(item => item.id === activeProjectData.projectId);
            setActiveProject(tmpSelectedProject);

            let tmpSelectedRule = roles.find(item => item.value === activeProjectData.roleId);
            setActiveRule(tmpSelectedRule);
        } else {
            setActiveProject(null);
            setActiveRule(null);
        }
    }, [open, activeProjectData, projects, roles]);

    return (
        <CommonDialog id={'userAccessDialogContainer'} open={open} title={'Добавление доступа'} onSubmit={_onSubmit} onDismiss={onDismiss} mode={mode}>
            {init &&
            <div className={classNames(cs.selectors, {[cs.mobile]: platform === 'mobile'})}>
                <Select options={projects} value={activeProject} onChange={(e) => setActiveProject(e)}
                        error={projectError} placeholder='Выберите проект' label='Проект' isClearable
                        disabled={projects.length === 0} id={'userAccessProject'}/>
                <Select value={activeRule} onChange={(e) => setActiveRule(e)} error={ruleError}
                        placeholder='Выберите роль' label='Роль' isClearable disabled={roles.length === 0}
                        id={'userAccessRole'} options={activeProject ? activeProject['permittedRoles'] : roles}/>
            </div>}
        </CommonDialog>
    );
};

export default UserAccessDialog;
