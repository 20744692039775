import { useCallback } from 'react';
import { useNavigate } from 'shared/router';
import { useGlobalContext } from 'lib/hooks';

export function useCancelBehavior() {
    const navigate = useNavigate();
    const { visitedUrls, setVisitedUrls, setFormDataChanged } = useGlobalContext();

    const url = visitedUrls[visitedUrls.length - 2] ?? '/';

    const cancel = useCallback(() => {
        const visitedUrlsClone = [...visitedUrls];
        visitedUrlsClone.length = visitedUrlsClone.length ? visitedUrlsClone.length - 1 : 0;

        setVisitedUrls(visitedUrlsClone);
        setFormDataChanged(false);

        // window.history.pushState(null, '', url);
        navigate(url, 'no-check-form-data-changed');

        // if (window.history.length > 0) {
        //     window.history.back();
        // } else {
        //     navigate('/');
        // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, setFormDataChanged, setVisitedUrls, url]);

    return { cancel };
}
