import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { selectUsers } from 'slice/authSlice';
import { Link, useNavigate } from 'shared/router';
import { useDialog, useGlobalContext, useMessage } from 'lib/hooks';
import Confirmation from 'components/confirmation';
import { NewsComponent, TableComponent } from 'components/data-components';
import Icon, { Icons } from 'uikit/icon';
import { EditRowToolbar } from 'uikit/table';
import api from 'api';
import cx from './global-tab-news.module.scss';
import formatDateTime from 'lib/helpers/formatDateTime';
import Tippy from '@tippyjs/react';
import { USER_ROLES } from 'model/role';
import { fetchMenuButtons } from 'slice/authSlice';
import { MultiClumpTooltip } from 'components/MultiClumpTooltip/MultiClumpTooltip';
import { EmptyNews } from '../projects/empty';

const GlobalTabNews = () => {
    const tableRef = useRef(null);

    const dispatch = useDispatch();
    const { platform, isAuthorizedRole } = useGlobalContext();

    const selector = useSelector(state => state);
    const navigate = useNavigate();

    const { dialogState, openDialog, closeDialog } = useDialog();
    const { addSuccess, addError } = useMessage();

    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [isEmpty, setIsEmpty] = useState(false);
    const [isSearch, setIsSearch] = useState(false);

    const [type, setType] = useState(0);

    const [newsTotal, setNewsTotal] = useState(0);
    const [news, setNews] = useState([]);

    const [checkedCheckbox, setCheckedCheckbox] = useState([]);
    const checkedCheckboxRef = useRef(checkedCheckbox);

    const requestNews = async (offset, count, sort = { by: '', desc: false }, search = '', filters = {}) => {
        try {
            setIsLoading(true);

            const newsResponse = await api.archive.getNews(offset / count, count,
                sort.by ? (sort.by + ',' + (sort.desc ? 'desc' : 'asc')) : '', search, filters);

            setNewsTotal(newsResponse.totalElements);
            setNews(newsResponse.content);

            setIsSearch(!!search);
            setIsEmpty(newsResponse.totalElements === 0 && _.isEmpty(filters));

            setIsLoading(false);
        } catch (e) {
            console.log(e);
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
            setIsLoading(false);
        }
    };
    const checkboxHandler = useCallback((node) => {
        setCheckedCheckbox((prevValue) => {
          if (prevValue.some(c => c.id === node.id)) {
              return prevValue.filter(c => c.id !== node.id);
          } else {
              return [...prevValue, node]
          }
        })
    }, [])

    const removeNews = useCallback(async (data) => {
        try {
            setIsLoading(true);
            closeDialog();

            if (data?.id) {
                await api.news.deleteNews(data.id);
            }  else if (checkedCheckboxRef.current.length) {
                for (let i = 0; i < checkedCheckboxRef.current.length; i++) {
                    await api.news.deleteNews(checkedCheckboxRef.current[i].id);
                }
            }

            setNews([]);
            tableRef?.current?.reload();

            addSuccess(data.id ? 'Новость удалена' : 'Выбранные новости удалены');
        } catch (error) {
            addError('При удалении произошла ошибка');
        } finally {
            setIsLoading(false);
        }
    }, [closeDialog, addSuccess, addError]);
    const onNewsRemove = useCallback((data) => {
        if (data?.length) {
            setCheckedCheckbox(data.map(d => d.original))
        }

        let text = '';

        if (data?.length) {
            text = data.map((item) => {
                return {
                    ...item.original,
                    text: (
                        <>
                            <span onClick={() => navigate(`/projects/news/${item.original.id}`)} style={{ color: '#279BD9', cursor: 'pointer' }}>
                                {item?.original?.title}
                            </span>
                        </>
                    )
                }
            });
        } else {
            text = (
                <span>
                    Вы действительно хотите удалить новость&nbsp;
                    <span style={{ color: '#279BD9', cursor: 'pointer' }} onClick={() => navigate(`/projects/news/${data.id}`)}>
                        {data?.title}
                    </span>?
                </span>
            );
        }

        openDialog({
            title: 'Удаление',
            subTitle: data?.length ? 'Вы действительно хотите удалить нижеперечисленные объекты? Этот процесс нельзя будет отменить' : null,
            text,
            color: 'red',
            contentType: data?.length ? 'CHECKBOX_LIST' : 'TEXT',
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Да, удалить',
            onChange: (news) => checkboxHandler(news),
            onSubmit: () => removeNews(data),
            onClose: closeDialog,
        });
    }, [navigate, closeDialog, openDialog, removeNews, checkboxHandler]);

    const unArchiveNews = useCallback(async (data) => {
        try {
            setIsLoading(true);
            closeDialog();

            if (data?.id) {
                await api.archive.moveNewsFromArchive(data.id);
            }  else if (checkedCheckboxRef.current.length) {
                for (let i = 0; i < checkedCheckboxRef.current.length; i++) {
                    await api.archive.moveNewsFromArchive(checkedCheckboxRef.current[i].id);
                }
            }

            dispatch(fetchMenuButtons());

            setNews([]);
            tableRef?.current?.reload();

            addSuccess(data.id ? 'Новость восстановлена' : 'Выбранные новости восстановлены');
        } catch (error) {
            addError('При восстановлении произошла ошибка');
        } finally {
            setIsLoading(false);
        }
    }, [dispatch, closeDialog, addSuccess, addError]);

    const onNewsUnArchive = useCallback((data) => {
        if (data?.length) {
            setCheckedCheckbox(data.map(d => d.original))
        }

        let text = '';

        if (data?.length) {
            text = data.map((item) => {
                return {
                    ...item.original,
                    text: (
                        <>
                            <span onClick={() => navigate(`/archive/news/${item.original.id}`)} style={{ color: '#279BD9', cursor: 'pointer' }}>
                                {item?.original?.title}
                            </span>
                        </>
                    )
                }
            });
        } else {
            text = (
                <span>
                    Вы действительно хотите восстановить из архива новость&nbsp;
                    <span style={{ color: '#279BD9', cursor: 'pointer' }} onClick={() => navigate(`/archive/news/${data.id}`)}>
                        {data?.title}
                    </span> ?
                </span>
            );
        }

        openDialog({
            title: 'Восстановление',
            subTitle: data?.length ? 'Вы действительно хотите восстановить из архива нижеперечисленные объекты?' : null,
            text,
            contentType: data?.length ? 'CHECKBOX_LIST' : 'TEXT',
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Подтвердить',
            onChange: (news) => checkboxHandler(news),
            onSubmit: () => unArchiveNews(data),
            onClose: closeDialog,
        });
    }, [navigate, closeDialog, openDialog, unArchiveNews, checkboxHandler]);

    const newsColumns = useMemo(() => [
        {
            Header: 'Содержание',
            accessor: 'title',
            width: 300,
            Cell: (data) => {
                if (data.isMobile) {
                    return (
                        <div className={classNames(cx.titleBlock, cx.mobileTitleBlock)}
                             onClick={() => navigate(`/archive/news/${data.row.original['id']}`)}>
                            {(data.row.original['priority'] === 'HIGH' || !data.row.original['project']) &&
                            <div className={cx.titleBlockTags}>
                                {data.row.original['priority'] === 'HIGH' &&
                                <span className={cx.yellow}>Срочная новость</span>}
                                {!data.row.original['project'] && <span className={cx.blue}>Общая новость</span>}
                            </div>}
                            <MultiClumpTooltip className={cx.title} clamp={2} label={data.row.original['title']} />
                            {data.row.original['project'] &&
                            <div className={cx.titleBlockProject}>
                                <div className={cx.titleBlockProjectAvatar}>
                                    {data.row.original['project']['logoUuid'] &&
                                    <img src={api.upload.getImage(data.row.original['project']['logoUuid'], false, 128)} alt="" />}
                                </div>
                                <span>{data.row.original['project']['title']}</span>
                            </div>}
                            <div className={classNames(cx.titleBlockLogo, cx.logoMobile)}>
                                {data.row.original['logoUuid'] &&
                                <img src={api.upload.getImage(data.row.original['logoUuid'], false, 512)} alt="" />}
                                {!data.row.original['logoUuid'] && <img src="/img/news-card-cover.jpg" alt="" />}
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <Link to={`/archive/news/${data.row.original['id']}`} className={cx.titleBlock}>
                            <div className={cx.titleBlockLogo}>
                                {data.row.original['logoUuid'] &&
                                <img src={api.upload.getImage(data.row.original['logoUuid'], false, 512)} alt="" />}
                                {!data.row.original['logoUuid'] && <img src="/img/news-card-cover.jpg" alt="" />}
                            </div>
                            <div>
                                <div className={cx.titleBlockTags}>
                                    {data.row.original['priority'] === 'HIGH' &&
                                    <span className={cx.yellow}>Срочная новость</span>}
                                    {!data.row.original['project'] && <span className={cx.blue}>Общая новость</span>}
                                </div>

                                <MultiClumpTooltip label={data.row.original['title']} />

                                {data.row.original['project'] &&
                                <div className={cx.titleBlockProject}>
                                    <div className={cx.titleBlockProjectAvatar}>
                                        {data.row.original['project']['logoUuid'] &&
                                        <img src={api.upload.getImage(data.row.original['project']['logoUuid'], false, 128)}
                                             alt="" />}
                                    </div>
                                    <span className={cx.projectTitle}>
                                        <Tippy content={data.row.original['project']['title']}>
                                            <>{data.row.original['project']['title']}</>
                                        </Tippy>
                                    </span>
                                </div>}
                            </div>
                        </Link>
                    );
                }
            }
        },
        {
            Header: 'Дата изменения',
            accessor: 'modifyTime',
            width: 140,
            Cell: (data) => {
                const modifyTime = data.row.original['modifyTime'];
                const createTime = data.row.original['createTime'];

                return (
                    <div>
                        {data.isMobile && <div className={cx.subheader}>Дата изменения</div>}
                        {/*{modifyTime*/}
                        {/*    ? modifyTime.substr(0, modifyTime.length - 3)*/}
                        {/*    : createTime.substr(0, createTime.length - 3)}*/}
                        <div>
                            {formatDateTime(modifyTime ? modifyTime : createTime, true)}
                        </div>
                    </div>
                )
            }
        },
        {
            Header: 'Автор',
            accessor: 'author',
            width: 140,
            Cell: (data) => {
                return (
                    <div>
                        {data.isMobile && <div className={cx.subheader}>Автор</div>}
                        {data.row.original['author']['status'] === 'DELETED' ?
                            <span style={{opacity: 1}}>{data.row.original['author']['firstName'] + ' ' + data.row.original['author']['lastName']}</span>
                            : <Link to={'/edit-user/' + data.row.original['author']['login']}>
                                 {data.row.original['author']['firstName'] + ' ' + data.row.original['author']['lastName']}
                              </Link>}
                    </div>
                );
            }
        },
        {
            id: 4,
            settings: platform === 'mobile' ? ['no_td_wrap'] : [],
            width: 65,
            Cell: (data) => {
                let toolbar = [];

                toolbar.push({
                    icon: Icons.RELOAD,
                    tooltip: 'Разархивировать',
                    onClick: (data) => onNewsUnArchive(data),
                    isHidden: original => !original?.permissions?.canRestore
                });

                toolbar.push({
                    icon: Icons.TRASH,
                    iconHoverColor: 'red',
                    tooltip: 'Удалить документ',
                    onClick: (data) => onNewsRemove(data),
                    isHidden: original => !original?.permissions?.canDelete
                });

                return EditRowToolbar(toolbar)(data);
            }
        }
    ], [navigate, onNewsUnArchive, platform, onNewsRemove]);
    const newsActions = useMemo(() => {
        const actions = [];

        if (isAuthorizedRole([USER_ROLES.ROLE_ROOT, USER_ROLES.ROLE_ADMIN, USER_ROLES.ROLE_SUPER_USER])) {
            actions.push({
                icon: Icons.RELOAD,
                label: 'Разархивировать',
                onClick: (data) => onNewsUnArchive(data)
            });
        }

        if (isAuthorizedRole([USER_ROLES.ROLE_ROOT])) {
            actions.push({
                icon: Icons.TRASH,
                className: cx.deleteActionButton,
                label: 'Удалить',
                onClick: (data) => onNewsRemove(data)
            });
        }

        return actions;
    }, [isAuthorizedRole, onNewsUnArchive, onNewsRemove]);

    const loadUsers = async (search) => {
        let options = [];

        if (search) {
            options = users.filter(user => {
              return user.label.toString().toLowerCase().includes(search.toLowerCase());
            })
        }

        return { options }
    }

    const newsFilters = [
        {
            'label': 'Дата создания',
            'fields': [
                {
                    'name': 'createTime',
                    'type': 'date-select',
                    'default': {
                        'type': {
                            label: 'Все время',
                            value: 0
                        },
                        'from': null,
                        'to': null
                    }
                }
            ]
        },
        {
            'label': 'Автор',
            'fields': [
                {
                    'name': 'authorLogin.in',
                    'type': 'search',
                    'default': null,
                    'isMulti': true,
                    'loadOptions': loadUsers
                }
            ]
        },
        {
            'label': 'Искать по',
            'fields': [
                {
                    'name': 'global.in',
                    'type': 'select',
                    'default': null,
                    'options': [
                      { label: 'Все новости', value: '' },
                      { label: 'Проектные новости', value: 'false' },
                      { label: 'Общие новости', value: 'true' },
                    ]
                }
            ]
        },
        {
            'label': 'Приоритет',
            'fields': [
                {
                    'name': 'priority.in',
                    'type': 'select',
                    'default': null,
                    'options': [
                      { label: 'Любой', value: '' },
                      { label: 'Срочные новости', value: 'HIGH' },
                      { label: 'Обычные новости', value: 'MEDIUM' },
                    ]
                }
            ]
        },
        {
            'label': 'Срок действия новости',
            'fields': [
                {
                    'name': 'expireTime.greaterOrEqualThan',
                    'type': 'date',
                    'default': null
                },
                {
                    'name': 'expireTime.lessOrEqualThan',
                    'type': 'date',
                    'default': null
                }
            ]
        },
        {
            'label': 'Дата добавления в архив',
            'fields': [
                {
                    'name': 'archiveAddTime.greaterOrEqualThan',
                    'type': 'date',
                    'default': null
                },
                {
                    'name': 'archiveAddTime.lessOrEqualThan',
                    'isEndOfTheDay': true,
                    'type': 'date',
                    'default': null
                }
            ]
        },
        {
            'label': 'Участник, архивировавший новость',
            'fields': [
                {
                    'name': 'archiveUserLogin.in',
                    'type': 'search',
                    'default': null,
                    'isMulti': true,
                    'loadOptions': loadUsers
                }
            ]
        },

    ];

    useEffect(() => {
        checkedCheckboxRef.current = checkedCheckbox;
    }, [checkedCheckbox]);
    useEffect(() => {
        const fetchData = async () => {
            const usersResponse = await selectUsers(selector);
            setUsers(usersResponse.content?.map(user => ({
                label: user.firstName + ' ' + user.lastName,
                value: user.login,
            })));
        };
        fetchData();
    }, [selector]);

    return (
        <div className={cx.tabNews}>
            <Confirmation {...dialogState} />
            {isEmpty && !isSearch && (
                <EmptyNews />
            )}
            {(!isEmpty || isSearch) && (
                <div className={cx.news}>
                    <div className={cx.head}>
                        <h2>Новости</h2>
                        <ul>
                            <li className={type === 0 ? cx.active : ''} onClick={() => setType(0)}>
                                <Icon type={Icons.BURGER} width={12} height={12} />
                            </li>
                            <li className={type === 1 ? cx.active : ''} onClick={() => setType(1)}>
                                <Icon type={Icons.PANEL} width={12} height={12} />
                            </li>
                        </ul>
                    </div>
                    {type === 0 && (
                        <TableComponent
                            innerRef={tableRef}
                            isLoading={isLoading}
                            searchTitle="Поиск по автору и названию"
                            columns={newsColumns}
                            actions={newsActions}
                            total={newsTotal}
                            data={news}
                            onPaginate={requestNews}
                            filters={newsFilters}
                            isMobile={platform === 'mobile'}
                            defaultSort={{ by: 'modifyTime', desc: true }}
                            sortOptions={[
                                { Header: 'По дате создания', accessor: 'createTime' },
                                { Header: 'По дате архивации (по умолчанию)', accessor: 'modifyTime' },
                                { Header: 'По названию (по алфавиту)', accessor: 'title' },
                                { Header: 'По автору (по алфавиту по фамилии)', accessor: 'multiName' },
                                { Header: 'По количеству лайков', accessor: 'likesCount' },
                                { Header: 'По количеству дизлайков', accessor: 'dislikesCount' },
                                { Header: 'По популярности (кол-во просмотров)', accessor: 'readCount' },
                                { Header: 'По количеству комментариев', accessor: 'commentsCount' },
                                { Header: 'По рейтингу (оценка пользователя после прочтения)', accessor: '' },
                            ]}
                        />
                    )}
                    {type === 1 && (
                        <NewsComponent
                            isLoading={isLoading}
                            searchTitle="Поиск по автору и названию"
                            total={newsTotal}
                            data={news}
                            onPaginate={requestNews}
                            filters={newsFilters}
                            onRemove={(item) => onNewsRemove(item)}
                            onArchive={(item) => onNewsUnArchive(item)}
                            isMobile={platform === 'mobile'}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default GlobalTabNews;
