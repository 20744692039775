import { Node } from '@tiptap/core';

export const Br = Node.create({
    name: 'br',
    // content: 'inline*',
    group: 'inline',
    inline: true,

    parseHTML() {
        return [{ tag: 'br', priority: 199999 }];
    },
    renderHTML({ HTMLAttributes }) {
        return ['br', HTMLAttributes];
    },
});
