import React, { useEffect, useMemo, useRef, useState } from 'react';
import Slider from 'react-slick';
import { useEventListener } from 'lib/hooks';
import * as _ from 'lodash';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function SampleNextArrow(props) {
    const { className, show, style, onClick } = props;

    if (!show) {
      return null;
    }

    return <div className={className} style={{ ...style, display: 'block', background: 'red' }} onClick={onClick} />;
}

const Carousel = ({ settings = {}, children, className = '', itemWidth }) => {
    const sliderRef = useRef();

    const [slidesToShow, setSlidesToShow] = useState(1);
    const [showArrows, setShowArrows] = useState(false);

    settings = useMemo(() => {
        return Object.assign(
            {
                infinite: false,
                swipeToSlide: true,
                variableWidth: true,
                slidesToShow,
                slidesToScroll: 1,
                nextArrow: <SampleNextArrow show={showArrows} />,
            },
            settings
        );
    }, [settings, slidesToShow, showArrows]);

    useEffect(() => {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 400);
    }, []);

    useEventListener(
        'resize',
        _.debounce(() => {
            if (!sliderRef.current) {
                return;
            }
            
            setShowArrows(Math.floor(sliderRef.current.innerSlider.list.offsetWidth < (itemWidth + 24) * children.length));
            setSlidesToShow(Math.floor(sliderRef.current.innerSlider.list.offsetWidth / (itemWidth + 24)) || 1);
        }, 400)
    );

    return (
        <Slider ref={sliderRef} {...settings} className={className}>
            {children}
        </Slider>
    );
};

export default React.forwardRef((props, ref) => <Carousel {...props} forwardedRef={ref} />);
