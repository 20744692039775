import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'shared/router';
import { useArticleFormModel, useDialog, useGlobalContext } from 'lib/hooks';
import { Icons } from 'uikit/icon';
import Loading from 'uikit/loading';
import Card from 'components/card';
import ArticleForm from './article-form';
import api from 'api';
import cs from './article-form-page.module.scss';
import useAutoSave, { AutoSaveGroup } from '../../lib/hooks/useAutoSave';
import Confirmation from '../confirmation';
import { useSelector } from 'react-redux';
import { selectUser } from '../../slice/authSlice';
import { IndexedDbKeyValueStorage } from '../../lib/storage';
import { EmptySectionsArticle } from '../projects/empty';

export const articleFormPage = (Component, pageTitle, edit = false) => {
    const FormPage = (props) => {
        const navigate = useNavigate();
        const { dialogState, openDialog, closeDialog } = useDialog();

        const currentUser = useSelector(selectUser);
        const { uuid, type } = useParams();

        const model = useArticleFormModel(uuid, type);
        const { editor, article, loading, permissionsOk } = model;

        const { formDataChanged } = useGlobalContext();

        const onAutoSave = useCallback(() => {
            if (!formDataChanged) {
                return null;
            }

            return JSON.stringify({
                logo: article.logo,
                logoUuid: article.logoUuid,
                title: article.title,
                description: article.description,
                text: article.editor?.getData?.() || article.editor?.getHTML?.(),
                chatAIIndexingEnabled: article.chatAIIndexingEnabled,
                parentProjectId: article.parentProjectId,
                parentSectionId: article.parentSectionId,
                meta: article.meta,
                exactQueryGroups: article.exactQueryGroups,
                from: article.from,
                lifeTime: article.lifeTime,
            });
        }, [formDataChanged, article]);

        const storage = useMemo(() => {
            return new IndexedDbKeyValueStorage();
        }, []);
        const { autoSave, isAutoSave, setIsAutoSave, clearAutoSave } = useAutoSave(
            currentUser.login,
            AutoSaveGroup.ARTICLE,
            uuid,
            onAutoSave,
            storage
        );

        const [isEmpty, setIsEmpty] = useState(false);
        const [isFetching, setIsFetching] = useState(true);
        const [isEditorInstanceReady, setIsEditorInstanceReady] = useState(false);

        useEffect(() => {
            const fetchSections = async () => {
                try {
                    setIsFetching(true);
                    const response = await api.globalMenu.hasAccess();

                    setIsEmpty(!response || !response.hasAccessToSections);
                    setIsFetching(false);
                } catch {
                    setIsEmpty(true);
                    setIsFetching(false);
                }
            };
            fetchSections();
        }, []);
        useEffect(() => {
            if (!permissionsOk) {
                navigate('/401');
            }
        }, [navigate, permissionsOk]);
        useEffect(() => {
          if (editor?.instanceReady || editor?.isEditable) {
            setIsEditorInstanceReady(true);
          }
        }, [editor]);

        useEffect(() => {
            if (!autoSave || !editor || !isAutoSave) {
                return;
            }

            openDialog({
                title: 'У вас есть несохраненная версия статьи.',
                subTitle: 'Хотите продолжить работу с несохраненной версией документа?',
                color: 'green',
                closeBtnText: 'Нет, закрыть',
                submitBtnText: 'Продолжить работу',
                onSubmit: () => {
                    setIsAutoSave(false);
                    const data = JSON.parse(autoSave);

                    article.logo = data.logo;
                    article.logoUuid = data.logoUuid;
                    article.title = data['title'];
                    article.description = data['description'];

                    if (editor.getData) {
                        editor.insertHtml(data.text);
                    } else {
                        editor.commands.setContent(data.text);
                    }

                    article.chatAIIndexingEnabled = data['chatAIIndexingEnabled'];
                    article.parentProjectId = data['parentProjectId'];
                    article.parentSectionId = data['parentSectionId'];
                    article.meta = data['meta'];
                    article.exactQueryGroups = data['exactQueryGroups'];

                    if (data['from']) {
                        article.from = new Date(data['from']);
                    }

                    if (data['lifeTime']) {
                        article.lifeTime = new Date(data['lifeTime']);
                    }

                    article.commit();
                    closeDialog();
                },
                onClose: () => {
                    setIsAutoSave(false);

                    clearAutoSave();
                    closeDialog();
                }
            });
        }, [isAutoSave, setIsAutoSave, uuid, editor, article, autoSave, clearAutoSave, closeDialog, openDialog]);

        if (isFetching) {
            return (
                <Loading
                    withOverlay={false}
                    withRelativeOverlay={true}
                />
            );
        }

        return (
            <div className={cs.content}>
                <Confirmation {...dialogState} />
                <div
                    id="articleFormPageTitle"
                    className={cs.pageTitle}
                >
                    {pageTitle}
                </div>
                {!isEmpty && (
                    <ArticleForm article={article} loading={loading || !isEditorInstanceReady} edit={edit}>
                        <Component {...props} {...model} clearAutoSave={clearAutoSave} />
                    </ArticleForm>
                )}
                {isEmpty && (
                    <Card className={cs.emptyCard}>
                        <EmptySectionsArticle buttons={[{
                            icon: Icons.ADD,
                            title: 'Создать раздел',
                            onClick: () => navigate('/add/section')
                        }]} />
                    </Card>
                )}
            </div>
        );
    };

    return FormPage;
};
