/* eslint-disable no-unused-vars */
enum ELogMessage {
    FAILED_TO_FIND_DOC_BY_ID = 'Не удалось найти статью / новость по id',
    FAILED_TO_FIND_TITLE_BY_ID = 'Не удалось найти заголовок по id',
    FAILED_TO_SEND_DOC_TO_PRINT = 'Не удалось отправить статью / новость на печать',
    ERROR_COPYING_STYLES = 'Ошибка при копировании стилей',
    ERROR_PARSING_STYLES = 'Ошибка при обработке стилей'
};

interface IArgs { contentID: string; titleID: string; descID?: string; };


const changeDetailsState = (iframe: HTMLIFrameElement, isPrinting: boolean): void => {
    const details = iframe?.contentWindow?.document.body.querySelectorAll('details');
    
    if (details)
        for (let i = 0; i < details.length; i++) {
            if (isPrinting) {
                if (details[i].open) details[i].dataset.open = '1';
                else details[i].setAttribute('open', '');
            }
            else {
                if (details[i].dataset.open) details[i].dataset.open = '';
                else details[i].removeAttribute('open');
            }
        }
}

const parseStyles = async (sourceDoc: Document | null, targetDoc: Document | null): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
        try {
            Array.from(sourceDoc!.styleSheets).forEach((styleSheet) => {
                try {
                    const cssText = Array
                        .from(styleSheet.cssRules)
                        .map(rule => rule.cssText)
                        .join('\n');
                    const newStyle = targetDoc!.createElement('style');
                    newStyle.textContent = cssText;
                    targetDoc!.head.appendChild(newStyle);
                } catch (err) {
                    console.warn(ELogMessage.ERROR_COPYING_STYLES, err);
                    reject(err);
                }
            })
            setTimeout(resolve, 400);
        }
        catch (err) {
            console.warn(ELogMessage.ERROR_PARSING_STYLES, err);
            reject(err);
        }
    })
}

export const preparePrint = async ({ contentID, titleID, descID }: IArgs): Promise<void> => {

    const originalIframe = document.getElementById(contentID) as HTMLIFrameElement | null;
    if (!originalIframe || !originalIframe.contentWindow) {
        console.warn(ELogMessage.FAILED_TO_FIND_DOC_BY_ID, `'${contentID}'`);
        return Promise.reject(ELogMessage.FAILED_TO_SEND_DOC_TO_PRINT);
    }

    const titleElement: HTMLElement | null = document.getElementById(titleID);
    const descElement: HTMLElement | null = descID ? document.getElementById(descID) : null;

    const additionalContent: string | null = titleElement 
        ? `<div>
                <h1 class="h1">${ titleElement.textContent }</h1>
                ${ descElement 
                    ? ('<p style="margin: 20px 0;">' + descElement.textContent + '</p>') 
                    : '' }
                <div style="height: 1px; background: #eaedf3;"></div>
            </div>`
        : null;

    if (!additionalContent) {
        console.warn(ELogMessage.FAILED_TO_FIND_TITLE_BY_ID, `'${titleID}'`);
        originalIframe.contentWindow.print();
        return;
    }

    try {
        const tempIframe = document.createElement('iframe');
        Object.assign(tempIframe.style, { 
            position: 'absolute', 
            width: '0px', 
            height: '0px', 
            border: 'none', 
            visibility: 'hidden' 
        });
        document.body.appendChild(tempIframe);

        const tempDoc = tempIframe.contentDocument as Document;
        const originalDoc = originalIframe.contentDocument as Document;

        tempDoc.open();
        tempDoc.write(`
            <html>
                <head>
                    ${ originalDoc.head.innerHTML }
                </head>
                <body>
                    ${ additionalContent }
                    ${ originalDoc.body.innerHTML }
                </body>
            </html>
        `);
        tempDoc.close();

        tempIframe.onload = async () => {
            try {
                changeDetailsState(tempIframe, true);
                await parseStyles(originalDoc, tempDoc);
                tempIframe.contentWindow!.print();
                document.body.removeChild(tempIframe);
            } 
            catch {
                document.body.removeChild(tempIframe);
                originalIframe.contentWindow!.print();
            }
        }
    }
    catch (err) {
        console.warn(ELogMessage.FAILED_TO_SEND_DOC_TO_PRINT, err);
        originalIframe.contentWindow.print();
    }
}
