const autoResizeIframe = (iframe: HTMLIFrameElement) => {
    if (!iframe || !iframe.contentWindow || !iframe.contentWindow.document || !iframe.contentWindow.document.body) {
        throw new Error('Input iframe is incorrect or its content is inaccessible.');
    }

    iframe.style.overflow = 'hidden';
    iframe.scrolling = 'no';

    const observer = new ResizeObserver(entries => {
        for (const entry of entries) {
            iframe.style.height = `${entry.target.scrollHeight}px`;
        }
    });

    try {
        observer.observe(iframe.contentWindow.document.body);
    } catch (error) {
        throw new Error(`Failed to observe iframe content: ${error}`);
    }

    return () => {
        observer.disconnect();
    };
};

export default autoResizeIframe;
