import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDialog, useGlobalContext, useMessage } from 'lib/hooks';
import ruGrammar from 'lib/util/ruGrammar.util';
import {
    selectCommentsList,
    selectReplyComment,
    selectEditComment,
    selectIsLoading,
    selectIsPosting,
    selectText,
    fetchCommentsList,
    postComment,
    putComment,
    deleteComment,
    setCommentReplyFor,
    setCommentEditFor,
    invalidateState,
    likeComment,
    dislikeComment,
    setText,
    // selectPaging
} from 'slice/commentsSlice';
import Loading from 'uikit/loading';
import Textarea from 'uikit/textarea';
import Button from 'uikit/button';
import Confirmation from 'components/confirmation';
import CommentItem from './comment-item';
import api from 'api';
import cs from './comments-component.module.scss';
// import Pagination from 'uikit/pagination/pagination';

// const PAGE_SIZE = 20;

const Comments = ({ id, of, session, isArchive = false }) => {
    const dispatch = useDispatch();
    const { platform } = useGlobalContext();

    const { addSuccess } = useMessage();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const commentList = useSelector(selectCommentsList);

    const replyComment = useSelector(selectReplyComment);
    const editComment = useSelector(selectEditComment);

    const isLoading = useSelector(selectIsLoading);
    const isPosting = useSelector(selectIsPosting);

    // const paging = useSelector(selectPaging);
    // const [offset, setOffset] = useState(0);

    const text = useSelector(selectText);
    const [textRows, setTextRows] = useState(1);

    const remote = React.useMemo(() => ({
        /*getCommentsF:
            of === Comments.ARTICLES ? (uuid) => api.articleComments.getArticleComments(uuid, Math.round(offset / PAGE_SIZE)) :
                of === Comments.NEWS ? (uuid) => api.newsComments.getNewsComments(uuid, Math.round(offset / PAGE_SIZE)) : null,*/
        getCommentsF:
            of === Comments.ARTICLES ? (uuid) => api.articleComments.getArticleComments(uuid, 0, 2000) :
                of === Comments.NEWS ? (uuid) => api.newsComments.getNewsComments(uuid, 0, 2000) : null,
        postCommentF:
            of === Comments.ARTICLES ? api.articleComments.postComment :
                of === Comments.NEWS ? api.newsComments.postComment : null,
        likeCommentF:
            of === Comments.ARTICLES ? api.like.addArticleCommentLike :
                of === Comments.NEWS ? api.like.addNewsCommentLike : null,
        dislikeCommentF:
            of === Comments.ARTICLES ? api.dislike.addArticleCommentDislike :
                of === Comments.NEWS ? api.dislike.addNewsCommentDislike : null,
        getCommentLikesF:
            of === Comments.ARTICLES ? api.like.getArticleCommentLikes :
                of === Comments.NEWS ? api.like.getNewsCommentLikes : null,
        getCommentDislikesF:
            of === Comments.ARTICLES ? api.dislike.getArticleCommentDislikes :
                of === Comments.NEWS ? api.dislike.getNewsCommentDislikes : null,
        editCommentF:
            of === Comments.ARTICLES ? api.articleComments.editComment :
                of === Comments.NEWS ? api.newsComments.editComment : null,
        deleteCommentF:
            of === Comments.ARTICLES ? api.articleComments.deleteComment :
                of === Comments.NEWS ? api.newsComments.deleteComment : null
    }), [of/*, offset*/]);

    React.useEffect(() => {
        dispatch(fetchCommentsList(remote.getCommentsF, id));

        return () => {
            dispatch(invalidateState());
        };
    }, [dispatch, id, remote]);

    const addComment = () => {
        if (text.length > 0) {
            dispatch(postComment(remote.postCommentF, id, [], text, () => {
                dispatch(fetchCommentsList(remote.getCommentsF, id));
                setTextRows(1);
            }));
        } else {
            // setTextError(true);
        }
    };

    const onReplySubmit = (path, text) => {
        dispatch(postComment(remote.postCommentF, id, path, text));
    };

    const onEditSubmit = (id, path, text) => {
        dispatch(putComment(remote.editCommentF, id, path, text));
    };

    const onReplyComment = ({ id, path }) => {
        dispatch(setCommentReplyFor({ id, path }));
        setTimeout(() => {
            document.getElementById('reply-input').scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }, 100);
    };

    const onEditComment = ({ id, path }) => {
        dispatch(setCommentEditFor({ id, path }));
    };

    const onCancelReplyingOrEditing = () => {
        dispatch(setCommentReplyFor(null));
        dispatch(setCommentEditFor(null));
    };

    const onLike = ({ id, path, liked, disliked }) => {
        if (!liked && disliked) {
            onDislike({ id, path, liked, disliked });
        }
        dispatch(likeComment(remote.likeCommentF, remote.getCommentLikesF, session.login, id, path));
    };

    const onDislike = ({ id, path, liked, disliked }) => {
        if (!disliked && liked) {
            onLike({ id, path, liked, disliked })
        }
        dispatch(dislikeComment(remote.dislikeCommentF, remote.getCommentDislikesF, session.login, id, path));
    };

    const onDeleteAccepted = (data) => {
        const { id: commentId, path } = data;
        const successCallback = () => {
            closeDialog();
            addSuccess('Комментарий удалён');
            dispatch(fetchCommentsList(remote.getCommentsF, id));
        };

        dispatch(deleteComment(remote.deleteCommentF, commentId, path, successCallback));
    };

    const onCommentRemove = (data) => {
        openDialog({
            title: 'Удаление',
            text: 'Удалить комментарий? Этот процесс нельзя будет отменить',
            color: 'red',
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Да, удалить',
            onSubmit: () => onDeleteAccepted(data),
            onClose: closeDialog
        });
    }

    const onTextChange = str => {
        dispatch(setText(str));
        setTextRows(str.split('\n').length);
    };

    const onTextKeydown = event => {
        const disabled = isPosting || isLoading || isArchive || text.length === 0;

        if (!disabled && !event.shiftKey && event.keyCode === 13) {
            addComment();
        }
    };

    return (
        <div className={cs.comments} id={'js-comments'}>
            <Confirmation {...dialogState} />

            <h2>Комментарии</h2>

            {isLoading && commentList.length === 0 && <div className={cs.loader}><Loading withOverlay={false} small/></div>}

            {(!isLoading || commentList.length !== 0) &&
            <div id={'commentsComponentNewCommentField'} className={cs.container}>
                <h3>Оставьте свой комментарий</h3>
                <Textarea
                    style={{ resize: 'none' }}
                    rows={textRows}
                    placeholder="Введите комментарий..."
                    disabled={isPosting || isLoading || isArchive}
                    value={text}
                    onChange={onTextChange}
                    onKeyDown={onTextKeydown}
                />
                <Button color="green" disabled={isPosting || isLoading || isArchive || text.length === 0} onClick={addComment}>
                    Отправить
                </Button>
                <div className={cs.line}/>
                <div className={cs.sortWrapper}>
                    <div className={cs.commentsCount}>
                        {commentList.length}
                        {' '}
                        {ruGrammar.plural(commentList.length, ['комментариев', 'комментарий', 'комментария'])}
                    </div>
                    {/*{platform !== 'mobile' && <div className={cs.sorting}>*/}
                    {/*    <div>Сортировать:</div>*/}
                    {/*    <div>По дате добавления</div>*/}
                    {/*</div>}*/}
                </div>
                <div>
                    {commentList.map((comment, i) => (
                        <CommentItem
                            key={i}
                            comment={comment}
                            path={[]}
                            replyComment={replyComment}
                            editComment={editComment}
                            onReply={onReplyComment}
                            onEdit={onEditComment}
                            onCancel={onCancelReplyingOrEditing}
                            onReplySubmit={onReplySubmit}
                            onEditSubmit={onEditSubmit}
                            onToggleLike={onLike}
                            onToggleDislike={onDislike}
                            onDelete={onCommentRemove}
                            isPosting={isPosting}
                            loggedIn={session?.login}
                            isMobile={platform === 'mobile'}
                            isArchive={isArchive}
                        />
                    ))}
                </div>
                {/*<Pagination*/}
                {/*    total={paging?.totalElements}*/}
                {/*    count={PAGE_SIZE}*/}
                {/*    offset={paging?.number * PAGE_SIZE}*/}
                {/*    onPaginate={(offset, _) => setOffset(offset)} isMobile={false}*/}
                {/*/>*/}
            </div>}
        </div>
    );
};

Comments.ARTICLES = 'articles';
Comments.NEWS = 'news';

export default Comments;
