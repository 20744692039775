import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import Modal from 'react-modal';
import cx from './mobile-dropdown-menu.module.scss';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import { useNavigate } from 'shared/router';

const MobileDropdownMenu = ({
    items = [],
    alwaysFilled = false,
    className = '',
    toggler,
    filledOnMobile = false
}) => {
    const navigate = useNavigate();
    const itsRef = useRef(null);

	const [isOpen, setIsOpen] = useState(false);
	const [activeMenu, setActiveMenu] = useState(null);

	const activeData = !activeMenu ? items : items.find(i => i.title === activeMenu).submenu;

	const onClick = item => {
		if (item.submenu) {
			setActiveMenu(item.title);
		} else {
			setIsOpen(false);

            if (item.onClick) {
                item.onClick();
            } else if (item.link) {
                navigate(item.link);
            }
		}
	}

	return (
		<div className={className}>
			{toggler && toggler(setIsOpen)}

			{!toggler &&
			<IconButton className={(alwaysFilled || isOpen) && filledOnMobile && cx.show} onClick={() => setIsOpen(true)} icon={
				<Icon type={Icons.MORE_CIRCLE}
					  width={20}
					  height={20} />} />}

			<Modal isOpen={isOpen} className={cx.modal} overlayClassName={cx.modalOverlay} onRequestClose={() => setIsOpen(false)}>
				<div className={cx.modalMenu} ref={itsRef}>
					{activeData.map((item, i) => {
						if (item.isShow === false) {
							return null;
						}
						return (
							<div
                                key={i}
                                id={item.id}
                                className={classNames(cx.item, item.className)}
                                onClick={() => onClick(item)}
                            >
								<div className={cx.titleWrapper}>
									{item.icon && <Icon width={18} height={18} type={item.icon}/>}
									<div className={cx.title}>{item.title}</div>
								</div>
								{item.submenu && <Icon width={14} height={14} type={Icons.ARROW_RIGHT}/>}
							</div>
						);
					})}
					{!activeMenu && <div onClick={() => setIsOpen(false)} className={cx.cancelBtn}>
						Отмена
					</div>}
					{activeMenu && <div onClick={() => setActiveMenu(null)} className={cx.cancelBtn}>
						Назад
					</div>}
				</div>
			</Modal>
		</div>
	);
};

export default MobileDropdownMenu;
