import React, { useMemo, useState, useEffect, useCallback, useContext, useRef } from 'react';
import { Link, setDocumentTitle, useNavigate, useParams } from 'shared/router';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import { Icons } from 'uikit/icon';
import { EditRowToolbar } from 'uikit/table';
import Loading from 'uikit/loading';
import { ContentWrapper } from 'containers/content-wrapper';
import { useDialog, useGlobalContext, useMessage, useAuthorized } from 'lib/hooks';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import api from 'api';
import cx from './scripting-page.module.scss';
import Confirmation from 'components/confirmation';
import {TableComponent} from "components/data-components";
import {selectUser} from 'slice/authSlice';
import {USER_ROLES} from "model/role";
import { MENU_CONTENT_TYPE, MobileMenuContext } from 'containers/menu-wrapper/menu-wrapper';
import { ScriptsTreeContext } from 'containers/scripts-wrapper/scripts-wrapper';
import {collectScriptBreadCrumbs} from "lib/helpers";
import TableTitleCell from 'components/table-title-cell';
import { EmptyScripts } from 'components/projects/empty';

const ScriptingPage = () => {
    const tableRef = useRef(null);
    const navigate = useNavigate();

    const { projectUuid, sectionUuid, scriptUuid } = useParams();

    const { platform, isAuthorizedRole } = useGlobalContext();
    const { isAuthorizedAction } = useAuthorized();

    const { dialogState, openDialog, closeDialog } = useDialog();
    const { addSuccess, addError } = useMessage();

    const currentUser = useSelector(selectUser);
    const isCreateAuthorized = isAuthorizedAction([GLOBAL_ACTIONS.SCRIPT_CREATE]);

    const { setMenuContentType } = useContext(MobileMenuContext);
    const { reloadTree, setOnReloadTreeAction } = useContext(ScriptsTreeContext);

    const [isLoading, setIsLoading] = useState(true);
    const [, setIsInit] = useState(false);

    const [isReLoading, setIsReLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    const [scriptsData, setScriptsData] = useState([]);
    const [scriptsTotal, setScriptsTotal] = useState(0);

    const [checkedCheckbox, setCheckedCheckbox] = useState([]);
    const checkedCheckboxRef = useRef(checkedCheckbox);

    const checkboxHandler = useCallback((node) => {
        setCheckedCheckbox((prevValue) => {
            if (prevValue.some((c) => c.id === node.id)) {
                return prevValue.filter((c) => c.id !== node.id);
            } else {
                return [...prevValue, node];
            }
        });
    }, []);
    const fetchScripts = useCallback(async (offset, count, sort, search = '') => {
        setIsLoading(true);

        try {
            let scriptResponse;

            if (!projectUuid && !sectionUuid) {
                scriptResponse = await api.scripting.getAllScripts(
                    offset / count,
                    count,
                    sort.by ? (sort.by + ',' + (sort.desc ? 'desc' : 'asc')) : '',
                    search
                );
            }

            if (projectUuid && !sectionUuid) {
                scriptResponse = await api.scripting.getProjectScripts(
                    projectUuid,
                    offset / count,
                    count,
                    sort.by ? (sort.by + ',' + (sort.desc ? 'desc' : 'asc')) : '',
                    search
                );
            }

            if (sectionUuid) {
                scriptResponse = await api.scripting.getSectionScripts(
                    sectionUuid,
                    offset / count,
                    count,
                    sort.by ? (sort.by + ',' + (sort.desc ? 'desc' : 'asc')) : '',
                    search
                );
            }

            setScriptsData(scriptResponse.content);
            setScriptsTotal(scriptResponse.totalElements);

            setIsEmpty(parseInt(scriptResponse.totalElements) === 0 && !search);
        } catch (error) {
            setIsEmpty(true);
        } finally {
            setIsLoading(false);
            setIsReLoading(false);
        }
    }, [projectUuid, sectionUuid]);

    const archiveScripts = useCallback(async (data) => {
        try {
            setIsLoading(true);
            closeDialog();

            if (data?.id) {
                await api.archive.moveScriptToArchive(data.id);
            } else if (checkedCheckboxRef.current.length) {
                await api.archive.moveToArchive(data.map((script) => script.id));
            }

            tableRef?.current?.reload();
            reloadTree();

            addSuccess(data?.id ? 'Скрипт перемещен в архив' : 'Выбранные скрипты перенесены в архив');
        } catch (error) {
            addError('При архивировании произошла ошибка');
        } finally {
            setIsLoading(false);
        }
    }, [closeDialog, addSuccess, addError, reloadTree]);
    const onScriptsArchive = useCallback(async (data) => {
        if (data?.length) {
            setCheckedCheckbox(data.map((d) => d.original));
        }

        let text = '';

        if (data?.length) {
            text = data.map((item) => {
                return {
                    ...item.original,
                    text: (
                        <>
                            {item?.original?.parents?.projectTitle ? item.original.parents.projectTitle + ' / ' + item.original.parents.sections[0].title + ' / '
                                : 'Общая Скрипт / '}
                            <span
                                style={{ color: '#279BD9' }}
                                onClick={() => {
                                    navigate(`/scripting/script/${item.original['id']}`);
                                    closeDialog();
                                }}
                            >
                            {item.original.title}
                        </span>
                        </>
                    ),
                };
            });
        } else {
            text = (
                <span>
                    Вы действительно хотите перенести в архив скрипт&nbsp;
                    <span style={{ color: '#279BD9' }} onClick={() => navigate(`/scripting/script/${data['id']}`)}>
                        {data?.title}
                    </span>
                    &nbsp;? Скрипты в архиве могут быть восстановлены
                </span>
            );
        }

        setCheckedCheckbox(data?.length ? data.map((item => item.original)) : [data]);

        openDialog({
            title: 'Архивирование',
            subTitle: data?.length
                ? 'Вы действительно хотите переместить в архив нижеперечисленные скрипты? Скрипты в архиве могут быть восстановлены'
                : null,
            text,
            contentType: data?.length ? 'CHECKBOX_LIST' : 'TEXT',
            color: 'green',
            closeBtnText: 'Нет, закрыть',
            submitBtnText: 'Подтвердить',
            onChange: (scripts) => {
                checkboxHandler(scripts);
            },
            onSubmit: () => {
                archiveScripts(data?.length ? checkedCheckboxRef.current : data)
            },
            onClose: () => {
                closeDialog();
                setCheckedCheckbox([]);
            }
        });
    }, [openDialog, navigate, closeDialog, checkboxHandler, archiveScripts]);

    const columns = useMemo(() => {
        return [
            {
                Header: 'Содержание',
                accessor: 'title',
                Cell: (data) => {
                    const breadCrumbs = collectScriptBreadCrumbs(data.row.original.id, data.row.original.name, data.row.original?.parents);
                    const crumbs = breadCrumbs?.map((item) => {
                        return {
                            name: item.title,
                            linkTo: data.row.original.id === item.id ? '' :
                                item.type === 'PROJECT'
                                    ? `/projects/${item.id}/scripts`
                                    : `/projects/${breadCrumbs[0].id}/${item.id}/section/scripts`
                        };
                    });

                    return (
                        <TableTitleCell
                            title={data.row.original.title}
                            link={'/scripting/script/' + data.row.original['id']}
                            crumbs={crumbs}
                            description={data.row.original['description']}
                        />
                    );
                },
            },
            {
                Header: 'Дата изменения',
                accessor: 'modifyTime',
                Cell: (data) => {
                    const modifyTime = new Date(data.row.original['modifyTime']).toLocaleString();
                    const createTime = new Date(data.row.original['createTime']).toLocaleString();

                    return (
                        <div>
                            {data.isMobile && <div className={cx.subheader}>Дата изменения</div>}
                            {modifyTime
                                ? modifyTime.substr(0, modifyTime.length - 3)
                                : createTime.substr(0, createTime.length - 3)}
                        </div>
                    )
                }
            },
            {
                Header: 'Автор',
                accessor: 'author',
                Cell: (data) => {
                    return (
                        <div>
                            {data.isMobile && <div className={cx.subheader}>Автор</div>}
                            {data.row.original['author']['status'] === 'DELETED' && (
                                <span style={{ opacity: 1 }}>
                                    {data.row.original['author']['firstName'] + ' ' + data.row.original['author']['lastName']}
                                </span>
                            )}
                            {data.row.original['author']['status'] !== 'DELETED' && (
                                <Link to={'/edit-user/' + data.row.original['author']['login']}>
                                    {data.row.original['author']['firstName'] + ' ' + data.row.original['author']['lastName']}
                                </Link>
                            )}
                        </div>
                    );
                },
            },
            {
                id: 4,
                settings: platform === 'mobile' ? ['no_td_wrap'] : [],
                Cell: (data) => {
                    let toolbar = [];

                    toolbar.push({
                        icon: Icons.EDIT_PEN,
                        tooltip: 'Редактировать',
                        link: `/scripting/edit/${data.row.original.id}`,
                        isHidden: (original) => !original?.permissions?.canEdit
                    });
                    toolbar.push({
                        icon: Icons.ARCHIVE,
                        tooltip: 'Архивировать',
                        onClick: (data) => onScriptsArchive(data),
                        isHidden: (original) => !original?.permissions?.canArchive
                    });

                    return EditRowToolbar(toolbar)(data);
                },
            },
        ];
    }, [platform, onScriptsArchive]);
    const scriptsActions = useMemo(() => {
        const actions = [];

        if (isAuthorizedRole([USER_ROLES.ROLE_ROOT, USER_ROLES.ROLE_ADMIN, USER_ROLES.ROLE_SUPER_USER])) {
            actions.push({
                icon: Icons.ARCHIVE,
                label: 'В архив',
                onClick: (rows) => onScriptsArchive(rows),
            });
        }

        return actions;
    }, [isAuthorizedRole, onScriptsArchive]);

    const addScriptUrlRoot = `/scripting/create`;
    let addScriptUrl = `${addScriptUrlRoot}`;

    if (projectUuid) {
        addScriptUrl += `?projectId=${projectUuid}`
    }

    if (sectionUuid) {
        addScriptUrl += `&sectionId=${sectionUuid}`
    }

    useEffect(() => {
        checkedCheckboxRef.current = checkedCheckbox;
    }, [checkedCheckbox]);
    useEffect(() => {
        if (!setMenuContentType) {
            return;
        }

        setMenuContentType(MENU_CONTENT_TYPE.MAIN_MENU);
    }, [setMenuContentType]);

    useEffect(() => {
        setIsReLoading(true);
        setIsEmpty(false);

        setScriptsTotal(0);
        setIsInit(prev => {
            if (!prev) {
                return !prev;
            }

            tableRef?.current?.reload();
            return prev;
        });
    }, [projectUuid, sectionUuid, scriptUuid]);
    useEffect(() => { setOnReloadTreeAction(() => () => setScriptsData([])) }, [setOnReloadTreeAction]);

    useEffect(() => {
        setDocumentTitle('Скриптинг — KMS Gran');
    }, []);

    return (
        <ContentWrapper className={cx.contentWrapper}>
            <Confirmation
                submitBtnDisabled={!checkedCheckbox.length}
                {...dialogState}
            />
            <div className={classNames(cx.pageWrapper, isEmpty && !isLoading && cx['pageWrapper--empty'])}>
                {isLoading && isReLoading && (
                    <div className={cx.loader}>
                        <Loading withOverlay={false} />
                    </div>
                )}
                {isEmpty && !isLoading && (
                    <EmptyScripts buttons={isCreateAuthorized ? [
                        {
                            icon: Icons.EDIT_PEN,
                            title: 'Добавить скрипт',
                            onClick: () => navigate(addScriptUrl),
                        },
                    ] : null} />
                )}
                {!isEmpty && (
                    <>
                        {!isReLoading && <h2 className={cx.header}>Скрипты</h2>}
                        <div className={classNames(!isLoading && scriptsTotal > 0 && cx.table)}>
                            <TableComponent
                                innerRef={tableRef}
                                searchTitle="Поиск по автору и названию"
                                addTitle={currentUser.systemRoleId !== 6 ? "Добавить скрипт" : ''}
                                actions={scriptsActions}
                                columns={columns}
                                isLoading={isLoading}
                                total={scriptsTotal}
                                data={scriptsData}
                                onPaginate={fetchScripts}
                                addLink={addScriptUrl}
                                defaultSort={{ by: 'modifyTime', desc: true }}
                                isMobile={platform === 'mobile'}
                                archive={true}
                                id="scriptingPage"
                            />
                        </div>
                    </>
                )}
            </div>
        </ContentWrapper>
    );
};

export default ScriptingPage;

