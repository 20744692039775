import { Mark } from '@tiptap/core';

export const Monospace = Mark.create({
    name: 'monospace',

    parseHTML() {
        return [
            {
                tag: 'code',
                getAttrs: el => {
                    return !el.classList.contains('language') && null;
                },
            },
            {
                tag: 'span',
                getAttrs: el => {
                    return el.style.fontFamily.includes('monospace');
                },
            },
        ];
    },
    renderHTML() {
        return ['span', { style: 'font-family: monospace' }, 0];
    },
});
