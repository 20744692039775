import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import { TableMap } from '../../tableMap';
import { TableView, updateColumns } from '../TableView';
import { cellAround, pointsAtCell } from '../../utils';
import { tableNodeTypes } from './getTableNodeTypes';
import { stringToBoolean } from '../../../../utils/stringToBool';

export const columnResizingPluginKey = new PluginKey('tableColumnResizing');

export function columnResizing({ handleWidth = 5, cellMinWidth = 25, View = TableView, lastColumnResizable = true } = {}) {
    let isTablesInitialized = false;

    const initializeTables = (tr, view, lastColumnResizable) => {
        if (!tr || !view) {
            return;
        }

        const tables = [];

        tr.doc.descendants((node) => {
            if (node.type.name === 'table') {
                tables.push(node);
            }
        });

        if (tables.length === 0) {
            return;
        }

        isTablesInitialized = true;

        tables.forEach(table => {
            const cells = [];

            table.descendants((node, pos) => {
                if (node.type.name === 'tableCell') {
                    cells.push(pos);
                }
            });

            if (cells.length < 2) {
                return;
            }

            const cellsPos = getCellsPos(view, cells[1]);

            if (cellsPos && cellsPos.length > 0) {
                const $cell = view.state.doc.resolve(cellsPos[0]);
                const table = $cell.node(-2);

                if (!table.attrs.width?.includes('%')) {
                    cellsPos.pop();
                }
            }

            if (!lastColumnResizable) {
                for (let i = 0; i < cellsPos.length; i++) {
                    const $cell = view.state.doc.resolve(cellsPos[i]);
                    const table = $cell.node(-2);

                    const map = TableMap.get(table);
                    const tableStart = $cell.start(-2);

                    const col = map.colCount($cell.pos - tableStart) + $cell.nodeAfter.attrs.colspan - 1;

                    if (col === map.width - 1) {
                        return;
                    }
                }
            }

            updateHandle(view, cellsPos.length > 0 ? cellsPos : -1);
            handleMouseLeave(view);
        });
    };

    const plugin = new Plugin({
        key: columnResizingPluginKey,
        state: {
            init(_, state) {
                new ResizeObserver(() => {
                    initializeTables(this.lastTr, this.view, lastColumnResizable);
                }).observe(document.getElementById('editor'));

                plugin.spec.props.nodeViews[tableNodeTypes(state.schema).table.name] = (node, view) => {
                    this.view = view;
                    return new View(node, cellMinWidth, view);
                };

                return new ResizeState(-1, false);
            },
            apply(tr, prev) {
                this.lastTr = tr;

                if (!isTablesInitialized) {
                    initializeTables(tr, this.view, lastColumnResizable);
                }

                return prev.apply(tr);
            },
        },
        props: {
            attributes: state => {
                const pluginState = columnResizingPluginKey.getState(state);
                return pluginState && Array.isArray(pluginState.activeHandle) ? { class: 'resize-cursor' } : {};
            },
            handleDOMEvents: {
                mousemove: (view, event) => {
                    handleMouseMove(view, event, handleWidth, cellMinWidth, lastColumnResizable);
                },
                mouseleave: view => {
                    handleMouseLeave(view);
                },
                mousedown: (view, event) => {
                    handleMouseDown(view, event, cellMinWidth);
                },
            },
            decorations: state => {
                const pluginState = columnResizingPluginKey.getState(state);

                if (pluginState && Array.isArray(pluginState.activeHandle)) {
                    return handleDecorations(state, pluginState.activeHandle);
                }
            },
            nodeViews: {

            },
        },
    });

    return plugin;
}

/**
 * @public
 */
export class ResizeState {
    constructor(activeHandle, dragging) {
        this.activeHandle = activeHandle;
        this.dragging = dragging;
    }

    apply(tr) {
        const state = this;
        const action = tr.getMeta(columnResizingPluginKey);

        if (action && action.setHandle != null) {
            return new ResizeState(action.setHandle, false);
        }

        if (action && action.setDragging !== undefined) {
            return new ResizeState(state.activeHandle, action.setDragging);
        }

        if (Array.isArray(state.activeHandle) && tr.docChanged) {
            const handles = state.activeHandle.map(pos => {
                let handle = tr.mapping.map(pos, -1);

                if (!pointsAtCell(tr.doc.resolve(handle))) {
                    handle = -1;
                }

                return handle;
            });

            return new ResizeState(handles, state.dragging);
        }

        return state;
    }
}

function getCellsPos(view, pos) {
    const $cell = cellAround(view.state.doc.resolve(pos));

    if (!$cell) {
        return -1;
    }

    const map = TableMap.get($cell.node(-2));
    const tableStart = $cell.start(-2);

    const index = map.map.indexOf($cell.pos - tableStart);
    return [tableStart + map.map[index], tableStart + map.map[index + 1]];
}

// При наведении курсора на границу ячейки вычисляем и сохраняем в состоянии позиции левой и правой ячейки
function handleMouseMove(view, event, handleWidth, cellMinWidth, lastColumnResizable) {
    const pluginState = columnResizingPluginKey.getState(view.state);

    if (!pluginState) {
        return;
    }

    if (!pluginState.dragging) {
        const target = domCellAround(event.target);
        let cellsPos = -1;

        if (target) {
            const { left, right } = target.getBoundingClientRect();

            if (event.clientX - left <= handleWidth) {
                cellsPos = edgeCell(view, event, 'left', handleWidth);
            } else if (right - event.clientX <= handleWidth) {
                cellsPos = edgeCell(view, event, 'right', handleWidth);
            }
        }

        if (Array.isArray(cellsPos) && !cellsPos.every((cell, idx) => pluginState.activeHandle && cell === pluginState.activeHandle[idx])) {
            if (cellsPos) {
                const $cell = view.state.doc.resolve(cellsPos[0]);
                const table = $cell.node(-2);

                if (!table.attrs.width?.includes('%')) {
                    cellsPos.pop();
                }
            }

            if (!lastColumnResizable && cellsPos !== -1) {
                for (let i = 0; i < cellsPos.length; i++) {
                    const $cell = view.state.doc.resolve(cellsPos[i]);
                    const table = $cell.node(-2);

                    const map = TableMap.get(table);
                    const tableStart = $cell.start(-2);

                    const col = map.colCount($cell.pos - tableStart) + $cell.nodeAfter.attrs.colspan - 1;

                    if (col === map.width - 1) {
                        return;
                    }
                }
            }

            updateHandle(view, cellsPos);
        } else if (pluginState.activeHandle !== -1) {
            updateHandle(view, cellsPos);
        }
    }
}

function handleMouseLeave(view) {
    const pluginState = columnResizingPluginKey.getState(view.state);

    if (pluginState && Array.isArray(pluginState.activeHandle) && !pluginState.dragging) {
        updateHandle(view, -1);
    }
}

function handleMouseDown(view, event, cellMinWidth) {
    const win = view.dom.ownerDocument.defaultView ?? window;
    const pluginState = columnResizingPluginKey.getState(view.state);

    if (!pluginState || pluginState.activeHandle === -1 || pluginState.dragging) {
        return false;
    }

    const colsWidth = pluginState.activeHandle.map(cellPos => {
        const cell = view.state.doc.nodeAt(cellPos);
        return Number(currentColWidth(view, cellPos, cell.attrs));
    });

    view.dispatch(
        view.state.tr.setMeta(columnResizingPluginKey, {
            setDragging: { startX: event.clientX, startColsWidth: colsWidth },
            isResizingActive: true,
        })
    );

    function finish(event) {
        win.removeEventListener('mouseup', finish);
        win.removeEventListener('mousemove', move);

        const pluginState = columnResizingPluginKey.getState(view.state);

        if (pluginState?.dragging && Array.isArray(pluginState.activeHandle)) {
            const widths = draggedWidth(pluginState.dragging, event, cellMinWidth);

            if ((widths[0] < 45 && event.clientX - pluginState.dragging.startX <= 0) || (widths[1] && widths[1] < 45)) {
                view.dispatch(view.state.tr.setMeta(columnResizingPluginKey, { setDragging: null, isResizingActive: false }));
                return;
            } else {
                updateColumnWidth(view, pluginState.activeHandle, widths);
                view.dispatch(view.state.tr.setMeta(columnResizingPluginKey, { setDragging: null, isResizingActive: false }));
            }
        }
    }

    function move(event) {
        if (!event.which) {
            return finish(event);
        }

        const pluginState = columnResizingPluginKey.getState(view.state);

        if (!pluginState) {
            return;
        }

        if (pluginState.dragging && Array.isArray(pluginState.activeHandle)) {
            const widths = draggedWidth(pluginState.dragging, event, cellMinWidth);

            if ((widths[0] < 45 && event.clientX - pluginState.dragging.startX <= 0) || (widths[1] && widths[1] < 45)) {
                return;
            } else {
                displayColumnWidth(view, pluginState.activeHandle, widths, cellMinWidth);
            }
        }
    }

    win.addEventListener('mouseup', finish);
    win.addEventListener('mousemove', move);

    event.preventDefault();
    return true;
}

function currentColWidth(view, cellPos, { colspan, colwidth }) {
    const width = colwidth && colwidth[colwidth.length - 1];

    if (width) {
        return width;
    }

    const dom = view.domAtPos(cellPos);
    const node = dom.node.childNodes[dom.offset];

    let domWidth = node.offsetWidth;
    let parts = colspan;

    if (colwidth) {
        for (let i = 0; i < colspan; i++) {
            if (colwidth[i]) {
                domWidth -= colwidth[i];
                parts--;
            }
        }
    }

    return domWidth / parts;
}

function domCellAround(target) {
    while (target && target.nodeName !== 'TD' && target.nodeName !== 'TH') {
        target = target.classList && target.classList.contains('ProseMirror') ? null : target.parentNode;
    }

    return target;
}

function edgeCell(view, event, side, handleWidth) {
    // posAtCoords returns inconsistent positions when cursor is moving
    // across a collapsed table border. Use an offset to adjust the
    // target viewport coordinates away from the table border.
    const offset = side === 'right' ? -handleWidth : handleWidth;
    const found = view.posAtCoords({
        left: event.clientX + offset,
        top: event.clientY,
    });

    if (!found) {
        return -1;
    }

    const { pos } = found;
    const $cell = cellAround(view.state.doc.resolve(pos));

    if (!$cell) {
        return -1;
    }

    const map = TableMap.get($cell.node(-2));
    const tableStart = $cell.start(-2);

    const index = map.map.indexOf($cell.pos - tableStart);

    if (side === 'right') {
        return [tableStart + map.map[index], tableStart + map.map[index + 1]];
    } else if (index % map.width === 0) {
        return -1;
    } else {
        return [tableStart + map.map[index - 1], tableStart + map.map[index]];
    }
}

function draggedWidth(dragging, event, cellMinWidth) {
    const [leftCol, rightCol] = dragging.startColsWidth;
    const offset = event.clientX - dragging.startX;

    let result = [];

    if (!rightCol) {
        result.push(Math.max(cellMinWidth, leftCol + offset));
        return result;
    }

    result = [leftCol, rightCol];

    if (offset >= 0) {
        result[1] = Math.max(cellMinWidth, rightCol - offset);
        result[0] = result[1] <= 45 ? Math.max(cellMinWidth, leftCol + rightCol - result[1]) : Math.max(cellMinWidth, leftCol + offset);
    } else {
        result[0] = Math.max(cellMinWidth, leftCol + offset);
        result[1] = result[0] <= 45 ? Math.max(cellMinWidth, rightCol + leftCol - result[0]) : Math.max(cellMinWidth, rightCol - offset);
    }

    return result;
}

function updateHandle(view, cellsPos) {
    let cell, table;

    if (Array.isArray(cellsPos)) {
        cell = view.state.doc.resolve(cellsPos.find(pos => !isNaN(pos)));
        table = cell.node(-2);
    }

    if (cellsPos === -1 || (table && !stringToBoolean(table?.attrs['data-responsive']))) {
        view.dispatch(view.state.tr.setMeta(columnResizingPluginKey, { setHandle: cellsPos }));
    }
}

export function updateColumnWidth(view, cells, widths) {
    const $cells = cells.map(cell => view.state.doc.resolve(cell));
    const $cell = $cells?.[0];

    const table = $cell.node(-2);
    const map = TableMap.get(table);

    const tableStart = $cell.start(-2);
    const cols = $cells.map($cell => map.colCount($cell.pos - tableStart) + $cell.nodeAfter.attrs.colspan - 1);

    const tr = view.state.tr;

    for (let row = 0; row < map.height; row++) {
        for (let i = 0; i < cols.length; i++) {
            const mapIndex = row * map.width + cols[i];

            if (row && map.map[mapIndex] === map.map[mapIndex - map.width]) {
                continue;
            }

            const pos = map.map[mapIndex];
            const attrs = table.nodeAt(pos).attrs;
            const index = attrs.colspan === 1 ? 0 : cols[i] - map.colCount(pos);

            if (attrs.colwidth && attrs.colwidth[index] === widths[i]) {
                continue;
            }

            const colwidth = attrs.colwidth ? attrs.colwidth.slice() : zeroes(attrs.colspan);
            colwidth[index] = widths[i];

            tr.setNodeMarkup(tableStart + pos, null, {
                ...attrs,
                colwidth: stringToBoolean(table.attrs['data-responsive']) ? null : colwidth,
            });
        }
    }

    if (tr.docChanged) {
        view.dispatch(tr);
    }
}

function displayColumnWidth(view, cells, widths, cellMinWidth) {
    const $cells = cells.map(cell => view.state.doc.resolve(cell));
    const $cell = $cells?.[0];

    const table = $cell.node(-2);
    const map = TableMap.get(table);

    const tableStart = $cell.start(-2);
    const cols = $cells.map($cell => map.colCount($cell.pos - tableStart) + $cell.nodeAfter.attrs.colspan - 1);

    let dom = view.domAtPos($cell.start(-2)).node;

    while (dom && dom.nodeName !== 'TABLE') {
        dom = dom.parentNode;
    }

    if (!dom) {
        return;
    }

    updateColumns(table, table, dom, cellMinWidth, cols, widths);
}

function zeroes(n) {
    return Array(n).fill(0);
}

export function handleDecorations(state, cells) {
    const decorations = [];

    const $cell = state.doc.resolve(cells[0]);
    const table = $cell.node(-2);

    if (!table) {
        return DecorationSet.empty;
    }

    const map = TableMap.get(table);
    const start = $cell.start(-2);

    const col = map.colCount($cell.pos - start) + $cell.nodeAfter.attrs.colspan;

    for (let row = 0; row < map.height; row++) {
        const index = col + row * map.width - 1;
        // For positions that have either a different cell or the end
        // of the table to their right, and either the top of the table or
        // a different cell above them, add a decoration
        if ((col === map.width || map.map[index] !== map.map[index + 1]) && (row === 0 || map.map[index] !== map.map[index - map.width])) {
            const cellPos = map.map[index];

            const pos = start + cellPos + table.nodeAt(cellPos).nodeSize - 1;
            const dom = document.createElement('div');

            dom.className = 'column-resize-handle';
            decorations.push(Decoration.widget(pos, dom));
        }
    }

    return DecorationSet.create(state.doc, decorations);
}
