import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ContentWrapper } from 'containers/content-wrapper';
import { MobileTree, NavigationTree } from 'components/trees';
import Icon, { Icons } from 'uikit/icon';
import ActionLogPage from 'app/action-log/action-log-page/action-log';
import NotificationsPage from 'notifications/view/page';
import cs from './actions-log.module.scss';
import { useGlobalContext } from 'lib/hooks';
import { useCustomMobileMenu } from 'lib/hooks/useCustomMobileMenu';
import buttons from 'model/global-menu/buttons';
import { useSelector } from 'react-redux';
import { selectMenuButtons } from 'slice/authSlice';
import { useWindowSize } from 'lib/hooks/useWindowSize';
import { Route, RouteGroup, Link, useNavigate, useParams, setDocumentTitle } from 'shared/router';

const ActionsLog = () => {
    const { width } = useWindowSize();

    const navigate = useNavigate();
    const params = useParams();

    const { platform } = useGlobalContext();
    const [tab, setActiveTab] = useState(null);

    const [showTree, setShowTree] = useState(platform !== 'mobile' ? true : false);

    const treeRef = useRef(null);
    const menuButtons = useSelector(selectMenuButtons);

    useCustomMobileMenu(
        useCallback(onClose => {
            return (
                <MobileTree
                    onSelect={() => {
                        onClose();
                        setActiveTab('actions');
                    }}
                    getNodeLink={node => {
                        return { link: `/action-log/actions/${node.id}` };
                    }}
                    ref={treeRef}
                />
            );
        }, [])
    );

    useEffect(() => {
        if (!params['*']) {
            navigate('/action-log/actions', { replace: true });
            setActiveTab('actions');

            if (treeRef?.current?.tree) {
                treeRef.current.tree.selectNode(null);
            }
        } else {
            const index = params['*'].indexOf('/');
            setActiveTab(index !== -1 ? params['*'].slice(0, index) : params['*']);
        }
    }, [navigate, params, treeRef]);

    useEffect(() => {
        setDocumentTitle('Журнал действий — KMS Gran');
    }, []);

    return (
        <ContentWrapper>
            <div className={cs.toggleTreeButton} onClick={() => setShowTree(!showTree)}>
                <Icon type={Icons.SANDWICH} width={20} height={14} />
            </div>
            <div className={classNames(cs.base, { [cs.mobile]: platform === 'mobile' })}>
                {platform !== 'mobile' && (
                    <div
                        className={classNames(cs.treeContainer, {
                            [cs.treeContainerHidden]: width >= 768 && width <= 1024 && !showTree,
                            [cs.treeHidden]: width > 1024 && !showTree,
                        })}
                        onClick={() => setShowTree(!showTree)}
                    >
                        <div
                            className={cs.tree}
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <div className={cs.hideButton} onClick={() => setShowTree(isShow => !isShow)}>
                                <Icon className={cs.icon} type={Icons.ARROW_RIGHT} width={14} height={14} color="blue" />
                            </div>
                            {tab === 'actions' && (
                                <NavigationTree
                                    multipleChoice={false}
                                    allButtonTitle="Все проекты"
                                    path={'action-log/actions'}
                                    ref={treeRef}
                                    getNodeLink={node => `/action-log/actions/${node.id}?page=0&scroll=0`}
                                    scrollToNode={[params['*'].replace('actions', '').replace('/', '')]}
                                />
                            )}
                            {tab === 'notifications' && (
                                <div className={cs.noTree}>
                                    <Icon type={Icons.TEXT_ALIGN_LEFT} width={24} height={24} />
                                    <div>Иерархия отсутствует</div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div
                    className={classNames(cs.contentUsers, {
                        [cs.contentUsersWithoutTree]: !showTree && width > 1024,
                    })}
                >
                    {!showTree && (
                        <span className={cs.showButton} onClick={() => setShowTree(isShow => !isShow)}>
                            <Icon className={cs.icon} type={Icons.ARROW_RIGHT} width={14} height={14} />
                        </span>
                    )}
                    <ul className={cs.usersUl}>
                        {menuButtons[buttons.ACTIVITY] === true && (
                            <li className={tab === 'actions' ? cs.active : ''}>
                                <Link to="/action-log/actions">
                                    Действия
                                </Link>
                            </li>
                        )}
                        <li className={tab === 'notifications' ? cs.active : ''}>
                            <Link to="/action-log/notifications">
                                Уведомления
                            </Link>
                        </li>
                    </ul>
                    <div className={cs.tableWrapper}>
                        <RouteGroup className={cs.router}>
                            <Route to="actions/" component={<ActionLogPage treeRef={treeRef} />} />
                            <Route to="actions/:id" component={<ActionLogPage treeRef={treeRef} />} />
                            <Route to="notifications/" component={<NotificationsPage />} />
                        </RouteGroup>
                    </div>
                </div>
            </div>
        </ContentWrapper>
    );
};
export default ActionsLog;
