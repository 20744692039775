import React, { useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import cn from 'classnames';
import { useMessage } from 'lib/hooks';
import Button from 'uikit/button';
import AvatarEditor from 'components/avatar-editor/avatar-editor';
import { CloseButton } from './close-button';
import cs from './input-image.module.scss';
import Icon, { Icons } from 'uikit/icon';

const defaultProps = {
    image: '',
    width: '32px',
    height: '32px',
    bgSize: 'contain',
    key: 1,
};

const InputImage = ({ onChange = () => {}, title, btn, btnClassName, btnColor, btnSize }) => {
    const inputRef = useRef();
    const { addError } = useMessage();

    const [dirty, setDirty] = useState(false);
    const [state, setState] = useState(defaultProps);

    const [isAvatarModal, setIsAvatarModal] = useState(false);
    const [uploadAvatar, setUploadAvatar] = useState(null);

    const isEmpty = state.image === defaultProps.image;

    const imageLoaded = (imageData) => {
        setState({
            width: '100%',
            height: 'calc(100vw/4.9)',
            image: `url(${imageData})`,
            bgSize: 'cover',
        });
        setDirty(true);
        onChange(imageData);
    };

    const deleteImage = () => {
        setDirty(false);
        setState({
            ...defaultProps,
            key: state.key + 1,
        });
        onChange(null);
    };

    const handleChange = async (e) => {
        if (e.target.files.length > 0 && e.target.files[0].type.match(/^image\//)) {
            var oFReader = new FileReader();
            oFReader.readAsDataURL(e.target.files[0]);

            oFReader.onload = function () {
                setUploadAvatar(this.result);
                setIsAvatarModal(true);
            };
        } else {
            addError('для загрузки допустимы следующие форматы - JPG, PNG, WEBM.');
        }
    };

    const saveAvatar = (avatar) => {
        setIsAvatarModal(false);
        imageLoaded(avatar);
    };

    const onDrop = (files) => {
        handleChange({ target: { files: files } });
    };

    return (
        <>
            {isAvatarModal && (
                <AvatarEditor
                    type="rectangle"
                    open={isAvatarModal}
                    onSubmit={(avatar) => saveAvatar(avatar)}
                    onDismiss={() => setIsAvatarModal(false)}
                    img={uploadAvatar}
                    title="Загрузка обложки"
                />
            )}
            {!btn && (
                <Dropzone onDrop={onDrop} multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                            <input id="imageInput" {...getInputProps()} accept="image/png, image/jpeg" />
                            <label className={cn(cs.container, { [cs.borderEmpty]: isEmpty })}>
                                <div className={cs.inputWrapper}>
                                    <div
                                        style={{
                                            width: state.width,
                                            height: state.height,
                                            backgroundImage: state.image,
                                            backgroundSize: state.bgSize,
                                            backgroundPosition: 'center',
                                            cursor: 'pointer',
                                            backgroundRepeat: 'no-repeat',
                                            display: 'inline-block',
                                        }}
                                    />
                                </div>
                                {isEmpty && (
                                    <div className={cs.emptyContainer}>
                                        <Icon type={Icons.IMAGE} width={26} height={19} />
                                        <div className={cs.emptyTitle}>{title}</div>
                                        <div className={cs.emptySubtitle}>
                                            Перетащите изображение в данную область или нажмите для выбора файла
                                        </div>
                                    </div>
                                )}
                                {dirty && <CloseButton style={{ position: 'absolute', top: 0, right: 0 }} onClick={deleteImage} />}
                            </label>
                        </div>
                    )}
                </Dropzone>
            )}
            {btn && (
                <>
                    <Button className={btnClassName} color={btnColor} size={btnSize} onClick={() => inputRef.current.click()}>
                        {title}
                    </Button>
                    <input
                        id={'imageInput'}
                        type="file"
                        onChange={handleChange}
                        style={{ display: 'none' }}
                        accept="image/x-png,image/jpeg"
                        ref={inputRef}
                    />
                </>
            )}
        </>
    );
};

export { InputImage };
