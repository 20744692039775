import React from 'react';
import { NODE_TYPES } from './node-type';
import cs from './tree-icon.module.scss';
import Icon, { Icons } from 'uikit/icon';
import classNames from 'classnames';
import api from 'api/index';

const TreeIcon = ({ state, node, size = 16, isSelected }) => {
    const backgroundImage = React.useMemo(() => {
        if (node.logoUuid) {
            return api.upload.getImage(node.logoUuid, false, 128);
        } else {
            return undefined;
        }
    }, [node.logoUuid]);

    if (node.nodeType === NODE_TYPES.THEME) {
        return null;
    }

    return (
        <div className={classNames(cs.icon, { [cs.article]: node.nodeType === NODE_TYPES.ARTICLE, [cs.opened]: (isSelected && isSelected()) || state === 'opened'})} style={{ minWidth: `${size}px`, height: `${size}px` }} >
            {/*{ (node.nodeType === NODE_TYPES.PROJECT && !node.logoUuid) && <Icon width={16} height={16} type={Icons.PROJECT_DEFAULT_LOGO} /> }*/}
            { node.nodeType === NODE_TYPES.PROJECT && !node.logoUuid && <img alt={''} src={'/img/default-icon-project.jpg'} width={16} height={16} /> }
            { node.nodeType === NODE_TYPES.PROJECT && node.logoUuid && <div style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', minWidth: `${size}px`, height: `${size}px`}}/>}
            { node.nodeType === NODE_TYPES.SECTION && <Icon width={16} height={16} type={Icons.FOLDER_THEME} /> }
            { node.nodeType === NODE_TYPES.ARTICLE && <Icon width={16} height={16} type={Icons.ARTICLE} /> }
            { node.nodeType === NODE_TYPES.QUESTION && <Icon width={16} height={16} type={Icons.QUESTION} /> }
            { node.nodeType === NODE_TYPES.SCRIPTS && <Icon width={16} height={16} type={Icons.HELP_LIFELINE} /> }
        </div>
    );
};

export default TreeIcon;
