type ParsedItem = {
    type: 'dateISO' | 'dateDMY' | 'number' | 'string';
    value: string | number;
};

const DefaultPriority: Record<ParsedItem['type'], number> = {
    number: 1,
    dateISO: 2,
    dateDMY: 3,
    string: 4
};

const parseString = (str: string): ParsedItem => {
    const dateISORegex = /^\d{4}-\d{2}-\d{2}/;
    const dateDMYRegex = /^\d{2}\.\d{2}\.\d{4}/;
    const numberRegex = /^\d+/;

    if (dateISORegex.test(str)) {
        return { type: 'dateISO', value: str };
    }

    if (dateDMYRegex.test(str)) {
        return { type: 'dateDMY', value: str };
    }

    if (numberRegex.test(str)) {
        return { type: 'number', value: parseInt(str, 10) };
    }

    return { type: 'string', value: str };
};

const customComparer = (a: string, b: string, priority: Record<ParsedItem['type'], number> = DefaultPriority) => {
    const aParsed = parseString(a);
    const bParsed = parseString(b);

    if (aParsed.type !== bParsed.type) {
        return priority[aParsed.type] - priority[bParsed.type];
    }

    if (aParsed.type === 'dateISO' || aParsed.type === 'dateDMY') {
        return (aParsed.value as string).localeCompare(bParsed.value as string);
    }

    if (aParsed.type === 'number') {
        return (aParsed.value as number) - (bParsed.value as number);
    }

    return a.localeCompare(b, 'ru', { sensitivity: 'base' });
}

export default customComparer;
