import React from 'react';
import classNames from 'classnames';
import MultiClamp from 'react-multi-clamp';
import Tippy from '@tippyjs/react';
import IconGroup from 'uikit/icon-group/icon-group';
import BackButton from 'components/back-button';
import cs from './user-header.module.scss';

const UserHeader = ({
    idPrefix = '',
    userName = '',
    userPosition = '',
    userBlocked = false,
    avatarSrc = '',
    actionIcons,
    onGoBackHandler
}) => {
    return (
        <div className={cs.headerWrapper}>
            <div className={cs.header}>
                {onGoBackHandler && <BackButton onClick={onGoBackHandler} />}

                <img id={idPrefix + 'UserAvatar'} className={cs.logo} src={avatarSrc || '/img/avatar.png'} width={64} height={64} alt="" />

                <div id={idPrefix + 'UserInfoBlock'} className={cs.userInfoBlock}>
                    {userBlocked && (
                        <div className={classNames(cs.blockedUser, { [cs.blockedUserCenter]: !userPosition })}>ЗАБЛОКИРОВАН</div>
                    )}
                    <Tippy content={userName}>
                        <div id={idPrefix + 'UserInfo'} className={cs.userInfo}>
                            <MultiClamp clamp={1} ellipsis={'...'}>
                                {userName}
                            </MultiClamp>
                        </div>
                    </Tippy>
                    {userPosition && (
                        <div id={idPrefix + 'UserPosition'} className={cs.userPosition}>
                            {userPosition}
                        </div>
                    )}
                </div>
            </div>

            {actionIcons && (
                <div className={cs.headerActions}>
                    <IconGroup id={idPrefix + 'Actions'} position="right">
                        {actionIcons}
                    </IconGroup>
                </div>
            )}
        </div>
    );
};

export default UserHeader;
