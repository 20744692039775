import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Link } from 'shared/router';
import api from 'api';
import cx from './activity.module.scss';

export const ActivityTypes = {
    'USER.AUTHENTICATION': () => <p>Зашел в сеть</p>,
    'USER.DEAUTHENTICATION': () => <p>Вышел из сети</p>,
    'USER.CREATE': data => (
        <p>
            Создал участника "
            <Link to={'/edit-user/' + data['data']['login']}>{data['data']['firstname'] + ' ' + data['data']['lastname']}</Link>"
        </p>
    ),
    'USER.EDIT': data => (
        <p>
            Изменил данные участника "
            <Link to={'/edit-user/' + data['data']['login']}>{data['data']['firstname'] + ' ' + data['data']['lastname']}</Link>"
        </p>
    ),
    'USER.DELETE': data => (
        <p>
            Удалил участника "<span>{data['data']['firstname'] + ' ' + data['data']['lastname']}</span>"
        </p>
    ),
    'USER_ROLE.ASSIGN': data => (
        <p>
            Дал роль "{data['data']['role.title']}" участнику "
            <Link to={'/edit-user/' + data['data']['login']}>{data['data']['login']}</Link>"
        </p>
    ),
    'USER_ROLE.UNASSIGN': data => (
        <p>
            Удалил роль "{data['data']['resource.title']}" участника "
            <Link to={'/edit-user/' + data['data']['login']}>{data['data']['login']}</Link>"
        </p>
    ),
    'USER.ARCHIVE': data => (
        <p>
            Заблокировал участника "
            <Link to={'/edit-user/' + data['data']['login']}>{data['data']['firstname'] + ' ' + data['data']['lastname']}</Link>"
        </p>
    ),
    'USER.RESTORE': data => (
        <p>
            Восстановил участника "
            <Link to={'/edit-user/' + data['data']['login']}>{data['data']['firstname'] + ' ' + data['data']['lastname']}</Link>"
        </p>
    ),
    'GROUP_ROLE.ASSIGN': data => (
        <p>
            Дал роль "{data['data']['role.title']}" группе участников "{data['data']['group.name']}"
        </p>
    ),
    'GROUP_ROLE.UNASSIGN': data => (
        <p>
            Удалил роль "{data['data']['resource.title']}" группы участников "{data['data']['group.name']}"
        </p>
    ),
    'PROJECT.CREATE': data => (
        <p>
            Создал проект "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/' + data['data']['resource.id.1'] + '/articles'}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'PROJECT.EDIT': data => (
        <p>
            Изменил проект "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/' + data['data']['resource.id.1'] + '/articles'}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'PROJECT.DELETE': data => <p>Удалил проект "{data['data']['resource.title']}"</p>,
    'PROJECT.ADD_TO_FAVOURITES': data => (
        <p>
            Добавил в избранное проект "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/' + data['data']['resource.id.1'] + '/articles'}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'PROJECT.DELETE_FROM_FAVOURITES': data => (
        <p>
            Удалил из избранного проект "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/' + data['data']['resource.id.1'] + '/articles'}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'PROJECT.ARCHIVE': data => (
        <p>
            Добавил в архив проект "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/' + data['data']['resource.id.1'] + '/articles'}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'PROJECT.UNARCHIVE': data => (
        <p>
            Восстановил из архива проект "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/' + data['data']['resource.id.1'] + '/articles'}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'SECTION.CREATE': data => (
        <p>
            Создал раздел "
            <Link
                to={
                    '/' +
                    (data['status'] === 'ACTIVE' ? 'projects' : 'archive') +
                    '/' +
                    data['data']['project.id'] +
                    '/' +
                    data['data']['resource.id.2'] +
                    '/section/articles'
                }
            >
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'SECTION.EDIT': data => (
        <p>
            Изменил раздел "
            <Link
                to={
                    '/' +
                    (data['status'] === 'ACTIVE' ? 'projects' : 'archive') +
                    '/' +
                    data['data']['project.id'] +
                    '/' +
                    data['data']['resource.id.2'] +
                    '/section/articles'
                }
            >
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'SECTION.DELETE': data => <p>Удалил раздел "{data['data']['resource.title']}"</p>,
    'SECTION.DELETE_MEMBER': data => (
        <p>
            Удалил участника из раздела "
            <Link
                to={
                    '/' +
                    (data['status'] === 'ACTIVE' ? 'projects' : 'archive') +
                    '/' +
                    data['data']['project.id'] +
                    '/' +
                    data['data']['resource.id.1'] +
                    '/' +
                    data['data']['resource.title'] +
                    '/articles'
                }
            >
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'SECTION.ARCHIVE': data => (
        <p>
            Добавил в архив раздел "
            <Link
                to={
                    '/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/section/' + data['data']['resource.id.1'] + '/articles'
                }
            >
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'SECTION.UNARCHIVE': data => (
        <p>
            Восстановил из архива раздел "
            <Link
                to={
                    '/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/section/' + data['data']['resource.id.1'] + '/articles'
                }
            >
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'ARTICLE.OPEN': data => (
        <p>
            Открыл статью "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/article/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'ARTICLE.CREATE': data => (
        <p>
            Создал статью "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/article/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'ARTICLE.EDIT': data => (
        <p>
            Изменил статью "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/article/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'ARTICLE.READ': data => (
        <p>
            Прочитал статью "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/article/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'ARTICLE.DELETE': data => <p>Удалил статью "{data['data']['resource.title']}"</p>,
    'ARTICLE.ADD_TO_FAVOURITES': data => (
        <p>
            Добавил в избранное статью "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/article/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'ARTICLE.DELETE_FROM_FAVOURITES': data => (
        <p>
            Удалил из избранного статью "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/article/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'ARTICLE.LIKE': data => (
        <p>
            Поставил лайк "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/article/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'ARTICLE.UNLIKE': data => (
        <p>
            Убрал лайк "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/article/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'ARTICLE.DISLIKE': data => (
        <p>
            Поставил дизлайк "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/article/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'ARTICLE.UNDISLIKE': data => (
        <p>
            Убрал дизлайк "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/article/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'ARTICLE_COMMENT.CREATE': data => <p>Добавил комментарий "{data['data']['text']}"</p>,
    'ARTICLE_COMMENT.DELETE': data => <p>Удалил комментарий "{data['data']['text']}"</p>,
    'ARTICLE_COMMENT.EDIT': data => <p>Изменил комментарий "{data['data']['text']}"</p>,
    'ARTICLE_COMMENT.LIKE': data => <p>Поставил лайк комментарию "{data['data']['text']}"</p>,
    'ARTICLE_COMMENT.UNLIKE': data => <p>Убрал лайк с комментария "{data['data']['text']}"</p>,
    'ARTICLE_COMMENT.DISLIKE': data => <p>Поставил дизлайк комментарию "{data['data']['text']}"</p>,
    'ARTICLE_COMMENT.UNDISLIKE': data => <p>Убрал дизлайк с комментария "{data['data']['text']}"</p>,
    'ARTICLE.ARCHIVE': data => (
        <p>
            Добавил в архив статью "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/article/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'ARTICLE.UNARCHIVE': data => (
        <p>
            Восстановил из архива статью "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/article/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'NEWS.CREATE': data => (
        <p>
            Создал новость "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/news/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'NEWS.READ': data => (
        <p>
            Прочитал новость "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/news/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'NEWS.EDIT': data => (
        <p>
            Изменил новость "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/news/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'NEWS.DELETE': data => <p>Удалил новость "{data['data']['resource.title']}"</p>,
    'NEWS.LIKE': data => (
        <p>
            Поставил лайк "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/news/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'NEWS.UNLIKE': data => (
        <p>
            Убрал лайк "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/news/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'NEWS.DISLIKE': data => (
        <p>
            Поставил дизлайк "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/news/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'NEWS.UNDISLIKE': data => (
        <p>
            Убрал дизлайк "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/news/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'NEWS.ADD_TO_FAVOURITES': data => (
        <p>
            Добавил в избранное новость "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/news/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'NEWS.DELETE_FROM_FAVOURITES': data => (
        <p>
            Удалил из избранного новость "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/news/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'NEWS.ARCHIVE': data => (
        <p>
            Добавил в архив новость "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/news/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'NEWS.UNARCHIVE': data => (
        <p>
            Восстановил из архива новость "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/news/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'NEWS_COMMENT.CREATE': data => <p>Добавил комментарий "{data['data']['text']}"</p>,
    'NEWS_COMMENT.DELETE': data => <p>Удалил комментарий "{data['data']['text']}"</p>,
    'NEWS_COMMENT.EDIT': data => <p>Изменил комментарий "{data['data']['text']}"</p>,
    'NEWS_COMMENT.LIKE': data => <p>Поставил лайк комментарию "{data['data']['text']}"</p>,
    'NEWS_COMMENT.UNLIKE': data => <p>Убрал лайк с комментария "{data['data']['text']}"</p>,
    'NEWS_COMMENT.DISLIKE': data => <p>Поставил дизлайк комментарию "{data['data']['text']}"</p>,
    'NEWS_COMMENT.UNDISLIKE': data => <p>Убрал дизлайк с комментария "{data['data']['text']}"</p>,
    'NEWS.OPEN': data => (
        <p>
            Открыл новость "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/news/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'FILE.UPLOAD': data => (
        <p>
            Загрузил файл "<Link to="">{data['data']['resource.title']}</Link>"
        </p>
    ),
    'FILE.EDIT': data => (
        <p>
            Изменил файл "<Link to="">{data['data']['resource.title']}</Link>"
        </p>
    ),
    'FILE.DELETE': data => (
        <p>
            Удалил файл "<span>{data['data']['resource.title']}</span>"
        </p>
    ),
    'FILE.ATTACH': _data => {
        // console.log(JSON.stringify(data, null, 2));
        return (
            <p>Прикрепил файл</p>
        );
    },
    'FILE.DETACH': _data => {
        // console.log(JSON.stringify(data, null, 2));
        return (
            <p>Открепил файл</p>
        );
    },
    'USER_GROUP.CREATE': data => (
        <p>
            Создал группу участников "<Link to={'/edit-group/' + data['data']['resource.id.1']}>{data['data']['resource.title']}</Link>"
        </p>
    ),
    'USER_GROUP.ADD_MEMBER': data => (
        <p>
            Добавил участника "
            <Link to={'/edit-user/' + data?.['data']?.['login']}>
                {data?.['data']?.['firstname']} {data?.['data']?.['lastname']}
            </Link>
            " в группу "<Link to={'/edit-group/' + data['data']['resource.id.1']}>{data['data']['resource.title']}</Link>"
        </p>
    ),
    'USER_GROUP.DELETE_MEMBER': data => (
        <p>
            Удалил участника "
            <Link to={'/edit-user/' + data?.['data']?.['login']}>
                {data?.['data']?.['firstname']} {data?.['data']?.['lastname']}
            </Link>
            " из группы "<Link to={'/edit-group/' + data['data']['resource.id.1']}>{data['data']['resource.title']}</Link>"
        </p>
    ),
    'USER_GROUP.EDIT': data => (
        <p>
            Редактировал группу участников "
            <Link to={'/edit-group/' + data['data']['resource.id.1']}>{data['data']['resource.title']}</Link>"
        </p>
    ),
    'USER_GROUP.DELETE': data => <p>Удалил группу участников "{data['data']['resource.title']}"</p>,
    'RESOURCE.ADD_MEMBER': () => <p>Добавил участника в ресурс.</p>,
    'RESOURCE.DELETE_MEMBER': () => <p>Удалил участника из ресурса.</p>,
    'RESOURCE.SEARCH': data => <p>Искал "{data['data']['QUERY']}"</p>,
    'SCRIPT.CREATE': data => (
        <p>
            Создал скрипт "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'scripting' : 'archive') + '/script/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'SCRIPT.EDIT': data => (
        <p>
            Изменил скрипт "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'scripting' : 'archive') + '/script/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'SCRIPT.OPEN': data => (
        <p>
            Открыл скрипт "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'scripting' : 'archive') + '/script/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'SCRIPT.FINISH': data => (
        <p>
            Завершил прохождение скрипта "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'scripting' : 'archive') + '/script/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'SCRIPT.UNARCHIVE': data => (
        <p>
            Восстановил из архива скрипт "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'scripting' : 'archive') + '/script/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'SCRIPT.ARCHIVE': data => (
        <p>
            Добавил в архив скрипт "
            <Link to={'/' + (data['status'] === 'ACTIVE' ? 'scripting' : 'archive') + '/script/' + data['data']['resource.id.1']}>
                {data['data']['resource.title']}
            </Link>
            "
        </p>
    ),
    'SCRIPT.DELETE': data => <p>Удалил скрипт "{data['data']['resource.title']}"</p>,
    'USER_POSITION.CREATE': data => <p>Создал должность "{data['data']['resource.title']}"</p>,
    'USER_POSITION.EDIT': data => <p>Изменил должность "{data['data']['resource.title']}"</p>,
    'USER_POSITION.DELETE': data => <p>Удалил должность "{data['data']['resource.title']}"</p>,
    'SUPERVISOR.CREATE': data => (
        <p>
            Назначил "<Link to={'/edit-user/' + data['data']['login']}>{data['data']['firstname'] + ' ' + data['data']['lastname']}</Link>"
            руководителем офиса "{data['data']['resource.title']}"
        </p>
    ),
    'SUPERVISOR.EDIT': data => (
        <p>
            Изменил руководителя "
            <Link to={'/edit-user/' + data['data']['login']}>{data['data']['firstname'] + ' ' + data['data']['lastname']}</Link>"
        </p>
    ),
    'SUPERVISOR.DELETE': data => (
        <p>
            Удалил руководителя "
            <Link to={'/edit-user/' + data['data']['login']}>{data['data']['firstname'] + ' ' + data['data']['lastname']}</Link>"
        </p>
    ),
    'COMPANY.EDIT': data => (
        <p>
            Изменил данные о компании "<Link to={'/global-settings/companies'}>{data['data']['resource.title']}</Link>"
        </p>
    ),
    'OFFICE.CREATE': data => <p>Создал офис "{data['data']['resource.title']}"</p>,
    'OFFICE.EDIT': data => <p>Изменил офис "{data['data']['resource.title']}"</p>,
    'OFFICE.DELETE': data => <p>Удалил офис "{data['data']['resource.title']}"</p>,
    'USER_ACCESS.ASSIGN': data => {
        switch (data['data']['resource.type']) {
            case 'NEWS':
                return (
                    <p>
                        Дал доступ "
                        {data['data']['role.title']}"
                        пользователю "
                        <Link to={'/edit-user/' + data['data']['login']}>{data['data']['firstname'] + ' ' + data['data']['lastname']}</Link>
                        " на новость "<Link to={'/projects/news/' + data['data']['resource.id.1']}>{data['data']['resource.title']}</Link>"
                    </p>
                );
            case 'ARTICLE':
                return (
                    <p>
                        Дал доступ "
                        {data['data']['role.title']}"
                        пользователю "
                        <Link to={'/edit-user/' + data['data']['login']}>{data['data']['firstname'] + ' ' + data['data']['lastname']}</Link>
                        " на статью "<Link to={'/projects/article/' + data['data']['resource.id.1']}>{data['data']['resource.title']}</Link>
                        "
                    </p>
                );
            case 'SECTION':
                return (
                    <p>
                        Дал доступ "
                        {data['data']['role.title']}"
                        пользователю "
                        <Link to={'/edit-user/' + data['data']['login']}>{data['data']['firstname'] + ' ' + data['data']['lastname']}</Link>
                        " на раздел "
                        <Link to={'/projects/' + data['data']['project.id'] + '/' + data['data']['resource.id.1'] + '/section/articles'}>
                            {data['data']['resource.title']}
                        </Link>
                        "
                    </p>
                );
            case 'PROJECT':
                return (
                    <p>
                        Дал доступ "
                        {data['data']['role.title']}"
                        пользователю "
                        <Link to={'/edit-user/' + data['data']['login']}>{data['data']['firstname'] + ' ' + data['data']['lastname']}</Link>
                        " на проект "
                        <Link to={'/projects/' + data['data']['resource.id.1'] + '/articles'}>{data['data']['resource.title']}</Link>"
                    </p>
                );
            default:
                return 'USER_ACCESS.ASSIGN';
        }
    },
    'USER_ACCESS.UNASSIGN': data => {
        console.log(data['data']['resource.type']);

        switch (data['data']['resource.type']) {
            case 'NEWS':
                return (
                    <p>
                        Удалил доступ "
                        {data['data']['role.title']}"
                        пользователю "
                        <Link to={'/edit-user/' + data['data']['login']}>{data['data']['firstname'] + ' ' + data['data']['lastname']}</Link>
                        " на новость "<Link to={'/projects/news/' + data['data']['resource.id.1']}>{data['data']['resource.title']}</Link>"
                    </p>
                );
            case 'ARTICLE':
                return (
                    <p>
                        Удалил доступ "
                        {data['data']['role.title']}"
                        пользователю "
                        <Link to={'/edit-user/' + data['data']['login']}>{data['data']['firstname'] + ' ' + data['data']['lastname']}</Link>
                        " на статью "<Link to={'/projects/article/' + data['data']['resource.id.1']}>{data['data']['resource.title']}</Link>
                        "
                    </p>
                );
            case 'SECTION':
                return (
                    <p>
                        Удалил доступ "
                        {data['data']['role.title']}"
                        пользователю "
                        <Link to={'/edit-user/' + data['data']['login']}>{data['data']['firstname'] + ' ' + data['data']['lastname']}</Link>
                        " на раздел "
                        <Link to={'/projects/' + data['data']['project.id'] + '/' + data['data']['resource.id.1'] + '/section/articles'}>
                            {data['data']['resource.title']}
                        </Link>
                        "
                    </p>
                );
            case 'PROJECT':
                return (
                    <p>
                        Удалил доступ "
                        {data['data']['role.title']}"
                        пользователю "
                        <Link to={'/edit-user/' + data['data']['login']}>{data['data']['firstname'] + ' ' + data['data']['lastname']}</Link>
                        " на проект "
                        <Link to={'/projects/' + data['data']['resource.id.1'] + '/articles'}>{data['data']['resource.title']}</Link>"
                    </p>
                );
            default:
                return 'USER_ACCESS.ASSIGN';
        }
    },
    'GROUP_ACCESS.ASSIGN': data => {
        switch (data['data']['resource.type']) {
            case 'NEWS':
                return (
                    <p>
                        Дал доступ "
                        {data['data']['role.title']}"
                        группе "<Link to={'/edit-group/' + data['data']['group.id']}>{data['data']['group.name']}</Link>" на новость "
                        <Link to={'/projects/news/' + data['data']['resource.id.1']}>{data['data']['resource.title']}</Link>"
                    </p>
                );
            case 'ARTICLE':
                return (
                    <p>
                        Дал доступ "
                        {data['data']['role.title']}"
                        группе "<Link to={'/edit-group/' + data['data']['group.id']}>{data['data']['group.name']}</Link>" на статью "
                        <Link to={'/projects/article/' + data['data']['resource.id.1']}>{data['data']['resource.title']}</Link>"
                    </p>
                );
            case 'SECTION':
                return (
                    <p>
                        Дал доступ "
                        {data['data']['role.title']}"
                        группе "<Link to={'/edit-group/' + data['data']['group.id']}>{data['data']['group.name']}</Link>" на раздел "
                        <Link to={'/projects/' + data['data']['project.id'] + '/' + data['data']['resource.id.1'] + '/section/articles'}>
                            {data['data']['resource.title']}
                        </Link>
                        "
                    </p>
                );
            case 'PROJECT':
                return (
                    <p>
                        Дал доступ "
                        {data['data']['role.title']}"
                        группе "<Link to={'/edit-group/' + data['data']['group.id']}>{data['data']['group.name']}</Link>" на проект "
                        <Link to={'/projects/' + data['data']['resource.id.1'] + '/articles'}>{data['data']['resource.title']}</Link>"
                    </p>
                );
            default:
                return 'USER_ACCESS.ASSIGN';
        }
    },
    'GROUP_ACCESS.UNASSIGN': data => {
        switch (data['data']['resource.type']) {
            case 'NEWS':
                return (
                    <p>
                        Удалил доступ "
                        {data['data']['role.title']}"
                        группе "<Link to={'/edit-group/' + data['data']['group.id']}>{data['data']['group.name']}</Link>" на новость "
                        <Link to={'/projects/news/' + data['data']['resource.id.1']}>{data['data']['resource.title']}</Link>"
                    </p>
                );
            case 'ARTICLE':
                return (
                    <p>
                        Удалил доступ "
                        {data['data']['role.title']}"
                        группе "<Link to={'/edit-group/' + data['data']['group.id']}>{data['data']['group.name']}</Link>" на статью "
                        <Link to={'/projects/article/' + data['data']['resource.id.1']}>{data['data']['resource.title']}</Link>"
                    </p>
                );
            case 'SECTION':
                return (
                    <p>
                        Удалил доступ "
                        {data['data']['role.title']}"
                        группе "<Link to={'/edit-group/' + data['data']['group.id']}>{data['data']['group.name']}</Link>" на раздел "
                        <Link to={'/projects/' + data['data']['project.id'] + '/' + data['data']['resource.id.1'] + '/section/articles'}>
                            {data['data']['resource.title']}
                        </Link>
                        "
                    </p>
                );
            case 'PROJECT':
                return (
                    <p>
                        Удалил доступ "
                        {data['data']['role.title']}"
                        группе "<Link to={'/edit-group/' + data['data']['group.id']}>{data['data']['group.name']}</Link>" на проект "
                        <Link to={'/projects/' + data['data']['resource.id.1'] + '/articles'}>{data['data']['resource.title']}</Link>"
                    </p>
                );
            default:
                return 'USER_ACCESS.ASSIGN';
        }
    },
    'TERM.CREATE': data => {
        if (data['data']['project.id']) {
            return (
                <p>
                    Создал термин "{data['data']['resource.title']}" в проекте "
                    <Link
                        to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/' + data['data']['project.id'] + '/articles'}
                    >
                        {data['data']['project.title']}
                    </Link>
                    "
                </p>
            );
        } else {
            return <p>Создал общий термин "{data['data']['resource.title']}"</p>;
        }
    },
    'TERM.EDIT': data => {
        if (data['data']['project.id']) {
            return (
                <p>
                    Изменил термин "{data['data']['resource.title']}" в проекте "
                    <Link
                        to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/' + data['data']['project.id'] + '/articles'}
                    >
                        {data['data']['project.title']}
                    </Link>
                    "
                </p>
            );
        } else {
            return <p>Изменил общий термин "{data['data']['resource.title']}"</p>;
        }
    },
    'TERM.DELETE': data => {
        if (data['data']['project.id']) {
            return (
                <p>
                    Удалил термин в проекте "
                    <Link
                        to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/' + data['data']['project.id'] + '/articles'}
                    >
                        {data['data']['project.title']}
                    </Link>
                    "
                </p>
            );
        } else {
            return <p>Удалил общий термин "{data['data']['resource.title']}"</p>;
        }
    },
    'ARTICLE.RESTORE_SNAPSHOT': data => {
        return (
            <p>
                Восстановил версию статьи "
                <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/article/' + data['data']['resource.id.1']}>
                    {data['data']['resource.title']}
                </Link>
                " - "Версия {data['data']['version']}{' '}
                {DateTime.fromISO(data['data']['date']).setLocale('ru').toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}"
            </p>
        );
    },
    'NEWS.RESTORE_SNAPSHOT': data => {
        return (
            <p>
                Восстановил версию новости "
                <Link to={'/' + (data['status'] === 'ACTIVE' ? 'projects' : 'archive') + '/news/' + data['data']['resource.id.1']}>
                    {data['data']['resource.title']}
                </Link>
                " - "Версия {data['data']['version']}{' '}
                {DateTime.fromISO(data['data']['date']).setLocale('ru').toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}"
            </p>
        );
    },
};
export const Months = [
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря',
];
export const Days = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];

const Activity = () => {
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const data = await api.logging.getLogs({ size: 20, page: 0 });
            const items = [];

            const current = new Date();

            const currentYear = current.getFullYear();
            const currentMonth = current.getMonth();
            const currentDay = current.getDate();

            data.forEach(item => {
                const itemDate = new Date(item['timestamp']);

                const itemYear = itemDate.getFullYear();
                const itemMonth = itemDate.getMonth();
                const itemDay = itemDate.getDate();

                const timestamp = new Date(itemYear, itemMonth, itemDay).getTime() / 1000;
                let key = items.findIndex(item => item.timestamp === timestamp);

                if (key === -1) {
                    if (itemYear === currentYear && itemMonth === currentMonth && itemDay === currentDay) {
                        items.push({
                            timestamp: timestamp,
                            title: 'Сегодня',
                            users: [],
                        });
                    } else {
                        items.push({
                            timestamp: timestamp,
                            title: Days[itemDate.getDay()] + ', ' + itemDay + ' ' + Months[itemMonth],
                            users: [],
                        });
                    }

                    key = items.length - 1;
                }

                // Check user;
                let userIndex = items[key]['users'].findIndex(user => user['id'] === item?.['userInfo']?.['id']);

                if (userIndex === -1) {
                    items[key]['users'].push({
                        id: item?.['userInfo']?.['id'],
                        login: item?.['userInfo']?.['login'],
                        firstName: item?.['userInfo']?.['firstName'],
                        lastName: item?.['userInfo']?.['lastName'],
                        avatar: api.upload.getImage(item?.['userInfo']?.['avatarUuid'], false, 0),
                        items: [],
                    });
                    userIndex = items[key]['users'].length - 1;
                }

                // Add log to user;
                let hours = itemDate.getHours();
                item['hours'] = hours.toString().length === 1 ? '0' + hours : hours;

                let minutes = itemDate.getMinutes();
                item['minutes'] = minutes.toString().length === 1 ? '0' + minutes : minutes;

                items[key]['users'][userIndex]['items'].push(item);
            });

            setLogs(items);
        }

        fetchData();
    }, []);

    return (
        <div className={cx.activity}>
            <div className={cx.items}>
                {logs.map((item, itemIndex) => {
                    return (
                        <div key={itemIndex} className={cx.item}>
                            <h3>{item.title}</h3>
                            <div className={cx.users}>
                                {item.users.map((user, userIndex) => {
                                    return (
                                        <div key={userIndex} className={cx.user}>
                                            <div className={cx.head}>
                                                <div className={cx.avatar} style={{ backgroundImage: `url(${user.avatar})` }} />
                                                <Link to={'/edit-user/' + user.login}>
                                                    {user.firstName} {user.lastName}
                                                </Link>
                                            </div>
                                            <div className={cx.records}>
                                                {user.items.map((record, recordIndex) => {
                                                    return (
                                                        <div key={recordIndex} className={cx.record}>
                                                            <span className={cx.recordTime}>
                                                                {record.hours}:{record.minutes}
                                                            </span>
                                                            {ActivityTypes[record.resourceType + '.' + record.type]
                                                                ? ActivityTypes[record.resourceType + '.' + record.type](record)
                                                                : record.resourceType + '.' + record.type}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default React.forwardRef((props, ref) => <Activity {...props} forwardedRef={ref} />);
