import React from 'react';
import classNames from 'classnames';
import Icon, { Icons } from 'uikit/icon';
import cs from './back-button.module.scss';
import { Link } from 'shared/router';

const BackButton = ({ id = '', className = null, onClick = () => {}, link = '' }) => {
    if (link) {
        return (
            <Link to={link}>
                <Icon
                    id={id + "BackBtn"}
                    className={classNames(cs.backButton, className)}
                    type={Icons.BACK2}
                    width={32}
                    height={32}
                />
            </Link>
        );
    } else {
        return (
            <Icon
                id={id + "BackBtn"}
                className={classNames(cs.backButton, className)}
                type={Icons.BACK2}
                width={32}
                height={32}
                onClick={onClick}
            />
        );
    }
};

export default BackButton;
