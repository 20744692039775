import { useContext, useEffect } from 'react';
import { RouterContext } from '../routerProvider';

export const useNavigate = (): Function => {
    const context = useContext(RouterContext);

    if (!context) {
        throw new Error('useNavigate must be used within a RouterProvider');
    }

    const navigate = context.router.getUseNavigate()();
    const customNavigateFunction = context.router.getCustomNavigateFunction();

    useEffect(() => {
        context.router.setNavigateFunction(navigate);
    }, [context.router, navigate]);

    return customNavigateFunction ? customNavigateFunction : navigate;
};
