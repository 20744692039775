// [min, max] zoom scale limit
export const IMAGE_ZOOM_LIMIT: [number, number] = [0.2, 18] as const;
// initial zoom scale
export const IMAGE_ZOOM_INITIAL: number = 0.8 as const;
// error messages
export const IMAGE_MESSAGES = {
    error: {
        unavailable: 'Изображение повреждено или недоступно',
        undownloadable: 'Ошибка скачивания. Попробуйте скачать файл позднее'
    },
    success: {
        downloaded: 'Изображение скачено'
    }
} as const; 
// icons props for modal toolbars
export const ICONS_PROPS = {
    header: { color: 'white' as 'white', size: 18 },
    footer: { color: 'white' as 'white', size: 20 },
} as const; 