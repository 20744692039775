import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';

const lists = ['bulletList', 'orderedList'];

export const List = Extension.create({
    name: 'normalizeList',

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey('normalizeList'),
                appendTransaction: (_transactions, _oldState, newState) => {
                    try {
                        const tr = newState.tr;
                        let modified = false;

                        newState.doc.descendants((node, pos) => {
                            node.forEach((child, childOffset) => {
                                const childPos = pos + childOffset + 1;

                                if (
                                    child.type?.name !== 'listItem'
                                    || !lists.includes(child.content.firstChild.type.name)
                                ) {
                                    return;
                                }

                                if (child.childCount !== 1) {
                                    const newLastChild = child.content.firstChild.content.lastChild.copy(
                                        child.content.firstChild.content.lastChild.content.addToEnd(
                                            child.content.lastChild
                                        )
                                    );

                                    const newFirstChildContent = child.content.firstChild.content.replaceChild(
                                        child.content.firstChild.content.childCount - 1,
                                        newLastChild
                                    );

                                    tr.replaceWith(
                                        childPos,
                                        childPos + child.content.size,
                                        newFirstChildContent.content.filter(node => !!node.textContent)
                                    );
                                } else {
                                    tr.replaceWith(
                                        childPos,
                                        childPos + child.content.size,
                                        child.content.firstChild.content.content.filter(node => !!node.textContent)
                                    );
                                }

                                modified = true;
                            });
                        });

                        return modified ? tr : null;
                    } catch {
                        // TODO: Here we should log in sentry;
                    }

                    return null;
                },
            }),
        ];
    },
});
