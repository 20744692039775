import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import ReactDatePicker from 'react-date-picker';
import Icon, { Icons } from 'uikit/icon';
import cx from './styles.module.scss';
import moment from 'moment';
import './styles.css';

export const DatePicker = ({
    onChange = () => {},
    value,
    disabled,
    error,
    className = '',
    required,
    id = null,
    label,
    customRef,
    name,
    defaultValue,
    minDate = null,
    maxDate = null,
    isEndOfTheDay
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const [date, setDate] = useState(defaultValue ? defaultValue?.toISOString() : value?.toISOString() ?? '');
    const [selfValue, setSelfValue] = useState(defaultValue ? defaultValue : value || '');

    const onChangeSelf = (data) => {
        if (data === undefined) {
            return;
        }

        if (!Date.parse(data)) {
            if (!data || !data.target.value) {
                return;
            }

            let separator = null;

            if (data.target.value.indexOf('.') !== -1) {
                separator = '.';
            } else if (data.target.value.indexOf('/') !== -1) {
                separator = '/';
            } else if (data.target.value.indexOf('-') !== -1) {
                separator = '-';
            }

            let dateStr = data.target.value;
            dateStr = dateStr.slice(0, dateStr.length - 4) + separator + dateStr.slice(dateStr.length - 4, dateStr.length);

            let date = new Date(dateStr);

            if (isNaN(date.getTime())) {
                dateStr = dateStr.slice(dateStr.indexOf(separator) + 1, dateStr.length - 4) +
                    dateStr.slice(0, dateStr.indexOf(separator) + 1) + dateStr.slice(dateStr.length - 4, dateStr.length);

                date = new Date(dateStr);

                if (isNaN(date.getTime())) {
                    return;
                }
            }

            data = date;
        }

        if (isEndOfTheDay) {
            data = moment(data).add(23, 'h')
                               .add(59, 'm')
                               .add(59, 's')
                               .toDate();
        }

        setSelfValue(data);
        setDate(data && data.toISOString ? data.toISOString() : '');
        onChange(data);
    };

    useEffect(() => {
        if (value !== 'undefined' && value !== undefined) {
            if (value !== selfValue) {
                setSelfValue(value);
            }

            if (value?.toISOString() !== date) {
                setDate(value?.toLocaleString() ?? '');
            }
        }
    }, [value, selfValue, date]);
    useEffect(() => {
        if (!isOpen) {
            return;
        }

        document.querySelector('.react-date-picker__calendar--open').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });
    }, [isOpen]);

    return (
        <div
            id={id}
            style={{ width: '100%' }}
            className={classNames({ error: !!error, disabled: disabled }, className)}
            onClick={() => !disabled ? setIsOpen(true) : null}
        >
            {label && <div className={classNames(cx.label, { [cx.required]: required })}>{label}</div>}
            <ReactDatePicker
                isOpen={isOpen}
                onCalendarClose={() => setIsOpen(false)}
                minDate={minDate}
                maxDate={maxDate}
                clearIcon={null}
                value={selfValue}
                disabled={disabled}
                calendarIcon={disabled ? null : <Icon type={Icons.DATE_CALENDAR} width={14} height={15}/>}
                onChange={(data) => onChangeSelf(data)}
                className={classNames({
                    'react-calendar__empty': !selfValue,
                    'react-calendar__not-empty': !!selfValue,
                    [cx.errorInput]: !!error,
                    'field-invalid': !!error,
                })}
            />
            <input type="text" name={name} value={date} ref={customRef} style={{ display: 'none' }} readOnly={true}/>
            {error && <div className={cx.errorMessage}>{error}</div>}
        </div>
    );
};
