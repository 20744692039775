import { Node } from '@tiptap/core';

export const Div = Node.create({
    name: 'div',
    content: 'block*',
    group: 'block',

    addAttributes() {
        return {
            id: {
                default: null,
            },
            style: {
                default: null,
            },
        };
    },
    parseHTML() {
        return [
            {
                tag: 'div',
                getAttrs: el => !!el.id,
                priority: 1,
            },
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ['div', HTMLAttributes, 0];
    },
});
