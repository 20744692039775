import { 
	EEmbedDrawIoAttr, 
	EEmbedDrawIoError, 
	EEmbedDrawIoMode, 
	EEmbedDrawIoAction, 
	useEmbedDrawIoDialogStore,
	isEqualStr, 
	isIFrame,
	decodeBase64,
 } from '../index';

export const initializeEmbedDrawIoDialog = {
	preview: (staticDiagrams: HTMLElement[] | []): void => {

		if (staticDiagrams.length === 0) return;
	
		const { INIT, LOAD } = EEmbedDrawIoAction;
		const { 
			options: op, 
			setMode, 
			setDialogLoaded, 
			setIsPreviewDialog, 
			setCurrentBase64Image,
			resetDialogMainState
		} = useEmbedDrawIoDialogStore.getState();
	
		staticDiagrams.forEach((el: HTMLElement) => {
	
			const xml = el.getAttribute(EEmbedDrawIoAttr.DATA_ENCODED_XML) || '';
			const src = el.getAttribute(EEmbedDrawIoAttr.DATA_IMAGE_SRC) || '';
	
			const handleClick = (event: MouseEvent): void => {
	
				if ((event.target as HTMLElement).closest('A')) return;
	
				setCurrentBase64Image(src)
				setMode(EEmbedDrawIoMode.VIEWER_MODE);
				setIsPreviewDialog(true);
	
				const purgeMessageDialog = (): void => {
					window.removeEventListener('message', handleMessageDialog);
				};
	
				const handleMessageDialog = (event: MessageEvent): void => {
	
					if (!isEqualStr(event.origin, op.drawIoOriginLink)) {
						// console.warn(EEmbedDrawIoError.INVALID_MESSAGE_SOURCE, event.origin);
						return;
					}
	
					try { 
						const iframe = document.querySelector(`iframe[title=${ EEmbedDrawIoAttr.TITLE_IFRAME }]`);
						const msg = JSON.parse(event.data);
	
						switch (msg.event) {
							case INIT:
								if (isIFrame(iframe)) {
									iframe.contentWindow?.postMessage(
										JSON.stringify({
											action: LOAD,
											xml: decodeBase64(xml),
										}),
										op.drawIoOriginLink
									);
								} 
								else {
									purgeMessageDialog();
									resetDialogMainState();
									console.warn(EEmbedDrawIoError.ERROR_PROCESSING_MESSAGE, msg.event);
								}
								break;
	
							case LOAD:
								setDialogLoaded(true);
								break;
	
							default:
								purgeMessageDialog();
								resetDialogMainState();
								console.warn(EEmbedDrawIoError.UNKNOW_ACTION, msg.event);
						}
	
					} catch (error) {
						console.error(EEmbedDrawIoError.UNKNOW_ACTION, error);
					}
				}
	
				window.addEventListener('message', handleMessageDialog);
			}
	
			el.addEventListener('click', handleClick)
		});
	
		return;
	}
}