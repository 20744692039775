import React, { ReactElement } from 'react';
import { Router as Routes, Link, Location as Provider, globalHistory, useNavigate, useParams } from '@reach/router';
import {
    GetProviderProps,
    RenderLinkProps,
    RenderRouteProps,
    RenderRouteGroupProps,
    Router,
    Location, RenderOutletProps
} from './router';

export class ReachRouter extends Router {
    private navigateFunction: Function = () => {};
    private customNavigateFunction?: Function = undefined;

    private adaptChildren = (children?: React.ReactNode): ReactElement[] | undefined => {
        if (!children) {
            return undefined;
        }

        return React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) {
                return null;
            }

            const childProps = child.props as {
                to?: string;
                isDefault?: boolean;
                children?: React.ReactNode;
            };

            const newProps: Record<string, unknown> = { ...childProps };

            if (childProps.to !== undefined) {
                newProps.path = childProps.to;
            }

            if (childProps.isDefault !== undefined) {
                newProps.default = childProps.isDefault;
            }

            if (childProps.children !== undefined) {
                newProps.children = this.adaptChildren(childProps.children);
            }

            return React.cloneElement(child, { ...newProps });
        }) as React.ReactElement[];
    };

    getProvider = ({ children }: GetProviderProps): React.ReactNode => {
        // @ts-ignore
        return <Provider>{children}</Provider>;
    }

    getRouteGroup = ({ children, ...props }: RenderRouteGroupProps): React.ReactNode => {
        // @ts-ignore
        return <Routes {...props}>{this.adaptChildren(children)}</Routes>;
    }

    getRoute = ({ component, children, ...props }: RenderRouteProps): React.ReactNode => {
        // @ts-ignore
        return React.cloneElement(component, { children, ...props });
    }

    getOutlet = ({ children }: RenderOutletProps): React.ReactNode => {
        return children;
    }

    getParams = (): Function => {
        return useParams;
    }

    getLocation = (): Location => {
        return {
            origin: globalHistory.location.origin,
            search: globalHistory.location.search,
            pathname: globalHistory.location.pathname,
        };
    }

    getLink = ({ children, to, ...props }: RenderLinkProps): React.ReactNode => {
        // @ts-ignore
        return <Link to={to} {...props}>{children}</Link>;
    }

    getUseNavigate = (): Function => {
        return useNavigate;
    }

    getNavigateFunction = (): Function => {
        return this.navigateFunction;
    }

    setNavigateFunction = (func: Function): void => {
        this.navigateFunction = func;
    }

    getCustomNavigateFunction = (): Function | undefined => {
        return this.customNavigateFunction;
    }

    setCustomNavigateFunction = (func: Function): void => {
        this.customNavigateFunction = func;
    }
}
