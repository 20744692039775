import AuthApi from './auth-api';
import ProjectApi from 'api/project-api';
import SectionApi from 'api/./section-api';
import ArticleApi from 'api/article-api';
import NewsApi from 'api/news-api';
import ArchiveApi from 'api/archive-api';
import UserApi from 'api/user-api';
import LoggingApi from 'api/logging-api';
import StatsApi from 'api/stats-api';
import NotificationApi from 'notifications/infrastructure/notification-api';
import FileApi from 'api/file-api';
import ResourceApi from 'api/resource-api';
import SearchApi from 'api/search-api';
import RoleApi from 'api/role-api';
import GroupApi from 'api/group-api';
import ChatApi from 'api/chat-api';
import GroupsApi from 'api/groups-api';
import OfficeApi from 'api/office-api';
import CompanyApi from 'api/company-api';
import LikeApi from 'api/like-api';
import DislikeApi from 'api/dislike-api';
import UserPositionApi from 'api/user-position-api';
import FavoritesApi from 'api/favorites-api';
import ParserApi from 'api/parser-api';
import ArticleCommentsApi from 'api/article-comments-api';
import NewsCommentsApi from 'api/news-comments-api';
import ContentConverterApi from 'api/content-converter-api';
import FileCommentsApi from 'api/file-comments-api';
import CustomRolesApi from 'api/./custom-roles-api';
import GlobalMenuApi from 'api/global-menu';
import ReportsApi from 'api/reports-api';
import LockApi from 'api/lock-api';
import SnapshotApi from 'api/./snapshot-api';
import FilePreviewApi from 'api/./file-preview-api';
import ErrorReportResource from 'api/error-report-resource-api';
import TrainingApi from 'api/training-api';
import UploadApi from './upload-api';
import GlossaryApi from './glossary-api';
import ScriptingApi from './scripting-api';
import ScriptingDraftApi from './scripting-draft-api';
import LicenseApi from './license-api';
import TagApi from 'api/tag-api';
import ViewApi from 'api/view-api';
import ChatBotApi from './chat-bot-api';
import ConfigApi from './config-api';
// import savedRequestApi from './saved-request-api';
// import MockApi from './mock-api';


// if (process.env.NODE_ENV === 'development') {
//     import('./mock-setup')
//         .then(({ setupMockResponses }) => { setupMockResponses(); })
//         .catch((err) => console.warn('%c🚨 Ошибка загрузки Mock API (mock-setup): ', 
//             'color: crimson; font-weight: bold; font-size: 16px;', err));
// }

function apiService() {
    return {
        auth: new AuthApi({ prefix: '/api/' }),
        project: new ProjectApi({ prefix: '/services/kms/api/v1/' }),
        article: new ArticleApi({ prefix: '/services/kms/api/v1/' }),
        news: new NewsApi({ prefix: '/services/kms/api/v1/' }),
        archive: new ArchiveApi({ prefix: '/services/kms/api/v1/' }),
        user: new UserApi({ prefix: '/services/kms/api/' }),
        section: new SectionApi({ prefix: '/services/kms/api/v1/section/' }),
        logging: new LoggingApi({ prefix: '/services/logging/api/' }),
        stats: new StatsApi({ prefix: '/services/' }),
        reports: new ReportsApi({ prefix: '/services' }),
        notification: new NotificationApi({ prefix: '/services/notification/api/' }),
        file: new FileApi({ prefix: '' }),
        filePreview: new FilePreviewApi({ prefix: '/services/parser/api/v1/file-preview' }),
        fileComments: new FileCommentsApi({ prefix: '/services/kms/api/v1/file-comment' }),
        resource: new ResourceApi({ prefix: '/services/kms/api/v1/resource/' }),
        search: new SearchApi({ prefix: '/' }),
        group: new GroupApi({ prefix: '/services/kms/api/v1/groups/' }),
        role: new RoleApi({ prefix: '/services/kms/api/v1/' }),
        chat: new ChatApi({ prefix: '/services/chat/api/' }),
        groups: new GroupsApi({ prefix: '/services/kms/api/v1/groups/' }),
        company: new CompanyApi({ prefix: '/services/kms/api/v1/companies' }),
        office: new OfficeApi({ prefix: '/services/kms/api/v1/offices' }),
        like: new LikeApi({ prefix: '/services/kms/api/v1/like' }),
        dislike: new DislikeApi({ prefix: '/services/kms/api/v1/dislike' }),
        userPosition: new UserPositionApi({ prefix: '/services/kms/api/v1/user-position' }),
        favorites: new FavoritesApi({ prefix: '/services/kms/api/v1/favorites' }),
        parser: new ParserApi({ prefix: '' }),
        articleComments: new ArticleCommentsApi({ prefix: '/services/kms/api/v1/article-comment' }),
        newsComments: new NewsCommentsApi({ prefix: '/services/kms/api/v1/news-comment' }),
        contentConverter: new ContentConverterApi({ prefix: '/services/parser/api/v1/content-converter' }),
        customRoles: new CustomRolesApi({ prefix: '/services/kms/api/v1/custom-roles' }),
        globalMenu: new GlobalMenuApi({ prefix: '/services/kms/api/v1/' }),
        lock: new LockApi({ prefix: '/services/kms/api/v1/' }),
        snapshot: new SnapshotApi({ prefix: '/services/kms/api/v1/' }),
        scripting: new ScriptingApi({ prefix: '/services/kms/api/v1/script' }),
        scriptingDraft: new ScriptingDraftApi({ prefix: '/services/kms/api/v1/' }),
        errorReportResource: new ErrorReportResource({ prefix: '/services/kms/api/v1/' }),
        training: new TrainingApi({ prefix: '/services/training/api/' }),
        upload: new UploadApi({ prefix: '/services/upload/api/v1/' }),
        glossary: new GlossaryApi({ prefix: '/services/kms/api/v1/terms' }),
        license: new LicenseApi({ prefix: '/services/kms/api/v1/license' }),
        tags: new TagApi({ prefix: '/services/kms/api/v1/' }),
        view: new ViewApi({ prefix: '/services/kms/api/v1/view/' }),
        chatBot: new ChatBotApi({ prefix: '/services/kms/api/v1/chat-ai' }),
        config: new ConfigApi({ prefix: '/services/kms/api/v1/config/' }),
        // savedRequest: new savedRequestApi({ prefix: '' }),
        // mock: new MockApi({ prefix: '' })
    };
}

export default apiService();
