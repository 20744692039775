import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { Link, useNavigate } from 'shared/router';
import { useAuthorized, useDialog, useGlobalContext, useMessage } from 'lib/hooks';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import PrivatePage from 'containers/private-page-wrapper';
import { TableComponent } from 'components/data-components';
import Confirmation from 'components/confirmation';
import { Icons } from 'uikit/icon';
import { EditRowToolbar } from 'uikit/table';
import api from 'api';
import cx from './groups-page.module.scss';
import { MultiClumpTooltip } from 'components/MultiClumpTooltip/MultiClumpTooltip';

const GroupsPage = ({ hasAnyAuthorities }) => {
    const tableRef = useRef(null);

    const { platform } = useGlobalContext();
    const navigate = useNavigate();

    const { isAuthorizedAction } = useAuthorized();
    const canDelete = isAuthorizedAction([GLOBAL_ACTIONS.GROUP_DELETE]);

    const { addSuccess, addError } = useMessage();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [isLoading, setIsLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [groups, setGroups] = useState([]);

    const [checkedCheckbox, setCheckedCheckbox] = useState([]);
    const checkedCheckboxRef = useRef(checkedCheckbox);

    const onPaginate = useCallback(async (offset, count, sort, search) => {
        try {
            setIsLoading(true);
            let groupSearch = '';

            if (search !== '') {
                groupSearch = {
                    'orSearch.specified': true,
                    'description.contains': search,
                    'name.contains': search
                };
            }

            var localGroups = await api.groups.getGroups(offset / count, count,
                sort.by ? (sort.by + ',' + (sort.desc ? 'desc' : 'asc')) : '', groupSearch);

            const groupsTmp = localGroups.data.content;

            setGroups(groupsTmp);
            setTotal(parseInt(localGroups.data['totalElements']));

            setIsLoading(false);
        } catch (e) {
            console.log(e);
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
        }
    }, [addError]);
    const checkboxHandler = useCallback((node) => {
        setCheckedCheckbox((prevValue) => {
            if (prevValue.some(c => c.id === node.id)) {
                return prevValue.filter(c => c.id !== node.id);
            } else {
                return [...prevValue, node]
            }
        })
    }, []);

    const removeGroups = useCallback(async (data) => {
        try {
            setIsLoading(true);
            closeDialog();

            if (data?.id) {
                await api.groups.deleteGroup(data['id']);
            }  else if (checkedCheckboxRef.current.length) {
                for (let i = 0; i < checkedCheckboxRef.current.length; i++) {
                    await api.groups.deleteGroup(checkedCheckboxRef.current[i]['id']);
                }
            }

            tableRef?.current?.reload();
            addSuccess(data.length ? data.length === 1 ?  'Группа удалена' : 'Выбранные группы удалены' : 'Группа удалена');
        } catch (error) {
            addError('При удалении произошла ошибка');
        } finally {
            setIsLoading(false);
        }
    }, [closeDialog, addSuccess, addError]);
    const onRemoveGroups = useCallback((data) => {
        if (data?.length) {
            setCheckedCheckbox(data.map(d => d.original))
        }

        let text = '';

        if (data?.length) {
            text = data.map((item) => {
                return {
                    ...item.original,
                    text: (
                        <>
                            <span onClick={() => navigate(`/edit-group/${item.original.id}`)} style={{ color: '#279BD9', cursor: 'pointer' }}>
                                {item?.original?.name}
                            </span>
                        </>
                    )
                }
            });
        } else {
            text = (
                <span>
                    Вы действительно хотите удалить группу&nbsp;
                    <span style={{ color: '#279BD9', cursor: 'pointer' }} onClick={() => navigate(`/edit-group/${data.id}`)}>
                        {data?.name}
                    </span>
                    &nbsp;? Этот процесс нельзя будет отменить
                </span>
            );
        }

        openDialog({
            title: 'Удаление',
            subTitle: data?.length ? 'Вы действительно хотите удалить нижеперечисленные группы? Этот процесс нельзя будет отменить' : null,
            text,
            color: 'red',
            contentType: data?.length ? 'CHECKBOX_LIST' : 'TEXT',
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Да, удалить',
            onChange: (groups) => checkboxHandler(groups),
            onSubmit: () => removeGroups(data),
            onClose: closeDialog
        });
    }, [closeDialog, openDialog, removeGroups, navigate, checkboxHandler]);

    const columns = useMemo(() => [
        {
            Header: 'НАИМЕНОВАНИЕ',
            accessor: 'name',
            width: 200,
            Cell: (data) => {
              return (
                <Link to={`/edit-group/${data.row.original['id']}`} className={cx.nameCell}>
                    <MultiClumpTooltip label={data.row?.original['name']} />
                    <p className={cx.description}>{data.row?.original['description']}</p>
                </Link>
              )
            }
        },
        {
            Header: 'УЧАСТНИКИ',
            accessor: 'memberCount',
            width: 100,
            Cell: ({row}) => {
              return (<p>{row?.original['memberCount']} участников</p>)
            }
        },
        {
            id: 3,
            settings: platform === 'mobile' ? ['no_td_wrap'] : [],
            Cell: function(data) {
                const tools = [];
                tools.push({
                    icon: Icons.EDIT_PEN,
                    tooltip: 'Редактировать',
                    link: `/edit-group/${data.row.original['id']}/edit`,
                    isHidden: () => !data.row.original.permissions?.canEdit
                });
                tools.push({
                    icon: Icons.TRASH,
                    tooltip: 'Удалить',
                    onClick: (data) => onRemoveGroups(data),
                    isHidden: () => !data.row.original.permissions?.canDelete
                });
                return EditRowToolbar(tools)(data);
            }
        }
    ], [onRemoveGroups, platform]);
    const tableActions = canDelete ? [
        {
            icon: Icons.TRASH,
            label: 'Удалить',
            onClick: (data) => onRemoveGroups(data)
        }] : [];

    useEffect(() => {
        checkedCheckboxRef.current = checkedCheckbox;
    }, [checkedCheckbox]);

    return (
        <PrivatePage hasAnyAuthorities={hasAnyAuthorities}>
            <Confirmation {...dialogState} />
            <div className={cx.userContainer}>
                <h2>Группы</h2>
                <TableComponent
                    innerRef={tableRef}
                    searchTitle="Поиск"
                    addTitle="Добавить группу"
                    columns={columns}
                    actions={tableActions}
                    isLoading={isLoading}
                    total={total}
                    data={groups}
                    onPaginate={onPaginate}
                    addLink={isAuthorizedAction([GLOBAL_ACTIONS.GROUP_CREATE]) ? '/add/group' : ''}
                    // onRowClick={(data) => navigate(`/edit-group/${data['id']}`)}
                    isMobile={platform === 'mobile'}
                />
            </div>
        </PrivatePage>
    );
};

export default GroupsPage;
