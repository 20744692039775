import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useCancelBehavior, useGlobalContext, useMessage } from 'lib/hooks';
import { passwordValidator } from 'lib/util/validators.util';
import hot from 'containers/hot-container';
import Button from 'uikit/button';
import api from 'api';
import cs from 'components/user-form/user-form.module.scss';
import { useUserFormModel } from 'lib/hooks/use-user-form-model';
import PrivatePage from 'containers/private-page-wrapper';
import AvatarChange from 'components/avatar-change/avatar-change';
import UserForm from 'components/user-form/user-form';
import {setUsers} from 'slice/authSlice';
import {useDispatch} from 'react-redux';
import { setDocumentTitle, useNavigate } from 'shared/router';

const AddUserPage = ({ hasAnyAuthorities }) => {
    const navigate = useNavigate();
    const { platform, setFormDataChanged } = useGlobalContext();

    const { addError, addSuccess } = useMessage();
    const { cancel } = useCancelBehavior();
    const { user, loading, setLoading } = useUserFormModel();

    const [initEnabledNotificationActionTypes, setInitEnabledNotificationActionTypes] = useState(false);

    const [isSubmit, setIsSubmit] = useState(false);

    const dispatch = useDispatch();

    const onAvatarSubmit = (avatar) => {
        user.avatar = avatar;
        user.avatarChanged = true;
        user.commit();
        setFormDataChanged(true);
    };

    const onAvatarRemove = () => {
        user.avatar = null;
        user.avatarUuid = null;
        user.avatarChanged = true;
        user.commit();
        setFormDataChanged(true);
    };

    const addUser = async () => {
        setIsSubmit(true);

        if (!user.validateFields([])) {
            return;
        }

        if (await user.validateLoginExists()) {
            return;
        }

        setLoading(true);

        try {
            setFormDataChanged(false);
            const formData = user.getFormData();
            await api.user.postUser(formData);

            const users = await api.user.getUsers(0, 999999);
            dispatch(setUsers(users.data));

            addSuccess('Участник создан');
            navigate(`/edit-user/${user.login.toLowerCase()}`);
        } catch (error) {
            user.errors.email = 'Данная почта уже используется';
            addError('Не удалось создать участника');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const genPassword = (len) => {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result;

            do {
                result = '';

                for (let i = 0; i < len; i++) {
                    result += characters.charAt(Math.floor(Math.random() * characters.length));
                }
            } while (passwordValidator(result) !== null);

            return result;
        };

        if (user.password === undefined) {
            user.password = genPassword(8);
            user.commit();
        }
    }, [user, user.id]);

    useEffect(() => {
        if (!initEnabledNotificationActionTypes) {
            for (const i of ['ARTICLE', 'NEWS', 'FILE', 'USER', 'PROJECT', 'SECTION', 'SCRIPT']) {
                for (const j of ['READ', 'CREATE', 'EDIT', 'DELETE', 'ARCHIVE']) {
                    if (i === 'ARTICLE' || i === 'NEWS' || i === 'PROJECT' || i === 'SCRIPT') {
                        user.enabledNotificationActionTypes[i][j] = true;
                    } else {
                        if (j !== 'ARCHIVE') {
                            user.enabledNotificationActionTypes[i][j] = true;
                        }
                    }
                }
            }
            setInitEnabledNotificationActionTypes(true);
        }
    }, [initEnabledNotificationActionTypes, user, user.id]);

    useEffect(() => {
        if (isSubmit && Object.values(user.errors).some(v => v)) {
            user.scrollToError();
            setIsSubmit(false);
        }

    }, [isSubmit, user, user.errors]);
    useEffect(() => {
        setDocumentTitle('Добавление участника — KMS Gran');
    }, []);

    return (
        <PrivatePage hasAnyAuthorities={hasAnyAuthorities}>
            <div className={classNames({[cs.mobile]: platform === 'mobile'})}>
                <div id={'addUserPageTitle'} className={cs.pageTitle}>{'Добавление участника'}</div>

                <div className={cs.avatarContainer}>
                    <AvatarChange value={user.avatarChanged && user.avatar ? user.avatar
                        : (user.avatarUuid ? api.upload.getImage(user.avatarUuid, false, 512) : '/img/avatar.png')}
                                  onSubmit={onAvatarSubmit} onRemove={onAvatarRemove}/>
                </div>

                <UserForm user={user} loading={loading} isEditMode={true}>
                    <div className={cs.controlsContainer}>
                        <Button label={'Отмена'} onClick={cancel} fullWidth={platform === 'mobile'}/>
                        <Button onClick={addUser} label={'Сохранить'} disabled={loading} color={'green'}
                                fullWidth={platform === 'mobile'}/>
                    </div>
                </UserForm>
            </div>
        </PrivatePage>
    );
};

export default hot(AddUserPage);
