import { Node, mergeAttributes } from '@tiptap/core';
import { Plugin } from '@tiptap/pm/state';

export const Column = Node.create({
    name: 'column',
    group: 'column',
    content: '(paragraph|block)*',
    defining: true,
    selectable: true,

    addAttributes() {
        return {
            width: {
                default: null,
                renderHTML: () => {
                    return {
                        class: `column`,
                    };
                },
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'div.column',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        const attrs = mergeAttributes(HTMLAttributes);
        return ['div', attrs, 0];
    },

    addProseMirrorPlugins() {
        return [
            new Plugin({
                appendTransaction: (_transactions, _oldState, newState) => {
                    const tr = newState.tr;

                    newState.doc.descendants((node, pos) => {
                        if (node.type.name === 'column' && node.childCount === 0) {
                            tr.insert(pos + 1, newState.schema.nodes.paragraph.create());
                        }
                    });

                    return tr.steps.length ? tr : null;
                },
            }),
        ];
    },

    addKeyboardShortcuts() {
        return {
            Enter: ({ editor }) => {
                const selection = editor.state.selection;
                const parent = selection.$head.node(selection.$head.depth - 1);

                if (parent?.type !== this.type) return false;

                if (
                    !selection.empty ||
                    selection.$from.parent.childCount !== 0 ||
                    selection.$from.parent.type.spec.code ||
                    selection.$to.parent.type.spec.code
                ) {
                    return false;
                }

                const node = editor.$pos(selection.$head.pos);

                editor.chain().insertContentAt(node.to, { type: 'paragraph' }).run();

                return true;
            },
        };
    },
});
