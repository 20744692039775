import React, { useState, useMemo, useEffect } from 'react';
import { Link } from 'shared/router';

import { ReportsComponent } from 'components/data-components/reports-component';
import { sortTypesOptions } from 'app/statistic/vendors/filtersOptions';
import { ReportService } from 'app/statistic/services/ReportService';
import { useMessage } from 'lib/hooks';
import cx from './statistic-reports-page.module.scss';
import api from 'api';

const StatisticReportsDetailedSearchRequests = ({ setIsDownloadModal }) => {
    const { addError } = useMessage();

    const [isLoading, setIsLoading] = useState(false);

    const [reports, setReports] = useState([]);
    const [reportsTotal, setReportsTotal] = useState(0);
    const [sortByOptions, setSortByOptions] = useState([]);

    const getDetailedSearchRequestReports = async (page = 0, size = 50, filters = {}) => {
        setIsLoading(true);

        try {
            const res = await api.reports.getSearchDetails({ page, size, filters, cancelToken: ReportService.cancelToken.token });

            setReports(
                res.searches.content.map((i) => {
                    return {
                        ...i,
                        proposedArticles: i.avgNumberOfProposedArticles,
                        proposedNews: i.avgNumberOfProposedNews,
                        proposedFiles: i.avgNumberOfProposedFiles,
                        searchDate: i.searchDate,
                    };
                })
            );
            setReportsTotal(res.searches.totalElements);

            if (!res.searches.totalElements) {
                addError('По вашему запросу нет данных');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const onDownload = async (filters) => {
        try {
            await api.reports.downloadReports('search/details', filters, ReportService.cancelToken.token);
            setIsDownloadModal(true);
        } catch (error) {
            addError('Произошла ошибка при скачивании отчета');
        }
    };

    const columnsTmp = useMemo(() => {
        const data = [
            {
                Header: 'Запрос',
                accessor: 'query',
            },
            {
                Header: 'Участник',
                accessor: 'user',
                Cell: (data) => {
                    return data.row.original.login ? (
                        <Link className={cx.link} to={'/edit-user/' + data.row.original.login}>
                            {data.row.original.user}
                        </Link>
                    ) : (
                        data.row.original.user
                    );
                },
            },
            {
                Header: 'Дата запроса',
                accessor: 'searchDate',
                Cell: (data) => ReportService.defaultDateColumn('searchDate')(data),
            },
            {
                Header: 'Статей в выдаче',
                accessor: 'proposedArticles',
            },
            {
                Header: 'Новостей в выдаче',
                accessor: 'proposedNews',
            },
            {
                Header: 'Файлов в выдаче',
                accessor: 'proposedFiles',
            },
        ];

        setSortByOptions(
            data
                .filter((col) => col.accessor && col.isVisible !== false)
                .map((column) => ({ label: column.Header, value: column.accessor }))
        );

        return data;
    }, []);

    const filtersTmp = useMemo(() => {
        return [
            {
                fields: [
                    {
                        labels: ['Диапазон времени', 'От', 'До'],
                        name: 'createTime',
                        type: 'date-range',
                        default: {
                            type: {
                                label: 'За сегодня',
                                value: 1,
                            },
                            from: new Date(new Date().setHours(0, 0, 0)).toISOString(),
                            to: new Date(new Date().setHours(23, 59, 59)).toISOString()
                        },
                    },
                ],
                width: '100%',
            },
            {
                label: 'Запросы',
                fields: [
                    {
                        name: 'queries',
                        type: 'input-multi-value',
                        default: [],
                    },
                ],
                width: '100%',
            },
            {
                label: 'Участник',
                fields: [
                    {
                        name: 'users',
                        type: 'user-select',
                        default: [],
                    },
                ],
                width: '100%',
            },
            {
                label: 'Сортировать по',
                fields: [
                    {
                        name: 'sortBy',
                        type: 'select',
                        options: sortByOptions,
                        default: {label: 'Дата запроса', value: 'searchDate'},
                    },
                ],
                width: '25%',
            },
            {
                label: 'Порядок',
                fields: [
                    {
                        name: 'sort',
                        type: 'select',
                        options: sortTypesOptions,
                        default: sortTypesOptions[0],
                    },
                ],
                width: '25%',
            },
        ];
    }, [sortByOptions]);

    useEffect(() => {
        ReportService.init('DETAILED_SEARCH_REQUESTS');

        return () => {
            ReportService.destroy();
        };
    }, []);

    return (
        <ReportsComponent
            isEmpty={!reportsTotal}
            isLoading={isLoading}
            columns={columnsTmp}
            data={reports}
            total={reportsTotal}
            filters={filtersTmp}
            onDownload={onDownload}
            onPaginate={getDetailedSearchRequestReports}
        />
    );
};

export default StatisticReportsDetailedSearchRequests;
