import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useMessage } from 'lib/hooks';
import FavoritesPageTabProjects from './favorites-page-tab-projects';
import FavoritesPageTabNews from './favorites-page-tab-news';
import FavoritesPageTabArticles from './favorites-page-tab-articles';
import api from 'api';
import cx from './favorites-page.module.scss';
import { RouteGroup, Route, useNavigate, useLocation, useParams, setDocumentTitle } from 'shared/router';

const FavoritesPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const params = useParams();

    const { addError } = useMessage();
    const propTab = params['*'];

    const [tab, setActiveTab] = useState('projects');
    const [favoritesCounts, setFavoritesCounts] = useState({ projectCount: 0, articleCount: 0, newsCount: 0 });

    const onTabChange = (tab) => {
        navigate('/user/favorites' + (tab ? '/' + tab : ''));
        setActiveTab(tab);
    };
    const getTabCounts = useCallback(async () => {
        try {
            const counts = await api.favorites.getFavoritesCounts();
            setFavoritesCounts(counts);
        } catch (e) {
            console.log(e);
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
        }
    }, [addError]);

    useEffect(() => { getTabCounts() }, [getTabCounts]);
    useEffect(() => { setActiveTab(propTab) }, [location.pathname, propTab]);

    useEffect(() => {
        setDocumentTitle('Избранное — KMS Gran');
    }, []);

    return (
        <div className={cx.content}>
            <div className={cx.title}>Избранное</div>
            <ul className={cx.tabs}>
                <li className={classNames(cx.tab, { [cx.active]: tab === 'projects' })}
                    onClick={() => onTabChange('projects')}>
                    Проекты
                    <div className={cx.count}>{favoritesCounts.projectCount}</div>
                </li>
                <li className={classNames(cx.tab, { [cx.active]: tab === 'articles' })}
                    onClick={() => onTabChange('articles')}>
                    Статьи
                    <div className={cx.count}>{favoritesCounts.articleCount}</div>
                </li>
                <li className={classNames(cx.tab, { [cx.active]: tab === 'news' })}
                    onClick={() => onTabChange('news')}>
                    Новости
                    <div className={cx.count}>{favoritesCounts.newsCount}</div>
                </li>
            </ul>
            <div className={cx.tableWrapper}>
                <RouteGroup className={cx.router}>
                    <Route to="projects" component={<FavoritesPageTabProjects onCountChangeHandler={getTabCounts}/>} />
                    <Route
                        to="articles"
                        component={<FavoritesPageTabArticles
                            totalCount={favoritesCounts.articleCount}
                            onCountChangeHandler={getTabCounts}
                        />}
                    />
                    <Route
                        to="news"
                        component={<FavoritesPageTabNews
                            totalCount={favoritesCounts.newsCount}
                            onCountChangeHandler={getTabCounts}
                        />}
                    />
                </RouteGroup>
            </div>
        </div>
    );
};

export default FavoritesPage;
