import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NotificationCounter } from '../domain/counter';
import { updateNewsTicker } from 'slice/newsPickerSlice';
import { useMessage } from '../../lib/hooks';
import { NotificationSubscribe } from 'notifications/infrastructure/notification-wss';
import api from 'api';

interface NotificationsContextProps {
    notificationsCount: number;
    getUnreadNotifications: (_page: number) => Promise<any>;
    getAllNotifications: (_page: number) => Promise<any>;
    readNotification: (_id: string) => void;
    readAllNotifications: () => void;
    lastNotification: any;
}

const NotificationsContext = createContext<NotificationsContextProps>({
    notificationsCount: 0,
    getUnreadNotifications: () => Promise.resolve([]),
    getAllNotifications: () => Promise.resolve([]),
    readNotification: () => {},
    readAllNotifications: () => {},
    lastNotification: null
});

interface NotificationsProviderProps {
    children: ReactNode;
}

export const NotificationsProvider = ({ children }: NotificationsProviderProps) => {
    const { addError } = useMessage();
    const dispatch = useDispatch();

    const [counter, setCounter] = useState<NotificationCounter>();

    const [count, setCount] = useState<number>(0);
    const [lastNotification, setLastNotification] = useState(null);

    const getUnreadNotifications = useCallback((page: number) => {
        return api.notification.getUnread(page, 20, true).catch(() => {
            // TODO: Log to sentry;
        });
    }, []);

    const getAllNotifications = useCallback((page: number) => {
        return api.notification.getAll(page, 20).catch(() => {
            // TODO: Log to sentry;
        });
    }, []);

    const readNotification = useCallback((id: string) => {
        api.notification.read(id).then(() => {
            counter?.readNotification();
            setCount(counter?.getCount() || 0);
        }).catch(() => {
            addError('Не удалось прочитать уведомление. Пожалуйста попробуйте позже');
            // TODO: Log to sentry;
        });
    }, [addError, counter]);

    const readAllNotifications = useCallback(() => {
        api.notification.readAll().then(() => {
            counter?.readAllNotifications();
            setCount(counter?.getCount() || 0);
        }).catch(() => {
            addError('Не удалось прочитать уведомления. Пожалуйста попробуйте позже');
            // TODO: Log to sentry;
        });
    }, [addError, counter]);

    useEffect(() => {
        api.notification.getUnreadCount().then(count => {
            const counter = new NotificationCounter(count);

            setCounter(counter);
            setCount(counter?.getCount() || 0);
        }).catch(() => {
            // TODO: Log to sentry;
        });
    }, []);

    useEffect(() => {
        if (!counter) {
            return;
        }

        NotificationSubscribe((message: any) => {
            let newMessage = JSON.parse(message['body']);
            newMessage['author'] = newMessage['user'];

            counter?.addNotification();

            setCount(counter?.getCount() || 0);
            setLastNotification(newMessage);

            if (newMessage.data['news.ticker']) {
                dispatch(updateNewsTicker(true));
            }
        });
    }, [dispatch, counter]);

    return (
        <NotificationsContext.Provider value={{
            notificationsCount: count,
            getUnreadNotifications,
            getAllNotifications,
            readNotification,
            readAllNotifications,
            lastNotification
        }}>
            {children}
        </NotificationsContext.Provider>
    );
};

export const useNotifications = (): NotificationsContextProps => {
    const context = useContext(NotificationsContext);

    if (!context) {
        throw new Error('useNotification must be used within a NotificationProvider');
    }

    return context;
};
