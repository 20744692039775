import React, { useEffect } from 'react';
import Tippy from '@tippyjs/react';
import { Link, useNavigate } from 'shared/router';
import classNames from 'classnames';
import formatDateTime from 'lib/helpers/formatDateTime';
import BackButton from 'components/back-button';
import Crumbs from 'components/crumbs';
import cs from './posting-header.module.scss';
import Icon, { Icons } from 'uikit/icon';
import DocumentLabel from 'components/document-label/document-label';
import Mark from 'mark.js';
import { MultiClumpTooltip } from 'components/MultiClumpTooltip/MultiClumpTooltip';

export const DOCUMENT_TYPE = {
    ARTICLE: 'ARTICLE',
    NEWS: 'NEWS',
    SCRIPT: 'SCRIPT'
}

const PostingHeader = ({
    title,
    authorId,
    authorName,
    authorImage,
    authorStatus,
    crumbs,
    date,
    className = '',
    documentType,
    isHighPriority,
    isArchive,
    lastAction,
    viewersCount
}) => {
    const navigate = useNavigate();

    const goBack = () => {
        if (window.history.length > 0) {
            navigate(-1);
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(document.location.search);
        let highlights = JSON.parse(params.get('highlights'));
        highlights = highlights ? highlights.split(',') : [];

        if (highlights?.length > 0) {
            const mark = new Mark(document.querySelector('#postingHeaderTitle'));
            mark.mark(highlights, { className: 'nodemark', separateWordSearch: false });
        }
    }, [title, navigate]);

    return (
        <div id="postingHeader" data-testid="postingHeader" className={classNames(cs.header, className)}>
            <div className={cs.mobileContent}>
                <div className={cs.top}>
                    <BackButton className={cs.goBackButton} onClick={goBack} id="postingHeaderMobile" />
                    <div className={cs.titleContent}>
                        <Crumbs id="postingHeaderCrumbsMobile" data={crumbs} />
                        <div id="postingHeaderTitleMobile" data-testid="postingHeaderTitleMobile" className={cs.title}>{title}</div>
                    </div>
                </div>
                <div className={cs.info}>
                    <div className={cs.documentType} data-testid="postingHeaderDocumentTypeMobile">
                        {documentType === DOCUMENT_TYPE.ARTICLE && (
                            <>
                                <Icon width={15} height={15} type={Icons.NEWS} />
                                <span>Статья</span>
                            </>
                        )}
                        {documentType === DOCUMENT_TYPE.NEWS && (
                            <>
                                <Icon width={15} height={15} type={Icons.NEWS2} />
                                <span>Новость</span>
                            </>
                        )}
                        {documentType === DOCUMENT_TYPE.SCRIPT && (
                            <>
                                <Icon width={15} height={15} type={Icons.HELP_LIFELINE} />
                                <span>Скрипт</span>
                            </>
                        )}
                    </div>
                    {authorStatus === 'DELETED' && (
                        <span style={{ color: '#262626' }} className={cs.user}>{authorName}</span>
                    )}
                    {authorStatus !== 'DELETED' && (
                        <Link to={`/edit-user/${authorId}`} id='postingHeaderAuthorMobile' data-testid="postingHeaderAuthorMobile" className={cs.user}>
                            <img src={authorImage || '/img/avatar.png'} alt='avatar' width={20} height={20} />
                            <span>{authorName}</span>
                        </Link>
                    )}
                    {isHighPriority && (
                        <DocumentLabel className={cs.priorityLabel} type='yellow' size='small' text='Срочная новость' />
                    )}
                    <div className={cs.date} data-testid="postingHeaderDateMobile">
                        <Icon width={15} height={15} type={Icons.TIME} />
                        <span>{formatDateTime(date)}</span>
                    </div>
                </div>
                <div className={cs.bottom}>
                    {isArchive && (
                        <DocumentLabel className={cs.priorityLabel} type='default' size="small" text="Архив" />
                    )}
                    {lastAction && (
                        <div className={cs.lastAction}>
                            <div className={cs.userLink} data-testid="postingHeaderLastUserMobile">
                                <Icon type={Icons.EDIT_PEN} width={15} height={15} />
                                {(!lastAction.userInfo.status || lastAction.userInfo.status === 'DELETED') && (
                                    <span style={{ color: '#262626', margin: 0 }} className={cs.user}>
                                         {`${lastAction.userInfo.firstName} ${lastAction.userInfo.lastName}`}
                                    </span>
                                )}
                                {lastAction.userInfo.status && lastAction.userInfo.status !== 'DELETED' && (
                                    <Link to={`/edit-user/${lastAction.userInfo.login}`} className={cs.user}>
                                        {`${lastAction.userInfo.firstName} ${lastAction.userInfo.lastName}`}
                                    </Link>
                                )}
                            </div>
                            <div className={cs.creationDate} data-testid="postingHeaderLastDateMobile">, {formatDateTime(lastAction.timestamp)}</div>
                        </div>
                    )}
                </div>
            </div>
            <div className={cs.desktopContent}>
                <div className={cs.titleBar}>
                    <BackButton className={cs.goBackButton} onClick={goBack} id="postingHeader" />
                    <div className={cs.titleContent}>
                        <Crumbs id="postingHeaderCrumbs" data={crumbs} />
                        <div className={cs.title} data-testid="postingHeaderTitle">
                            <MultiClumpTooltip id="postingHeaderTitle" clamp={4} label={title} />
                        </div>
                    </div>
                </div>
                <div className={cs.infoBar}>
                    <div className={cs.documentType} data-testid="postingHeaderDocumentType">
                        {documentType === DOCUMENT_TYPE.ARTICLE && (
                            <>
                                <Icon width={15} height={15} type={Icons.NEWS} />
                                <span>Статья</span>
                            </>
                        )}
                        {documentType === DOCUMENT_TYPE.NEWS && (
                            <>
                                <Icon width={15} height={15} type={Icons.NEWS2} />
                                <span>Новость</span>
                            </>
                        )}
                        {documentType === DOCUMENT_TYPE.SCRIPT && (
                            <>
                                <Icon width={15} height={15} type={Icons.HELP_LIFELINE} />
                                <span>Скрипт</span>
                            </>
                        )}
                    </div>
                    {authorStatus === 'DELETED' && (
                        <span style={{ color: '#262626' }} className={cs.user}>{authorName}</span>
                    )}
                    {authorStatus !== 'DELETED' && (
                        <Link to={`/edit-user/${authorId}`} id='postingHeaderAuthor' data-testid="postingHeaderAuthor" className={cs.user}>
                            <img src={authorImage || '/img/avatar.png'} alt='avatar' width={20} height={20} />
                            <span>{authorName}</span>
                        </Link>
                    )}
                    {isHighPriority && (
                        <DocumentLabel className={cs.priorityLabel} type='yellow' size='small' text='Срочная новость' />
                    )}
                    <div className={cs.date} data-testid="postingHeaderDate">
                        <Icon width={15} height={15} type={Icons.TIME} />
                        <span>{formatDateTime(date)}</span>
                    </div>
                    {isArchive && (
                        <DocumentLabel className={cs.priorityLabel} type="default" size="small" text="Архив" />
                    )}
                    {lastAction && (
                        <div className={cs.lastAction}>
                            <div className={cs.userLink} data-testid="postingHeaderLastUser">
                                <Icon type={Icons.EDIT_PEN} width={15} height={15} />
                                {(!lastAction.userInfo.status || lastAction.userInfo.status === 'DELETED') && (
                                    <span style={{ color: '#262626', margin: 0 }} className={cs.user}>
                                         {`${lastAction.userInfo.firstName} ${lastAction.userInfo.lastName}`}
                                    </span>
                                )}
                                {lastAction.userInfo.status && lastAction.userInfo.status !== 'DELETED' && (
                                    <Link to={`/edit-user/${lastAction.userInfo.login}`} className={cs.user}>
                                        {`${lastAction.userInfo.firstName} ${lastAction.userInfo.lastName}`}
                                    </Link>
                                )}
                            </div>
                            <div className={cs.creationDate} data-testid="postingHeaderLastDate">, {formatDateTime(lastAction.timestamp)}</div>
                        </div>
                    )}
                    {!!viewersCount && viewersCount !== 0 && (
                        <Tippy content="Cейчас просматривают документ" placement="right">
                            <div className={cs.viewers} data-testid="postingHeaderViewers">
                                <Icon type={Icons.USER} width={15} height={15} />
                                <span>{viewersCount}</span>
                            </div>
                        </Tippy>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PostingHeader;
