import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { GlobalContext } from 'lib/context';
import FormDataSaveWrapper from 'containers/form-data-save-wrapper';
import { UploadProvider } from 'components/background-upload/upload-provider';
import ApiAxios from 'api/api-axios';
import store from '../store';


new ApiAxios({ prefix: '' }).setupInterceptors();

const App = ({ children }) => {
    const [userRolesInfo, setUserRolesInfo] = React.useState();
    const [sessionHasBeenFetched, setSessionHasBeenFetched] = React.useState(false);

    const [platform, setPlatform] = React.useState('desktop');

    const [visitedUrls, setVisitedUrls] = React.useState([]);

    const [formDataChanged, setFormDataChanged] = React.useState(false);
    const [formDataChangedSubmitHandler, setFormDataChangedSubmitHandler] = React.useState(null);

    const [locationUrl, setLocationUrl] = React.useState('');
    const [prevLocationUrl, setPrevLocationUrl] = React.useState('');

    const [gotScriptProgress, setGotScriptProgress] = React.useState(false);
    const [avatar, setAvatar] = React.useState('');

    const globalStateProvided = React.useMemo(() => ({
        userRolesInfo,
        setUserRolesInfo,
        sessionHasBeenFetched,
        setSessionHasBeenFetched,
        avatar,
        setAvatar,
        platform,
        visitedUrls,
        setVisitedUrls,
        formDataChanged,
        setFormDataChanged,
        locationUrl,
        setLocationUrl,
        prevLocationUrl,
        setPrevLocationUrl,
        gotScriptProgress,
        setGotScriptProgress,
        formDataChangedSubmitHandler,
        setFormDataChangedSubmitHandler,
    }), [
        userRolesInfo,
        sessionHasBeenFetched,
        avatar,
        platform,
        visitedUrls,
        formDataChanged,
        locationUrl,
        prevLocationUrl,
        gotScriptProgress,
        formDataChangedSubmitHandler
    ]);

    React.useEffect(() => {
        const width = window.innerWidth;
        if (width && width >= 768) {
            setPlatform('desktop');
        } else {
            setPlatform('mobile');
        }
    }, [])

    return (
        <Provider store={store}>
            <GlobalContext.Provider value={globalStateProvided}>
                <UploadProvider>
                    <FormDataSaveWrapper>
                        {children}
                        <ToastContainer position="bottom-right" />
                    </FormDataSaveWrapper>
                </UploadProvider>
            </GlobalContext.Provider>
        </Provider>
    );
};

export default App;
