import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'shared/router';
import { useDialog, useMessage } from 'lib/hooks';
import { TRAINING_PASSING_TYPES, TRAINING_RATING_SCALES } from 'lib/config/constant';
import AvatarEditor from 'components/avatar-editor/avatar-editor';
import { TrainingQuestionAddButton } from 'components/training';
import { Confirmation } from 'components/confirmation/confirmation';
import BackButton from 'components/back-button';
import TrainingTestBase from 'components/training/training-test/training-test-base/training-test-base';
import TrainingTestUsers from 'components/training/training-test/training-test-users/training-test-users';
import TrainingTestReports from 'components/training/training-test/training-test-reports/training-test-reports';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import Input from 'uikit/input/input';
import { Select } from 'uikit/select';
import Checkbox from 'uikit/checkbox/checkbox';
import Button from 'uikit/button';
import api from 'api';
import cx from './training-test.module.scss';

export default function TrainingTest({ id, isEdit }) {
    const location = useLocation();
    const navigate = useNavigate();

    const { addError } = useMessage();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [test, setTest] = useState(null);

    const [isAvatarModal, setIsAvatarModal] = useState(false);
    const [uploadAvatar, setUploadAvatar] = useState(null);
    const [avatar, setAvatar] = useState(null);

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const [isSettings, setIsSettings] = useState(false);

    const [type, setType] = useState(TRAINING_PASSING_TYPES[0]);
    const [isSkip, setIsSkip] = useState(false);

    const [isTestTime, setIsTestTime] = useState(false);
    const [testTime, setTestTime] = useState(0);

    const [isQuestionTime, setIsQuestionTime] = useState(false);
    const [questionTime, setQuestionTime] = useState(0);

    const [isShowCorrect, setIsShowCorrect] = useState(false);
    const [isShowCorrectAfter, setIsShowCorrectAfter] = useState(false);

    const [isGrading, setIsGrading] = useState(false);
    const [gradingType, setGradingType] = useState(TRAINING_RATING_SCALES[0]);

    const [isPassage, setIsPassage] = useState(false);
    const [gradingRange, setGradingRange] = useState([{ title: '', from: 0, to: 0, isPassed: false }]);

    const [endTestMessage, setEndTestMessage] = useState('');
    const [endTestSuccessMessage, setEndTestSuccessMessage] = useState('');
    const [endTestFailureMessage, setEndTestFailureMessage] = useState('');

    const [isMixQuestions, setIsMixQuestions] = useState(false);
    const [isMixAnswers, setIsMixAnswers] = useState(false);

    const [questions, setQuestions] = useState([]);

    const OnSelectAvatar = (e) => {
        if (e.target.files.length > 0 && e.target.files[0].type.match(/^image\//)) {
            const oFReader = new FileReader();
            oFReader.readAsDataURL(e.target.files[0]);

            oFReader.onload = function() {
                setUploadAvatar(this.result);
                setIsAvatarModal(true);
            };
        } else {
            // addError('для загрузки допустимы следующие форматы - JPG, PNG, WEBM.');
        }
    };
    const OnSaveAvatar = (avatar) => {
        setAvatar(avatar);
        setIsAvatarModal(false);
    };
    const OnAvatarRemove = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setUploadAvatar(null);
        setAvatar(null);
    };

    const OnChangeGradingRange = (index, key, value) => {
        const temp = Object.assign([], gradingRange);
        temp[index][key] = value;
        setGradingRange(temp);
    };
    const OnRemoveGradingRange = (index) => {
        const temp = Object.assign([], gradingRange);
        temp.splice(index, 1);
        setGradingRange(temp);
    };
    const OnAddGradingRange = () => {
        const temp = Object.assign([], gradingRange);
        temp.push({ title: '', from: 0, to: 0, isPassed: false });
        setGradingRange(temp);
    };

    const onCreateTest = async () => {
        try {
            await api.training.postTest(avatar, title, description, type['value'], isSkip, testTime, questionTime,
                isShowCorrect, isShowCorrectAfter, gradingType['value'], isPassage, JSON.stringify(gradingRange),
                endTestMessage, endTestSuccessMessage, endTestFailureMessage, isMixQuestions, isMixAnswers,
                questions.map(p => p['id']));

            navigate('/training/all');
        } catch (e) {
            console.log(e);
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
        }
    };
    const onUpdateTest = async () => {
        try {
            await api.training.putTest(id, avatar, title, description, type['value'], isSkip, testTime, questionTime,
                isShowCorrect, isShowCorrectAfter, gradingType['value'], isPassage, JSON.stringify(gradingRange),
                endTestMessage, endTestSuccessMessage, endTestFailureMessage, isMixQuestions, isMixAnswers,
                questions.map(p => p['id']));

            navigate('/training/all');
        } catch (e) {
            console.log(e);
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
        }
    };
    const onDeleteTest = async () => {
        openDialog({
            title: 'Удаление теста',
            text: (<span>Вы действительно хотите удалить тест?</span>),
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Подтвердить',
            onClose: closeDialog,
            onSubmit: async () => {
                try {
                    closeDialog();
                    await api.training.deleteTest(id);
                    navigate('/training/all');
                } catch (e) {
                    console.log(e);
                    addError('Сервис недоступен. Пожалуйста попробуйте позже.');
                }
            }
        });
    };

    useEffect(() => {
        if (!id) {
            return;
        }

        const fetchData = async () => {
            const response = await api.training.getTest(id);
            setTest(response);

            setAvatar(response['image']);
            setTitle(response['title']);
            setDescription(response['description']);
            setType(TRAINING_PASSING_TYPES.find(p => p.value === response['type']));
            setIsSkip(response['isSkip']);
            setIsTestTime(response['testTime'] > 0);
            setTestTime(response['testTime']);
            setIsQuestionTime(response['questionTime'] > 0);
            setQuestionTime(response['questionTime']);
            setIsShowCorrect(response['isShowCorrect']);
            setIsShowCorrectAfter(response['isShowCorrectAfter']);
            setGradingType(TRAINING_RATING_SCALES.find(p => p.value === response['gradingType']));
            setIsPassage(JSON.parse(response['gradingRange']).length > 0);
            setGradingRange(JSON.parse(response['gradingRange']));
            setEndTestMessage(response['endTestMessage']);
            setEndTestSuccessMessage(response['endTestSuccessMessage']);
            setEndTestFailureMessage(response['endTestFailureMessage']);
            setIsMixQuestions(response['isMixQuestions']);
            setIsMixAnswers(response['isMixAnswers']);
            setQuestions(response['questions']);
        };
        fetchData();
    }, [id]);

    return (
        <div className={cx.trainingTest}>
            <Confirmation {...dialogState}/>
            {(!id || (id && isEdit)) &&
            <div className={cx.trainingTestCreate}>
                <h1>{(id && isEdit) ? 'Редактирование теста' : 'Создание теста'}</h1>
                {isAvatarModal &&
                <AvatarEditor open={isAvatarModal} onSubmit={(avatar) => OnSaveAvatar(avatar)} type="rectangle"
                              img={uploadAvatar} title="Загрузка обложки" onDismiss={() => setIsAvatarModal(false)}/>}
                <input id="test-avatar" type="file" accept="image/png, image/jpeg" onChange={OnSelectAvatar}/>
                <div className={cx.trainingTestAvatar} onClick={() => document.getElementById('test-avatar').click()}>
                    {!avatar &&
                    <>
                        <Icon type={Icons.IMAGE} width={32} height={32}/>
                        <h3>Обложка теста</h3>
                        <p>Перетащите изображение в данную область или нажмите для выбора файла</p>
                    </>}
                    {avatar && <img src={avatar} alt="Обложка теста"/>}
                    {avatar &&
                    <div className={cx.trainingTestAvatarOverlay}>
                        <button onClick={OnAvatarRemove}>Удалить обложку</button>
                        <button>Изменить обложку</button>
                    </div>}
                </div>
                <div className={cx.trainingTestForm}>
                    <div className={cx.trainingTestFormFields}>
                        <Input id="themeFormTittle" name="title" type="text" className={cx.trainingTestFormField}
                               label="Заголовок" value={title} required onChange={(str) => setTitle(str)}/>
                        <Input id="themeFormDescription" name="description" type="text"
                               className={cx.trainingTestFormField} label="Описание теста" value={description}
                               required onChange={(str) => setDescription(str)}/>
                        <div className={cx.trainingTestFormDelimiter}/>
                        <div className={cx.trainingTestFormSettings}>
                            <p className={cx.trainingTestFormSettingsLabel} onClick={() => setIsSettings(!isSettings)}>
                                <Icon type={isSettings ? Icons.CHEVRON_UP : Icons.ARROW_DOWN} width={12} height={12}/>
                                <span className={isSettings ? cx.trainingTestFormSettingsLabelActive : null}>
                                Настройки прохождения теста
                            </span>
                            </p>
                            {isSettings &&
                            <div className={cx.trainingTestFormSettingsContent}>
                                <div className={cx.trainingTestFormSettingsContentItem}>
                                    <Select label="Прохождение теста" options={TRAINING_PASSING_TYPES} value={type}
                                            onChange={(value) => setType(value)}/>
                                </div>
                                <div className={cx.trainingTestFormSettingsContentItem}>
                                    <Checkbox label="Разрешить пропускать задания" checked={isSkip}
                                              onClick={() => setIsSkip(!isSkip)}/>
                                    <span>Участник сможет завершить тест не пройдя часть заданий</span>
                                </div>
                                <div className={cx.trainingTestFormSettingsContentItem}>
                                    <Checkbox label="Ограничить время прохождения теста" checked={isTestTime}
                                              onClick={() => { setIsTestTime(!isTestTime); setTestTime(0); }}/>
                                    {isTestTime &&
                                    <div className={cx.trainingTestFormSettingsContentItemInput}>
                                        <Input value={testTime} onChange={(str) => setTestTime(str)}/>
                                        <span>минут (-а)</span>
                                    </div>}
                                </div>
                                <div className={cx.trainingTestFormSettingsContentItem}>
                                    <Checkbox label="Ограничить время прохождения вопроса" checked={isQuestionTime}
                                              onClick={() => { setIsQuestionTime(!isQuestionTime); setQuestionTime(0); }}/>
                                    {isQuestionTime &&
                                    <div className={cx.trainingTestFormSettingsContentItemInput}>
                                        <Input value={questionTime} onChange={(str) => setQuestionTime(str)}/>
                                        <span>минут (-а)</span>
                                    </div>}
                                </div>
                                <div className={cx.trainingTestFormSettingsContentItem}>
                                    <Checkbox label="Показывать правилен ли ответ участника" checked={isShowCorrect}
                                              onClick={() => setIsShowCorrect(!isShowCorrect)}/>
                                </div>
                                <div className={cx.trainingTestFormSettingsContentItem}>
                                    <Checkbox label="После ответа показывать правильный ответ на вопрос" checked={isShowCorrectAfter}
                                              onClick={() => setIsShowCorrectAfter(!isShowCorrectAfter)}/>
                                </div>
                            </div>}
                        </div>
                        <div className={cx.trainingTestFormDelimiter}/>
                        <div className={cx.trainingTestFormGrading}>
                            <p className={cx.trainingTestFormGradingLabel} onClick={() => setIsGrading(!isGrading)}>
                                <Icon type={isGrading ? Icons.CHEVRON_UP : Icons.ARROW_DOWN} width={12} height={12}/>
                                <span className={isGrading ? cx.trainingTestFormGradingLabelActive : null}>
                                Оценивание
                            </span>
                            </p>
                            {isGrading &&
                            <div className={cx.trainingTestFormGradingContent}>
                                <div className={cx.trainingTestFormGradingContentItem}>
                                    <Select label="Шкала оценивания" options={TRAINING_RATING_SCALES} value={gradingType}
                                            onChange={(value) => setGradingType(value)}/>
                                </div>
                                {gradingType.value === 1 &&
                                <div className={cx.trainingTestFormGradingContentItem}>
                                    <Checkbox label="Установить порог прохождения" checked={isPassage}
                                              onClick={() => setIsPassage(!isPassage)}/>
                                </div>}
                                {gradingType.value === 1 && isPassage &&
                                <div className={cx.trainingTestFormGradingContentItem}>
                                    <Input label="Максимальное количество баллов" disabled
                                           value={questions.reduce((a, b) => a + Number(b.mark || 0), 0)}/>
                                </div>}
                                {gradingType.value === 1 && isPassage &&
                                <div className={cx.trainingTestFormGradingContentRange}>
                                    {gradingRange.map((item, index) => (
                                        <div key={index} className={cx.trainingTestFormGradingContentRangeItem}>
                                            <IconButton icon={<Icon type={Icons.TRASH} width={15}/>}
                                                        onClick={() => OnRemoveGradingRange(index)}/>
                                            <Input label="Название диапазона" value={item['title']}
                                                   onChange={(str) => OnChangeGradingRange(index, 'title', str)}/>
                                            <Input label="От" type="number" value={item['from']}
                                                   onChange={(str) => OnChangeGradingRange(index, 'from', str)}/>
                                            <Input label="До" type="number" value={item['to']}
                                                   onChange={(str) => OnChangeGradingRange(index, 'to', str)}/>
                                            <Checkbox label="Пройдено" checked={item['isPassed']}
                                                      onClick={() => OnChangeGradingRange(index, 'isPassed', !item['isPassed'])}/>
                                        </div>
                                    ))}
                                </div>}
                                {gradingType.value === 1 && isPassage &&
                                <div className={cx.trainingTestFormGradingContentRangeAdd} onClick={() => OnAddGradingRange()}>
                                    <div className={cx.trainingTestFormGradingContentRangeAddIcon}>
                                        <Icon type={Icons.ADD} width={10} height={10}/>
                                    </div>
                                    <span>Добавить диапазон</span>
                                </div>}
                                <div className={cx.trainingTestFormDelimiter}/>
                                {(gradingType.value === 0 || (gradingType.value === 1 && !isPassage)) &&
                                <div className={cx.trainingTestFormGradingContentItem}>
                                    <Input label="Сообщение после прохождения теста" value={endTestMessage}
                                           onChange={(str) => setEndTestMessage(str)}/>
                                </div>}
                                {(gradingType.value === 1 && isPassage) &&
                                <div className={cx.trainingTestFormGradingContentItem}>
                                    <Input label="Сообщение при успешном прохождении" value={endTestSuccessMessage}
                                           onChange={(str) => setEndTestSuccessMessage(str)}/>
                                </div>}
                                {(gradingType.value === 1 && isPassage) &&
                                <div className={cx.trainingTestFormGradingContentItem}>
                                    <Input label="Сообщение при непрохождении" value={endTestFailureMessage}
                                           onChange={(str) => setEndTestFailureMessage(str)}/>
                                </div>}
                            </div>}
                        </div>
                        <div className={cx.trainingTestFormDelimiter}/>
                        <div className={cx.trainingTestFormContent}>
                            <h2>Содержание</h2>
                            <div className={cx.trainingTestFormContentInfo}>
                                <Icon type={Icons.INFO} width={15} height={15}/>
                                <span>Вы сможете добавить вопросы в тест после его создания</span>
                            </div>
                            <div className={cx.trainingTestFormContentItem}>
                                <Checkbox label="Перемешать вопросы" checked={isMixQuestions}
                                          onClick={() => setIsMixQuestions(!isMixQuestions)}/>
                                <span>Каждый участник получит индивидуальный порядок вопросов.</span>
                            </div>
                            <div className={cx.trainingTestFormContentItem}>
                                <Checkbox label="Перемешать ответы в вопросах с вариантами выбора" checked={isMixAnswers}
                                          onClick={() => setIsMixAnswers(!isMixAnswers)}/>
                                <span>Каждый участник получит индивидуальный порядок ответов на вопрос.</span>
                            </div>
                        </div>
                        <div className={cx.trainingTestFormDelimiter}/>
                        <TrainingQuestionAddButton questions={questions} onQuestions={(questions) => setQuestions(questions)}/>
                    </div>
                    <div className={cx.trainingTestFormButtons}>
                        <Button label="Отмена" onClick={() => navigate('/training/all')}/>
                        <Button label="Сохранить" color="green" onClick={() => id ? onUpdateTest() : onCreateTest()}/>
                    </div>
                </div>
            </div>}
            {(id && test && !isEdit) &&
            <div className={cx.trainingTestView}>
                <div className={cx.trainingTestViewHeader}>
                    <div className={cx.trainingTestViewHeaderLeft}>
                        <BackButton onClick={() => navigate('/training/all')}/>
                        <h1>{title}</h1>
                    </div>
                    <div className={cx.trainingTestViewHeaderRight}>
                        <IconButton icon={<Icon type={Icons.EDIT_PEN} width={15} height={15}/>} onClick={() => navigate('/training/test/1/edit')}/>
                        <IconButton icon={<Icon type={Icons.TRASH} width={14} height={15}/>} onClick={() => onDeleteTest()}/>
                    </div>
                </div>
                <ul className={cx.trainingTestViewTabs}>
                    <li className={location.pathname.indexOf('/users') === -1 && location.pathname.indexOf('/reports') === -1 ? cx.trainingTestViewTabsActive : null}
                        onClick={() => navigate(`/training/test/${id}`)}>Содержание</li>
                    <li className={location.pathname.indexOf('/users') !== -1 ? cx.trainingTestViewTabsActive : null}
                        onClick={() => navigate(`/training/test/${id}/users`)}>Назначения</li>
                    <li className={location.pathname.indexOf('/reports') !== -1 ? cx.trainingTestViewTabsActive : null}
                        onClick={() => navigate(`/training/test/${id}/reports`)}>Отчеты</li>
                </ul>
                {location.pathname.indexOf('/users') === -1 && location.pathname.indexOf('/reports') === -1 &&
                <TrainingTestBase author={test['author']} date={test['createdDate']} avatar={avatar}
                                  description={description} gradingType={gradingType['label']}
                                  usersCount={test['usersCount']} questions={questions}/>}
                {location.pathname.indexOf('/users') !== -1 && <TrainingTestUsers id={id}/>}
                {location.pathname.indexOf('/reports') !== -1 && <TrainingTestReports id={id}/>}
            </div>}
        </div>
    );
};
