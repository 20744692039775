import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import { Link, useNavigate, useParams } from 'shared/router';
import { useAuthorized, useDialog, useGlobalContext, useMessage } from 'lib/hooks';
import {selectUser, setUsers as setUsersReducer} from 'slice/authSlice';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import PrivatePage from 'containers/private-page-wrapper';
import { TableComponent } from 'components/data-components';
import { EmptyCell } from 'components/data-components/table-component';
import { Confirmation } from 'components/confirmation/confirmation';
import { EditRowToolbar } from 'uikit/table';
import { Icons } from 'uikit/icon';
import api from 'api';
import cx from './user-page.module.scss';
import { MultiClumpTooltip } from 'components/MultiClumpTooltip/MultiClumpTooltip';
import { useWindowSize } from 'lib/hooks/useWindowSize';
import Loading from 'uikit/loading';
import { EmptyUsers } from 'components/projects/empty';

const UsersPage = ({ hasAnyAuthorities }) => {
    const tableRef = useRef(null);
    const { width } = useWindowSize();

    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { isAuthorizedAction } = useAuthorized();
    const { addSuccess, addError } = useMessage();

    const currentUser = useSelector(selectUser);

    const { dialogState, openDialog, closeDialog } = useDialog();
    const { platform } = useGlobalContext();

    const [isLoading, setIsLoading] = useState(false);
    const [, setIsInit] = useState(false);

    const [isReLoading, setIsReLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    const [total, setTotal] = useState(0);
    const [localUsers, setLocalUsers] = useState([]);

    const [checkedCheckbox, setCheckedCheckbox] = useState([]);
    const checkedCheckboxRef = useRef(checkedCheckbox);

    const onPaginate = useCallback(async (offset, count, sort, search, filters) => {
        try {
            setIsLoading(true);

            let usersData;

            if (!id || id === 'all') {
                usersData = await api.user.getUsers(offset / count, count,
                    sort.by ? (sort.by + ',' + (sort.desc ? 'desc' : 'asc')) : 'createdDate,desc', search, filters);
            } else {
                usersData = await api.resource.findUsersByResourceIdPaginate(id, offset / count, count,
                    sort.by ? (sort.by + ',' + (sort.desc ? 'desc' : 'asc')) : 'user.createdDate,desc', search, filters);

                for (let i = 0; i < usersData.data.content.length; i++) {
                    usersData.data.content[i] = usersData.data.content[i]['user'];
                }
            }

            const usersTmp = usersData.data.content.map(item => {
                return {
                    ...item,
                    position: item['position'] ? item['position'] : <EmptyCell/>,
                    office: item['office'] ? item['office'] : <EmptyCell/>,
                    supervisor: item['superVisor'] ? item['superVisor'] : <EmptyCell/>,
                    lastModifiedDate: item['lastModifiedDate']
                        ? new Date(item['lastModifiedDate']).toLocaleString().slice(0, -3)
                        : new Date(item['createdDate']).toLocaleString().slice(0, -3)
                };
            });

            setLocalUsers(usersTmp);
            setTotal(parseInt(usersData.data['totalElements']));

            setIsEmpty(parseInt(usersData.data['totalElements']) === 0 && !search && Object.keys(filters).length === 0);
        } catch {

        } finally {
            setIsLoading(false);
            setIsReLoading(false);
        }
    }, [id]);
    const checkboxHandler = useCallback((node) => {
        setCheckedCheckbox((prevValue) => {
            if (prevValue.some(c => c.id === node.id)) {
                return prevValue.filter(c => c.id !== node.id);
            } else {
                return [...prevValue, node]
            }
        })
    }, [])

    const blockUsers = useCallback(async (data) => {
        try {
            setIsLoading(true);
            closeDialog();

            if (data?.id) {
                await api.user.blockUser(data.login);
            }  else if (checkedCheckboxRef.current.length) {
                for (let i = 0; i < checkedCheckboxRef.current.length; i++) {
                    await api.user.blockUser(checkedCheckboxRef.current[i].login);
                }
            }

            const usersRes = await api.user.getUsers(0, 999999);
            dispatch(setUsersReducer(usersRes.data));

            tableRef?.current?.reload();
            addSuccess(data.length ? 'Выбранные (' + data.length + ') участники заблокированы' : 'Участник заблокирован');
        } catch (error) {
            addError('При блокировке возникла ошибка');
        } finally {
            setIsLoading(false);
        }
    }, [dispatch, closeDialog, addSuccess, addError]);
    const onBlockUsers = useCallback((data) => {
        if (data?.length) {
            setCheckedCheckbox(data.map(d => d.original))
        }

        let text = '';

        if (data?.length) {
            text = data.map((item) => {
                return {
                    ...item.original,
                    text: (
                        <>
                            <span onClick={() => navigate(`/edit-user/${item.original.login}`)} style={{ color: '#279BD9', cursor: 'pointer' }}>
                                {item?.original?.firstName} {item?.original?.lastName}
                            </span>
                        </>
                    )
                }
            });
        } else {
            text = (
                <span>
                    Вы действительно хотите заблокировать участника&nbsp;
                    <span style={{ color: '#279BD9', cursor: 'pointer' }} onClick={() => navigate(`/edit-user/${data.login}`)}>
                        {data.firstName} {data.lastName}
                    </span>?
                </span>
            );
        }

        openDialog({
            title: 'Блокирование',
            subTitle: data?.length ? 'Вы действительно хотите заблокировать нижеперечисленных участников?' : null,
            text,
            contentType: data?.length ? 'CHECKBOX_LIST' : 'TEXT',
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Да, заблокировать',
            onChange: (users) => checkboxHandler(users),
            onSubmit: () => blockUsers(data),
            onClose: closeDialog
        });
    }, [closeDialog, openDialog, blockUsers, navigate, checkboxHandler]);

    const columns = useMemo(() => [
        {
            Header: 'ИМЯ',
            accessor: 'firstName',
            width: 100,
            maxWidth: 500,
            Cell: (data) => {
                return (
                    <Link to={`/edit-user/${data.row.original['login']}`} className={classNames(cx.nameCell, {[cx.mobileName]: data.isMobile})}>
                        <img src={data.row.original['avatar'] ? api.upload.getImage(data.row.original['avatar'], false, 128) : '/img/avatar.png'}
                             alt=""/>
                        <MultiClumpTooltip label={data.row.original['firstName'] + ' ' + data.row.original['lastName']} />
                    </Link>
                )
            }
        },
        {
            Header: 'ДОЛЖНОСТЬ',
            accessor: 'position',
            width: 100,
            Cell: (data) => {
                return (
                    <>
                        {data.isMobile && <div className={cx.subheader}>Должность</div>}
                        {data.row.original['position']}
                    </>
                )
            }
        },
        {
            Header: 'ОФИС',
            accessor: 'office',
            width: 100,
            Cell: (data) => {
                return (
                    <>
                        {data.isMobile && <div className={cx.subheader}>Офис</div>}
                        {data.row.original['office']}
                    </>
                )
            }
        },
        {
            Header: 'РУКОВОДИТЕЛЬ',
            accessor: 'supervisor',
            width: 100,
            maxWidth: 500,
            Cell: (data) => {
                return (
                    <>
                        {data.isMobile && <div className={cx.subheader}>Руководитель</div>}
                        {data.row.original['supervisor']}
                    </>
                )
            }
        },
        {
            Header: 'ДАТА ИЗМЕНЕНИЯ',
            accessor: 'lastModifiedDate',
            width: 130,
            Cell: (data) => {
                return (
                    <>
                        {data.isMobile && <div className={cx.subheader}>Дата изменения</div>}
                        {data.row.original['lastModifiedDate']}
                    </>
                )
            }
        },
        {
            id: 5,
            width: 65,
            settings: platform === 'mobile' ? ['no_td_wrap'] : [],
            Cell: function(data) {
                const tools = [];

                if (data.row.original.permissions?.canEdit) {
                    tools.push({
                        icon: Icons.EDIT_PEN,
                        tooltip: 'Редактировать',
                        link: `/edit-user/${data.row.original['login']}/edit`
                    });
                }

                tools.push({
                    icon: Icons.BLOCK,
                    iconHoverColor: 'red',
                    tooltip: 'Заблокировать',
                    onClick: (data) => onBlockUsers(data),
                    covered: !(data.row.original.permissions?.canBlock && data.row.original.id !== currentUser.id)
                });

                return EditRowToolbar(tools)(data);
            }
        }
    ], [onBlockUsers, platform, currentUser.id]);

    const loadProjectOptions = async (search) => {
        let options = [];

        if (search) {
            const projectsResponse = await api.project.searchByTitle(search);

            options = projectsResponse.map((project) => ({ label: project.title, value: project.id }));
        }

        return { options };
    };

    const loadGroupsOptions = useCallback(async (search) => {
        let options = [];

        if (search) {
            const groupsResponse = await api.group.searchByName(search);

            options = groupsResponse.map((group) => ({ label: group.name, value: group.id }));
        }

        return { options };
    }, []);

    const loadSystemRolesOptions = useCallback(async () => {
        let rolesResponse = await api.role.getRolesList();
        rolesResponse = rolesResponse.filter(p => p.system)

        let options = rolesResponse.map((role) => ({label: role.title, value: role.name}));

        return {
            options: options,
        };
    }, []);

    const loadProjectRolesOptions = useCallback(async () => {
        let rolesResponse = await api.role.getRolesList();
        rolesResponse = rolesResponse.filter(p => !p.system)

        let options = rolesResponse.map((role) => ({label: role.title, value: role.name}));

        return {
            options: options,
        };
    }, []);

    const loadOfficesOptions = useCallback(async (search, _loadedOptions, { page }) => {
        let options = [];
        const officesResponse = await api.office.getOffices(page, 20, '', search);

        options = officesResponse.content.map((office) => ({ label: office.name, value: office.name }));

        return {
            options: options,
            hasMore: page < officesResponse.totalPages - 1,

            additional: {
                page: page + 1,
            },
        };
    }, []);

    const loadPositionsOptions = useCallback(async (search, _loadedOptions, { page }) => {
        let options = [];
        const positionsResponse = await api.userPosition.getUserPosition(page, 20, '', search);

        options = positionsResponse.content.map((position) => ({ label: position.title, value: position.title }));

        return {
            options: options,
            hasMore: page < positionsResponse.totalPages - 1,

            additional: {
                page: page + 1,
            },
        };
    }, []);

    const loadSupervisorsOptions = useCallback(async (search, _loadedOptions, { page }) => {
        let options = [];
        const supervisorsResponse = await api.office.getSupervisors(page, 20, '', search);

        options = supervisorsResponse.content.map((value) => ({ label: value.user.firstName + ' ' + value.user.lastName, value: value.user.login }));

        return {
            options: options,
            hasMore: page < supervisorsResponse.totalPages - 1,

            additional: {
                page: page + 1,
            },
        };
    }, []);

    const usersFilters = [
        {
            'label': 'Должность',
            'fields': [
                {
                    'name': 'positionTitle.in',
                    'type': 'search',
                    'default': null,
                    'isMulti': true,
                    'loadOptions': loadPositionsOptions
                }
            ]
        },
        {
            'label': 'Руководитель',
            'fields': [
                {
                    'name': 'supervisorLogin.in',
                    'type': 'search',
                    'default': null,
                    'isMulti': true,
                    'loadOptions': loadSupervisorsOptions
                }
            ]
        },
        {
            'label': 'Офис',
            'fields': [
                {
                    'name': 'officeName.in',
                    'type': 'search',
                    'default': null,
                    'isMulti': true,
                    'loadOptions': loadOfficesOptions
                }
            ]
        },
        {
            'label': 'Группа',
            'fields': [
                {
                    'name': 'group.in',
                    'type': 'search',
                    'default': null,
                    'isMulti': true,
                    'loadOptions': loadGroupsOptions
                }
            ]
        },
        {
            'label': 'Проект',
            'fields': [
                {
                    'name': 'project.in',
                    'type': 'search',
                    'default': null,
                    'isMulti': true,
                    'loadOptions': loadProjectOptions
                }
            ]
        },
        {
            'label': 'Системная роль',
            'fields': [
                {
                    'name': 'roleName.in',
                    'type': 'select',
                    'default': null,
                    'isMulti': true,
                    'loadOptions': loadSystemRolesOptions
                }
            ]
        },
        {
            'label': 'Проектная роль',
            'fields': [
                {
                    'name': 'projectRoleName.in',
                    'type': 'select',
                    'default': null,
                    'isMulti': true,
                    'loadOptions': loadProjectRolesOptions
                }
            ]
        },
    ];
    const tableActions = [];

    if (isAuthorizedAction([GLOBAL_ACTIONS.USER_BLOCK])) {
        tableActions.push({
            icon: Icons.BLOCK,
            className: cx.blockActionButton,
            label: 'Заблокировать',
            onClick: (data) => onBlockUsers(data)
        });
    }

    useEffect(() => {
        checkedCheckboxRef.current = checkedCheckbox;
    }, [checkedCheckbox]);

    useEffect(() => {
        setIsReLoading(true);
        setIsEmpty(false);

        setTotal(0);
        setIsInit(prev => {
            if (!prev) {
                return !prev;
            }

            tableRef?.current?.reload();
            return prev;
        });
    }, [id]);

    return (
        <PrivatePage hasAnyAuthorities={hasAnyAuthorities}>
            <Confirmation {...dialogState} />
            {isLoading && isReLoading && (
                <div className={cx.loader}>
                    <Loading withOverlay={false} />
                </div>
            )}
            {isEmpty && !isLoading && (
                <EmptyUsers />
            )}
            {!isEmpty && (
                <div className={cx.userContainer}>
                    {!isReLoading && <h2>Участники</h2>}
                    <TableComponent
                        innerRef={tableRef}
                        searchTitle="ФИО, логин, email, телефон"
                        addTitle="Добавить"
                        columns={columns}
                        actions={tableActions}
                        isLoading={isLoading}
                        total={total}
                        data={localUsers}
                        onPaginate={onPaginate}
                        filters={usersFilters}
                        addLink={isAuthorizedAction([GLOBAL_ACTIONS.USER_CREATE]) ? '/add/user' : ''}
                        isMobile={platform === 'mobile' || width <= 1024}
                        // filterButtons={width < 768 || width > 1024 ? [{
                        //     title: 'Импорт',
                        //     link: '/add/importUsers'
                        // }] : []}
                        defaultSort={{ by: (id !== 'all' ? 'user.' : '') + 'multiName', desc: true }}
                        sortOptions={[
                            {
                                Header: 'По имени (по алфавиту по фамилии) (по умолчанию)',
                                accessor: (id !== 'all' ? 'user.' : '') + 'multiName'
                            },
                            {
                                Header: 'По дате создания',
                                accessor: (id !== 'all' ? 'user.' : '') + 'createdDate'
                            },
                            {
                                Header: 'По дате изменения',
                                accessor: (id !== 'all' ? 'user.' : '') + 'lastModifiedDate'
                            },
                            {
                                Header: 'По должности',
                                accessor: (id !== 'all' ? 'user.' : '') + 'position'
                            },
                            {
                                Header: 'По офису',
                                accessor: (id !== 'all' ? 'user.' : '') + 'office'
                            },
                            {
                                Header: 'По руководителю',
                                accessor: (id !== 'all' ? 'user.' : '') + 'supervisor'
                            }
                        ]}
                    />
                </div>
            )}
        </PrivatePage>
    );
};

export default UsersPage;
