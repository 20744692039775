import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'shared/router';
import { useSectionFormModel } from 'lib/hooks/use-section-form-model';
import { Icons } from 'uikit/icon';
import Loading from 'uikit/loading';
import { SectionForm } from 'components/section-form';
import Card from 'components/card';
import api from 'api';
import cs from './section-form-page.module.scss';
import { EmptyProjectsSection } from '../projects/empty';

export const sectionFormPage = (Component, pageTitle, edit = false) => {
    return props => {
        const navigate = useNavigate();

        const [isEmpty, setIsEmpty] = useState(false);
        const [isFetching, setIsFetching] = useState(true);

        const { uuid } = useParams();
        const model = useSectionFormModel(uuid);

        const { section, loading, permissionsOk } = model;

        useEffect(() => {
            const fetchProjects = async () => {
                try {
                    setIsFetching(true);

                    const response = await api.globalMenu.hasAccess();
                    const projects = await api.project.getProjects(0, 1);

                    setIsEmpty(!response || !response.hasAccessToProjects || projects.content.length === 0);
                    setIsFetching(false);
                } catch {
                    setIsEmpty(true);
                    setIsFetching(false);
                }
            };
            fetchProjects();
        }, []);

        useEffect(() => {
            if (!permissionsOk) {
                navigate('/401');
            }
        }, [navigate, permissionsOk]);

        return !isFetching ? (
            <div className={cs.content}>
                <div id={'sectionFormPageTitle'} className={cs.pageTitle}>{pageTitle}</div>
                {!isEmpty &&
                <SectionForm section={section} loading={loading} uuid={uuid} edit={edit}>
                    <Component {...props} {...model} />
                </SectionForm>}
                {isEmpty &&
                <Card className={cs.emptyCard}>
                    <EmptyProjectsSection buttons={[{
                        icon: Icons.ADD,
                        title: 'Создать проект',
                        onClick: () => {
                            navigate('/add/project');
                        }
                    }]} />
                </Card>}
            </div>
        ) : <Loading withOverlay={false} withRelativeOverlay={true}/>;
    };
};
