import React from 'react';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import cx from './icon.module.scss';
import { useGlobalContext } from 'lib/hooks';
import { Placement } from '@floating-ui/react';

interface IconProps {
    id?: string;
    type: React.ReactNode;
    color?: 'gray' | 'red' | 'blue' | 'green' | 'white';
    className?: string;
    width: number;
    height: number;
    tooltip?: React.ReactNode;
    tooltipPlacement?: Placement;
}

const Icon = ({
    id = '',
    type = '',
    color,
    className = '',
    width = 15,
    height = 15,
    tooltip = null,
    tooltipPlacement = 'top',
    ...attrs
}: IconProps) => {
    const { platform } = useGlobalContext();
    const _color = color ? cx[color] : '';

    return (
        <>
            {platform === 'desktop' && tooltip &&
            <Tippy content={tooltip} placement={tooltipPlacement}>
                <i id={id} data-testid={id} className={classNames(className, [_color])} style={{
                    display: 'inherit',
                    width: width + 'px',
                    height: height + 'px'
                }} {...attrs}>{type}</i>
            </Tippy>}
            {(platform !== 'desktop' || !tooltip) &&
            <i id={id} data-testid={id} className={classNames(className, [_color])} style={{
                display: 'inherit',
                width: width + 'px',
                height: height + 'px'
            }} {...attrs}>{type}</i>}
        </>
    );
};

export default Icon;
