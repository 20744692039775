import { useEffect } from 'react';
import { useNavigate } from 'shared/router';
import { usePermittedActions } from '../../lib/hooks';

const useCheckResourcePermission = (type: any, uuid: any, permission: string) => {
    const navigate = useNavigate();
    const { checkPermission, ready } = usePermittedActions(type, uuid);

    useEffect(() => {
        if (ready && !checkPermission(permission)) {
            navigate('/401');
        }
    }, [permission, ready, checkPermission, navigate]);
};

export default useCheckResourcePermission;
