import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchRoles } from 'slice/customRolesSlice';
import { Outlet, setDocumentTitle } from 'shared/router';

const CustomRoles = ({ children }) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchRoles());
    }, [dispatch]);
    useEffect(() => {
        setDocumentTitle('Роли — KMS Gran');
    }, []);

    return (
        <div>
            <Outlet>{children}</Outlet>
        </div>
    );
};

export default CustomRoles;
