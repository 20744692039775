import { formatMessage } from "components/chat/utils";

const decodeHtmlEntities = (str) => {
    const htmlEntitiesMap = {
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
        '&quot;': '"',
        '&#39;': "'",
        '&nbsp;': ' ',
        '&ndash;': '–',
        '&mdash;': '—',
        '&apos;': "'",
        '&cent;': '¢',
        '&pound;': '£',
        '&yen;': '¥',
        '&euro;': '€',
        '&copy;': '©',
        '&reg;': '®',
        '&#160;': ' ',
    };

    return formatMessage(str).replace(/&[a-z]+;|&#\d+;/gi, (entity) => htmlEntitiesMap[entity] || entity);
};

export default decodeHtmlEntities;
