import React, { useRef } from 'react';
import { MenuButton } from './MenuButton';
import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';
import api from 'api/index';

export const MenuButtonImage = ({ initId, setCurrentMenu, isTooltip }) => {
    const inputRef = useRef(null);

    const hostname = window.location.hostname.includes('localhost')
        ? 'http://localhost:3000'
        : window.location.protocol + '//' + window.location.hostname;
    const editor = useEditorContext();
    const addImage = () => {
        setCurrentMenu(undefined);
        inputRef.current.click();
    };

    const handleAndInsertNewFiles = async e => {
        if (!editor || editor.isDestroyed || e.target.files.length === 0) {
            return;
        }

        const file = e.target.files[0];

        if (!file) {
            return;
        }

        const formData = new FormData();

        const re = new RegExp('XSRF-TOKEN=([^;]+)');
        const value = re.exec(document.cookie);

        formData.append('init', initId);
        formData.append('upload', file);
        formData.append('ckCsrfToken', value !== null ? decodeURI(value[1]) : null);

        const res = await api.file.uploadEditorFile(formData);

        inputRef.current.value = null;

        editor
            .chain()
            .focus()
            .setResizableImage({ src: res.url.replace('https://stag-kms.gran-soft.ru', hostname), alt: res.fileName, 'data-align': 'center' })
            // .setResizableImage({ src: res.url, alt: res.fileName })
            .run();
    };

    return (
        <>
            <MenuButton
                icon={Icons.EditorIconImage}
                onClick={addImage}
                tooltip={isTooltip === false ? null : 'Картинка'}
                label="Картинка"
                // disabled={!editor?.isEditable}
            ></MenuButton>
            <input
                ref={inputRef}
                style={{ display: 'none' }}
                type="file"
                accept={'image/png, image/jpeg'}
                onChange={handleAndInsertNewFiles}
            />
        </>
    );
};
