import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Link } from 'shared/router';
import { selectUsers } from 'slice/authSlice';
import { Select, SelectSearch } from 'uikit/select';
import Button from 'uikit/button';
import Icon, { Icons } from 'uikit/icon';
import cx from './managers-page-add-manager.module.scss';
import api from 'api';
import IconButton from 'uikit/icon-button';
import { useGlobalContext, useMessage } from 'lib/hooks';

const ManagersPageAddManager = ({ manager, onCloseHandler, onSubmitHandler }) => {
    const { platform } = useGlobalContext();
    const { addError } = useMessage();

    const selector = useSelector(state => state);

    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedOffice, setSelectedOffice] = useState(null);

    const [users, setUsers] = useState([]);
    const [usersSearchField, setSearchField] = useState('');

    const [filteredUsers, setFilteredUsers] = useState([]);
    const [offices, setOffices] = useState([]);

    const [errors, setErrors] = useState();

    const filterUser = useCallback((value = '') => {
        return users.filter(user => user.label.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }, [users]);

    const onClose = () => {
        onCloseHandler(false);
    };

    const onUserSelectInputChange = (e, setValue) => {
        if (setValue.action === 'input-change') {
            if (e.toString().length > 0) {
                setFilteredUsers(filterUser(e));
            } else if (e.toString().length === 0) {
                setFilteredUsers([]);
                setSelectedUser(null);
            }
            setSearchField(e);
        }
    };

    const onUserSelectChange = (e) => {
        if (errors?.user) {
            const _errors = { ...errors };
            delete _errors.user;
            setErrors(_errors);
        }

        setSearchField('');
        setSelectedUser(e);
    };

    const onOfficeSelectChange = (e) => {
        if (errors?.office) {
            const _errors = { ...errors };
            delete _errors.office;
            setErrors(_errors);
        }

        setSelectedOffice(e);
    };

    const onUserSelectMenuOpen = () => {
        if (selectedUser && !usersSearchField) {
            setSearchField(selectedUser.label);
            setFilteredUsers(filterUser(selectedUser.label));
        }
    };

    const onSubmit = () => {
        setErrors(null);
        if (!selectedUser) {
            setErrors(state => ({ ...state, user: 'Необходимо выбрать руководителя' }));
        }

        if (!selectedOffice) {
            setErrors(state => ({ ...state, office: 'Необходимо выбрать офис' }));
        }

        if (selectedUser && selectedOffice) {
            onSubmitHandler(selectedUser.value, selectedOffice.value, manager?.id || null);
            onCloseHandler(false);
        }
    };

    const fetchOffices = async () => {
        try {
            const res = await api.office.getOffices(0, 2000);
            setOffices(res.content.map(office => ({ label: office.name, value: office.id })));
        } catch {

        }
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const res = await selectUsers(selector);
                const _users = res.content.map(user => ({
                    label: `${user.firstName} ${user.lastName}`,
                    value: user.login,
                    payload: user
                }));

                setUsers(_users);
            } catch (e) {
                console.log(e);
                addError('Сервис недоступен. Пожалуйста попробуйте позже.');
            }
        };

        if (manager?.user && manager?.office) {
            setSelectedUser({ label: `${manager.user.firstName} ${manager.user.lastName}`, value: manager.user.login });
            setSelectedOffice({ label: manager.office.name, value: manager.office.id });
        }

        fetchUsers();
        fetchOffices();
    }, [addError, manager, selector]);

    return (
        <>
            <div className={cx.modalHeader}>
                <h3>Назначить руководителя</h3>
                <IconButton icon={<Icon type={Icons.CROSS} width={16} height={16}/>}
                            onClick={onClose}/>
            </div>
            <div className={cx.modalBody}>
                <div className={cx.userSelectWrapper}>
                    <SelectSearch label="Имя руководителя" value={selectedUser} inputValue={usersSearchField}
                                  options={filteredUsers} isSearchable required error={errors?.user} filterOption={null}
                                  noDataMessage="Ничего не найдено" onMenuOpen={onUserSelectMenuOpen}
                                  onInputChange={onUserSelectInputChange} onChange={onUserSelectChange}
                                  id={'managerFormName'}/>
                    <div className={cx.userSelectDesc}>
                        Руководителем можно назначить только уже созданного участника.
                        Если профиль еще не был создан —
                        <Link className={cx.userLink} to={'/add/user'}> пригласите участника</Link>
                    </div>
                </div>
                <Select label="Выберите офис" placeholder="Выберите офис из спиcка" required error={errors?.office}
                        value={selectedOffice} className={cx.actions} options={offices} onChange={onOfficeSelectChange}
                        id={'managerFormOffice'}/>
            </div>
            <div className={classNames(cx.modalFooter, {[cx.mobileFooter]: platform === 'mobile'})}>
                <Button label="Отменить" onClick={onClose} fullWidth={platform === 'mobile'}/>
                <Button color="green" label="Подтвердить" onClick={onSubmit} fullWidth={platform === 'mobile'}/>
            </div>
        </>
    );
};

export default ManagersPageAddManager;
