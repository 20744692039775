import React, { useImperativeHandle, useRef, useState } from 'react';
import classNames from 'classnames';
import DropdownListItem from './dropdown-list-item';
import cs from './dropdown-list.module.scss';

export const DropdownList = ({
    mode,
    name,
    currentMenu,
    setCurrentMenu,
    innerRef,
    position = 'left',
    ...props
}) => {
    const ref = useRef(null);
    const [isShow, setIsShow] = useState(false);

    const showMenu = event => {
        event.preventDefault();
        event.stopPropagation();

        if (setCurrentMenu) {
            setCurrentMenu(name);
        }

        setIsShow(show => {
            if (!show && document.body.offsetWidth > 1024) {
                document.addEventListener('click', closeMenu);
            }

            return !show;
        });
    };

    const hideMenu = () => {
        setIsShow(false);
        document.removeEventListener('click', closeMenu);
    };

    const closeMenu = event => {
        if (ref && !ref.current?.contains(event.target)) {
            setIsShow(false);
            document.removeEventListener('click', closeMenu);
        }
    };

    useImperativeHandle(innerRef, () => ({
        showmenu: showMenu,
        hidemenu: hideMenu,
    }));

    React.useEffect(() => {
        if (mode === 'DESKTOP') {
            return;
        }

        if (currentMenu !== name) {
            setIsShow(false);
        }
    }, [mode, name, currentMenu, setCurrentMenu]);

    return (
        <div
            className={classNames(cs.container, props.className, {
                [cs.show]: isShow,
            })}
            style={props.containerStyle}
        >
            {props.toggler?.(showMenu, isShow) || (
                <button className={cs.button} onClick={showMenu}>
                    {props.title}
                </button>
            )}
            {isShow && (
                <div
                    className={classNames(cs.menu, props.menuClassName, {
                        [cs.left]: position === 'left',
                        [cs.right]: position === 'right',
                    })}
                    style={props.menuStyle}
                    ref={ref}
                >
                    {React.Children.map(props.children, child => {
                        if (React.isValidElement(child)) {
                            return React.cloneElement(child);
                        }

                        return child;
                    })}
                    <div className={cs.MenuContentClose} onClick={hideMenu}>
                        Отмена
                    </div>
                </div>
            )}
        </div>
    );
};

DropdownList.Item = DropdownListItem;
export default DropdownList;
