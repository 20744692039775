import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import classNames from 'classnames';
import Marquee from 'react-marquee-slider';
import { useNavigate } from 'shared/router';
import { useDispatch, useSelector } from 'react-redux';
import times from 'lodash/times';
import { useWindowSize } from 'lib/hooks/useWindowSize';
import { selectNewsPickerUpdateStatus, setNewsPicker, updateNewsTicker, setNewsBeingRemoved } from 'slice/newsPickerSlice';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import api from 'api';
import cx from './news-ticker.module.scss';

const NewsTicker = () => {
    const refs = useRef([React.createRef()]);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { width } = useWindowSize();
    const isNewTickerUpdate = useSelector(selectNewsPickerUpdateStatus);

    const [tickerNews, setTickerNews] = useState([]);
    const [news, setNews] = useState(undefined);

    const [repeatNumber, setRepeatNumber] = useState(1);

    const [newsWidth, setNewsWidth] = useState(width);
    const [move, setMove] = useState(40);

    const velocity = useMemo(() => {
        if (newsWidth <= width) {
            return 40;
        } else {
            return 40 * width / newsWidth;
        }
    }, [width, newsWidth]);
    const fetchData = useCallback(async () => {
        let tmpTickers = await api.news.getTickers();
        setTickerNews(tmpTickers);

        api.news.getNews(0, tmpTickers.length, 'modifyTime,desc', '', {
            'uuid.in': tmpTickers.map((ticker) => ticker.newsId)
        }).then(p => setNews(p));

        dispatch(setNewsPicker({
            count: tmpTickers.length,
            list: tmpTickers
        }));
    }, [dispatch]);

    const deleteTicker = async (id) => {
        try {
            await api.news.deleteTicker(id);
            dispatch(setNewsBeingRemoved(id));

            fetchData();
        } catch (e) {
            console.log(e);
        }
    };

    const onMouseEnter = () => {
        setMove(0);
    };
    const onMouseLeave = () => {
        setMove(velocity);
    };

    const renderNewsTicker = (item) => {
        let itemData = { permissions: { canEdit: false } };

        if (news) {
            const foundItemData = news.content.find(p => p.id === item.newsId);

            if (foundItemData) {
                itemData = foundItemData;
            }
        }

        return (
            <div key={item.newsId} className={classNames(cx.newsTickerWrapper, cx[item.color])}>
                <span
                    className={cx.newsTickerTitle}
                    style={{ 'color': '#' + item.color }}
                    onClick={() => navigate(`/projects/news/${item.newsId}`)}
                >
                    {item.text}
                </span>
                {itemData.permissions.canEdit && (
                    <IconButton
                        onClick={() => deleteTicker(item.newsId)}
                        icon={<Icon type={Icons.CROSS} width={8} height={8} />}
                    />
                )}
                {!itemData.permissions.canEdit && (
                    <div style={{ width: '8px', height: '8px' }}></div>
                )}
            </div>
        );
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);
    useEffect(() => {
        if (isNewTickerUpdate) {
            fetchData();
            dispatch(updateNewsTicker(false));
        }
    }, [isNewTickerUpdate, dispatch, fetchData]);

    useEffect(() => {
        if (tickerNews.length === 1) {
            setRepeatNumber(20);
            if (refs && refs.current && refs.current[0] && refs.current[0].current) {
                setNewsWidth(20 * refs.current[0].current.clientWidth);
            }
        } else {
            setRepeatNumber(10);
            if (refs && refs.current && refs.current[0] && refs.current[0].current) {
                setNewsWidth(10 * refs.current[0].current.clientWidth);
            }
        }
    }, [tickerNews]);
    useEffect(() => {
        setMove(velocity);
    }, [velocity, tickerNews]);

    if (tickerNews.length < 1) {
        return null;
    }

    return (
        <div
            id="news-line"
            className={cx.blockWrapper}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <div className={cx.marqueeWrapper} style={{ height: '28px' }}>
                <Marquee velocity={move} onInit={() => {}}>
                    {times(repeatNumber, Number).map(id => (
                        <div ref={refs.current[id]} className={cx.newsGroup} key={id}>
                            {tickerNews.map(renderNewsTicker)}
                        </div>
                    ))}
                </Marquee>
            </div>
        </div>
    );
};

export default NewsTicker;
