import { useContext } from 'react';
import { RouterContext } from '../routerProvider';
import { RenderLinkProps } from '../routers/router';
import { useNavigate } from '../hooks/useNavigate';

export const Link = (props: RenderLinkProps) => {
    const context = useContext(RouterContext);

    if (!context) {
        throw new Error('Link must be used within a RouterProvider');
    }

    const navigate = useNavigate();

    return (
        <a
            {...props}
            href={props.to}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                const to = props.to.length > 1 && props.to[0] !== '/' ? `/${props.to}` : props.to;
                navigate(to);
            }}
        >
            {props.children}
        </a>
    );
};
