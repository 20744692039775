import React, { useState, useMemo, useEffect } from 'react';
import { Link } from 'shared/router';
import { ReportsComponent } from 'components/data-components/reports-component';
import cx from './statistic-reports-page.module.scss';
import api from 'api';
import { articleSearchBy, newsSearchBy, objectTypes, sortTypesOptions } from 'app/statistic/vendors/filtersOptions';
import { ReportService } from 'app/statistic/services/ReportService';
import { useMessage } from 'lib/hooks';

const StatisticReportsPopularityByRating = ({ setIsDownloadModal }) => {
    const { addError } = useMessage();

    const [isLoading, setIsLoading] = useState(false);

    const [reports, setReports] = useState([]);
    const [reportsTotal, setReportsTotal] = useState(0);
    const [sortByOptions, setSortByOptions] = useState([]);

    const getArticlesInProjectsReports = async (page = 0, size = 50, filters = {}) => {
        setIsLoading(true);

        try {
            const res = await api.reports.getDocumentPopularity({ page, size, filters, cancelToken: ReportService.cancelToken.token });

            setReports(
                res.resources.content.map(i => {
                    return {
                        ...i,
                        resource_title_path: i.resourceTitlePath,
                        project_title: i.projectTitle,
                        document_type: i.objectType,
                    };
                })
            );
            setReportsTotal(res.resources.totalElements);

            if (!res.resources.totalElements) {
                addError('По вашему запросу нет данных');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const onDownload = async filters => {
        try {
            await api.reports.downloadReports('document-popularity/by-likes', filters, ReportService.cancelToken.token);
            setIsDownloadModal(true);
        } catch (error) {
            addError('Произошла ошибка при скачивании отчета');
        }
    };

    const columnsTmp = useMemo(() => {
        const data = [
            {
                Header: 'Документ',
                accessor: 'resource_title_path',
                Cell: data => {
                    return (
                        <Link
                            className={cx.link}
                            to={
                                data.row.original.resourceType === 'NEWS'
                                    ? `/projects/news/${data.row.original.resourceUuid}`
                                    : `/projects/article/${data.row.original.resourceUuid}`
                            }
                        >
                            {data.row.original.resource_title_path}
                        </Link>
                    );
                },
            },
            {
                Header: 'Проект',
                accessor: 'project_title',
                Cell: data => {
                    return (
                        <Link className={cx.link} to={'/projects/' + data.row.original.projectUuid + '/articles'}>
                            {data.row.original.project_title}
                        </Link>
                    );
                },
            },
            {
                Header: 'Тип',
                accessor: 'document_type',
                Cell: data => {
                    return data.row.original.resourceType === 'ARTICLE'
                        ? 'Статья'
                        : data.row.original.resourceType === 'NEWS'
                        ? 'Новость'
                        : 'Неизвестный тип';
                },
            },
            {
                Header: 'Лайков',
                accessor: 'likes',
            },
            {
                Header: 'Дизлайков',
                accessor: 'dislikes',
            },
        ];

        setSortByOptions(
            data
                .filter(col => col.accessor && col.accessor !== 'document_type' && col.isVisible !== false)
                .map(column => ({ label: column.Header, value: column.accessor }))
        );

        return data;
    }, []);

    const filtersTmp = useMemo(() => {
        return [
            {
                label: 'До указаной даты',
                fields: [
                    {
                        name: 'timestamp',
                        type: 'date',
                        default: new Date().toISOString(),
                    },
                ],
                width: '100%',
            },
            {
                label: 'Тип документа',
                fields: [
                    {
                        name: 'document-type-select',
                        type: 'document-type-select',
                        options: objectTypes,
                        default: objectTypes[0],
                        required: true,
                    },
                ],
                width: '100%',
            },
            {
                label: '',
                fields: [
                    {
                        name: 'articles-group',
                        type: 'articles',
                        options: articleSearchBy,
                        default: articleSearchBy[0],
                        required: true,
                    },
                ],
                width: '100%',
            },
            {
                label: '',
                fields: [
                    {
                        name: 'news-group',
                        type: 'news',
                        options: newsSearchBy,
                        default: newsSearchBy[1],
                        required: true,
                    },
                ],
                width: '100%',
            },
            {
                label: 'Сортировать по',
                fields: [
                    {
                        name: 'sortBy',
                        type: 'select',
                        options: sortByOptions,
                        default: sortByOptions ? sortByOptions.find(i => i.value === 'likes') : null,
                    },
                ],
                width: '25%',
            },
            {
                label: 'Порядок',
                fields: [
                    {
                        name: 'sort',
                        type: 'select',
                        options: sortTypesOptions,
                        default: sortTypesOptions[0],
                    },
                ],
                width: '25%',
            },
        ];
    }, [sortByOptions]);

    useEffect(() => {
        ReportService.init('DOCUMENTS_POPULARITY_BY_RATING');

        return () => {
            ReportService.destroy();
        };
    }, []);

    return (
        <ReportsComponent
            isEmpty={!reportsTotal}
            isLoading={isLoading}
            columns={columnsTmp}
            data={reports}
            total={reportsTotal}
            filters={filtersTmp}
            onDownload={onDownload}
            onPaginate={getArticlesInProjectsReports}
        />
    );
};

StatisticReportsPopularityByRating.propTypes = {};

export default StatisticReportsPopularityByRating;
