import React, { useState, useEffect } from 'react';
import { useGlobalContext, useMessage } from 'lib/hooks';
import hot from 'containers/hot-container';
import PrivatePage from 'containers/private-page-wrapper';
import { projectFormPage } from 'components/project-form';
import Button from 'uikit/button';
import Loading from 'uikit/loading';
import api from 'api';
import { useDispatch } from 'react-redux';
import { fetchMenuButtons } from 'slice/authSlice';
import { setDocumentTitle, useNavigate } from 'shared/router';

const AddProjectPage = ({ project, loading, setLoading, hasAnyAuthorities = [] }) => {
    const navigate = useNavigate();

    const { platform, setFormDataChanged } = useGlobalContext();
    const dispatch = useDispatch();

    const { addError, addSuccess } = useMessage();
    const [isSubmit, setIsSubmit] = useState();

    const addProject = async () => {
        setFormDataChanged(false);
        setIsSubmit(true);

        if (!project.validateFields()) {
            return;
        }

        const titleExists = await project.validateTitleExists();
        if (titleExists) {
            return;
        }

        setLoading(true);
        try {
            const formData = project.getFormData();
            const res = await api.project.postProject(formData);

            addSuccess('Проект создан');
            navigate(`/projects/${res.id}/articles`);
        } catch (error) {
            addError('Не удалось создать проект');
        } finally {
            setLoading(false);
            dispatch(fetchMenuButtons());
        }
    };

    useEffect(() => {
        if (isSubmit && Object.values(project.errors).some(v => v)) {
            project.scrollToError();
            setIsSubmit(false);
        }
    }, [isSubmit, project, project.errors]);
    useEffect(() => {
        setDocumentTitle('Добавление проекта — KMS Gran');
    }, []);

    return (
        <PrivatePage hasAnyAuthorities={hasAnyAuthorities}>
            {loading &&
            <Loading withOverlay={false} withRelativeOverlay={true}/>}
            <Button onClick={addProject} label='Создать проект' disabled={loading} color={'green'}
                    fullWidth={platform === 'mobile'}/>
        </PrivatePage>
    );
};

export default hot(projectFormPage(AddProjectPage, 'Добавление проекта'));
