import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'shared/router';
import { useSelector } from 'react-redux';
import Icon, { Icons } from 'uikit/icon';
import { useNotifications } from 'notifications/appllication/context';
import { NotificationTypes } from 'notifications/view/types';
import { isManualNotification } from 'notifications/utils/isManualNotification';
import { selectNewsPicker } from 'slice/newsPickerSlice';
import Button from 'uikit/button';
import Loading from 'uikit/loading';
import api from 'api';
import cx from './modal.module.scss';

const NotificationsModal = ({ isMobile, onClose }) => {
    const ref = useRef(null);

    const { notificationsCount, getUnreadNotifications, readNotification, lastNotification } = useNotifications();
    const isTicker = useSelector(selectNewsPicker).count > 0;

    const [isLoading, setIsLoading] = useState(true);
    const [isLastNotification, setIsLastNotification] = useState(false);

    const [page, setPage] = useState(0);

    const [notifications, setNotifications] = useState([]);
    const [height, setHeight] = useState(0);

    const onRead = (notification) => {
        setNotifications(prev => {
            const newNotifications = Object.assign([], prev);
            const notificationIndex = newNotifications.findIndex((item) => item['id'] === notification['id']);

            if (notificationIndex !== -1) {
                newNotifications[notificationIndex]['readByUser'] = true;
            }

            readNotification(notification['id']);
            return newNotifications;
        });
    };
    const onWindowClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const getHeight = (elIndex) => {
        let _height = 0;

        for (let i = 0; i < elIndex + 1; i++) {
            if (i > 4 && ref.current && ref.current.children.length > i) {
                _height += ref.current.children[i].clientHeight;
            }
        }

        return _height
    };
    const onScroll = () => {
        const nextNotifications = Object.assign([], notifications);

        let viewNotifications = document.getElementById('notifications').children;
        let isChanged = false;

        for (let i = 0; i < viewNotifications.length; i++) {
            if (isManualNotification(nextNotifications[i])) {
                continue;
            }

            if (ref.current.scrollTop < getHeight(i) || nextNotifications[i].readByUser) {
                continue;
            }

            nextNotifications[i].readByUser = true;
            isChanged = true;

            readNotification(nextNotifications[i]?.id);
        }

        if (!isLastNotification && !isLoading && ref.current.scrollTop + ref.current.clientHeight >= ref.current.scrollHeight - 50) {
            setPage(prev => prev + 1);
        }

        if (isChanged) {
            setNotifications(nextNotifications);
        }
    };

    useEffect(() => {
        if (!notifications || notifications.length === 0) {
            return;
        }

        let _height = 0;

        for (let i = 0; i < 5; i++) {
            if (ref.current && ref.current.children.length > i) {
                _height += ref.current.children[i].clientHeight;
            }
        }

        setHeight(_height);
    }, [ref, notifications]);
    useEffect(() => {
        setIsLoading(true);

        getUnreadNotifications(page).then(response => {
            setIsLoading(false);

            if (response.content.length === 0) {
                setIsLastNotification(true);
            }

            setNotifications(prev => [...prev || [], ...response.content]);
        });
    }, [page, getUnreadNotifications]);

    useEffect(() => {
        if (!lastNotification || lastNotification.readByUser) {
            return;
        }

        setNotifications(prev => {
            if (prev.length === 0 || prev.findIndex(p => p.id === lastNotification.id) !== -1) {
                return prev;
            }

            return [lastNotification, ...prev];
        });
    }, [lastNotification]);
    useEffect(() => {
        if (notifications.length === 0) {
            return;
        }

        if (notifications.length > 5) {
            return;
        }

        if (notifications.filter(p => !p.readByUser && !isManualNotification(p)).length === 0) {
            return;
        }

        setNotifications(prev => {
            const nextNotifications = Object.assign([], prev);
            nextNotifications.forEach(p => {
                if (p.readByUser || isManualNotification(p)) {
                    return;
                }

                readNotification(p.id);
                p.readByUser = true;
            });

            return nextNotifications;
        });
    }, [notifications, readNotification]);

    return (
        <>
            {isMobile && <div className={cx.mobileBack} />}
            <div
                id="notificationsWindow"
                onClick={onWindowClick}
                className={classNames(cx.container, {[cx.mobile]: isMobile}, {[cx.newLine]: isTicker})}
            >
                <p className={cx.head}>
                    <b>Непросмотренные</b>
                    &nbsp;—&nbsp;
                    {notificationsCount}
                </p>
                {!isLoading && notifications.length <= 0&& (
                    <div className={cx.empty}>
                        <Icon type={Icons.BELL} width={24} height={24} />
                        <span>Нет новых уведомлений</span>
                    </div>
                )}
                {notifications.length > 0 && (
                    <div
                        id="notifications"
                        ref={ref}
                        className={cx.notifications}
                        style={{ height: height }}
                        onScroll={onScroll}
                    >
                        {notifications.map((item, i) => {
                            return (
                                <div
                                    key={i}
                                    className={classNames(cx.notification, item['readByUser'] ? cx.notificationRead : null)}
                                >
                                    <div className={cx.notificationContent}>
                                        {item['author'] && (
                                            <img src={item['author']['avatarUuid'] ?
                                                api.upload.getImage(item['author']['avatarUuid'], false, 128)
                                                : '/img/avatar.png'} alt='' />
                                        )}
                                        <p>{NotificationTypes[item['objectType'] + '.' + item['eventType']]
                                            ? NotificationTypes[item['objectType'] + '.' + item['eventType']](onClose, item, true)
                                            : item['objectType'] + '.' + item['eventType']}</p>
                                    </div>
                                    {(item['objectType'] === 'ARTICLE_COMMENT' || item['objectType'] === 'NEWS_COMMENT') && (
                                        <p className={cx.notificationComment}>{item['data']['text']}</p>
                                    )}
                                    {isManualNotification(item) && (
                                        <Button
                                            color="green"
                                            disabled={item['readByUser']}
                                            onClick={() => onRead(item)}
                                        >
                                            {item['readByUser'] ? 'Прочитано' : 'Прочитал (-а) уведомление'}
                                        </Button>
                                    )}
                                    <p className={cx.notificationTime}>
                                        {new Date(item.timestamp).toLocaleString().slice(0, -3)}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                )}
                {isLoading && (
                    <div className={cx.loader}>
                        <Loading withOverlay={false} small />
                    </div>
                )}
                <Link to='/action-log/notifications' className={cx.bottom} onClick={onClose}>
                    Показать все уведомления
                </Link>
            </div>
        </>
    );
}

export default NotificationsModal;
