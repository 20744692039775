import React from "react";
import Modal from "react-modal";
import { createRoot } from "react-dom/client";
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as serviceWorker from './serviceWorker';
import 'lib/polyfills/promise-allSettled';
import { ConfigurationProvider } from './lib/context';
import App from 'app';
import Routes from 'app/routes';
import './index.scss';
import 'tippy.js/dist/tippy.css';
import '@trendmicro/react-checkbox/dist/react-checkbox.css';
import * as Sentry from '@sentry/react';
import { RouterProvider } from 'shared/router';
import { ReactRouter } from './shared/router/routers/reactRouter';

Modal.setAppElement('#root');

const initConfigurationCallback = configuration => {
    if (!configuration.common.sentry.enabled || !configuration.common.sentry.dsn || !configuration.common.sentry.environment) {
        return;
    }

    Sentry.init({
        dsn: configuration.common.sentry.dsn,
        environment: configuration.common.sentry.environment,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
};

const root = createRoot(document.getElementById('root'));
const router = new ReactRouter();

root.render(
    <ConfigurationProvider initCallback={initConfigurationCallback}>
        <RouterProvider router={router}>
            <App>
                <Routes />
            </App>
        </RouterProvider>
    </ConfigurationProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
