import React, { useEffect } from 'react';
import { useNavigate } from 'shared/router';
import { useNewsFormModel } from 'lib/hooks';
import ContentManagementProjectFormPage from './content-management-form';
import cs from './content-management-form-page.module.scss';

export const contentManagementNewsFormPage = (Component, pageTitle) => {
    const FormPage = (props) => {

        const navigate = useNavigate();
        const { uuid } = props;

        const model = useNewsFormModel(uuid);
        const { news, loading, permissionsOk } = model;

        useEffect(() => {
            if (!permissionsOk) {
                navigate('/401');
            }
        }, [navigate, permissionsOk]);

        return (
            <div className={cs.content}>
                <div id={'contentManagementProjectFormPageTitle'} className={cs.pageTitle}>{pageTitle}</div>
                <ContentManagementProjectFormPage resource={news} loading={loading}>
                    <Component {...props} {...model}/>
                </ContentManagementProjectFormPage>
            </div>
        );
    };

    return FormPage;
};
