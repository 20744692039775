import React from 'react';

function Pane({ children, primary, hidden, size, percentage, vertical }) {
    const classes = ['layout-pane'];
    const style = {};

    if (!primary) {
        const dimension = vertical ? 'height' : 'width';
        style[dimension] = `${size}${percentage ? '%' : 'px'}`;
    }

    if (primary) {
        classes.push('layout-pane-primary');
    }

    if (hidden) {
        classes.push('layout-pane-hidden');

        style.position= 'absolute';
        style.left = -10000;
    }

    return (
        <div className={classes.join(' ')} style={style}>
            {children}
        </div>
    );
}

export default Pane;
