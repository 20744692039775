import { v4 as uuidv4 } from 'uuid';

export const isIFrame = (input: Element | null): input is HTMLIFrameElement => 
    input !== null && input.tagName === 'IFRAME';

export const isEqualStr = (str1: string | number, str2: string | number): boolean => 
    str1.toString().toLowerCase() === str2.toString().toLowerCase();

export const encodeBase64 = (str: string): string => 
    btoa(unescape(encodeURIComponent(str)));

export const decodeBase64 = (str: string): string => 
    decodeURIComponent(escape(atob(str)));

export const decodeXmlSvgBase64ToSvg = (str: string): string =>
    decodeURIComponent(escape(atob(str.replace(/^data:image\/svg\+xml;base64,/, ''))));

export const downloadSvgFromBase64Image = (base64Str: string): void => {
    const link = document.createElement('a');
    link.href = base64Str;
    link.download = `diagram-svg_${uuidv4().replace(/-/g, '').slice(0, 20)}.svg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
