import React, { useCallback, useEffect, useRef } from 'react';
import { CurrentRouter } from 'shared/router';
import { useGlobalContext, useDialog } from 'lib/hooks';
import Confirmation from 'components/confirmation';

const FormDataSaveWrapper = ({ children }) => {
    const { dialogState, openDialog, closeDialog } = useDialog();
    const { visitedUrls,
        setVisitedUrls,
        formDataChanged,
        setFormDataChanged,
        gotScriptProgress,
        setGotScriptProgress,
        formDataChangedSubmitHandler,
    } = useGlobalContext();

    const formDataChangedRef = useRef(false);
    const gotScriptProgressRef = useRef(false);
    const formDataChangedSubmitHandlerRef = useRef(null);

    const onSubmitHandler = useCallback((navigateHandler) => {
        closeDialog();
        navigateHandler();
    }, [closeDialog]);

    // Диалог при изменённом сост-ии формы
    const openFormDataChangeDialog = useCallback((navigateHandler) => {
        openDialog({
            title: 'Хотите покинуть страницу?',
            subTitle: 'Вы действительно хотите выйти? Все несохранённые данные будут потеряны.',
            color: 'green',
            closeBtnText: 'Отменить',
            submitBtnText: 'Подтвердить',
            onSubmit: () => {
                onSubmitHandler(navigateHandler);
                setFormDataChanged(false);
                formDataChangedSubmitHandlerRef?.current?.();
                // setFormDataChangedSubmitHandler(null);
            },
            onClose: closeDialog
        });
    }, [openDialog, closeDialog, onSubmitHandler, setFormDataChanged]);

    // Диалог при прерывании прогресса скрипта
    const openScriptProgressDialog = useCallback((navigateHandler) => {
        openDialog({
            title: 'Конец скрипта',
            subTitle: 'При выходе с этой страницы, текущий скрипт будет прерван. Прогресс выполнения скрипта нельзя вернуть.',
            color: 'red',
            closeBtnText: 'Нет, вернуться',
            submitBtnText: 'Да, завершить скрипт',
            onSubmit: () => {
                onSubmitHandler(navigateHandler);
                setGotScriptProgress(false);
            },
            onClose: closeDialog
        });
    }, [openDialog, closeDialog, onSubmitHandler, setGotScriptProgress]);

    const onBeforeUnload = useCallback((event) => {
        if (formDataChangedRef.current) {
            event.preventDefault();
            event.returnValue = 'Вы уверены в том что хотите покинуть страницу?';
        }
    }, []);


    useEffect(() => {
        if (visitedUrls[visitedUrls.length - 1] !== window.location.pathname + window.location.search){
            setVisitedUrls([
              ...visitedUrls,
              window.location.pathname + window.location.search
            ]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname, window.location.search]);

    // useEffect(() => {
    //     const onLocationChange = () => {
    //         const url = visitedUrls[visitedUrls.length - 2] ?? '/';
    //
    //         const navByUrl = () => {
    //             const visitedUrlsClone = [...visitedUrls];
    //
    //             visitedUrlsClone.length = visitedUrlsClone.length ? visitedUrlsClone.length - 1 : 0;
    //             setVisitedUrls(visitedUrlsClone);
    //
    //             window.history.pushState(null, '', url);
    //             CurrentRouter.getNavigateFunction()(url);
    //         }
    //
    //         // Отслеживание состояния формы
    //         if (formDataChangedRef.current/*  && isActualUrl(formUrls) */) {
    //             openFormDataChangeDialog(navByUrl);
    //             return false;
    //         }
    //
    //         // Отслеживание прогресса скрипта
    //         if (gotScriptProgressRef.current/*  && isActualUrl(scriptUrls) */) {
    //             openScriptProgressDialog(navByUrl);
    //             return false;
    //         }
    //
    //         navByUrl();
    //     };
    //
    //     window.addEventListener('popstate', onLocationChange);
    //
    //     return() => {
    //       window.removeEventListener('popstate', onLocationChange);
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [visitedUrls]);

    useEffect(() => {
      formDataChangedRef.current = formDataChanged;
    }, [formDataChanged]);

    useEffect(() => {
      gotScriptProgressRef.current = gotScriptProgress;
    }, [gotScriptProgress]);

    useEffect(() => {
      formDataChangedSubmitHandlerRef.current = formDataChangedSubmitHandler;
    }, [formDataChangedSubmitHandler]);

    useEffect(() => {
        window.addEventListener('beforeunload', onBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload);
        };
    }, [onBeforeUnload]);

    useEffect(() => {
        CurrentRouter.setCustomNavigateFunction((...props) => {
            const navByUrl = () => {
                CurrentRouter.getNavigateFunction()(...props);
            }

            // const noCheckFormDataChanged = props[1] && (props[1] === 'no-check-form-data-changed' || props[1]?.replace === false);
            const noCheckFormDataChanged = props[1] && props[1] === 'no-check-form-data-changed';

            // Отслеживание состояния формы
            if (formDataChangedRef.current && !noCheckFormDataChanged/*  && isActualUrl(formUrls) */) {
                openFormDataChangeDialog(navByUrl);
                return false;
            }

            // Отслеживание прогресса скрипта
            if (gotScriptProgressRef.current/*  && isActualUrl(scriptUrls) */) {
                openScriptProgressDialog(navByUrl);
                return false;
            }

            navByUrl();
        });
    }, [openFormDataChangeDialog, openScriptProgressDialog]);

    return (
        <>
            {children}
            <Confirmation {...dialogState} />
        </>
    );
};

export default FormDataSaveWrapper;
