import { EMPTY_CKEDITOR_TEXT, EMPTY_TIPTAP_TEXT } from '../../editors/constants';

const textRe = /^[a-zA-Zа-яА-ЯёЁăĂâÂîÎșȘțȚ0-9\s=!?;:`"«»'№#@$%&*^)\\/|(.…,+_—–-]*$/;
const nameRe = /^[a-zA-Zа-яА-ЯёЁăĂâÂîÎșȘțȚ\s\\/|—–-]*$/;
export const loginPassRe = /^[\da-zA-Z_.]+$/;

const emailRegexs = [
    /^[\w0-9!#$%&'*\-+/=?^_`{|}~.]+@/
];

const emailRegexAlternatives = [
    /@[a-z0-9.-]+$/,
    /@\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}]$/
];

const emailRegexNegatives = [
    /\.\./,
    /^\./,
    /\.@/,
    /@\./,
    /\.$/,

    // больше одной собаки
    /^.*@.*@.*$/,

    // тире после собаки и точки
    /@-/,
    /@.+\.-/,

    // тире перед точкой
    /@.+-\./,
    /@.+-$/,

    // только цифры
    /@[0-9.]+$/
];

const emailLengthNegatives = [
    // больше 64 символов в блоке домена после собаки
    /@.{64,}\./,
    /@.+\..{64, }\./,
    /@.+\.{64,}$/,

    // больше 64 символов до собаки
    /^.{64,}@/,

    // общая длина меньше 5 или больше 254
    /^.{,4}$/,
    /^.{255,}$/
];

export function textValidator(str, len = 254, required = true, validate = true) {
    if (required && (!str || str.trim().length === 0)) return 'Поле обязательно для заполнения';
    if (len > 0 && str?.length > len) return 'Превышена максимальная длина текста';
    if (validate && !textRe.test(str)) return 'Для ввода допустимы следующие символы - буквы, цифры, символы =!?;:`"«»\'№#@$%&*^)(.,_+–-';
    return null;
}

export function htmlValidator(str, required = true) {
    if (required && (!str || str.trim().length === 0 || str === EMPTY_CKEDITOR_TEXT || str === EMPTY_TIPTAP_TEXT)) {
        return 'Поле обязательно для заполнения';
    }

    return null;
}

export function nameValidator(name, required = true) {
    if (!required && !name) {
        return null;
    }

    if (required && (!name || name.trim().length === 0)) {
        return 'Поле обязательно для заполнения';
    }

    if (name.length > 254) {
        return 'Превышена максимальная длина текста';
    }

    if (!nameRe.test(name)) {
        return 'Для ввода допустимы русские буквы и тире';
    }

    if (['—', '–', '-'].indexOf(name.at(0)) !== -1 || ['—', '–', '-'].indexOf(name.at(-1)) !== -1) {
        return 'Поле не может начинаться и заканчиваться тире';
    }

    return null;
}

export function descValidator(desc, required = true) {
    return textValidator(desc, 254, required);
}

export function emailValidator(email, required = true) {
    if (required && email.trim().length === 0) {
        return 'Поле обязательно для заполнения';
    }

    if (!required && (!email || email.trim().length === 0)) {
        return null;
    }

    for (const r of emailRegexs) {
        if (!r.test(email)) {
            return 'Неверный формат почты';
        }
    }

    let isAltError = true;

    for (const r of emailRegexAlternatives) {
        if (r.test(email)) {
            isAltError = false;
            break;
        }
    }

    if (isAltError) {
        return 'Неверный формат почты';
    }

    for (const r of emailRegexNegatives) {
        if (r.test(email)) {
            return 'Неверный формат почты';
        }
    }

    for (const r of emailLengthNegatives) {
        if (r.test(email)) {
            return 'Максимальная длина почты - 254 символа. Максимальная длина текста между точками - 64 символа';
        }
    }

    return null;
}

export function loginValidator(str) {
    if (!str || str.trim().length === 0) return 'Поле обязательно для заполнения';
    if (str.length < 3 || str.length > 254) return 'Максимальная длина логина 254 символа, минимальная длина логина 3 символа';
    if (!loginPassRe.test(str)) return 'Для ввода допустимы буквы латиницей, цифры, _';
    return null;
}

export function passwordValidator(password, required = true) {
    if (!required && (!password || password.trim().length === 0)) {
        return null;
    }

    // eslint-disable-next-line no-useless-escape
    if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9.,\-"'()«»!@#№$%^&*_+=?;:|\\\/~><\[\]{}]{8,16}$/.test(password)) {
        return 'Длина пароля должна быть от 8 до 16 символов, и он должен содержать как минимум одну прописную букву, одну заглавную букву и одну цифру.';
    }

    return null;
}

export function validateFields(data) {
    let result = {};

    for (const field of data) {
        const v = field.validator(field.value);

        if (v) {
            result[field.name] = v;
        }
    }

    return result;
}

export function urlValidator(url) {
    try {
        new URL(url);
        return true;
    } catch (e) {
        return false;
    }
}
