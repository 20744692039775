import React, { useCallback, useState } from 'react';
import { useGlobalContext } from 'lib/hooks';
import { useCustomMobileMenu } from 'lib/hooks/useCustomMobileMenu';
import Tree from 'components/trees/training-tree/training-tree';
import { TrainingNavigation } from 'components/training';
import { Icons } from 'uikit/icon';
import cx from './training-tab-catalog.module.scss';
import { EmptyTrainingThemeOrTest } from '../../projects/empty';

const themeEmptyButtons = [
    {
        icon: Icons.FOLDER_THEME,
        title: 'Создать тему',
        link: '/training/catalog/theme'
    },
    {
        icon: Icons.ARTICLE,
        title: 'Создать вопрос',
        link: '/training/catalog/question'
    }
];

export default function TrainingTabCatalog({ children }) {
    const { platform } = useGlobalContext();
    const [isReloadTree, setIsReloadTree] = useState(false);

    const reloadTree = () => setIsReloadTree(!isReloadTree);

    useCustomMobileMenu(useCallback((_onClose) => {
        return (<Tree isReload={isReloadTree}/>);
    }, [isReloadTree]));

    const childrenWithProps = React.cloneElement(children, {
        children: React.Children.map(children['props']['children'], child => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child, { reloadTree });
            }

            return child;
        })
    });

    return (
        <div className={cx.trainingCatalog}>
            {platform === 'desktop' &&
            <div className={cx.trainingCatalogTree}>
                <Tree isReload={isReloadTree}/>
            </div>}
            <div className={cx.trainingCatalogContainer}>
                <TrainingNavigation/>
                {childrenWithProps}
                {children['props']['location']['pathname'] === '/training/catalog' && (
                    <EmptyTrainingThemeOrTest buttons={themeEmptyButtons} />
                )}
            </div>
        </div>
    );
};
