import React from 'react';
import classNames from 'classnames';
import { idFromString } from 'lib/helpers';
import cx from './button.module.scss';

const Button = ({
    id = null,
    className = null,
    type = 'normal',
    btnType,
    color = 'base',
    size = 'medium',
    label = '',
    children = null,
    fullWidth = false,
    selected = false,
    disabled = false,
    onClick = () => {},
    ...attrs
}) => {
    const key = id || idFromString(label);
    const styles = classNames(cx[type], cx[color], { [cx.small]: size === 'small', [cx.medium]: size === 'medium' });

    return (
        <div className={classNames(cx.buttonContainer, className, {
            [cx.fullWidth]: fullWidth,
            [cx.disabled]: disabled,
            [cx.selected]: selected
        })}>
            <button id={key} className={styles} disabled={disabled} onClick={onClick} type={btnType} {...attrs}>
                {children ? children : label}
            </button>
        </div>
    );
};

export default Button;
