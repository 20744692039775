import React from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';
import TagsInput from 'react-tagsinput';
import Button from 'uikit/button';
import Checkbox from 'uikit/checkbox/checkbox';
import { Select } from 'uikit/select';
import TextArea from 'uikit/textarea';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import cs from './send-email.module.scss';
import { useGlobalContext } from 'lib/hooks';
import { desktopStyles, mobileStyles } from 'lib/util/modalStyles';

const options = [
    { label: 'Microsoft Word (DOCX)', value: 'docx' },
    { label: 'Документ PDF', value: 'pdf' },
    { label: 'Текст в формате TXT', value: 'txt' },
    { label: 'Текст в формате RTF', value: 'rtf' },
    { label: 'Microsoft Excel (XLS)', value: 'xls' }
];

// TODO refactoring along with Confirmation and SubscribeUpdates
const SendEmail = ({
    isOpen = false,
    onDismiss = () => {},
    onOk = () => {},
    color = 'green',
    okButtonText = 'Отправить',
    dismissButtonText = 'Отмена'
}) => {
    const { platform } = useGlobalContext();
    const [emails, setEmails] = React.useState([]);

    return (
        <Modal isOpen={isOpen} onRequestClose={onDismiss} contentLabel={'Отправка по электронной почте'}
               style={platform === 'mobile' ? mobileStyles : desktopStyles}>
            <div className={classNames(cs.modal, {[cs.mobile]: platform === 'mobile'})}>
                <div className={cs.head}>
                    <div className={cs.text}>Отправка по электронной почте</div>
                    <IconButton icon={<Icon type={Icons.CROSS} width={16} height={16}/>}
                                onClick={onDismiss}/>
                </div>
                <div className={cs.content}>
                    <div className={cs.fieldRow}>
                        <label className={cs.label}>Кому</label>
                        <div className={classNames(cs.inputWrapper, cs.withDesc)}>
                            <TagsInput inputProps={{ className: 'react-tagsinput-input', placeholder: 'Email..' }}
                                       value={emails} onChange={setEmails}/>
                        </div>
                        <span className={cs.description}>
                            Вы можете ввести несколько адресов через запятую или пробел
                        </span>
                    </div>
                    <div style={{ marginBottom: '1.5rem' }}>
                        <Checkbox label={'Отправить мне копию письма'}/>
                    </div>
                    <div className={cs.fieldRow}>
                        <Select label={'Выберите формат'} options={options} defaultValue={options[0]}/>
                    </div>
                    <TextArea label={'Комментарий к письму'}/>
                </div>
                <div className={cs.footer}>
                    <Button onClick={onDismiss} label={dismissButtonText} fullWidth={platform === 'mobile'}/>
                    <Button onClick={onOk} label={okButtonText} color={color} fullWidth={platform === 'mobile'}/>
                </div>
            </div>
        </Modal>
    );
};

export default SendEmail;
