import axios from 'axios';
import Cookies from 'js-cookie';
import { CurrentRouter } from 'shared/router';
import { ApiError } from 'lib/errors';
import api from 'api'
import { store } from 'store'; 
import { clearSession } from 'slice/authSlice';

export default class ApiAxios {
    logoutUrl = undefined;

    constructor({ prefix } = {}) {
        this.prefix = prefix;
        // this.setupInterceptors();
    }

    axios() {
        return axios;
    }

    setupInterceptors() {

        const logStyles = {
            orange: 'color: orange; font-weight: bold; font-size: 16px;',
            green: 'color: limegreen; font-weight: bold; font-size: 16px;',
            red: 'color: crimson; font-weight: bold; font-size: 16px;',
            blue: 'color: dodgerblue; font-weight: bold; font-size: 16px;'
        }

        axios.interceptors.response.use(
            response => response,
            async (error) => {
                console.log('setupInterceptors start')
                const { response, /*config*/ } = error;

                if (response /*&& config*/) {
                    // const savedRequest = localStorage.getItem('retryRequest');
                    // let retryCount = 0;

                    // if (savedRequest) {
                    //     const parsedRequest = JSON.parse(savedRequest);
                    //     if (parsedRequest.url === config.url) {
                    //         retryCount = parsedRequest._retryCount + 1;
                    //     }
                    // }

                    if (response.status === 401 /*|| response.status === 403*/) {
                    
                        // console.log(`%c📢 Пойман статус ${ response.status }. Ответ с бэкенда: `, logStyles.orange, response)

                        // localStorage.setItem('retryRequest', JSON.stringify({...config, _retryCount: retryCount}));

                        // config._retryCount++;

                        const loginUrl = response?.data?.loginUrl;
                        if (loginUrl) {
                            const alreadyRedirected = sessionStorage.getItem('alreadyRedirected');

                            if (alreadyRedirected === 'true') {
                                console.warn('%c✅ 401 статус уже был обработан ранее — пропускаем повторный редирект', logStyles.green);
                                return response;
                            }

                            // console.log(`%c📢 Редирект на loginUrl: `, logStyles.orange, loginUrl);
                            // // store.dispatch(clearSession());
                            // // await new Promise(resolve => setTimeout(resolve, 5000)); // delay

                            // // store.dispatch(clearSession());
                            // sessionStorage.setItem('alreadyRedirected', 'true');

                            // window.location.replace(loginUrl);

                            const { protocol, hostname, port, pathname } = window.location;

                            let base = `${protocol}//${hostname}`;
                            if (hostname === 'localhost' && port) base += `:${port}`;

                            const returnUrl = `${base}${pathname}`;
                            const encodedReturnUrl = encodeURIComponent(returnUrl);

                            const separator = loginUrl.includes('?') ? '&' : '?';
                            const loginUrlWithReturnUrl = `${loginUrl}${separator}returnUrl=${encodedReturnUrl}`;

                            // console.log(`%c📢 Редирект на loginUrl: `, logStyles.green, loginUrlWithReturnUrl);

                            // await new Promise(resolve => setTimeout(resolve, 30000));

                            sessionStorage.setItem('alreadyRedirected', 'true');
                            window.location.replace(loginUrlWithReturnUrl);

                            return;
                        }
                        else {
                            console.log(`%c🚨 Не удалось получить loginUrl из response`, logStyles.red);
                            return Promise.reject(new ApiError(response?.data, response?.status));
                        }
                    }
                }
                console.log('Promise.reject(new ApiError(response?.data, response?.status))')
                return Promise.reject(new ApiError(response?.data, response?.status));
            }
        )
    }

    get(url, query = {}, allResponse = false, responseType = '', headers, cancelToken) {
        return this.request({ method: 'get', url, query, responseType, headers, cancelToken }, allResponse);
    }

    put(url, data = {}) {
        return this.request({ method: 'put', url, data });
    }

    patch(url, data = {}) {
        return this.request({ method: 'patch', url, data });
    }

    post(url, data = {}, allResponse = false, responseType = '', headers, cancelToken) {
        return this.request({ method: 'post', url, data, responseType, headers, cancelToken }, allResponse);
    }

    deleteRequest(url) {
        return this.request({ method: 'delete', url });
    }

    upload(url, formData, onUploadProgress, cancelToken) {
        return this.request({
            method: 'post', url, data: formData, onUploadProgress, cancelToken, headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    getHeaders() {
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Timezone': (new Date()).getTimezoneOffset()
        };

        const xsrfToken = Cookies.get('XSRF-TOKEN');

        if (xsrfToken) {
            headers['X-XSRF-TOKEN'] = xsrfToken;
        }

        return headers;
    }

    request({ url, method, query = {}, data = {}, responseType = '', headers = {}, onUploadProgress, cancelToken }, allResponse = false) {
        const config = {
            baseURL: this.prefix,
            url,
            method,
            headers: { ...this.getHeaders(), ...headers },
            data,
            params: query,
            responseType,
            validateStatus: function(status) {
                // return status <= 500;
                return status <= 500 && status !== 401 /*&& status !== 403*/;
            },
            onUploadProgress,
            cancelToken
        };

        return axios(config)
            .then(res => {
                if (res.status >= 400 && res.status !== 401 /*&& res.status !== 403*/) {
                    throw new ApiError(res.data, res.status);
                }

                // Check response null values array function;
                const proceedArray = (array) => {
                    array.map(p => {
                        if (Object.prototype.toString.call(p) === '[object Array]') {
                            proceedArray(p);
                        } else if (Object.prototype.toString.call(p) === '[object Object]') {
                            proceedObject(p);
                        }

                        return p;
                    });
                };

                // Check response null values object function;
                const proceedObject = (object) => {
                    Object.keys(object).map(p => {
                        if (Object.prototype.toString.call(object[p]) === '[object Array]') {
                            proceedArray(object[p]);
                        } else if (Object.prototype.toString.call(object[p]) === '[object Object]') {
                            proceedObject([p]);
                        } else if (object[p] === null && ['author', 'user'].includes(p)) {
                            object[p] = {};
                        }

                        return p;
                    });
                };

                // Check response null values;
                if (Object.prototype.toString.call(res.data) === '[object Array]') {
                    proceedArray(res.data);
                } else if (Object.prototype.toString.call(res.data) === '[object Object]') {
                    proceedObject(res.data);
                }

                if (allResponse) {
                    return res;
                } else {
                    return res.data;
                }
            }).catch(e => {
                if (axios.isCancel(e)) {
                    return;
                }

                let error;

                try {
                    console.log("error = e.name === 'ApiError' ? e : new ApiError(e.response.data, e.response.status)")
                    error = e.name === 'ApiError' ? e : new ApiError(e.response.data, e.response.status);
                } catch (e) {
                    console.log('error = new ApiError()')
                    error = new ApiError();
                }

                // if (this.implementationApi === 2 || this.implementationApi === 4) {
                //     if ((e.name === 'Error' && config.url.indexOf('/kms/api/v1/files/upload') === -1)
                //         || (e.responseCode && e.responseCode === 401 && config.url !== 'roles/info')) {
                //         console.log('401 Redirect', ApiAxios.logoutUrl);
    
                //         if (window.location.pathname !== '/login') {
                //             if (process.env.NODE_ENV === 'development') {
                //                 document.location.replace('/login');
                //             } else {
                //                 if (ApiAxios.logoutUrl) {
                //                     document.location.replace(ApiAxios.logoutUrl);
                //                 } else {
                //                     document.location.reload();
                //                 }
                //             }
                //         }
                //     }
                // }

                if (e.responseCode && e.responseCode === 423) {
                    console.log('423 Redirect');

                    if (e.errorData.error === 'NO_LICENSE') {
                        CurrentRouter.getNavigateFunction()('/login-license');
                    } else {
                        CurrentRouter.getNavigateFunction()('/multiply');
                    }
                }

                throw error;
            });
    }
}
