import React from 'react';
import { MenuButton } from './MenuButton';
import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';

export const MenuButtonOrderedList = ({ label, _hideMenu }) => {
    const editor = useEditorContext();

    // const toggleOrderedList = () => {
    //     editor?.chain().focus().toggleOrderedList().run();
    //     hideMenu?.();
    // };
    return (
        <MenuButton
            tooltipLabel={label}
            icon={Icons.EditorIconListNumber}
            // onClick={toggleOrderedList}
            label={label}
            style={{ whiteSpace: 'nowrap', display: 'flex', minWidth: 'initial' }}
            disabled={!editor?.isEditable}
        ></MenuButton>
    );
};
