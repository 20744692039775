import { useRef, useCallback, useLayoutEffect } from 'react';

export const useControlledInputOnChangeCursorFix = callback => {
    const inputCursor = useRef(0);
    const inputRef = useRef(null);

    const newCallback = useCallback(
        (value, e) => {
            inputCursor.current = e.target.selectionStart;
            if (e.target.type === 'text') {
                inputRef.current = e.target;
            }
            callback(value, e);
        },
        [callback]
    );

    useLayoutEffect(() => {
        if (inputRef.current) {
            inputRef.current.setSelectionRange(inputCursor.current, inputCursor.current);
        }
    });

    return newCallback;
};

