import React, { useEffect, useState } from 'react';
import { default as ReactTimePicker } from 'react-time-picker';
import classNames from 'classnames';
import Icon, { Icons } from 'uikit/icon';
import cx from './styles.module.scss';
import './styles.css';

export const TimePicker = ({
    onChange = () => {},
    value,
    disabled,
    error,
    className = '',
    label,
    customRef,
    name,
    defaultValue
}) => {
    const [time, setTime] = useState(defaultValue ? defaultValue : value ?? '');
    const [selfValue, setSelfValue] = useState(defaultValue ? defaultValue : value || '');

    const onChangeSelf = (data) => {
        setSelfValue(data);
        setTime(data ?? '');
        onChange(data);
    };

    useEffect(() => {
        if (value !== 'undefined' && value !== undefined) {
            if (value !== selfValue) {
                setSelfValue(value);
            }

            if (value !== time) {
                setTime(value ?? '');
            }
        }
    }, [value, selfValue, time]);

    return (
        <div style={{ width: '100%' }} className={classNames({ error: !!error }, className)}>
            {label && <div className={cx.label}>{label}</div>}
            <ReactTimePicker onChange={(data) => onChangeSelf(data)} value={selfValue}
                             className={selfValue ? '' : 'react-calendar__empty'} disabled={disabled}
                             calendarIcon={<Icon type={Icons.DATE_CALENDAR} width={14} height={15}/>} clearIcon={null}/>
            <input type='text' name={name} defaultValue={time} ref={customRef} style={{ display: 'none' }}/>
            {error && <div className={cx.error}>{error}</div>}
        </div>
    );
};
