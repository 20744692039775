import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { parse } from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { setDocumentTitle, useNavigate } from 'shared/router';
import { useEventListener, useGlobalContext, useMessage } from 'lib/hooks';
import { selectFilters, setAuthors, setTags, setTimePeriod, setWhereLooking, setProjects } from 'slice/globalSearchSlice';
import Button from 'uikit/button';
import Loading from 'uikit/loading';
import Icon, { Icons } from 'uikit/icon';
import FilterIndicator from 'uikit/filterIndicator/filterIndicator';
import { ContentWrapper } from 'containers/content-wrapper';
import api from 'api';
import cx from './search-result-page.module.scss';
import FilesList from '../components/files-list';
import DocumentsList from '../components/documents-list';

const resultSize = 15;

export default function SearchResultPage() {
    const navigate = useNavigate();
    const { addError } = useMessage();

    const store = useSelector(selectFilters);
    const dispatch = useDispatch();

    const { platform } = useGlobalContext();
    const [loading, setLoading] = useState(true);
    const [fileLoading, setFileLoading] = useState(true);

    const [searchResult, setSearchResult] = useState(null);
    const [searchQuery, setSearchQuery] = useState({});

    const [orderSort, setOrderSort] = useState(false);
    const [typeSort, setTypeSort] = useState('По умолчанию');

    const [pseudoQuery, setPseudoQuery] = useState('');
    const [force, setForce] = useState(false);

    const [fileOrderSort, setFileOrderSort] = useState(false);
    const [fileTypeSort, setFileTypeSort] = useState('По умолчанию');

    const homePage = () => {
        navigate('/');
    };

    const deleteTag = index => {
        let tags = [...store.tags];

        if (tags.length === 1) {
            tags = [];
            dispatch(setTags(tags));

            return;
        }

        tags.splice(index, 1);
        dispatch(setTags(tags));
    };
    const deleteTimePeriod = () => {
        dispatch(setTimePeriod(null));
    };
    const deleteAuthor = index => {
        let authors = Object.assign([], store.authors);

        if (authors.length === 1) {
            authors = [];
        } else {
            authors.splice(index, 1);
        }

        dispatch(setAuthors(authors));
    };
    const deleteProject = index => {
        let projects = Object.assign([], store.projects);

        if (projects.length === 1) {
            projects = [];
        } else {
            projects.splice(index, 1);
        }

        dispatch(setProjects(projects));
    };
    const deleteWhereLooking = data => {
        let whereLookingCopy = { ...store.whereLooking };
        whereLookingCopy[data] = false;

        if (
            whereLookingCopy.includeNews === false &&
            whereLookingCopy.includeFiles === false &&
            whereLookingCopy.includeArticles === false &&
            whereLookingCopy.includeArchivedNews === false &&
            whereLookingCopy.includeArchivedArticles === false
        ) {
            whereLookingCopy = {
                includeNews: true,
                includeArticles: true,
                includeArchivedNews: true,
                includeArchivedArticles: true,
                includeFiles: true,
            };
        }

        dispatch(setWhereLooking(whereLookingCopy));
    };

    const getWhereLooking = () => {
        let whereLookingNames = ['includeNews', 'includeArticles', 'includeArchivedNews', 'includeArchivedArticles', 'includeFiles'];
        let whereLookingNamesRu = ['По новостям', 'По статьям', 'По архивным новостям', 'По архивным статьям', 'По файлам'];
        let whereLookingCopy = { ...store.whereLooking };
        let changed = false;
        let whereLooking = [];
        for (let i = 0; i < whereLookingNames.length; i++) {
            if (whereLookingCopy[whereLookingNames[i]] === false) {
                changed = true;
                break;
            }
        }
        if (changed) {
            for (let i = 0; i < whereLookingNames.length; i++) {
                if (whereLookingCopy[whereLookingNames[i]] === true) {
                    whereLooking.push({
                        whereLookingNames: whereLookingNames[i],
                        whereLookingNamesRu: whereLookingNamesRu[i],
                    });
                }
            }
        }
        return whereLooking;
    };
    const query = useCallback(
        async force => {
            setForce(false);
            setLoading(true);
            const searchParams = parse(window.location.search);

            let query = {
                query: searchParams['query'],
                resourceStatusFilter: [],
                page: 0,
                size: resultSize,
                authorIds: [],
                projectIds: [],
                keywords: store['tags'],
                sort: [
                    {
                        field: 'createTime',
                        order: 'DESC',
                    },
                ],
            };

            if (force) {
                setForce(true);
                query.force = true;
            }

            if (store.timePeriod) {
                query.dateRange = { range: store.timePeriod.value };
            }

            for (let i = 0; i < store.authors.length; i++) {
                query.authorIds.push(store.authors[i].value);
            }

            for (let i = 0; i < store.projects.length; i++) {
                query.projectIds.push(store.projects[i].value);
            }

            // if (searchParams['timePeriod'] || searchParams['fromDate'] || searchParams['toDate'])
            //     query.dateRange = {};
            //
            // if (searchParams['timePeriod']) {
            //     query.dateRange.range = searchParams['timePeriod'];
            // }
            //
            // if (searchParams['fromDate']) {
            //     query.dateRange.from = searchParams['timePeriod'];
            // }
            //
            // if (searchParams['toDate']) {
            //     query.dateRange.to = searchParams['timePeriod'];
            // }

            // if (store.whereLooking === 'Everywhere') {
            //     query.resourceStatusFilter = [
            //         {status: ['ACTIVE', 'ARCHIVED'], resourceType: 'NEWS'},
            //         {status: ['ACTIVE', 'ARCHIVED'], resourceType: 'ARTICLE'},
            //         {status: ['ACTIVE', 'ARCHIVED'], resourceType: 'FILE'},
            //         {status: ['ACTIVE', 'ARCHIVED'], resourceType: 'PROJECT'},
            //         {status: ['ACTIVE', 'ARCHIVED'], resourceType: 'SECTION'}
            //     ]
            // } else if (store.whereLooking === 'SearchNewsOnly') {
            //     query.resourceStatusFilter = [{
            //         status: ['ACTIVE'],
            //         resourceType: 'NEWS'
            //     }];
            // } else if (store.whereLooking === 'SearchOnlyInTheArchive') {
            //     query.resourceStatusFilter = [
            //         {status: ['ARCHIVED'], resourceType: 'NEWS'},
            //         {status: ['ARCHIVED'], resourceType: 'ARTICLE'},
            //         {status: ['ARCHIVED'], resourceType: 'FILE'},
            //         {status: ['ARCHIVED'], resourceType: 'PROJECT'},
            //         {status: ['ARCHIVED'], resourceType: 'SECTION'}
            //     ]
            // }

            if (store.whereLooking.includeNews || store.whereLooking.includeArchivedNews) {
                query.resourceStatusFilter.push({
                    status: [],
                    resourceType: 'NEWS',
                });

                if (store.whereLooking.includeNews) {
                    query.resourceStatusFilter[query.resourceStatusFilter.length - 1].status.push('ACTIVE');
                }

                if (store.whereLooking.includeArchivedNews) {
                    query.resourceStatusFilter[query.resourceStatusFilter.length - 1].status.push('ARCHIVED');
                }
            }

            if (store.whereLooking.includeArticles || store.whereLooking.includeArchivedArticles) {
                query.resourceStatusFilter.push({
                    status: [],
                    resourceType: 'ARTICLE',
                });

                if (store.whereLooking.includeArticles) {
                    query.resourceStatusFilter[query.resourceStatusFilter.length - 1].status.push('ACTIVE');
                }

                if (store.whereLooking.includeArchivedArticles) {
                    query.resourceStatusFilter[query.resourceStatusFilter.length - 1].status.push('ARCHIVED');
                }
            }

            if (store.whereLooking.includeFiles) {
                query.resourceStatusFilter.push({
                    status: ['ACTIVE'],
                    resourceType: 'FILE',
                });
            }

            if (typeSort === 'По дате добавления') {
                query.sort[0].field = 'createTime';
            } else if (typeSort === 'По дате изменения') {
                query.sort[0].field = 'modifyTime';
            } else if (typeSort === 'По автору') {
                query.sort[0].field = 'author.id';
            } else if (typeSort === 'По популярности') {
                query.sort[0].field = 'openCount';
            } else if (typeSort === 'По количеству лайков') {
                query.sort[0].field = 'likesCount';
            } else if (typeSort === 'По количеству дизлайков') {
                query.sort[0].field = 'dislikesCount';
            } else if (typeSort === 'По количеству прочтений') {
                query.sort[0].field = 'readCount';
            }

            if (orderSort) {
                query.sort[0].order = 'ASC';
            } else {
                query.sort[0].order = 'DESC';
            }

            if (typeSort === 'По умолчанию') {
                delete query.sort;
            }

            if (searchParams['mode']) {
                query['searchAlgorithm'] = searchParams['mode'];
            }

            setSearchQuery(query);

            if (store.init) {
                try {
                    const searchResultTmp = await api.search.search(query);

                    if (!force) {
                        setPseudoQuery(searchResultTmp['pseudoQuery']);
                    }
                    setSearchResult(() => {
                        const result = structuredClone(searchResultTmp)

                        const articles = searchResultTmp['articles'].filter(p => p['status'] !== 'ARCHIVED');
                        const archivedArticles = searchResultTmp['articles'].filter(p => p['status'] === 'ARCHIVED');

                        const exactAnswers = searchResultTmp['exactAnswers'];

                        const globalNews = searchResultTmp['news'].filter(p => p['status'] !== 'ARCHIVED' && p['breadcrumbs'].length === 0);
                        const archivedGlobalNews = searchResultTmp['news'].filter(p => p['status'] === 'ARCHIVED' && p['breadcrumbs'].length === 0);

                        const news = searchResultTmp['news'].filter(p => p['status'] !== 'ARCHIVED' && p['breadcrumbs'].length !== 0);
                        const archivedNews = searchResultTmp['news'].filter(p => p['status'] === 'ARCHIVED' && p['breadcrumbs'].length !== 0);

                        result['hits'] = articles.concat(globalNews, news, archivedArticles, archivedGlobalNews, archivedNews, exactAnswers);
                        return result;
                    });
                } catch (e) {
                    console.log(e);
                    addError('Сервис недоступен. Пожалуйста попробуйте позже.');
                } finally {
                    setLoading(false);
                }
            }
        },
        [addError, orderSort, typeSort, store]
    );

    const onScroll = async e => {
        if (e.target.scrollTop + e.target.clientHeight + 1 >= e.target.scrollHeight) {
            if (
                searchResult['resourceTypeCount']['ARTICLE'] + searchResult['resourceTypeCount']['NEWS'] >
                (searchQuery['page'] + 1) * resultSize
            ) {
                try {
                    searchQuery['page'] += 1;
                    setSearchQuery(searchQuery);

                    const searchResultTmp = await api.search.search(searchQuery);
                    searchResult['hits'] = searchResult['hits'].concat(searchResultTmp['articles'], searchResultTmp['news']);

                    const articles = searchResult['hits'].filter(p => p['type'] === 'ARTICLE' && p['status'] !== 'ARCHIVED');
                    const archivedArticles = searchResult['hits'].filter(p => p['type'] === 'ARTICLE' && p['status'] === 'ARCHIVED');

                    const news = searchResult['hits'].filter(p => p['type'] === 'NEWS' && p['status'] !== 'ARCHIVED');
                    const archivedNews = searchResult['hits'].filter(p => p['type'] === 'NEWS' && p['status'] === 'ARCHIVED');

                    searchResultTmp['hits'] = articles.concat(news, archivedArticles, archivedNews);
                    setSearchResult(JSON.parse(JSON.stringify(searchResult)));
                } catch {
                    // no action required
                }
            }
        }
    };
    const onLoadFiles = useCallback(
        async (isFull = false) => {
            setFileLoading(true);
            const query = Object.assign({}, searchQuery);

            try {
                if (searchResult) {
                    if (!isFull && searchResult['files']?.length <= 4) {
                        query['size'] = 4;
                    } else {
                        query['size'] = searchResult['resourceTypeCount']['FILE'];
                    }
                }
            } catch (e) {
                console.log(e);
            }

            query['resourceStatusFilter'] = [{ status: ['ACTIVE'], resourceType: 'FILE' }];
            query['sort'] = [{ field: 'createTime', order: 'DESC' }];

            if (fileTypeSort === 'По дате добавления') {
                query['sort'][0]['field'] = 'createTime';
            } else if (fileTypeSort === 'По дате изменения') {
                query['sort'][0]['field'] = 'modifyTime';
            } else if (fileTypeSort === 'По автору') {
                // query['sort']['field'] = 'author';
            } else if (fileTypeSort === 'По популярности') {
                // query['sort']['field'] = field: 'createTime'
            }

            if (fileOrderSort) {
                query['sort'][0]['order'] = 'ASC';
            } else {
                query['sort'][0]['order'] = 'DESC';
            }

            if (fileTypeSort === 'По умолчанию') {
                delete query['sort'];
            }

            query['page'] = 0;

            try {
                const searchResultTmp = await api.search.search(query);
                if (searchResult && searchResultTmp['files']) {
                    searchResult['files'] = searchResultTmp['files'];
                    setSearchResult(searchResult);
                }
            } catch (e) {
                console.log(e);
                addError('Сервис недоступен. Пожалуйста попробуйте позже.');
            } finally {
                setFileLoading(false);
            }
        },
        [addError, fileTypeSort, fileOrderSort, searchQuery, searchResult]
    );

    useEventListener('scroll', onScroll, document.getElementById('content'));

    useEffect(() => {
        query(false);
    }, [query]);
    useEffect(() => {
        onLoadFiles(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileTypeSort, fileOrderSort]);

    useEffect(() => {
        setDocumentTitle('Поиск — KMS Gran');
    }, []);

    return (
        <ContentWrapper>
            <Loading active={loading || fileLoading} withOverlay />
            <div className={classNames(cx.search, { [cx.mobile]: platform === 'mobile' })}>
                <div className={cx.heading}>
                    <p className={cx.searchTitle}>
                        Результаты поиска по запросу — «
                        <span>
                            {searchResult
                                ? searchResult.pseudoQuery
                                    ? searchResult.pseudoQuery
                                    : searchResult.query
                                : searchQuery['query']}
                        </span>
                        »
                    </p>
                    {force && (
                        <p className={cx.searchInstead}>
                            Искать вместо этого «<span onClick={() => query()}>{pseudoQuery}</span>»
                        </p>
                    )}
                    {!force && searchResult && searchResult['pseudoQuery'] && (
                        <p className={cx.searchInstead}>
                            Искать вместо этого <span onClick={() => query(true)}>{searchResult.query}</span>
                        </p>
                    )}
                    {force && <p className={cx.searchCount}>Найдено совпадений: {searchResult?.total}</p>}
                    {!force && searchResult && searchResult?.total !== 0 && (
                        <p className={cx.searchCount}>Найдено совпадений: {searchResult?.total}</p>
                    )}
                </div>
                <div className={cx.filters}>
                    {store !== null && store.timePeriod !== null && (
                        <span>
                            <FilterIndicator label="Дата создания" text={store.timePeriod.label} onRemove={() => deleteTimePeriod()} />
                        </span>
                    )}
                    {store !== null && store.projects.map((item, index) => (
                        <span key={item.value}>
                            <FilterIndicator
                                label="Проект"
                                text={item.label}
                                onRemove={() => deleteProject(index)}
                            />
                        </span>
                    ))}
                    {store !== null && store.tags.map((item, index) => (
                        <span>
                            <FilterIndicator
                                label="Теги"
                                text={item.name}
                                onRemove={() => deleteTag(index)}
                            />
                        </span>
                    ))}
                    {store !== null && store.authors.map((item, index) => (
                        <span>
                            <FilterIndicator
                                label="Автор"
                                text={item.label}
                                onRemove={() => deleteAuthor(index)}
                            />
                        </span>
                    ))}
                    {store !== null && getWhereLooking().map((item) => (
                        <span>
                            <FilterIndicator
                                label="Искать"
                                text={item.whereLookingNamesRu}
                                onRemove={() => deleteWhereLooking(item.whereLookingNames)}
                            />
                        </span>
                    ))}
                </div>
                <div id='searchResultPageResultContainer' className={cx.searchResultContainer}>
                    {!loading && !fileLoading && searchResult !== null && searchResult.total !== 0 && (
                        <>
                            <FilesList
                                files={searchResult['files']}
                                typeSort={fileTypeSort}
                                orderSort={fileOrderSort}
                                resourceTypeCount={searchResult['resourceTypeCount']['FILE']}
                                sortTypeChange={setFileTypeSort}
                                orderTypeChange={setFileOrderSort}
                                onLoadFiles={onLoadFiles}
                            />
                            <DocumentsList
                                documents={searchResult['hits']}
                                typeSort={typeSort}
                                orderSort={orderSort}
                                onTypeSortChange={setTypeSort}
                                onOrderSortChange={setOrderSort}
                            />
                        </>
                    )}

                    {!loading && !fileLoading && searchResult?.total === 0 && (
                        <div className={cx.errorSearch}>
                            <div className={cx.errorImg}>
                                <Icon type={Icons.WARNING} height={43} width={38} />
                            </div>
                            <div className={cx.errorText}>
                                <span>
                                    Поиск по данному запросу не дал результатов. Вернитесь на главную страницу или попробуйте изменить
                                    запрос и выполнить поиск заново
                                </span>
                            </div>
                            <div className={cx.homeButton}>
                                <Button className={cx.button} color="blue" onClick={() => homePage()}>
                                    <Icon type={Icons.JUMP_LEFT} height={10} width={12} />
                                    <span className={cx.textButton}>Перейти на главную</span>
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </ContentWrapper>
    );
}
