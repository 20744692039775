import React from 'react';
import classNames from 'classnames';
import cx from './button-group.module.scss';

const ButtonGroup = ({ position = 'left', type = 'normal', children = null }) => {
    return (
        <div className={classNames(cx.buttonGroup, cx[position], cx[type])}>{children}</div>
    );
};

export default ButtonGroup;
