import React from 'react';
import { Link, useNavigate } from 'shared/router';
import { useDialog, useMessage, usePermittedActions } from 'lib/hooks';
import { perm, RESOURCE_TYPE } from 'model/resource';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button/icon-button';
import Confirmation from 'components/confirmation';
import api from 'api';
import cx from './project.module.scss';
import { TreePropContext } from 'containers/side-tree-wrapper';
import ButtonMenu from 'uikit/button-menu';
import { useConfiguration } from '../../lib/context';

const Project = ({
    id = '',
    avatar = '',
    title = '',
    description = '',
    manager = '',
    tab,
    onTabChange,
    handleOnDelete,
    isMobile
}) => {
    const navigate = useNavigate();
    const { checkPermission } = usePermittedActions(RESOURCE_TYPE.PROJECT, id);

    const { isScriptingEnabled } = useConfiguration();

    const { dialogState, openDialog, closeDialog } = useDialog();
    const { addError, addSuccess } = useMessage();

    const { reloadTree } = React.useContext(TreePropContext);

    const p = {
        delete: checkPermission(perm.document.PROJECT_DELETE_REQUEST),
        unarchive: checkPermission(perm.document.RESOURCE_ARCHIVE_RESTORE)
    }

    const onUnArchive = async () => {
        const text = (
            <span>
              Вы действительно хотите восстановить из архива проект&nbsp;
              <span style={{ color: '#279BD9' }}>
                  {title}
              </span> ?
          </span>
        )

        openDialog({
            title: 'Восстановление',
            text,
            onSubmit: () => unArchive(),
            onClose: closeDialog,
            contentType: 'TEXT'
        });
    };

    const unArchive = async () => {
        try {
            await api.archive.moveProjectFromArchive(id);
            addSuccess('Проект восстановлен из архива');
            handleOnDelete(id);
        } catch (e) {
            addError('Не удалось восстановить проект из архива');
        }
    };

    const remove = async () => {
        try {
            await api.project.deleteProject(id);

            // const node = tree.getNodeById(id);
            // tree.removeNode(node);
            // tree.selectNode(null);
            // setScrollToNode(null);

            reloadTree();

            addSuccess('Проект удалён');
            handleOnDelete(id)
            navigate('/archive');
        } catch (e) {
            addError('Не удалось удалить проект');
        }
    };

    const onRemove = async () => {
        const text = (
            <span>
              Вы действительно хотите удалить проект&nbsp;
              <span style={{ color: '#279BD9' }}>
                  {title}
              </span> ?
              Этот процесс нельзя будет отменить
          </span>
        )

        openDialog({
            color: 'red',
            title: 'Удаление',
            text,
            onSubmit: () => remove(),
            onClose: closeDialog,
            contentType: 'TEXT'
        });
    };

    return (
        <>
            <Confirmation {...dialogState} />
            {!isMobile && (
                <div className={cx.project}>
                    <div className={cx.head}>
                        <div className={cx.avatar}>
                            {avatar && <img alt="" src={avatar} />}
                            {!avatar && <img alt="" src={'/img/default-icon-project.jpg'} />}
                        </div>
                        <div className={cx.body}>
                            <div className={cx.title}>
                                <h1>{title}</h1>
                                <div className={cx.navigation}>
                                    {p.unarchive && (
                                        <IconButton
                                            icon={<Icon type={Icons.RELOAD} tooltip='Разархивировать' width={13} height={12} />}
                                            onClick={onUnArchive}
                                        />
                                    )}
                                    {p.delete && (
                                        <IconButton
                                            icon={<Icon type={Icons.TRASH} tooltip='Удалить' width={14} height={15} />}
                                            onClick={onRemove}
                                            hoverColor='red'
                                        />
                                    )}
                                </div>
                            </div>
                            {description && <p>{description}</p>}
                            {manager &&
                                <div className={cx.manager}>
                                    <span>Руководитель проекта</span>
                                    <Link to='/'>{manager}</Link>
                                </div>}
                        </div>
                    </div>
                </div>
            )}

            {isMobile && (
                <div className={cx.mobileProject}>
                    <div className={cx.titleBlock}>
                        <div id={'projectAvatar'} className={cx.avatar}>
                            {avatar ? <img alt={''} src={avatar} /> :
                                <img alt={''} src={'/img/default-icon-project.jpg'} />}
                        </div>
                        <h1 id={'project-title'} className={cx.title}>{title}</h1>
                    </div>
                    {description && <p id={'project-description'} className={cx.desc}>{description}</p>}
                    {manager && (
                        <div className={cx.manager}>
                            <span>Руководитель проекта</span>
                            <Link to={'/'}>{manager}</Link>
                        </div>
                    )}
                    <ButtonMenu
                        className={cx.navigationMenu}
                        items={[
                            p.unarchive && {
                                title: 'Разархивировать',
                                icon: Icons.RELOAD,
                                onClick: onUnArchive
                            },
                            p.delete && {
                                title: 'Удалить',
                                icon: Icons.TRASH,
                                onClick: onRemove
                            }
                        ].filter(Boolean)}
                    />
                </div>
            )}

            <ul id={'archiveProjectMenu'} className={cx.projectMenu}>
                <li className={tab === 'articles' ? cx.active : ''}
                    onClick={() => onTabChange('articles')}>Статьи
                </li>
                <li className={tab === 'news' ? cx.active : ''}
                    onClick={() => onTabChange('news')}>Новости
                </li>
                {isScriptingEnabled && (
                    <li id={cx.scriptsTab} className={tab === 'scripts' ? cx.active : ''}
                        onClick={() => onTabChange('scripts')}>Скрипты
                    </li>
                )}
            </ul>
        </>
    );
};

export default Project;
