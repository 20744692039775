import { useCallback } from 'react';
import { toast } from 'react-toastify';
import CustomToast from '../../uikit/toast/custom-toast';

export function useMessage() {
    const addSuccess = useCallback((text) => {
        toast(CustomToast, {
            closeButton: false,
            hideProgressBar: true,
            data: {
                text: text,
                appearance: 'success'
            }
        });
    }, []);

    const addInfo = useCallback((text) => {
        toast(CustomToast, {
            closeButton: false,
            hideProgressBar: true,
            data: {
                text: text,
                appearance: 'info'
            }
        });
    }, []);

    const addError = useCallback((text) => {
        toast(CustomToast, {
            closeButton: false,
            hideProgressBar: true,
            data: {
                text: text,
                appearance: 'error'
            }
        });
    }, []);

    return { addSuccess, addInfo, addError };
}
