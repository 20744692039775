import React from 'react';
import classNames from 'classnames';
import cx from './loading.module.scss';

const Loading = ({
    active = true,
    className,
    withOverlay = true,
    small = false,
    description = 'Active loading indicator',
    withRelativeOverlay = false,
    size = undefined,
    style,
    ...props
}) => {
    const loadingClasses = classNames(cx.loading, className, {
        [cx.loadingSmall]: small,
        [cx.loadingStop]: !active
    });

    const loadingStyles = {
        width: size,
        height: size,
        ...style,
    }

    const overlayClasses = classNames(cx.loadingOverlay, {
        [cx.loadingOverlayStop]: !active
    });

    const relativeOverlayClasses = classNames(cx.loadingOverlay, cx.loadingRelativeOverlay, {
        [cx.loadingOverlayStop]: !active
    });

    const loading = (
        <div {...props} aria-label={description} aria-live={active ? 'assertive' : 'off'} className={loadingClasses} style={loadingStyles}>
            <svg className={cx.loading__svg} viewBox="-75 -75 150 150">
                <title>Loading</title>
                {small && <circle className={cx.loading__background} cx="0" cy="0" r="37.5"/>}
                <circle className={cx.loading__stroke} cx="0" cy="0" r="37.5"/>
            </svg>
        </div>
    );

    if (withOverlay) {
        return <div className={overlayClasses}>{loading}</div>;
    } else if (withRelativeOverlay) {
        return <div className={relativeOverlayClasses}>{loading}</div>;
    } else {
        return loading;
    }
};

export default Loading;
