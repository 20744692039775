import { configureStore } from '@reduxjs/toolkit';
import appReducer from 'slice/appSlice';
import treeReducer from 'slice/treeSlice';
import newsPickerReducer from 'slice/newsPickerSlice';
import commentsReducer from 'slice/commentsSlice';
import authReducer from 'slice/authSlice';
import customRolesReducer from 'slice/customRolesSlice';
import reportsReducer from 'slice/reportsSlice';
import globalSearchReducer from 'slice/globalSearchSlice';

export const store = configureStore({
    reducer: {
        app: appReducer,
        tree: treeReducer,
        newsPicker: newsPickerReducer,
        comments: commentsReducer,
        auth: authReducer,
        customRoles: customRolesReducer,
        reports: reportsReducer,
        globalSearch: globalSearchReducer,
    },
});

export default store; // Оставляем дефолтный экспорт для совместимости
