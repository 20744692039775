import ApiClient from '../../api/api-axios';

export default class NotificationApi extends ApiClient {
    getAll = async (page = 0, size = 500) => {
        return await this.get(`/notifications?page=${page}&size=${size}&sort=id,desc`);
    };

    getUnread = async (page = 0, size = 2000, system = false) => {
        return await this.get(`/notifications/unread?page=${page}&size=${size}&sort=id,desc&system=${system}`);
    };

    getUnreadCount = async () => {
        return await this.get(`/notifications/unread/count`);
    };

    readAll = async () => {
        return await this.put('/notifications/all/read');
    };

    read = async (notificationId) => {
        return await this.put(`/notifications/${notificationId}/read`);
    };
}
