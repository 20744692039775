import React, { useRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import Modal from 'react-modal';
import { cloneDeep } from 'lodash';
import { Link } from 'shared/router';
import { useClickOutside } from 'lib/hooks/useClickOutside';
import { Select, SelectSearch } from 'uikit/select';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button/icon-button';
import Input from 'uikit/input/input';
import RangeSlider from 'uikit/range-slider';
import Button from 'uikit/button';
import { DatePicker } from 'uikit/datetime';
import cx from './filter.module.scss';
import { useGlobalContext, useMediaQuery } from 'lib/hooks';
import { AdaptiveLink } from 'containers/adaptive-link/adaptive-link';
import RadioButton, { RadioGroup } from 'uikit/radio-button';
import { SingleValueWithImg, OptionWithImg } from 'uikit/select/components/select-components-with-img';

const DEFAULT_FILTERS_DATA = {};

const AddBtn = ({
    link,
    title,
    onClick,
    isInline = false,
    id="",
}) => {
    return (
        <AdaptiveLink link={link} className={isInline ? cx.addBtnWrapper : ''}>
            <button className={classNames(cx.addBtn, isInline && cx['addBtn--mobile-inline'])} onClick={onClick} data-testid={id + "Mobile"}>
                <div className={cx.icon}>
                    <Icon type={Icons.ADD} width={8} height={8}/>
                </div>
                {title}
            </button>
        </AdaptiveLink>
    )
}

const Filter = ({
    id = '',
    search = '',
    searchText = '',
    onSearch = () => {},
    add = '',
    onAdd = () => {},
    filters = [],
    filtersData = DEFAULT_FILTERS_DATA,
    onFilters = () => {},
    sort,
    sortOptions,
    onSort,
    addLink = '',
    onImport = null,
    onExport = null,
    selectOptions = null,
    selectOption = null,
    onSelect = () => {},
    buttons = [],
    isLoading,
    addMobile = '',
}) => {
    const { platform } = useGlobalContext();
    const modalRef = useRef(null);

    const [isInit, setIsInit] = useState(false);
    const [isFilters, setIsFilters] = useState(false);
    const [isSort, setIsSort] = useState(false);
    const [isUseSlider, setIsUseSlider] = useState(true);

    const [searchTemp, setSearchTemp] = useState(searchText);
    const [filtersDataTemp, setFiltersDataTemp] = useState({});

    const onChangeFilters = (field, value) => {
        const filtersDataCopy = Object.assign({}, filtersDataTemp);

        filtersDataCopy[field['name']] = value;
        onFilters(filtersDataCopy);
    };
    const onChangeFiltersObject = (field, key, value) => {
        const filtersDataCopy = Object.assign({}, filtersDataTemp);

        if (!filtersDataCopy[field['name']]) {
            filtersDataCopy[field['name']] = {};
        }

        if (field['type'] !== 'range') {
            filtersDataCopy[field['name']][key] = value;
        }

        if (field['type'] === 'date-select' && key === 'type') {
            let today = new Date();

            if (value.value === 0 || value.value === 6) {
                filtersDataCopy[field['name']]['from'] = null;
                filtersDataCopy[field['name']]['to'] = null;
            } else if (value.value === 1) {
                filtersDataCopy[field['name']]['from'] = new Date(today.setHours(0, 0, 0));
                filtersDataCopy[field['name']]['to'] = today;
            } else if (value.value === 2) {
                let current = new Date(today - (60 * 60 * 24 * 1 * 1000));
                filtersDataCopy[field['name']]['from'] = new Date(current.setHours(0, 0, 0));
                filtersDataCopy[field['name']]['to'] = new Date(current.setHours(23, 59, 59));
            } else if (value.value === 3) {
                let current = new Date(today - (60 * 60 * 24 * 7 * 1000));
                filtersDataCopy[field['name']]['from'] = new Date(current.setHours(0, 0, 0));
                filtersDataCopy[field['name']]['to'] = today;
            } else if (value.value === 4) {
                let current = new Date(today - (60 * 60 * 24 * 30 * 1000));
                filtersDataCopy[field['name']]['from'] = new Date(current.setHours(0, 0, 0));
                filtersDataCopy[field['name']]['to'] = today;
            } else if (value.value === 5) {
                let current = new Date(today - (60 * 60 * 24 * 90 * 1000));
                filtersDataCopy[field['name']]['from'] = new Date(current.setHours(0, 0, 0));
                filtersDataCopy[field['name']]['to'] = today;
            }
        }

        if (field['type'] === 'date-select' && (key === 'from' || key === 'to')) {
            if (!filtersDataTemp[field['name']] || (filtersDataTemp[field['name']] && filtersDataTemp[field['name']]['type']['value'] !== 6)) {
                filtersDataCopy[field['name']]['type'] = { label: 'Произвольный диапазон', value: 6 };
            }
            if (key === 'to') {
                filtersDataCopy[field['name']]['to'] = new Date(value.setHours(23, 59, 59));
            }
        }

        if (field['type'] === 'range') {
            const prevState = cloneDeep(filtersDataCopy[field['name']]);

            let from = +prevState['from'];
            let to = +prevState['to'];

            if (key === 'from' || key === 'to' || key === 'type') {
                setIsUseSlider(false);
            }

            if (key === 'type') {
                const nextType = value.value;

                let min = +prevState['min'];
                let max = +prevState['max'];

                if (prevState['type']['value'] === 0) {
                    from = nextType === 1 ? from / 1000 : nextType === 2 ? from / 1000 / 1000 : from;
                    to = nextType === 1 ? to / 1000 : nextType === 2 ? to / 1000 / 1000 : to;
                    min = nextType === 1 ? min / 1000 : nextType === 2 ? min / 1000 / 1000 : min;
                    max = nextType === 1 ? max / 1000 : nextType === 2 ? max / 1000 / 1000 : max;
                } else if (prevState['type']['value'] === 1) {
                    from = nextType === 0 ? from * 1000 : nextType === 2 ? from / 1000 : from;
                    to = nextType === 0 ? to * 1000 : nextType === 2 ? to / 1000 : to;
                    min = nextType === 0 ? min * 1000 : nextType === 2 ? min / 1000 : min;
                    max = nextType === 0 ? max * 1000 : nextType === 2 ? max / 1000 : max;
                } else if (prevState['type']['value'] === 2) {
                    from = nextType === 1 ? from * 1000 : nextType === 0 ? from * 1000 * 1000 : from;
                    to = nextType === 1 ? to * 1000 : nextType === 0 ? to * 1000 * 1000 : to;
                    min = nextType === 1 ? min * 1000 : nextType === 0 ? min * 1000 * 1000 : min;
                    max = nextType === 1 ? max * 1000 : nextType === 0 ? max * 1000 * 1000 : max;
                }

                filtersDataCopy[field['name']] = {
                    ...filtersDataCopy[field['name']],
                    min,
                    max,
                    from,
                    to,
                    range: [from, to],
                    type: value
                };
            } else if (key === 'range') {
                filtersDataCopy[field['name']] = {
                    ...filtersDataCopy[field['name']],
                    from: value[0],
                    to: value[1],
                    range: value,
                };
            } else if (key === 'from' || key === 'to') {
                from = key === 'from' ? value : prevState.from;
                to = key === 'to' ? value : prevState.to;

                filtersDataCopy[field['name']] = {
                    ...filtersDataCopy[field['name']],
                    from,
                    to,
                    range: [from, to],
                };

                onFilters(filtersDataCopy)
            }
        }

        setFiltersDataTemp(filtersDataCopy);

        if (field['type'] === 'date-select') {
            onFilters(filtersDataCopy);
        }

        if (field['type'] === 'range' && key === 'type') {
            setIsUseSlider(true);
        }
    };

    const onResetFilters = (filter) => {
        const filtersDataCopy = Object.assign({}, filtersDataTemp);

        filter['fields'].map((field) => {
            return filtersDataCopy[field['name']] = JSON.parse(JSON.stringify(field['default']));
        });

        onFilters(filtersDataCopy);
    };
    const onReset = () => {
        const filtersDataCopy = Object.assign({}, filtersDataTemp);

        filters.map((filter) => {
            return filter['fields'].map((field) => {
                return filtersDataCopy[field['name']] = JSON.parse(JSON.stringify(field['default']));
            });
        });

        onFilters(filtersDataCopy);
    };

    const onFilterClick = (e) => {
        e.stopPropagation();
        setIsFilters(true);
    };

    useClickOutside(modalRef, () => {
        setIsFilters(false);
    });

    useEffect(() => { setFiltersDataTemp(filtersData); }, [filtersData]);
    useEffect(() => {
        if (isLoading || isInit) {
            return;
        }

        const data = {};

        filters.map((filter) => {
            return filter['fields'].map((field) => {
                return data[field['name']] = JSON.parse(JSON.stringify(field['default']));
            });
        });

        setFiltersDataTemp(data);
        setIsInit(true);
    }, [isInit, filters, isLoading]);

    const isGotAddButton = add && (addLink || onAdd);
    const isGotAddButtonMobileTitle = addMobile !== '';
    const isPhone = useMediaQuery("(max-width: 430px)");

    return (
        <div id={id} className={cx.filterComponent}>
            {platform !== 'mobile' &&
            <div className={cx.filter}>
                <div className={cx.filterLeft}>
                    {selectOptions &&
                    <Select className={cx.filterLeftSelect} options={selectOptions} value={selectOption}
                            components={{ SingleValue: SingleValueWithImg, Option: OptionWithImg }} onChange={onSelect}/>}
                    {search &&
                    <div className={cx.search}>
                        <div className={cx.searchButton} onClick={() => onSearch(searchTemp)}>
                            <IconButton icon={<Icon type={Icons.SEARCH} width={13} height={13}/>}/>
                        </div>
                        <input type="text" placeholder={search} value={searchTemp}
                               onChange={(e) => setSearchTemp(e.target.value)}
                               onKeyPress={(e) => {
                                   if (e.key === 'Enter') {
                                       onSearch(searchTemp);
                                   }
                               }}
                               data-testid={id + "Search"}
                        />
                    </div>}
                </div>
                <div className={cx.filterRight}>
                    {onImport &&
                    <Button className={cx.filterRightImport} onClick={onImport}>
                        <Icon type={Icons.UPLOAD} width={18} height={18}/>
                        <span>Импорт</span>
                    </Button>}
                    {onExport &&
                    <Button className={cx.filterRightExport} onClick={onExport}>
                        <Icon type={Icons.DOWNLOAD} width={18} height={18}/>
                        <span>Экспорт</span>
                    </Button>}
                    {sortOptions && sortOptions.length > 0 &&
                    <button className={cx.sortBtn} onClick={() => setIsSort(true)}>
                        <Icon type={Icons.SORT} width={16} height={16}/>
                    </button>}
                    {filters.length !== 0 &&
                    <button className={cx.filterBtn} onClick={onFilterClick}>
                        <Icon type={Icons.FILTER}/>
                        Фильтры
                    </button>}
                    {add && (addLink || onAdd) &&
                    <AdaptiveLink link={addLink}>
                        <button className={cx.addBtn} onClick={onAdd} data-testid={id + "AddBtn"}>
                            <div className={cx.icon}>
                                <Icon type={Icons.ADD} width={8} height={8}/>
                            </div>
                            {add}
                        </button>
                    </AdaptiveLink>}
                    {buttons.length > 0 && buttons.map((button, i) => {
                        return (
                            <Link key={i} className={cx.filterBtn} to={button.link ? button.link : ''}>
                                {button.title}
                            </Link>
                        );
                    })}
                </div>
            </div>}

            {platform === 'mobile' &&
            <div className={cx.mobileFilter}>
                <div className={cx.controls}>
                    {search &&
                    <div className={cx.search}>
                        <div className={cx.searchButton} onClick={() => onSearch(searchTemp)}>
                            <IconButton icon={<Icon type={Icons.SEARCH} width={16} height={16} />} />
                        </div>
                        <input type="text" placeholder={'Поиск...'} value={searchTemp}
                               onChange={(e) => setSearchTemp(e.target.value)}
                               onKeyPress={(e) => {
                                   if (e.key === 'Enter') {
                                       onSearch(searchTemp);
                                   }
                               }}
                               data-testid={id + "SearchMobile"}
                        />
                    </div>}

                    {sortOptions && sortOptions.length > 0 &&
                    <button className={cx.sortBtn} onClick={() => setIsSort(true)}>
                        <Icon type={Icons.SORT} width={16} height={16}/>
                    </button>}

                    {filters.length !== 0 &&
                    <button className={cx.filterBtn} onClick={() => setIsFilters(true)}>
                        <Icon type={Icons.FILTER}/>
                    </button>}
                    {isGotAddButton && isGotAddButtonMobileTitle && !isPhone && (
                        <AddBtn
                            link={addLink}
                            title={addMobile}
                            onClick={onAdd}
                            isInline={isGotAddButtonMobileTitle}
                            id={id + "AddBtn"}
                        />
                    )}
                </div>
                {isGotAddButton && (!isGotAddButtonMobileTitle || (isGotAddButtonMobileTitle && isPhone)) && (
                    <AddBtn
                        link={addLink}
                        title={add}
                        onClick={onAdd}
                        id={id + "AddBtn"}
                    />
                )}
            </div>}

            {sort && sortOptions &&
            <Modal isOpen={isSort} className={classNames(cx.filters, {[cx.mobileFilters]: platform !== 'desktop'})}
                   overlayClassName={cx.filtersOverlay}>
                <div className={cx.filtersHeader}>
                    <h3>Сортировка</h3>
                    <IconButton icon={<Icon type={Icons.CROSS} width={14} height={14}/>}
                                onClick={() => setIsSort(false)}/>
                </div>
                <div className={cx.sortBody}>
                    <h4>Порядок сортировки:</h4>
                    <div className={cx.divider}/>
                    <RadioGroup className={cx.sortOrder} value={sort.desc} onChange={(v) => {
                        if (v !== sort.desc) {
                            onSort({ id: sort.by }, v);
                        }
                    }}>
                        <RadioButton value={true} label={'По убыванию (по умолчанию)'}/>
                        <RadioButton value={false} label={'По возрастанию'}/>
                    </RadioGroup>
                    <div className={cx.divider}/>
                    <RadioGroup value={sort.by} onChange={(v) => onSort({ id: v })}>
                        {sortOptions.map((column, i) => (
                            <RadioButton key={i} value={column.accessor} label={column.Header}/>
                        ))}
                    </RadioGroup>
                </div>
                <div className={cx.filtersFooter}>
                    <Button label="Закрыть" onClick={() => setIsSort(false)}/>
                </div>
            </Modal>}

            <Modal contentRef={(n) => modalRef.current = n} isOpen={isFilters}
                   className={classNames(cx.filters, {[cx.mobileFilters]: platform !== 'desktop'})}
                   overlayClassName={cx.filtersOverlay}>
                <div className={cx.filtersHeader}>
                    <h3>Фильтры</h3>
                    <IconButton icon={<Icon type={Icons.CROSS} width={14} height={14}/>}
                                onClick={() => setIsFilters(false)}/>
                </div>
                <div className={cx.filtersBody}>
                    {filters.map((filter) => {
                        return (
                            <div key={filter['label']} className={cx.row}>
                                <h4>{filter['label']}</h4>
                                {filter['fields'].map((field) => {
                                    return (
                                        <div key={field['name']} className={cx.filtersField}>
                                            {field['type'] === 'date-select' && (
                                                <Select
                                                    isMulti={false}
                                                    isSearchable={false}
                                                    value={filtersDataTemp[field['name']]?.type}
                                                    options={[
                                                        {
                                                            label: 'За все время',
                                                            value: 0
                                                        },
                                                        {
                                                            label: 'За сегодня',
                                                            value: 1
                                                        },
                                                        {
                                                            label: 'За вчера',
                                                            value: 2
                                                        },
                                                        {
                                                            label: 'За неделю',
                                                            value: 3
                                                        },
                                                        {
                                                            label: 'За месяц',
                                                            value: 4
                                                        },
                                                        {
                                                            label: 'За три месяца',
                                                            value: 5
                                                        },
                                                        {
                                                            label: 'Произвольный диапазон',
                                                            value: 6
                                                        }
                                                    ]}
                                                    onChange={(value) => onChangeFiltersObject(field, 'type', value)}
                                                />
                                            )}

                                            {field['type'] === 'date-select' && filtersDataTemp[field['name']]?.type?.value === 6 && (
                                                <>
                                                    <DatePicker
                                                        value={filtersDataTemp[field['name']]?.from}
                                                        onChange={(value) => onChangeFiltersObject(field, 'from', value)}
                                                    />
                                                    <DatePicker
                                                        value={filtersDataTemp[field['name']]?.to}
                                                        onChange={(value) => onChangeFiltersObject(field, 'to', value)}
                                                    />
                                                </>
                                            )}

                                            {field['type'] === 'date' &&
                                            <DatePicker value={filtersDataTemp[field['name']]}
                                                        isEndOfTheDay={field['isEndOfTheDay']}
                                                        onChange={(value) => onChangeFilters(field, value)}/>}

                                            {field['type'] === 'select' && !field['loadOptions'] && (
                                                <Select
                                                    isMulti={field['isMulti']}
                                                    value={filtersDataTemp[field['name']]}
                                                    options={field['options']}
                                                    disabled={field['disabled']}
                                                    isSearchable={false}
                                                    onChange={(value) => onChangeFilters(field, value)}
                                                />
                                            )}

                                            {field['type'] === 'select' && field['loadOptions'] && (
                                                <Select
                                                    isMulti={field['isMulti']}
                                                    loadOptions={field['loadOptions']}
                                                    value={filtersDataTemp[field['name']]}
                                                    onChange={(value) => onChangeFilters(field, value)}
                                                    disabled={field['disabled']}
                                                    additional={{ page: 0 }}
                                                    isSearchable={false}
                                                    paginate
                                                />
                                            )}
                                            {field['type'] === 'search' && (
                                                <SelectSearch
                                                    isMulti={field['isMulti']}
                                                    value={filtersDataTemp[field['name']]}
                                                    options={field['options']}
                                                    onChange={(value) => onChangeFilters(field, value)}
                                                    loadOptions={field['loadOptions']}
                                                    additional={{ page: 0 }}
                                                    paginate={!!field['loadOptions']}
                                                />
                                            )}

                                            {field['type'] === 'range' &&
                                            <div className={cx.range}>
                                                <div className={cx.rangeInputs}>
                                                    <Input className={cx.rangeInputsFrom} type='text'
                                                           value={filtersDataTemp[field['name']]?.from || null}
                                                           onChange={(value) => {
                                                               onChangeFiltersObject(field, 'from', value);
                                                           }}/>
                                                    <span>&mdash;</span>
                                                    <Input className={cx.rangeInputsTo} type='text'
                                                           value={filtersDataTemp[field['name']]?.to || null}
                                                           onChange={(value) => {
                                                               onChangeFiltersObject(field, 'to', value);
                                                           }}/>
                                                    <Select isSearchable={false}
                                                            value={filtersDataTemp[field['name']]?.type}
                                                            options={field['options']}
                                                            onChange={(value) => {
                                                                onChangeFiltersObject(field, 'type', value);
                                                            }}/>
                                                </div>
                                                <div className={cx.rangeSlider}>
                                                    <RangeSlider step={filtersDataTemp[field['name']]?.type?.value === 2
                                                        ? 0.001
                                                        : filtersDataTemp[field['name']]?.type?.value === 1
                                                            ? 0.001
                                                            : 1}
                                                                 allowCross={false}
                                                                 min={filtersDataTemp[field['name']]?.min}
                                                                 max={filtersDataTemp[field['name']]?.max}
                                                                 value={filtersDataTemp[field['name']]?.range}
                                                                 onChange={(value) => {
                                                                     if (isUseSlider) {
                                                                         onChangeFiltersObject(field, 'range', value);
                                                                     }
                                                                 }}
                                                                 onAfterChange={() => {
                                                                     onFilters(filtersDataTemp);
                                                                 }}/>
                                                </div>
                                            </div>}
                                        </div>
                                    );
                                })}
                                <button onClick={() => onResetFilters(filter)} className={cx.reset}>Сбросить</button>
                            </div>
                        );
                    })}
                </div>
                <div className={cx.filtersFooter}>
                    <Button label="Сбросить все" onClick={onReset}/>
                </div>
            </Modal>
        </div>
    );
};

export default React.forwardRef((props, ref) => <Filter {...props} forwardedRef={ref}/>);
