import { List } from './extension-list';
import { CustomBulletList } from './extension-bullet-list';
import { CustomOrderedList } from './extension-ordered-list';

export const ListExtensions = [
    List,
    // MultiList,
    // MultiListItem,
    CustomBulletList,
    CustomOrderedList,
];
