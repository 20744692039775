import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { selectUsers } from 'slice/authSlice';
import Modal from 'react-modal';
import { saveAs } from 'file-saver';
import { useDialog, useMessage, usePermittedActions, useGlobalContext } from 'lib/hooks';
import { FILES_TYPE_ICONS, FILES_TYPE_SHORT_NAME } from 'lib/config/constant';
import { humanFileSize } from 'lib/helpers';
import { perm, RESOURCE_TYPE } from 'model/resource';
import { FileEditDialog } from 'components/file/file-edit-dialog';
import { FileViewDialog } from 'components/file/file-view-dialog';
import { TableComponent } from 'components/data-components';
import Confirmation from 'components/confirmation';
import { EditRowToolbar } from 'uikit/table';
import Icon, { Icons } from 'uikit/icon';
import ButtonMenu from 'uikit/button-menu';
import api from 'api/index';
import cx from './tab-files.module.scss';
import { MultiClumpTooltip } from 'components/MultiClumpTooltip/MultiClumpTooltip';
import Loading from 'uikit/loading';
import getFileTypeOptions from 'lib/helpers/getFileTypeOptions';
import { EmptyFiles } from './empty';

const TabFiles = ({ projectId = '', sectionId = '' }) => {
    const tableRef = useRef(null);

    const selector = useSelector((state) => state);
    const { dialogState, openDialog, closeDialog } = useDialog();
    const { addSuccess, addError } = useMessage();
    const { platform } = useGlobalContext();

    const { checkPermission } = usePermittedActions(
        projectId && sectionId ? RESOURCE_TYPE.SECTION : RESOURCE_TYPE.PROJECT,
        projectId && sectionId ? sectionId : projectId
    );

    const [users, setUsers] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [, setIsInit] = useState(false);

    const [isReLoading, setIsReLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    const [filesTotal, setFilesTotal] = useState(0);
    const [files, setFiles] = useState([]);
    const [fileSizeRange, setFileSizeRange] = useState([0, 1]);

    const [isEditFileModal, setEditFileModal] = useState(false);
    const [isViewFileModal, setViewFileModal] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);

    const [checkedCheckbox, setCheckedCheckbox] = useState([]);
    const checkedCheckboxRef = useRef(checkedCheckbox);

    const requestFiles = useCallback(
        async (offset, count, sort = { by: '', desc: false }, search = '', filters = {}) => {
            setIsLoading(true);
            let response;

            if (projectId && sectionId) {
                response = await api.file.getFileListBySectionNew(
                    projectId,
                    sectionId,
                    offset / count,
                    count,
                    sort.by ? sort.by + ',' + (sort.desc ? 'desc' : 'asc') : 'modifyTime,desc',
                    search,
                    filters
                );
            } else {
                response = await api.file.getFileListByProjectNew(
                    projectId,
                    offset / count,
                    count,
                    sort.by ? sort.by + ',' + (sort.desc ? 'desc' : 'asc') : 'modifyTime,desc',
                    search,
                    filters
                );
            }

            setFilesTotal(response.totalElements);
            setFiles(response.content);

            setIsEmpty(response.totalElements === 0 && _.isEmpty(filters) && !search);

            setIsLoading(false);
            setIsReLoading(false);

            setFileSizeRange([Math.floor(response.minFileSize / 1000), Math.ceil(response.maxFileSize / 1000)]);

            return response;
        },
        [projectId, sectionId]
    );
    const checkboxHandler = useCallback((node) => {
        setCheckedCheckbox((prevValue) => {
            if (prevValue.some((c) => c.id === node.id)) {
                return prevValue.filter((c) => c.id !== node.id);
            } else {
                return [...prevValue, node];
            }
        });
    }, []);

    const deleteFilesViewDialog = () => {
        tableRef?.current?.reload();
    };

    const deleteFiles = useCallback(
        async (data) => {
            setIsLoading(true);
            closeDialog();

            try {
                if (data?.fileId) {
                    await api.file.removeFile(data.fileId);
                } else if (checkedCheckboxRef.current.length) {
                    for (let i = 0; i < checkedCheckboxRef.current.length; i++) {
                        await api.file.removeFile(checkedCheckboxRef.current[i].fileId);
                    }
                } else {
                    setIsLoading(false);
                    return;
                }

                tableRef?.current?.reload();
                addSuccess(data.fileId ? 'Файл удален' : 'Выбранные файлы удалены');
            } catch (error) {
                addError('При удалении произошла ошибка');
            } finally {
                setIsLoading(false);
            }
        },
        [closeDialog, addSuccess, addError]
    );
    const onFilesDelete = useCallback(
        async (data) => {
            if (data?.length) {
                setCheckedCheckbox(data.map((d) => d.original));
            }
            const text = data?.length ? (
                data.map((item) => {
                    return {
                        ...item.original,
                        text: (
                            <>
                                <span onClick={() => fileSelectHandler(data)} style={{ color: '#279BD9' }}>
                                    {item?.original?.name}
                                </span>
                            </>
                        ),
                    };
                })
            ) : (
                <span>
                    Вы действительно хотите удалить файл
                    <span style={{ color: '#279BD9' }} onClick={() => fileSelectHandler(data)}>
                        {data?.name}
                    </span>{' '}
                    ? Этот процесс нельзя будет отменить
                </span>
            );
            const subTitle = data?.length
                ? 'Вы действительно хотите удалить нижеперечисленные объекты? Этот процесс нельзя будет отменить'
                : null;

            openDialog({
                title: 'Удаление',
                subTitle,
                text,
                contentType: data?.length ? 'CHECKBOX_LIST' : 'TEXT',
                color: 'red',
                closeBtnText: 'Нет, закрыть',
                submitBtnText: 'Да, удалить',
                onChange: (articles) => checkboxHandler(articles),
                onSubmit: () => deleteFiles(data?.length ? checkedCheckboxRef.current : data),
                onClose: closeDialog,
            });
        },
        [checkboxHandler, deleteFiles, openDialog, closeDialog]
    );

    const filesColumns = useMemo(() => {
        return [
            {
                Header: 'Название',
                accessor: 'name',
                width: 400,
                maxWidth: 400,
                Cell: function (data) {
                    return (
                        <div className={cx.nameCell}>
                            <div className={cx.iconWrapper}>
                                <Icon type={FILES_TYPE_ICONS[data.row.original.type] || Icons.NONE_FILE} width={24} height={24} />
                            </div>

                            <div>
                                <div className={cx.fileName} onClick={() => fileSelectHandler(data)}>
                                    <MultiClumpTooltip label={data.row.original.name} />
                                </div>
                                <div className={cx.fileDescription}>
                                    <span className={cx.fileType}>{FILES_TYPE_SHORT_NAME[data.row.original.type]}</span>—{' '}
                                    {humanFileSize(data.row.original.size, true)}
                                </div>
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: 'Дата создания',
                accessor: 'createTime',
                width: 140,
                Cell: function (data) {
                    const date = new Date(data.row.original.createTime).toLocaleString();
                    return date.substr(0, date.length - 3);
                },
            },
            {
                id: 4,
                width: 100,
                Cell: (data) => {
                    let toolbar = [];

                    if (checkPermission(perm.file.FILE_EDIT)) {
                        toolbar.push({
                            id: 'tabFileEditIcon',
                            icon: Icons.EDIT_PEN,
                            tooltip: 'Редактировать',
                            onClick: (data) => {
                                setSelectedFile(data);
                                setEditFileModal(true);
                            },
                        });
                    }

                    if (checkPermission(perm.file.FILE_DELETE)) {
                        toolbar.push({
                            id: 'tabFileDeleteIcon',
                            icon: Icons.TRASH,
                            tooltip: 'Удалить',
                            onClick: async (data) => onFilesDelete(data),
                        });
                    }

                    if (checkPermission(perm.file.FILE_READ)) {
                        toolbar.push({
                            id: 'tabFileDownloadIcon',
                            icon: Icons.DOWNLOAD,
                            tooltip: 'Скачать',
                            onClick: async (data) => {
                                const res = await api.file.download(data.fileId);
                                const blob = new Blob([res], { type: data.type });
                                saveAs(blob, data.name);
                            },
                        });
                    }

                    return platform === 'mobile' ? (
                      <ButtonMenu
                          items={toolbar.map((item) => {
                              return {
                                  ...item,
                                  title: item.tooltip
                              }
                          })}
                      />
                    ) : EditRowToolbar(toolbar)(data);
                },
            },
        ];
    }, [platform, checkPermission, onFilesDelete]);
    const filesActions = useMemo(() => {
        const actions = [];

        if (checkPermission(perm.file.FILE_DELETE)) {
            actions.push({
                icon: Icons.TRASH,
                label: 'Удалить',
                onClick: (data) => onFilesDelete(data),
            });
        }

        return actions;
    }, [checkPermission, onFilesDelete]);

    const loadUsers = async (search) => {
        let options = [];

        if (search) {
            options = users.filter(user => {
              return user.label.toString().toLowerCase().includes(search.toLowerCase());
            })
        }

        return { options }
    }

    const filesFilters = [
        {
            label: 'Формат файла',
            fields: [
                {
                    name: 'type.in',
                    type: 'select',
                    default: null,
                    isMulti: true,
                    options: getFileTypeOptions()
                },
            ],
        },
        {
            label: 'Размер файла',
            fields: [
                {
                    name: 'fileSize',
                    type: 'range',
                    default: {
                        min: fileSizeRange[0],
                        max: fileSizeRange[1],
                        from: fileSizeRange[0],
                        to: fileSizeRange[1],
                        type: {
                            label: 'Кб',
                            value: 0,
                        },
                        range: fileSizeRange,
                    },
                    options: [
                        {
                            label: 'Кб',
                            value: 0,
                        },
                        {
                            label: 'Мб',
                            value: 1,
                        },
                        {
                            label: 'Гб',
                            value: 2,
                        },
                    ],
                },
            ],
        },
        {
            label: 'Автор файла',
            fields: [
                {
                    name: 'userLogin.in',
                    type: 'search',
                    default: null,
                    isMulti: true,
                    loadOptions: loadUsers
                },
            ],
        },
        {
            label: 'Дата загрузки',
            fields: [
                {
                    name: 'createTime',
                    type: 'date-select',
                    default: {
                        type: {
                            label: 'Все время',
                            value: 0,
                        },
                        from: null,
                        to: null,
                    },
                },
            ],
        },
    ];

    const toggleEditModal = () => {
        setEditFileModal((isOpen) => !isOpen);
    };
    const toggleViewModal = () => {
        setViewFileModal((isOpen) => !isOpen);
    };

    const fileSelectHandler = (values) => {
        setSelectedFile(values);
        setViewFileModal(true);
    };

    useEffect(() => {
        checkedCheckboxRef.current = checkedCheckbox;
    }, [checkedCheckbox]);
    useEffect(() => {
        const fetchData = async () => {
          const usersResponse = await selectUsers(selector);
          setUsers(usersResponse.content?.map(user => ({
              label: user.firstName + ' ' + user.lastName,
              value: user.login,
          })));
        };

        fetchData();
    }, [selector]);

    useEffect(() => {
        setIsReLoading(true);
        setIsEmpty(false);

        setFilesTotal(0);
        setIsInit(prev => {
            if (!prev) {
                return !prev;
            }

            tableRef?.current?.reload();
            return prev;
        });
    }, [projectId, sectionId]);

    return (
        <div className={cx.tableWrapper}>
            <Confirmation {...dialogState} />
            <Modal isOpen={isEditFileModal} className={cx.modal} onRequestClose={toggleEditModal} overlayClassName={cx.modalOverlay}>
                <FileEditDialog file={selectedFile} handleClose={toggleEditModal} onFileChange={requestFiles} />
            </Modal>
            {isViewFileModal && (
                <FileViewDialog
                    file={selectedFile}
                    onRemove={deleteFilesViewDialog}
                    openEditModal={toggleEditModal}
                    handleClose={toggleViewModal}
                    onChange={requestFiles}
                />
            )}
            {isLoading && isReLoading && (
                <div className={cx.loader}>
                    <Loading withOverlay={false} />
                </div>
            )}
            {isEmpty && !isLoading && (
                <EmptyFiles />
            )}
            {!isEmpty && (
                <TableComponent
                    innerRef={tableRef}
                    isLoading={isLoading}
                    title="Файлы"
                    searchTitle="Поиск по имени"
                    columns={filesColumns}
                    actions={filesActions}
                    total={filesTotal}
                    data={files}
                    onPaginate={requestFiles}
                    onRowClick={fileSelectHandler}
                    filters={filesFilters}
                    name="files"
                    isMobile={platform === 'mobile'}
                />
            )}
        </div>
    );
};

export default TabFiles;
