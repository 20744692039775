import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Configuration } from './configuration';
import { EDITOR_TIPTAP } from '../../../editors/constants';
import api from 'api/index';

const defaultConfiguration: Configuration = {
    common: {
        defaultProject: {
            enabled: false,
            projectIds: [],
        },
        editor: {
            type: EDITOR_TIPTAP,
            experimental: {
                switchEditors: false
            }
        },
        search: {
            voice: true
        },
        sentry: {
            enabled: false,
            dsn: '',
            environment: ''
        }
    },
    features: {
        aiServices: {
            enabled: false,
            assistantEnabled: false,
            botServiceUrl: '',
            processingServiceUrl: ''
        },
        scripting: {
            enabled: true
        },
        education: {
            enabled: true
        },
    },
    host: "",
};

interface ConfigurationContextProps {
    configuration: Configuration;
    isAiChatEnabled: boolean;
    isScriptingEnabled: boolean;
    isEducationEnabled: boolean;
    isVoiceSearchEnabled: boolean;
    isEditorExperimentalSwitch: boolean;
    getEditorType: () => string;
}

const ConfigurationContext = createContext<ConfigurationContextProps>({
    configuration: defaultConfiguration,
    isAiChatEnabled: false,
    isScriptingEnabled: false,
    isEducationEnabled: false,
    isVoiceSearchEnabled: false,
    isEditorExperimentalSwitch: false,
    getEditorType: () => "",
});

interface ConfigurationProviderProps {
    children: ReactNode;
    initCallback?: (_configuration: Configuration) => void;
}

export const ConfigurationProvider = ({ children, initCallback }: ConfigurationProviderProps) => {
    const [configuration, setConfiguration] = useState<Configuration>(defaultConfiguration);

    const getEditorType = () => EDITOR_TIPTAP;

    useEffect(() => {
        api.config.getConfig().then((response: Configuration) => {
            setConfiguration(response);
            initCallback?.(response);
        // api.savedRequest.retrySavedRequest();
        });
    }, [initCallback]);

    return (
        <ConfigurationContext.Provider value={{
            configuration,
            isAiChatEnabled: configuration?.features?.aiServices?.enabled || false,
            isScriptingEnabled: configuration?.features?.scripting?.enabled || false,
            isEducationEnabled: configuration?.features?.education?.enabled || false,
            isVoiceSearchEnabled: configuration?.common?.search?.voice || false,
            isEditorExperimentalSwitch: false,
            getEditorType
        }}>
            {children}
        </ConfigurationContext.Provider>
    );
};

export const useConfiguration = (): ConfigurationContextProps => {
    const context = useContext(ConfigurationContext);

    if (!context) {
        throw new Error('useConfiguration must be used within a ConfigurationProvider');
    }

    return context;
};
