import { Node, mergeAttributes } from '@tiptap/core';

export const TableOfContentsItem = Node.create({
    name: 'tableOfContentsItem',
    content: 'paragraph block*',
    selectable: true,
    draggable: false,

    addAttributes() {
        return {
            'data-type': {
                default: null,
                parseHTML: el => el.getAttribute('data-type'),
                renderHTML: attrs => ({ 'data-type': attrs['data-type'] }),
            },
            'data-level': {
                default: null,
                parseHTML: el => el.getAttribute('data-level'),
                renderHTML: attrs => ({ 'data-level': attrs['data-level'] }),
            },
            class: {
                default: null,
                parseHTML: el => el.getAttribute('class'),
                renderHTML: attrs => ({ class: attrs.class }),
            },
            uniqid: {
                default: null,
                parseHTML: el => el.getAttribute('data-toc-uniq-id'),
                renderHTML: attrs => {
                    if (!attrs.uniqid) {
                        return {
                            'data-toc-uniq-id': attrs.uniqid,
                        };
                    }

                    return attrs;
                },
            },
            'data-mark': {
                default: null,
                renderHTML: attrs => {
                    if (!attrs['data-mark']) return {};
                    if (attrs['data-mark'] === 'italic') return { ...attrs, style: 'font-style: italic' };
                    if (attrs['data-mark'] === 'bold') return { ...attrs, style: 'font-weight: 600' };
                    if (attrs['data-mark'] === 'underline') return { ...attrs, style: 'text-decoration: underline' };
                },
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'li',
                getAttrs: el => el.getAttribute('data-type') === 'toc-item',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['li', mergeAttributes(HTMLAttributes, { 'data-type': 'toc-item', contentEditable: false }), 0];
    },
});
