import React, { useCallback, useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Link, useLocation, Outlet } from 'shared/router';
import { useSelector } from 'react-redux';
import { useGlobalContext } from 'lib/hooks';
import buttons from 'model/global-menu/buttons';
import { selectMenuButtons } from 'slice/authSlice';
import { selectNewsPicker } from 'slice/newsPickerSlice';
import NewsTicker from 'components/news-ticker/news-ticker';
import GlobalMenu from 'uikit/global-menu';
import cx from './menu-wrapper.module.scss';
import Header from 'components/header/header';
import { Icons } from 'uikit/icon';
import { useConfiguration } from '../../lib/context';

export const MENU_CONTENT_TYPE = {
    MAIN_MENU: 0,
    CUSTOM: 1,
};

export const MobileMenuContext = React.createContext({});

export const MenuWrapper = ({ children }) => {
    const location = useLocation();
    const menuButtons = useSelector(selectMenuButtons);

    const { isAiChatEnabled, isScriptingEnabled, isEducationEnabled } = useConfiguration();

    const { platform } = useGlobalContext();
    const isTicker = useSelector(selectNewsPicker).count > 0;

    const [menuAlwaysOpen, _setMenuAlwaysOpen] = useState(false);
    const setMenuAlwaysOpen = useCallback(
        v => {
            _setMenuAlwaysOpen(v);

            if (v) {
                setMenuOpen(true);
            }
        },
        [_setMenuAlwaysOpen]
    );
    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenu = useCallback(() => {
        if (!menuAlwaysOpen) {
            setMenuOpen(!menuOpen);
        }
    }, [setMenuOpen, menuOpen, menuAlwaysOpen]);
    const [menuContentType, _setMenuContentType] = useState(MENU_CONTENT_TYPE.MAIN_MENU);
    const setMenuContentType = useCallback(t => _setMenuContentType(t), [_setMenuContentType]);
    const [renderMenu, _setRenderMenu] = useState(() => () => {});
    const setRenderMenu = useCallback(f => _setRenderMenu(() => f), [_setRenderMenu]);
    const [menuTitle, _setMenuTitle] = useState('');
    const setMenuTitle = useCallback(t => _setMenuTitle(() => t), [_setMenuTitle]);

    const [itemActive, setItemActive] = useState(null);

    const menus = useMemo(
        () => [
            {
                icon: 'home',
                mobileIcon: Icons.HOME,
                title: 'Главная',
                to: '',
                platforms: ['mobile'],
                canExpand: false,
                onClick: () => setMenuOpen(false),
                isMobile: true,
            },
            {
                icon: 'addition',
                mobileIcon: Icons.ADD_CIRCLE,
                title: 'Добавить',
                to: 'add',
                auth: buttons.ADD,
                canExpand: false,
                isMobile: true,
            },
            {
                icon: 'projects',
                mobileIcon: Icons.PROJECTS,
                title: 'Проекты',
                to: 'projects',
                canExpand: true,
                isMobile: true,
            },
            {
                icon: 'user',
                mobileIcon: Icons.GROUP,
                title: 'Участники',
                to: 'users',
                auth: buttons.USERS,
                canExpand: true,
                isMobile: true,
                activePaths: ['edit-user', 'edit-group']
            },
            {
                icon: 'chat',
                mobileIcon: Icons.CHAT,
                title: 'Чат',
                to: 'chat',
                canExpand: false,
                onClick: () => setMenuOpen(false),
                isMobile: true,
            },
            {
                icon: 'news',
                mobileIcon: Icons.NEWS,
                title: 'Новости',
                to: 'news',
                canExpand: false,
                onClick: () => setMenuOpen(false),
                isMobile: true,
            },
            {
                icon: 'journal',
                mobileIcon: Icons.JOURNAL,
                title: 'Журнал действий',
                to: 'action-log',
                auth: buttons.ACTIVITY,
                canExpand: true,
                isMobile: true,
            },
            {
                icon: 'stat',
                mobileIcon: Icons.STAT,
                title: 'Статистика',
                to: 'statistic/dashboard/general',
                auth: buttons.STAT,
                canExpand: true,
                isMobile: false,
            },
            {
                icon: 'archive',
                mobileIcon: Icons.ARCHIVE,
                title: 'Архив',
                to: 'archive',
                auth: buttons.ARCHIVE,
                canExpand: true,
                isMobile: true,
            },
            {
                icon: 'files',
                mobileIcon: Icons.FILES,
                title: 'Файлы',
                to: 'files',
                canExpand: true,
                isMobile: true,
            },
            {
                icon: 'book',
                mobileIcon: Icons.BOOK,
                title: 'Обучение',
                to: 'training',
                canExpand: false,
                onClick: () => setMenuOpen(false),
                isMobile: false,
            },
            {
                id: 'scriptingMenuItem',
                icon: 'scripting',
                mobileIcon: Icons.SCRIPTING,
                title: 'Скриптинг',
                to: 'scripting',
                canExpand: false,
                onClick: () => setMenuOpen(false),
                isMobile: true,
            },
            {
                mobileIcon: Icons.SETTINGS,
                title: 'Глобальные настройки',
                to: 'global-settings',
                platforms: ['mobile'],
                auth: buttons.SETTINGS,
                canExpand: true,
                isMobile: true,
            },
            {
                icon: 'AIchat',
                mobileIcon: Icons.CHAT_BOT,
                title: 'AI',
                to: 'ai',
                auth: true,
                canExpand: false,
                onClick: () => setMenuOpen(false),
                isMobile: true,
            },
        ],
        []
    );
    const menuItems = React.useMemo(() => {

        return menuButtons
            ? menus
                .filter(menu => !menu.platforms || menu.platforms.includes(platform))
                .filter(menu => (menu.to === 'ai' && isAiChatEnabled) || !menu.auth || menuButtons[menu.auth] === true)
                .filter(menu => {
                    if (isScriptingEnabled) {
                        return true
                    } else {
                        return menu.to !== 'scripting'
                    }
                })
                .filter(menu => {
                    if (isEducationEnabled) {
                        return true
                    } else {
                        return menu.to !== 'training'
                    }
                })
            : [];
    }, [menus, menuButtons, platform, isAiChatEnabled, isScriptingEnabled, isEducationEnabled]);

    useEffect(() => {
        setItemActive(location.pathname.split('/')[1]);
    }, [location]);

    return (
        <div className={classNames(cx.pageWrapper, { [cx.withTicker]: isTicker })}>
            <NewsTicker />
            {platform === 'mobile' && (
                <div className={classNames(cx.mobileMenuWrapper, { [cx.withTicker]: isTicker })}>
                    <GlobalMenu
                        menuItems={menuItems}
                        itemActive={itemActive}
                        toggleMenu={toggleMenu}
                        isOpen={menuOpen}
                        menuContentType={menuContentType}
                        isTicker={isTicker}
                        menuTitle={menuTitle}
                        renderMenu={renderMenu}
                        onClose={() => setMenuOpen(false)}
                    />
                    <MobileMenuContext.Provider value={{ setRenderMenu, setMenuContentType, toggleMenu, setMenuAlwaysOpen, setMenuTitle }}>
                        <div className={cx.content}>
                            <Header platform={platform} isTicker={isTicker} />
                            <Outlet>{children}</Outlet>
                        </div>
                    </MobileMenuContext.Provider>
                </div>
            )}
            {platform === 'desktop' && (
                <div className={cx.menuWrapper}>
                    <div id="menu" className={cx.menu}>
                        <Link className={cx.granLogo} to={'/'}>
                            <img src={'/icons/logo.svg'} alt="" />
                        </Link>
                        <GlobalMenu
                            menuItems={menuItems}
                            itemActive={itemActive}
                            unreadNews={menuButtons?.unreadNews}
                            unreadArticles={menuButtons?.unreadArticles}
                            isTicker={isTicker}
                        />
                    </div>
                    <div className={cx.content}>
                        <Header platform={platform} />
                        <Outlet>{children}</Outlet>
                    </div>
                </div>
            )}
        </div>
    );
};
