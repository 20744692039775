import React, { useState, useMemo, useEffect } from 'react';
import { Link } from 'shared/router';
import { ReportsComponent } from 'components/data-components/reports-component';
import { articleSearchBy, newsSearchBy, objectTypes, sortTypesOptions } from 'app/statistic/vendors/filtersOptions';
import { ReportService } from 'app/statistic/services/ReportService';
import { useMessage } from 'lib/hooks';
import cx from './statistic-reports-page.module.scss';
import api from 'api';

const StatisticReportsActionsReports = ({ setIsDownloadModal }) => {
    const { addError } = useMessage();

    const [isLoading, setIsLoading] = useState(false);

    const [actionsTypes, setActionsTypes] = useState([]);

    const [reports, setReports] = useState([]);
    const [reportsTotal, setReportsTotal] = useState(0);
    const [sortByOptions, setSortByOptions] = useState([]);
    const [filters, setFilters] = useState({});

    const getActionsReports = async (page = 0, size = 50, filters = {}) => {
        setIsLoading(true);

        try {
            const res = await api.reports.getActionsReport({ page, size, filters, cancelToken: ReportService.cancelToken.token });

            setReports(
                res.projectEvents.content.map((i) => {
                    return {
                        ...i,
                        eventType: i.message,
                        timestamp: i.timestamp,
                    };
                })
            );
            setReportsTotal(res.projectEvents.totalElements);

            if (!res.projectEvents.totalElements) {
                addError('По вашему запросу нет данных');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };
    const onDownload = async (filters) => {
        try {
            await api.reports.downloadReports('events', filters, ReportService.cancelToken.token);
            setIsDownloadModal(true);
        } catch (error) {
            addError('Произошла ошибка при скачивании отчета');
        }
    };

    const columnsTmp = useMemo(() => {
        const data = [
            {
                Header: filters['document-type-select']?.value === 'ARTICLE' ? 'Документ' : 'Проект',
                accessor:
                    filters['document-type-select']?.value === 'ARTICLE' || filters['news-group']?.value !== 'GLOBAL_NEWS'
                        ? 'projectTitle'
                        : '',
                sort: false,
                Cell: (data) => {
                    if (data.row.original.objectType?.includes('ARTICLE') || data.row.original.objectType?.includes('FILE')) {
                        switch (data.row.original.status) {
                            case 'ARCHIVED':
                                return (
                                    <Link className={cx.link} to={'/archive/article/' + data.row.original.resourceId}>
                                        {data.row.original.projectTitle}
                                    </Link>
                                );
                            case 'ACTIVE':
                                return (
                                    <Link className={cx.link} to={'/projects/article/' + data.row.original.resourceId}>
                                        {data.row.original.projectTitle}
                                    </Link>
                                );
                            default:
                                return data.row.original.projectTitle;
                        }
                    } else {
                        return data.row.original.projectTitle || '–';
                    }
                },
            },
            {
                Header: 'Название новости',
                accessor: 'title',
                isVisible: filters['document-type-select']?.value === 'NEWS',
                sort: false,
                Cell: (data) => {
                    switch (data.row.original.status) {
                        case 'ACTIVE':
                            return (
                                <Link className={cx.link} to={'/projects/news/' + data.row.original.resourceId}>
                                    {data.row.original.title}
                                </Link>
                            );
                        case 'ARCHIVED':
                            return (
                                <Link className={cx.link} to={'/archive/news/' + data.row.original.resourceId}>
                                    {data.row.original.title}
                                </Link>
                            );
                        default:
                            return <div>{data.row.original.title}</div>;
                    }
                },
            },
            {
                Header: 'Действие',
                accessor: 'eventMessageRu',
                Cell: (data) => {
                    return data.row.original.message;
                },
            },
            {
                Header: 'Участник',
                accessor: 'user',
                Cell: (data) => {
                    return data.row.original.activeUser ? (
                        <Link className={cx.link} to={'/edit-user/' + data.row.original.login}>
                            {data.row.original.user}
                        </Link>
                    ) : (
                        data.row.original.user
                    );
                },
            },
            {
                Header: 'Дата',
                accessor: 'timestamp',
                Cell: (data) => ReportService.defaultDateColumn('timestamp')(data),
            },
        ];

        setSortByOptions((prevState) => {
            const newState = data
                .filter((col) => col.accessor && col.isVisible !== false && col.sort !== false)
                .map((column) => ({ label: column.Header, value: column.accessor }));

            if (JSON.stringify(prevState) !== JSON.stringify(newState)) {
                setFilters((prevState) => ({ ...prevState, sortBy: newState[newState.length - 1] }));
            }

            return newState;
        });

        return data;
    }, [filters]);
    const filtersTmp = useMemo(() => {
        const data = [
            {
                fields: [
                    {
                        labels: ['Диапазон времени', 'От', 'До'],
                        name: 'createTime',
                        type: 'date-range',
                        default: filters['createTime'] || {
                            type: {
                                label: 'За сегодня',
                                value: 1,
                            },
                            from: new Date(new Date().setHours(0, 0, 0)).toISOString(),
                            to: new Date(new Date().setHours(23, 59, 59)).toISOString()
                        },
                    },
                ],
                width: '100%',
            },
            {
                label: 'Тип документа',
                fields: [
                    {
                        name: 'document-type-select',
                        type: 'document-type-select',
                        options: objectTypes,
                        default: filters['document-type-select'] || objectTypes[0],
                        required: true,
                    },
                ],
                width: '100%',
            },
            {
                label: '',
                fields: [
                    {
                        name: 'articles-group',
                        type: 'articles',
                        options: articleSearchBy,
                        default: filters['articles-group'] || articleSearchBy[0],
                    },
                ],
                width: '100%',
            },
            {
                label: '',
                fields: [
                    {
                        name: 'news-group',
                        type: 'news',
                        options: newsSearchBy,
                        default: filters['news-group'] || newsSearchBy[1],
                    },
                ],
                width: '100%',
            },
            {
                label: 'Участник',
                fields: [
                    {
                        name: 'users',
                        type: 'user-select',
                        default: filters['users'] || [],
                    },
                ],
                width: '100%',
            },
            {
                label: 'Действия',
                fields: [
                    {
                        name: 'eventTypes',
                        type: 'select',
                        options: actionsTypes,
                        default: actionsTypes ? filters['eventTypes'] || actionsTypes[0] : null,
                    },
                ],
                width: '50%',
            },
            {
                label: 'Сортировать по',
                fields: [
                    {
                        name: 'sortBy',
                        type: 'select',
                        options: sortByOptions,
                        default: filters['sortBy'] || { label: 'Дата', value: 'timestamp' },
                    },
                ],
                width: '25%',
            },
            {
                label: 'Порядок',
                fields: [
                    {
                        name: 'sort',
                        type: 'select',
                        options: sortTypesOptions,
                        default: filters['sort'] || sortTypesOptions[0],
                    },
                ],
                width: '25%',
            },
        ];

        return data;
    }, [sortByOptions, filters, actionsTypes]);

    useEffect(() => {
        ReportService.init('ACTIONS_REPORTS');

        return () => {
            ReportService.destroy();
        };
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            const res = await ReportService.getActionsTypes();

            setActionsTypes(res);
        };

        fetchData();
    }, []);

    return (
        <ReportsComponent
            isEmpty={!reportsTotal}
            isLoading={isLoading}
            columns={columnsTmp}
            data={reports}
            total={reportsTotal}
            filters={filtersTmp}
            onDownload={onDownload}
            onPaginate={getActionsReports}
            onChangeFilters={setFilters}
        />
    );
};

export default StatisticReportsActionsReports;
