import { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';

const treeNodeTypes = Object.freeze({
    project: 'PROJECT', section: 'SECTION',
    article: 'ARTICLE', scripts: 'SCRIPTS',
})

export const useAutoNavigate = (_idsToOpen, treeRef) => {
    const { project, section, article, scripts } = treeNodeTypes;
    const [idsToOpen, setIdsToOpen] = useState(_idsToOpen);

    // function findParentNodeWithZeroDepth(node) {
    //     if (!node || !node.state || typeof node.state.depth !== 'number') return null;
    //     if ((node.state.depth - 1) === -1) return node;
    //     if (node.parent) return findParentNodeWithZeroDepth(node.parent);
    //     return null;
    // }

    function findOptimalVisibleNode(tree, targetNode, nodeHeight, visibleZoneHeight) {

        let currentNode = targetNode;

        do {

            const currentParent = currentNode.parent;

            const nodeIndex = currentParent.children.indexOf(currentNode);
            if (nodeIndex === -1) break;

            // eslint-disable-next-line no-loop-func
            currentParent.children.forEach(childNode => {
                if (childNode.hasChildren() &&
                    childNode.state.open &&
                    childNode.id !== currentNode.id) tree.closeNode(childNode, { silent: true });
            })

            const visibleNodesInParentHeight = currentParent.children
                .slice(0, nodeIndex + 1)
                .reduce((sum, node) => sum + (node.state.total || 1), 2) * nodeHeight;

            if (visibleNodesInParentHeight <= visibleZoneHeight) currentNode = currentParent;
            else break;

        } while (currentNode.parent.state.depth > -1)

        return currentNode;
    }

    function getHeighValueByQuerySelector(sel) {
        const el = document.querySelector(sel);
        return el ? el.getBoundingClientRect().height : null;
    }

    useEffect(() => {
        setIdsToOpen(prevState => {
            if (isEqual(prevState, _idsToOpen)) {
                return prevState;
            } else {
                return _idsToOpen;
            }
        });
    }, [_idsToOpen, setIdsToOpen]);

    useEffect(() => {
        let handler;
        new Promise(resolve => {
            function tryTree() {
                const tree = treeRef?.current?.tree;

                if (tree && tree.nodes.length > 0) {
                    resolve(tree);
                } else {
                    handler = setTimeout(tryTree, 300);
                }
            }

            tryTree();
        }).then(tree => {

            const treeNodeHeight = getHeighValueByQuerySelector('[class*="tree-node_container"]');
            const treeVisibleZoneHeight = getHeighValueByQuerySelector('.expand-trigger');

            if (Array.isArray(idsToOpen) && idsToOpen.length > 0) {
                const interval = setInterval(() => {
                    idsToOpen.forEach((id, i) => {

                        const node = tree.getNodeById(id);

                        if (node) {

                            const selectedNode = tree.getSelectedNode();
                            // const rootNode = findParentNodeWithZeroDepth(selectedNode)

                            if (!selectedNode) {
                                return;
                            }

                            tree.openNode(node, { silent: true });

                            switch (selectedNode?.nodeType) {
                                case section:
                                case article:
                                case scripts:
                                    try {
                                        tree.scrollToNode(
                                            findOptimalVisibleNode(
                                                tree,
                                                selectedNode,
                                                treeNodeHeight,
                                                treeVisibleZoneHeight
                                            )
                                        );
                                        break;
                                    }
                                    catch {
                                        tree.scrollToNode(selectedNode)
                                        break;
                                     }
                                case project:
                                    tree.scrollToNode(selectedNode);
                                    break;
                                default:
                                    tree.scrollToNode(node);
                            }

                            if (i + 1 === idsToOpen.length && (!selectedNode || selectedNode.id !== node.id)) {
                                tree.selectNode(node, { silent: true });
                            }

                            clearInterval(interval);
                        }
                    });
                }, 500);
            }
        });

        return () => {
            if (handler) {
                clearTimeout(handler);
            }
        };
    }, [idsToOpen, treeRef, article, project, scripts, section]);
};
