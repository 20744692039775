import React, { useState } from 'react';
import classNames from 'classnames';
import cx from './text.module.scss';

const Text = ({
    id = null,
    name = null,
    className = null,
    label = null,
    value = null,
    maxLength = 128,
    isCounter = false,
    isError = false,
    error = '',
    onChange = () => {}
}) => {
    const [isLengthError, setIsLengthError] = useState(false);

    const onTextChange = (e) => {
        if (maxLength > 0 && e.target.value.length > maxLength) {
            setIsLengthError(true);
            return;
        }

        setIsLengthError(false);
        onChange(e.target.value);
    };

    return (
        <div id={id} className={classNames(cx.text, isError || isLengthError ? cx.textError : null, className)}>
            <label className={isError || isLengthError ? cx.error : null} htmlFor={name}>{label}</label>
            <textarea id={name} name={name} value={value} onChange={onTextChange}/>
            {isCounter && !isError &&
            <span className={isLengthError ? cx.error : ''}>Использовано символов: {value.length} / {maxLength}</span>}
            {isError &&
            <span className={cx.error}>{error}</span>}
        </div>
    );
};

export default Text;
