import React, { useEffect } from 'react';
import { useNavigate } from 'shared/router';
import { useProjectFormModel } from 'lib/hooks';
import ContentManagementProjectFormPage from './content-management-form';
import cs from './content-management-form-page.module.scss';

export const contentManagementProjectFormPage = (Component, pageTitle) => {
    const FormPage = (props) => {

        const navigate = useNavigate();
        const { uuid } = props;

        const model = useProjectFormModel(uuid);
        const { project, loading, permissionsOk } = model;

        useEffect(() => {
            if (!permissionsOk) {
                navigate('/401');
            }
        }, [navigate, permissionsOk]);

        return (
            <div className={cs.content}>
                <div id={'contentManagementProjectFormPageTitle'} className={cs.pageTitle}>{pageTitle}</div>
                <ContentManagementProjectFormPage resource={project} loading={loading}>
                    <Component {...props} {...model}/>
                </ContentManagementProjectFormPage>
            </div>
        );
    };

    return FormPage;
};
