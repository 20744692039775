import React, { useEffect } from 'react';
import { useCancelBehavior, useGlobalContext, useMessage } from 'lib/hooks';
import hot from 'containers/hot-container';
import Button from 'uikit/button';
import api from 'api';
import cs from 'components/user-form/user-form.module.scss';
import { useUserFormModel } from 'lib/hooks/use-user-form-model';
import AvatarChange from 'components/avatar-change/avatar-change';
import UserForm from 'components/user-form/user-form';
import { setDocumentTitle, useNavigate } from 'shared/router';

const ProfilePageEdit = () => {
    const navigate = useNavigate();

    const { platform, setFormDataChanged } = useGlobalContext();
    const { addError, addSuccess } = useMessage();

    const { cancel } = useCancelBehavior();
    const { user, loading, setLoading } = useUserFormModel(null, true);

    const onAvatarSubmit = (avatar) => {
        user.avatar = avatar;
        user.avatarChanged = true;
        user.commit();
    };
    const onAvatarRemove = () => {
        user.avatar = null;
        user.avatarUuid = null;
        user.avatarChanged = true;
        user.commit();
    };
    const editUser = async () => {
        if (!user.validateFields([], true)) {
            return;
        }

        setLoading(true);

        try {
            const formData = user.getFormData();
            await api.user.putUser(formData);

            addSuccess('Данные профиля изменены');
            setFormDataChanged(false);

            navigate(`/user/profile`);
        } catch (error) {
            console.log(error);
            addError('Не удалось изменить данные профиля');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setDocumentTitle('Профиль — KMS Gran');
    }, []);

    return (
        <>
            <div className={cs.pageTitle}>
                Редактирование профиля
            </div>
            <div className={cs.avatarContainer}>
                <AvatarChange
                    value={user.avatarChanged && user.avatar
                        ? user.avatar
                        : (user.avatarUuid
                            ? api.upload.getImage(user.avatarUuid, false, 512)
                            : '/img/avatar.png'
                    )}
                    onSubmit={onAvatarSubmit}
                    onRemove={onAvatarRemove}
                />
            </div>
            <div className={platform === 'mobile' ? cs.mobile : null}>
                <UserForm
                    user={user}
                    loading={loading}
                    isEditMode={true}
                    allowEmptyPassword={true}
                    disableLoginEdit={true}
                >
                    <div className={cs.controlsContainer}>
                        <Button
                            label="Отмена"
                            onClick={cancel}
                            fullWidth={platform === 'mobile'}
                        />
                        <Button
                            onClick={editUser}
                            label="Сохранить"
                            disabled={loading}
                            color="green"
                            fullWidth={platform === 'mobile'}
                        />
                    </div>
                </UserForm>
            </div>
        </>
    );
};

export default hot(ProfilePageEdit);
