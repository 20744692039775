import React from 'react';
import classNames from 'classnames';
import { Link } from 'shared/router';
import Tippy from '@tippyjs/react';
import { useMessage } from 'lib/hooks';
import formatDateTime from 'lib/helpers/formatDateTime';
import Icon, { Icons } from 'uikit/icon';
import api from 'api';
import cx from './project.module.scss';
import MultiClamp from 'react-multi-clamp';

const Project = ({
    id = '',
    image = '',
    title = '',
    managerLogin = '',
    manager = '',
    managerStatus,
    managerShortName = '',
    activityUserLogin = '',
    activityUser = '',
    activityUserAvatar = '',
    favorite,
    activityDate = '',
    articles = 0,
    news = 0,
    files = 0,
    members = 0,
    onClick = () => {},
    onFavoritesHandler,
    link = '',
    onDeleteFromFavorites = () => {},
}) => {
    const { addSuccess } = useMessage();

    const toggleFavorites = async e => {
        e.stopPropagation();

        try {
            if (favorite) {
                onDeleteFromFavorites(id, title);
            } else {
                await api.favorites.addProjectToFavorites(id);
                addSuccess('Проект добавлен в избранное');
            }

            if (onFavoritesHandler) {
                onFavoritesHandler(!favorite, id);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className={cx.project}>
            <div className={cx.head}>
                <div className={cx.title}>
                    <Link to={link} onClick={() => onClick(id)}>
                        <div id={'projectAvatar'} className={cx.image}>
                            {image ? <img alt={''} src={image} /> : <img alt={''} src={'/img/default-icon-project.jpg'} />}
                        </div>
                        <Tippy content={title}>
                            <span>
                                <MultiClamp clamp={2} ellipsis={'...'}>
                                    {title}
                                </MultiClamp>
                            </span>
                        </Tippy>
                    </Link>
                    <div onClick={toggleFavorites}>
                        <Icon
                            className={classNames(cx.favorites, {
                                [cx.favorite]: favorite,
                            })}
                            type={favorite ? Icons.STAR_FILLED : Icons.STAR}
                            width={14}
                            height={14}
                            tooltip={'В избранное'}
                        />
                    </div>
                </div>
                <div className={cx.container}>
                    <div className={cx.manager}>
                        <span>Руководитель:</span>
                        <Tippy content={manager}>
                            {managerStatus === 'DELETED' ? (
                                <span>{managerShortName}</span>
                            ) : (
                                <Link to={'/edit-user/' + managerLogin}>{managerShortName}</Link>
                            )}
                        </Tippy>
                    </div>
                    <div className={cx.activity}>
                        <span>Последняя активность:</span>
                        <Link to={'/edit-user/' + activityUserLogin}>
                            <Tippy content={activityUser}>
                                <div className={cx.avatar} style={{ backgroundImage: `url(${activityUserAvatar})` }} />
                            </Tippy>
                            {formatDateTime(activityDate)}
                        </Link>
                    </div>
                </div>
            </div>
            <ul>
                <li>
                    <p>
                        <Icon type={Icons.NEWS} />
                        <span>Статьи</span>
                    </p>
                    <Link to={'/projects/' + id + '/articles'}>
                        {articles} статей <Icon type={Icons.ARROW_RIGHT} width={12} height={12} />
                    </Link>
                </li>
                <li>
                    <p>
                        <Icon type={Icons.NEWS} />
                        <span>Новости</span>
                    </p>
                    <Link to={'/projects/' + id + '/news'}>
                        {news} новости <Icon type={Icons.ARROW_RIGHT} width={12} height={12} />
                    </Link>
                </li>
                <li>
                    <p>
                        <Icon type={Icons.FILES} />
                        <span>Файлы</span>
                    </p>
                    <Link to={'/projects/' + id + '/files'}>
                        {files} файл <Icon type={Icons.ARROW_RIGHT} width={12} height={12} />
                    </Link>
                </li>
                <li>
                    <p>
                        <Icon type={Icons.USER} />
                        <span>Участники</span>
                    </p>
                    <Link to={'/projects/' + id + '/users'}>
                        {members} участников <Icon type={Icons.ARROW_RIGHT} width={12} height={12} />
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default React.forwardRef((props, ref) => <Project {...props} forwardedRef={ref} />);
