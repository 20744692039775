import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useDebounceValue } from 'lib/hooks';
import Tree from '../infinite-tree/tree';
import { useLocationTree } from 'components/trees/utils';
import Loading from 'uikit/loading';
import Icon, { Icons } from 'uikit/icon';
import cs from './navigation-tree.module.scss';
import { createCheckedBucket } from 'components/trees/infinite-tree/utils';
import IconButton from 'uikit/icon-button';
import { NODE_TYPES } from 'components/trees';
import api from 'api';
import { AdaptiveLink } from 'containers/adaptive-link/adaptive-link';
import { useSelector } from 'react-redux';
import { selectGlobalRootPermActions } from 'slice/authSlice';
import { getSwitchedLayout } from 'lib/util/language.util';
import { toggleTreeNodes } from 'lib/helpers';

const NavigationTree = ({
    forwardedRef,
    onNavigate,
    onDeleteBatch,
    onUnArchiveBatch,
    onArchiveBatch,
    multipleChoice = true,
    getNodeLink,
    isReload,
    showUnreadCounters,
    ...props
}) => {
    const fetchTreeFunc = props.archive ? api.archive.getUsersDocumentTree : api.project.getUsersDocumentTree;
    const { loading, tree } = useLocationTree({
        open: true,
        withArticles: true,
        withSections: true,
        withScripts: false,
        fetchTreeFunc,
        isReload,
    });

    const [query, setQuery] = useState('');
    const [debouncedValue] = useDebounceValue(query.trim().replace(/\s{2,}/g, ' '), 300);
    const [toolbarMode, setToolbarMode] = useState('search');
    const [actionsInProgress, setActionsInProgress] = useState(false);
    const globalRootPermActions = useSelector(selectGlobalRootPermActions);

    const expandAll = () => {
        toggleTreeNodes(forwardedRef.current, false);
    };
    const collapseAll = () => {
        toggleTreeNodes(forwardedRef.current, true);
    };

    useEffect(() => {
        if (forwardedRef?.current?.tree) {
            const { tree } = forwardedRef.current;
            tree.filter(function (node) {
                const name = node.name.toLowerCase();
                const value = debouncedValue.toLowerCase();
                return name.includes(value) || name.includes(getSwitchedLayout(value));
            });
            toggleTreeNodes(forwardedRef.current, debouncedValue.trim().length === 0);
        }
    }, [debouncedValue, forwardedRef]);

    const checkedBucket = React.useRef(createCheckedBucket());
    const _checkedChange = useCallback(() => {
        checkedBucket.current.change(forwardedRef.current.tree);
        const hasChecked = checkedBucket.current.hasChecked();
        setToolbarMode(hasChecked ? 'group_actions' : 'search');
    }, [checkedBucket, forwardedRef]);

    const _clearChecked = useCallback(() => {
        forwardedRef.current.tree.nodes
            .filter(n => n.nodeType === NODE_TYPES.PROJECT)
            .forEach(n => forwardedRef.current.tree.checkNode(n, false));
        setToolbarMode('search');
    }, [forwardedRef]);

    const startAction = useCallback(() => {
        setActionsInProgress(true);
    }, [setActionsInProgress]);

    const finishAction = useCallback(() => {
        setActionsInProgress(false);
        setToolbarMode('search');
    }, [setActionsInProgress]);

    return (
        <div id={'tree-container'} className={cs.tree}>
            {!loading && tree?.length ? (
                <>
                    <>
                        <Loading withOverlay={true} active={actionsInProgress} />
                        {toolbarMode === 'search' && (
                            <div className={cs.search}>
                                <input type="text" placeholder="Поиск по дереву" value={query} onChange={e => setQuery(e.target.value)} />
                                <div className={cs.hideButton} onClick={() => props.setShowTree(isShow => !isShow)}>
                                    <Icon className={cs.icon} type={Icons.ARROW_RIGHT} width={14} height={14} color="blue" />
                                </div>
                            </div>
                        )}
                        {toolbarMode === 'group_actions' && (
                            <div className={cs.groupActions}>
                                <div className={cs.actions}>
                                    {props.archive ? (
                                        <IconButton
                                            icon={<Icon type={Icons.RELOAD} width={16} height={16} color={'gray'} />}
                                            onClick={() => {
                                                onUnArchiveBatch(checkedBucket.current.getState(), startAction, finishAction);
                                            }}
                                        />
                                    ) : (
                                        <IconButton
                                            icon={<Icon type={Icons.ARCHIVE} width={16} height={16} />}
                                            onClick={() => {
                                                onArchiveBatch(checkedBucket.current.getState(), startAction, finishAction);
                                            }}
                                        />
                                    )}
                                    {props.archive && globalRootPermActions?.find(action => action === 'PROJECT_DELETE') && (
                                        <IconButton
                                            icon={<Icon type={Icons.TRASH} width={16} height={16} />}
                                            onClick={() => {
                                                onDeleteBatch(checkedBucket.current.getState(), startAction, finishAction);
                                            }}
                                        />
                                    )}
                                </div>
                                <div className={cs.close}>
                                    <IconButton icon={<Icon type={Icons.CROSS} width={13} height={13} />} onClick={_clearChecked} />
                                </div>
                            </div>
                        )}
                        <div className={cs.toolbar}>
                            <>
                                <div className={cs.btn} onClick={collapseAll}>
                                    <Icon type={Icons.CHEVRONS_UP} width={6.77} />
                                    свернуть всё
                                </div>
                                <div className={cs.btn} onClick={expandAll}>
                                    <Icon type={Icons.CHEVRON_DOWN} width={6.77} />
                                    развернуть всё
                                </div>
                            </>
                        </div>
                        {!loading && props.allButtonTitle && (
                            <AdaptiveLink
                                link={'/' + props.path}
                                onClick={() => {
                                    forwardedRef.current.tree.selectNode(null);
                                    props.setScrollToNode?.(null);
                                }}
                            >
                                <button className={classNames(cs.allBtn, !props.scrollToNode ? cs.allBtnActive : '')}>
                                    <Icon type={Icons.LIST} />
                                    {props.allButtonTitle}
                                </button>
                            </AdaptiveLink>
                        )}
                    </>
                    <div className={cs.sizerContainer}>
                        <AutoSizer>
                            {({ height }) => {
                                return (
                                    <Tree
                                        height={height - 24}
                                        data={tree}
                                        onUpdate={onNavigate}
                                        autoOpen={false}
                                        ref={forwardedRef}
                                        showCheckbox={props.showCheckbox}
                                        {...props}
                                        multipleChoice={multipleChoice}
                                        onCheckedChange={_checkedChange}
                                        highlight={[debouncedValue, getSwitchedLayout(debouncedValue)]}
                                        getNodeLink={getNodeLink}
                                        showUnreadCounters={showUnreadCounters}
                                    />
                                );
                            }}
                        </AutoSizer>
                    </div>
                </>
            ) : loading ? (
                <Loading withOverlay={false} small active={loading} />
            ) : (
                <div className={cs.noTree}>
                    <Icon type={Icons.TEXT_ALIGN_LEFT} width={24} height={24} />
                    <div>Дерево пустое</div>
                </div>
            )}
        </div>
    );
};

export default React.forwardRef((props, ref) => <NavigationTree {...props} forwardedRef={ref}/>);
