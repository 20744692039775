import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import decodeHtmlEntities from 'lib/helpers/decodeHtmlEntities';
import { iFrameDomContentLoaded } from 'lib/util/iframeDomContentLoaded.util';
import { useImagePreviewModal, ImagePreviewModal } from 'components/ImagePreviewModal/index';
import {
    EEmbedDrawIoAttr,
    useEmbedDrawIoDialogStore,
    initializeEmbedDrawIoDialog,
    EmbedDrawIoDialogModal
} from '../tiptap-editor/extensions/EmbedDrawIo/index'
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import { viewerFonts } from './viewer-fonts';
import { ckeditorStyles } from './ckeditor-styles';
import cx from './html-viewer.module.scss';
import { FileViewDialog } from 'components/file/file-view-dialog';
import Loading from '../../uikit/loading';
import api from 'api';
import { useMessage } from 'lib/hooks';
import autoResizeIframe from './iframeResizer';

const HtmlViewer = ({
    id = null,
    className,
    body = '',
    onInit = () => {},
    onAfterLoad = () => {},
    documentUuid = '',
    files,
    isOpenDropdowns = false
}) => {
    const _id = id || 'htmlViewerFrame';
    const {addError} = useMessage();

    const [isLoading, setIsLoading] = useState(true);
    const [autoResizeIframeDisconnect, setAutoResizeIframeDisconnect] = useState(undefined);

    const [termTags, setTermTags] = useState('');
    const [termDefinition, setTermDefinition] = useState('');

    const { isPreviewDialogOpen: isEmbedDrawIoPreviewDialogOpen } = useEmbedDrawIoDialogStore();

    const [selectedFile, setSelectedFile] = useState(null);
    const [isFileViewOpen, setIsFileViewOpen] = useState(false);

    const {
        openImageModal,
        isOpen,
        setIsOpen,
        imageRef,
        imageSrc,
        imageLoading,
        setImageLoading,
        imageZoomInitial,
        imageZoomLimit,
        imageDownload,
        downloadAction
    } = useImagePreviewModal();

    const onTermModalClose = () => {
        setTermTags('');
        setTermDefinition('');
    };
    const initializeTerms = terms => {
        for (let i = 0; i < terms.length; i++) {
            if (!terms[i]) {
                continue;
            }

            terms[i].addEventListener('click', () => {
                setTermTags(terms[i].textContent);
                setTermDefinition(terms[i].getAttribute('data-definition'));
            });
        }
    };

    const initializeImageModal = useCallback(
        images => {
            images.forEach(image => {
                if (!image) return;
                image.addEventListener('click', e =>  e.target.parentNode.tagName !== 'A' && openImageModal(e.target));
            });
        },
        [openImageModal]
    );

    const openFileModal = useCallback(target => {
        setSelectedFile({ fileId: target.getAttribute('data-file-id') });
        setIsFileViewOpen(true);
    }, []);

    const onFileViewModalClose = () => {
        setSelectedFile(null);
        setIsFileViewOpen(false);
    };

    const checkFileExistInDocument = useCallback(async (fileId, articleId) => {
        if (!articleId) {
            return files.some(f => f.status !== 'deleted' && f.fileId === fileId);
        }

        return await api.file.checkFileExistInDocument(fileId, articleId);
    }, [files]);

    const initializeFileModal = useCallback(
        (files, documentUuid) => {
            files.forEach(async file => {
                if (!file) {
                    return;
                }
                
                file.addEventListener('click', async e => {
                    e.preventDefault();
                    if (file.tagName !== 'A') {
                        return;
                    }

                    const isExist = await checkFileExistInDocument(file.getAttribute('data-file-id'), documentUuid);

                    if (!isExist) {
                        addError('Файл удалён из документа')
                        return;
                    }

                    file.tagName === 'A' && openFileModal(file);
                });
            });
        },
        [openFileModal, addError, checkFileExistInDocument]
    );

    const getTables = tables => {
        const response = [];

        tables.forEach(table => {
            const row = table.querySelector('tbody')?.firstChild?.childNodes;

            if (!row) {
                return;
            }

            response.push({
                element: table,
                position: table.getBoundingClientRect(),
            });
        });

        return response;
    };

    const initializeTables = tables => {
        tables.forEach(table => {
            let totalWidth = 0;
            let tableLayoutFixed = true;

            const row = table.querySelector('tbody')?.firstChild?.childNodes;

            if (!row) {
                return;
            }

            for (const element of row) {
                if (!element.style.width) {
                    tableLayoutFixed = false;
                }

                totalWidth += +element.style.width.replace('px', '') || 0;
            }

            table.style.overflowX = 'auto';

            if (totalWidth && totalWidth >= 200) {
                table.style.width = table.width.includes('%') || table.width.includes('px') ? table.width : totalWidth + 'px';
                table.style.tableLayout = !table.width.includes('%') && tableLayoutFixed ? 'fixed' : null;
            }
        });
    };
    const initializeTableActionWrappers = tableActionWrappers => {
        tableActionWrappers.forEach(i => {
            const rows = i.getElementsByTagName('tr');
            const firstColumnTHs = [];

            Array.from(rows).forEach(row => {
                if (!row.firstChild) {
                    return;
                }

                if (row.firstChild.tagName !== 'TH') {
                    return;
                }

                firstColumnTHs.push(row.firstChild);
            });

            if (firstColumnTHs.length <= 1) {
                return;
            }

            let offsetWidth = 0;

            for (const th of firstColumnTHs) {
                offsetWidth = th.offsetWidth < offsetWidth ? offsetWidth : th.offsetWidth;

                th.style.width = offsetWidth + 'px';
                th.style.position = 'sticky';

                th.style.left = 0 + 'px';
                th.style.zIndex = 2;
            }
        });
    };

    const onBeforePrintDetails = allDetails => {
        for (let i = 0; i < allDetails.length; i++) {
            if (allDetails[i].open) {
                allDetails[i].dataset.open = '1';
            } else {
                allDetails[i].setAttribute('open', '');
            }
        }
    };
    const onAfterPrintDetails = allDetails => {
        for (let i = 0; i < allDetails.length; i++) {
            if (allDetails[i].dataset.open) {
                allDetails[i].dataset.open = '';
            } else {
                allDetails[i].removeAttribute('open');
            }
        }
    };

    const initializeFullScreenRollback = (element, iframe) => {
        if (!element) {
            return;
        }

        element.addEventListener('scroll', () => {
            const scrollTop = element.scrollTop;

            if (scrollTop !== 0) {
                document.onfullscreenchange = () => {
                    if (!iframe.contentDocument.fullscreenElement) {
                        element.scrollTop = scrollTop;
                    }
                };
            }
        });
    };

    // TODO: Рассмотреть для рефакторинга
    const initializeLinksScrolling = (links, iframe) => {
        links.forEach(link => {
            if (!link) {
                return;
            }

            if (typeof link?.href.includes === 'function' && link?.href.includes('#') && link?.href.includes(window.location.pathname)) {
                link.addEventListener('click', function (e) {
                    e.preventDefault();

                    const articlePostingCover = document.getElementById('articlePostingCover')?.offsetHeight || 0;
                    const articlePostingDescription = document.getElementById('articlePostingDescription')?.offsetHeight || 0;
                    const postingSearchContainer = document.getElementById('postingSearchContainer')?.offsetHeight || 0;

                    const files = document.getElementById('files');
                    const filesHeight = files?.offsetHeight ?? 0;
                    const anchorId = this.href.slice(this.href.lastIndexOf('#') + 1);

                    let anchorEl =
                        iframe.contentWindow.document.getElementById(anchorId) ||
                        iframe.contentWindow.document.getElementsByName(anchorId)[0] ||
                        null;

                    if (anchorEl) {
                        const scrollWrapper = iframe.closest('.js-scroll-wrapper');
                        anchorEl = anchorEl.closest('tr') || anchorEl.closest('thead') || anchorEl;

                        const elOffsetTop = anchorEl.getBoundingClientRect().top;
                        const postingSearchContainerHeight = document.getElementById('postingSearchContainer')?.offsetHeight || 0;

                        let scrollTop =
                            elOffsetTop +
                            postingSearchContainerHeight +
                            articlePostingCover +
                            articlePostingDescription +
                            filesHeight +
                            postingSearchContainer -
                            16;

                        if (scrollWrapper) {
                            scrollWrapper.scrollTo({
                                top: scrollTop,
                                behavior: 'smooth',
                            });
                        }
                    }
                });
            } else if (!link.target) {
                link.target = '_blank';
            }
        });
    };

    const onDOMContentLoaded = useCallback(() => {
        const iframe = document.getElementById(_id);

        if (iframe.contentWindow == null) {
            return;
        }

        iframe.style.minHeight = 'auto';
        iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight}px`;

        try {
            const autoResizeIframeDisconnect = autoResizeIframe(iframe);
            setAutoResizeIframeDisconnect(() => autoResizeIframeDisconnect);
        } catch {
            iframe.scrolling = 'yes';
            iframe.style.overflow = 'auto';

            iframe.style.minHeight = '800px';
            iframe.style.height = '800px';
        }

        iframe.contentWindow.document.documentElement.style.overflowX = 'auto';
        iframe.contentWindow.document.body.style.overflowX = 'auto';

        initializeLinksScrolling(iframe.contentWindow.document.querySelectorAll('a'), iframe);

        if (isOpenDropdowns) {
            onBeforePrintDetails(iframe.contentWindow.document.body.querySelectorAll('details'));
        }

        onInit?.();
        setIsLoading(false);
    }, [_id, onInit, isOpenDropdowns]);

    useEffect(() => {
        return () => {
            autoResizeIframeDisconnect?.();
        };
    }, [autoResizeIframeDisconnect]);

    useEffect(() => {
        autoResizeIframeDisconnect?.();
        setIsLoading(true);

        const container = document.getElementById('htmlViewerContainer');
        container.removeChild(document.getElementById(_id));

        const iframe = document.createElement('iframe');

        iframe.id = id;
        iframe.class = className;

        const styles = `
            <link rel='stylesheet' href='/github.css'>
        `;
        const scripts = `
            <script src='/heading.js'></script>
            <script src='/highlight.min.js'></script>
            <script>hljs.highlightAll();</script>
        `;

        iframe.setAttribute('srcDoc', viewerFonts + ckeditorStyles + styles + scripts + decodeHtmlEntities(body));
        iframe.setAttribute('title', 'htmlViewer');

        iframe.style.width = '100%';
        iframe.style.minHeight = '800px';

        iframe.setAttribute('scrolling', 'yes');
        container.appendChild(iframe);

        iFrameDomContentLoaded(iframe, onDOMContentLoaded);
        iframe.contentWindow?.addEventListener('load', onAfterLoad);

        const element = document.querySelector('#articlePreviewText, #newsPreviewBodyIframe, #articleContainer, #newsContainer');
        initializeFullScreenRollback(element, iframe);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentUuid, body, _id, className, id]);
    useEffect(() => {
        if (isLoading) {
            return;
        }

        const frame = document.getElementById(_id);

        initializeTerms(frame.contentWindow.document.querySelectorAll('.tiarm'));
        initializeImageModal(frame.contentWindow.document.querySelectorAll('img'));
        initializeFileModal(frame.contentWindow.document.querySelectorAll('a[data-file-id]'), documentUuid);
        initializeEmbedDrawIoDialog.preview(frame.contentWindow.document.querySelectorAll(`div[${ EEmbedDrawIoAttr.DATA_EXPORTED_SVG }]`));

        const tableElements = frame.contentWindow.document.querySelectorAll('table');

        initializeTables(tableElements);
        initializeTableActionWrappers(frame.contentWindow.document.querySelectorAll('.tableActionsWrapper'));

        const tables = getTables(tableElements);

        if (tables.length) {
            const scrollWrapper = frame.closest('.js-scroll-wrapper');

            if (scrollWrapper) {
                const viewer = document.getElementById('htmlViewerContainer');

                scrollWrapper.addEventListener('scroll', e => {
                    let scrollTop = e.target.scrollTop - viewer.offsetTop + 64;

                    tables.forEach(table => {
                        const thead = table.element.querySelector('thead') || table.element.querySelector('th')?.closest('tr');

                        if (!thead) {
                            return;
                        }

                        const tableRect = table.element.getBoundingClientRect();

                        if (tableRect.top <= scrollTop && tableRect.bottom - thead.offsetHeight > scrollTop) {
                            thead.style.top = `${scrollTop - tableRect.top}px`;
                        } else if (tableRect.top >= scrollTop && tableRect.bottom > scrollTop) {
                            thead.style.top = 0;
                        }
                    });
                });
            }
        }

        // Open closed details elements for printing;
        frame.contentWindow?.addEventListener('beforeprint', () =>
            onBeforePrintDetails(frame.contentWindow.document.body.querySelectorAll('details'))
        );

        // After printing close details elements not opened before;
        frame.contentWindow?.addEventListener('afterprint', () =>
            onAfterPrintDetails(frame.contentWindow.document.body.querySelectorAll('details'))
        );
    }, [isLoading, _id, initializeImageModal, initializeFileModal, documentUuid]);

    return (
        <>
            <div id="htmlViewerContainer" className="wrapper">
                <Loading active={isLoading} withOverlay={false} withRelativeOverlay={true} />
                <iframe id={id} className={className} title="htmlViewer" />
                <ImagePreviewModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    imageRef={imageRef}
                    imageSrc={imageSrc}
                    imageLoading={imageLoading}
                    setImageLoading={setImageLoading}
                    imageZoomInitial={imageZoomInitial}
                    imageZoomLimit={imageZoomLimit}
                    imageDownload={imageDownload}
                    downloadAction={downloadAction}
                />
                <Modal
                    className={cx.termModal}
                    isOpen={!!(termTags && termDefinition)}
                    overlayClassName={cx.termModalOverlay}
                    onRequestClose={onTermModalClose}
                >
                    <div className={cx.termModalHeader}>
                        <h2>{termTags}</h2>
                        <IconButton icon={<Icon type={Icons.CROSS} width={16} height={16} />} onClick={onTermModalClose} />
                    </div>
                    <div className={cx.termModalBody}>{termDefinition}</div>
                </Modal>
                {
                    isEmbedDrawIoPreviewDialogOpen && (
                        <EmbedDrawIoDialogModal isOpen={ isEmbedDrawIoPreviewDialogOpen } />
                    )
                }
            </div>
            {isFileViewOpen && <FileViewDialog file={selectedFile} isNoActions={true} handleClose={onFileViewModalClose} />}
        </>
    );
};

export default HtmlViewer;
