import React, { useEffect, useState } from 'react';
import { useNavigate } from 'shared/router';
import { useDialog, useMessage } from 'lib/hooks';
import { TRAINING_QUESTION_TYPES } from 'lib/config/constant';
import { TrainingQuestionAddButton } from 'components/training';
import { Confirmation } from 'components/confirmation/confirmation';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import Input from 'uikit/input/input';
import Button from 'uikit/button';
import api from 'api';
import cx from './training-theme.module.scss';

export default function TrainingTheme({ id, isEdit, reloadTree }) {
    const navigate = useNavigate();

    const { addError } = useMessage();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [themeTitle, setThemeTitle] = useState('');
    const [themeDescription, setThemeDescription] = useState('');
    const [themeQuestions, setThemeQuestions] = useState([]);

    const onCreateTheme = async () => {
        try {
            themeQuestions.map(p => p['type'] = p['type']['value']);
            await api.training.postTheme(themeTitle, themeDescription, themeQuestions);

            reloadTree();
            navigate('/training/catalog');
        } catch (e) {
            console.log(e);
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
        }
    };
    const onUpdateTheme = async () => {
        try {
            themeQuestions.map(p => p['type'] = p['type']['value']);
            await api.training.putTheme(id, themeTitle, themeDescription, themeQuestions);

            reloadTree();
            navigate('/training/catalog');
        } catch (e) {
            console.log(e);
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
        }
    };
    const onDeleteTheme = async () => {
        openDialog({
            title: 'Удаление темы',
            text: (<span>Вы действительно хотите удалить тему?</span>),
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Подтвердить',
            onClose: closeDialog,
            onSubmit: async () => {
                try {
                    closeDialog();
                    await api.training.deleteTheme(id);

                    reloadTree();
                    navigate('/training/catalog');
                } catch (e) {
                    console.log(e);
                    addError('Сервис недоступен. Пожалуйста попробуйте позже.');
                }
            }
        });
    };

    useEffect(() => {
        if (!id) {
            return;
        }

        const fetchData = async () => {
            try {
                const response = await api.training.getTheme(id);
                response.questions.map(p => p.type = TRAINING_QUESTION_TYPES[TRAINING_QUESTION_TYPES.findIndex(p1 => p1.value === p.type)]);

                setThemeTitle(response['title']);
                setThemeDescription(response['description']);
                setThemeQuestions(response['questions']);
            } catch (e) {
                console.log(e);
                addError('Сервис недоступен. Пожалуйста попробуйте позже.');
            }
        };
        fetchData();
    }, [addError, id]);

    return (
        <div className={cx.trainingTheme}>
            <Confirmation {...dialogState}/>
            {(!id || (id && isEdit)) &&
            <div className={cx.trainingThemeCreate}>
                <h1>{(id && isEdit) ? 'Редактирование темы' : 'Создание темы'}</h1>
                <div className={cx.trainingThemeForm}>
                    <div className={cx.trainingThemeFormFields}>
                        <Input id="themeFormTittle" name="title" type="text" className={cx.trainingThemeFormField}
                               label="Заголовок" value={themeTitle} required onChange={(str) => setThemeTitle(str)}/>
                        <Input id="themeFormDescription" name="description" type="text"
                               className={cx.trainingThemeFormField} label="Описание темы" value={themeDescription}
                               required onChange={(str) => setThemeDescription(str)}/>
                        <div className={cx.trainingThemeFormDelimiter}/>
                        <h2>Содержание</h2>
                        <div className={cx.trainingThemeFormInfo}>
                            <Icon type={Icons.INFO} width={15} height={15}/>
                            <span>Вы сможете добавить вопросы в тему после ее создания</span>
                        </div>
                        <TrainingQuestionAddButton questions={themeQuestions}
                                                   onQuestions={(questions) => setThemeQuestions(questions)}/>
                    </div>
                    <div className={cx.trainingThemeFormButtons}>
                        <Button label="Отмена" onClick={() => navigate('/training/catalog')}/>
                        <Button label="Сохранить" color="green" onClick={id ? onUpdateTheme : onCreateTheme}/>
                    </div>
                </div>
            </div>}
            {(id && !isEdit) &&
            <div className={cx.trainingThemeView}>
                <div className={cx.trainingThemeViewHeader}>
                    <ul>
                        <li>
                            <IconButton icon={<Icon type={Icons.EDIT_PEN} width={15}/>}
                                        onClick={() => navigate(`/training/catalog/theme/${id}/edit`)}/>
                        </li>
                        <li>
                            <IconButton icon={<Icon type={Icons.TRASH} width={15}/>}
                                        onClick={() => onDeleteTheme()}/>
                        </li>
                    </ul>
                    <h1>{themeTitle}</h1>
                    <p>{themeDescription}</p>
                </div>
                <div className={cx.trainingThemeViewContent}>
                    <TrainingQuestionAddButton isViewMode={true} questions={themeQuestions}
                                               onQuestions={(questions) => setThemeQuestions(questions)}/>
                </div>
            </div>}
        </div>
    );
};
