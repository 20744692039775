import { Node, mergeAttributes } from '@tiptap/core';

export const TableOfContentsList = Node.create({
    name: 'tableOfContentsList',
    group: 'block list',
    content: 'tableOfContentsItem+',

    addAttributes() {
        return {
            'data-type': {
                default: null,
                parseHTML: el => el.getAttribute('data-type'),
                renderHTML: attrs => ({ 'data-type': attrs['data-type'] }),
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'ul',
                getAttrs: el => el.getAttribute('data-type') === 'toc-list',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['ul', mergeAttributes(HTMLAttributes, { 'data-type': 'toc-list' }), 0];
    },
});
