import React from 'react';

export interface GetProviderProps {
    children: React.ReactNode;
}

export interface RenderRouteGroupProps {
    children: any;
}

export interface RenderRouteProps {
    component: React.ReactNode;
    children?: any;
    to?: string;
    isDefault?: boolean;
}

export interface RenderOutletProps {
    children?: any;
}

export interface Location {
    origin: string;
    search: string;
    pathname: string;
}

export interface RenderLinkProps {
    children: React.ReactNode;
    to: string;
}

export abstract class Router {
    // eslint-disable-next-line no-unused-vars
    abstract getProvider: ({ children }: GetProviderProps) => React.ReactNode;
    // eslint-disable-next-line no-unused-vars
    abstract getRouteGroup: ({ children }: RenderRouteGroupProps) => React.ReactNode;
    // eslint-disable-next-line no-unused-vars
    abstract getRoute: ({ component, children, to, isDefault }: RenderRouteProps) => React.ReactNode;
    // eslint-disable-next-line no-unused-vars
    abstract getOutlet: ({ children }: RenderOutletProps) => React.ReactNode;
    abstract getParams: () => Function;
    abstract getLocation: () => Location;
    // eslint-disable-next-line no-unused-vars
    abstract getLink: ({ children, to }: RenderLinkProps) => React.ReactNode;
    abstract getUseNavigate: () => Function;
    abstract getNavigateFunction: () => Function;
    // eslint-disable-next-line no-unused-vars
    abstract setNavigateFunction: (func: Function) => void;
    abstract getCustomNavigateFunction: () => Function | undefined;
    // eslint-disable-next-line no-unused-vars
    abstract setCustomNavigateFunction: (func: Function) => void;
}
