import React, { useContext, useEffect } from 'react';
import cs from './submenu.module.scss';
import { useGlobalContext } from 'lib/hooks';
import { MENU_CONTENT_TYPE, MobileMenuContext } from 'containers/menu-wrapper/menu-wrapper';
import { useLocation, useNavigate } from 'shared/router';
import { useDispatch, useSelector } from 'react-redux';
import { selectMenuButtons } from 'slice/authSlice';
import cn from 'classnames';
import { useConfiguration } from '../../lib/context';

const Submenu = ({menuConfig, mobileMenuTitle = ''}) => {
    const { platform } = useGlobalContext();
    const { setRenderMenu, setMenuContentType, setMenuTitle } = useContext(MobileMenuContext);

    const { isScriptingEnabled } = useConfiguration();

    if (!isScriptingEnabled) {
        const scriptingIndex = menuConfig.findIndex((item) => item.to?.includes('script'));
        if (scriptingIndex !== -1) {
            menuConfig.splice(scriptingIndex, 1);
        }
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const location = useLocation();
    const menuButtons = useSelector(selectMenuButtons);

    const menuItems = React.useMemo(() => {
        return menuButtons ? menuConfig.filter(menu => !menu.auth || menuButtons[menu.auth] === true) : [];
    }, [menuButtons, menuConfig]);

    useEffect(() => {
        if (platform !== 'desktop' && setMenuContentType && setRenderMenu) {
            setMenuContentType(MENU_CONTENT_TYPE.CUSTOM);
            setMenuTitle(mobileMenuTitle);

            setRenderMenu((onClose) => {
                return (
                    <>
                        {menuConfig.filter(menu => !menu.auth || menuButtons[menu.auth] === true).map((menu, i) => (
                        <button id={menu.id} key={i} className={cn(cs.submenuItem, {[cs.active]: menu.to && location.pathname.includes(menu.to)})}
                                onClick={() => {
                                    if (menu.to) {
                                        navigate(menu.to);
                                    } else if (menu.action) {
                                        menu.action(dispatch);
                                    }

                                    onClose();
                                }}>
                            {menu.name}
                        </button>))}
                    </>
                );
            })
        }
    }, [setMenuContentType, setRenderMenu, platform, location.pathname, dispatch, menuButtons, navigate, setMenuTitle, menuConfig, mobileMenuTitle]);

    if (platform !== 'desktop') {
        return <div/>;
    }

    return (
        <div id="sub-menu-container" className={cs.container}>
            {menuItems.map((item, index) => {
                const isCurrent = item.links
                    ? item.links.filter(p => location.pathname.indexOf(p) !== -1).length !== 0
                    : item.to === location.pathname;

                return (
                    <button
                        id={item.id}
                        key={index}
                        className={cn({
                            [cs.current]: isCurrent
                        })}
                        onClick={!isCurrent ? () => {
                            if (item.to) {
                                navigate(item.to);
                            } else if (item.action) {
                                item.action(dispatch);
                            }
                        } : null}
                    >
                        {item.name}
                    </button>
                );
            })}
        </div>
    );
};

export default Submenu;
