import React, { useContext } from 'react';
import { RouterContext } from '../routerProvider';
import { RenderRouteGroupProps } from '../routers/router';

export const RouteGroup = (props: RenderRouteGroupProps): React.ReactNode => {
    const context = useContext(RouterContext);

    if (!context) {
        throw new Error('RouteGroup must be used within a RouterProvider');
    }

    return context.router.getRouteGroup(props);
};
