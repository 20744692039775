import { createContext, ReactNode } from 'react';
import { Router } from './routers/router';
import { DEFAULT_ROUTER } from './constants';

interface RouterContextProps {
    router: Router;
}

export const RouterContext = createContext<RouterContextProps>({
    router: DEFAULT_ROUTER
});

export let CurrentRouter: Router = DEFAULT_ROUTER;

interface RouterProviderProps {
    children: ReactNode;
    router?: Router;
}

export const RouterProvider = ({ children, router = DEFAULT_ROUTER }: RouterProviderProps) => {
    let currentRouter = router;

    if (!(currentRouter instanceof Router)) {
        currentRouter = DEFAULT_ROUTER;
    }

    CurrentRouter = currentRouter;

    return (
        <RouterContext.Provider value={{ router: currentRouter }}>
            <currentRouter.getProvider>
                {children}
            </currentRouter.getProvider>
        </RouterContext.Provider>
    );
};
