/* eslint-disable no-unused-vars */

export enum EEmbedDrawIoMode {
    EDIT_MODE,
    VIEWER_MODE,
}

export enum EEmbedDrawIoAction {
    CONFIGURE = 'configure',
    INIT = 'init',
    LOAD = 'load',
    SAVE = 'save',
    EXPORT = 'export',
    EXIT = 'exit',
}

export enum EEmbedDrawIoError {
    ERROR_PROCESSING_MESSAGE = '[Embed Draw.io] Ошибка обработки сообщения:',
    INVALID_MESSAGE_SOURCE = '[Embed Draw.io] Неверный источник сообщения:',
    UNKNOW_ACTION = '[Embed Draw.io] Неизвестное событие:',
}

export enum EEmbedDrawIoAttr {
    NAME_EXTENSION = 'embed-draw-io-image-ext',
    DATA_EXPORTED_SVG = 'data-embed-draw-io-exported-svg',
    DATA_ENCODED_XML = 'data-embed-draw-io-encoded-xml',
    DATA_IMAGE_SRC = 'data-embed-draw-io-image-src',
    TITLE_IFRAME = 'embed-draw-io-dialog-iframe',
}