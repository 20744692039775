import React from 'react';
import cs from '../infinite-tree/tree-toggler.module.scss';

const MobileNodeToggler = ({ state, depth, ...props }) => {
    const iconUrl = state === 'closed' ? '/icons/right.svg' : state === 'opened' ? '/icons/down.svg' : '';

    return (
        <div
            className={cs.toggler}
            style={{ marginLeft: `${depth * 24}px`, backgroundImage: `url(${iconUrl})` }}
            {...props}
        />
    );
};

export default MobileNodeToggler;
