import React from 'react';
import cx from './global.module.scss';
import { useConfiguration } from '../../lib/context';

const ArchiveGlobal = ({ tab, onTabChange }) => {
    const { isScriptingEnabled } = useConfiguration();

    return (
        <div className={cx.archiveGlobal}>
            <ul>
                <li className={!tab || tab === 'articles' ? cx.active : ''}
                    onClick={() => onTabChange('articles')}>Статьи
                </li>
                <li className={tab === 'news' ? cx.active : ''}
                    onClick={() => onTabChange('news')}>Новости
                </li>
                {isScriptingEnabled && (
                    <li id={cx.scriptsTab} className={tab === 'scripts' ? cx.active : ''}
                        onClick={() => onTabChange('scripts')}>Скрипты
                    </li>
                )}
            </ul>
        </div>
    );
};

export default ArchiveGlobal;
