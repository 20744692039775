import React, { useState, useEffect, useMemo, useRef } from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import TagsInput from 'react-tagsinput';
import { humanFileSize } from 'lib/helpers';
import { useWindowSize } from 'lib/hooks/useWindowSize';
import { textValidator } from 'lib/util/validators.util';
import { desktopStyles, mobileStyles } from 'lib/util/modalStyles';
import { useDialog, useGlobalContext, useMessage } from 'lib/hooks';
import GlossaryService from 'services/GlossaryService';
import Text from 'components/text/text';
import Confirmation from 'components/confirmation';
import { TableComponent } from 'components/data-components';
import Button from 'uikit/button';
import Loading from 'uikit/loading';
import { Select } from 'uikit/select';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import { EditRowToolbar } from 'uikit/table';
import ProgressBar from 'uikit/progress-bar/progress-bar';
import { SingleValueWithImg, OptionWithImg } from 'uikit/select/components/select-components-with-img';
import api from 'api';
import cx from './glossary-page.module.scss';
import { setDocumentTitle } from '../../../shared/router';

const GlossaryPage = () => {
    const tableRef = useRef(null);

    const { platform } = useGlobalContext();
    const { width } = useWindowSize();

    const { addError, addSuccess } = useMessage();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [isLoading, setIsLoading] = useState(false);

    const [isModal, setIsModal] = useState(false);
    const [isExport, setIsExport] = useState(false);

    const [isImport, setIsImport] = useState(false);
    const [importStage, setImportStage] = useState(0);

    const [importFileName, setImportFileName] = useState('');
    const [importFileCurrent, setImportFileCurrent] = useState(0);
    const [importFileTotal, setImportFileTotal] = useState(0);

    const [projects, setProjects] = useState([]);
    const [tableProjects, setTableProjects] = useState(undefined);

    const [termTitleError, setTermTitleError] = useState(null);
    const [termDescriptionError, setTermDescriptionError] = useState(null);

    const [termId, setTermId] = useState(null);
    const [termProject, setTermProject] = useState({ label: 'Общие', value: null });
    const [termTitle, setTermTitle] = useState([]);
    const [termDescription, setTermDescription] = useState('');

    const [glossaryTotal, setGlossaryTotal] = useState(1);
    const [glossaryData, setGlossaryData] = useState([]);

    const onCloseModal = () => {
        setTermTitleError(null);
        setTermDescriptionError(null);

        setTermId(null);
        setTermProject({ label: 'Общие', value: null });

        setTermTitle([]);
        setTermDescription('');

        setIsModal(false);
    };
    const onTerm = async () => {
        setTermTitleError(null);
        setTermDescriptionError(null);

        if (termTitle.length === 0) {
            setTermTitleError('Поле обязательно для заполнения');
            return false;
        }

        if (termDescription.length === 0) {
            setTermDescriptionError('Поле обязательно для заполнения');
            return false;
        }

        try {
            if (termId) {
                await api.glossary.update(termId, termProject['value'], termTitle, termDescription);
            } else {
                await api.glossary.create(termProject['value'], termTitle, termDescription);
            }

            addSuccess(termId ? 'Термин успешно изменен.' : 'Термин успешно создан.');
        } catch {
            addError(termId ? 'Не удалось изменить термин.' : 'Не удалось создать термин.');
        }

        setTermId(null);
        setTermProject({ label: 'Общие', value: null });
        setTermTitle([]);
        setTermDescription('');

        GlossaryService.invalidate();
        tableRef?.current?.reload();

        setIsModal(false);
    };

    const onChangeTermTitle = async (value) => {
        setTermTitleError(null);
        let validationError = null;

        const tags = value.map(p => {
            let tag = p.replaceAll('&nbsp;', ' ').replace(/\s+/g, ' ').trim();

            if (tag.length > 128) {
                tag = tag.substring(0, 128);
            }

            return tag;
        });

        for (let i = 0; i < tags.length; i++) {
            validationError = textValidator(tags[i], 128, true);

            if (validationError != null) {
                break;
            }

            let validationResponse = null;

            if (termId) {
                validationResponse = await api.glossary.validateExceptTerm(termProject['value'], tags[i], termId);
            } else {
                validationResponse = await api.glossary.validate(termProject['value'], tags[i]);
            }

            if (!validationResponse || !validationResponse['data']) {
                validationError = 'Такой термин уже существует.';
                break;
            }

            let sameValues = [];

            for (let j = 0; j < tags.length; j++) {
                if (tags[i].toLowerCase() === tags[j].toLowerCase()
                    || tags[i].toLowerCase() === tags[j].toLowerCase().replace('е', 'ё')
                    || tags[i].toLowerCase() === tags[j].toLowerCase().replace('ё', 'е')) {
                    sameValues.push(tags[j]);
                }
            }

            if (sameValues.length > 1) {
                validationError = 'Такой термин уже существует.';
                break;
            }
        }

        if (validationError !== null) {
            setTermTitleError(validationError);
        } else {
            setTermTitle(tags);
        }
    };
    const onGlossaryPaginate = async (offset, count, sort = { by: '', desc: false }, search = '', filters = {}) => {
        try {
            setIsLoading(true);

            const response = await api.glossary.list(
                offset / count,
                count,
                sort.by ? (sort.by + ',' + (sort.desc ? 'desc' : 'asc')) : 'createTime,desc',
                search,
                filters
            );

            setGlossaryTotal(response['data']['totalElements']);
            setGlossaryData(response['data']['content']);

            setIsLoading(false);
        } catch {
            setIsLoading(false);
        }
    };

    const onImport = async (files) => {
        try {
            const formData = new FormData();
            formData.append('file', files[0]);

            setImportFileName(files[0]['name']);
            setImportFileTotal(files[0]['size']);

            setImportStage(1);

            await api.glossary.import(formData, async (data) => {
                setImportFileCurrent(data['loaded']);
                setImportFileTotal(data['total']);

                if (data['loaded'] === data['total']) {
                    setImportStage(2);
                }
            }, null);

            GlossaryService.invalidate();
            tableRef?.current?.reload();
        } catch {
            addError('Не удалось импортировать. Пожалуйста убедитесь в правильности файла импорта.');
        }

        setImportFileName('');
        setImportFileCurrent(0);
        setImportFileTotal(0);

        setImportStage(0);
        setIsImport(false);
    };
    const onCloseImport = () => {
        setIsImport(false);
        setImportStage(0);
    };

    const onExport = () => {
        setIsExport(true);

        fetch(api.glossary.export())
            .then(response => response.blob())
            .then(blob => {
                const link = document.createElement('a');

                link.href = URL.createObjectURL(blob);
                link.download = 'Глоссарий.xlsx';

                link.click();
                setIsExport(false);
            })
            .catch(console.error);
    };
    const onCloseExport = () => {
        setIsExport(false);
    };

    const glossaryColumns = useMemo(() => [
        {
            Header: 'Термин и определение',
            accessor: 'definition',
            Cell: (data) => {
                return (
                    <div className={cx.tableColumnTitle}>
                        <p>{data.row.original['termTags'].map((item) => item['termTag']).join(', ')}</p>
                        <span>{data.row.original['termDefinition']}</span>
                    </div>
                );
            }
        },
        {
            Header: 'Проект',
            accessor: 'projectId',
            width: 130,
            Cell: (data) => {
                return data.row.original['project'] ? data.row.original['project']['title'] : 'Общие';
            }
        },
        {
            Header: 'Дата изменения',
            accessor: 'modifyTime',
            width: 130,
            Cell: (data) => {
                const createTime = new Date(data.row.original['modifyTime'] || data.row.original['createTime']).toLocaleString();

                return (
                    <div>{createTime.substr(0, createTime.length - 3)}</div>
                )
            }
        },
        {
            id: 4,
            Header: '',
            settings: platform === 'mobile' ? ['no_td_wrap'] : [],
            width: 65,
            Cell: function(data) {
                let toolbar = [];

                toolbar.push({
                    icon: Icons.EDIT_PEN,
                    tooltip: 'Редактировать',
                    onClick: () => {
                        setTermId(data.row.original['id']);
                        setTermProject(data.row.original['project']
                            ? projects.find((project) => project['value'] === data.row.original['project']['id'])
                            : projects[0]);

                        setTermTitle(data.row.original['termTags'].map((item) => item['termTag']));
                        setTermDescription(data.row.original['termDefinition']);

                        setIsModal(true);
                    }
                });

                toolbar.push({
                    icon: Icons.TRASH,
                    tooltip: 'Удалить',
                    onClick: () => {
                        openDialog({
                            title: 'Удаление термина',
                            text: 'Вы действительно хотите удалить термин?',
                            closeBtnText: 'Нет, отменить',
                            submitBtnText: 'Да, удалить',
                            onClose: closeDialog,
                            onSubmit: async () => {
                                try {
                                    await api.glossary.remove(data.row.original['id']);
                                    addSuccess('Термин успешно удален.');
                                } catch (ex) {
                                    addError('Не удалось удалить термин.');
                                }

                                GlossaryService.invalidate();
                                tableRef?.current?.reload();

                                closeDialog();
                            },
                            contentType: 'TEXT'
                        });
                    }
                });

                return EditRowToolbar(toolbar, null, true, false, true)(data);
            }
        }
    ], [addError, addSuccess, openDialog, closeDialog, platform, projects]);
    const glossaryActions = useMemo(() => {
        const actions = [];

        actions.push({
            icon: Icons.TRASH,
            label: 'Удалить',
            onClick: (data) => {
                openDialog({
                    title: 'Удаление терминов',
                    text: 'Вы действительно хотите удалить термины?',
                    closeBtnText: 'Нет, отменить',
                    submitBtnText: 'Да, удалить',
                    onClose: closeDialog,
                    onSubmit: async () => {
                        try {
                            for (let i = 0; i < data.length; i++) {
                                await api.glossary.remove(data[i].original['id']);
                            }

                            addSuccess('Термины успешно удалены.');
                        } catch (ex) {
                            addError('Не удалось удалить термины.');
                        }

                        GlossaryService.invalidate();
                        tableRef?.current?.reload();

                        closeDialog();
                    },
                    contentType: 'TEXT'
                });
            }
        });

        return actions;
    }, [addError, addSuccess, openDialog, closeDialog]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.project.getProjects(0, 2000);
                const projects = response['content'].map((project) => {
                    return {
                        label: project['title'],
                        img: project['logoUuid'] ? api.project.getSmallLogo(project['logoUuid']) : null,
                        value: project['id']
                    };
                });

                projects.unshift({ label: 'Общие', img: null, value: null });
                setProjects(projects);

                const tableProjects = Object.assign([], projects);
                tableProjects.unshift({ label: 'Все', img: null, value: '' });

                setTableProjects(tableProjects);
            } catch (e) {
                console.log(e);
                addError('Сервис недоступен. Пожалуйста попробуйте позже.');
            }
        };
        fetchData();
    }, [addError]);
    useEffect(() => {
        setDocumentTitle('Глоссарий — KMS Gran');
    }, []);

    return (
        <div className={cx.contentWrapper}>
            <Confirmation {...dialogState}/>

            <Modal className={cx.glossaryModal} overlayClassName={cx.glossaryModalOverlay} isOpen={isModal}
                   contentLabel={termId ? 'Изменить термин' : 'Новый термин'} onRequestClose={onCloseModal}
                   style={platform === 'mobile' ? mobileStyles : desktopStyles}>
                <div className={cx.glossaryModalHeader}>
                    <h2>{termId ? 'Изменить термин' : 'Новый термин'}</h2>
                    <IconButton icon={<Icon type={Icons.CROSS} width={14} height={14}/>} onClick={onCloseModal}/>
                </div>
                <div className={cx.glossaryModalBody}>
                    <div className={cx.glossaryModalBodyRow}>
                        <label htmlFor="termProject">Проект</label>
                        <Select id="termProject" options={projects} value={termProject}
                                components={{ SingleValue: SingleValueWithImg, Option: OptionWithImg }}
                                onChange={(option) => setTermProject(option)}/>
                    </div>
                    <div className={classNames(cx.glossaryModalBodyRow, termTitleError ? cx.glossaryModalBodyRowError : null)}>
                        <label htmlFor="termTitle">Термин</label>
                        <TagsInput className={classNames('react-tagsinput', cx.reactTagsInput)}
                                   inputProps={{ id: 'termTitle', className: 'react-tagsinput-input', placeholder: 'Термин' }}
                                   value={termTitle} onChange={onChangeTermTitle}
                                   renderTag={(data) => {
                                       return (
                                           <div key={data['key']} className={cx.reactTagsInputTag}>
                                               <span>{data['tag']}</span>
                                               <div onClick={() => data['onRemove'](data['key'])}>
                                                   <Icon type={Icons.CROSS} width={6} height={6}/>
                                               </div>
                                           </div>
                                       );
                                   }}/>
                        {!termTitleError && <span>Нажмите Enter чтобы применить ввод в поле</span>}
                        {termTitleError && <span>{termTitleError}</span>}
                    </div>
                    <Text id="termDescription" name="termDescription" label="Определение" value={termDescription}
                          onChange={(value) => setTermDescription(value)} maxLength={512} isCounter={true}
                          isError={termDescriptionError && termDescription.length === 0} error={termDescriptionError}/>
                </div>
                <div className={cx.glossaryModalFooter}>
                    <Button label="Отмена" onClick={onCloseModal}/>
                    <Button label="Подтвердить" color="green" onClick={onTerm}/>
                </div>
            </Modal>

            <Modal className={cx.glossaryImportModal} overlayClassName={cx.glossaryModalOverlay} isOpen={isImport}
                   onRequestClose={onCloseImport} style={platform === 'mobile' ? mobileStyles : desktopStyles}>
                <div className={cx.glossaryImportModalHeader}>
                    <h2>{importStage === 1 ? 'Загрузка файла' : 'Импорт словаря'}</h2>
                    <IconButton icon={<Icon type={Icons.CROSS} width={14} height={14}/>} onClick={onCloseImport}/>
                </div>
                <div className={cx.glossaryImportModalBody}>
                    {importStage === 0 &&
                    <div className={cx.glossaryImportModalBodyStage0}>
                        <div className={cx.glossaryImportModalBodyDropzone}>
                            <Dropzone onDrop={onImport}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: cx.dropZone })}>
                                        <input {...getInputProps()} />
                                        <Icon type={Icons.DOWNLOAD} width={24} height={24} color="blue"/>
                                        <p>Перетащите файл в эту область или нажмите для выбора файла с компьютера</p>
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                        <div className={cx.glossaryImportModalBodySeparator}/>
                        <p>Воспользуйтесь шаблоном Excel таблицы для заполнения словаря:</p>
                        <div className={cx.glossaryImportModalBodyTemplate}>
                            <div className={cx.glossaryImportModalBodyTemplateContainer}>
                                <Icon type={Icons.XLS_FILE} width={24} height={24}/>
                                <div>
                                    <p>Шаблон-для-Импорта</p>
                                    <span>XLS — 315 Кб</span>
                                </div>
                            </div>
                            <a href={api.glossary.template()} download>
                                <Button>
                                    <Icon type={Icons.DOWNLOAD} width={18} height={18}/>
                                    <span>Скачать шаблон</span>
                                </Button>
                            </a>
                        </div>
                    </div>}

                    {importStage === 1 &&
                    <div className={cx.glossaryImportModalBodyStage1}>
                        <Icon type={Icons.XLS_FILE} width={32} height={32}/>
                        <div>
                            <p>{importFileName}</p>
                            <ProgressBar now={importFileCurrent * 100 / importFileTotal}/>
                            <span>{humanFileSize(importFileCurrent)} / {humanFileSize(importFileTotal)}</span>
                        </div>
                    </div>}

                    {importStage === 2 &&
                    <div className={cx.glossaryImportModalBodyStage2}>
                        <Loading withOverlay={false} small/>
                        <p>
                            Дождитесь завершения процесса перезаписи словаря. При закрытии этого окна результат
                            перезаписи не будет сохранен.
                        </p>
                    </div>}
                </div>
                {importStage !== 0 &&
                <div className={cx.glossaryImportModalFooter}>
                    <Button label={importStage === 1 ? 'Отменить загрузку' : 'Отменить импорт'} onClick={onCloseImport}/>
                </div>}
            </Modal>

            <Modal className={cx.glossaryExportModal} overlayClassName={cx.glossaryModalOverlay} isOpen={isExport}
                   onRequestClose={onCloseExport} style={platform === 'mobile' ? mobileStyles : desktopStyles}>
                <div className={cx.glossaryExportModalHeader}>
                    <h2>Экспорт словаря</h2>
                    <IconButton icon={<Icon type={Icons.CROSS} width={14} height={14}/>} onClick={onCloseExport}/>
                </div>
                <div className={cx.glossaryExportModalBody}>
                    <Loading withOverlay={false} small/>
                    <p>Дождитесь окончания формирования словаря. Скачивание файла начнется автоматически.</p>
                </div>
                <div className={cx.glossaryExportModalFooter}>
                    <Button label="Отменить экспорт" onClick={onCloseExport}/>
                </div>
            </Modal>

            {tableProjects && (
                <TableComponent
                    innerRef={tableRef}
                    isMobile={platform === 'mobile'}
                    isLoading={isLoading}
                    name="glossary"
                    title="Глоссарий"
                    searchTitle="Поиск по названию..."
                    columns={glossaryColumns}
                    actions={glossaryActions}
                    total={glossaryTotal}
                    data={glossaryData}
                    onPaginate={onGlossaryPaginate}
                    addTitle="Добавить термин"
                    onAdd={() => setIsModal(true)}
                    onImport={width < 768 || width > 1024 ? () => setIsImport(true) : null}
                    onExport={width < 768 || width > 1024 ? onExport : null}
                    selectField="projectId.equals"
                    selectOptions={tableProjects}
                />
            )}
        </div>
    );
};

export default GlossaryPage;
