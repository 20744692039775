import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';

export const PREVENT_COL_CLICK_RESET_META = 'prevent-col-click-reset';
let lastPreventTransaction = null;

export const ColumnClickReset = Extension.create({
    name: 'columnClickReset',

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey('columnClickReset'),
                filterTransaction: (transaction) => {
                    if (transaction.selectionSet && lastPreventTransaction) {
                        lastPreventTransaction = null;
                        return false;
                    }

                    if (transaction.getMeta(PREVENT_COL_CLICK_RESET_META)) {
                        lastPreventTransaction = transaction;
                    }

                    return true;
                },
            }),
        ];
    },
});
