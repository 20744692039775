import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { setDocumentTitle, useNavigate, useParams } from 'shared/router';
import { useAuthorized, useDialog, useGlobalContext, useMessage } from 'lib/hooks';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import { ContentWrapper } from 'containers/content-wrapper';
import { Confirmation } from 'components/confirmation/confirmation';
import { ChatMenu, ChatWindow, ChatGroup, ChatDialog } from 'components/chat';
import Icon, { Icons } from 'uikit/icon';
import { ChatMessage, ChatSubscribe } from 'api/chat-wss';
import api from 'api';
import cx from './chat-page.module.scss';
import { MENU_CONTENT_TYPE, MobileMenuContext } from 'containers/menu-wrapper/menu-wrapper';

export default function ChatPage () {
    const params = useParams();
    const id = params['*'];

    const { platform } = useGlobalContext();
    const selector = useSelector(state => state);

    const { isAuthorizedAction } = useAuthorized();
    const navigate = useNavigate();

    const { dialogState, openDialog, closeDialog } = useDialog();
    const { addError } = useMessage();

    const session = selector.auth.user;
    const usersData = selector.auth.users;

    const [users, setUsers] = useState([]);

    const [chats, setChats] = useState(null);
    const [subscribeMessage, setSubscribeMessage] = useState(null);

    const [currentChat, setCurrentChat] = useState(null);
    const [updateChat, setUpdateChat] = useState(null);

    const [isCreateDialog, setIsCreateDialog] = useState(false);
    const [isCreateGroup, setIsCreateGroup] = useState(false);

    const idChanged = useRef(false);
    const chatChanged = useRef(false);

    const onOpenChat = useCallback(async (chatId, type, data) => {
        // Change chat route;
        navigate('/chat/' + chatId);

        // Select chat by input data;
        let chat = chats.find((item) => item.id === chatId && item.type === type);

        if (!chat && type === 0) {
            const user = users.find((item) => item.id === chatId);

            if (!user) {
                return;
            }

            chat = {
                id: user.id,
                login: user.login,
                type: 0,
                title: user.firstName + ' ' + user.lastName,
                image: user && user['avatarUuid']
                    ? api.upload.getImage(user['avatarUuid'], false, 128) : '/img/avatar.png',
                isPrimary: false,
                isRead: 0,
                lastChatMessage: {
                    firstName: '',
                    lastName: '',
                    message: '',
                    date: ''
                },
                groups: chats.filter((chat) => chat.type === 1).filter((group) => {
                    return group.users.find((groupUser) => groupUser.login === user.login) !== undefined;
                })
            };
        } else if ((!chat || data) && type === 1) {
            let chatsCopy = Object.assign([], chats);

            if (chat) {
                chatsCopy.splice(chatsCopy.indexOf(chat), 1);
            }

            chat = {
                id: data.id,
                login: '',
                type: 1,
                title: data.name,
                description: data.description,
                image: data && data['avatarUuid'] ? api.chat.getLogo(data['avatarUuid']) : '/img/avatar.png',
                isPrimary: false,
                isRead: 0,
                lastChatMessage: chat ? chat.lastChatMessage : {
                    firstName: '',
                    lastName: '',
                    message: '',
                    date: ''
                },
                groups: data.userGroups.map(group => {
                    group['members'].map(member => {
                        member['image'] = member && member['avatarUuid']
                            ? api.upload.getImage(member['avatarUuid'], false, 128) : '/img/avatar.png';
                        return member;
                    });
                    return group;
                }),
                users: data.users.map((user) => {
                    const userData = users.find((item) => item['login'] === user['login']);
                    user['image'] = userData && userData['avatarUuid']
                        ? api.upload.getImage(userData['avatarUuid'], false, 128) : '/img/avatar.png';
                    return user;
                })/*.filter((user) => user['login'] !== session['login'])*/
            };

            chatsCopy.unshift(chat);
            setChats(chatsCopy);
        }

        // Select all chat messages;
        let messages = [];

        try {
            if (chat.type === 0) {
                messages = await api.chat.getChatDialogMessages(chat.id, 0, 2000, 'createdDateTime,desc');
            } else {
                messages = await api.chat.getChatGroupMessages(chat.id, 0, 2000, 'createdDateTime,desc');
            }
        } catch (e) {
            console.log(e);
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
            return;
        }

        chat.messages = messages.map((message) => {
            let replyMessage = null;

            if (message['message'].indexOf('{[<>JSON<>]}') !== -1) {
                const messageArr = message['message'].split('{[<>JSON<>]}');

                message['message'] = messageArr[0];
                replyMessage = JSON.parse(messageArr[1]);
            }

            return {
                id: message['id'],
                sender: message['fromUserId'],
                firstName: message['fromUserFirstName'],
                lastName: message['fromUserLastName'],
                avatar: '',
                message: message['message'],
                isUpdate: message['modifiedDateTime'] !== null,
                isRead: message['seen'],
                date: new Date(message['createdDateTime']),
                original: message,
                replyMessage: replyMessage
            };
        }).sort((a, b) => a.date > b.date ? 1 : -1);

        // Read all chat messages;
        if (chat.type === 0) {
            api.chat.seeChatDialogMessages(chat.id);
        } else {
            api.chat.seeChatGroupMessages(chat.id);
        }

        // Set chat to online;
        chat.isOnline = true;
        chat.isRead = 0;

        // Set current chat;
        setCurrentChat(chat);

        // Scroll chat window;
        setTimeout(() => {
            const chatWindow = document.getElementById('chat-window');

            if (!chatWindow) {
                return;
            }

            chatWindow.scrollTop = chatWindow.scrollHeight;
        }, 1);

        // Close modals;
        setIsCreateDialog(false);
        setIsCreateGroup(false);

        chatChanged.current = true;
    }, [navigate, addError, chats, users]);
    const onMessage = async (chatId, message) => {
        // Check message;
        if (!message.trim()) {
            return;
        }

        // Send message;
        if (currentChat.type === 0) {
            ChatMessage(session.id, chatId, 0, message);
        } else if (currentChat.type === 1) {
            ChatMessage(session.id, 0, chatId, message);
        }
    };

    const onUpdateChatGroup = async (chatId) => {
        const chat = chats.find((item) => item.id === chatId && item.type === 1);

        setCurrentChat(null);
        setUpdateChat(chat);

        setIsCreateGroup(true);
    };
    const onRemoveChatGroup = async (id) => {
        openDialog({
            title: 'Удаление группового чата',
            text: (<span>Вы действительно хотите удалить групповой чат?</span>),
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Подтвердить',
            onClose: closeDialog,
            onSubmit: async () => {
                await api.chat.deleteChatGroup(id);

                setCurrentChat(null);
                setChats(chats.filter((chat) => chat.id !== id));

                closeDialog();
                navigate('/chat');
            }
        });
    };
    const onRemoveDialog = async (id) => {
        openDialog({
            title: 'Удаление диалога',
            text: (<span>Вы действительно хотите удалить диалог?</span>),
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Подтвердить',
            onClose: closeDialog,
            onSubmit: async () => {
                await api.chat.deleteDialog(id);

                setCurrentChat(null);
                setChats(chats.filter((chat) => chat.id !== id));

                closeDialog();
                navigate('/chat');
            }
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            // Clear subscribe message;
            setSubscribeMessage(null);

            // Select chat;
            let chat = null;

            if (subscribeMessage.chatGroupId) {
                chat = chats.find((item) => item.id === subscribeMessage.chatGroupId);
            } else if (session.id === subscribeMessage.fromUserId) {
                chat = chats.find((item) => item.id === subscribeMessage.toUserId);
            } else if (session.id === subscribeMessage.toUserId) {
                chat = chats.find((item) => item.id === subscribeMessage.fromUserId);
            }

            if (!chat && (session.id === subscribeMessage.fromUserId || session.id === subscribeMessage.toUserId)) {
                const chatId = session.id === subscribeMessage.fromUserId ? subscribeMessage.toUserId : subscribeMessage.fromUserId;
                const user = users.find((user) => user.id === chatId);

                if (!user) {
                    return;
                }

                chat = {
                    id: chatId,
                    login: user.login,
                    type: 0,
                    title: user.firstName + ' ' + user.lastName,
                    image: user.image,
                    isPrimary: false,
                    isRead: 0,
                    lastChatMessage: {},
                    messages: []
                };
            }

            if (!chat) {
                return;
            }

            // Check is current chat;
            if (currentChat && currentChat.id === chat.id) {
                // Add message to chat;
                let message;

                while (!message || message.length === 0) {
                    if (chat.type === 0) {
                        message = await api.chat.getChatDialogMessages(chat.id, chat.messages.length, 1, 'createdDateTime,asc');
                    } else {
                        message = await api.chat.getChatGroupMessages(chat.id, chat.messages.length, 1, 'createdDateTime,asc');
                    }
                }

                let replyMessage = null;

                if (message[0]['message'].indexOf('{[<>JSON<>]}') !== -1) {
                    const messageArr = message[0]['message'].split('{[<>JSON<>]}');

                    message[0]['message'] = messageArr[0];
                    replyMessage = JSON.parse(messageArr[1]);
                }

                chat.messages.push({
                    id: message[0]['id'],
                    sender: message[0]['fromUserId'],
                    firstName: message[0]['fromUserFirstName'],
                    lastName: message[0]['fromUserLastName'],
                    avatar: '',
                    message: message[0]['message'],
                    isUpdate: message[0]['modifiedDateTime'] !== null,
                    isRead: message[0]['seen'],
                    date: new Date(message[0]['createdDateTime']),
                    original: message[0],
                    replyMessage: replyMessage
                });

                // Set all chat message read;
                for (let i = 0; i < chat.messages.length; i++) {
                    chat.messages[i].isRead = true;
                }

                // Read all chat messages;
                if (chat.type === 0) {
                    api.chat.seeChatDialogMessages(chat.id);
                } else {
                    api.chat.seeChatGroupMessages(chat.id);
                }

                // Save chat;
                setCurrentChat(chat);

                // Scroll chat window;
                setTimeout(() => {
                    const chatWindow = document.getElementById('chat-window');
                    chatWindow.scrollTop = chatWindow.scrollHeight;
                }, 1);
            } else {
                chat.isRead += 1;
            }

            // Check chat exist in chats;
            let chatsData = Object.assign([], chats);

            if (!chatsData.find((item) => item.id === chat.id)) {
                chatsData.push(chat);
            }

            // Update chat in menu;
            chatsData = chatsData.map((item) => {
                if (item.id === chat.id) {
                    item.lastChatMessage.firstName = 'Вы';
                    item.lastChatMessage.lastName = '';
                    item.lastChatMessage.message = subscribeMessage.message;
                    item.lastChatMessage.date = new Date().toISOString();

                    if (item.lastChatMessage.message.indexOf('{[<>JSON<>]}') !== -1) {
                        item.lastChatMessage.message = item.lastChatMessage.message.slice(
                            0, item.lastChatMessage.message.indexOf('{[<>JSON<>]}'));
                    }
                }
                return item;
            });
            setChats(chatsData.sort((a, b) => {
                return new Date(a.lastChatMessage.date) > new Date(b.lastChatMessage.date) ? -1 : 1;
            }));
        };

        // Check subscribe message;
        if (subscribeMessage) {
            fetchData();
        }
    }, [session, users, chats, currentChat, subscribeMessage]);
    useEffect(() => {
        const fetchData = async () => {
            ChatSubscribe((message) => setSubscribeMessage(JSON.parse(message.body)));

            const users = usersData.content.filter((user) => user.login !== session.login);
            setUsers(users);

            let groupsChatsResponse = [], usersChatsResponse = [];

            try {
                groupsChatsResponse = await api.chat.getChatGroups();
                usersChatsResponse = await api.chat.getUsers(0, 999999);
            } catch (e) {
                console.log(e);
                addError('Сервис недоступен. Пожалуйста попробуйте позже.');
            }

            const groupsChats = await groupsChatsResponse.map((chat) => {
                let user = {};

                if (chat['lastChatMessage']) {
                    user = users.find((user) => user.id === chat['lastChatMessage']['fromUserId']);
                }

                if (!user && chat['lastChatMessage']['fromUserId'] === session['id']) {
                    user = { 'firstName': 'Вы', 'lastName': '' };
                }

                if (chat['lastChatMessage'] && chat['lastChatMessage']['message'].indexOf('{[<>JSON<>]}') !== -1) {
                    chat['lastChatMessage']['message'] = chat['lastChatMessage']['message'].slice(
                        0, chat['lastChatMessage']['message'].indexOf('{[<>JSON<>]}'));
                }

                return {
                    id: chat['groupId'],
                    login: '',
                    type: 1,
                    title: chat['groupName'],
                    description: chat['groupDescription'],
                    image: chat && chat['avatarUuid'] ? api.chat.getLogo(chat['avatarUuid']) : '/img/avatar.png',
                    isPrimary: false,
                    isRead: chat['unreadMessages'],
                    lastChatMessage: {
                        firstName: user && user['firstName'] ? user['firstName'] : '',
                        lastName: user && user['lastName'] ? user['lastName'] : '',
                        message: chat['lastChatMessage'] ? chat['lastChatMessage']['message'] : '',
                        date: chat['lastChatMessage'] ? chat['lastChatMessage']['createdDateTime'] : ''
                    },
                    users: chat.users.map((user) => {
                        const userData = users.find((item) => item['login'] === user['login']);
                        user['image'] = userData && userData['avatarUuid']
                            ? api.upload.getImage(userData['avatarUuid'], false, 128) : '/img/avatar.png';
                        return user;
                    }),
                    groups: chat.userGroups.map(group => {
                        group['members'].map(member => {
                            member['image'] = member && member['avatarUuid']
                                ? api.upload.getImage(member['avatarUuid'], false, 128) : '/img/avatar.png';
                            return member;
                        });
                        return group;
                    })
                };
            });
            const usersChats = await usersChatsResponse.filter((user) => user.login !== session.login).map((user) => {
                const userData = users.find((item) => item['login'] === user['login']);

                if (user['lastChatMessage'] && user['lastChatMessage']['message'].indexOf('{[<>JSON<>]}') !== -1) {
                    user['lastChatMessage']['message'] = user['lastChatMessage']['message'].slice(
                        0, user['lastChatMessage']['message'].indexOf('{[<>JSON<>]}'));
                }

                return {
                    id: user['userId'],
                    login: user['login'],
                    type: 0,
                    title: user['userFirstName'] + ' ' + user['userLastName'],
                    image: userData && userData['avatarUuid']
                        ? api.upload.getImage(userData['avatarUuid'], false, 128) : '/img/avatar.png',
                    isPrimary: false,
                    isRead: user['unreadMessages'],
                    lastChatMessage: {
                        message: user['lastChatMessage'] ? user['lastChatMessage']['message'] : '',
                        date: user['lastChatMessage'] ? user['lastChatMessage']['createdDateTime'] : ''
                    },
                    groups: groupsChats.filter((group) => {
                        return group.users.find((groupUser) => groupUser['login'] === user['login']) !== undefined;
                    })
                };
            });

            setChats(groupsChats.concat(usersChats).sort((a, b) => {
                return new Date(a.lastChatMessage.date) > new Date(b.lastChatMessage.date) ? -1 : 1;
            }));
        };
        fetchData();
    }, [addError, session, usersData.content]);

    useEffect(() => { idChanged.current = true }, [id]);
    useEffect(() => {
        if (!id || !chats || isCreateGroup) {
            setCurrentChat(null);
            return;
        }

        if (currentChat !== null && idChanged.current) {
            idChanged.current = false;
            return;
        }

        if (currentChat !== null && chatChanged.current) {
            chatChanged.current = false;
            return;
        }

        const chat = chats.find(c => c.id.toString() === id.toString());

        if (!chat || (currentChat !== null && chat.id === currentChat.id)) {
            return;
        }

        onOpenChat(chat.id, chat.type, null);
    }, [id, currentChat, chats, onOpenChat, isCreateGroup]);

    const { setMenuContentType } = useContext(MobileMenuContext);
    useEffect(() => {
        if (!setMenuContentType) {
            return;
        }

        setMenuContentType(MENU_CONTENT_TYPE.MAIN_MENU);
    }, [setMenuContentType]);

    useEffect(() => {
        setDocumentTitle('Чат — KMS Gran');
    }, []);

    return (
        <ContentWrapper>
            <Confirmation {...dialogState}/>
            <div className={classNames(cx.chatContainer, {[cx.mobile]: platform !== 'desktop'})}>
                {isCreateDialog &&
                <ChatDialog users={users} onOpenChat={onOpenChat} onClose={() => setIsCreateDialog(false)} />}
                {(platform === 'desktop' || (!id && !isCreateGroup)) &&
                <ChatMenu chats={chats} currentChat={currentChat} onOpenChat={onOpenChat}
                          onCreateDialog={isAuthorizedAction([GLOBAL_ACTIONS.CHAT_CONVERSATION_ADD])
                              ? () => setIsCreateDialog(true) : null}
                          onCreateGroup={isAuthorizedAction([GLOBAL_ACTIONS.CHAT_GROUP_ADD]) ? () => {
                              setCurrentChat(null);
                              setUpdateChat(null);

                              setIsCreateGroup(true);
                              navigate('/chat');
                          } : null} isMobile={platform === 'mobile'}/>}
                {(platform === 'desktop' || id || isCreateGroup) &&
                <div className={cx.chatContent}>
                    {!currentChat && !isCreateGroup &&
                    <div className={cx.chatEmpty}>
                        <Icon type={Icons.CHAT} width={43} height={37} />
                        <p>Выберите беседу из списка чатов</p>
                    </div>}
                    {currentChat && !isCreateGroup &&
                    <ChatWindow session={session} users={users} chats={chats} currentChat={currentChat}
                                onMessage={isAuthorizedAction([GLOBAL_ACTIONS.CHAT_CONVERSATION_ADD]) ? onMessage : null}
                                onOpenChat={onOpenChat} isMobile={platform === 'mobile'}
                                onUpdateChatGroup={isAuthorizedAction([GLOBAL_ACTIONS.CHAT_GROUP_EDIT]) ? onUpdateChatGroup : null}
                                onRemoveChatGroup={isAuthorizedAction([GLOBAL_ACTIONS.CHAT_GROUP_DELETE]) ? onRemoveChatGroup : null}
                                onRemoveDialog={isAuthorizedAction([GLOBAL_ACTIONS.CHAT_CONVERSATION_DELETE]) ? onRemoveDialog : null}/>}
                    {isCreateGroup &&
                    <ChatGroup users={users} chat={updateChat} onOpenChat={onOpenChat}
                               onClose={() => setIsCreateGroup(false)} />}
                </div>}
            </div>
        </ContentWrapper>
    );
};
