import React from 'react';
import classNames from 'classnames';
import { TextareaAutoSizable } from 'uikit/textareaAutoSizable/TextareaAutoSizable';
import LocationChange from 'components/location-change';
import api from 'api';
import cs from '../create-script.module.scss';

function ScriptInfo({ data, errors, onNameChange, onDescriptionChange, onDestinationChange }) {
    return (
        <div className={cs.stepContainer} style={{ height: "100%" }} data-testid="scriptInfo">
            <p className={cs.stepHeadingLabel} data-testid="scriptInfoLable">О скрипте</p>
            <TextareaAutoSizable
                className={classNames(cs.desc, cs.scriptInfoName)}
                label="Название скрипта"
                placeholder="Название скрипта"
                rows="2"
                value={data.scriptName}
                onChange={onNameChange}
                error={errors?.scriptName}
                data-testid="scriptInfoTitle"
            />
            <TextareaAutoSizable
                className={classNames(cs.desc, cs.scriptInfoDescription)}
                label="Описание скрипта"
                placeholder="Описание скрипта"
                rows="2"
                value={data.scriptDescription}
                onChange={onDescriptionChange}
                error={errors?.scriptDescription}
                data-testid="scriptInfoDescription"
            />
            <LocationChange
                title="Выберите месторасположение"
                placeholder="Нажмите для выбора расположения..."
                visualPath={
                    (data.scriptDestination?.parentProjectId && data.scriptDestination?.parentSectionId)
                        ? data.scriptDestination?.visualPath : []
                }
                onChange={onDestinationChange}
                onClearPath={() => onDestinationChange(undefined)}
                error={errors?.path}
                fetchTreeFunc={api.project.getUsersDocumentTree}
                autoOpenPath={
                    data.scriptDestination.parentsIds
                    ?? [data.scriptDestination.parentProjectId, data.scriptDestination.parentSectionId]
                        .filter(item => item)
                }
                locationPathClassName={cs.scriptLocationPath}
            />
        </div>
    );
}

export default ScriptInfo;
