import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import AutoSizer from 'react-virtualized-auto-sizer';
import Tree from '../infinite-tree/tree';
import { useDebounceValue } from 'lib/hooks';
import { NODE_TYPES } from 'components/trees';
import { useLocationTree } from 'components/trees/utils';
import CommonDialog from 'components/trees/common-dialog';
import Icon, { Icons } from 'uikit/icon';
import Input from 'uikit/input';
import Loading from 'uikit/loading';
import cs from './reports-projects-tree.module.scss';
import api from 'api';

const ReportsProjectsTree = ({
    open,
    onDismiss,
    onSubmit,
    withArticles,
    withSections,
    removedNodesIds,
    multipleChoice = false,
    isReload,
    articles,
    ...props
}) => {
    const treeRef = useRef(null);
    const [query, setQuery] = useState('');

    const { loading, tree } = useLocationTree({
        open,
        withArticles,
        withSections,
        fetchTreeFunc: api.project.getUsersDocumentTree,
        isReload,
    });

    const [debouncedValue] = useDebounceValue(query.trim().replace(/\s{2,}/g, " "), 300);
    const [isAllChecked, setIsAllChecked] = useState(false);

    const [isTreeUpdate, setIsTreeUpdate] = useState(true);
    const [checkedNodes, setCheckedNodes] = useState([]);

    const _onSubmit = () => {
        const nodes = treeRef.current.tree.nodeTable.data;
        const selectedNodes = Object.values(nodes)
            .filter((node) => node.state?.checked)
            .sort((nodeA, nodeB) => {
                return nodeA.state.path - nodeB.state.path;
            });

        setCheckedNodes(selectedNodes);
        onSubmit(selectedNodes);
    };
    const _onUpdateNode = () => {
        const { tree } = treeRef.current;
        const isAllChecked = tree.nodes.every((node) => node.state?.checked);

        setIsAllChecked(isAllChecked);
    };
    const onAllButtonClick = () => {
        const { tree } = treeRef.current;
        const isAllChecked = tree.nodes.every((node) => node.state?.checked);

        if (isAllChecked) {
            tree.nodes.forEach((node) => {
                if (node.nodeType === NODE_TYPES.PROJECT) {
                    tree.checkNode(node);
                }
            });
        } else {
            tree.nodes.forEach((node) => {
                if ((node.nodeType === NODE_TYPES.PROJECT || node.nodeType === NODE_TYPES.SECTION) && !node.state.checked) {
                    tree.checkNode(node);
                }
            });
        }

        setIsAllChecked(!isAllChecked);
    };

    useEffect(() => {
        if (treeRef?.current?.tree) {
            const { tree } = treeRef.current;
            tree.filter(debouncedValue.trim());
        }
    }, [debouncedValue, treeRef]);
    useEffect(() => {
        if (treeRef?.current?.tree) {
            const { tree } = treeRef.current;

            if (open && !loading && checkedNodes.length) {
                setIsTreeUpdate(true);
                checkedNodes.forEach((node) => {
                    const _node = tree.getNodeById(node.id);

                    if (_node) {
                        _node.state.checked = node.state.checked || false;
                    }
                });

                if (!tree.nodes.some((n) => n.state.checked)) {
                    setIsAllChecked(false);
                }

                setIsTreeUpdate(false);
            }
        }
    }, [treeRef, checkedNodes, loading, open, setIsTreeUpdate]);

    useEffect(() => {
        if (Array.isArray(removedNodesIds) && removedNodesIds.length) {
            setCheckedNodes((prevState) => {
                return [
                    ...prevState.map((node) => {
                        if (!removedNodesIds.includes(node.id)) {
                            return node;
                        }

                        node.state.checked = false;

                        return node;
                    }),
                ];
            });
        } else if (removedNodesIds === 'ALL') {
          setCheckedNodes([]);
          setIsAllChecked(false);
        }
      }, [removedNodesIds, setCheckedNodes]);
    useEffect(() => {
        let interval;

        if (articles.length !== 0) {
            let tryCount = 0

            interval = setInterval(() => {
                if (!treeRef?.current?.tree) {
                    return;
                }

                const { tree } = treeRef.current;

                if (tryCount === 5) {
                    clearInterval(interval);
                    setIsTreeUpdate(false);

                    return;
                }

                if (tree.nodes.length !== 0) {
                    articles.forEach(article => {
                        const _node = tree.getNodeById(article.articleId);

                        if (_node) {
                            let parent = _node.getParent();

                            while (parent.id) {
                                tree.openNode(parent);
                                parent = parent.getParent();
                            }

                            tree.checkNode(_node, true);
                        }
                    });

                    clearInterval(interval);
                    setIsTreeUpdate(false);
                } else {
                    tryCount++;
                }
            }, 500);
        } else {
            setIsTreeUpdate(false);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
                setIsTreeUpdate(false);
            }
        };
    }, [articles, treeRef]);

    return (
        <CommonDialog
            open={open}
            loading={loading}
            hasData={tree.length > 0}
            title={withArticles ? 'Выбор статьи' : withSections ? 'Выбор раздела' : 'Выбор проекта'}
            onSubmit={_onSubmit}
            onDismiss={onDismiss}
        >
            <div id="tree-container" data-testid="treeContainer" className={cs.tree}>
                {(loading || isTreeUpdate) && (
                    <Loading withOverlay={false} withRelativeOverlay={true} />
                )}
                <div className={cs.searchInput}>
                    <Input
                        type={'text'}
                        placeholder={'Поиск по названию'}
                        value={query}
                        onChange={(value) => {
                            setQuery(value);
                        }}
                        data-testid="treeContainerSearch"
                    />
                </div>
                {!loading && (
                    <button
                        className={classNames(cs.allBtn, isAllChecked ? cs.allBtnActive : '')}
                        onClick={onAllButtonClick}
                        data-testid="treeContainerAllBtn"
                    >
                        <Icon type={Icons.LIST} />
                        {isAllChecked ? 'Снять выделение' : 'Выбрать все'}
                    </button>
                )}
                <div className={cs.sizerContainer} data-testid="treeContainerContent" style={{ opacity: isTreeUpdate ? 0 : 1 }}>
                    <AutoSizer>
                        {({ width, height }) => {
                            return (
                                <Tree
                                    width={width}
                                    height={height}
                                    data={tree}
                                    onUpdate={_onUpdateNode}
                                    multipleChoice={multipleChoice}
                                    autoOpen={false}
                                    isDeselecting={true}
                                    onCheckedChange={() => {}}
                                    ref={treeRef}
                                    {...props}
                                    getNodeLink={() => '#'}
                                    checkNodeOnSelect={true}
                                    toggleNodeOnSelect={true}
                                />
                            );
                        }}
                    </AutoSizer>
                </div>
            </div>
        </CommonDialog>
    );
};

export default React.forwardRef((props, ref) => <ReportsProjectsTree {...props} forwardedRef={ref} />);
