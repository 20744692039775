import React from 'react';
import classNames from 'classnames';
import ArchiveIcon from './archive-icon';
import UnArchiveIcon from './unarchive-icon';
import DeleteIcon from './delete-icon';
import EditIcon from './edit-icon';
import AddIcon from './add-icon';
import cs from './tree-button.module.scss';
import Tippy from '@tippyjs/react';
import { AdaptiveLink } from 'containers/adaptive-link/adaptive-link';

const ButtonType = {
    ARCHIVE: ArchiveIcon,
    UNARCHIVE: UnArchiveIcon,
    DELETE: DeleteIcon,
    EDIT: EditIcon,
    ADD: AddIcon
};

const TreeButton = ({ icon: Icon, width, height, hoverColor, onClick, tooltip, getLink, ...attr }) => {
    const _onClick = e => {
        if (onClick) {
            e.stopPropagation();
            onClick();
        }
    };

    return (
        <Tippy content={tooltip}>
            <AdaptiveLink link={getLink} style={{ width, height }} className={classNames(cs.button, cs[`hover-${hoverColor}`])} onClick={_onClick}>
                <Icon {...attr} />
            </AdaptiveLink>
        </Tippy>
    );
};

export {
    TreeButton,
    ButtonType
};
