import React, { useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { findParentNodeClosestToPos } from '@tiptap/core';
import Tippy from '@tippyjs/react';

import Icon, { Icons } from 'uikit/icon';
import { useEditorContext } from '../../context';
import { MenuButtonBulletList } from './MenuButtonBulletList';
import { MenuButtonOrderedList } from './MenuButtonOrderList';
import { Menu, MenuItem } from '../DropdownMenu/DropdownMenu';
import { MultiListModal } from './MenuButtonMultiList';

import cx from '../../editor.module.scss';

export const markers = [
    { value: 'list-decimal', label: '1, 2, 3', type: 'orderedList' },
    { value: 'list-upper-alpha', label: 'A, B, C', type: 'orderedList' },
    { value: 'list-lower-alpha', label: 'a, b, c', type: 'orderedList' },
    { value: 'list-lower-roman', label: 'i, ii, iii', type: 'orderedList' },
    { value: 'list-upper-roman', label: 'I, II, III', type: 'orderedList' },
    { value: 'list-disc', label: '•', type: 'bulletList' },
    { value: 'list-option-1', label: '⊙', type: 'bulletList' },
    { value: 'list-circle', label: '○', type: 'bulletList' },
    { value: 'list-option-2', label: '◍', type: 'bulletList' },
    { value: 'list-option-3', label: '◆', type: 'bulletList' },
    { value: 'list-option-4', label: '❖', type: 'bulletList' },
    { value: 'list-option-5', label: '✦', type: 'bulletList' },
    { value: 'list-option-6', label: '✧', type: 'bulletList' },
    { value: 'list-option-7', label: '✱', type: 'bulletList' },
    { value: 'list-option-8', label: '☆', type: 'bulletList' },
    { value: 'list-option-9', label: '◌', type: 'bulletList' },
    { value: 'list-option-10', label: '✓', type: 'bulletList' },
];

const getSelectedLists = editor => {
    const lists = [];

    if (!editor) {
        return lists;
    }

    const { selection } = editor.state;
    const { $from, $to } = selection;

    if ($from.pos !== $to.pos) {
        editor.state.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
            if (node.type.name !== 'bulletList' && node.type.name !== 'orderedList') {
                return;
            }

            const isSelected = pos >= $from.pos - 3;

            if (!isSelected) {
                return;
            }

            lists.push({ node, pos });
        });
    }

    if ($from.pos === $to.pos || lists.length === 0) {
        const parentList = findParentNodeClosestToPos($from, node => node.type.name === 'bulletList' || node.type.name === 'orderedList');

        if (parentList) {
            lists.push({ node: parentList.node, pos: parentList.pos });
        }
    }

    return lists;
};

export const setBulletList = (editor, mode, menuRef, type) => {
    if (!editor) {
        return;
    }

    const textAlign = editor?.getAttributes('paragraph')?.textAlign;
    const lists = getSelectedLists(editor);

    if (lists.length > 0) {
        editor
            ?.chain()
            .updateAttributes('listItem', { class: `list-${textAlign}` })
            .command(({ tr, dispatch }) => {
                lists.forEach(({ pos, node }) => {
                    tr.setNodeMarkup(pos, undefined, {
                        ...node.attrs,
                        'data-list-type': type,
                    });
                });

                return dispatch(tr);
            })
            .focus(editor?.state.selection.$anchor.pos + 1)
            .run();
    } else {
        editor
            ?.chain()
            .toggleBulletList()
            .updateAttributes('bulletList', { 'data-list-type': type })
            .updateAttributes('listItem', { class: `list-${textAlign}` })
            .focus(editor?.state.selection.$anchor.pos + 1)
            .run();
    }

    if (mode === 'DESKTOP') {
        menuRef.current?.hidemenu?.();
    }
};
export const setOrderedList = (editor, mode, menuRef, type) => {
    const textAlign = editor?.getAttributes('paragraph')?.textAlign;
    const lists = getSelectedLists(editor);

    if (lists.length > 0) {
        editor
            ?.chain()
            .updateAttributes('listItem', { class: `list-${textAlign}` })
            .command(({ tr, dispatch }) => {
                lists.forEach(({ pos, node }) => {
                    tr.setNodeMarkup(pos, undefined, {
                        ...node.attrs,
                        'data-list-type': type,
                    });
                });

                return dispatch(tr);
            })
            .focus(editor?.state.selection.$anchor.pos + 1)
            .run();
    } else {
        editor
            ?.chain()
            .toggleOrderedList()
            .updateAttributes('orderedList', { 'data-list-type': type })
            .updateAttributes('listItem', { class: `list-${textAlign}` })
            .focus(editor?.state.selection.$anchor.pos + 1)
            .run();
    }

    if (mode === 'DESKTOP') {
        menuRef.current?.hidemenu?.();
    }
};

export const MenuButtonList = ({ mode, currentMenu, setCurrentMenu }) => {
    const menuRef = useRef(null);
    const editor = useEditorContext();
    const orderedListAttrs = editor?.getAttributes('orderedList');
    const bulletListAttrs = editor?.getAttributes('bulletList');
    // const multiListAttrs = editor?.getAttributes('multi-list');

    const [show, setShow] = useState(false);

    const orderedMarkers = useMemo(() => markers.filter(m => m.type === 'orderedList'), []);
    const bulletMarkers = useMemo(() => markers.filter(m => m.type === 'bulletList'), []);

    const toggleDialog = () => {
        const { selection } = editor.state;
        const { $from, $to } = selection;
        const range = $from.blockRange($to);

        if (!range) {
            setShow(prev => !prev);
            return false;
        }

        setShow(prev => !prev);
    };

    return (
        <>
            <Menu
                ref={menuRef}
                nested={mode === 'MOBILE'}
                mode={mode}
                name="list"
                currentMenu={currentMenu}
                setCurrentMenu={name => {
                    if (mode === 'MOBILE' && name !== 'list') {
                        setCurrentMenu('sub');
                    } else {
                        setCurrentMenu(name);
                    }
                }}
                label={
                    <Tippy content="Списки" disabled={mode !== 'DESKTOP'}>
                        {mode !== 'MOBILE' ? (
                            <div className={classNames('editor-button', cx.editorMenuButton)}>
                                <Icon type={Icons.EditorIconList} width={18} height={18} />
                                <Icon type={Icons.EditorIconArrowDown} width={12} height={12} />
                            </div>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <div className={classNames('editor-button', cx.editorMenuButton)}>
                                    <Icon type={Icons.EditorIconList} width={18} height={18} />
                                    <span className="editor-button-label" style={{ paddingLeft: 6 }}>
                                        Списки
                                    </span>
                                </div>
                                <span aria-hidden style={{ display: 'flex', marginRight: 6 }}>
                                    <Icon type={Icons.EditorIconArrowRight} width={12} height={12} />
                                </span>
                            </div>
                        )}
                    </Tippy>
                }
                menuButtonStyles={mode === 'MOBILE' ? { width: '100%' } : null}
                menuContentStyles={mode === 'MOBILE' ? { overflow: 'initial', minWidth: 254 } : null}
            >
                <Menu
                    mode={mode}
                    name="list"
                    currentMenu={currentMenu}
                    setCurrentMenu={setCurrentMenu}
                    selected={bulletListAttrs?.['data-type'] === 'bullet-list'}
                    label={<MenuButtonBulletList label="Маркированный список" />}
                    onClick={() => setBulletList(editor, mode, menuRef, 'list-disc')}
                >
                    {bulletMarkers.map(m => {
                        return (
                            <MenuItem
                                key={m.label}
                                selected={bulletListAttrs?.['data-list-type'] === m.value}
                                label={m.label}
                                onClick={() => setBulletList(editor, mode, menuRef, m.value)}
                            />
                        );
                    })}
                </Menu>

                <Menu
                    mode={mode}
                    name="list"
                    currentMenu={currentMenu}
                    setCurrentMenu={setCurrentMenu}
                    selected={orderedListAttrs?.['data-type'] === 'ordered-list'}
                    label={<MenuButtonOrderedList label="Нумерованный список" />}
                    onClick={() => setBulletList(editor, mode, menuRef, 'list-decimal')}
                >
                    {orderedMarkers.map(m => {
                        return (
                            <MenuItem
                                key={m.label}
                                selected={orderedListAttrs?.['data-list-type'] === m.value}
                                label={m.label}
                                onClick={() => setOrderedList(editor, mode, menuRef, m.value)}
                            />
                        );
                    })}
                </Menu>

                {/* {mode === 'DESKTOP' && (
                    <MenuItem
                        selected={multiListAttrs?.['data-type'] === 'multi-list'}
                        label={<MenuButtonMultiList label="Многоуровневый список" onClick={toggleDialog} />}
                    />
                )} */}
            </Menu>

            {show && <MultiListModal show={show} markers={markers} onClose={toggleDialog} />}
        </>
    );
};
