import React, { useEffect, useState, useMemo } from 'react';
import { setDocumentTitle, useNavigate } from 'shared/router';
import { useAuthorized } from 'lib/hooks';
import { useUserFormModel } from 'lib/hooks/use-user-form-model';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import UserForm from 'components/user-form/user-form';
import IconButton from 'uikit/icon-button';
import Icon, { Icons } from 'uikit/icon';
import api from 'api/index';
import UserHeader from 'uikit/user-header';

const ProfilePage = () => {
    const navigate = useNavigate();
    const { isAuthorizedAction } = useAuthorized();

    const model = useUserFormModel(null, true);
    const [positions, setPositions] = useState(null);

    const { user, loading } = model;

    const actions = useMemo(() => {
        return (
            isAuthorizedAction([GLOBAL_ACTIONS.USER_EDIT]) &&
            <div id="profilePageEditIcon" onClick={() => navigate('/user/profile/edit')}>
                <IconButton icon={<Icon type={Icons.EDIT_PEN} tooltip="Редактировать" width={16} height={16}/>}/>
            </div>
        );
    }, [isAuthorizedAction, navigate]);

    useEffect(() => {
        const getPositions = async () => {
            try {
                const response = await api.userPosition.getUserPosition(0, 2000);
                setPositions(response.content.map(pos => ({ label: pos.title, value: pos.id })));
            } catch {

            }
        };

        if (user && user.position > 0) {
            getPositions();
        }
    }, [user, user.position]);

    useEffect(() => {
        setDocumentTitle('Профиль — KMS Gran');
    }, []);

    return (
        <>
            <UserHeader
                userName={user.firstName + ' ' + user.lastName}
                userPosition={user.position > 0 && positions ? positions.find(v => v.value === user.position).label : ''}
                userBlocked={user.status === 'BLOCKED'}
                avatarSrc={user.avatarUuid && api.upload.getImage(user.avatarUuid, false, 512)}
                actionIcons={actions}
                idPrefix="profilePage"
            />
            <UserForm
                user={user}
                loading={loading}
                isEditMode={false}
                showAllNotificationTypes={true}
            />
        </>
    );
};

export default ProfilePage;
