import React from 'react';
import Modal from 'react-modal';
import { desktopStyles } from 'lib/util/modalStyles';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import cs from './LicenseViewModal.module.scss';

const LicenseViewModal = ({ open, license, onCloseModal }) => {
    if (!license) {
        return null;
    }

    return (
        <Modal isOpen={open} contentLabel="Пакет лицензий добавлен" style={desktopStyles}>
            <div className={cs.modalContent}>
                <div className={cs.modalContentTitle}>
                    <h3>Пакет лицензий добавлен</h3>
                    <IconButton icon={<Icon width={14} height={14} type={Icons.CROSS}/>} onClick={onCloseModal}/>
                </div>
                <div className={cs.modalContentRow}>
                    <span>Наименование</span>
                    <p>{license['licenseName']}</p>
                </div>
                <div className={cs.modalContentRow}>
                    <span>Компания</span>
                    <p>{license['companyName']}</p>
                </div>
                <div className={cs.modalContentRow}>
                    <span>Выдана</span>
                    <p>{new Date(license['createDate']).toLocaleString().slice(0, -3)}</p>
                </div>
                <div className={cs.modalContentRow}>
                    <span>Дата окончания</span>
                    <p>{new Date(license['expirationDate']).toLocaleString().slice(0, -3)}</p>
                </div>
                <div className={cs.modalContentRow}>
                    <span>Количество лицензий</span>
                    <p>{license['numberOfLicenses']}</p>
                </div>
                <div className={cs.modalContentRow}>
                    <span>Осталось дней</span>
                    <p>{license['daysLeft']}</p>
                </div>
            </div>
        </Modal>
    );
};

export default LicenseViewModal;
