import React from 'react';
import hot from 'containers/hot-container';
import { contentManagementNewsFormPage } from 'components/content-management-project-form';
import Button from 'uikit/button';
import { useGlobalContext, useMessage } from 'lib/hooks';
import api from 'api/index';
import { useNavigate } from 'shared/router';

const ContentManagementNewsPage = ({ news, loading, setLoading }) => {
    const navigate = useNavigate();

    const { platform } = useGlobalContext();
    const { addSuccess } = useMessage();

    const save = async () => {
        setLoading(true);

        if (news.users?.length > 0) {
            await api.errorReportResource.addErrorReportManagers(news.users, news.id, 'NEWS');
        }

        if (news.managersRemove?.length > 0) {
            await api.errorReportResource.deleteErrorReportManagers(news.managersRemove, news.id, 'NEWS');
        }

        setLoading(false);
        navigate(`/projects/news/${news.id}`);

        addSuccess('Контент-менеджеры обновлены');
    }

    return (
        <>
            <Button label={'Сохранить'} disabled={loading} color={'green'} fullWidth={platform === 'mobile'}
                    onClick={save}/>
        </>
    );
};

export default hot(contentManagementNewsFormPage(ContentManagementNewsPage, 'Контент-менеджмент'));
