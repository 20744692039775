import React, { useRef, useState, useEffect } from 'react';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';
import { useNavigate } from 'shared/router';
import cs from './crubms.module.scss';

const Crumbs = ({ id, className, data, size = 'small', noTooltip = false }) => {
    const clampRefs = useRef([]);
    const navigate = useNavigate();

    const [isFirst, setIsFirst] = useState(false);
    const [isLast, setIsLast] = useState(false);
    const [isLine, setIsLine] = useState(false);
    const firstClampElementOH = size === 'small' ? 16 : 19;

    useEffect(() => {
        setTimeout(() => {
            let first = false;

            for (let i = 0; i < clampRefs.current.length; i++) {
                const clampElement = clampRefs.current[i].current;

                if (clampElement) {
                    if (i === 0 && clampElement.offsetHeight > firstClampElementOH) {
                        first = true;
                        setIsFirst(true);
                    }

                    if (i === 1) {
                        if (!first && clampElement.offsetHeight > 32) {
                            setIsLast(true);
                        }

                        if (first && clampElement.offsetHeight > firstClampElementOH) {
                            setIsLast(true);
                        }
                    }
                }
            }

            setTimeout(() => {
                const clampElement = clampRefs.current[0]?.current;
                setIsLine(false);

                if (!clampElement) {
                    setIsLine(false);
                    return;
                }

                if (clampElement.scrollHeight === 0) {
                    setIsLine(false);
                    return;
                }

                if (clampElement.scrollHeight > 16) {
                    setIsLine(false);
                    return;
                }

                if (clampElement.scrollHeight !== clampElement.clientHeight) {
                    setIsLine(false);
                    return;
                }

                setIsLine(first);
            }, 100);
        }, 100);
    }, [firstClampElementOH]);

    if (!data || data.length === 0) {
        return null;
    }

    const filteredData = data.filter(p => p.linkTo);
    const items = [filteredData[0]];

    if (filteredData.length > 1) {
        items.push(filteredData[filteredData.length - 1]);
    }

    return (
        <Tippy
            disabled={(!isFirst && !isLast) || noTooltip}
            className={cs.crumbsTip}
            content={items.map((p, i) => p.name + (i === 0 && items.length !== 1 ? ' / ' : '')).join('')}
        >
            <div id={id} data-testid={id} className={classNames(cs.crumbs, { [cs.crumbsFree]: isFirst }, className)}>
                {items.map((p, i) => {
                    clampRefs.current[i] = clampRefs.current[i] || React.createRef();

                    return (
                        <span
                            key={p.name + p.linkTo}
                            ref={clampRefs.current[i]}
                            className={classNames(
                                isFirst ? cs.crumb : null,
                                i === 0 && isLine ? cs.crumbLine : null,
                                size === 'medium' ? cs.medium : null
                            )}
                            onClick={() => navigate(p.linkTo)}
                        >
                            {p.name}
                            {i === 0 && items.length !== 1 && <i>&nbsp;/&nbsp;</i>}
                        </span>
                    );
                })}
            </div>
        </Tippy>
    );
};

export default Crumbs;
