import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'shared/router';
import { useDialog, useGlobalContext, useMessage } from 'lib/hooks';
import { Icons } from 'uikit/icon';
import { EditRowToolbar } from 'uikit/table';
import { TableComponent } from 'components/data-components';
import api from 'api/index';
import cx from './section-tab-articles.module.scss';
import { Confirmation } from 'components/confirmation/confirmation';
import { USER_ROLES } from 'model/role';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMenuButtons, selectUsers } from 'slice/authSlice';
import TableTitleCell from 'components/table-title-cell';
import Loading from 'uikit/loading';
import _ from 'lodash';
import { EmptyArticles } from '../projects/empty';

const ArchiveSectionTabArticles = ({ _projectId = '', sectionId = '', tree, reloadTree, setOnReloadTreeAction }) => {
    const treeRef = useRef(tree);
    const tableRef = useRef(null);

    const { platform, isAuthorizedRole } = useGlobalContext();
    const selector = useSelector((state) => state);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { addSuccess, addError } = useMessage();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [users, setUsers] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [, setIsInit] = useState(false);

    const [isReLoading, setIsReLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    const [articlesTotal, setArticlesTotal] = useState(0);
    const [articles, setArticles] = useState([]);

    const [checkedCheckbox, setCheckedCheckbox] = useState([]);
    const checkedCheckboxRef = useRef(checkedCheckbox);

    const requestArticles = useCallback(
        async (offset, count, sort = { by: '', desc: false }, search = '', filters = {}) => {
            setIsLoading(true);

            const articlesResponse = await api.archive.getSectionArticles(
                sectionId,
                offset / count,
                count,
                sort.by ? sort.by + ',' + (sort.desc ? 'desc' : 'asc') : 'modifyTime,desc',
                search,
                filters
            );

            setArticlesTotal(articlesResponse.totalElements);
            setArticles(articlesResponse.content);

            setIsEmpty(articlesResponse.totalElements === 0 && _.isEmpty(filters) && !search);

            setIsLoading(false);
            setIsReLoading(false);
        },
        [sectionId]
    );
    const checkboxHandler = useCallback((node) => {
        setCheckedCheckbox((prevValue) => {
            if (prevValue.some((c) => c.id === node.id)) {
                return prevValue.filter((c) => c.id !== node.id);
            } else {
                return [...prevValue, node];
            }
        });
    }, []);

    const deleteArticles = useCallback(
        async (data) => {
            try {
                setIsLoading(true);
                closeDialog();

                if (data?.id) {
                    await api.article.deleteArticle(data.id);
                } else if (checkedCheckboxRef.current.length) {
                    for (let i = 0; i < checkedCheckboxRef.current.length; i++) {
                        await api.article.deleteArticle(checkedCheckboxRef.current[i].id);
                    }
                }

                tableRef?.current?.reload();
                reloadTree();

                addSuccess(data.length ? 'Выбранные статьи удалены' : 'Статья удалена');
            } catch (error) {
                addError('При удалении возникла ошибка');
            } finally {
                setIsLoading(false);
            }
        },
        [closeDialog, addSuccess, addError, reloadTree]
    );
    const onDeleteArticles = useCallback(
        (data) => {
            if (data?.length) {
                setCheckedCheckbox(data.map((d) => d.original));
            }

            let text = '';

            if (data?.length) {
                text = data.map((item) => {
                    return {
                        ...item.original,
                        text: (
                            <>
                                <span
                                    onClick={() => navigate(`/archive/article/${item.original.id}`)}
                                    style={{ color: '#279BD9', cursor: 'pointer' }}
                                >
                                    {item?.original?.title}
                                </span>
                            </>
                        ),
                    };
                });
            } else {
                text = (
                    <span>
                        Вы действительно хотите удалить статью&nbsp;
                        <span style={{ color: '#279BD9', cursor: 'pointer' }} onClick={() => navigate(`/archive/article/${data.id}`)}>
                            {data?.title}
                        </span>
                        &nbsp;? Этот процесс нельзя будет отменить
                    </span>
                );
            }

            openDialog({
                title: 'Удаление',
                text,
                subTitle: data?.length
                    ? 'Вы действительно хотите удалить нижеперечисленные документы? Этот процесс нельзя будет отменить'
                    : null,
                color: 'red',
                contentType: data?.length ? 'CHECKBOX_LIST' : 'TEXT',
                closeBtnText: 'Нет, отменить',
                submitBtnText: 'Да, удалить',
                onChange: (articles) => checkboxHandler(articles),
                onSubmit: () => deleteArticles(data),
                onClose: closeDialog,
            });
        },
        [closeDialog, openDialog, deleteArticles, checkboxHandler, navigate]
    );

    const unArchiveArticles = useCallback(
        async (data) => {
            try {
                setIsLoading(true);
                closeDialog();

                if (data?.id) {
                    await api.archive.moveArticleFromArchive(data.id);
                } else if (checkedCheckboxRef.current.length) {
                    for (let i = 0; i < checkedCheckboxRef.current.length; i++) {
                        await api.archive.moveArticleFromArchive(checkedCheckboxRef.current[i].id);
                    }
                }

                tableRef?.current?.reload();
                reloadTree();

                dispatch(fetchMenuButtons());
                addSuccess(data.length ? 'Выбранные статьи восстановлены' : 'Статья восстановлена');
            } catch (error) {
                addError('При восстановлении возникла ошибка');
            } finally {
                setIsLoading(false);
            }
        },
        [dispatch, closeDialog, addSuccess, addError, reloadTree]
    );
    const onUnArchiveArticles = useCallback(
        (data) => {
            if (data?.length) {
                setCheckedCheckbox(data.map((d) => d.original));
            }

            let text = '';

            if (data?.length) {
                text = data.map((item) => {
                    return {
                        ...item.original,
                        text: (
                            <>
                                <span
                                    onClick={() => navigate(`/archive/article/${item.original.id}`)}
                                    style={{ color: '#279BD9', cursor: 'pointer' }}
                                >
                                    {item?.original?.title}
                                </span>
                            </>
                        ),
                    };
                });
            } else {
                text = (
                    <span>
                        Вы действительно хотите восстановить из архива статью&nbsp;
                        <span style={{ color: '#279BD9', cursor: 'pointer' }} onClick={() => navigate(`/archive/article/${data.id}`)}>
                            {data?.title}
                        </span>
                        ?
                    </span>
                );
            }

            openDialog({
                title: 'Восстановление',
                subTitle: data?.length ? 'Вы действительно хотите восстановить из архива нижеперечисленные документы?' : null,
                text,
                contentType: data?.length ? 'CHECKBOX_LIST' : 'TEXT',
                closeBtnText: 'Нет, отменить',
                submitBtnText: 'Подтвердить',
                onChange: (articles) => checkboxHandler(articles),
                onSubmit: () => unArchiveArticles(data),
                onClose: closeDialog,
            });
        },
        [closeDialog, openDialog, unArchiveArticles, checkboxHandler, navigate]
    );

    const articlesColumns = useMemo(
        () => [
            {
                Header: 'Содержание',
                accessor: 'title',
                Cell: (data) => (
                    <TableTitleCell
                        title={data.row.original.title}
                        link={`/archive/article/${data.row.original['id']}`}
                    />
                ),
            },
            {
                Header: 'Дата изменения',
                accessor: 'modifyTime',
                Cell: (data) => {
                    const date = new Date().toLocaleString();
                    return (
                        <div>
                            {data.isMobile && <div className={cx.subheader}>Дата изменения</div>}
                            {date.substr(0, date.length - 3)}
                        </div>
                    );
                    // return data.row.original['article']['lastAction']
                    //     ? new Date(data.row.original['article']['lastAction']['timestamp']).toLocaleString()
                    //     : new Date(data.row.original['article']['createTime']).toLocaleString();
                },
            },
            {
                Header: 'Автор',
                accessor: 'multiName',
                Cell: (data) => {
                    return (
                        <div>
                            {data.isMobile && <div className={cx.subheader}>Автор</div>}
                            {data.row.original['author']['status'] === 'DELETED' ? (
                                <span style={{ opacity: 1 }}>
                                    {data.row.original['author']['firstName'] + ' ' + data.row.original['author']['lastName']}
                                </span>
                            ) : (
                                <Link to={'/edit-user/' + data.row.original['author']['login']}>
                                    {data.row.original['author']['firstName'] + ' ' + data.row.original['author']['lastName']}
                                </Link>
                            )}
                        </div>
                    );
                },
            },
            {
                id: 4,
                width: 75,
                maxWidth: 75,
                settings: platform === 'mobile' ? ['no_td_wrap'] : [],
                Cell: function (data) {
                    const toolbar = [];

                    toolbar.push({
                        icon: Icons.RELOAD,
                        tooltip: 'Разархивировать',
                        onClick: (data) => onUnArchiveArticles(data),
                        isHidden: (original) => !original?.permissions?.canRestore,
                    });

                    toolbar.push({
                        icon: Icons.TRASH,
                        iconHoverColor: 'red',
                        tooltip: 'Удалить',
                        onClick: (data) => onDeleteArticles(data),
                        isHidden: (original) => !original?.permissions?.canDelete,
                    });

                    return EditRowToolbar(toolbar)(data);
                },
            },
        ],
        [onDeleteArticles, onUnArchiveArticles, platform]
    );
    const articlesActions = useMemo(() => {
        const actions = [];

        if (isAuthorizedRole([USER_ROLES.ROLE_ROOT, USER_ROLES.ROLE_ADMIN, USER_ROLES.ROLE_SUPER_USER])) {
            actions.push({
                icon: Icons.RELOAD,
                label: 'Разархивировать',
                onClick: (data) => onUnArchiveArticles(data),
            });
        }

        if (isAuthorizedRole([USER_ROLES.ROLE_ROOT])) {
            actions.push({
                icon: Icons.TRASH,
                className: cx.deleteActionButton,
                label: 'Удалить',
                onClick: (data) => onDeleteArticles(data),
            });
        }

        return actions;
    }, [onUnArchiveArticles, isAuthorizedRole, onDeleteArticles]);

    const loadUsers = async (search) => {
        let options = [];

        if (search) {
            options = users.filter((user) => {
                return user.label.toString().toLowerCase().includes(search.toLowerCase());
            });
        }

        return { options };
    };

    const articlesFilters = [
        {
            label: 'Дата создания',
            fields: [
                {
                    name: 'createTime',
                    type: 'date-select',
                    default: {
                        type: {
                            label: 'Все время',
                            value: 0,
                        },
                        from: null,
                        to: null,
                    },
                },
            ],
        },
        {
            label: 'Автор',
            fields: [
                {
                    name: 'authorLogin.in',
                    type: 'search',
                    default: null,
                    isMulti: true,
                    loadOptions: loadUsers,
                },
            ],
        },
        {
            label: 'Срок действия статьи',
            fields: [
                {
                    name: 'lifeTime.greaterOrEqualThan',
                    type: 'date',
                    default: null,
                },
                {
                    name: 'lifeTime.lessOrEqualThan',
                    type: 'date',
                    default: null,
                },
            ],
        },
        {
            label: 'Дата добавления в архив',
            fields: [
                {
                    name: 'archiveAddTime.greaterOrEqualThan',
                    type: 'date',
                    default: null,
                },
                {
                    name: 'archiveAddTime.lessOrEqualThan',
                    isEndOfTheDay: true,
                    type: 'date',
                    default: null,
                },
            ],
        },
        {
            label: 'Участник, архивировавший статью',
            fields: [
                {
                    name: 'archiveUserLogin.in',
                    type: 'search',
                    default: null,
                    isMulti: true,
                    loadOptions: loadUsers,
                },
            ],
        },
    ];

    useEffect(() => {
        const fetchData = async () => {
            const usersResponse = await selectUsers(selector);
            setUsers(
                usersResponse.content?.map((user) => ({
                    label: user.firstName + ' ' + user.lastName,
                    value: user.login,
                }))
            );
        };
        fetchData();
    }, [selector]);

    useEffect(() => {
        checkedCheckboxRef.current = checkedCheckbox;
    }, [checkedCheckbox]);

    useEffect(() => {
        setIsReLoading(true);
        setIsEmpty(false);

        setArticlesTotal(0);
        setIsInit(prev => {
            if (!prev) {
                return !prev;
            }

            tableRef?.current?.reload();
            return prev;
        });
    }, [sectionId]);

    useEffect(() => {
        if (tree) {
            treeRef.current = tree;
        }
    }, [tree]);
    useEffect(() => {
        setOnReloadTreeAction(() => () => tableRef?.current?.reload());
    }, [setOnReloadTreeAction]);

    return (
        <div className={cx.tabArticles}>
            <Confirmation {...dialogState} />
            {isLoading && isReLoading && (
                <div className={cx.loader}>
                    <Loading withOverlay={false} />
                </div>
            )}
            {isEmpty && !isLoading && (
                <EmptyArticles />
            )}
            {!isEmpty && (
                <TableComponent
                    innerRef={tableRef}
                    isLoading={isLoading}
                    archive={true}
                    title="Статьи"
                    searchTitle="Поиск по автору и названию"
                    columns={articlesColumns}
                    actions={articlesActions}
                    total={articlesTotal}
                    data={articles}
                    onPaginate={requestArticles}
                    isMobile={platform === 'mobile'}
                    filters={articlesFilters}
                    defaultSort={{ by: 'modifyTime', desc: true }}
                    sortOptions={[
                        { Header: 'По дате создания', accessor: 'createTime' },
                        { Header: 'По дате архивации (по умолчанию)', accessor: 'modifyTime' },
                        { Header: 'По названию (по алфавиту)', accessor: 'title' },
                        { Header: 'По автору (по алфавиту по фамилии)', accessor: 'multiName' },
                        { Header: 'По количеству лайков', accessor: 'likesCount' },
                        { Header: 'По количеству дизлайков', accessor: 'dislikesCount' },
                        { Header: 'По популярности (кол-во просмотров)', accessor: 'readCount' },
                        { Header: 'По количеству комментариев', accessor: 'commentsCount' },
                        { Header: 'По рейтингу (оценка пользователя после прочтения)', accessor: '' },
                    ]}
                />
            )}
        </div>
    );
};

export default ArchiveSectionTabArticles;
