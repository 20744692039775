import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCancelBehavior, useDialog, useGlobalContext, useMessage } from 'lib/hooks';
import { updateNewsTicker } from 'slice/newsPickerSlice';
import hot from 'containers/hot-container';
import PrivatePage from 'containers/private-page-wrapper';
import Button from 'uikit/button';
import { newsFormPage } from 'components/news-form';
import Preview from 'components/news-preview';
import Loading from 'uikit/loading';
import Divider from 'uikit/divider';
import api from 'api';
import cs from './add-news-page.module.scss';
import { useUpload } from 'components/background-upload/upload-provider';
import Confirmation from 'components/confirmation';
import classNames from 'classnames';
import { incrementUnreadCounter } from 'slice/authSlice';
import UploadEditorImages from 'lib/helpers/uploadEditorImages';
import { setDocumentTitle, useNavigate } from 'shared/router';

const AddNewsPage = ({ news, loading, setLoading, clearNews, hasAnyAuthorities = [], clearAutoSave }) => {
    const navigate = useNavigate();

    const { platform, setFormDataChanged } = useGlobalContext();
    const dispatch = useDispatch();

    const { addError, addSuccess } = useMessage();
    const { cancel } = useCancelBehavior();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [previewShow, setPreviewShow] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    const { showBackgroundModal, isUploading, addGroupCallback, removeGroupCallback } = useUpload(news.timeId);
    const [queuedAction, setQueuedAction] = useState(null);

    const onOk = async () => {
        showBackgroundModal();

        const formData = news.getFormData();
        formData.text = await UploadEditorImages(news.id ?? news.initId, formData.text);

        const oldTicker = news.ticker;
        let method;
        switch (queuedAction) {
            case 'addNews':
                method = api.news.postNews;
                break;
            case 'addNewsDraft':
                method = api.news.postNewsDraft;
                break;
            default:
                method = () => console.log('won\'t happen, but warning');
        }
        const callback = (uploadedFiles) => {
            const files = [...formData.files, ...uploadedFiles.filter((f) => f.status === 'done').map((f) => f.fileId)];
            // uniques only
            formData.files = files.filter((v, i, a) => a.indexOf(v) === i);
            method(formData);
            if (oldTicker !== formData.tickerRequest) {
                dispatch(updateNewsTicker(true));
            }
            addSuccess('Новость добавлена.');
            removeGroupCallback(callback);
        };
        addGroupCallback(callback);
        closeDialog();

        addSuccess('Действие будет выполнено после загрузки файлов. Не закрывайте вкладку.');
        navigate('/news');
    };

    const onBackgroundUpload = () => {
        openDialog({
            title: 'Фоновый режим',
            text: 'Не все файлы были загружены. Загрузить их в фоновом режиме?',
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Подтвердить',
            onSubmit: onOk,
            onClose: closeDialog,
        });
    };

    const addNews = async () => {
        setFormDataChanged(false);
        news.commit();
        news.submit();

        setIsSubmit(true);

        if (!news.validateFields()) {
            return;
        }

        if (isUploading) {
            setQueuedAction('addNews');
            onBackgroundUpload();
            return;
        }

        setPreviewShow(false);
        setLoading(true);

        try {
            const formData = news.getFormData();
            formData.text = await UploadEditorImages(news.id ?? news.initId, formData.text);

            if (formData.publicationTime) {
                await api.news.postDelayedNews(formData);
                addSuccess('Новость добавлена в отложенные публикации');

                clearNews();
                navigate('/user/delayed-publications/news');
            } else {
                const res = await api.news.postNews(formData);

                addSuccess('Новость опубликована');

                if (formData.tickerRequest) {
                    dispatch(updateNewsTicker(true));
                }

                clearNews();
                dispatch(incrementUnreadCounter('unreadNews'));
                navigate((!res.project ? '' : '/projects') + `/news/${res.id}`);
            }

            clearAutoSave();
        } catch (error) {
            addError('Не удалось опубликовать новость');
        } finally {
            setLoading(false);
        }
    };

    const addNewsDraft = async () => {
        setFormDataChanged(false);
        news.commit();
        news.submit();

        setIsSubmit(true);

        if (!news.validateFields(['title', 'text'])) {
            return;
        }

        if (isUploading) {
            setQueuedAction('addNewsDraft');
            onBackgroundUpload();
            return;
        }

        setPreviewShow(false);
        setLoading(true);

        try {
            const formData = news.getFormData();
            formData.text = await UploadEditorImages(news.id ?? news.initId, formData.text);

            const res = await api.news.postNewsDraft(JSON.stringify(formData));

            addSuccess(' Черновик новости создан');
            clearNews();

            if (!news.id) {
                navigate('/user/drafts/news');
            } else {
                navigate((!res.project ? '' : '/projects') + `/news/${res.id}`);
            }

            clearAutoSave();
        } catch (error) {
            addError('Не удалось сохранить новость');
        } finally {
            setLoading(false);
        }
    };

    const _onPreview = () => {
        setFormDataChanged(false);
        news.commit();
        news.submit();

        setPreviewShow(true);
    };

    useEffect(() => {
        if (isSubmit && Object.values(news.errors).some((v) => v)) {
            news.scrollToError();
            setIsSubmit(false);
        }
    }, [isSubmit, news, news.errors]);
    useEffect(() => {
        setDocumentTitle('Добавление новости — KMS Gran');
    }, []);

    return (
        <PrivatePage hasAnyAuthorities={hasAnyAuthorities}>
            <Confirmation {...dialogState} />

            {loading && <Loading withOverlay={false} withRelativeOverlay={true} />}

            <Divider className={cs.toolDivider} />

            <div className={classNames(cs.tool, { [cs.mobile]: platform === 'mobile' })}>
                <Button
                    label={'Отмена'}
                    disabled={loading}
                    onClick={cancel}
                    fullWidth={platform === 'mobile'}
                />
                <Button
                    label={'В черновик'}
                    disabled={loading}
                    onClick={addNewsDraft}
                    fullWidth={platform === 'mobile'}
                />
                <Button
                    label={'Предпросмотр'}
                    disabled={loading}
                    onClick={_onPreview}
                    fullWidth={platform === 'mobile'}
                />
                <Button
                    label={'Опубликовать'}
                    color={'green'}
                    disabled={loading}
                    onClick={addNews}
                    fullWidth={platform === 'mobile'}
                />
            </div>

            <Preview
                show={previewShow}
                onDismiss={() => setPreviewShow(false)}
                onDraft={addNewsDraft}
                onPublish={addNews}
                news={news}
                type={'news'}
            />
        </PrivatePage>
    );
};

export default hot(newsFormPage(AddNewsPage, 'Добавление новости'));
