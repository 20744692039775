import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { fetchMenuButtons, selectUsers } from 'slice/authSlice';
import { Link, useNavigate } from 'shared/router';
import { useAuthorized, useDialog, useGlobalContext, useMessage } from 'lib/hooks';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import Confirmation from 'components/confirmation';
import { TableComponent } from 'components/data-components';
import { Icons } from 'uikit/icon';
import { EditRowToolbar } from 'uikit/table';
import api from 'api/index';
import cx from './tab-articles.module.scss';
import TableTitleCell from 'components/table-title-cell';
import Loading from 'uikit/loading';
import { EmptyArticles, EmptySectionsArticle } from './empty';

const TabArticles = ({ projectId = '', reloadTree, setOnReloadTreeAction }) => {
    const tableRef = useRef(null);

    const selector = useSelector(state => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { dialogState, openDialog, closeDialog } = useDialog();
    const { addSuccess, addError } = useMessage();

    const { isAuthorizedAction } = useAuthorized();
    const { platform } = useGlobalContext();

    const [users, setUsers] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [, setIsInit] = useState(false);

    const [isReLoading, setIsReLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    const [isSectionsLoading, setIsSectionsLoading] = useState(false);
    const [isSections, setIsSections] = useState(false);

    const [articlesTotal, setArticlesTotal] = useState(0);
    const [articles, setArticles] = useState([]);

    const [checkedCheckbox, setCheckedCheckbox] = useState([]);
    const checkedCheckboxRef = useRef(checkedCheckbox);

    const isCreateAuthorized = isAuthorizedAction([
        isSections ? GLOBAL_ACTIONS.ARTICLE_CREATE : GLOBAL_ACTIONS.SECTION_CREATE
    ]);

    const requestArticles = useCallback(async (offset, count, sort = { by: '', desc: false }, search = '', filters = {}) => {
        setIsLoading(true);

        const articlesResponse = await api.article.getProjectArticles(projectId, offset / count, count,
            sort.by ? (sort.by + ',' + (sort.desc ? 'desc' : 'asc')) : 'modifyTime,desc', search, filters);

        setArticlesTotal(articlesResponse.totalElements);
        setArticles(articlesResponse.content);

        const filtersClone = structuredClone(filters);
        delete filtersClone['readied.in'];

        setIsEmpty(articlesResponse.totalElements === 0 && _.isEmpty(filtersClone) && !search);

        setIsLoading(false);
        setIsReLoading(false);
    }, [projectId]);
    const checkboxHandler = useCallback((node) => {
        setCheckedCheckbox((prevValue) => {
          if (prevValue.some(c => c.id === node.id)) {
              return prevValue.filter(c => c.id !== node.id);
          } else {
              return [...prevValue, node]
          }
        })
    }, [])

    const archiveArticles = useCallback(async (data) => {
        try {
            setIsLoading(true);
            closeDialog();

            if (data?.id) {
                await api.archive.moveArticleToArchive(data.id);
            }  else if (checkedCheckboxRef.current.length) {
                await api.archive.moveToArchive(data.map(article => article.id));
            }

            tableRef?.current?.reload();
            reloadTree();

            dispatch(fetchMenuButtons());
            addSuccess(data?.id ? 'Статья перемещена в архив' : 'Выбранные статьи перенесены в архив');
        } catch (error) {
            addError('При архивировании произошла ошибка')
        } finally {
            setIsLoading(false);
        }
    }, [dispatch, closeDialog, addSuccess, addError, reloadTree]);
    const onArticlesArchive = useCallback(async (data) => {
        if (data?.length) {
            setCheckedCheckbox(data.map(d => d.original))
        }

        let text = '';

        if (data?.length) {
            text = data.map((item) => {
                return {
                    ...item.original,
                    text: (
                        <span style={{ color: '#279BD9' }} onClick={() => {
                            navigate(`/projects/articles/${item.original['id']}`);
                            closeDialog();
                        }}>{item.original.title}</span>
                    )
                }
            });
        } else {
            text = (
                <span>
                    Вы действительно хотите перенести в архив статью&nbsp;
                    <span style={{ color: '#279BD9' }} onClick={() => navigate(`/projects/articles/${data['id']}`)}>
                        {data?.title}
                    </span>
                    &nbsp;? Объекты в архиве могут быть восстановлены
                </span>
            );
        }

        openDialog({
            title: 'Архивирование',
            subTitle: data?.length ? 'Вы действительно хотите переместить в архив нижеперечисленные объекты? Объекты в архиве могут быть восстановлены' : null,
            text,
            contentType: data?.length ? 'CHECKBOX_LIST' : 'TEXT',
            color: 'green',
            closeBtnText: 'Нет, закрыть',
            submitBtnText: 'Подтвердить',
            onChange: (articles) => checkboxHandler(articles),
            onSubmit: () => archiveArticles(data?.length ? checkedCheckboxRef.current : data),
            onClose: closeDialog
        });
    }, [checkboxHandler, archiveArticles, navigate, openDialog, closeDialog]);

    const articlesColumns = useMemo(() => [
        {
            Header: 'Содержание',
            accessor: 'title',
            Cell: (data) => (
                <TableTitleCell
                    title={data.row.original.title}
                    link={`/projects/article/${data.row.original['id']}`}
                />
            ),
        },
        {
            Header: 'Дата изменения',
            accessor: 'modifyTime',
            Cell: (data) => {
                const createTime = new Date(data.row.original['createTime']).toLocaleString();
                const modifyTime = new Date(data.row.original['modifyTime']).toLocaleString();

                return (
                    <div>
                        {data.isMobile && <div className={cx.subheader}>Дата изменения</div>}
                        {modifyTime
                            ? modifyTime.substr(0, modifyTime.length - 3)
                            : createTime.substr(0, createTime.length - 3)}
                    </div>
                )
            }
        },
        {
            Header: 'Автор',
            accessor: 'multiName',
            Cell: (data) => {
                return (
                    <div>
                        {data.isMobile && <div className={cx.subheader}>Автор</div>}
                        {data.row.original['author']['status'] === 'DELETED'
                            ? <span style={{opacity: 1}}>{data.row.original['author']['firstName'] + ' ' + data.row.original['author']['lastName']}</span>
                            : <Link to={'/edit-user/' + data.row.original['author']['login']}>
                                {data.row.original['author']['firstName'] + ' ' + data.row.original['author']['lastName']}
                            </Link>}
                    </div>
                );
            }
        },
        {
            id: 4,
            settings: platform === 'mobile' ? ['no_td_wrap'] : [],
            Cell: function(data) {
                let toolbar = [];

                toolbar.push({
                    icon: Icons.EDIT_PEN,
                    tooltip: 'Редактировать',
                    link: `/projects/article/${data.row.original.id}/edit`,
                    isHidden: (original) => !original.permissions.canEdit
                });

                toolbar.push({
                    icon: data.row.original['favorite'] ? Icons.STAR_FILLED : Icons.STAR,
                    iconActive: data.row.original['favorite'],
                    tooltip: 'В избранное',
                    onClick: async (data) => {
                        if (data.favorite) {
                            await api.favorites.deleteArticleFromFavorites(data.id);
                            addSuccess('Статья удалена из избранного');
                        } else {
                            await api.favorites.addArticleToFavorites(data.id);
                            addSuccess('Статья добавлена в избранное');
                        }

                        const articlesCopy = Object.assign([], articles);
                        articlesCopy.find((item) => item.id === data.id).favorite = !data.favorite;
                        setArticles(articlesCopy);
                    }
                });

                toolbar.push({
                    icon: Icons.ARCHIVE,
                    tooltip: 'Архивировать',
                    onClick: (data) => onArticlesArchive(data),
                    isHidden: (original) => !original.permissions.canArchive
                });

                return EditRowToolbar(toolbar)(data);
            }
        }
    ], [addSuccess, articles, onArticlesArchive, platform]);
    const articlesActions = useMemo(() => {
        const actions = [];
        const canArchive = (articles ?? []).some(article => article.permissions?.canArchive);

        if (canArchive) {
            actions.push({
                icon: Icons.ARCHIVE,
                label: 'В архив',
                onClick: (data) => onArticlesArchive(data)
            });
        }

        return actions;
    }, [articles, onArticlesArchive]);

    const loadUsers = async (search) => {
        let options = [];

        if (search) {
            options = users.filter(user => {
              return user.label.toString().toLowerCase().includes(search.toLowerCase());
            })
        }

        return { options }
    }

    const articlesFilters = [
        {
            'label': 'Дата создания',
            'fields': [
                {
                    'name': 'createTime',
                    'type': 'date-select',
                    'default': {
                        'type': {
                            label: 'Все время',
                            value: 0
                        },
                        'from': null,
                        'to': null
                    }
                }
            ]
        },
        {
            'label': 'Автор',
            'fields': [
                {
                    'name': 'authorLogin.in',
                    'type': 'search',
                    'default': null,
                    'isMulti': true,
                    'loadOptions': loadUsers
                }
            ]
        },
        {
            'label': 'Срок действия статьи',
            'fields': [
                {
                    'name': 'lifeTime.greaterOrEqualThan',
                    'type': 'date',
                    'default': null
                },
                {
                    'name': 'lifeTime.lessOrEqualThan',
                    'type': 'date',
                    'default': null
                }
            ]
        },
        {
            'label': 'Наличие вложений',
            'fields': [
                {
                    'name': 'containsAttachment.equals',
                    'type': 'select',
                    'default': null,
                    'options': [
                        {label: 'Есть', value: 'true'},
                        {label: 'Нет', value: 'false'},
                        {label: 'Все', value: null},
                    ]
                }
            ]
        }
    ];

    useEffect(() => {
        checkedCheckboxRef.current = checkedCheckbox;
    }, [checkedCheckbox]);
    useEffect(() => {
        const fetchData = async () => {
            const usersResponse = await selectUsers(selector);
            setUsers(usersResponse.content?.map(user => ({
                label: user.firstName + ' ' + user.lastName,
                value: user.login,
            })));
        };
        fetchData();
    }, [selector]);

    useEffect(() => {
        const fetchSections = async () => {
            setIsSectionsLoading(true);
            const response = await api.section.getRootSections(projectId);

            setIsSections(response.length !== 0);
            setIsSectionsLoading(false);
        };

        fetchSections();

        setIsReLoading(true);
        setIsEmpty(false);

        setArticlesTotal(0);
        setIsInit(prev => {
            if (!prev) {
                return !prev;
            }

            tableRef?.current?.reload();
            return prev;
        });
    }, [projectId]);

    useEffect(() => { setOnReloadTreeAction(() => () => tableRef?.current?.reload()) }, [setOnReloadTreeAction]);

    return (
        <div className={cx.tabArticles}>
            <Confirmation {...dialogState} />
            {((isLoading && isReLoading) || isSectionsLoading) && (
                <div className={cx.loader}>
                    <Loading withOverlay={false} />
                </div>
            )}
            {isEmpty && !isLoading && !isSectionsLoading && (
                <>
                    {!isSections && (
                        <EmptySectionsArticle buttons={isCreateAuthorized ? [{
                            icon: Icons.EDIT_PEN,
                            title: 'Добавить раздел',
                            link: '/add/section'
                        }] : null} />
                    )}
                    {isSections && (
                        <EmptyArticles buttons={isCreateAuthorized ? [{
                            icon: Icons.EDIT_PEN,
                            title: 'Добавить статью',
                            link: '/add/article'
                        }] : null} />
                    )}
                </>
            )}
            {!isEmpty && !isSectionsLoading && (
                <TableComponent
                    innerRef={tableRef}
                    id={'tabArticlesTableComponent'}
                    isLoading={isLoading}
                    title="Статьи"
                    name="articles"
                    searchTitle="Поиск по автору и названию"
                    addTitle="Добавить статью"
                    addTitleMobile="Добавить"
                    columns={articlesColumns}
                    actions={articlesActions}
                    total={articlesTotal}
                    data={articles}
                    onPaginate={requestArticles}
                    filters={articlesFilters}
                    isMobile={platform === 'mobile'}
                    onAdd={isAuthorizedAction([GLOBAL_ACTIONS.ARTICLE_CREATE]) ? () => navigate('/add/article') : undefined}
                    defaultSort={{ by: 'modifyTime', desc: true }}
                    isShowReadiedFilter={true}
                    sortOptions={[
                        { Header: 'По дате создания', accessor: 'createTime' },
                        { Header: 'По дате изменения (по умолчанию)', accessor: 'modifyTime' },
                        { Header: 'По названию (по алфавиту)', accessor: 'title' },
                        { Header: 'По автору (по алфавиту по фамилии)', accessor: 'multiName' },
                        { Header: 'По количеству лайков', accessor: 'likesCount' },
                        { Header: 'По количеству дизлайков', accessor: 'dislikesCount' },
                        { Header: 'По популярности (кол-во просмотров)', accessor: 'readCount' },
                        { Header: 'По количеству комментариев', accessor: 'commentsCount' },
                        { Header: 'По рейтингу (оценка пользователя после прочтения)', accessor: '' },
                    ]}
                />
            )}
        </div>
    );
};

export default TabArticles;
