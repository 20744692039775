import React, { useEffect } from 'react';
import { useNavigate } from 'shared/router';
import ContentManagementProjectFormPage from './content-management-form';
import cs from './content-management-form-page.module.scss';
import { useSectionFormModel } from 'lib/hooks/use-section-form-model';

export const contentManagementSectionFormPage = (Component, pageTitle) => {
    const FormPage = (props) => {

        const navigate = useNavigate();
        const { uuid } = props;

        let model = useSectionFormModel(uuid);

        const { section, loading, permissionsOk } = model;

        useEffect(() => {
            if (!permissionsOk) {
                navigate('/401');
            }
        }, [navigate, permissionsOk]);

        return (
            <div className={cs.content}>
                <div id={'contentManagementProjectFormPageTitle'} className={cs.pageTitle}>{pageTitle}</div>
                <ContentManagementProjectFormPage resource={section} loading={loading}>
                    <Component {...props} {...model}/>
                </ContentManagementProjectFormPage>
            </div>
        );
    };

    return FormPage;
};
