import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Link, setDocumentTitle, useNavigate, useParams } from 'shared/router';
import { useAuthorized, useDialog, useGlobalContext, useMessage } from 'lib/hooks';
import { useUserFormModel } from 'lib/hooks/use-user-form-model';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import { ContentWrapper } from 'containers/content-wrapper';
import UserForm from 'components/user-form/user-form';
import { Confirmation } from 'components/confirmation/confirmation';
import IconButton from 'uikit/icon-button';
import Icon, { Icons } from 'uikit/icon';
import api from 'api/index';
import { setUsers } from 'slice/authSlice';
import { useDispatch } from 'react-redux';
import UserHeader from 'uikit/user-header';
import ButtonMenu from 'uikit/button-menu';
import { EmptyUserDeleted } from 'components/projects/empty';

const UserPage = () => {
    const { userLogin } = useParams();

    const { platform } = useGlobalContext();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { isAuthorizedAction } = useAuthorized();

    const { addSuccess, addError } = useMessage();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const model = useUserFormModel(userLogin);
    const { user, loading, deleted } = model;

    const [positions, setPositions] = useState(null);

    const blockUser = useCallback(async () => {
        try {
            closeDialog();
            await api.user.blockUser(user.login);

            user.status = 'BLOCKED';
            user.commit();

            const users = await api.user.getUsers(0, 999999);
            dispatch(setUsers(users.data));

            addSuccess('Участник заблокирован');
        } catch (error) {
            addError('При блокировании возникла ошибка');
        }
    }, [dispatch, user, addSuccess, addError, closeDialog]);
    const onBlockUser = useCallback(() => {
        openDialog({
            title: 'Блокирование',
            text: (
                <span>
                    Вы действительно хотите заблокировать участника 
                    <span style={{ color: '#279BD9' }}>
                        {user?.firstName} {user.lastName}
                    </span> ?
                </span>
            ),
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Да, заблокировать',
            onSubmit: () => blockUser(),
            onClose: closeDialog,
            contentType: 'TEXT'
        });
    }, [user, openDialog, closeDialog, blockUser])

    const unBlockUser = useCallback(async () => {
        closeDialog();

        try {
            await api.user.unBlockUser(user.login);

            const users = await api.user.getUsers(0, 999999);
            dispatch(setUsers(users.data));

            user.status = 'ACTIVE';
            user.commit();

            addSuccess('Участник разблокирован');
        } catch (error) {
            addError('При разблокировании возникла ошибка');
        }
    }, [dispatch, user, addSuccess, addError, closeDialog]);
    const deleteUser = useCallback(async () => {
        closeDialog();

        try {
            await api.user.deleteUser(user.login);

            const users = await api.user.getUsers(0, 999999);
            dispatch(setUsers(users.data));

            addSuccess('Участник удалён');

            navigate('/users/blocked-users/all');
        } catch (error) {
            addError('При удалении возникла ошибка');
        }
    }, [dispatch, navigate, user, addSuccess, addError, closeDialog]);

    const onUnBlockUser = useCallback(() => {
        openDialog({
            title: 'Разблокирование',
            text: (
                <span>
                    Вы действительно хотите разблокировать участника 
                    <span style={{ color: '#279BD9' }}>
                        {user?.firstName} {user.lastName}
                    </span> ?
                </span>
            ),
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Да, разблокировать',
            onSubmit: () => unBlockUser(),
            onClose: closeDialog,
            contentType: 'TEXT'
        });
    }, [user, openDialog, closeDialog, unBlockUser])
    const onDeleteUser = useCallback(() => {
        openDialog({
            title: 'Удаление',
            color: 'red',
            text: (
                <span>
                    Вы действительно хотите удалить участника 
                    <span style={{ color: '#279BD9' }}>
                        {user?.firstName} {user.lastName}
                    </span> ?
                </span>
            ),
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Подтвердить',
            onSubmit: () => deleteUser(),
            onClose: closeDialog,
            contentType: 'TEXT'
        });
    }, [user, openDialog, closeDialog, deleteUser])

    const goBack = () => {
        if (window.history.length > 0) {
            navigate(-1);
        } else {
            if (user.status === 'ACTIVE') {
                navigate('/users/users-page/all');
            } else if (user.status === 'BLOCKED') {
                navigate('/users/blocked-users/all');
            }
        }
    };
    const actions = useMemo(() => {
        if (platform === 'mobile') {
            return (
                <ButtonMenu
                    items={[
                        {
                            icon: Icons.EDIT_PEN,
                            title: 'Редактировать',
                            onClick: () => navigate(`/edit-user/${user.login}/edit`),
                            isShow: user.permissions?.canEdit && user.status !== 'BLOCKED'
                        },
                        {
                            icon: Icons.BLOCK,
                            title: 'Заблокировать',
                            onClick: onBlockUser,
                            isShow: user.permissions?.canBlock && user.status === 'ACTIVE'
                        },
                        {
                            icon: Icons.UNBLOCK,
                            title: 'Разблокировать',
                            onClick: onUnBlockUser,
                            isShow: user.permissions?.canBlock && user.status === 'BLOCKED'
                        },
                        {
                            icon: Icons.TRASH,
                            title: 'Удалить',
                            onClick: onDeleteUser,
                            isShow: user.permissions?.canDelete && user.status === 'BLOCKED'
                        }
                    ]}
                />
            );
        }

        return (
            <>
                {user.permissions?.canEdit && user.status !== 'BLOCKED' &&
                <Link id={'userPageHeaderEditIcon'} to={`/edit-user/${user.login}/edit`}>
                    <IconButton icon={<Icon type={Icons.EDIT_PEN} tooltip={'Редактировать'} width={16} height={16}/>}/>
                </Link>}

                {user.permissions?.canBlock && user.status === 'ACTIVE' &&
                <div id={'userPageHeaderBlockIcon'} onClick={onBlockUser}>
                    <IconButton icon={<Icon type={Icons.BLOCK} width={16} height={16} tooltip={'Заблокировать'}/>}/>
                </div>}

                {user.permissions?.canBlock && user.status === 'BLOCKED' &&
                <div id={'userPageHeaderUnblockIcon'} onClick={onUnBlockUser}>
                    <IconButton icon={<Icon type={Icons.UNBLOCK} width={16} height={16} tooltip={'Разблокировать'}/>}/>
                </div>}

                {user.permissions?.canDelete && user.status === 'BLOCKED' &&
                <div id={'userPageHeaderDeleteIcon'} onClick={onDeleteUser}>
                    <IconButton icon={<Icon type={Icons.TRASH} width={16} height={16} tooltip={'Удалить'}/>}/>
                </div>}
            </>
        );
    }, [navigate, platform, onDeleteUser, onBlockUser, onUnBlockUser, user])

    useEffect(() => {
        const getPositions = async () => {
            const response = await api.userPosition.getUserPosition(0, 2000);
            setPositions(response.content.map(pos => ({ label: pos.title, value: pos.id })));
        };

        if (user && user.position > 0) {
            getPositions();
        }
    }, [user, user.position]);
    useEffect(() => {
        if (!isAuthorizedAction([GLOBAL_ACTIONS.USER_PAGE])) {
            navigate('/401');
        }
    }, [isAuthorizedAction, navigate]);

    useEffect(() => {
        if (!user?.firstName) {
            return;
        }

        setDocumentTitle(`${user.firstName} ${user.lastName} — KMS Gran`);
    }, [user]);

    if (loading) {
        return null;
    }

    if (deleted) {
        return (
            <EmptyUserDeleted />
        );
    }

    return (
        <ContentWrapper>
            <Confirmation {...dialogState} />
            <UserHeader
                userName={user.firstName + ' ' + user.lastName}
                userPosition={user.position > 0 && positions && positions.find(v => v.value === user.position).label}
                userBlocked={user.status === 'BLOCKED'}
                avatarSrc={user.avatarUuid && api.upload.getImage(user.avatarUuid, false, 512)}
                actionIcons={actions}
                idPrefix="userPage"
                onGoBackHandler={goBack}
            />
            <UserForm
                user={user}
                loading={loading}
                isEditMode={false}
                showAllNotificationTypes={false}
            />
        </ContentWrapper>
    );
};

export default UserPage;
