import { mergeAttributes, Node } from '@tiptap/core';
import { v4 } from 'uuid';

export const TableCell = Node.create({
    name: 'tableCell',

    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },

    content: 'block+',

    addAttributes() {
        return {
            cellId: {
                default: v4(),
                rendered: false,
            },
            colspan: {
                default: 1,
            },
            rowspan: {
                default: 1,
            },
            align: {
                default: null,
                parseHTML: el => el.getAttribute('align') || el.style.textAlign,
                renderHTML: attrs => {
                    return attrs.align ? { align: attrs.align, style: `text-align: ${attrs.align}` } : {};
                },
            },
            valign: {
                default: 'middle',
                renderHTML: attrs => {
                    return attrs.valign ? { valign: attrs.valign, style: `vertical-align: ${attrs.valign}` } : {};
                },
            },
            colwidth: {
                default: null,
                parseHTML: element => {
                    const colwidth = element.getAttribute('colwidth') || element.style.width;
                    const value = colwidth ? [parseInt(colwidth, 10)] : null;
                    return value;
                },
                renderHTML: attrs => {
                    if (!attrs.colwidth) {
                        return {};
                    }

                    return {
                        colwidth: attrs.colwidth - 2,
                        style: `width: ${attrs.colwidth - 2}px;`,
                    };
                },
            },
            bgcolor: {
                default: '',
                parseHTML: el => el.getAttribute('bgcolor') || el.style.backgroundColor,
                renderHTML: attrs => {
                    if (!attrs.bgcolor) {
                        return {};
                    }
                    return {
                        style: `background-color: ${attrs.bgcolor};`,
                    };
                },
            },
            'data-border-color': {
                default: '#D8D8D8',
                parseHTML: el => el.getAttribute('data-border-color') || el.style.borderColor,
                renderHTML: attrs => {
                    if (!attrs['data-border-color']) {
                        return {};
                    }

                    return {
                        style: `border-color: ${attrs['data-border-color']}`,
                    };
                },
            },
            textcolor: {
                default: '',
                parseHTML: el => el.getAttribute('textcolor') || el.style.color,
                renderHTML: attrs => {
                    if (!attrs.textcolor) {
                        return {};
                    }

                    return {
                        style: `color: ${attrs.textcolor};`,
                    };
                },
            },
        };
    },

    tableRole: 'cell',

    isolating: true,

    draggable: false,

    selectable: false,

    parseHTML() {
        return [{ tag: 'td' }];
    },

    renderHTML({ HTMLAttributes }) {
        return ['td', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },

    addCommands() {
        return {
            changeVerticalAlign:
                align =>
                ({ commands }) => {
                    return commands.updateAttributes('tableCell', { valign: align });
                },
        };
    },
});
