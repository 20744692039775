import { ReactNodeViewRenderer } from '@tiptap/react';
import CodeBlockLowlight, { CodeBlockLowlightOptions } from '@tiptap/extension-code-block-lowlight';
import { v4 as uuidv4 } from 'uuid';
import { CodeBlockWithLineNumbersView } from './view';
import { NodeSelection, TextSelection } from '@tiptap/pm/state';
import { CellSelection } from '../../Table/cellSelection';

export const CodeBlockWithLineNumbersExt = CodeBlockLowlight.extend<CodeBlockLowlightOptions>({
    isolating: true,

    addAttributes() {
        return {
            ...this.parent?.(),
            id: {
                default: uuidv4(),
                rendered: false,
            },
            customStyles: {
                default: {
                    pre: `
                        display: grid;
                        grid-template-columns: auto 1fr;
                        line-height: 1rem;`,
                    lineNumbers: `
                        display: inline-block;
                        pointer-events: none;
                        user-select: none;
                        font-family: 'NotoSans';
                        font-size: 0.8rem;
                        text-align: right;
                        background: #DCE9F4;
                        color: #979692;
                        padding: 0.75rem 0.5rem 0.75rem 1rem;
                        margin: 1rem 0;
                        border-radius: 0.5rem 0 0 0.5rem;`,
                    lineNumber: `
                        user-select: none;`,
                    code: `
                        display: inline-block;
                        white-space: pre-wrap;
                        border-radius: 0 0.5rem 0.5rem 0;`,
                },
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'div.code-block-with-line-numbers',
                getAttrs: node => {
                    const element = node as HTMLElement;

                    const codeElement = element.querySelector('code');
                    if (codeElement) {
                        const linesDiv = element.querySelector('div');
                        if (linesDiv) linesDiv.remove();
                    }

                    return {
                        language: codeElement?.getAttribute('class')?.replace('language-', '') || 'plaintext',
                    };
                },
            },
        ];
    },

    renderHTML({ node }) {
        const attrs = node?.attrs;
        const lines = (node?.textContent || '').split('\n');
        return [
            'div',
            { class: 'code-block-with-line-numbers' },
            [
                'pre',
                { style: attrs.customStyles.pre },
                [
                    'div',
                    { style: attrs.customStyles.lineNumbers },
                    ...lines.map((_, index) => ['span', { style: attrs.customStyles.lineNumber }, `${index + 1}\n`]),
                ],
                ['code', { class: attrs.language ? `language-${attrs.language}` : '', style: attrs.customStyles.code }, node.textContent],
            ],
        ];
    },

    addCommands() {
        return {
            setCodeBlock:
                attributes =>
                ({ commands }) => {
                    return commands.setNode(this.name, attributes);
                },
            toggleCodeBlock:
                attributes =>
                ({ view, state, commands }) => {
                    const isCellSelection = state.selection instanceof CellSelection;

                    if (isCellSelection) {
                        view.dispatch(state.tr.setSelection(TextSelection.create(state.tr.doc, state.selection.from + 1, state.selection.from + 1)));
                    }
                    return commands.toggleNode(this.name, 'paragraph', attributes);
                },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(CodeBlockWithLineNumbersView);
    },

    addKeyboardShortcuts() {
        const onDelete = () => {
            return (
                (this.editor.state.selection instanceof NodeSelection && this.editor.state.selection.node.type.name === 'codeBlock') ||
                (this.editor.state.selection.$from.parent.type.name === 'codeBlock' &&
                    !this.editor.state.selection.$from.parent.textContent)
            );
        };

        return {
            ...this.parent?.(),
            Backspace: onDelete,
            Delete: onDelete,
        };
    },
});
