import { Extension } from '@tiptap/core';
import { NodeSelection, Plugin } from '@tiptap/pm/state';
import { findParentNodeClosestToPos } from './Column/utils';
import { findParentNodeOfType } from 'prosemirror-utils';

function createRect(rect, pos) {
    if (rect == null) {
        return null;
    }

    let newRect = {
        left: rect.left,
        top: rect.top,
        width: rect.width,
        height: rect.height,
        bottom: 0,
        right: 0,
    };

    if (pos === 'absolute') {
        const editorContent = document.getElementById('editor-content');
        const editorContentRect = editorContent.getBoundingClientRect();

        newRect.left = rect.left - editorContentRect.left;
        newRect.top = rect.top - editorContentRect.top + editorContent.scrollTop;
    }

    newRect.bottom = newRect.top + newRect.height;
    newRect.right = newRect.left + newRect.width;

    return newRect;
}

function absoluteRect(node) {
    return createRect(node.getBoundingClientRect(), 'absolute');
}

function nodeDOMAtCoords(coords) {
    return document.elementsFromPoint(coords.x, coords.y).find(elem => {
        return elem.parentElement?.matches?.('.ProseMirror');
    });
}

function nodePosAtDOM(node, view) {
    const boundingRect = createRect(node.getBoundingClientRect());

    return view.posAtCoords({
        left: boundingRect.left + 1,
        top: boundingRect.top + 1,
    })?.inside;
}

function DragHandle(options) {
    let dragHandleElement = null;
    let contentWrapper = null;

    let timer = null;
    let dragStart = false;

    let edgeSize = {
        top: null,
        bottom: null,
    };

    function handleDragEnd(event, view) {
        dragStart = false;
        view.dispatch(view.state.tr.setSelection(NodeSelection.create(view.state.doc, 0)));
    }
    function handleDragStart(event, view) {
        view.focus();

        if (!event.dataTransfer) {
            return;
        }

        const node = nodeDOMAtCoords({
            x: event.clientX + 50,
            y: event.clientY,
        });

        if (!(node instanceof Element)) {
            return;
        }

        const nodePos = nodePosAtDOM(node, view);

        if (nodePos == null || nodePos < 0) {
            return;
        }

        dragStart = true;
        view.dispatch(view.state.tr.setSelection(NodeSelection.create(view.state.doc, nodePos)));
        const sel = view.state.selection;

        if (sel.node?.type.name === 'anchor') {
            const firstAncestor = findParentNodeClosestToPos(sel.$from, ({ node }) => {
                return node.type.name === 'paragraph';
            });

            view.dispatch(view.state.tr.setSelection(NodeSelection.create(view.state.doc, firstAncestor.pos)));
        }
        if (sel.node?.type.name === 'column') {
            const firstAncestor = findParentNodeClosestToPos(sel.$from, ({ node }) => {
                return node.type.name === 'columnBlock';
            });

            view.dispatch(view.state.tr.setSelection(NodeSelection.create(view.state.doc, firstAncestor.pos)));
        }

        if (sel.$anchor.parent?.type.name === 'tableCell' || sel.$anchor.parent?.type.name === 'tableHeader') {
            const firstAncestor = findParentNodeOfType(view.state.schema.nodes.table)(sel);

            view.dispatch(view.state.tr.setSelection(NodeSelection.create(view.state.doc, firstAncestor.pos)));
        }

        if (sel.node?.type.name === 'listItem') {
            const firstAncestor = findParentNodeClosestToPos(sel.$from, ({ node }) => {
                return node.type.name === 'orderedList' || node.type.name === 'bulletList';
            });

            view.dispatch(view.state.tr.setSelection(NodeSelection.create(view.state.doc, firstAncestor.pos)));
        }

        const slice = view.state.tr.selection.content();

        const { dom, text } = view.serializeForClipboard(slice);

        event.dataTransfer.clearData();

        event.dataTransfer.setData('text/html', dom.innerHTML);
        event.dataTransfer.setData('text/plain', text);

        event.dataTransfer.effectAllowed = 'copyMove';

        if (view.state.selection.node?.type.name === 'resizableImage') {
            const img = node.getElementsByTagName('img');
            event.dataTransfer.setDragImage(img?.[0], 0, 0);
        } else {
            event.dataTransfer.setDragImage(node, 0, 0);
        }

        view.dragging = { slice, move: event.ctrlKey };
    }

    function handleClick(event, view) {
        view.focus();
        view.dom.classList.remove('dragging');

        const node = nodeDOMAtCoords({
            x: event.clientX + 50 + options.dragHandleWidth,
            y: event.clientY,
        });

        if (!(node instanceof Element)) {
            return;
        }

        const nodePos = nodePosAtDOM(node, view);

        if (!nodePos) {
            return;
        }

        view.dispatch(view.state.tr.setSelection(NodeSelection.create(view.state.doc, nodePos)));
    }

    function handleDocumentMouseMove(event) {
        if (!dragStart) {
            return;
        }

        let viewportY = event.clientY;
        const contentWrapperRect = contentWrapper.getBoundingClientRect();

        edgeSize.top = contentWrapperRect.top;
        edgeSize.bottom = contentWrapperRect.bottom;

        let edgeTop = edgeSize.top;
        let edgeBottom = edgeSize.bottom;

        let isInTopEdge = viewportY < edgeTop;
        let isInBottomEdge = viewportY > edgeBottom;

        if (!(isInTopEdge || isInBottomEdge)) {
            clearTimeout(timer);
            return;
        }

        let documentHeight = Math.max(contentWrapper.scrollHeight, contentWrapper.offsetHeight, contentWrapper.clientHeight);
        let maxScrollY = documentHeight;

        (function checkForWindowScroll() {
            clearTimeout(timer);

            if (adjustWindowScroll()) {
                timer = setTimeout(checkForWindowScroll, 30);
            }
        })();

        function adjustWindowScroll() {
            let currentScrollY = contentWrapper.scrollTop;
            let canScrollUp = currentScrollY > 0;

            let canScrollDown = currentScrollY < maxScrollY;
            let nextScrollY = currentScrollY;

            let maxStep = 50;

            if (isInTopEdge && canScrollUp) {
                let intensity = (edgeTop - viewportY) / edgeSize.top;
                nextScrollY = nextScrollY - maxStep * intensity;
            } else if (isInBottomEdge && canScrollDown) {
                let intensity = (viewportY - edgeBottom) / edgeSize.bottom;
                nextScrollY = nextScrollY + maxStep * intensity;
            }

            nextScrollY = Math.max(0, Math.min(maxScrollY, nextScrollY));

            if (nextScrollY !== currentScrollY) {
                contentWrapper.scrollTo(0, nextScrollY);
                return true;
            } else {
                return false;
            }
        }
    }

    function handleMouseMove(event) {
        const node = nodeDOMAtCoords({
            x: event.clientX + 50 + options.dragHandleWidth,
            y: event.clientY,
        });

        if (!(node instanceof Element)) {
            hideDragHandle();
            return;
        }

        const rect = absoluteRect(node);

        if (node.matches('ul:not([data-type=taskList]) li, ol li')) {
            rect.left -= options.dragHandleWidth;
        }

        if (node.matches('hr')) {
            rect.top -= 4;
        }

        rect.width = options.dragHandleWidth;

        if (!dragHandleElement) {
            return;
        }

        dragHandleElement.style.left = `${rect.left - rect.width}px`;
        dragHandleElement.style.top = `${rect.top + 1}px`;

        showDragHandle();
    }

    function hideDragHandle() {
        if (dragHandleElement) {
            dragHandleElement.classList.add('hidden');
        }
    }

    function showDragHandle() {
        if (dragHandleElement) {
            dragHandleElement.classList.remove('hidden');
        }
    }

    return new Plugin({
        view: view => {
            dragHandleElement = document.createElement('div');
            dragHandleElement.draggable = true;

            dragHandleElement.dataset.dragHandle = '';
            dragHandleElement.classList.add('drag-handle');

            dragHandleElement.addEventListener('drag', handleDocumentMouseMove);
            dragHandleElement.addEventListener('dragstart', e => {
                dragHandleElement.classList.add('grabing');
                handleDragStart(e, view);
            });

            dragHandleElement.addEventListener('dragend', e => {
                dragHandleElement.classList.remove('grabing');
                handleDragEnd(e, view);
            });
            dragHandleElement.addEventListener('click', e => {
                handleClick(e, view);
            });

            contentWrapper = document?.getElementById('editor-content');
            contentWrapper?.appendChild(dragHandleElement);

            contentWrapper.addEventListener('mousemove', handleMouseMove);
            hideDragHandle();

            return {
                destroy: () => {
                    document.removeEventListener('drag', handleDocumentMouseMove);
                    contentWrapper.removeEventListener('mousemove', handleMouseMove);

                    dragHandleElement?.remove?.();
                    dragHandleElement = null;
                },
            };
        },
        props: {
            handleDOMEvents: {
                dragstart: (view, event) => {
                    event.preventDefault();
                    return false;
                },
                drop: (view, event) => {
                    let pos = view.posAtCoords({ left: event.clientX, top: event.clientY });
                    const node = view.state.doc.nodeAt(pos.pos);

                    if (node?.type?.name && node.type.name.includes('tableOfContents')) {
                        event.preventDefault();
                        return false;
                    }

                    // const isTable = view.state.selection.node.type.name === 'table';
                    //
                    // if (isTable) {
                    //     console.log(pos);
                    //     if (pos.inside >= 0) {
                    //         view.dispatch(view.state.tr.setSelection(NodeSelection.create(view.state.doc, pos.inside)));
                    //     }
                    //
                    //     const firstAncestor = findParentNodeClosestToPos(view.state.selection.$from, ({ node }) => {
                    //         return node.type.name === 'table';
                    //     });
                    //
                    //     if (firstAncestor.node?.type.name === 'table') {
                    //         return true;
                    //     }
                    // }

                    view.dom.classList.remove('dragging');
                },
                dragend: view => {
                    view.dom.classList.remove('dragging');
                },
            },
        },
    });
}

const DragAndDrop = Extension.create({
    name: 'dragAndDrop',

    addProseMirrorPlugins() {
        return [
            DragHandle({
                dragHandleWidth: 24,
            }),
        ];
    },
});

export default DragAndDrop;
