import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'shared/router';
import Confirmation from 'components/confirmation';
import { TableComponent } from 'components/data-components';
import Icon, { Icons } from 'uikit/icon';
import { EditRowToolbar } from 'uikit/table';
import api from 'api';
import cx from './delayed-publications-page-tab-news.module.scss';
import { useDialog, useGlobalContext, useMessage } from 'lib/hooks';
import classNames from 'classnames';
import formatDateTime from 'lib/helpers/formatDateTime';
import { useWindowSize } from 'lib/hooks/useWindowSize';
import { MultiClumpTooltip } from 'components/MultiClumpTooltip/MultiClumpTooltip';
import { EmptyNews } from 'components/projects/empty';

const DelayedPublicationsTabNews = ({ onCountChangeHandler }) => {
    const navigate = useNavigate();
    const tableRef = useRef(null);

    const { platform } = useGlobalContext();
    const { width } = useWindowSize();

    const { dialogState, openDialog, closeDialog } = useDialog();
    const { addSuccess, addError } = useMessage();

    const [isLoading, setIsLoading] = useState(false);

    const [isEmpty, setIsEmpty] = useState(false);
    const [isSearch, setIsSearch] = useState(false);

    const [newsTotal, setNewsTotal] = useState(0);
    const [news, setNews] = useState([]);

    const [checkedCheckbox, setCheckedCheckbox] = useState([]);
    const checkedCheckboxRef = useRef(checkedCheckbox);

    const requestNews = useCallback(async (offset, count, sort = { by: '', desc: false }, search = '') => {
        try {
            setIsLoading(true);

            const newsResponse = await api.news.getDelayedNews({
                page: offset / count,
                size: count,
                sort: sort.by ? (sort.by + ',' + (sort.desc ? 'desc' : 'asc')) : '',
                search
            });

            setNewsTotal(newsResponse.totalElements);
            setNews(newsResponse.content);

            setIsSearch(!!search);
            setIsEmpty(newsResponse.totalElements === 0);

            setIsLoading(false);
        } catch {

        }
    }, []);

    const checkboxHandler = useCallback((node) => {
        setCheckedCheckbox((prevValue) => {
            if (prevValue.some(c => c.id === node.id)) {
                return prevValue.filter(c => c.id !== node.id);
            } else {
                return [...prevValue, node]
            }
        })
    }, [])

    const deleteDelayedNews = useCallback(async (data) => {
        setIsLoading(true);
        closeDialog();

        try {
            if (data.id) {
                await api.news.deleteDelayedNews(data.id);
            } else if (checkedCheckboxRef.current.length) {
                for (let i = 0; i < checkedCheckboxRef.current.length; i++) {
                    await api.news.deleteDelayedNews(checkedCheckboxRef.current[i].id);
                }
            } else {
                setIsLoading(false);
                return;
            }

            tableRef?.current?.reload();
            onCountChangeHandler();

            addSuccess(data.id ? 'Отложенная новость удалена' : 'Выбранные отложенные новости удалены')
        } catch (error) {
            addError('При удалении отложенной новости произошла ошибка')
        } finally {
            setIsLoading(false);
        }
    }, [onCountChangeHandler, closeDialog, addSuccess, addError]);

    const onDeleteDelayedNews = useCallback((data) => {
        if (data?.length) {
            setCheckedCheckbox(data.map(d => d.original));
        }

        const text = data?.length ?
            data.map(item => {
                return {
                    ...item.original,
                    text: (
                        <>
                            <span onClick={() => navigate(`/user/delayed-news/${item.original.id}/delayed-news/edit`)} style={{ color: '#279BD9', cursor: 'pointer' }}>
                                {item?.original?.title}
                            </span>
                        </>
                    )
                }
            }) : (
                <span>
                    Вы действительно хотите удалить новость 
                    <span
                        style={{ color: '#279BD9', cursor: 'pointer' }}
                        onClick={() => navigate(`/user/delayed-news/${data.id}/delayed-news/edit`)}
                    >
                        {data?.title}
                    </span>
                </span>
            )
        const subTitle = data?.length ? 'Вы действительно хотите удалить нижеперечисленные объекты?' : null

        openDialog({
            title: 'Удаление отложенной новости',
            text,
            subTitle,
            contentType: data?.length ? 'CHECKBOX_LIST' : 'TEXT',
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Да, удалить',
            onChange: (news) => checkboxHandler(news),
            onSubmit: () => deleteDelayedNews(data),
            onClose: closeDialog,
        })
    }, [navigate, closeDialog, openDialog, deleteDelayedNews, checkboxHandler])

    const newsColumns = useMemo(() => [
        {
            Header: 'Содержание',
            accessor: 'title',
            width: 333,
            maxWidth: 333,
            Cell: (data) => {
                if (data.isMobile) {
                    return (
                        <div className={classNames(cx.titleBlock, cx.mobileTitleBlock)}
                             onClick={() => navigate(`/user/delayed-news/${data.row.original['id']}/delayed-news/edit`)}>
                            {(data.row.original['priority'] === 'HIGH' || !data.row.original['project']) &&
                            <div className={cx.titleBlockTags}>
                                {data.row.original['priority'] === 'HIGH' &&
                                <span className={cx.yellow}>Срочная новость</span>}
                                {!data.row.original['project'] && <span className={cx.blue}>Общая новость</span>}
                            </div>}
                            <p className={cx.title}>{data.row.original['title']}</p>
                            {data.row.original['project'] &&
                            <div className={cx.titleBlockProject}>
                                <div className={cx.titleBlockProjectAvatar}>
                                    {data.row.original['project']['logoUuid'] &&
                                    <img src={api.upload.getImage(data.row.original['project']['logoUuid'], false, 128)}
                                         alt="" />}
                                </div>
                                <span>{data.row.original['project']['title']}</span>
                            </div>}
                            <div className={classNames(cx.titleBlockLogo, cx.logoMobile)}>
                                {data.row.original['logoUuid'] &&
                                <img src={api.upload.getImage(data.row.original['logoUuid'], false, 512)} alt="" />}
                                {!data.row.original['logoUuid'] && <img src="/img/news-card-cover.jpg" alt="" />}
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <Link to={`/user/delayed-news/${data.row.original['id']}/delayed-news/edit`} className={cx.titleBlock}>
                            <div className={cx.titleBlockLogo}>
                                {data.row.original['logoUuid'] &&
                                <img src={api.upload.getImage(data.row.original['logoUuid'], false, 512)} alt="" />}
                                {!data.row.original['logoUuid'] && <img src="/img/news-card-cover.jpg" alt="" />}
                            </div>
                            <div>
                                {(data.row.original['priority'] === 'HIGH' || !data.row.original['project']) && (
                                    <div className={cx.titleBlockTags}>
                                        {data.row.original['priority'] === 'HIGH' &&
                                            <span className={cx.yellow}>Срочная новость</span>}
                                        {!data.row.original['project'] && <span className={cx.blue}>Общая новость</span>}
                                    </div>
                                )}
                                <MultiClumpTooltip
                                    clamp={2}
                                    className={cx.title}
                                    label={data.row.original['title']}
                                />
                                {data.row.original['project'] &&
                                <div className={cx.titleBlockProject}>
                                    <div className={cx.titleBlockProjectAvatar}>
                                        {data.row.original['project']['logoUuid'] &&
                                        <img src={api.upload.getImage(data.row.original['project']['logoUuid'], false, 128)} alt="" />}
                                    </div>
                                    <span>{data.row.original['project']['title']}</span>
                                </div>}
                            </div>
                        </Link>
                    );
                }
            }
        },
        {
            Header: 'Дата публикации',
            accessor: 'publicationTime',
            Cell: (data) => {
                return (
                    <div>
                        {data.isMobile && <div className={cx.subheader}>Дата публикации</div>}
                        <div>
                            {formatDateTime(data.row.original['publicationTime'], true)}
                        </div>
                    </div>
                )
            }
        },
        {
            id: 4,
            Header: '',
            width: 75,
            maxWidth: 75,
            settings: platform === 'mobile' ? ['no_td_wrap'] : [],
            Cell: function(data) {
                let toolbar = [];

                if (width > 1024) {
                    toolbar.push({
                        icon: Icons.EDIT_PEN,
                        tooltip: 'Редактировать',
                        link: `/user/delayed-news/${data.row.original.id}/delayed-news/edit`,
                        // isHidden: original => !(original?.permittedActions ?? []).includes(GLOBAL_ACTIONS.ARTICLE_EDIT)
                    });
                }

                toolbar.push({
                    icon: Icons.DELETE,
                    tooltip: 'Удалить отложенную новость',
                    onClick: (data) => onDeleteDelayedNews(data)
                });

                return (
                    <>
                        {EditRowToolbar(toolbar)(data)}
                        <Icon
                            className={cx.mobileDelete}
                            type={Icons.TRASH}
                            width={15}
                            height={15}
                            onClick={() => onDeleteDelayedNews(data.row.original)}
                        />
                    </>
                );
            }
        }
    ], [navigate, onDeleteDelayedNews, platform, width]);

    const newsActions = useMemo(() => {
        const actions = [];

        actions.push({
            icon: Icons.TRASH,
            label: 'Удалить',
            onClick: (data) => onDeleteDelayedNews(data)
        });

        return actions;
    }, [onDeleteDelayedNews]);

    useEffect(() => {
        checkedCheckboxRef.current = checkedCheckbox;
    }, [checkedCheckbox]);

    return (
        <div className={cx.tableWrapper}>
            <Confirmation {...dialogState} />

            {isEmpty && !isSearch && (
                <EmptyNews />
            )}

            {(!isEmpty || isSearch) &&
            <TableComponent
                innerRef={tableRef}
                isLoading={isLoading}
                searchTitle="Поиск по автору и названию"
                columns={newsColumns}
                actions={newsActions}
                total={newsTotal}
                data={news}
                onPaginate={requestNews}
                isMobile={platform === 'mobile'}
                defaultSort={{ by: 'createTime', desc: true }}
            />}
        </div>
    );
};

export default DelayedPublicationsTabNews;
