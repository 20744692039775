// import React from 'react';
// import { Range, Handle } from 'rc-slider';
// import style from './range-slider.module.scss';
import 'rc-slider/assets/index.css';

const RangeSlider = (_props) => {
    return null;
    // const handle = (data) => {
    //     return (
    //         <Handle {...data}>
    //             <div className={style.rangeSliderValue}>{data.value}</div>
    //         </Handle>
    //     );
    // };
    //
    // return (
    //     <Range railStyle={{ backgroundColor: '#E8E8E8' }}
    //            trackStyle={[{ backgroundColor: '#1280CE' }, { backgroundColor: '#1280CE' }]}
    //            handleStyle={[{
    //                border: '3px solid #1280CE',
    //                height: 18,
    //                width: 18,
    //                marginTop: '-7px',
    //                boxShadow: 'none'
    //            }, {
    //                border: '3px solid #1280CE',
    //                height: 18,
    //                width: 18,
    //                marginTop: '-7px',
    //                boxShadow: 'none'
    //            }]} handle={handle} {...props}/>
    // );
};

export default RangeSlider;
