import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { setDocumentTitle, useLocation, useNavigate, useParams } from 'shared/router';
import { applyNodeChanges, applyEdgeChanges, addEdge, getOutgoers, MarkerType } from 'react-flow-renderer';
import { v1 } from 'uuid';
import isEqual from 'lodash/isEqual';
import Icon, { Icons } from 'uikit/icon';
import Button from 'uikit/button';
import { useDialog, useMessage, useGlobalContext } from 'lib/hooks';
import { ContentWrapper } from 'containers/content-wrapper';
import BackButton from 'components/back-button';
import Confirmation from 'components/confirmation';
import ScriptInfo from './components/script-info';
import ActiveNodeSettings from './components/active-node-settings';
import ActiveLinkSettings from './components/active-link-settings';
import ScriptPreview from '../script-preview/script-preview';
import cs from './create-script.module.scss';
import api from 'api';
import ActiveConditionNodeSettings from './components/active-condition-node-settings';
import ScriptEditor, {formatEdges} from 'components/script-editor/script-editor';
import { textValidator } from "lib/util/validators.util";
import Crumbs from 'components/crumbs';
import { MultiClumpTooltip } from 'components/MultiClumpTooltip/MultiClumpTooltip';
import useAutoSave, { AutoSaveGroup } from '../../../../lib/hooks/useAutoSave';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../slice/authSlice';
import { NODE_TYPES } from '../../../../components/trees';
import TreeUtils from '../../../../lib/util/tree.util';
import { parse } from 'query-string';
import { IndexedDbKeyValueStorage } from '../../../../lib/storage';
import cx from '../../../../components/data-components/reports-component.module.scss';
import { EmptyScript, EmptyScriptDeleted } from 'components/projects/empty';
import { perm } from '../../../../model/resource';
import { GLOBAL_ACTIONS } from '../../../../model/auth/permissions';
import { useCheckGlobalPermission, useCheckResourcePermission } from 'shared/permissions';

const EDGE_TYPE_PREVIEW = 'preview';
const EDGE_TYPE_STRAIGHT = 'straight';
const EDGE_TYPE_FROM = 'link_from';
const EDGE_TYPE_TO = 'link_to';

const getPreviewEdge = (source, target, state = EDGE_TYPE_PREVIEW) =>  {
    return {
        id: v1(),
        source,
        target,
        sourceHandle: 'handle-bottom-right',
        targetHandle: 'handle-right-center',
        type: EDGE_TYPE_PREVIEW,
        style: {
            stroke: 'var(--color-blue)',
            strokeWidth: '1px',
            strokeDasharray: '4 4'
        },
        markerEnd: {
            type: MarkerType.ArrowClosed,
            color: '#1280ce',
            height: 12
        },
        state
    };
}
const setSameTargetLinkMarkers = (sameTargetEdges, markeredTargetEdges) => {
    let type = MarkerType.ArrowClosed;
    sameTargetEdges.forEach((item) => {
        if (!markeredTargetEdges[item.source]){
            markeredTargetEdges[item.source] = item.id;
            type = MarkerType.ArrowClosed;
        } else {
            type = null;
        }
    });

    return type;
}

const getSectionsPath = (rootSections, sectionId) => {
    const sectionsPath = [];
    let found = false;

    const recurse = (sections) => {
        for (let i = 0; i < sections.length; i++) {
            sectionsPath.push(sections[i].uuid);

            if ((sections[i].uuid === sectionId)) {
                found = true;
                break;
            } else {
                const subSections = sections[i].subSections ?? [];
                if (subSections?.length > 0) {
                    recurse(subSections);
                    if(found){
                        break;
                    }
                }
            }
            sectionsPath.pop();
        }
    }

    recurse(rootSections);

    return sectionsPath
};
const getSectionsVisualPath = (sections, sectionsPath) => {
    const result = [];
    let level = 0;

    const getSectionPath = (sections, pathItem) => {
        const sectionNode = sections.find(({ uuid }) => uuid === pathItem);
        if (sectionNode) {
            result.push(sectionNode.title);

            if (sectionNode.subSections) {
                level++;
                getSectionPath(sectionNode.subSections, sectionsPath[level]);
            }
        }
    };

    getSectionPath(sections, sectionsPath[level]);

    return result;
};

const CreateScript = () => {
    const conditionNodeRef = useRef(null);
    const [state, setState] = useState(null);

    const { uuid, type } = useParams();

    const location = useLocation();
    const navigate = useNavigate();

    const { formDataChanged, setFormDataChanged } = useGlobalContext();
    const { addError, addSuccess } = useMessage();

    const { dialogState, openDialog, closeDialog } = useDialog();
    const currentUser = useSelector(selectUser);

    const [initId, setInitId] = useState(null);
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const [scriptEditMode, setScriptEditMode] = useState(!uuid);
    const [showPreview, setShowPreview] = useState(false);
    const [previewElements, setPreviewElements] = useState([]);

    const [activeNode, setActiveNode] = useState(null);
    const [linkedNodeId, setLinkedNodeId] = useState(null);
    const [activeConditionNode, setActiveConditionNode] = useState(null);
    const [activeConditionNodeEdges, setActiveConditionNodeEdges] = useState(null);
    const [lastNodeId, setLastNodeId] = useState();
    const [stepCounter, setStepCounter] = useState(1);

    const [scriptInfo, setScriptInfo] = useState({
        scriptName: '',
        scriptDescription: '',
        scriptDestination: {
            parentProjectId: '',
            parentSectionId: '',
            visualPath: [],
        },
    });
    const [errors, setErrors] = useState({});
    const [isSubmitDisabled, setSubmitDisabled] = useState(false);
    const prevNodeRef = useRef(null);
    // Бэкап нод в редакторе скрипта - для отслеживания внесённых в ред-ре изменений
    const nodesBackupRef = useRef(null);

    const nodesRef = useRef(nodes);
    const getNodesRefCurrent = () => JSON.parse(JSON.stringify(nodesRef.current));
    const edgesRef = useRef(edges);
    const getEdgesRefCurrent = () => JSON.parse(JSON.stringify(edgesRef.current));
    const lastNodeIdRef = useRef(lastNodeId);

    const onAutoSave = useCallback(() => {
        if (!formDataChanged) {
            return null;
        }

        return JSON.stringify({
            nodes: nodes,
            edges: edges,
            scriptInfo: scriptInfo
        });
    }, [formDataChanged, nodes, edges, scriptInfo]);

    const storage = useMemo(() => {
        return new IndexedDbKeyValueStorage();
    }, []);
    const { autoSave, isAutoSave, setIsAutoSave, clearAutoSave } = useAutoSave(
        currentUser.login,
        AutoSaveGroup.SCRIPT,
        uuid,
        onAutoSave,
        storage
    );

    useCheckGlobalPermission(uuid, GLOBAL_ACTIONS.SCRIPT_CREATE);
    useCheckResourcePermission('script', type !== 'draft' ? uuid : undefined, perm.script.SCRIPT_EDIT);

    const onNodesChange = useCallback((changes) => {
        setFormDataChanged(!isEqual(nodesRef.current, nodesBackupRef.current));
        return setNodes((ns) => applyNodeChanges(changes, ns));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onEdgesChange = useCallback((changes) => setEdges((es) => {
        setFormDataChanged(true);
        return applyEdgeChanges(changes, es);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

    const onConnect = useCallback((connection) => {
        setFormDataChanged(true);
        return setEdges((eds) => addEdge(connection, eds));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Нажатие на узел условия
    const onConditionNodeClick = useCallback((nodeId, resetLinkedNodes) => {
        // Сброс связанных шагов
        if (resetLinkedNodes) {
            onActiveNodeOptionOut();
        }
        setScriptEditMode(false);
        setActiveConditionNode(nodesRef.current.find((item) => item.id === nodeId));
        setActiveNode(null);
    }, []);

    // Добавление шага
    const onAddStep = useCallback(({ id, position }) => {
        onActiveNodeOptionOut();
        setStepCounter((prevState) => prevState + 1);
        let newNodeId = v1();
        let conditionId = v1();
        let stepNumber = stepCounter + 1;
        let curPosition = position;
        const curNode = nodesRef.current.find(({ id: nodeId }) => nodeId === id);
        if (curNode) {
            curPosition = curNode.position
        }

        const { x, y } = curPosition;

        const newNode = {
            id: newNodeId.toString(),
            type: 'special',
            data: {
                text: 'Шаг ' + stepNumber,
                id: newNodeId.toString(),
                position: {
                    x,
                    y: y + curNode.height + 200,
                },
                targetStep: false,
                isLinked: false,
                isHovered: false
            },
            files: [],
            links: [],
            articles: [],
            images: [],
            position: {
                x,
                y: y + curNode.height + 200,
            },
            showLink: false,
            root: false,
        };

        const newCondition = {
            id: conditionId.toString(),
            type: 'neutral',
            data: {
                text: 'Новое условие',
                id: conditionId.toString(),
                position: {
                    x,
                    y: y + curNode.height + 90,
                },
            },
            position: {
                x,
                y: y + curNode.height + 90,
            },
            root: false,
        };

        const edgeFrom = {
            id: v1(),
            source: id,
            target: conditionId.toString(),
            sourceHandle: 'handle-bottom',
            type: 'straight',
            style: {
                stroke: 'var(--color-blue)',
                strokeWidth: '1px'
            },
            state: 'link'
        };

        const edgeTo = {
            id: v1(),
            source: conditionId.toString(),
            target: newNodeId.toString(),
            targetHandle: 'handle-center-top',
            type: 'straight',
            style: {
                stroke: 'var(--color-blue)',
                strokeWidth: '1px'
            },
            markerEnd: {
                type: MarkerType.ArrowClosed,
                color: '#1280ce',
                height: 20
            },
            state: 'link'
        };

        setLastNodeId(conditionId.toString());
        setNodes((els) => [...els, newCondition, newNode]);
        setEdges((prevState) => [...prevState, edgeFrom, edgeTo]);
        prevNodeRef.current = null;

        conditionNodeRef.current = newCondition;
        setFormDataChanged(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepCounter]);

    // Отслеживание добавления нового условия
    useEffect(() => {
        if (conditionNodeRef.current) {
            onConditionNodeClick(conditionNodeRef.current.id);
            setActiveConditionNode(conditionNodeRef.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conditionNodeRef.current]);

    // Флаг связанности узлов
    const isEdgeConnected = (edge, id) => edge.target === id || edge.source === id;

    // Получаем связанные узлы
    const getConnectedNodes = useCallback((id) => {
        let _nodes = getNodesRefCurrent();
        let _edges = getEdgesRefCurrent();
        const connectedEdges = _edges.filter((edge) => {
            return isEdgeConnected(edge, id);
        });
        const connectedNodes = connectedEdges.length ? _nodes.filter(({ id }) => {
            return !!connectedEdges.find((edge) => isEdgeConnected(edge, id))
        }) : [_nodes.find((node) => node.id === id)];

        return connectedNodes;
    }, [])

    // Отслеживание длины текста, вводимого в поля "Текст шага" и "Текст условия"
    const watchTextLength = useCallback(((textLength, limit = 1500) => {
        if (textLength > limit) {
            setErrors((prevState) => ({ ...prevState, textLength: 'Превышено максимальное количество символов' }));
            return;
        }
        if (textLength === 0) {
            setErrors((prevState) => ({ ...prevState, textLength: 'Поле обязательно для заполнения' }));
            return;
        }
        setErrors((prevState) => ({ ...prevState, textLength: null }));
    }), [setErrors]);

    // Отслеживание допустимых символов в тексте
    const watchTextCharacters = useCallback(((text, limit = 1500) => {
        if (textValidator(text, limit, true, false)) {
            setErrors((prevState) => ({ ...prevState, textCharacters: textValidator(text, limit, true, false) }));
            return;
        }
        setErrors((prevState) => ({ ...prevState, textCharacters: null }));
    }), [setErrors]);

    // Обработчик удаления шага/условия из панели справа
    const onRemoveHandler = (node) => {
        const { isStep } = node;
        let _nodes = getNodesRefCurrent();
        let _edges = getEdgesRefCurrent();
        const prevEdge = _edges.find((e) => e.target === node.id);
        const prevNode = _nodes.find((n) => n.id === prevEdge?.source);
        getConnectedNodes(node.id);

        if (prevNode) {
            prevNodeRef.current = prevNode;
        };

        openDialog({
            color: 'red',
            title: `Удалить ${isStep ? 'шаг' : 'условие'}`,
            text: isStep
                ? 'Вы уверены что хотите удалить данный шаг? Будут удалены также все условия идущие к нему и от него соответственно.'
                : 'Вы уверены что хотите удалить данное условие? Его связи с шагами будут также потеряны.',
            closeBtnText: 'Нет, закрыть',
            submitBtnText: 'Да, удалить',
            onSubmit: () => {
                onRemove(node);
                prevNodeRef.current = null;
            },
            onClose: () => {
                closeDialog();
                prevNodeRef.current = null;
            },
            contentType: 'TEXT'
        });
    };

    // Удаление узла шага/условия
    const onRemove = useCallback((node) => {
        let _nodes = getNodesRefCurrent();
        let _edges = getEdgesRefCurrent();
        const connectedNodes = getConnectedNodes(node.id);

        let nextNode = getOutgoers(node, _nodes, _edges);

        _nodes = _nodes.filter((n) => {
            if (node.isStep) {
                return !connectedNodes.find(( connectedNode ) => connectedNode.id === n.id)
            } else {
                return n.id !== node?.id
            }
        });

        _edges = _edges.filter((e) => {
            return e.source !== node?.id
        });
        _edges = _edges.filter((e) => {
            return e.target !== node?.id
        });

        while (true) {
            if (!nextNode.length) {
                break;
            }

            const nextNodeId = nextNode[0]?.id;

            // eslint-disable-next-line no-loop-func
            _nodes = _nodes.filter((n) => {
                if (node.isStep) {
                    return !connectedNodes.find(( connectedNode ) => connectedNode.id === n.id)
                } else {
                    return n.id !== node?.id
                }
            });
            const markeredTargetEdges = {};

            _edges = _edges
                .filter((e) => {
                    return e.source !== nextNodeId
                })
                // eslint-disable-next-line no-loop-func
                .map((edge) => {
                    // Выделение маркером связи только одного условия
                    const sameTargetEdges = _edges.filter(({ target, type }) => target === edge.target && type === edge.type);
                    let type = MarkerType.ArrowClosed;

                    type = setSameTargetLinkMarkers(sameTargetEdges, markeredTargetEdges);

                    if (edge.markerEnd) {
                        edge.markerEnd.height = 15;
                        edge.markerEnd.type = type;
                    }

                    return edge;
                });

            node = nextNode;
            nextNode = getOutgoers(node, _nodes, _edges);
        }

        setNodes(_nodes);
        setEdges(_edges);

        closeDialog();

        setActiveNode(null);
        setActiveConditionNode(null);
    }, [closeDialog, getConnectedNodes]);

    const findActiveNode = (nodeId) => nodesRef.current.find((n) => n.id === nodeId);

    // Нажатие на шаг (шаг становится активным)
    const onNodeClick = useCallback((nodeId) => {
        const _activeNode = findActiveNode(nodeId);

        _activeNode.showLink = false;
        setScriptEditMode(false);
        setActiveNode(JSON.parse(JSON.stringify(_activeNode)));
        setActiveConditionNode(null);
        // Сброс выбранного для соединения шага
        onActiveNodeOptionOut();
    }, []);

    // Нажатие на кнопку связывания шагов (иконка "ссылка")
    const onLinkClick = useCallback((nodeId) => {
        const _activeNode = findActiveNode(nodeId);

        _activeNode.showLink = true;
        setScriptEditMode(false);
        setActiveConditionNode(null);
        setActiveNode(JSON.parse(JSON.stringify(_activeNode)));
        // Сброс выбранного для соединения шага
        onActiveNodeOptionOut();
    }, [])

    const onNameChange = async (value) => {
        errors['scriptName'] = textValidator(value, 254, true, false);
        setScriptInfo(prevState => {
            const newState = Object.assign({}, prevState);
            newState.scriptName = value;
            return newState;
        });
    };
    const onDescriptionChange = (value) => {
        errors['scriptDescription'] = textValidator(value, 254, false, false);
        setScriptInfo(prevState => {
            const newState = Object.assign({}, prevState);
            newState.scriptDescription = value;
            return newState;
        });
    };
    const onDestinationChange = (node) => {
        if (!node) {
            setScriptInfo(prevState => {
                const newState = Object.assign({}, prevState);
                newState.scriptDestination = {
                    parentProjectId: '',
                    parentSectionId: '',
                    visualPath: [],
                    parentsIds: [],
                };
                return newState;
            });
        } else if (node.nodeType !== NODE_TYPES.SECTION) {
            addError('Для выбора доступны только разделы.');
        } else {
            let sectionId = node.id;
            let projectId;
            let parentsIds = [node.id];

            function traverseNode(_node) {
                if (_node.nodeType === NODE_TYPES.PROJECT) {
                    projectId = _node.id;
                    parentsIds.push(_node.id);
                } else {
                    parentsIds.push(_node.parent.id);
                    traverseNode(_node.parent);
                }
            }

            traverseNode(node);
            setErrors((prevState) => ({ ...prevState, path: null }));

            setScriptInfo(prevState => {
                const newState = Object.assign({}, prevState);
                newState.scriptDestination = {
                    parentProjectId: projectId,
                    parentSectionId: sectionId,
                    visualPath: TreeUtils.getVisualPathTo(node),
                    parentsIds: parentsIds.reverse()
                };
                return newState;
            });
        }
    };

    // Обработчик редактирования активного шага
    const onActiveNodeChange = (node) => {
        setActiveNode(node);

        setNodes((prevState) => {
            return prevState.map((n) => {
                if (n.id === node.id) {
                    return {
                        ...node,
                        position: n.position
                    };
                }

                return n;
            });
        });
    };

    // Обработчик изменения данных условия в панели справа
    const onActiveConditionNodeChange = (node) => {
        setActiveConditionNode(node);
        setNodes((prevState) => {
            return prevState.map((n) => {
                if (n.id === node.id) {
                    return {
                        ...node,
                        position: n.position
                    };
                }

                return n;
            });
        });

        let _edges = getEdgesRefCurrent();
        formatEdges(_edges, node, setEdges);
    };

    // Изменение значения поля "Условие ведет к шагу"
    const onActiveConditionNodeTargetChange = (e) => {
        const { value } = e;
        let _edges = getEdgesRefCurrent();
        const targetEdgeIndex = _edges.findIndex(( edge ) => {
            return edge.id === activeConditionNodeEdges[1].id
        });
        _edges[targetEdgeIndex].target = value;
        setEdges(_edges);

        setActiveConditionNodeEdges([
            activeConditionNodeEdges[0],
            {
                ...activeConditionNodeEdges[1],
                target: value
            }
        ]);
    }

    // Отправка скрипта (сохранение)
    const submitScript = async (type) => {
        setSubmitDisabled(true);
        setFormDataChanged(false);

        const _errors = await validateField(scriptInfo);
        setErrors(_errors);

        if (Object.values(_errors).every((error) => !error)) {
            const data = {
                scriptTitle: scriptInfo?.scriptName,
                description: scriptInfo?.scriptDescription,
                parentSectionId: scriptInfo?.scriptDestination.parentSectionId,
                elements: [
                    ...nodes.map((n) => {
                        if (n.files?.length) {
                            n.files = n.files.map((f) => {
                                return {
                                    fileId: f.serverData?.id || f.serverData.fileId,
                                    fileName: f.serverData.name,
                                    fileSize: f.serverData.size,
                                    fileType: f.serverData.type,
                                };
                            });
                        }

                        return n;
                    }),
                    ...edges,
                ],
            };

            try {
                if (type === 'public') {
                    if (uuid) {
                        await api.scripting.patchScript({ ...data, id: uuid });

                        navigate(`/scripting/script/${uuid}`);
                        addSuccess('Скрипт изменён');
                    } else {
                        const script = await api.scripting.postScript({ ...data, initId: initId });

                        navigate(`/scripting/script/${script['id']}`);
                        addSuccess('Скрипт создан');
                    }
                } else if (type === 'draft') {
                    if (uuid) {
                        await api.scriptingDraft.patchScript({ ...data, id: uuid });
                        addSuccess('Черновик скрипта изменён');
                    } else {
                        await api.scriptingDraft.postScript({ ...data, initId: initId });
                        addSuccess('Черновик скрипта создан');
                    }

                    navigate('/user/drafts/scripts');
                } else if (type === 'publish') {
                    const response = await api.scriptingDraft.saveAndPublishScript({ ...data, id: uuid });

                    navigate(`/scripting/script/${response.id}`);
                    addSuccess('Черновик скрипта опубликован');
                }
            } catch (error) {
                addError('Произошла ошибка');
            }
        } else {
            setScriptEditMode(true);
        }

        setSubmitDisabled(false);
    };

    // Валидация полей
    const validateField = useCallback(async (data) => {
        let errors = {};

        if (data?.scriptName) {
            errors['scriptName'] = textValidator(data.scriptName, 254, true, false);

            let isTitleUsed = await api.scripting.validateTitle(data?.scriptName.toLowerCase(), data?.scriptDestination.parentSectionId, uuid);

            if (!isTitleUsed) {
                errors['scriptName'] = 'Данное название уже используется';
            }
        }

        if (data?.scriptDescription) {
            errors['scriptDescription'] = textValidator(data.scriptDescription, 254, false, false);
        }

        if (data?.scriptDestination.parentSectionId) {
            errors['path'] = data?.scriptDestination.parentSectionId ? null : 'Поле не должно быть пустым';
        }

        return errors
    }, [uuid]);

    // Предпросмотр скрипта
    const onPreviewOpenHandler = useCallback(() => {
        const _nodes = nodesRef.current
            .filter((node) => node.type === 'special')
            .map((node) => {
                const nodeConditionIds = edgesRef.current.filter((edge) => edge.source === node.id).map((n) => n.target);
                const conditionNodes = [];

                nodeConditionIds.forEach((id) => {
                    const _node = nodesRef.current.find((n) => n.id === id);

                    if (_node) {
                        _node['targetId'] = edgesRef.current.find((edge) => edge.source === _node.id)?.target;
                        _node.selected = false;
                        conditionNodes.push(_node);
                    }
                });

                return {
                    ...node,
                    selected: false,
                    conditionNodes,
                };
            });

        setPreviewElements(JSON.parse(JSON.stringify(_nodes)));
        setShowPreview(true);
    }, []);

    // Кнопка "Назад" (в шапке слева)
    const goBack = () => {
        setFormDataChanged(false);

        if (type === 'draft') {
            navigate('/user/drafts/scripts', 'no-check-form-data-changed');
        } else {
            navigate(-1);
        }
    };

    // Инициализация скрипта
    const initScript = async () => {
        try {
            const res = await api.scripting.initScript();
            setInitId(res?.initializeId);
        } catch (error) {
            console.log(error);
        }
    };

    // Выбор шага для связывания (список "Выберите шаг")
    const onSelectLinkedStep = (activeNodeId, value) => {
        onActiveNodeOptionOut();

        setLinkedNodeId(value);
        const edge = getPreviewEdge(activeNodeId, value);

        setEdges((prevState) => [...prevState, edge]);

        const _nodes = getNodesRefCurrent();

        setNodes(_nodes.map(( node ) => {
            const item = node.type === 'special' ? {
                ...node,
                data: {
                    ...node.data,
                    isHovered: false,
                    isLinked: node.id === value
                }
            } : node;
            return item;
        }));
    }

    // Ховер над опциями "Выберите шаг"
    const onActiveNodeOptionHover = (activeNodeId, value) => {
        const _nodes = getNodesRefCurrent();

        setNodes(_nodes.map(( node ) => {
            const item = node.type === 'special' ? {
                ...node,
                data: {
                    ...node.data,
                    isLinked: false,
                    isHovered: node.id === value
                }
            } : node;
            return item;
        }));

        const edge = getPreviewEdge(activeNodeId, value);

        setEdges((prevState) => [...prevState.filter(( edge ) => edge.state !== EDGE_TYPE_PREVIEW), edge]);
    }

    // Удаление курсора с опции "Выберите шаг"
    const onActiveNodeOptionOut = () => {
        setLinkedNodeId(null);
        setNodes(JSON.parse(JSON.stringify(nodesRef.current)).map(( node ) => {
            const item = node.type === 'special' ? {
                ...node,
                data: {
                    ...node.data,
                    isHovered: false,
                    isLinked: false
                }
            } : node;
            return item;
        }));

        setEdges((prevState) => {
            return [...prevState.filter(( edge ) => edge.state !== EDGE_TYPE_PREVIEW)];
        })
    }

    // Возможные шаги для связывания с текущим активным шагом
    const getActiveNodeStepsOptions = () => {
        const stepNodes = nodes.filter((node) => node.type === 'special' && node.id !== activeNode.id);

        return stepNodes.map(( { data: { id, text } } ) => {
            return {
                label: text,
                value: id,
            }
        });
    }

    // Подтверждение связывания шагов
    const onSubmitStepsLinking = (source, target, data) => {
        onActiveNodeOptionOut();
        const { id, position } = data;
        let curPosition = position;
        const curNode = nodesRef.current.find(({ id: nodeId }) => nodeId === id);
        if (curNode) {
            curPosition = curNode.position
        }
        const { x, y } = curPosition;

        let conditionId = v1();

        const newCondition = {
            id: conditionId.toString(),
            type: 'neutral',
            data: {
                text: 'Новое условие',
                id: conditionId.toString(),
                position: {
                    x: x + 140,
                    y: y + 100,
                },
            },
            position: {
                x: x + 140,
                y: y + 100,
            },
            root: false,
        };

        const edgeFrom = {
            id: v1(),
            source,
            target: conditionId.toString(),
            sourceHandle: 'handle-bottom-right',
            type: EDGE_TYPE_FROM,
            style: {
                stroke: 'var(--color-blue)',
                strokeWidth: '1px'
            },
            state: 'link'
        };

        const edgeTo = {
            id: v1(),
            source: conditionId.toString(),
            target,
            targetHandle: 'handle-right-center',
            type: EDGE_TYPE_TO,
            style: {
                stroke: 'var(--color-blue)',
                strokeWidth: '1px'
            },
            markerEnd: {
                type: MarkerType.ArrowClosed,
                color: '#1280ce',
                height: 10
            },
            state: 'link'
        };

        setNodes((els) => [...els, newCondition]);
        setEdges((prevState) => [...prevState, edgeFrom, edgeTo]);

        conditionNodeRef.current = newCondition;
    }

    const getActiveNodeConditions = () => {
        const stepEdges = edges.filter(edge => edge.source === activeNode.id);
        const stepConditions = [];

        for (let i = 0; i < stepEdges.length; i++) {
            const condition = nodes.find(node => node.id === stepEdges[i].target);

            if (condition) {
                const edgeToNextStep = edges.find(edge => edge.source === condition.id);

                if (edgeToNextStep) {
                    const nextStep = nodes.find(node => node.id === edgeToNextStep.target);

                    if (nextStep) {
                        stepConditions.push({
                            condition,
                            nextStep
                        });
                    }
                }
            }
        }

        return stepConditions;
    };

    const collectCrumbs = () => {
        return [
            {
                linkTo: `/projects/${scriptInfo?.scriptDestination.parentProjectId}/articles`,
                name: scriptInfo?.scriptDestination.visualPath[0]
            },
            {
                linkTo: `/projects/${scriptInfo?.scriptDestination.parentProjectId}/${scriptInfo?.scriptDestination.parentSectionId}/section/articles`,
                name: scriptInfo?.scriptDestination.visualPath[scriptInfo?.scriptDestination.visualPath.length - 1]
            }
        ];
    };

    const onScriptComplete = async () => {
        if (!uuid) {
            return;
        }

        await api.scripting.complete(uuid);
    };

    useEffect(() => {
        nodesRef.current = nodes;
    }, [nodes]);
    useEffect(() => {
        edgesRef.current = edges;
    }, [edges]);
    useEffect(() => {
        lastNodeIdRef.current = lastNodeId;
    }, [lastNodeId]);

    // Изменения активного условия
    useEffect(() => {
        if (activeConditionNode) {
            const { id: activeConditionNodeId } = activeConditionNode;
            let _edges = getEdgesRefCurrent();
            const markeredTargetEdges = {};

            const newEdges = _edges.map((edge) => {
                if (edge.style) {
                    edge.style.strokeWidth = isEdgeConnected(edge, activeConditionNodeId) ? '2px': '1px'
                }
                let type = MarkerType.ArrowClosed;
                const sameTargetEdges = _edges.filter(({ target, type }) => target === edge.target && type === edge.type);
                // Если условий больше 1
                if (sameTargetEdges.length > 1){
                    // Выделение маркером связи активного условия
                    if (sameTargetEdges.find(({ source }) => source === activeConditionNodeId)) {
                        if(edge.source !== activeConditionNodeId) {
                            type = null;
                        } else {
                            type = MarkerType.ArrowClosed;
                        }
                    } else {
                        // Условия для одного шага все неактивны
                        // Выделение маркером связи только одного условия
                        type = setSameTargetLinkMarkers(sameTargetEdges, markeredTargetEdges);
                    }
                }

                if (edge.markerEnd) {
                    edge.markerEnd.height = 15;
                    edge.markerEnd.type = type;
                }

                return edge;
            });

            setEdges(newEdges);

            const connectedEdges = _edges.filter((edge) => {
                return isEdgeConnected(edge, activeConditionNodeId);
            });

            setActiveConditionNodeEdges(connectedEdges);
        }
    }, [activeConditionNode]);
    useEffect(() => {
        if (activeNode) {
            const markeredTargetEdges = {};
            const edgesRefCurrent = getEdgesRefCurrent();
            const _edges = edgesRefCurrent.map((edge) => {
                // Выделение маркером связи только одного условия
                const sameTargetEdges = edgesRefCurrent.filter(({ target, type }) => target === edge.target && type === edge.type);
                let type = MarkerType.ArrowClosed;

                type = setSameTargetLinkMarkers(sameTargetEdges, markeredTargetEdges);

                if (edge.style) {
                    edge.style.strokeWidth = '1px'
                }
                if (edge.markerEnd) {
                    edge.markerEnd.height = 12;
                    edge.markerEnd.type = type;
                }

                return edge;
            });

            setEdges(_edges);
        }
    }, [activeNode]);

    // Загрузка скрипта
    useEffect(() => {
        const fetchScript = async () => {
            const res = type === 'draft'
                ? await api.scriptingDraft.getScriptById(uuid) : await api.scripting.getPublicScript(uuid);

            const script = type === 'draft' ? res.scriptDraft : res.script;

            const _nodes = [];
            const _edges = [];

            script.elements.forEach((el) => {
                const elementType = el.type.toLowerCase();

                if ([EDGE_TYPE_STRAIGHT, EDGE_TYPE_FROM, EDGE_TYPE_TO].includes(elementType)) {
                    _edges.push({
                        ...el,
                        type: elementType,
                    });
                } else {
                    _nodes.push({
                        ...el,
                        files: el.files.map((f) => {
                            return {
                                status: 'done',
                                fileId: f.fileId,
                                serverData: {
                                    id: f.fileId,
                                    name: f.fileName,
                                    size: f.fileSize,
                                    type: f.fileType,
                                },
                            };
                        }),
                        type: elementType,
                    });
                }
            });

            _nodes.forEach(node => formatEdges(_edges, node, setEdges));
            setNodes(_nodes);

            nodesBackupRef.current = _nodes;
            setFormDataChanged(false);

            setEdges((prevState) => {
                return [...prevState.filter(( edge ) => edge.state !== EDGE_TYPE_PREVIEW)];
            });

            setScriptInfo({
                scriptName: script.name,
                scriptDescription: script.description,
                scriptDestination: {
                    parentProjectId: res.parents.projectId,
                    parentSectionId: res.parents.sections[res.parents.sections.length - 1].uuid,
                    visualPath: [res.parents.projectTitle].concat(res.parents.sections.map((section) => section.title)),
                },
            });

            setInitId(script?.initId);
            setState(res?.state || 'DRAFT');
        };

        if (uuid) {
            fetchScript()
                .catch((error) => {
                    if (error.errorData?.message === 'error.resource.deleted') {
                        setState('DELETED');
                        return;
                    }

                    setState('ERROR');
                });
        } else {
            initScript().then(() => {
                const uuid = v1();
                setLastNodeId(uuid);

                const nodesInit = [
                    {
                        id: uuid,
                        type: 'special',
                        data: { text: 'Шаг 1', id: uuid, position: { x: 250, y: 5 }, targetStep: false },
                        files: [],
                        links: [],
                        articles: [],
                        images: [],
                        position: { x: 250, y: 5 },
                        root: true,
                    },
                ];

                setNodes(nodesInit);
                nodesBackupRef.current = nodesInit;

                setState('NEW');
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid, type]);
    useEffect(() => {
        const requiredFieldsNotFilled = !scriptInfo.scriptName || !scriptInfo.scriptDestination.parentSectionId;
        let nodesHasError = false;

        for (let i = 0; i < nodes.length; i++) {
            //определяем это шаг или условие
            if (nodes[i].articles) {
                if (nodes[i].data.text.trim().length > 1500
                    || nodes[i].data.text.trim().length === 0
                    // || nodes[i].data.text.match(new RegExp(/[^а-яА-ЯёЁa-zA-Z0-9…., \-—"'()«»!@#№$%^&*_+=?;:|/\\\n\r]/gi))
                ) {
                    nodesHasError = true;
                    break;
                }
            } else {
                if (nodes[i].data.text.trim().length > 254
                    || nodes[i].data.text.trim().length === 0
                    // || nodes[i].data.text.match(new RegExp(/[^а-яА-ЯёЁa-zA-Z0-9…., \-—"'()«»!@#№$%^&*_+=?;:|/\\\n\r]/gi))
                ) {
                    nodesHasError = true;
                    break;
                }
            }
        }

        setSubmitDisabled(requiredFieldsNotFilled || nodesHasError)
    }, [nodes, scriptInfo.scriptName, scriptInfo.scriptDestination.parentSectionId]);

    useEffect(() => {
        if (!autoSave || !isAutoSave) {
            return;
        }

        openDialog({
            title: 'У вас есть несохраненная версия скрипта.',
            subTitle: 'Хотите продолжить работу с несохраненной версией скрипта?',
            color: 'green',
            closeBtnText: 'Нет, закрыть',
            submitBtnText: 'Продолжить работу',
            onSubmit: () => {
                setIsAutoSave(false);
                const data = JSON.parse(autoSave);

                setNodes(data.nodes);
                setEdges(data.edges);
                setScriptInfo(data.scriptInfo);

                closeDialog();
            },
            onClose: () => {
                setIsAutoSave(false);

                clearAutoSave();
                closeDialog();
            }
        });
    }, [isAutoSave, setIsAutoSave, uuid, autoSave, clearAutoSave, closeDialog, openDialog]);
    useEffect(() => {
        const { projectId, sectionId } = parse(location.search);

        if (projectId && !scriptInfo.scriptDestination.visualPath.length) {
            const treeNodes = api.project.getUsersDocumentTree();

            treeNodes.then((response) => {
                const projectNode = response.find(({ uuid }) => uuid === projectId);

                if (projectNode) {
                    setScriptInfo(prevState => {
                        const newState = Object.assign({}, prevState);
                        newState.scriptDestination = {
                            ...newState.scriptDestination,
                            parentProjectId: projectId,
                            visualPath: [
                                projectNode.title
                            ]
                        };
                        return newState;
                    });

                    if (sectionId) {
                        const sectionsPath = getSectionsPath(projectNode.rootSections, sectionId);
                        const parentsIds = [projectId];

                        let visualPath = [projectNode.title];

                        sectionsPath.forEach((sectionsPathItem) => {
                            parentsIds.push(sectionsPathItem);
                        });

                        visualPath = visualPath.concat(getSectionsVisualPath(projectNode.rootSections, sectionsPath, visualPath));

                        setScriptInfo(prevState => {
                            const newState = Object.assign({}, prevState);
                            newState.scriptDestination = {
                                parentProjectId: projectId,
                                parentSectionId: sectionId,
                                parentsIds,
                                visualPath
                            };
                            return newState;
                        });
                    }
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!uuid) {
            setDocumentTitle('Добавление скрипта — KMS Gran');
        } else if (scriptInfo?.scriptName) {
            setDocumentTitle(`${scriptInfo?.scriptName} — KMS Gran`);
        }
    }, [scriptEditMode, scriptInfo, uuid]);

    if (!state) {
        return null;
    }

    if (state === 'DELETED') {
        return (
            <div className={cx.empty}>
                <EmptyScriptDeleted />
            </div>
        );
    }

    if (state === 'ERROR') {
        return (
            <div className={cx.empty}>
                <EmptyScript />
            </div>
        );
    }

    return (
        <>
            <Confirmation {...dialogState} />
            <ContentWrapper>
                <div className={cs.createContainer}>
                    <div className={cs.pageContainer}>
                        <div className={cs.constructorContainer}>
                            <div className={cs.headingContainer}>
                                <div className={cs.infoContainer}>
                                    <div className={cs.navigation}>
                                        <BackButton onClick={goBack} id="createScript"/>
                                    </div>
                                    <div className={cs.info}>
                                        {scriptInfo?.scriptName && (
                                            <MultiClumpTooltip clamp={1} className={cs.title} label={scriptInfo?.scriptName} id="createScriptTitle" />
                                        )}
                                        {!scriptInfo?.scriptName && (
                                            <p className={cs.titleEmpty} data-testid="createScriptTitle">Название не указано</p>
                                        )}
                                        {scriptInfo?.scriptDestination?.visualPath.length === 0 && (
                                            <p className={cs.destinationEmpty} data-testid="createScriptCrumbs">Местоположение не указано</p>
                                        )}
                                        {scriptInfo?.scriptDestination?.visualPath.length !== 0 && (
                                            <p className={cs.destination}>
                                                <Crumbs data={collectCrumbs()} id="createScriptCrumbs" />
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div onClick={() => setScriptEditMode(true)} className={cs.editContainer} data-testid="createScriptEditInfoBtn">
                                    <Icon color="blue" width={14} height={14} type={Icons.EDIT_PEN} />
                                    <span>Редактировать информацию</span>
                                </div>
                            </div>
                            <ScriptEditor
                                nodes={nodes}
                                edges={edges}
                                onNodeClick={onNodeClick}
                                onConnect={onConnect}
                                onConditionNodeClick={onConditionNodeClick}
                                onAddStep={onAddStep}
                                onNodesChange={onNodesChange}
                                onEdgesChange={onEdgesChange}
                                activeNodeId={activeNode?.id ?? activeConditionNode?.id}
                                onLinkClick={onLinkClick}
                            />
                            <div className={cs.generalControlButtonsContainer}>
                                <Button
                                    label="Предпросмотр"
                                    onClick={onPreviewOpenHandler}
                                    data-testid="createScriptPreviewBtn"
                                />
                                {(!uuid || (uuid && type === 'draft')) && (
                                    <Button
                                        label={uuid ? "Сохранить и оставить в черновик" : "Сохранить черновик"}
                                        onClick={() => submitScript('draft')}
                                        disabled={isSubmitDisabled}
                                        data-testid="createScriptDraftBtn"
                                    />
                                )}
                                {type !== 'draft' && (
                                    <Button
                                        label="Опубликовать"
                                        color="green"
                                        onClick={() => submitScript('public')}
                                        disabled={isSubmitDisabled}
                                        data-testid="createScriptPublicBtn"
                                    />
                                )}
                                {type === 'draft' && (
                                    <Button
                                        label="Сохранить и опубликовать"
                                        color="green"
                                        onClick={() => submitScript('publish')}
                                        disabled={isSubmitDisabled}
                                        data-testid="createScriptSaveBtn"
                                    />
                                )}
                            </div>
                        </div>
                        <div className={cs.uiContainer}>
                            {!scriptEditMode && !activeNode && !activeConditionNode && (
                                <div className={cs.uiContainerEmpty}>Выберите шаг или условие</div>
                            )}
                            {!scriptEditMode && activeNode && !activeNode.showLink && (
                                <ActiveNodeSettings
                                    errors={errors}
                                    activeNode={activeNode}
                                    initId={initId}
                                    onChange={onActiveNodeChange}
                                    onRemove={onRemoveHandler}
                                    onAddStep={onAddStep}
                                    watchTextCharacters={watchTextCharacters}
                                    conditions={getActiveNodeConditions()}
                                    editConditionNode={onConditionNodeClick}
                                />
                            )}
                            {!scriptEditMode && activeNode && activeNode.showLink && (
                                <ActiveLinkSettings
                                    activeNode={{ id: activeNode.id, data: activeNode.data }}
                                    stepsOptions={getActiveNodeStepsOptions()}
                                    onChange={onSelectLinkedStep}
                                    onOptionHover={onActiveNodeOptionHover}
                                    onOptionOut={onActiveNodeOptionOut}
                                    onCancel={onActiveNodeOptionOut}
                                    linkedNodeId={linkedNodeId}
                                    onSubmit={onSubmitStepsLinking}
                                />
                            )}
                            {!scriptEditMode && activeConditionNode && (
                                <ActiveConditionNodeSettings
                                    activeConditionNode={activeConditionNode}
                                    onChange={onActiveConditionNodeChange}
                                    onChangeTarget={onActiveConditionNodeTargetChange}
                                    onRemove={onRemoveHandler}
                                    errors={errors}
                                    watchTextLength={watchTextLength}
                                    watchTextCharacters={watchTextCharacters}
                                    stepsOptions={nodes.filter(({ type, id: nodeId }) => {
                                        return type === 'special' && nodeId !== activeConditionNodeEdges?.[0]?.source
                                    }).map(( { data: { id, text } } ) => {
                                        return {
                                            label: text,
                                            value: id,
                                        }
                                    })}
                                    conditionTarget={activeConditionNodeEdges?.[1].target}
                                />
                            )}
                            {scriptEditMode && (
                                <ScriptInfo
                                    data={scriptInfo}
                                    errors={errors}
                                    setErrors={setErrors}
                                    onNameChange={onNameChange}
                                    onDescriptionChange={onDescriptionChange}
                                    onDestinationChange={onDestinationChange}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </ContentWrapper>
            <ScriptPreview
                show={showPreview}
                elements={previewElements}
                title={scriptInfo?.scriptName}
                description={scriptInfo?.scriptDescription}
                onDismiss={() => setShowPreview(false)}
                onPublish={() => submitScript('public')}
                onEditDraft={uuid && type === 'draft' ? () => submitScript('draft') : null}
                onScriptComplete={onScriptComplete}
            />
        </>
    );
};

export default CreateScript;
