import React, { useEffect, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useCombinedRefs, useDebounceValue } from 'lib/hooks';
import Tree from '../infinite-tree/tree';
import { useAutoNavigate } from 'components/trees/utils';
import cs from './statistics-tree.module.scss';
import { tree } from './statistics-tree-data';
import { cloneDeep } from 'lodash';
import TreeUtils from 'lib/util/tree.util';

const StatisticsTree = ({ forwardedRef, scrollToNode, onNavigate, getNodeLink, ...props }) => {
    const treeRef = useCombinedRefs(forwardedRef);
    // const { tree } = useStatisticsTree(true, true, true, props.archive);

    const [debouncedValue] = useDebounceValue('', 300);
    const clonedTreeRef = useRef(cloneDeep(tree));
    const [idsToOpen, setIdsToOpen] = useState([]);

    useAutoNavigate(idsToOpen, treeRef);

    // traverse tree and consistently find nodes which are path to the 'scrollToNode'
    useEffect(() => {
        let handle;

        function findIdsToOpen() {
            if (handle > 0) {
                clearTimeout(handle);
            }

            if (!treeRef.current?.tree) {
                handle = setTimeout(findIdsToOpen, 500);
            } else {
                if (!scrollToNode) {
                    return;
                }

                const tree_ = treeRef.current?.tree;
                const target = tree_.getNodeById(scrollToNode?.[1]);

                if (target) {
                    const path = TreeUtils.getPathIdsTo(target);

                    setIdsToOpen(path); // <------
                } else {
                    // so as nodes were not have loaded yet
                    handle = setTimeout(findIdsToOpen, 500);
                }
            }
        }

        findIdsToOpen();

        return () => {
            if (handle) {
                clearTimeout(handle);
            }
        };
    }, [scrollToNode, treeRef]);

    useEffect(() => {
        if (treeRef?.current?.tree) {
            const { tree } = treeRef.current;
            tree.filter(debouncedValue.trim());
        }
    }, [debouncedValue, treeRef]);

    return (
        <div id={'tree-container'} className={cs.tree}>
            <div className={cs.sizerContainer}>
                <AutoSizer>
                    {({ height }) => {
                        return (
                            <Tree
                                height={height}
                                data={clonedTreeRef.current}
                                onUpdate={onNavigate}
                                autoOpen={false}
                                ref={treeRef}
                                wordBreak
                                getNodeLink={getNodeLink}
                                onCheckedChange={() => {}}
                                statistics
                                hideIcons
                                {...props}
                            />
                        );
                    }}
                </AutoSizer>
            </div>
        </div>
    );
};

export default React.forwardRef((props, ref) => <StatisticsTree {...props} forwardedRef={ref} />);
