import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { selectUsers } from 'slice/authSlice';
import Modal from 'react-modal';
import { useAuthorized, useCancelBehavior, useDialog, useGlobalContext, useMessage } from 'lib/hooks';
import { descValidator, textValidator } from 'lib/util/validators.util';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import { ContentWrapper } from 'containers/content-wrapper';
import Input from 'uikit/input';
import Button from 'uikit/button';
import Divider from 'uikit/divider';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import Checkbox from 'uikit/checkbox/checkbox';
import Loading from 'uikit/loading';
import BackButton from 'components/back-button';
import Confirmation from 'components/confirmation';
import Card from 'components/card';
import api from 'api';
import cx from './edit-group-page.module.scss';
import { Link, setDocumentTitle, useNavigate, useParams } from 'shared/router';
import { desktopStyles, mobileStyles } from 'lib/util/modalStyles';
import ButtonMenu from 'uikit/button-menu';
import { MultiClumpTooltip } from 'components/MultiClumpTooltip/MultiClumpTooltip';
import classNames from 'classnames';
import { EmptyGroup } from 'components/projects/empty';

/*const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: 650,
        width: 570,
        zIndex: 8000,
        padding: 0,
        background: '#FFFFFF',
        border: '1px solid #EAEDF3',
        boxSizing: 'border-box',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
        borderRadius: 5
    }
};*/

const EditGroupPage = ({ mode }) => {
    const navigate = useNavigate();
    const { groupId } = useParams();

    const { platform, setFormDataChanged } = useGlobalContext();
    const selector = useSelector(state => state);
    const groupRef = useRef(null)

    const [loading, setLoading] = useState(false);
    const [isAddToGroupModalOpen, setIsAddToGroupModalOpen] = useState(false);
    const [isRemoveGroupModalOpen, setIsRemoveGroupModalOpen] = useState(false);

    const { isAuthorizedAction } = useAuthorized();
    const { cancel } = useCancelBehavior();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const [users, setUsers] = useState([]);
    const [filtersUsers, setFiltersUsers] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);

    const [group, setGroup] = useState();
    const [groupMembers, setGroupMembers] = useState([]);

    const [nameError, setNameError] = useState(null);
    const [descError, setDescError] = useState(null);
    const [usersError, setUsersError] = useState(null);

    const { addSuccess } = useMessage();

    const selectUser = (e, user) => {
        const tmp = Object.assign([], groupMembers);
        const index = groupMembers.findIndex(p => p['id'] === user['id']);

        if (index !== -1) {
            tmp.splice(index, 1);
        } else {
            tmp.push(user);
        }

        setGroupMembers(tmp);
    };

    const isCheckedUser = (user) => {
        return groupMembers.findIndex(p => p['id'] === user['id']) !== -1;
    };

    const openAddToGroupModal = () => {
        setGroupMembers(group['members']);
        setIsAddToGroupModalOpen(true);
    };
    const closeAddToGroupModal = () => {
        setIsAddToGroupModalOpen(false);
        onUsersSearch({ target: { value: '' } });
    };

    const saveUsers = () => {
        const tmp = Object.assign({}, group);
        tmp['members'] = groupMembers;
        setGroup(tmp);

        onUsersSearch({ target: { value: '' } });
        setIsAddToGroupModalOpen(false);
    };

    const removeUser = (user) => {
        const tmp = Object.assign({}, group);
        tmp['members'] = tmp['members'].filter(item => item !== user);
        setGroup(tmp);
    };

    const onUsersSearch = (e) => {
        const searchResult = [];
        const { target: { value } } = e;
        const valueNames = value.split(" ").filter(item => item).map((val) => val.toLowerCase());

        users.map((user) => {
            if (!value) {
                setFiltersUsers(users);
            };

            const userNames = [user['firstName']?.toLowerCase()];
            if (user['lastName']){
                userNames.push(user['lastName']?.toLowerCase())
            };

            if (
                (valueNames.length > 1
                    // todo: в будущем более гибко переписать на какой-то цикл
                    ? ((valueNames[0] === userNames[0] && valueNames[1] === userNames[1])
                        || (valueNames[0] === userNames[1] && valueNames[1] === userNames[0]))
                    : valueNames.some((name) => userNames.includes(name)))
                    || (user['lastName'] && user['lastName'].toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1)
                    || (user['email'] && user['email'].toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1)
            ) {
                searchResult.push(user);
            }

            return user;
        });

        setFiltersUsers(searchResult);
    };

    const onGroupRemove = () => {
        openDialog({
            color: 'red',
            title: 'Удаление',
            text: 'Вы подтверждаете удаление?',
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Да, удалить',
            onSubmit: removeGroup,
            onClose: closeDialog,
        })
    }

    const removeGroup = async () => {
        setFormDataChanged(false);
        await api.groups.deleteGroup(group['id']);
        closeDialog();
        navigate('/users/groups-page/all');
    };

    const editGroup = async () => {
        setFormDataChanged(false);
        let isErr = false;

        if (nameError || descError || usersError) {
            isErr = true;
        }

        if (name.length === 0 || description.length === 0) {
            onNameChange(name);
            onDescChange(description);
            isErr = true;
        }

        let members = [];

        for (let i = 0; i < group.members.length; i++) {
            members.push(group.members[i]['login']);
        }

        if (Array.isArray(members) && members.length <= 0) {
            setUsersError('Необходимо добавить участников группы');
            isErr = true;
        }

        if (isErr) {
            return;
        }

        setLoading(true);

        await api.groups.editGroup({ id: group['id'], name, description, members });
        setIsEditMode(false);

        setLoading(false);
        navigate(`/edit-group/${groupId}`);

        addSuccess('Группа изменена');
    };

    const onNameChange = (str) => {
        setNameError(null);
        setName(str);

        const err = textValidator(str);

        if (err !== '') {
            setNameError(err);
        }
    };

    const onDescChange = (str) => {
        setDescError(null);
        setDescription(str);

        const err = descValidator(str);

        if (err !== '') {
            setDescError(err);
        }
    };

    useEffect(() => {
        if (mode === 'edit') {
            setIsEditMode(true);
        }

        const getUsers = async () => {
            try {
                let tmpUsers = await selectUsers(selector);

                setUsers(tmpUsers['content']);
                setFiltersUsers(tmpUsers['content']);

                let tmpGroup = await api.groups.getGroupById(groupId);
                setGroup(tmpGroup);
                const { name, description, members } = tmpGroup;

                setName(name);
                setDescription(description);

                groupRef.current = {
                    name,
                    description,
                    members
                }
            } catch {
                setGroup(null);
            }
        };

        if (users.length === 0) {
            getUsers();
        }
    }, [group, groupId, users.length, mode, selector, groupMembers]);

    useEffect(() => {
        // console.log(group);
        if (group && group.members.length > 0) {
            setUsersError(null);
        }
    }, [group]);

    useEffect(() => {
        if (mode === 'edit' && !isAuthorizedAction([GLOBAL_ACTIONS.GROUP_EDIT])) {
            navigate('/401');
        }
    }, [mode, isAuthorizedAction, navigate]);

    useEffect(() => {
        if (groupRef.current) {
          const { name: refName, description: refDescription, members: refMembers } = groupRef.current;

          setFormDataChanged(refName !== name || refDescription !== description || !isEqual(group.members.map(({ id }) => id), refMembers.map(({ id }) => id)));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, description, group]);

    useEffect(() => {
        setDocumentTitle('Участники — KMS Gran');
    }, []);

    if (!loading && group === null) {
        return (
            <EmptyGroup />
        );
    }

    return (
        <ContentWrapper>
            <Confirmation {...dialogState} />
            {loading && (
                <Loading withOverlay={false} withRelativeOverlay={true}/>
            )}
            <Modal isOpen={isAddToGroupModalOpen} contentLabel={'Добавление в группу'} style={platform === 'mobile' ? mobileStyles : desktopStyles}>
                <div className={classNames(cx.modalContent, { [cx.modalMobile]: platform === 'mobile' })}>
                    <div className={cx.title}>
                        <div>Добавление в группу</div>
                        <IconButton icon={<Icon width={14} height={14} type={Icons.CROSS} />}
                                    onClick={() => closeAddToGroupModal()} />
                    </div>
                    <div className={cx.searchContainer}>
                        <div className={cx.search}>
                            <div className={cx.searchButton} onClick={() => {
                            }}>
                                <IconButton icon={<Icon type={Icons.SEARCH} width={13} height={13} />} />
                            </div>
                            <input type='text' placeholder={'Поиск по имени...'}
                                   onChange={(e) => onUsersSearch(e)} />
                        </div>
                    </div>
                    <Divider style={{
                        marginLeft: '-24px',
                        marginRight: '-24px',
                        marginTop: '1rem',
                        marginBottom: '1rem'
                    }} />
                    <div className={cx.container}>
                        {filtersUsers.map((item, i) => (
                            <div
                                key={i}
                                className={classNames(cx.userContainer, {
                                    [cx.userContainerChecked]: isCheckedUser(item)
                                })}
                                onClick={(e) => selectUser(e, item)}
                            >
                                <Checkbox onClick={(e) => selectUser(e, item)} checked={isCheckedUser(item)} />
                                <div className={cx.userInfo}>
                                    <img
                                        src={item['avatarUuid'] ? api.upload.getImage(item['avatarUuid'], false, 128) : '/img/avatar.png'}
                                        alt='' />
                                    <p>{item['firstName']} {item['lastName']}</p>
                                </div>
                            </div>))}
                    </div>
                    <Divider
                        style={{ marginLeft: '-24px', marginRight: '-24px', marginTop: '1rem', marginBottom: '0' }} />
                    <div className={cx.buttons}>
                        <Button
                            label="Отмена"
                            fullWidth={platform === 'mobile'}
                            onClick={() => closeAddToGroupModal()}
                        />
                        <Button
                            color="green"
                            label="Отправить"
                            fullWidth={platform === 'mobile'}
                            onClick={() => saveUsers()}
                        />
                    </div>
                </div>
            </Modal>
            <Modal isOpen={isRemoveGroupModalOpen} contentLabel={'Удаление группы'} style={platform === 'mobile' ? mobileStyles : desktopStyles}>
                <div className={cx.modalContent}>
                    <div className={cx.title}>
                        <div>Удаление группы</div>
                        <IconButton icon={<Icon width={14} height={14} type={Icons.CROSS}/>}
                                    onClick={() => closeAddToGroupModal()}/>
                    </div>
                    <Divider className={cx.divider}/>
                    <div className={cx.container}>
                        Удаление всех участников приведет к удалению группы. Продолжить?
                    </div>
                    <Divider className={cx.divider}/>
                    <div className={cx.buttons}>
                        <Button label={'Отмена'} onClick={() => {
                            cancel()
                            setIsRemoveGroupModalOpen(false)
                        }}/>
                        <Button color={'green'} label={'Продолжить'} onClick={() => removeGroup()}/>
                    </div>
                </div>
            </Modal>
            <div className={cx.content}>
                {!isEditMode && <div className={cx.headCard}>
                    <div className={cx.headContainer}>
                        <BackButton link={'/users/groups-page/all'}/>
                        <div>
                            <MultiClumpTooltip clamp={2} className={cx.name} label={group?.name} />
                        </div>
                    </div>
                    <div className={cx.buttonHeadContainer}>
                        {platform === 'mobile' &&
                        <ButtonMenu
                            items={[
                                {
                                    icon: Icons.EDIT_PEN,
                                    title: 'Редактировать',
                                    onClick: () => navigate('edit'),
                                    isShow: isAuthorizedAction([GLOBAL_ACTIONS.GROUP_EDIT])
                                },
                                {
                                    icon: Icons.DELETE,
                                    title: 'Удалить',
                                    onClick: onGroupRemove,
                                    isShow: isAuthorizedAction([GLOBAL_ACTIONS.GROUP_DELETE])
                                }
                            ]}
                        />}
                        {platform !== 'mobile' &&
                        <>
                            {isAuthorizedAction([GLOBAL_ACTIONS.GROUP_EDIT]) &&
                            <Link to={'edit'} className={cx.leftIcon}>
                                <IconButton
                                    icon={<Icon type={Icons.EDIT_PEN} tooltip={'Редактировать'} width={16} height={16}/>}/>
                            </Link>}
                            {isAuthorizedAction([GLOBAL_ACTIONS.GROUP_DELETE]) &&
                            <IconButton onClick={() => onGroupRemove()} hoverColor={'red'}
                                        icon={<Icon type={Icons.DELETE} tooltip={'Удалить'} width={16} height={16}/>}/>}
                        </>}
                    </div>
                </div>}
                {isEditMode && <div className={cx.pageTitle}>
                    Изменение группы участников
                </div>}
                {group != null && <Card className={cx.card}>
                    <Input type='text' value={name} onChange={(str) => onNameChange(str)} label='Название группы'
                           className={cx.field} error={nameError} disabled={!isEditMode}/>
                    <Input type='text' value={description} onChange={(str) => onDescChange(str)} label='Описание группы'
                           className={cx.field} disabled={!isEditMode} error={descError}/>
                    <Divider/>
                    <div className={cx.addProjectContainer}>
                        {isEditMode &&
                        <div onClick={openAddToGroupModal} className={cx.addButton}>
                            <Icon width={20} height={20} type={Icons.OPEND_LOCK}/>
                            <p>Добавить участника</p>
                        </div>}
                        {usersError && <div className={cx.errorText}>{usersError}</div>}
                        <div className={cx.projectsContainer}>
                            {group.members.map((item, i) => (<div key={i} className={cx.projectContainer}>
                                <Link to={'/edit-user/' + item['login']} className={cx.projectInfo}>
                                    {item['avatarUuid'] &&
                                    <img src={api.upload.getImage(item['avatarUuid'], false, 128)}alt="" />}
                                    {!item['avatarUuid'] &&
                                    <img src="/img/avatar.png" alt="" />}
                                    <p className={cx.prijectName}>{item['firstName']} {item['lastName']}</p>
                                </Link>
                                <div className={cx.projectButtons}>
                                    {isEditMode && <div className={cx.buttonsContainer}>
                                        <div className={cx.iconButton}>
                                            <IconButton icon={<Icon type={Icons.TRASH} tooltip={'Удалить'}/>}
                                                        onClick={() => {removeUser(item)}}
                                                        hoverColor={'red'}/>
                                        </div>
                                    </div>}
                                </div>
                            </div>))}
                        </div>
                    </div>
                    {isEditMode && <Divider className={cx.divider}/>}
                    {isEditMode && <div className={cx.tool}>
                        <Button label={'Отмена'} onClick={cancel}/>
                        <Button onClick={() => {
                            if (group.members.length === 0) {
                                setIsRemoveGroupModalOpen(true)
                            } else {
                                editGroup()
                            }
                        }} label='Сохранить' color={'green'}/>
                    </div>}
                </Card>}
            </div>
        </ContentWrapper>
    );
};

export default EditGroupPage;
