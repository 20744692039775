import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    NavLink,
    useLocation,
    Outlet,
    useNavigate,
    useParams
} from 'react-router-dom';
import { GetProviderProps, RenderLinkProps, RenderRouteGroupProps, RenderRouteProps, Router, Location } from './router';

export class ReactRouter extends Router {
    private navigateFunction: Function = () => {};
    private customNavigateFunction?: Function = undefined;

    private adaptChildren = (children?: React.ReactNode): React.ReactNode | undefined => {
        if (!children) {
            return undefined;
        }

        return React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) {
                return null;
            }

            const childProps = child.props as {
                to?: string;
                isDefault?: boolean;
                component: React.ReactNode;
                children?: React.ReactNode;
            };

            if (childProps.isDefault) {
                return (
                    <Route index={true} element={childProps.component} />
                );
            }

            return (
                <Route
                    path={childProps.to}
                    element={childProps.component}
                >
                    {this.adaptChildren(childProps.children)}
                </Route>
            );
        });
    };

    getProvider = ({ children }: GetProviderProps): React.ReactNode => {
        return <BrowserRouter>{children}</BrowserRouter>
    }

    getRouteGroup = ({ children, ...props }: RenderRouteGroupProps): React.ReactNode => {
        return (
            <div {...props}>
                <Routes>{this.adaptChildren(children)}</Routes>
            </div>
        );
    }

    getRoute = ({ component, children, to, isDefault }: RenderRouteProps): React.ReactNode => {
        return <Route path={to} element={component} index={isDefault}>{children}</Route>;
    }

    getOutlet = (): React.ReactNode => {
        return <Outlet />;
    }

    getParams = (): Function => {
        return useParams;
    }

    getLocation = (): Location => {
        const location = useLocation();
        return {
            origin: '',
            search: location.search,
            pathname: location.pathname
        };
    }

    getLink = ({ children, to, ...props }: RenderLinkProps): React.ReactNode => {
        return <NavLink to={to} {...props}>{children}</NavLink>;
    }

    getUseNavigate = (): Function => {
        return useNavigate;
    }

    getNavigateFunction = (): Function => {
        return this.navigateFunction;
    }

    setNavigateFunction = (func: Function): void => {
        this.navigateFunction = func;
    }

    getCustomNavigateFunction = (): Function | undefined => {
        if (!this.customNavigateFunction) {
            return undefined;
        }

        // Can be simplified. Need for testing.
        return (...props: any[]) => {
            // console.trace();
            // console.log(window.history.length, props);

            if (this.customNavigateFunction) {
                this.customNavigateFunction(...props);
            }
        };
    }

    setCustomNavigateFunction = (func: Function): void => {
        this.customNavigateFunction = func;
    }
}
