import { Node } from '@tiptap/core';
import { NodeSelection, TextSelection } from '@tiptap/pm/state';

const onDelete = (editor, names, isBackspace) => {
    try {
        const { selection } = editor.state;
        const { $from } = selection;

        if (selection.from !== selection.to) {
            return false;
        }

        let nearestNodePos = isBackspace ? selection.from - 1 : selection.from;
        let nearestNode = isBackspace ? $from.nodeBefore : $from.nodeAfter;

        if (!nearestNode) {
            for (let depth = $from.depth; depth > 0; depth--) {
                const parentPos = isBackspace ? $from.before(depth) : $from.after(depth);

                if (parentPos - (isBackspace ? 1 : 0) < 0) {
                    continue;
                }

                nearestNodePos = parentPos - (isBackspace ? 1 : 0);
                nearestNode = editor.state.doc.nodeAt(nearestNodePos);

                if (nearestNode) {
                    break;
                }
            }
        }

        if (!(selection instanceof NodeSelection) && nearestNode && names.includes(nearestNode.type.name)) {
            const newSelection = NodeSelection.create(editor.state.doc, nearestNodePos);
            const nodeDOM = editor.view.nodeDOM(nearestNodePos);

            nodeDOM.querySelector('img')?.click();
            nodeDOM.querySelector('video-js')?.click();

            editor.view.dispatch(editor.state.tr.setSelection(newSelection));
            editor.view.focus();

            if (selection instanceof TextSelection && !$from.nodeBefore && !$from.nodeAfter) {
                editor.view.dispatch(editor.state.tr.delete($from.pos - 1, $from.pos));
            }

            return true;
        }

        if (selection instanceof NodeSelection && names.includes(nearestNode.type.name)) {
            editor.view.dispatch(editor.state.tr.deleteSelection());
            return true;
        }
    } catch (e) {

    }

    return false;
};

export const SelectDelete = Node.create({
    name: 'selectDelete',

    addOptions() {
        return {
            extensions: [],
        };
    },

    addKeyboardShortcuts() {
        const names = this.options.extensions.map(p => p.name);

        return {
            Backspace: ({ editor }) => onDelete(editor, names, true),
            Delete: ({ editor }) => onDelete(editor, names, false),
        };
    }
});
