import { useEffect } from 'react';

export const useClickOutside = (ref, callback) => {
    const handleClick = e => {
        try {
            if (ref.current
                && e.target.id.slice(0, 12) !== 'react-select'
                && e.target.className !== 'react-tagsinput-remove'
                && !ref.current.contains(e.target)
                && e.target.className.indexOf('react-calendar') === -1
                && e.target.parentElement.className.indexOf('react-calendar') === -1
            ) {
                callback();
            }
        } catch {

        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    });
};
