import { getMarkRange, mergeAttributes } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import Link from '@tiptap/extension-link';
import { formatLinkName } from './commands';

// From DOMPurify
// https://github.com/cure53/DOMPurify/blob/main/src/regexp.js
// eslint-disable-next-line no-control-regex
const ATTR_WHITESPACE = /[\u0000-\u0020\u00A0\u1680\u180E\u2000-\u2029\u205F\u3000]/g

function isAllowedUri(uri, protocols) {
    const allowedProtocols = ['http', 'https', 'ftp', 'ftps', 'mailto', 'tel', 'callto', 'sms', 'cid', 'xmpp'];

    if (protocols) {
        protocols.forEach(protocol => {
            const nextProtocol = typeof protocol === 'string' ? protocol : protocol.scheme;

            if (nextProtocol) {
                allowedProtocols.push(nextProtocol);
            }
        });
    }

    return (
        !uri ||
        uri
            .replace(ATTR_WHITESPACE, '')
            // eslint-disable-next-line no-useless-escape
            .match(new RegExp(`^(?:(?:${allowedProtocols.join('|')}):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))`, 'i'))
    );
}

export const CustomLink = Link.extend({
    exitable: true,

    addAttributes() {
        return {
            ...this.parent?.(),
            'data-file-id': {
                default: null
            }
        }
    },

    parseHTML() {
        return [
            {
                tag: 'a[href]:not(:has(img))',
                getAttrs: dom => {
                    const href = dom.getAttribute('href');

                    // prevent XSS attacks
                    if (!href || !isAllowedUri(href, this.options.protocols)) {
                        return false;
                    }
                    return null;
                },
            },
            {
                tag: 'a[data-file-id]'
            }
        ];
    },

    renderHTML({ HTMLAttributes }) {
        // False positive; we're explicitly checking for javascript: links to ignore them
        // eslint-disable-next-line no-script-url
        if (HTMLAttributes.href?.toString()?.startsWith('javascript:')) {
            // strip out the href
            return ['a', mergeAttributes(this.options.HTMLAttributes, { ...HTMLAttributes, href: '' }), 0]
        }
        return ['a', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
    },

    addCommands() {
        return {
            setLink:
                (name, attrs) =>
                ({ chain }) => {
                    return chain()
                        .command(({ state }) => {
                            return formatLinkName(name, state);
                        })
                        .setMark(this.name, attrs)
                        .setMeta('preventAutolink', true)
                        .run();
                },
            updateLink:
                (name, attrs) =>
                ({ chain }) => {
                    chain()
                        .updateAttributes('link', attrs)
                        .command(({ state }) => formatLinkName(name, state))
                        .run();
                },
            unsetLink:
                () =>
                ({ chain }) => {
                    return chain().unsetMark(this.name, { extendEmptyMarkRange: true }).setMeta('preventAutolink', true).run();
                },
        };
    },

    addProseMirrorPlugins() {
        const existingPlugins = this.parent?.();
        const additionalPlugin = new Plugin({
            key: new PluginKey('linkKeyDown'),
            props: {
                handleTextInput: (view, _from, _to) => {
                    const { $from } = view.state.selection;
                    const marks = $from.marks();
                    const removedMark = marks.find(m => m.type.name === 'link');

                    if (!removedMark) {
                        return false;
                    }
                    const { tr } = view.state;
                    const { from, to } = getMarkRange($from, removedMark.type);

                    const isAtStart = $from.pos === from;
                    const isAtEnd = $from.pos === to;

                    if (isAtStart || isAtEnd) {
                        tr.removeStoredMark(removedMark);
                    }
                    view.dispatch(tr);
                },
            },
        });

        return [...(existingPlugins || []), additionalPlugin];
    },
});
