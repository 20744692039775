import React from 'react';
import classNames from 'classnames';
import { ColorPicker } from '../ColorPicker/ColorPicker';
import cx from '../../editor.module.scss';
import { hexToRgb, isValidRGB } from '../ColorPicker/utils';
import { isValidHex } from 'react-color/lib/helpers/color';

const DEFAULT_COLORS_SELECT_OPTIONS = [
    ['#162745', '#515D73', '#969DAB', '#999999', '#B7B7B7', '#CCCCCC', '#D9D9D9', '#EFEFEF', '#F3F3F3', '#FFFFFF'],
    ['#8C1A10', '#EC3323', '#F39D39', '#FFFD55', '#76FA4C', '#72FCFD', '#5587E1', '#0021F5', '#8A2DF6', '#EA3EF7'],
    ['#DFBAB1', '#EECECD', '#F9E6D0', '#FEF2D0', '#DCE9D5', '#D3E0E3', '#CCDAF6', '#E8EAF5', '#D8D3E7', '#E6D2DC'],
    ['#D18370', '#DF9D9B', '#F3CCA2', '#FCE5A3', '#BDD6AC', '#A8C3C8', '#A9C2F0', '#C6CBE7', '#B2A9D3', '#CEA8BC'],
    ['#BE4B31', '#D26D6A', '#F3CCA2', '#FAD978', '#9EC284', '#7FA4AE', '#759EE5', '#879ECA', '#8A7EBE', '#B87F9F'],
    ['#9A2A15', '#BC261A', '#DB944B', '#EBC352', '#79A65A', '#53808C', '#4779D2', '#6E84B0', '#6251A2', '#9B5378'],
    ['#7B2817', '#8D1A10', '#AA6324', '#B99130', '#48742C', '#254E5B', '#1F57C5', '#4869A9', '#302171', '#6B2346'],
    ['#541607', '#5E0D07', '#714216', '#7B611D', '#314D1C', '#17343C', '#244683', '#394CA5', '#1D154A', '#46162F'],
];

export const MenuButtonColorPicker = ({ value = '#fffff', onChange, onTabChange, onClose }) => {
    const [selectedTab, setSelectedTab] = React.useState(1);

    const handleChangeComplete = color => {
        const { r, g, b, a } = color.rgb;
        onChange(a < 1 ? `rgba(${r}, ${g}, ${b}, ${a})` : color.hex);
    };

    const handleTabChange = tabIndex => {
        onTabChange?.(tabIndex);
        setSelectedTab(tabIndex);
    };

    return (
        <div className="js-color-picker" style={{ padding: '12px', height: '100%' }}>
            <div className={cx.tabs}>
                <div
                    className={classNames(cx.tab, {
                        [cx.active]: selectedTab === 1,
                    })}
                    onClick={() => handleTabChange(1)}
                >
                    Цвета
                </div>
                <div
                    className={classNames(cx.tab, {
                        [cx.active]: selectedTab === 2,
                    })}
                    onClick={() => handleTabChange(2)}
                >
                    Палитра
                </div>
            </div>
            <div className={cx.colorsTab}>
                {selectedTab === 1 ? (
                    <div className={cx.colorsContent}>
                        {DEFAULT_COLORS_SELECT_OPTIONS.map((row, idx) => {
                            return (
                                <div className={cx.colorsRow} key={idx}>
                                    {row.map(color => {
                                        const { r, g, b } = hexToRgb(color);
                                        const rgb = `rgb(${r}, ${g}, ${b})`;
                                        return (
                                            <div key={color} style={{ position: 'relative' }}>
                                                <div
                                                    className={classNames(cx.colorsCell, {
                                                        [cx.whiteCell]: color === '#FFFFFF',
                                                        [cx.selected]: isValidHex(value)
                                                            ? value === color
                                                            : isValidRGB(value)
                                                            ? value === rgb
                                                            : value === color,
                                                    })}
                                                    style={{
                                                        backgroundColor: color,
                                                    }}
                                                    onClick={() => {
                                                        onChange(color);
                                                        onClose?.();
                                                    }}
                                                ></div>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                ) : null}
                {selectedTab === 2 ? (
                    <div className={classNames(cx.colorsContent, cx.colorsContentNoPadding)}>
                        <ColorPicker color={value} onChange={handleChangeComplete} />
                    </div>
                ) : null}
            </div>
        </div>
    );
};
