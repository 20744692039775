import React from 'react';
import cn from 'classnames';
import cs from './card.module.scss';

const Card = ({ children, className = '', id = '' }) => {
    return (
        <div id={id} className={cn(cs.card, className)}>
            {children}
        </div>
    );
};

export default Card;
