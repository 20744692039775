import React, { useEffect, useRef, useState } from 'react';
import cx from './global-filter.module.scss';
import Modal from 'react-modal';
import classNames from 'classnames';
import { useGlobalContext, useMessage } from 'lib/hooks';
import IconButton from 'uikit/icon-button';
import Icon, { Icons } from 'uikit/icon';
import { Select } from 'uikit/select';
import { DatePicker } from 'uikit/datetime';
import Checkbox from 'uikit/checkbox/checkbox';
import Button from 'uikit/button';
import { useClickOutside } from 'lib/hooks/useClickOutside';

function useOuterClick(ref, callback) {
    const handleClick = e => {
        if (ref.current
            && e.target.id.slice(0, 12) !== 'react-select'
            && e.target.className !== 'react-tagsinput-remove'
            && !ref.current.contains(e.target)
            && !ref.current.contains(e.target.ownerDocument.activeElement.parentElement)
            && e.target.className.indexOf('react-calendar') === -1
            && e.target.parentElement.className.indexOf('react-calendar') === -1
        ) {
            callback();
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    });
}

const GlobalFilter = ({
    value = {
        timePeriod: null,
        tags: [],
        authors: [],
        whereLooking: {
            includeNews: true,
            includeArticles: true,
            includeArchivedNews: false,
            includeArchivedArticles: true,
            includeFiles: true
        }
    },
    onChange = () => {},
    isOpen,
    close = () => {},
    timePeriod , //выбранный период
    onChangeTimePeriod = () => {}, //эвент изменения периода
    timePeriodMode, //режим выбора периода
    fromDate, //начальная дата
    onChangeFromDate = () => {}, //эвент изменения начальнай даты
    toDate, //сегодняшняя дата
    onChangeToDate, //эвент изменения сегодняшний даты
    tags, //написанные теги
    authors, //выбранные авторы
    authorsOptions, //возможные авторы
    onChangeAuthors, //эвент изменения авторов
    onChangeProjects,
    projects,
    loadProjectsOptions,
    onResetProjects,
    onResetAuthors, //функция для ресета авторов
    acrossAllPlaces, //функция для выделения всех вариантов где искать
    removeAcrossAllPlaces, //функция которая убирает выделения всех вариантов где искать
    whereLooking, //где искать
    onChangeWhereLooking, //эвент изменения места поиска
    saveActiveFilter, //функция сохранения активных фильтров
    deleteSaveActiveFilter, //функция удаления сохраненных фильтров
    acceptFilter, //функция применения фильтров
    onResetAll //функция сбрасывания всех фильтров
}) => {
    const filterRef = useRef(null);
    const tagsInputRef = useRef(null);

    const { addSuccess } = useMessage();
    const { platform } = useGlobalContext();

    const [tagsInputValue, setTagsInputValue] = useState('');

    useOuterClick(filterRef, () => {
        close();
    });
    useClickOutside(tagsInputRef, () => {
        if (tagsInputValue !== '') {
            tags.push(tagsInputValue);
            setTagsInputValue('');
        }
    });

    const timePeriodOptions = [
        { value: 'TODAY', label: 'За сегодня' },
        { value: 'YESTERDAY', label: 'За вчера' },
        { value: 'LAST_WEEK', label: 'За неделю' },
        { value: 'LAST_MONTH', label: 'За месяц' },
        { value: 'THREE_MONTHS', label: 'За три месяца' },
        { value: 'period', label: 'Произвольный диапазон' },
    ];
    const GetValue = () => {
        return {
            timePeriod: timePeriod || value.timePeriod,
            tags: tags || value.tags,
            authors: authors || value.authors,
            whereLooking: whereLooking || value.whereLooking
        }
    }

    const OnChangeTimePeriod = (data) => {
        const oldValue = GetValue();
        let Value = GetValue();
        Value.timePeriod = data;
        onChangeTimePeriod(data);
        onChange(Value, oldValue);
    }
    const OnChangeAuthors = (data) => {
        const oldValue = GetValue();
        const newValue = GetValue();

        newValue.authors = data;

        onChangeAuthors(data);
        onChange(newValue, oldValue);
    }

    const OnChangeProjects = (data) => {
        const oldValue = GetValue();
        const newValue = GetValue();

        newValue.projects = data;

        onChangeProjects(data);
        onChange(newValue, oldValue);
    }
    const OnChangeWhereLooking = (data, type) => {
        const oldValue = GetValue();
        let Value = GetValue();

        const WhereLooking = {
            includeNews: whereLooking === undefined ? value.whereLooking.includeNews : whereLooking.includeNews,
            includeArticles: whereLooking === undefined ? value.whereLooking.includeArticles : whereLooking.includeArticles,
            includeArchivedNews: whereLooking === undefined ? value.whereLooking.includeArchivedNews : whereLooking.includeArchivedNews,
            includeArchivedArticles: whereLooking === undefined ? value.whereLooking.includeArchivedArticles : whereLooking.includeArchivedArticles,
            includeFiles: whereLooking === undefined ? value.whereLooking.includeFiles : whereLooking.includeFiles
        };

        if (type === 'includeNews') {
            WhereLooking.includeNews = data
        } else if (type === 'includeArticles') {
            WhereLooking.includeArticles = data
        } else if (type === 'includeArchivedNews') {
            WhereLooking.includeArchivedNews = data
        } else if (type === 'includeArchivedArticles') {
            WhereLooking.includeArchivedArticles = data
        } else if (type === 'includeFiles') {
            WhereLooking.includeFiles = data
        }

        Value.whereLooking = WhereLooking;
        onChangeWhereLooking(WhereLooking);
        onChange(Value, oldValue)
    }

    return (
        <Modal
            className={classNames(cx.filters, {[cx.mobileFilters]: platform === 'mobile'})}
            overlayClassName={cx.filtersOverlay}
            isOpen={isOpen}
            contentRef={(n) => {
                filterRef.current = n;
            }}
        >
            <div className={cx.header}>
                <h3>Фильтры</h3>
                <IconButton
                    icon={<Icon type={Icons.CROSS} width={14} height={14} />}
                    onClick={() => close()}
                />
            </div>
            <div className={cx.body}>
                <div className={cx.row}>
                    <h4 className={cx.main}>Дата создания</h4>
                    <Select
                        className={cx.users}
                        value={timePeriod || value.timePeriod}
                        options={timePeriodOptions}
                        onChange={OnChangeTimePeriod}
                        isSearchable={false}
                    />
                    {timePeriodMode && (
                        <div className={cx.dateRange}>
                            <DatePicker value={fromDate} onChange={onChangeFromDate} />
                            <DatePicker value={toDate} onChange={onChangeToDate} />
                        </div>
                    )}
                </div>
                <div className={cx.row}>
                    <h4 className={cx.main}>Проекты</h4>
                    <div className={cx.tagItem}>
                        <Select
                            isMulti={true}
                            className={cx.users}
                            value={projects === undefined ? value.projects : projects}
                            loadOptions={loadProjectsOptions}
                            onChange={OnChangeProjects}
                            additional={{ page: 0 }}
                            paginate
                        />
                    </div>
                    <button onClick={onResetProjects} className={cx.reset}>Сбросить</button>
                </div>
                <div className={cx.row}>
                    <h4>Автор</h4>
                    <Select isMulti={true} className={cx.users} value={authors === undefined ? value.authors : authors} options={authorsOptions}
                            onChange={OnChangeAuthors} />
                    <button onClick={() => {
                        onResetAuthors();
                    }} className={cx.reset}>Сбросить
                    </button>
                </div>
                <div className={cx.row}>
                    <h4 className={cx.main}>Где искать</h4>
                    <div className={cx.actionsSearch}>
                        <span onClick={acrossAllPlaces}>Выделить все</span> / <span onClick={removeAcrossAllPlaces}>Убрать выделение</span>
                    </div>
                    <Checkbox label={'По новостям'} onChange={ () => {
                        OnChangeWhereLooking(whereLooking === undefined ? !value.whereLooking.includeNews : !whereLooking.includeNews, 'includeNews')
                    }} checked={whereLooking === undefined ? value.whereLooking.includeNews : whereLooking.includeNews} />

                    <Checkbox label={'По статьям'} onChange={ () => {
                        OnChangeWhereLooking(whereLooking === undefined ? !value.whereLooking.includeArticles : !whereLooking.includeArticles, 'includeArticles')
                    }} checked={whereLooking === undefined ? value.whereLooking.includeArticles : whereLooking.includeArticles} />

                    <Checkbox label={'По архивным новостям'} onChange={ () => {
                        OnChangeWhereLooking(whereLooking === undefined ? !value.whereLooking.includeArchivedNews : !whereLooking.includeArchivedNews, 'includeArchivedNews')
                    }} checked={whereLooking === undefined ? value.whereLooking.includeArchivedNews : whereLooking.includeArchivedNews} />

                    <Checkbox label={'По архивным статьям'} onChange={ () => {
                        OnChangeWhereLooking(whereLooking === undefined ? !value.whereLooking.includeArchivedArticles : !whereLooking.includeArchivedArticles, 'includeArchivedArticles')
                    }} checked={whereLooking === undefined ? value.whereLooking.includeArchivedArticles : whereLooking.includeArchivedArticles} />

                    <Checkbox label={'По файлам'} onChange={ () => {
                        OnChangeWhereLooking(whereLooking === undefined ? !value.whereLooking.includeFiles : !whereLooking.includeFiles, 'includeFiles')
                    }} checked={whereLooking === undefined ? value.whereLooking.includeFiles : whereLooking.includeFiles} />
                </div>
                <div className={cx.row}>
                    <button onClick={() => {
                        saveActiveFilter();
                        close();
                        addSuccess('Фильтры успешно сохранены');
                    }} className={cx.filterAction}><Icon type={Icons.EDIT} color={'blue'} height={15} width={15} />
                        <span className={cx.textFilterAction}>Сохранить активные фильтры по умолчанию</span>
                    </button>
                    <button onClick={() => {
                        deleteSaveActiveFilter();
                    }} className={cx.filterAction}><Icon type={Icons.TRASH} color={'blue'} height={15} width={15} />
                        <span className={cx.textFilterAction}>Сбросить сохраненные фильтры</span>
                    </button>
                </div>
            </div>
            <div className={cx.footer}>
                <Button onClick={() => {
                    acceptFilter();
                    close();
                }} color='green' label='Применить фильтры' fullWidth={platform === 'mobile'}/>
                <Button label='Сбросить фильтры' onClick={() => onResetAll()} fullWidth={platform === 'mobile'} />
            </div>
        </Modal>
    )
};

export default GlobalFilter;
