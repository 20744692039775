export const languages = [
  { label: 'Apache', value: 'apache' },
  { label: 'Bash', value: 'bash' },
  { label: 'CoffeeScript', value: 'cofeescript' },
  { label: 'C++', value: 'cpp' },
  { label: 'С#', value: 'csharp' },
  { label: 'CSS', value: 'css' },
  { label: 'Diff', value: 'diff' },
  { label: 'HTML', value: 'html' },
  { label: 'HTTP', value: 'http' },
  { label: 'INI', value: 'ini' },
  { label: 'Java', value: 'java' },
  { label: 'JavaScript', value: 'javascript' },
  { label: 'JSON', value: 'json' },
  { label: 'Makefile', value: 'makefile' },
  { label: 'Markdown', value: 'markdown' },
  { label: 'Nginx', value: 'nginx' },
  { label: 'Objective-C', value: 'objectivec' },
  { label: 'Perl', value: 'prel' },
  { label: 'PHP', value: 'php' },
  { label: 'Python', value: 'python' },
  { label: 'Ruby', value: 'ruby' },
  { label: 'SQL', value: 'sql' },
  { label: 'VBScript', value: 'vbscript' },
  { label: 'XHTML', value: 'xhtml' },
  { label: 'XML', value: 'xml' },
];
