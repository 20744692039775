import React from "react";

import { Bar } from "./Bar";
import { Volume } from "./Volume";
import Icon from "uikit/icon/icon";
import { Icons } from "uikit/icon";

import cx from './video-player.module.scss'

export const Controls = ({
    status = "PAUSE",
    watchedTime,
    currentTime,
    volume = 1,
    getDuration,
    getCurrentTime,
    onPlayStatusChange,
    onFullscreenHandler,
    onTimeChange = () => {},
    onVolumeChange = () => {},
}) => {
    return (
        <div className={cx.controls}>
            <div className={cx.buttonWrapper}>
                <button
                    className={cx.button}
                    onClick={onPlayStatusChange}
                >
                    {status === "PLAY" && <Icon type={Icons.Pause} width={20} height={20} color="white" />}
                    {status === "PAUSE" && <Icon type={Icons.PlayFilled} width={20} height={20} color="white" />}
                    {status !== "PLAY" && status !== "PAUSE" && (
                        <Icon type={Icons.Refresh} width={20} height={20} color="white" />
                    )}
                </button>
            </div>

            <Volume volume={volume} onVolumeUpdate={onVolumeChange} />
            <Bar
                watchedTime={watchedTime}
                currentTime={currentTime}
                getDuration={getDuration}
                getCurrentTime={getCurrentTime}
                setCurrentTime={onTimeChange}
            />
            <div className={cx.maximize} onClick={onFullscreenHandler}>
                <Icon type={Icons.Maximise} width={20} height={20} color="white" />
            </div>
        </div>
    );
};
