import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'shared/router';
import Table from 'uikit/table';
import Loading from 'uikit/loading';
import Icon, { Icons } from 'uikit/icon';
import Filter from 'uikit/filter/filter';
import Pagination from 'uikit/pagination/pagination';
import cx from './table-component.module.scss';
import { Select } from 'uikit/select';

const DEFAULT_SEARCH = '';
const DEFAULT_FILTERS = {};

export const EmptyCell = ({ title }) => {
    return <div className={cx.emptyCell}>{ title ? title : 'Не указано'}</div>;
}

export const TableComponent = ({
   id = '',
   innerRef,
   isLoading = false,
   isPaginate = true,
   title = '',
   searchTitle = '',
   addTitle = '',
   addLink = '',
   columns = [],
   actions = [],
   total = 0,
   data = [],
   onPaginate = () => {},
   onAdd = null,
   filters = [],
   onRowClick = () => {},
   isMobile = false,
   isGlobalCheckbox = true,
   onRowSelect = () => {},
   canRowSelect = true,
   archive,
   onImport = null,
   onExport = null,
   selectField = null,
   selectOptions = null,
   filterButtons = [],
   defaultSort = { by: '', desc: true },
   sortOptions = [],
   isShowReadiedFilter = false,
   headContent = '',
   onSortChange,
   addTitleMobile = '',
   rowsToTilesOnMobile = false,
}) => {
    const navigate = useNavigate();
    const [isEmpty, setIsEmpty] = useState(true);

    const [tableOffset, setTableOffset] = useState((() => {
        let offset = 0;

        const params = new URLSearchParams(document.location.search);
        const requestOffset = Number(params.get('offset'));

        if (requestOffset) {
            offset = requestOffset;
        }

        return offset;
    })());
    const [tableCount, setTableCount] = useState((() => {
        let count = 10;

        const params = new URLSearchParams(document.location.search);
        const requestCount = Number(params.get('count'));

        if (requestCount) {
            count = requestCount;
        }

        return count;
    })());
    const [tableSort, setTableSort] = useState((() => {
        let sort = defaultSort;
        const params = new URLSearchParams(document.location.search);

        const requestSortBy = params.get('sortBy');
        const requestSortDesc = params.get('sortDesc');

        if (requestSortBy && requestSortDesc) {
            sort = { by: requestSortBy, desc: !!requestSortDesc };
        }

        return sort;
    })());
    const [tableSearch, setTableSearch] = useState(DEFAULT_SEARCH);
    const [tableFilters, setTableFilters] = useState((() => {
        const filters = DEFAULT_FILTERS;

        if (selectOptions && selectOptions.length !== 0) {
            filters[selectField] = selectOptions[0];
        }

        return filters;
    })());

    const onTablePaginate = useCallback((offset, count, sort = tableSort) => {
        setTableOffset(offset);
        setTableCount(count);
        setTableSort(sort.by === '' ? defaultSort : sort);

        navigate(document.location.pathname + '?offset=' + offset + '&count=' + count + '&sortBy=' + sort.by + '&sortDesc=' + (sort.desc ? 1 : 0), { replace: true });
    }, [navigate, defaultSort, tableSort]);

    const onTableSort = useCallback((column) => {
        const sort = Object.assign({}, tableSort);

        if (sort.by === column.id) {
            sort.desc = !sort.desc;
        } else {
            sort.by = column.id;
            sort.desc = false;
        }

        setTableSort(sort);
        if (onSortChange) {
            onSortChange(sort);
        }
        onTablePaginate(tableOffset, tableCount, sort);
    }, [onTablePaginate, tableCount, tableOffset, tableSort, onSortChange]);

    const onMobileTableSort = useCallback((column, order = tableSort.desc) => {
        const sort = Object.assign({}, tableSort);

        sort.desc = order;
        sort.by = column.id;

        setTableSort(sort);
        if (onSortChange) {
            onSortChange(sort);
        }
        onTablePaginate(tableOffset, tableCount, sort);
    }, [onTablePaginate, tableCount, tableOffset, tableSort, onSortChange]);

    const onTableSearch = useCallback((text) => {
        setTableSearch(text);
        onTablePaginate(tableOffset, tableCount, tableSort);
    }, [onTablePaginate, tableCount, tableOffset, tableSort]);

    const onTableFilters = useCallback((filtersData) => {
        setTableFilters(filtersData);
        onTablePaginate(tableOffset, tableCount, tableSort);
    }, [onTablePaginate, tableCount, tableOffset, tableSort]);

    const onChangeFilters = (field, value) => {
        const filtersDataCopy = Object.assign({}, tableFilters);

        filtersDataCopy[field] = value;
        onTableFilters(filtersDataCopy);
    };

    const reload = () => {
        setTableSearch(DEFAULT_SEARCH);
        setTableFilters(DEFAULT_FILTERS);

        setIsEmpty(true);
        onTablePaginate(tableOffset, tableCount, defaultSort);
    };

    useImperativeHandle(innerRef, () => ({
        'reload': reload,
    }));

    useEffect(() => {
        onPaginate(tableOffset, tableCount, tableSort, tableSearch, tableFilters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableOffset, tableCount, tableSort, tableSearch, tableFilters]);

    if (isEmpty && total !== 0) {
        setIsEmpty(false);
    }

    if (isShowReadiedFilter && !('readied.in' in tableFilters)) {
        onChangeFilters('readied.in', { label: 'Все', value: null });
    }

    if (total === tableOffset) {
        const offset = tableOffset - tableCount;
        if (offset >= 0) {
            setTableOffset(offset);
        }
    }

    if (isEmpty) {
        return null;
    }

    return (
        <div id={id + "TableContainer"} className={cx.table}>
            {(title || isShowReadiedFilter) && (
                <div className={cx.head}>
                    <h2>{title}</h2>
                    {!isMobile && (
                        <div className={cx.headContent}>
                            {isShowReadiedFilter && (
                                <div className={cx.headReadied}>
                                    <Icon type={Icons.EYE} width={16} height={16} />
                                    <span>Показать документы:</span>
                                    <Select
                                        isMulti={false}
                                        isSearchable={false}
                                        value={tableFilters['readied.in']}
                                        onChange={(value) => onChangeFilters('readied.in', value)}
                                        options={[
                                            { label: 'Все', value: null },
                                            { label: 'Непрочитанные', value: 'false' },
                                            { label: 'Прочитанные', value: 'true' }
                                        ]}
                                    />
                                </div>
                            )}
                            {headContent}
                        </div>
                    )}
                </div>
            )}
            <div className={cx.body}>
                {(isLoading || (total !== 0 && data.length === 0 && !tableSearch)) && (
                    <div className={cx.loader}>
                        <Loading withOverlay={false} />
                    </div>
                )}
                {(searchTitle || addTitle || filters.length > 0 || filterButtons.length > 0) && (
                    <div className={cx.filter}>
                        <Filter
                            id='tableComponentFilter'
                            search={searchTitle}
                            searchText={tableSearch}
                            onSearch={(text) => onTableSearch(text)}
                            isLoading={isLoading}
                            add={addTitle}
                            onAdd={onAdd}
                            addLink={addLink}
                            filters={filters}
                            filtersData={tableFilters}
                            onFilters={onTableFilters}
                            isMobile={isMobile}
                            sort={tableSort}
                            onSort={onMobileTableSort}
                            sortOptions={sortOptions.length > 0 ? sortOptions : null}
                            onImport={onImport}
                            onExport={onExport}
                            selectOptions={selectOptions}
                            selectOption={tableFilters[selectField]}
                            buttons={filterButtons}
                            onSelect={(value) => {
                                const filters = Object.assign({}, tableFilters);
                                filters[selectField] = value;
                                onTableFilters(filters);
                            }}
                            addMobile={addTitleMobile}
                        />
                    </div>
                )}
                <Table
                    archive={archive}
                    columns={columns}
                    actions={actions}
                    data={data}
                    sort={tableSort}
                    onSort={onTableSort}
                    onRowClick={onRowClick}
                    isMobile={isMobile}
                    isGlobalCheckbox={isGlobalCheckbox}
                    onRowSelection={onRowSelect}
                    canRowSelect={canRowSelect}
                    rowsToTilesOnMobile={rowsToTilesOnMobile}
                    search={tableSearch}
                    id={id + "Table"}
                />
                {isPaginate && total > 0 && (
                    <div className={cx.pagination}>
                        <Pagination
                            total={total}
                            count={tableCount}
                            offset={tableOffset}
                            onPaginate={onTablePaginate}
                            isMobile={isMobile}
                            id={id + "TablePagination"}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
