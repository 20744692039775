import { useEffect, useState } from 'react';
import * as validators from 'lib/util/validators.util';
import { UserFormModel } from 'model/user';
import api from 'api';

export function useUserFormModel(userLogin, useCurrentUser = false) {
    const [loading, setLoading] = useState(false);
    const [deleted, setDeleted] = useState(false);

    const [user, setUser] = useState(new UserFormModel());

    useEffect(() => {
        async function getUser() {
            setLoading(true);

            try {
                let data;

                if (userLogin) {
                    data = await api.user.getUserByLogin(userLogin);
                } else if (useCurrentUser) {
                    data = await api.user.getCurrentUser();
                }

                setUser(new UserFormModel(data));
            } catch {
                setDeleted(true);
            }

            setLoading(false);
        }

        getUser();
    }, [userLogin, useCurrentUser, setDeleted]);

    function commit() {
        const copy = this.copy();
        copy.commit = commit;

        setUser(copy);
    }
    function validateFields(fields, allowEmptyPassword = false) {
        if (!fields || fields.length === 0) {
            fields = ['login', 'firstName', 'middleName', 'lastName', 'email', 'password', 'birthDate', 'employmentDate'];
        }

        let errors = {};

        if (fields.includes('login')) {
            errors['login'] = validators.loginValidator(user.login);
        }

        if (fields.includes('firstName')) {
            errors['firstName'] = validators.nameValidator(user.firstName);
        }

        if (fields.includes('middleName')) {
            errors['middleName'] = validators.nameValidator(user.middleName, false);
        }

        if (fields.includes('lastName')) {
            errors['lastName'] = validators.nameValidator(user.lastName);
        }

        if (fields.includes('email')) {
            errors['email'] = validators.emailValidator(user.email, false);
        }

        if (fields.includes('password')) {
            errors['password'] = validators.passwordValidator(user.password, !allowEmptyPassword);
        }

        if (fields.includes('birthDate')) {
            if (!user.birthDate) {
                errors['birthDate'] = 'Необходимо указать дату рождения';
            } else if (new Date() - new Date(user.birthDate) <= 0) {
                errors['birthDate'] = 'Дата рождения не может быть в будущем';
            } else {
              errors['birthDate'] = null;
            }
        }

        if (fields.includes('employmentDate')) {
            errors['employmentDate'] = user.employmentDate ? null : 'Необходимо указать дату приема на работу';
        }

        if (fields.includes('roles')) {
            errors['roles'] = user.roles.length ? null : 'Необходимо указать доступ к проектам';
        }

        user.errors = {
            ...user.errors,
            ...errors,
            loginExists: false,
        };

        user.commit();
        return Object.values(errors).every((v) => v === null);
    }

    function scrollToError() {
        const { login, loginExists, firstName, middleName, lastName, password, email, birthDate, employmentDate } = user.errors;

        if (login || loginExists) {
            document.getElementById('userFormLogin').scrollIntoView(false);
        } else if (firstName) {
            document.getElementById('userFormFirstName').scrollIntoView(false);
        } else if (middleName) {
            document.getElementById('userFormMiddleName').scrollIntoView(false);
        } else if (lastName) {
            document.getElementById('userFormLastName').scrollIntoView(false);
        } else if (password) {
            document.getElementById('userFormPassword').scrollIntoView(false);
        } else if (email) {
            document.getElementById('userFormEmail').scrollIntoView(false);
        } else if (birthDate) {
            document.getElementById('userFormBirthDate').scrollIntoView(false);
        } else if (employmentDate) {
            document.getElementById('userFormEmploymentDate').scrollIntoView(false);
        }
    }
    async function validateLoginExists() {
        try {
            if (user.errors.login) {
                return;
            }

            const loginExists = (await api.user.validateLogin(user.login)) !== 'ALLOWED';

            if (loginExists) {
                user.errors = {
                    ...user.errors,
                    loginExists: true,
                };
                user.commit();
            }

            return loginExists;
        } catch {
            return true;
        }
    }

    user.commit = commit;
    user.validateFields = validateFields;
    user.scrollToError = scrollToError;
    user.validateLoginExists = validateLoginExists;

    return { user, setUser, loading, setLoading, deleted };
}
