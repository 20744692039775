import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'shared/router';
import { useMessage, usePermittedActions, useDialog, useAuthorized, useCombinedRefs } from 'lib/hooks';
import { perm, RESOURCE_TYPE } from 'model/resource';
import Confirmation from 'components/confirmation';
import Lock from 'components/lock';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button/icon-button';
import api from 'api';
import cx from './project.module.scss';
import ButtonMenu from 'uikit/button-menu';
import { updateNewsTicker } from 'slice/newsPickerSlice';
import { useDispatch } from 'react-redux';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import { useConfiguration } from '../../lib/context';

const Project = ({
    id = '',
    avatar = '',
    title = '',
    description = '',
    manager = '',
    managerLogin = '',
    favorite,
    tab,
    onTabChange,
    onDelete,
    onFavoriteHandler,
    isMobile,
    empty,
    isProjectInfoHidden,
    onInfoShow,
    projectInfoRef,
}) => {
    const dispatch = useDispatch();
    const activeTabRef = useRef(null);
    const infoRef = useCombinedRefs(projectInfoRef);

    const { isScriptingEnabled } = useConfiguration();

    const { isAuthorizedAction } = useAuthorized();
    const { checkPermission } = usePermittedActions(RESOURCE_TYPE.PROJECT, id);

    const { addError, addSuccess } = useMessage();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [lock, setLock] = useState(false);

    const p = {
        lock: checkPermission(perm.document.LOCK),
        add: checkPermission(perm.document.PROJECT_CREATE),
        edit: checkPermission(perm.document.PROJECT_EDIT),
        delete: checkPermission(perm.document.PROJECT_DELETE_REQUEST),
        archive: checkPermission(perm.document.RESOURCE_ARCHIVE_MOVE),
    };

    const addToFavorites = async () => {
        try {
            favorite ? await api.favorites.deleteProjectFromFavorites(id) : await api.favorites.addProjectToFavorites(id);
            onFavoriteHandler(!favorite, id);

            favorite ? addSuccess('Проект удален из избранного') : addSuccess('Проект добавлен в избранное');
        } catch (e) {
            addError('Не удалось добавить проект в избранное');
        }
    };

    const moveToArchive = async () => {
        try {
            await api.archive.moveProjectToArchive(id);

            addSuccess('Проект перемещен в архив');

            dispatch(updateNewsTicker(true));

            onDelete(id);
        } catch (e) {
            addError('Не удалось переместить проект в архив');
        }
    };

    const onMoveToArchive = async () => {
        const text = (
            <span>
                Вы действительно хотите перенести в архив проект&nbsp;
                <span style={{ color: '#279BD9' }}>{title}</span> ? Проекты в архиве могут быть восстановлены
            </span>
        );

        openDialog({
            title: 'Архивирование',
            text,
            onSubmit: () => moveToArchive(),
            onClose: closeDialog,
            contentType: 'TEXT',
        });
    };

    useEffect(() => {
        if (activeTabRef === null) {
            return;
        }

        activeTabRef.current?.scrollIntoView();
    }, [activeTabRef]);

    return (
        <>
            {lock && (
                <Lock
                    title="Доступ к проекту"
                    onClose={() => setLock(false)}
                    emptyTitle="Проект в закрытом доступе"
                    emptyDescription="Пригласите первого участника для работы с проектом"
                    projectId={id}
                    resourceId={id}
                    resourceType="PROJECT"
                    projectName={title}
                    empty={empty}
                />
            )}
            {!isMobile && (
                <div className={cx.project}>
                    <div className={cx.head}>
                        <div
                            id="projectAvatar"
                            className={classNames(cx.avatar, {
                                [cx.small]: isProjectInfoHidden,
                            })}
                        >
                            {avatar && <img alt="" src={avatar} />}
                            {!avatar && <img alt="" src={'/img/default-icon-project.jpg'} />}
                        </div>
                        <div className={cx.body}>
                            <div className={cx.title}>
                                <h1 id="project-title">{title}</h1>
                                <div className={cx.hideButton} onClick={onInfoShow}>
                                    О проекте
                                </div>
                                <div className={cx.navigation}>
                                    {p.edit && (
                                        <Link id="projectEditIcon" to={`/projects/project/${id}/edit`}>
                                            <Icon type={Icons.EDIT_PEN} tooltip="Редактировать" />
                                        </Link>
                                    )}
                                    {p.lock && (
                                        <IconButton
                                            id="projectLockIcon"
                                            icon={<Icon type={Icons.LOCK} tooltip="Доступы" />}
                                            onClick={() => setLock(true)}
                                        />
                                    )}
                                    <IconButton
                                        id="projectAddToFavouritesIcon"
                                        onClick={addToFavorites}
                                        icon={
                                            <Icon
                                                type={favorite ? Icons.STAR_FILLED : Icons.STAR}
                                                tooltip="В избранное"
                                                className={classNames({ [cx.favorites]: favorite })}
                                            />
                                        }
                                    />
                                    {p.archive && (
                                        <IconButton
                                            id="projectArchiveIcon"
                                            icon={<Icon type={Icons.ARCHIVE} tooltip="Архивировать" />}
                                            onClick={onMoveToArchive}
                                        />
                                    )}
                                </div>
                            </div>
                            <div
                                ref={infoRef}
                                className={classNames(cx.projectInfo, {
                                    [cx.hidden]: isProjectInfoHidden,
                                })}
                            >
                                {description && <p id="project-description">{description}</p>}
                                {manager && (
                                    <div className={cx.manager}>
                                        <span>Руководитель проекта</span>
                                        <Link id="project-manager" to={'/edit-user/' + managerLogin}>
                                            {manager}
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isMobile && (
                <div className={cx.mobileProject}>
                    <div className={cx.titleBlock}>
                        <div id={'projectAvatar'} className={cx.avatar}>
                            {avatar ? <img alt={''} src={avatar} /> : <img alt={''} src={'/img/default-icon-project.jpg'} />}
                        </div>
                        <h1 id={'project-title'} className={cx.title}>
                            {title}
                        </h1>
                    </div>
                    {description && (
                        <p id={'project-description'} className={cx.desc}>
                            {description}
                        </p>
                    )}
                    {manager && (
                        <div className={cx.manager}>
                            <span>Руководитель проекта</span>
                            <Link id={'project-manager'} to={'/edit-user/' + managerLogin}>
                                {manager}
                            </Link>
                        </div>
                    )}

                    <ButtonMenu
                        className={cx.navigationMenu}
                        items={[
                            p.edit && {
                                title: 'Редактировать',
                                icon: Icons.EDIT_PEN,
                                link: `/projects/project/${id}/edit`,
                                id: 'projectEditItem',
                            },
                            p.lock && {
                                title: 'Доступы',
                                icon: Icons.LOCK,
                                onClick: () => setLock(true),
                                id: 'projectLockItem',
                            },
                            {
                                title: favorite ? 'Из избранного' : 'В избранное',
                                icon: favorite ? Icons.STAR_FILLED : Icons.STAR,
                                onClick: addToFavorites,
                                id: 'projectAddToFavouritesItem',
                            },
                            p.archive && {
                                title: 'Архивировать',
                                icon: Icons.ARCHIVE,
                                onClick: onMoveToArchive,
                                id: 'projectArchiveItem',
                            },
                            // {
                            //     title: 'Настройки',
                            //     icon: Icons.SETTINGS,
                            //     onClick: () => {},
                            //     id: 'projectSettingsIcon'
                            // }
                        ].filter(Boolean)}
                    />
                </div>
            )}
            <div className={cx.projectMenuWrapper}>
                <div id="projectMenuFogLeft" className={cx.projectMenuFogLeft} style={{ opacity: 0 }}></div>
                <div id="projectMenuFogRight" className={cx.projectMenuFogRight}></div>
                <ul
                    id="projectMenu"
                    className={cx.projectMenu}
                    onScroll={e => {
                        if (e.target.scrollWidth - 10 < e.target.clientWidth + e.target.scrollLeft) {
                            document.getElementById('projectMenuFogRight').style.opacity = '0';
                        } else {
                            document.getElementById('projectMenuFogRight').style.opacity = '1';
                        }

                        if (e.target.scrollLeft > 10) {
                            document.getElementById('projectMenuFogLeft').style.opacity = '1';
                        } else {
                            document.getElementById('projectMenuFogLeft').style.opacity = '0';
                        }
                    }}
                >
                    <li
                        ref={tab === 'articles' ? activeTabRef : null}
                        className={tab === 'articles' ? cx.active : ''}
                        onClick={() => onTabChange('articles')}
                    >
                        Статьи
                    </li>
                    <li
                        ref={tab === 'news' ? activeTabRef : null}
                        className={tab === 'news' ? cx.active : ''}
                        onClick={() => onTabChange('news')}
                    >
                        Новости
                    </li>
                    {isAuthorizedAction([GLOBAL_ACTIONS.ACTIVITY_PAGE]) && (
                        <li
                            ref={tab === 'actions' ? activeTabRef : null}
                            className={tab === 'actions' ? cx.active : ''}
                            onClick={() => onTabChange('actions')}
                        >
                            Журнал Действий
                        </li>
                    )}
                    <li
                        ref={tab === 'users' ? activeTabRef : null}
                        className={tab === 'users' ? cx.active : ''}
                        onClick={() => onTabChange('users')}
                    >
                        Участники
                    </li>
                    <li
                        ref={tab === 'files' ? activeTabRef : null}
                        className={tab === 'files' ? cx.active : ''}
                        onClick={() => onTabChange('files')}
                    >
                        Файлы
                    </li>
                    {isScriptingEnabled && (
                        <li
                            id={cx.scriptsTab}
                            ref={tab === 'scripts' ? activeTabRef : null}
                            className={tab === 'scripts' ? cx.active : ''}
                            onClick={() => onTabChange('scripts')}
                        >
                            Скрипты
                        </li>
                    )}
                </ul>
            </div>
            <Confirmation {...dialogState} />
        </>
    );
};

export default Project;
