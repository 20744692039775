import React, { useState } from 'react';
import classNames from 'classnames';
import IconButton, { IconButtonType } from 'uikit/icon-button';
import Icon, { Icons } from 'uikit/icon';
import AvatarEditor from 'components/avatar-editor/avatar-editor';
import cs from './avatar-change.module.scss';
import { useGlobalContext, useMessage } from 'lib/hooks';

const AvatarChange = ({ value, onSubmit, onRemove }) => {
    const { platform } = useGlobalContext();
    const { addError } = useMessage();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [uploadAvatar, setUploadAvatar] = useState(null);

    const selectAvatar = (e) => {
        if (e.target.files.length === 0) {
            return;
        }

        const allowedFormats = ['image/png', 'image/jpeg'];

        if (e.target.files.length > 0 && allowedFormats.includes(e.target.files[0].type)) {
            let oFReader = new FileReader();
            oFReader.readAsDataURL(e.target.files[0]);

            oFReader.onload = function() {
                setUploadAvatar(this.result);
                setIsModalOpen(true);
            };
        } else {
            addError('Для загрузки допустимы следующие форматы - JPG, PNG.');
        }
    };
    const onAvatarSubmit = (avatar) => {
        onSubmit(avatar);
        setIsModalOpen(false);
    };

    const onAvatarCancel = () => {
        document.getElementById('project-avatar').value = '';
        setIsModalOpen(false)
    };
    const onAvatarRemove = () => {
        onRemove();
        setUploadAvatar(null);
        setIsModalOpen(false);
    };

    return (
        <>
            {isModalOpen &&
            <AvatarEditor open={isModalOpen} img={uploadAvatar} title={'Загрузка аватара'}
                          onSubmit={onAvatarSubmit} onDismiss={onAvatarCancel}/>}

            <div className={classNames(cs.avatarPanel, {[cs.mobile]: platform === 'mobile'})}>
                <img src={value} alt=""/>
                <div className={cs.infoContainer}>
                    <IconButton type={IconButtonType.TABLE} label="Загрузить фото"
                                icon={<Icon type={Icons.PHOTO} width={15} height={13}/>}
                                onClick={() => document.getElementById('project-avatar').click()}/>
                    <IconButton type={IconButtonType.TABLE} label="Удалить фото"
                                icon={<Icon type={Icons.TRASH} width={15} height={13}/>}
                                onClick={onAvatarRemove}/>
                    <input id="project-avatar" type="file" name="myfile" onChange={selectAvatar}
                           accept="image/png, image/jpeg"/>
                </div>
            </div>
        </>
    )
};

export default AvatarChange;
