import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { useCancelBehavior, useGlobalContext, useMessage, useManualFormDataChange } from 'lib/hooks';
import { ProjectFormModel } from 'model/project';
import Card from 'components/card';
import UserInput from 'components/user-input';
import AvatarEditor from 'components/avatar-editor/avatar-editor';
import Divider from 'uikit/divider';
import Input from 'uikit/input/input';
import Icon, { Icons } from 'uikit/icon';
import Button from 'uikit/button/button';
import IconButton, { IconButtonType } from 'uikit/icon-button';
import cs from './project-form.module.scss';

const ProjectForm = ({ project, loading, children, edit = false }) => {
    const projectRef = useRef(project);
    const userInputRef = useRef();

    const { addError } = useMessage();
    const { cancel } = useCancelBehavior();

    const { platform, setFormDataChanged, setFormDataChangedSubmitHandler } = useGlobalContext();
    const { isFormDataTouched, handleManualFormDataChange } = useManualFormDataChange(edit);

    const [isAvatarModal, setIsAvatarModal] = useState(false);
    const [uploadAvatar, setUploadAvatar] = useState(null);

    const onAvatarCancel = () => {
        document.getElementById('project-avatar').value = '';
        setIsAvatarModal(false)
    };
    const onAvatarSave = (avatar) => {
        handleManualFormDataChange();

        project.croppableUploadAvatar = avatar;
        project.commit();

        setIsAvatarModal(false);
    };

    const onAvatarLoad = () => {
        document.getElementById('project-avatar').click();
    };
    const onAvatarRemove = () => {
        project.logoUuid = null;
        project.croppableUploadAvatar = '';

        project.commit();
        setUploadAvatar(null);
    };
    const onSelectAvatar = (e) => {
        handleManualFormDataChange();
        if (e.target.files.length > 0 && e.target.files[0].type.match(/^image\//)) {
            const oFReader = new FileReader();
            oFReader.readAsDataURL(e.target.files[0]);

            oFReader.onload = function() {
                setUploadAvatar(this.result);
                setIsAvatarModal(true);
            };
        } else {
            addError('для загрузки допустимы следующие форматы - JPG, PNG, WEBM.');
        }
    };

    const onTitleFocus = () => {
        project.touched.title = true;
    };
    const onTitleBlur = () => {
        if (projectRef.current.title === project.title) {
            return;
        }

        project.validateTitleExists();
    };
    const onTitleChange = (value) => {
        handleManualFormDataChange();
        project.title = value.replace(/\u00a0/g, ' ');

        project.validateFields(['title']);
        project.commit();
    };

    const onDescriptionFocus = () => {
        project.touched.description = true;
    };
    const onDescriptionChange = (value) => {
        handleManualFormDataChange();
        project.description = value.replace(/\u00a0/g, ' ');

        project.validateFields(['description']);
        project.commit();
    };

    useEffect(() => {
        if (!loading) {
            projectRef.current = cloneDeep(project);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, project.id]);
    useEffect(() => {
        if (!loading){
            const { title, description, croppableUploadAvatar } = project;
            const { title: refTitle, description: refDesacription, croppableUploadAvatar: refCroppableUploadAvatar } = projectRef.current;
            setFormDataChanged(!isEqual([title, description, croppableUploadAvatar], [refTitle, refDesacription, refCroppableUploadAvatar]) || isFormDataTouched);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project.title, project.description, project.croppableUploadAvatar])

    // Сброс до инициализирующих данных при попытке уйти с формы добавления проекта, нажав на тот же пункт в меню - "Проект"
    function resetToInitialValues () {
        return () => {
            const initialEntity = new ProjectFormModel();
            const initialEntityKeys = Object.keys(initialEntity);
            initialEntityKeys.forEach((key) => {
                project[key] = initialEntity[key];
            })
            userInputRef?.current?.setValue('');
            project.commit();
            console.log(project);
        }
    }
    useEffect(() => {
        if (!edit) {
            setFormDataChangedSubmitHandler(resetToInitialValues);
        }
        return () => {
            if (!edit) {
                setFormDataChangedSubmitHandler(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={classNames(cs.content, { [cs.mobile]: platform === 'mobile' })}>
            {isAvatarModal && (
                <AvatarEditor
                    open={isAvatarModal}
                    title="Загрузка аватара"
                    img={uploadAvatar}
                    onDismiss={onAvatarCancel}
                    onSubmit={onAvatarSave}
                />
            )}
            <div className={cs.avatarContainer}>
                <div className={cs.avatarPanel}>
                    <img src={project.getFormImage()} alt="" />
                    <div className={cs.infoContainer}>
                        <IconButton
                            type={IconButtonType.TABLE}
                            label="Загрузить фото"
                            icon={<Icon type={Icons.PHOTO} width={15} height={13} />}
                            onClick={onAvatarLoad}
                        />
                        <IconButton
                            type={IconButtonType.TABLE}
                            label="Удалить фото"
                            icon={<Icon type={Icons.TRASH} width={15} height={13} />}
                            onClick={onAvatarRemove}
                        />
                        <input
                            id="project-avatar"
                            type="file"
                            name="myfile"
                            onChange={onSelectAvatar}
                            accept="image/png, image/jpeg"
                        />
                    </div>
                </div>
            </div>
            <Card id="projectFormCard">
                <div className={cs.titleBlock}>Основные данные</div>
                <Input
                    id="projectFormNameField"
                    className={cs.field}
                    type="text"
                    name="title"
                    label="Название"
                    value={project.title}
                    onChange={onTitleChange}
                    disabled={loading}
                    required
                    error={project.errors.titleExists || project.errors.title}
                    onFocus={onTitleFocus}
                    onBlur={onTitleBlur}
                />
                <Input
                    id="projectFormDescriptionField"
                    className={cs.field}
                    type="text"
                    name="description"
                    label="Краткое описание"
                    value={project.description}
                    onChange={onDescriptionChange}
                    disabled={loading}
                    required
                    error={project.errors.description}
                    onFocus={onDescriptionFocus}
                />
                <Divider />
                <UserInput
                    ref={userInputRef}
                    id="projectFormSupervisorField"
                    label="Руководитель"
                    onChange={() => {}}
                />
                <Divider className={cs.divider} />
                <div className={cs.tool}>
                    <Button
                        label="Отмена"
                        fullWidth={platform === 'mobile'}
                        onClick={cancel}
                    />
                    {children}
                </div>
            </Card>
        </div>
    );
};

export default ProjectForm;
