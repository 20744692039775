import React from 'react';
import { NodeViewProps, NodeViewWrapper, NodeViewContent } from '@tiptap/react';
import { Attrs } from '@tiptap/pm/model';
import cn from './view.module.scss';


export const CodeBlockWithLineNumbersView: React.FC<NodeViewProps> = ({ node }) => {

    const attrs: Attrs = node?.attrs;
    const lines: string[] = (node?.textContent || '').split('\n');

    return (
        <NodeViewWrapper className={cn.codeBlockWithLineNumbers}>
            <pre>
                <div aria-hidden='true'>
                    {
                        lines.map((_, index) => (
                            <React.Fragment key={ index }>
                                <span>{ index + 1 }</span>
                                {'\n'}
                            </React.Fragment>
                        ))
                    }
                </div>
                <NodeViewContent
                    as='code'
                    className={ attrs.language ? `language-${attrs.language}` : 'plaintext' }
                />
            </pre>
        </NodeViewWrapper>
    )
}


