import React from 'react';
import cn from 'classnames';
import { useNavigate } from 'shared/router';
import Checkbox from 'uikit/checkbox';
import Icon, { Icons } from 'uikit/icon';
import { UnreadLabel } from 'uikit/global-menu/unread-label';
import { AdaptiveLink } from 'containers/adaptive-link/adaptive-link';
import TreeIcon from './tree-icon';
import Toggler from './tree-toggler';
import NodeName from './tree-node-name';
import { NODE_TYPES } from './node-type';
import { TreeButton, ButtonType } from './tree-button';
import cs from './tree-node.module.scss';

const TreeNode = ({
    node,
    tree,
    toggleState,
    onUpdate,
    multipleChoice,
    onEditNode,
    onArchiveNode,
    onUnArchiveNode,
    onDeleteNode,
    archive,
    onAddNode,
    onCancelAdd,
    onAddCommit,
    checkedChange,
    showCheckbox,
    highlight,
    wordBreak,
    getNodeLink,
    getEditNodeLink,
    showUnreadCounters,
    hideIcons,
    statistics,
    checkNodeOnSelect = false,
    toggleNodeOnSelect = false
}) => {
    const navigate = useNavigate();
    const [nodeDraftName, setNodeDraftName] = React.useState('');

    const draftNameChanged = (e) => {
        setNodeDraftName(e.target.value);
    };

    const draftNameAdd = (name) => {
        onAddCommit(name);
        setNodeDraftName('');
    }

    const draftNameCancel = () => {
        onCancelAdd();
        setNodeDraftName('');
    }

    const link = React.useMemo(() => {
        return getNodeLink ? getNodeLink(node) : '';
    }, [getNodeLink, node]);
    const isSelected = () => {
        return node['nodeType'] === 'THEME' || node['nodeType'] === 'QUESTION'
            ? (node['nodeType'] === 'THEME'
                && document.location['pathname'].indexOf('/training/catalog/theme/' + node['resourceId']) !== -1)
            || (node['nodeType'] === 'QUESTION'
                && document.location['pathname'].indexOf('/training/catalog/question/' + node['resourceId']) !== -1)
            : node.state.selected;
    };

    const showEditBlock = (onEditNode || onArchiveNode || onUnArchiveNode || onDeleteNode || onAddNode);
    const openTreeNode = (node, tree) => {
        const { children, indeterminate, state: { open, checked }} = node;

        if (!open || (open && !checked && typeof indeterminate === 'boolean' && !indeterminate)){
            tree.toggleNode(node);
        }

        if (children.length) {
            children.forEach((childNode) => {
                openTreeNode(childNode, tree)
            });
        }
    }

    return (
        <div className={cn(cs.container, {
            [cs.editableNode]: showEditBlock,
        })}>
            <a
                href={link}
                className={cn(cs.node, {
                    [cs.selected]: isSelected(),
                    [cs.statistics]: statistics,
                })}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    onUpdate(node);
                    return false;
                }}
            >
                {/* CHECKBOX */}
                {(multipleChoice && (!showCheckbox || showCheckbox(node))) &&
                    <div className={cs.checkbox}>
                        <Checkbox
                            checked={node.state.checked}
                            indeterminate={node.state.indeterminate || node.indeterminate}
                            onClick={e => e.stopPropagation()}
                            onChange={e => {
                                if (node.state.checked && !node.indeterminate && !node.state.indeterminate
                                    && node['resourceType'] !== 'THEME' && node['resourceType'] !== 'QUESTION') {
                                    // tree.updateNode(node, { indeterminate: true });
                                    // node.indeterminate = true;

                                    tree.checkNode(node, e.target.checked);
                                } else {
                                    if (node.indeterminate) {
                                        node.indeterminate = false;
                                    }

                                    tree.updateNode(node, { indeterminate: false });
                                    tree.checkNode(node, e.target.checked);
                                }

                                openTreeNode(node, tree);
                                e.stopPropagation();

                                checkedChange(node, e.target.checked, e);
                            }}
                        />
                    </div>
                }
                {!(multipleChoice && (!showCheckbox || showCheckbox(node))) && (
                    <div style={{ minWidth: 20 }}>{/*  no content  */}</div>
                )}
                <Toggler
                    state={toggleState}
                    depth={node.state.depth}
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();

                        if (toggleState === 'closed') {
                            tree.openNode(node);
                        } else if (toggleState === 'opened') {
                            tree.closeNode(node, { silent: true });
                        }
                    }}
                />
                {/* NODE NAME */}
                {node.nodeType === NODE_TYPES.ADD && (
                    <div className={cs.addDraftContainer}>
                        <input type={'text'} value={nodeDraftName} onChange={draftNameChanged} />
                        <button className={cs.ok} onClick={() => draftNameAdd(nodeDraftName)}>
                            <Icon width={20} height={20} type={Icons.CHECK} />
                        </button>
                        <button className={cs.cancel} onClick={draftNameCancel}>
                            <Icon width={20} height={20} type={Icons.CLOSE_REMOVE_X} />
                        </button>
                    </div>
                )}
                {node.nodeType !== NODE_TYPES.ADD && (
                    <AdaptiveLink
                        className={cs.clickable}
                        link={typeof link === 'object' ? link.link : link}
                        replace={typeof link === 'object' && link.replace}
                        onClick={(event) => {
                            tree.selectNode(node);

                            if (checkNodeOnSelect && !["PROJECT", "SECTION"].includes(node.nodeType)) {
                                tree.checkNode(node);
                            }

                            if (toggleNodeOnSelect) {
                                tree.toggleNode(node, { silent: true });
                            }

                            event.stopPropagation();
                        }}
                    >
                        <div style={{ position: 'relative', width: '100%' }}>
                            {node.nodeType && !hideIcons && (
                                <TreeIcon
                                    state={toggleState}
                                    nodeType={node.nodeType}
                                    node={node}
                                    isSelected={isSelected}
                                />
                            )}
                            <NodeName
                                className={cs.nodeName}
                                state={toggleState}
                                node={node}
                                highlight={highlight}
                                wordBreak={wordBreak}
                                hideIcons={hideIcons}
                                archive={archive}
                            >
                                {node.name}
                            </NodeName>
                        </div>
                        {node.role && <span className={cs.nodeRole}>{node.role.title}</span>}
                    </AdaptiveLink>
                )}
            </a>
            {showEditBlock && (
                <div className={cs.editBlock}>
                    {!archive && (onEditNode || getEditNodeLink) && node?.permissions?.canEdit && (
                        <TreeButton
                            width={28}
                            height={'100%'}
                            icon={ButtonType.EDIT}
                            onClick={() => navigate(getEditNodeLink(node))}
                            tooltip={'Редактировать'}
                        />
                    )}
                    {!archive && onArchiveNode && node?.permissions?.canArchive && (
                        <TreeButton
                            width={28}
                            height={'100%'}
                            icon={ButtonType.ARCHIVE}
                            onClick={() => onArchiveNode(node)}
                            tooltip={'Архивировать'}
                        />
                    )}
                    {archive && onUnArchiveNode && node?.permissions?.canRestore && (
                        <TreeButton
                            width={28}
                            height={'100%'}
                            icon={ButtonType.UNARCHIVE}
                            onClick={() => onUnArchiveNode(node)}
                            tooltip={'Разархивировать'}
                        />
                    )}
                    {archive && onDeleteNode && node?.permissions?.canDelete && (
                        <TreeButton
                            width={28}
                            height={'100%'}
                            icon={ButtonType.DELETE}
                            hoverColor={'red'}
                            onClick={() => onDeleteNode(node)}
                            tooltip={'Удалить'}
                        />
                    )}
                    {onAddNode && (node.nodeType === NODE_TYPES.PROJECT || node.nodeType === NODE_TYPES.SECTION || node.nodeType === NODE_TYPES.THEME) && (
                        <TreeButton
                            width={28}
                            height={'100%'}
                            icon={ButtonType.ADD}
                            onClick={() => onAddNode(node)}
                            tooltip={'Добавить'}
                        />
                    )}
                </div>
            )}
            {showUnreadCounters && !node.state.open && (
                <div className={cs.unreadBlock}>
                    <UnreadLabel
                        className={cn(cs.unreadLabel, { [cs.labelGray]: node.nodeType !== NODE_TYPES.PROJECT })}
                        count={node.unreadCount}
                    />
                </div>
            )}
        </div>
    );
};

export default TreeNode;
