import React, { useState, useMemo, useEffect } from 'react';
import { Link } from 'shared/router';
import { ReportsComponent } from 'components/data-components/reports-component';
import cx from './statistic-reports-page.module.scss';
import api from 'api';
import { articleSearchBy, sortTypesOptions } from 'app/statistic/vendors/filtersOptions';
import { ReportService } from 'app/statistic/services/ReportService';
import { useMessage } from 'lib/hooks';

const StatisticReportsActionsInArticles = ({ setIsDownloadModal }) => {
    const { addError } = useMessage();

    const [isLoading, setIsLoading] = useState(false);

    const [actionsTypes, setActionsTypes] = useState([]);

    const [reports, setReports] = useState([]);
    const [reportsTotal, setReportsTotal] = useState(0);
    const [sortByOptions, setSortByOptions] = useState([]);

    const getActionsInProjectsReports = async (page = 0, size = 50, filters = {}) => {
        setIsLoading(true);
        try {
            const res = await api.reports.getUserActions({
                type: 'events/article',
                page,
                size,
                filters,
                cancelToken: ReportService.cancelToken.token,
            });

            setReports(
                res.projectEvents.content.map((i) => {
                    return {
                        ...i,
                        eventType: i.message,
                        timestamp: i.timestamp,
                    };
                })
            );

            setReportsTotal(res.projectEvents.totalElements);

            if (!res.projectEvents.totalElements) {
                addError('По вашему запросу нет данных');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const onDownload = async (filters) => {
        try {
            await api.reports.downloadReports('events/article', filters, ReportService.cancelToken.token);
            setIsDownloadModal(true);
        } catch (error) {
            addError('Произошла ошибка при скачивании отчета');
        }
    };

    const columnsTmp = useMemo(() => {
        const data = [
            {
                Header: 'Документ',
                accessor: 'projectTitle',
                sort: false,
                Cell: (data) => {
                    return (
                        <>
                            {data.row.original.status === 'DELETED' && <div>{data.row.original.projectTitle}</div>}
                            {data.row.original.status !== 'DELETED' && (
                                <Link
                                    className={cx.link}
                                    to={
                                        (data.row.original.status === 'ARCHIVED' ? '/archive/article/' : '/projects/article/') +
                                        data.row.original.resourceId
                                    }
                                >
                                    {data.row.original.projectTitle}
                                </Link>
                            )}
                        </>
                    );
                },
            },
            {
                Header: 'Действие',
                accessor: 'eventMessageRu',
                Cell: (data) => {
                    return data.row.original.message;
                },
            },
            {
                Header: 'Участник',
                accessor: 'user',
                Cell: (data) => {
                    return data.row.original.activeUser ? (
                        <Link className={cx.link} to={'/edit-user/' + data.row.original.login}>
                            {data.row.original.user}
                        </Link>
                    ) : (
                        data.row.original.user
                    );
                },
            },
            {
                Header: 'Дата',
                accessor: 'timestamp',
                Cell: (data) => ReportService.defaultDateColumn('timestamp')(data),
            },
        ];

        setSortByOptions(
            data
                .filter((col) => col.accessor && col.isVisible !== false && col.sort !== false)
                .map((column) => ({ label: column.Header, value: column.accessor }))
        );

        return data;
    }, []);

    const filtersTmp = useMemo(() => {
        return [
            {
                fields: [
                    {
                        labels: ['Диапазон времени', 'От', 'До'],
                        name: 'createTime',
                        type: 'date-range',
                        default: {
                            type: {
                                label: 'За сегодня',
                                value: 1,
                            },
                            from: new Date(new Date().setHours(0, 0, 0)).toISOString(),
                            to: new Date(new Date().setHours(23, 59, 59)).toISOString()
                        },
                    },
                ],
                width: '100%',
            },
            {
                label: '',
                fields: [
                    {
                        name: 'articles-group',
                        type: 'articles',
                        options: articleSearchBy,
                        default: articleSearchBy[0],
                        required: true,
                    },
                ],
                width: '100%',
            },
            {
                label: 'Участник',
                fields: [
                    {
                        name: 'users',
                        type: 'user-select',
                        default: [],
                    },
                ],
                width: '100%',
            },
            {
                label: 'Действия',
                fields: [
                    {
                        name: 'eventTypes',
                        type: 'select',
                        options: actionsTypes,
                        default: actionsTypes ? actionsTypes[0] : null,
                    },
                ],
                width: '50%',
            },
            {
                label: 'Сортировать по',
                fields: [
                    {
                        name: 'sortBy',
                        type: 'select',
                        options: sortByOptions,
                        default: {label: 'Дата', value: 'timestamp'},
                    },
                ],
                width: '25%',
            },
            {
                label: 'Порядок',
                fields: [
                    {
                        name: 'sort',
                        type: 'select',
                        options: sortTypesOptions,
                        default: sortTypesOptions[0],
                    },
                ],
                width: '25%',
            },
        ];
    }, [sortByOptions, actionsTypes]);

    useEffect(() => {
        ReportService.init('ACTIONS_IN_ARTICLES');

        return () => {
            ReportService.destroy();
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const res = await ReportService.getActionsTypes();

            setActionsTypes(res);
        };

        fetchData();
    }, []);

    return (
        <ReportsComponent
            isEmpty={!reportsTotal}
            isLoading={isLoading}
            columns={columnsTmp}
            data={reports}
            total={reportsTotal}
            filters={filtersTmp}
            onDownload={onDownload}
            onPaginate={getActionsInProjectsReports}
        />
    );
};

export default StatisticReportsActionsInArticles;
