import React, { useEffect } from 'react';
import { BubbleMenu } from '@tiptap/react';
import { findParentNodeClosestToPos, posToDOMRect } from '@tiptap/core';
import { Icons } from 'uikit/icon';
import { MenuButton } from '../Menu/MenuButton';
import DropdownList from 'uikit/dropdown-list/dropdown-list';
import { EmojiPicker } from 'uikit/emoji-picker/emoji-picker';
import { MenuButtonColorPicker } from '../Menu/MenuButtonColorPicker';
import cx from '../../editor.module.scss';
import { findParentNodeOfType } from 'prosemirror-utils';
import { BubbleExtensions, isBubbleExtensionActive } from '../../utils/isBubbleExtensionActive';
import { NodeSelection } from '@tiptap/pm/state';
import Tippy from '@tippyjs/react';

export const BubbleMenuCallout = ({ editor, mode, currentMenu, setCurrentMenu }) => {
    const [value, setValue] = React.useState('rgba(0, 0, 0, 1)');

    const emojiPickerRef = React.useRef(null);
    const colorPickerRef = React.useRef(null);

    const [isEmojiPickerOpen, setIsEmojiPickerOpen] = React.useState(false);
    const [isColorPickerOpen, setIsColorPickerOpen] = React.useState(false);

    const onEmojiClick = emoji => {
        editor.commands.updateCallout({
            'data-emoji': emoji.e,
        });
    };

    const onBackgroundChange = color => {
        setValue(color);
        editor.commands.updateCallout({
            'data-background': color,
        });
    };

    const onTypeChange = type => {
        editor.commands.updateCallout({
            type,
            'data-background': null,
            'data-emoji': null,
        });
    };

    const onEmojiPickerOpen = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        if (mode === 'MOBILE') {
            emojiPickerRef.current.showmenu(e);
        } else {
            setIsEmojiPickerOpen(prev => !prev);
            setIsColorPickerOpen(false);
        }
    };
    const onColorPickerOpen = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        if (mode === 'MOBILE') {
            colorPickerRef.current.showmenu(e);
        } else {
            setIsColorPickerOpen(prev => !prev);
            setIsEmojiPickerOpen(false);
        }
    };

    useEffect(() => {
        const onDocumentClick = () => {
            setIsEmojiPickerOpen(false);
            setIsColorPickerOpen(false);
        };

        document.addEventListener('click', onDocumentClick);

        return () => {
            document.removeEventListener('click', onDocumentClick);
        };
    }, []);

    return (
        <>
            <div style={{ display: currentMenu !== 'callout-emoji' && currentMenu !== 'callout-color' ? 'none' : 'block' }}>
                <DropdownList
                    innerRef={emojiPickerRef}
                    mode={mode}
                    name="callout-emoji"
                    currentMenu={currentMenu}
                    setCurrentMenu={setCurrentMenu}
                >
                    <EmojiPicker onClick={onEmojiClick} />
                </DropdownList>
                <DropdownList
                    innerRef={colorPickerRef}
                    mode={mode}
                    name="callout-color"
                    currentMenu={currentMenu}
                    setCurrentMenu={setCurrentMenu}
                >
                    <MenuButtonColorPicker onChange={onBackgroundChange} value={value} />
                </DropdownList>
            </div>
            <BubbleMenu
                tippyOptions={{
                    placement: 'bottom',
                    arrow: false,
                    getReferenceClientRect: () => {
                        const nearestParent = findParentNodeClosestToPos(editor?.state.selection.$anchor, node => {
                            return node.type.name === 'callout';
                        });

                        if (nearestParent) {
                            const wrapperDomNode = editor?.view.nodeDOM(nearestParent.pos);

                            if (wrapperDomNode) {
                                return wrapperDomNode.getBoundingClientRect();
                            }
                        }
                        const { ranges } = editor?.state.selection;
                        const from = Math.min(...ranges.map(range => range.$from.pos));
                        const to = Math.max(...ranges.map(range => range.$to.pos));
                        return posToDOMRect(editor?.view, from, to);
                    },
                }}
                editor={editor}
                shouldShow={({ editor }) => {
                    const isActive = isBubbleExtensionActive(editor?.state.selection, BubbleExtensions.CALLOUT);

                    if (isActive) {
                        document.querySelector('.ProseMirror').className += ' callout-active';
                    } else {
                        document.querySelector('.ProseMirror').className = document
                            .querySelector('.ProseMirror')
                            .className.replace(' callout-active', '');
                    }

                    return isActive;
                }}
            >
                <div className={cx.bubbleMenu}>
                    <MenuButton
                        className={cx.bubbleMenuButton}
                        icon={Icons.EditorIconCalloutYellow}
                        onClick={() => {
                            setCurrentMenu(undefined);
                            onTypeChange('yellow');
                        }}
                    />
                    <MenuButton
                        className={cx.bubbleMenuButton}
                        icon={Icons.EditorIconCalloutGreen}
                        onClick={() => {
                            setCurrentMenu(undefined);
                            onTypeChange('green');
                        }}
                    />
                    <MenuButton
                        className={cx.bubbleMenuButton}
                        icon={Icons.EditorIconCalloutBlue}
                        onClick={() => {
                            setCurrentMenu(undefined);
                            onTypeChange('blue');
                        }}
                    />
                    <MenuButton
                        className={cx.bubbleMenuButton}
                        icon={Icons.EditorIconCalloutPirple}
                        onClick={() => {
                            setCurrentMenu(undefined);
                            onTypeChange('pirple');
                        }}
                    />
                    <MenuButton
                        className={cx.bubbleMenuButton}
                        icon={Icons.EditorIconCalloutRed}
                        onClick={() => {
                            setCurrentMenu(undefined);
                            onTypeChange('red');
                        }}
                    />
                    <Tippy
                        placement="bottom"
                        interactive={true}
                        arrow={false}
                        visible={isEmojiPickerOpen}
                        content={(
                            <div onClick={(e) => {
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();
                            }}>
                                <EmojiPicker onClick={onEmojiClick} />
                            </div>
                        )}
                    >
                        <MenuButton
                            className={cx.bubbleMenuButton}
                            icon={Icons.EditorIconEmoji}
                            onClick={onEmojiPickerOpen}
                        />
                    </Tippy>
                    <div style={{ height: 17, width: 1, borderRight: '1px solid rgba(39, 155, 217, 0.15)' }}></div>
                    <Tippy
                        placement="bottom"
                        interactive={true}
                        arrow={false}
                        visible={isColorPickerOpen}
                        content={(
                            <div onClick={(e) => {
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();
                            }}>
                                <MenuButtonColorPicker onChange={onBackgroundChange} value={value} />
                            </div>
                        )}
                    >
                        <MenuButton
                            className={cx.bubbleMenuButton}
                            icon={Icons.EditorIconFill}
                            onClick={onColorPickerOpen}
                        />
                    </Tippy>
                    <div style={{ height: 17, width: 1, borderRight: '1px solid rgba(39, 155, 217, 0.15)' }}></div>
                    <MenuButton
                        className={cx.bubbleMenuButton}
                        icon={Icons.EditorIconTrash}
                        onClick={() => {
                            setCurrentMenu(undefined);

                            let pos;
                            let start;

                            let nodeSize;
                            let content;

                            if (editor.state.selection instanceof NodeSelection && editor.state.selection.node.type.name === 'callout') {
                                pos = editor.state.selection.from;
                                start = editor.state.selection.from;

                                nodeSize = editor.state.selection.node.nodeSize;
                                content = editor.state.selection.node.content.content
                            } else {
                                const callout = findParentNodeOfType(editor.state.schema.nodes.callout)(editor.state.selection);

                                pos = callout.pos;
                                start = callout.start;

                                nodeSize = callout.node.nodeSize;
                                content = callout.node.content.content;
                            }

                            const tr = editor.state.tr;

                            tr.replaceWith(pos, start + nodeSize, content);
                            editor.view.dispatch(tr);
                        }}
                    />
                </div>
            </BubbleMenu>
        </>
    );
};
