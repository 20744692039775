import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { descValidator, textValidator, validateFields } from 'lib/util/validators.util';
import { useCancelBehavior, useGlobalContext, useMessage } from 'lib/hooks';
import { selectUsers } from 'slice/authSlice';
import PrivatePage from 'containers/private-page-wrapper';
import Card from 'components/card';
import Input from 'uikit/input';
import Button from 'uikit/button';
import Divider from 'uikit/divider';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import Checkbox from 'uikit/checkbox/checkbox';
import Loading from 'uikit/loading';
import api from 'api';
import cx from './add-group-page.module.scss';
import { desktopStyles, mobileStyles } from 'lib/util/modalStyles';
import { setDocumentTitle, useNavigate } from 'shared/router';

const AddGroupPage = ({ hasAnyAuthorities = [] }) => {
    const navigate = useNavigate();

    const { platform, setFormDataChanged, setFormDataChangedSubmitHandler } = useGlobalContext();
    const groupRef = useRef(null)

    const selector = useSelector(state => state);

    const { cancel } = useCancelBehavior();
    const [loading, setLoading] = useState(false);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const [isModal, setIsModal] = useState(false);
    const [users, setUsers] = useState([]);

    const [filtersUsers, setFiltersUsers] = useState([]);
    const [groupMembers, setGroupMembers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const [nameError, setNameError] = useState(null);
    const [descError, setDescError] = useState(null);
    const [usersError, setUsersError] = useState(null);

    const { addSuccess, addError } = useMessage();

    useEffect(() => {
        const getUsers = async () => {
            let tmpUsers = await selectUsers(selector);

            setUsers(tmpUsers.content);
            setFiltersUsers(tmpUsers.content);
        };
        if (users.length === 0) {
            getUsers();
        }
    }, [selectedUsers, users.length, selector]);

    const selectUser = (e, user) => {
        const tmp = Object.assign([], groupMembers);
        const index = groupMembers.findIndex(p => p['id'] === user['id']);

        if (index !== -1) {
            tmp.splice(index, 1);
        } else {
            tmp.push(user);
        }

        setGroupMembers(tmp);
    };
    const isCheckedUser = (user) => {
        return groupMembers.findIndex(p => p['id'] === user['id']) !== -1;
    };

    const openModal = () => {
        setGroupMembers(selectedUsers);
        setIsModal(true);
    };
    const closeModal = () => {
        setIsModal(false);
        onUsersSearch({ target: { value: '' } });
    };

    const saveUsers = () => {
        setSelectedUsers(groupMembers);
        setIsModal(false);
        onUsersSearch({ target: { value: '' } });
    };

    const onUsersSearch = (e) => {
        const searchResult = [];
        const { target: { value } } = e;
        const valueNames = value.split(" ").filter(item => item).map((val) => val.toLowerCase());

        users.map((user) => {
            if (!value) {
                setFiltersUsers(users);
            };

            const userNames = [user['firstName']?.toLowerCase()];
            if (user['lastName']){
                userNames.push(user['lastName']?.toLowerCase())
            };

            if (
                (valueNames.length > 1
                    // todo: в будущем более гибко переписать на какой-то цикл
                    ? ((valueNames[0] === userNames[0] && valueNames[1] === userNames[1])
                        || (valueNames[0] === userNames[1] && valueNames[1] === userNames[0]))
                    : valueNames.some((name) => userNames.includes(name)))
                || (user['lastName'] && user['lastName'].toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1)
                || (user['email'] && user['email'].toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1)
            ) {
                searchResult.push(user);
            }

            return user;
        });

        setFiltersUsers(searchResult);
    };

    const createGroup = async () => {
        setFormDataChanged(false);
        let isErr = false;

        const validation = validateFields([
            { name: 'name', value: name, validator: textValidator },
            { name: 'desc', value: description, validator: textValidator }
        ]);

        if (Object.keys(validation).length > 0) {
            setNameError(validation['name']);
            setDescError(validation['desc']);
            isErr = true;
        }

        let members = [];

        for (let i = 0; i < selectedUsers.length; i++) {
            members.push(selectedUsers[i]['login']);
        }

        if (Array.isArray(members) && members.length <= 0) {
            setUsersError('Необходимо добавить участников группы');
            isErr = true;
        }

        if (isErr) {
            return;
        }

        setLoading(true);

        const response = await api.groups.createGroup({ name, description, members });

        setLoading(false);

        if (response.status === 'SUCCESS') {
            navigate('/users/groups-page/all');
            addSuccess('Группа создана');
        } else if (response.errorMessage.includes('already exists')) {
            setNameError('Группа с таким названием уже существует');
        } else {
            navigate('/users/groups-page/all');
            addError('Не удалось создать группу');
        }
    };

    useEffect(() => {
        groupRef.current = {
            name: '',
            description: ''
        }
    }, []);

    useEffect(() => {
        const { name: refName, description: refDescription } = groupRef.current;

        setFormDataChanged(refName !== name || refDescription !== description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, description]);

    useEffect(() => {
        if (selectedUsers.length > 0) {
            setUsersError(null);
        }
    }, [selectedUsers]);

    const onNameChange = (str) => {
        setNameError(null);
        setName(str);

        const err = textValidator(str);

        if (err !== '') {
            setNameError(err);
        }
    };

    const onDescChange = (str) => {
        setDescError(null);
        setDescription(str);

        const err = descValidator(str);

        if (err !== '') {
            setDescError(err);
        }
    };

    const removeUser = (user) => {
        const updatedUsers = selectedUsers.filter(item => item !== user);
        setSelectedUsers(updatedUsers)
    };

    // Сброс до инициализирующих данных при попытке уйти с формы добавления группы, нажав на тот же пункт в меню - "Группу"
    function resetToInitialValues () {
        return () => {
            setName('');
            setDescription('');
            setSelectedUsers([]);
        }
    }

    useEffect(() => {
        setFormDataChangedSubmitHandler(resetToInitialValues);
        return () => {
            setFormDataChangedSubmitHandler(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setDocumentTitle('Добавление группы участников — KMS Gran');
    }, []);

    return (
        <PrivatePage hasAnyAuthorities={hasAnyAuthorities}>
            {loading &&
            <Loading withOverlay={false} withRelativeOverlay={true}/>}
            <Modal isOpen={isModal} contentLabel={'Добавление в группу'}
                   style={platform === 'mobile' ? mobileStyles : desktopStyles}>
                <div className={classNames(cx.modalContent, {[cx.modalMobile]: platform === 'mobile'})}>
                    <div className={cx.title}>
                        <div>Добавление в группу</div>
                        <IconButton icon={<Icon width={14} height={14} type={Icons.CROSS}/>} onClick={() => {
                            closeModal();
                        }}/>
                    </div>
                    <div className={cx.searchContainer}>
                        <div className={cx.search}>
                            <div className={cx.searchButton} onClick={() => {}}>
                                <IconButton icon={<Icon type={Icons.SEARCH} width={13} height={13}/>}/>
                            </div>
                            <input type="text" placeholder={'Поиск по имени...'}
                                   onChange={(e) => onUsersSearch(e)}/>
                        </div>
                    </div>
                    <Divider style={{ marginLeft: '-24px', marginRight: '-24px', marginTop: '1rem', marginBottom: '1rem' }} />
                    <div className={cx.container}>
                        {filtersUsers.map((item, i) => (
                            <div
                                key={i}
                                className={classNames(cx.userContainer, {
                                    [cx.userContainerChecked]: isCheckedUser(item)
                                })}
                                onClick={(e) => selectUser(e, item)}
                            >
                                <Checkbox onClick={(e) => selectUser(e, item)} checked={isCheckedUser(item)}/>
                                <div className={cx.userInfo}>
                                    <img src={item['avatarUuid'] ? api.upload.getImage(item['avatarUuid'], false, 128) : '/img/avatar.png'} alt=""/>
                                    <p>{item['firstName']} {item['lastName']}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <Divider style={{ marginLeft: '-24px', marginRight: '-24px', marginTop: '1rem', marginBottom: '0' }} />
                    <div className={cx.buttons}>
                        <Button
                            label="Отмена"
                            fullWidth={platform === 'mobile'}
                            onClick={() => closeModal()}
                        />
                        <Button
                            color="green"
                            label="Отправить"
                            fullWidth={platform === 'mobile'}
                            onClick={() => saveUsers()}
                        />
                    </div>
                </div>
            </Modal>
            <div className={classNames(cx.content, {[cx.mobile]: platform === 'mobile'})}>
                <div id={'addGroupPageTitle'} className={cx.pageTitle}>
                    Добавление группы участников
                </div>
                <Card id={'addGroupCard'}>
                    <Input type='text' value={name} onChange={(str) => onNameChange(str)} label='Название группы'
                           required className={cx.field} id={'addGroupNameField'} error={nameError}/>
                    <Input type='text' value={description} onChange={(str) => onDescChange(str)} label='Описание группы'
                           required className={cx.field} id={'addGroupDescriptionField'} error={descError}/>
                    <Divider/>
                    <div className={cx.addProjectContainer}>
                        <div id={'addGroupAddUserButton'} onClick={openModal} className={cx.addButton}>
                            <Icon width={20} height={20} type={Icons.OPEND_LOCK}/>
                            <p>Добавить участника</p>
                        </div>
                        {usersError && <div className={cx.errorText}>{usersError}</div>}
                        <div className={cx.projectsContainer}>
                            {selectedUsers.map((item, i) => (<div key={i} className={cx.projectContainer}>
                                <div className={cx.projectInfo}>
                                    {item['avatar'] &&
                                    <img src={api.upload.getImage(item['avatar'], false, 128)} alt="" />}
                                    {!item['avatar'] &&
                                    <img src={item['avatarUuid'] ? api.upload.getImage(item['avatarUuid'], false, 128) : '/img/avatar.png'} alt=""/>}
                                    <p className={cx.prijectName}>{item['firstName']} {item['lastName']}</p>
                                </div>
                                <div className={cx.projectButtons}>
                                    <div className={cx.buttonsContainer}>
                                        <div className={cx.iconButton}>
                                            <IconButton icon={<Icon type={Icons.TRASH} tooltip={'Удалить'}/>}
                                                        onClick={() => removeUser(item)} hoverColor={'red'}/>
                                        </div>
                                    </div>
                                </div>
                            </div>))}
                        </div>
                    </div>
                    <Divider className={cx.divider}/>
                    <div className={cx.tool}>
                        <Button label={'Отмена'} onClick={cancel} fullWidth={platform === 'mobile'}/>
                        <Button onClick={() => createGroup()} label='Сохранить' color={'green'}
                                fullWidth={platform === 'mobile'}/>
                    </div>
                </Card>
            </div>
        </PrivatePage>
    );
};

export default AddGroupPage;
