import React from 'react';
import classNames from 'classnames';
import IconButton from 'uikit/icon-button';
import Icon, { Icons } from 'uikit/icon';
import cs from './dropdown-menu.module.scss';

const DropdownMenu = ({
    position = 'left',
    className = null,
    menuClassName = null,
    alwaysFilled = false,
    buttonId = '',
    children,
    icon,
    tooltip,
    width,
    height,
    menuStyle,
    toggler
}) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const showMenu = (event) => {
        event.preventDefault();
        document.addEventListener('click', closeMenu);

        setIsOpen(true);
    };

    const closeMenu = () => {
        setIsOpen(false);
        document.removeEventListener('click', closeMenu);
    };

    return (
        <div className={classNames(cs.container, className, { [cs.show]: alwaysFilled || isOpen })}>
            {toggler?.(isOpen) || (
                <IconButton
                    buttonId={buttonId}
                    onClick={showMenu}
                    icon={
                        <Icon
                            type={icon ? icon : Icons.MORE_CIRCLE}
                            tooltip={tooltip ? tooltip : null}
                            width={width ? width : 20}
                            height={height ? height : 20}
                        />
                    }
                />
            )}
            {isOpen && (
                <div
                    className={classNames(cs.menu, menuClassName, {
                        [cs.left]: position === 'left',
                        [cs.right]: position === 'right',
                    })}
                    style={menuStyle}
                    onClick={() => closeMenu({})}
                >
                    {children}
                </div>
            )}
        </div>
    );
};

export default DropdownMenu;
