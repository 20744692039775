import { stringToBoolean } from '../../../utils/stringToBool';
import { toPrecision } from '../utils';
import { getRow } from './utilities/getRow';

function getColgroup(table) {
    let colgroup = table.firstChild;
    let childIndex = 0;

    while (colgroup) {
        const nodeName = colgroup.nodeName;

        if (nodeName === 'COLGROUP') {
            break;
        } else if (nodeName === 'CAPTION') {
            colgroup = colgroup.nextSibling;
            childIndex++;
        } else {
            break;
        }
    }

    if (colgroup) {
        if (colgroup.nodeName !== 'COLGROUP') {
            colgroup = table.insertBefore(document.createElement('COLGROUP'), table.children[childIndex]);
        }
    } else {
        if (table.children.length === 0) {
            colgroup = table.appendChild(document.createElement('COLGROUP'));
        } else {
            colgroup = table.insertBefore(document.createElement('COLGROUP'), table.children[0]);
        }
    }

    return colgroup;
}
function getTableWidth(row, cellMinWidth, overrideCol, overrideValue) {
    let tableWidth = 0;

    let colCount = overrideCol;
    let colValue = overrideValue;

    for (let i = 0, col = 0; i < row.childCount; i += 1) {
        const { colspan, colwidth } = row.child(i).attrs;

        if (Array.isArray(overrideCol) && Array.isArray(overrideValue)) {
            const index = overrideCol.findIndex(c => c === col);

            colCount = ~index ? overrideCol[index] : colCount;
            colValue = ~index ? overrideValue[index] : colValue;
        }

        for (let j = 0; j < colspan; j += 1, col += 1) {
            const hasWidth = colCount === col ? colValue : colwidth && colwidth[j];
            tableWidth += hasWidth || cellMinWidth;
        }
    }

    return tableWidth;
}

export function updateColumns(prevNode, node, table, cellMinWidth, overrideCol, overrideValue) {
    let totalWidth = 0;
    let fixedWidth = false;

    const colgroup = getColgroup(table);

    if (!colgroup) {
        return;
    }

    let nextDOM = colgroup.firstChild;
    const row = getRow(node, 0).node;

    let colCount = overrideCol;
    let colValue = overrideValue;

    if (row) {
        let isFullWidthTable = table.getAttribute('width')?.includes('%');
        let tableWidth = getTableWidth(row, cellMinWidth, overrideCol, overrideValue);

        if (table.offsetWidth > tableWidth || isFullWidthTable) {
            tableWidth = table.offsetWidth;
        }

        for (let i = 0, col = 0; i < row.childCount; i += 1) {
            const { colspan, colwidth } = row.child(i).attrs;

            if (Array.isArray(overrideCol) && Array.isArray(overrideValue)) {
                const index = overrideCol.findIndex(c => c === col);

                colCount = ~index ? overrideCol[index] : colCount;
                colValue = ~index ? overrideValue[index] : colValue;
            }

            for (let j = 0; j < colspan; j += 1, col += 1) {
                const hasWidth = colCount === col ? colValue : colwidth && colwidth[j];
                let cssWidth = '';

                if (!stringToBoolean(node.attrs['data-responsive'])) {
                    cssWidth = hasWidth ? `${toPrecision((hasWidth / tableWidth) * 100)}%` : `${cellMinWidth}px`;
                }

                totalWidth += hasWidth || cellMinWidth;

                if (hasWidth) {
                    fixedWidth = true;
                }

                if (!nextDOM) {
                    colgroup.appendChild(document.createElement('col')).style.width = cssWidth;
                } else {
                    if (
                        stringToBoolean(prevNode.attrs['data-responsive']) !== stringToBoolean(node.attrs['data-responsive']) &&
                        stringToBoolean(node.attrs['data-responsive'])
                    ) {
                        nextDOM.style.width = null;
                    } else if (nextDOM.style.width !== cssWidth) {
                        nextDOM.style.width = cssWidth;
                    }

                    nextDOM = nextDOM.nextSibling;
                }
            }
        }
    }

    while (nextDOM) {
        const after = nextDOM.nextSibling;

        nextDOM?.parentNode?.removeChild(nextDOM);
        nextDOM = after;
    }

    if (!stringToBoolean(node.attrs['data-responsive'])) {
        table.style.tableLayout = 'fixed';
    } else {
        table.style.tableLayout = '';
    }
    if (fixedWidth) {
        table.style.width = node.attrs.width?.includes('%') ? node.attrs.width : totalWidth + 'px';
        table.style.minWidth = '';
    } else {
        table.style.width = node.attrs.width || '';
        table.style.minWidth = totalWidth + 'px';
    }

    for (let attr in node.attrs) {
        table.setAttribute(attr, node.attrs[attr]);
    }
}

export class TableView {
    node;
    cellMinWidth;
    dom;
    table;
    contentDOM;

    constructor(node, cellMinWidth) {
        this.node = node;
        this.cellMinWidth = cellMinWidth;

        this.tableContainer = document.createElement('div');
        this.tableContainer.className = 'tableContainer';

        this.tableWrapper = document.createElement('div');
        this.tableWrapper.className = 'tableWrapper';

        this.tableOuterWrapper = document.createElement('div');
        this.tableOuterWrapper.className = 'tableOuterWrapper';

        this.tableWrapper = document.createElement('div');
        this.tableWrapper.className = 'tableWrapper';

        this.dom = document.createElement('div');
        this.dom.className = 'tableActionsWrapper';

        this.dom.append(this.tableContainer);
        this.tableContainer.append(this.tableOuterWrapper);

        this.tableOuterWrapper.append(this.tableWrapper);
        this.table = document.createElement('table');

        this.contentDOM = this.tableWrapper.appendChild(this.table);
        updateColumns(this.node, node, this.table, cellMinWidth);
    }

    update(node) {
        if (node.type !== this.node.type) {
            return false;
        }

        this.tableWrapper.classList.remove('align-' + this.node.attrs['data-align']);
        this.tableWrapper.classList.add('align-' + node.attrs['data-align']);

        updateColumns(this.node, node, this.table, this.cellMinWidth);

        this.node = node;
        return true;
    }

    ignoreMutation(record) {
        const table = this.contentDOM;
        const colgroup = getColgroup(table);

        return record.type === 'attributes' && (record.target === table || (colgroup && colgroup.contains(record.target)));
    }
}
