import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Schema, DOMParser as ProseMirrorDOMParser, DOMSerializer } from '@tiptap/pm/model';
import { copyTableWhenAllCellsSelected } from '../Table/extension-table/utilities/copyTableWhenAllCellsSelected';
import pasteRtfImages from '../../extensions/CopyPaste/PasteRtfImages';
import pasteFromWord from '../../extensions/CopyPaste/PasteFromWord';
import { pasteLink } from './PasteLink';

const TEXT_FORMAT = 'text/plain';
const HTML_FORMAT = 'text/html';
const wrapMap = {
    thead: ['table'],
    tbody: ['table'],
    tfoot: ['table'],
    caption: ['table'],
    colgroup: ['table'],
    col: ['table', 'colgroup'],
    tr: ['table', 'tbody'],
    td: ['table', 'tbody', 'tr'],
    th: ['table', 'tbody', 'tr'],
};
const replacements = [
    {
        tag: 'h1',
        replacement: 'p',
    },
    {
        tag: 'h2',
        replacement: 'p',
    },
    {
        tag: 'h3',
        replacement: 'p',
    },
    {
        tag: 'h4',
        replacement: 'p',
    },
    {
        tag: 'h5',
        replacement: 'p',
    },
    {
        tag: 'h6',
        replacement: 'p',
    },
    {
        tag: 'a',
        replacement: 'span',
    },
    {
        tag: 'i',
        replacement: 'span',
    },
    {
        tag: 'b',
        replacement: 'span',
    },
    {
        tag: 'u',
        replacement: 'span',
    },
    {
        tag: 'strong',
        replacement: 'span',
    },
    {
        tag: 'font',
        replacement: 'span',
    },
    {
        tag: 'ul',
        replacement: 'p',
    },
    {
        tag: 'ol',
        replacement: 'p',
    },
    {
        tag: 'dl',
        replacement: 'p',
    },
    {
        tag: 'li',
        replacement: 'p',
    },
    {
        tag: 'dd',
        replacement: 'p',
    },
    {
        tag: 'dt',
        replacement: 'p',
    },
    {
        tag: 'th',
        replacement: 'td',
    },
];

const applyInline = (element, recursive = true, sheets) => {
    let node = element;

    if (!node) {
        throw new Error('No element specified.');
    }

    if (sheets) {
        try {
            const matches = matchRules(node, sheets);
            const srcRules = document.createElement(node.tagName).style;

            srcRules.cssText = node.style?.cssText;
            matches.forEach(rule => {
                for (const prop of rule.style) {
                    let val = srcRules.getPropertyValue(prop) || rule.style.getPropertyValue(prop);
                    let priority = rule.style.getPropertyPriority(prop);

                    node.style.setProperty(prop, val, priority);
                }
            });
        } catch {

        }
    }

    if (recursive) {
        node.children.forEach(child => {
            applyInline(child, recursive, sheets);
        });
    }
};
function matchRules(el, sheets) {
    sheets = sheets || document.styleSheets;
    const response = [];

    for (let i in sheets) {
        if (sheets.hasOwnProperty(i)) {
            const rules = sheets[i].rules || sheets[i].cssRules;

            for (let r in rules) {
                try {
                    if (rules[r].selectorText && el?.matches(rules[r].selectorText)) {
                        response.push(rules[r]);
                    }
                } catch {
                    // This catch need if styles have not valid selector;
                }
            }
        }
    }

    return response;
}

const mergeDecorators = body => {
    const parentElement = [];

    body.querySelectorAll('span, strong').forEach(element => {
        if (element.parentElement.tagName === 'SPAN' || element.parentElement.tagName === 'STRONG') {
            return;
        }

        parentElement.push(element);
    });

    parentElement.forEach(element => {
        while (true) {
            if (
                element.children.length > 1 ||
                !element.children[0] ||
                element.textContent ||
                (element.children[0].tagName !== 'SPAN' && element.children[0].tagName !== 'STRONG')
            ) {
                break;
            }

            element.style = element.style.cssText + ';' + element.children[0].style.cssText;

            if (element.children[0].tagName === 'STRONG') {
                element.style.fontWeight = 600;
            }

            element.innerHTML = element.children[0].innerHTML;
        }
    });
};
const pixelsToPoints = body => {
    body.querySelectorAll('*').forEach(node => {
        if (node.style.fontSize && node.style.fontSize.indexOf('px') !== -1) {
            node.style.fontSize = +parseFloat(node.style.fontSize) * 0.75 + 'pt';
        }
    });
};

function combineStyleSheetsUnique(targetSheet, sheetsToMerge) {
    const uniqueRules = new Set();

    for (const sheet of sheetsToMerge) {
        try {
            if (sheet.cssRules) {
                for (const rule of sheet.cssRules) {
                    if (!uniqueRules.has(rule.cssText)) {
                        uniqueRules.add(rule.cssText);
                        targetSheet.insertRule(rule.cssText, targetSheet.cssRules.length);
                    }
                }
            }
        } catch (error) {
            console.error("Ошибка при обработке стиля:", error);
        }
    }
}
export const parseHTML = (schema, html) => {
    const parser = new DOMParser();

    const startTag = '<body>';
    const endTag = '</body>';

    const { body } = parser.parseFromString(startTag + html + endTag, 'text/html');

    const styles = body.getElementsByTagName('style');
    const sheet = new CSSStyleSheet();

    if (styles) {
        const sheets = [];

        styles.forEach(style => {
            sheets.push(style.sheet);
        });

        combineStyleSheetsUnique(sheet, sheets);
    }

    mergeDecorators(body);
    pixelsToPoints(body);

    applyInline(body, true, [sheet]);
    return { document: ProseMirrorDOMParser.fromSchema(schema).parse(body) };
};
const getContentGeneratorName = content => {
    const metaGeneratorTag = /<meta\s+name=["']?generator["']?\s+content=["']?(\w+)/gi;
    const result = metaGeneratorTag.exec(content);

    let generatorName;

    if (!result || !result.length) {
        return;
    }

    generatorName = result[1].toLowerCase();

    if (generatorName.indexOf('microsoft') === 0) {
        return 'microsoft';
    }

    if (generatorName.indexOf('libreoffice') === 0) {
        return 'libreoffice';
    }

    // eslint-disable-next-line no-useless-escape
    const wordRegexp = /(class="?Mso|style=["'][^"]*?\bmso\-|w:WordDocument|<o:\w+>|<\/font>)/;

    if (wordRegexp.test(content)) {
        return 'microsoft';
    }

    return 'unknown';
};

export const CopyPaste = Extension.create({
    name: 'copyPaste',
    addOptions() {
        return {
            initId: null,
            onUpload: null,
        };
    },
    addStorage() {
        return {
            initId: null,
        };
    },
    addCommands() {
        return {
            execPasteTest: (data, pasteRaw) => () => {
                const textContent = data.text;
                let htmlContent = data.html;

                const rtfContent = data.rtf;
                const items = Array.from(data.items || []);

                if (!htmlContent && textContent) {
                    htmlContent = pasteLink(textContent);
                }

                if (!htmlContent) {
                    for (const item of items) {
                        if (['image/gif', 'image/jpeg', 'image/png'].includes(item.type)) {
                            const position = this.editor.state.selection.$head;

                            this.options.onUpload(item.data, this.editor.storage.copyPaste.initId).then(attrs => {
                                this.editor.chain().focus().setResizableImage(attrs, position, { updateSelection: false }).run();
                            });

                            return true;
                        }
                    }
                }

                const contentGeneratorName = getContentGeneratorName(htmlContent);
                let content = htmlContent.replace(/<br \/>/gm, '<p></p>');

                if (contentGeneratorName === 'microsoft') {
                    content = pasteFromWord(content);
                }

                content = pasteRtfImages(content, rtfContent);
                const hasHTML = !!htmlContent;

                if (hasHTML) {
                    const parser = new DOMParser();
                    const htmlDom = parser.parseFromString(content, 'text/html');

                    if (pasteRaw) {
                        htmlDom.querySelectorAll('*').forEach(element => {
                            element.removeAttribute('style');
                            element.removeAttribute('class');

                            element.removeAttribute('color');
                            element.removeAttribute('bgcolor');

                            element.removeAttribute('lang');
                            element.removeAttribute('href');
                        });

                        replacements.forEach(p => {
                            htmlDom.querySelectorAll(p.tag).forEach(element => {
                                const replacement = document.createElement(p.replacement);

                                for (let i = 0, l = element.attributes.length; i < l; ++i) {
                                    const nodeName = element.attributes.item(i).nodeName;
                                    const nodeValue = element.attributes.item(i).nodeValue;

                                    replacement.setAttribute(nodeName, nodeValue);
                                }

                                replacement.innerHTML = element.innerHTML;
                                element.parentNode.replaceChild(replacement, element);
                            });
                        });
                    }

                    if (!pasteRaw) {
                        // Save styles from font to child elements. Because prose mirror remove font elements;
                        htmlDom.querySelectorAll('font').forEach(font => {
                            let child = font;

                            while (child.tagName === 'FONT') {
                                if (child.firstElementChild === null) {
                                    if (child.textContent) {
                                        const item = document.createElement('span');
                                        item.textContent = child.textContent;

                                        child.textContent = '';
                                        child.appendChild(item);

                                        child = item;
                                        continue;
                                    }

                                    return;
                                }

                                child = child.firstElementChild;
                            }

                            if (font.hasAttribute('color')) {
                                child.style.color = font.getAttribute('color');
                            }

                            if (font.hasAttribute('face')) {
                                child.style.fontFamily = font.getAttribute('face');
                            }

                            child.style.cssText += font.style.cssText;
                        });

                        // Remove go back element which exist in some word documents;
                        htmlDom.querySelectorAll('[name="_GoBack"]').forEach(element => {
                            element.remove();
                        });
                    }

                    // Check table border;
                    htmlDom.querySelectorAll('table').forEach(table => {
                        if (table.getAttribute('border') === '1') {
                            return;
                        }

                        let hasBorder = false;

                        table.querySelectorAll('td').forEach(p => {
                            if (p.style.cssText.includes('border')) {
                                hasBorder = true;
                            }
                        });

                        if (hasBorder) {
                            table.setAttribute('border', '1');
                        }
                    });

                    // Updated table border to default;
                    // htmlDom.querySelectorAll('th, td').forEach(td => {
                    //     td.style.borderColor = '#D8D8D8';
                    // });

                    // Update image sizes;
                    htmlDom.querySelectorAll('img').forEach(img => {
                        if (img.parentElement.nodeName === 'FONT') {
                            img.parentElement.replaceWith(img);
                        }
                        if (img.parentElement.nodeName === 'P') {
                            img.parentElement.replaceWith(img);
                        }
                        if (!img.getAttribute('width')) {
                            img.setAttribute('width', 'auto');
                        }

                        if (!img.getAttribute('height')) {
                            img.setAttribute('height', 'auto');
                        }
                    });

                    htmlDom.querySelectorAll('*').forEach(p => {
                        if (!p.hasAttribute('align')) {
                            return;
                        }

                        p.style.textAlign = p.getAttribute('align');
                    });

                    content = htmlDom.documentElement.innerHTML;
                }

                return this.editor.commands.pasteContent(hasHTML ? content.replace('/<br>/gm', '') : textContent, !hasHTML);
            },
            pasteContent: (content = '') => () => {
                const { editor } = this;

                const schema = new Schema({ ...this.editor.schema.spec });
                const promise = Promise.resolve(parseHTML(schema, content, this.options.initId));

                promise.then(async ({ document }) => {
                    if (!document) {
                        return;
                    }

                    const { firstChild, childCount } = document.content;
                    const toPaste = childCount === 1 && firstChild.type.name === 'paragraph'
                        ? firstChild.content : document.content;

                    editor
                        .chain()
                        .focus()
                        .deleteSelection()
                        .insertContent(toPaste.toJSON(), { updateSelection: false })
                        .run();
                });

                return true;
            },
        };
    },
    addProseMirrorPlugins() {
        const handleCutAndCopy = async (view, event) => {
            copyTableWhenAllCellsSelected({ editor: this.editor });

            const sel = this.editor.state.selection;

            let context = [];
            let { content, openStart, openEnd } = sel.content();

            while (openStart > 1 && openEnd > 1 && content.childCount === 1 && content.firstChild.childCount === 1) {
                openStart--;
                openEnd--;
                let node = content.firstChild;
                context.push(node.type.name, node.attrs !== node.type.defaultAttrs ? node.attrs : null);
                content = node.content;
            }

            let serializer = view.someProp('clipboardSerializer') || DOMSerializer.fromSchema(view.state.schema);
            let doc = document.implementation.createHTMLDocument('title');
            const wrap = doc.createElement('div');
            wrap.appendChild(serializer.serializeFragment(content, { document: doc }));

            let firstChild = wrap.firstChild,
                needsWrap,
                wrappers = 0;
            while (firstChild && firstChild.nodeType === 1 && (needsWrap = wrapMap[firstChild.nodeName.toLowerCase()])) {
                for (let i = needsWrap.length - 1; i >= 0; i--) {
                    let wrapper = doc.createElement(needsWrap[i]);
                    while (wrap.firstChild) wrapper.appendChild(wrap.firstChild);
                    wrap.appendChild(wrapper);
                    wrappers++;
                }
                firstChild = wrap.firstChild;
            }

            if (wrap.firstChild && wrap.firstChild.nodeType === 1)
                wrap.firstChild.setAttribute(
                    'data-pm-slice',
                    `${openStart} ${openEnd}${wrappers ? ` -${wrappers}` : ''} ${JSON.stringify(context)}`
                );
            wrap.innerHTML = wrap.innerHTML.replace(/<p><\/p>/gm, '<br />');
            if (wrap.innerText === wrap.innerHTML) {
                wrap.innerHTML = `<p>${wrap.innerHTML}</p>`;
            }

            event.clipboardData.setData(TEXT_FORMAT, wrap.innerText);
            event.clipboardData.setData(HTML_FORMAT, wrap.innerHTML);

            const blobHtml = new Blob([wrap.innerHTML], { type: HTML_FORMAT });
            const blobText = new Blob([wrap.innerText], { type: TEXT_FORMAT });

            const { ClipboardItem } = window;

            try {
                await navigator.clipboard.write([new ClipboardItem({ [HTML_FORMAT]: blobHtml, [TEXT_FORMAT]: blobText })]);
            } catch (ex) {
                console.log(ex);
            }

            event.preventDefault();
            event.stopPropagation();

            return true;
        };
        const handleCopyCodeBlock = async (event) => {

            event.preventDefault();

            const selectedText = window.getSelection()?.toString() || '';

            if (selectedText) {

                if (event.clipboardData) {
                    event.clipboardData.setData(TEXT_FORMAT, selectedText);
                }

                if (navigator.clipboard) {
                    try {
                        await navigator.clipboard.write(
                            [
                                new window.ClipboardItem({ [TEXT_FORMAT]: new Blob([selectedText], { type: TEXT_FORMAT }) })
                            ]
                        );
                    }
                    catch (ex) {
                        console.log(ex);
                    }
                }
            }

            return true;
        }

        return [
            new Plugin({
                key: new PluginKey('copyPaste'),
                props: {
                    handleDOMEvents: {
                        copy: (view, event) => {
                            switch(view.state.selection.$from.parent.type.name) {
                                case 'codeBlock':
                                    handleCopyCodeBlock(event);
                                    break;
                                default:
                                    handleCutAndCopy(view, event);
                            }

                            return true;
                        },
                        cut: (view, event) => {
                            handleCutAndCopy(view, event);
                            this.editor.commands.deleteSelection();

                            return true;
                        },
                    },
                },
            }),
        ];
    },
});
