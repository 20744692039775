import api from 'api/index';
import { normalizeSpaces } from '../../lib/helpers/normalizeSpaces';

export class ProjectFormModel {

    id = null;
    title = '';
    description = '';
    createTime = null;
    author = null;
    croppableUploadAvatar = null;
    errors = {
        title: null,
        titleExists: null,
        description: null
    };
    bindHandler = null;
    touched = {};

    constructor(project) {
        if (project) {
            this.id = project.id;
            this.title = project.title;
            this.description = project.description;
            this.createTime = project.createTime;
            this.author = project.author;
            this.logoUuid = project.logoUuid;
        }
    }

    copy() {
        return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
    }

    getParentsIds() {
        return this.id ? [this.id] : [];
    }

    applyTreeChanges(tree) {
        if (tree) {
            const thisNode = tree.getNodeById(this.id);
            tree.updateNode(thisNode, { name: this.title, logoUuid: this.logoUuid, smallLogoUuid: this.logoUuid });
        }
    }

    getFormData() {
        const data = {};

        if (this.id) {
            data.id = this.id;
        }

        data.title = normalizeSpaces(this.title?.trim());
        data.description = normalizeSpaces(this.description?.trim());

        data.logoType = this.croppableUploadAvatar !== null && this.croppableUploadAvatar.length > 0 ?
            this.croppableUploadAvatar.split(';')[0].split(':')[1] : null;

        data.logo = this.croppableUploadAvatar !== null ?
                this.croppableUploadAvatar.length > 0 ? this.croppableUploadAvatar.split(',')[1] : ''
            : null;

        if (this.createTime) {
            data.createTime = this.createTime;
        }

        return data;
    }

    getFormImage() {
        if (this.croppableUploadAvatar) {
            return this.croppableUploadAvatar;
        } else if (this.logoUuid) {
            return api.upload.getImage(this.logoUuid, false, 0);
        } else {
            return '/img/default-icon-project.jpg';
        }
    }
}
