import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useCancelBehavior, useGlobalContext } from 'lib/hooks';
import cs from './content-management-form.module.scss';
import Card from 'components/card';
import Button from 'uikit/button';
import Divider from 'uikit/divider';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import api from 'api/index';
import Input from 'uikit/input/input';
import { useNavigate } from 'shared/router';
import { SelectionUsers } from 'components/selection-users';

const ContentManagementForm = ({ resource, children }) => {

    const { platform } = useGlobalContext();
    const { cancel } = useCancelBehavior();
    const navigate = useNavigate();

    const [isModal, setIsModal] = useState(false);

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [search, setSearch] = useState([]);
    const [filtersActiveUsers, setFiltersActiveUsers] = useState([]);

    const [managersRemove, setManagersRemove] = useState([]);
    const [managers, setManagers] = useState([]);

    const closeModal = () => {
        setIsModal(false);
    };

    useEffect(() => {

        const _selectedUsers = Array.from(selectedUsers, el => el.login);
        const _managers = Array.from(managers, el => el.login);

        const result = _selectedUsers.filter(el => !_managers.includes(el));

        resource.users = result;
        resource.commit();
    }, [selectedUsers, managers, resource]);

    useEffect(() => {
        const getData = async () => {
            const res = await api.errorReportResource.findErrorReportManagers(resource.id);
            setManagers(res.users);
            setSelectedUsers(res.users);
        };

        if (resource.id) {
            getData();
        }
    }, [resource.id]);

    const onChangeUsers = (data) => {
        const tmp = JSON.stringify(data);
        setSelectedUsers(JSON.parse(tmp));
        setIsModal(false);
    };

    const getUsers = () => {
        if (search.length === 0) {
            return selectedUsers;
        } else {
            return filtersActiveUsers;
        }
    }

    const selectUser = (user) => {
        for (let i = 0; i < selectedUsers.length; i++) {
            if (selectedUsers[i]['id'] === user['id']) {
                selectedUsers.splice(i, 1);
                const tmp = JSON.stringify(selectedUsers);
                setSelectedUsers(JSON.parse(tmp));
                if (search.length !== 0) {
                    onUsersSearch(search);
                }
                return;
            }
        }

        selectedUsers.push(user);
        const tmp = JSON.stringify(selectedUsers);
        setSelectedUsers(JSON.parse(tmp));
    };

    const addRemoveManager = (user) => {
        const _managersRemove = managersRemove;
        const element = managers.find((element) => user.id === element.id);

        if (element && !managersRemove.find((element) => user.id === element.id)) {
            _managersRemove.push(element);
            setManagersRemove(_managersRemove);
        }

        resource.managersRemove = Array.from(_managersRemove, el => el.login);
        resource.commit();

        selectUser(user);
    }

    const deleteRemoveManager = (users) => {
        const _managersRemove = managersRemove;

        for (let i = 0; i < users.length; i++) {
            const user = users[i];

            const element = _managersRemove.findIndex((element) => user.id === element.id);
            if (element) {
                _managersRemove.splice(element, 1);
            }
        }

        resource.managersRemove = Array.from(_managersRemove, el => el.login);
        resource.commit();

        setManagersRemove(_managersRemove);

        onChangeUsers(users);
    }

    const onUsersSearch = (e) => {
        setSearch(e);
        const searchResult = [];

        selectedUsers.map((user) => {
            if (!e) {
                setFiltersActiveUsers(selectedUsers);
            }

            if (((user['firstName'] && user['firstName'].toLowerCase().indexOf(e.toLowerCase()) !== -1)
                || (user['lastName'] && user['lastName'].toLowerCase().indexOf(e.toLowerCase()) !== -1)
                || (user['email'] && user['email'].toLowerCase().indexOf(e.toLowerCase()) !== -1))) {
                searchResult.push(user);
            }

            return user;
        });

        setFiltersActiveUsers(searchResult);
    };


    return (
        <div className={classNames(cs.content, {[cs.mobile]: platform === 'mobile'})}>

            <SelectionUsers close={closeModal}  onChange={deleteRemoveManager} isOpen={isModal} value={() => {
                const tmp = JSON.stringify(selectedUsers);
                return JSON.parse(tmp);
            }}/>

            <Card id={'contentManagementProjectFormCard'}>

                <div className={cs.titleBlock}>Список ответственных участников</div>


                <div className={cs.input}>
                    <Input id="themeFormTittle" name="title" type="text"
                           placeholder={'Поиск...'}
                           icon={Icons.SEARCH}
                           value={search} required onChange={(str) => {
                        onUsersSearch(str)
                    }}
                    />
                </div>

                <div id={'addGroupAddUserButton'} onClick={() => setIsModal(true)} className={cs.addButton}>
                    <Icon width={20} height={20} type={Icons.ADD_CIRCLE} color={'blue'}/>
                    <p>Добавить участника вручную</p>
                </div>

                <div className={cs.projectsContainer}>
                    {getUsers().map((item, i) => (<div key={i} className={cs.projectContainer}>
                        <div className={cs.projectInfo} onClick={() => navigate('/edit-user/' + item.login) }>
                            {item['avatar'] ? <img src={api.user.getUserSmallLogo(item['avatar'])} alt="" /> : item['avatarUuid'] ? <img src={api.user.getLogo(item['avatarUuid'])} alt="" /> : <img src="/img/avatar.png" alt="" />}
                            <p className={cs.projectName}>{item['firstName']} {item['lastName']}</p>
                        </div>
                        <div className={cs.projectButtons}>
                            <div className={cs.buttonsContainer}>
                                <div className={cs.iconButton}>
                                    <IconButton icon={<Icon type={Icons.EYE} tooltip={'Удалить'}/>}
                                                onClick={() => navigate('/edit-user/' + item.login) } hoverColor={'blue'}/>
                                </div>
                                <div className={cs.iconButton}>
                                    <IconButton icon={<Icon type={Icons.TRASH} tooltip={'Удалить'}/>}
                                                onClick={() => {addRemoveManager(item)}} hoverColor={'red'}/>
                                </div>
                            </div>
                        </div>
                    </div>))}
                </div>

                <Divider className={cs.divider}/>
                <div className={cs.tool} >
                    <Button label={'Отмена'} onClick={cancel} fullWidth={platform === 'mobile'}/>
                    {children}
                </div>
            </Card>
        </div>
    );
};

export default ContentManagementForm;
