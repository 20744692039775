import React, { useContext } from 'react';
import { RouterContext } from '../routerProvider';
import { RenderOutletProps } from '../routers/router';

export const Outlet = (props: RenderOutletProps): React.ReactNode => {
    const context = useContext(RouterContext);

    if (!context) {
        throw new Error('Outlet must be used within a RouterProvider');
    }

    return context.router.getOutlet(props);
};
