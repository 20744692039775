import React, { useState, useEffect } from 'react';
import { useNavigate } from 'shared/router';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDialog, useInterval } from 'lib/hooks';
import Confirmation from 'components/confirmation';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import Input from 'uikit/input';
import Checkbox from 'uikit/checkbox';
import { Select } from 'uikit/select';
import Button from 'uikit/button';
import cx from './training-test-question.module.scss';
import api from 'api/index';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);
    return result;
};

export default function TrainingTestQuestion({ testId, questionId }) {
    const navigate = useNavigate();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [test, setTest] = useState(null);
    const [question, setQuestion] = useState(null);

    const [storage, setStorage] = useState(null);
    const [time, setTime] = useState(Date.now());

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        onAnswer(reorder(question['answers'], result['source']['index'], result['destination']['index']));
    };
    const onAnswer = (answer, option) => {
        const newQuestion = Object.assign({}, question);

        if (question['type'] === 0) {
            const answerIndex = newQuestion['answers'].indexOf(answer);
            newQuestion['answers'][answerIndex]['isChecked'] = !answer['isChecked'];
        } else if (question['type'] === 1) {
            for (let i = 0; i < newQuestion['answers'].length; i++) {
                newQuestion['answers'][i]['isChecked'] = false;
            }

            const answerIndex = newQuestion['answers'].indexOf(answer);
            newQuestion['answers'][answerIndex]['isChecked'] = true;
        } else if (question['type'] === 2) {
            newQuestion['answer'] = answer.target.value;
        } else if (question['type'] === 3) {
            newQuestion['answer'] = answer;
        } else if (question['type'] === 4) {
            const answerIndex = newQuestion['answers'].indexOf(answer);
            newQuestion['answers'][answerIndex]['answer'] = option;
        } else if (question['type'] === 5) {
            newQuestion['answers'] = answer;
        }

        setQuestion(newQuestion);
    };

    const onTestClose = () => {
        openDialog({
            color: 'red',
            title: 'Выход из теста',
            text: 'Вы уверены, что хотите выйти из теста? Попытка прохождения будет засчитана, а весь несохраненный прогресс будет отменен.',
            closeBtnText: 'Нет, закрыть',
            submitBtnText: 'Да, выйти',
            onSubmit: () => {
                localStorage.removeItem('test');
                navigate('/training');
            },
            onClose: closeDialog,
        });
    };

    const canPrevious = () => {
        return +questionId !== 1 && test['type'] !== 0;
    };
    const onPrevious = () => {
        navigate('/training/test/' + testId + '/process/' + (+questionId - 1));
    };

    const canNext = () => {
        return +questionId !== test['questions'].length && (test['type'] !== 0
            || (((question['type'] === 0 || question['type'] === 1)
                    && question['answers'].filter(p => p.isChecked).length !== 0)
                || ((question['type'] === 2 || question['type'] === 3) && question['answer'])
                || (question['type'] === 4
                    && question['answers'].filter(p => p['answer']).length === question['answers'].length)
                || question['type'] === 5));
    };
    const onNext = () => {
        const newStorage = Object.assign({}, storage);

        if (question['type'] === 2 || question['type'] === 3) {
            newStorage['questions'][questionId - 1]['answer'] = question['answer'];
        } else {
            newStorage['questions'][questionId - 1]['answer'] = question['answers'];
        }

        newStorage['questions'][questionId]['start'] = Date.now();
        newStorage['questions'][questionId - 1]['finish'] = Date.now();

        setStorage(newStorage);

        localStorage.setItem('test', JSON.stringify(newStorage));
        navigate('/training/test/' + testId + '/process/' + (+questionId + 1));
    };

    const canEnd = () => {
        return +questionId === test['questions'].length
            && (((question['type'] === 0 || question['type'] === 1)
                    && question['answers'].filter(p => p.isChecked).length !== 0)
                || ((question['type'] === 2 || question['type'] === 3) && question['answer'])
                || (question['type'] === 4
                    && question['answers'].filter(p => p['answer']).length === question['answers'].length)
                || question['type'] === 5);
    };
    const onEnd = () => {
        const newStorage = Object.assign({}, storage);

        if (question['type'] === 2 || question['type'] === 3) {
            newStorage['questions'][questionId - 1]['answer'] = question['answer'];
        } else {
            newStorage['questions'][questionId - 1]['answer'] = question['answers'];
        }

        newStorage['questions'][questionId - 1]['finish'] = Date.now();
        newStorage['finish'] = Date.now();

        setStorage(newStorage);

        localStorage.setItem('test', JSON.stringify(newStorage));

        if (+questionId !== test['questions'].length || newStorage.questions.filter(p => p['answer'] === null).length !== 0) {
            openDialog({
                color: 'red',
                title: 'Завершить тестирование',
                text: 'У вас остались неотвеченные вопросы, вы уверены что хотите завершить тестирование? Вопросы без ответа будут засчитаны как неправильные.',
                closeBtnText: 'Нет, закрыть',
                submitBtnText: 'Да, завершить',
                onSubmit: () => navigate('/training/test/' + testId + '/process/end'),
                onClose: closeDialog,
            });

            return;
        }

        navigate('/training/test/' + testId + '/process/end');
    };

    useInterval(() => {
        const currentTime = Date.now();

        if (test['testTime'] > 0 && (storage['start'] + test['testTime'] * 60000) - currentTime <= 0) {
            localStorage.removeItem('test');
            navigate('/training');
        }

        if (test['questionTime'] > 0 && (storage['questions'][questionId - 1]['start'] + test['questionTime'] * 60000) - currentTime <= 0) {
            storage['questions'][questionId]['start'] = currentTime;
            setStorage(storage);

            localStorage.setItem('test', JSON.stringify(storage));
            navigate('/training/test/' + testId + '/process/' + (+questionId + 1));
        }

        setTime(currentTime);
    }, 1000);

    useEffect(() => {
        const fetchData = async () => {
            const response = await api.training.getTest(testId);
            setTest(response);

            const savedTest = JSON.parse(localStorage.getItem('test'));

            if (!savedTest || (response['testTime'] && savedTest['start'] + response['testTime'] * 60000 < Date.now())) {
                localStorage.removeItem('test');
                navigate('/training');

                return;
            }

            setStorage(savedTest);
        };
        fetchData();
    }, [navigate, testId]);
    useEffect(() => {
        if (!test) {
            return;
        }

        const savedTest = JSON.parse(localStorage.getItem('test'));
        const currentQuestionId = savedTest['questions'].filter(p => p['start'] !== null).length;

        if (test['type'] === 0 && +questionId !== currentQuestionId) {
            navigate('/training/test/' + testId + '/process/' + currentQuestionId);
        }

        const currentQuestion = test['questions'].find(p => p['id'] === savedTest['questions'][questionId - 1]['id']);

        currentQuestion['answers'] = savedTest['questions'][questionId - 1]['answers'];
        currentQuestion['answer'] = null;

        setQuestion(currentQuestion);
    }, [navigate, testId, test, questionId]);

    if (question === null || storage === null) {
        return null;
    }

    return (
        <div className={cx.trainingTestQuestion}>
            <Confirmation {...dialogState}/>
            <div className={cx.trainingTestQuestionHead}>
                <div className={cx.trainingTestQuestionHeadLeft}>
                    <div className={cx.trainingTestQuestionHeadLeftContainer}>
                        <h3>{test['title']}</h3>
                        <p>{test['questions'].length} вопросов</p>
                    </div>
                    <Button color="red" onClick={() => onTestClose()}>Выйти из теста</Button>
                </div>
                {test['testTime'] > 0 &&
                <div className={cx.trainingTestQuestionHeadRight}>
                    <Icon type={Icons.TIME} width={24} height={24}/>
                    <div className={cx.trainingTestQuestionHeadRightContainer}>
                        <span>Оставшееся время:</span>
                        <p>{new Date((storage['start'] + test['testTime'] * 60000) - time).toISOString().substr(11, 8)}</p>
                    </div>
                </div>}
            </div>
            <div className={cx.trainingTestQuestionBody}>
                <div className={cx.trainingTestQuestionBodyLeft}>
                    <div className={cx.trainingTestQuestionBodyLeftTop}>
                        <p className={cx.trainingTestQuestionBodyLeftCounter}>
                            ВОПРОС {questionId} ИЗ {test['questions'].length}
                        </p>
                        {test['questionTime'] > 0 &&
                        <div className={cx.trainingTestQuestionBodyLeftTimer}>
                            <Icon type={Icons.TIME} width={16} height={16}/>
                            <span>{new Date((storage['questions'][questionId - 1]['start'] + test['questionTime'] * 60000) - time).toISOString().substr(11, 8)}</span>
                        </div>}
                        {question['image'] && <img src={question['image']} alt={question['title']}/>}
                        <h3>{question['title']}</h3>
                    </div>
                    <div className={cx.trainingTestQuestionBodyLeftBottom}>
                        {question['type'] === 0 &&
                        <div className={cx.trainingTestQuestionBodyLeftBottomMany}>
                            <p className={cx.trainingTestQuestionBodyLeftBottomDescription}>
                                Выберите несколько правильных ответов:
                            </p>
                            <ul>
                                {question['answers'].map((answer, i) => {
                                    return (
                                        <li key={i}>
                                            <Checkbox checked={answer['isChecked']} onChange={() => onAnswer(answer)}/>
                                            <span>{answer['value1']}</span>
                                        </li>
                                    );
                                })}
                            </ul>
                            <p className={cx.trainingTestQuestionBodyLeftBottomClear}>Очистить мой ответ</p>
                        </div>}
                        {question['type'] === 1 &&
                        <div className={cx.trainingTestQuestionBodyLeftBottomOne}>
                            <p className={cx.trainingTestQuestionBodyLeftBottomDescription}>
                                Выберите один правильный ответ:
                            </p>
                            <ul>
                                {question['answers'].map((answer, i) => {
                                    return (
                                        <li key={i}>
                                            <Checkbox checked={answer['isChecked']} onChange={() => onAnswer(answer)}/>
                                            <span>{answer['value1']}</span>
                                        </li>
                                    );
                                })}
                            </ul>
                            <p className={cx.trainingTestQuestionBodyLeftBottomClear}>Очистить мой ответ</p>
                        </div>}
                        {question['type'] === 2 &&
                        <div className={cx.trainingTestQuestionBodyLeftBottomOpen}>
                            <p className={cx.trainingTestQuestionBodyLeftBottomDescription}>
                                Введите правильный ответ:
                            </p>
                            <textarea onChange={onAnswer}>{question['answer']}</textarea>
                        </div>}
                        {question['type'] === 3 &&
                        <div className={cx.trainingTestQuestionBodyLeftBottomNext}>
                            <p className={cx.trainingTestQuestionBodyLeftBottomDescription}>
                                Впишите правильный ответ:
                            </p>
                            <div>
                                <span>{question['title']}</span>
                                <Input placeholder="Ваш ответ..." value={question['answer']} onChange={onAnswer}/>
                            </div>
                        </div>}
                        {question['type'] === 4 &&
                        <div className={cx.trainingTestQuestionBodyLeftBottomMatch}>
                            <p className={cx.trainingTestQuestionBodyLeftBottomDescription}>Сопоставьте варианты:</p>
                            <ul>
                                {question['answers'].map((answer, i) => {
                                    return (
                                        <li key={i}>
                                            <span>{answer['value1']}</span>
                                            <Select value={answer['answer']}
                                                    onChange={(option) => onAnswer(answer, option)}
                                                    options={question['answers'].map((option, i) => {
                                                        return { label: option['value2'], value: i };
                                                    })}/>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>}
                        {question['type'] === 5 &&
                        <div className={cx.trainingTestQuestionBodyLeftBottomSort}>
                            <p className={cx.trainingTestQuestionBodyLeftBottomDescription}>Расположите ответы в правильном порядке:</p>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {question['answers'].map((answer, i) => {
                                                return (
                                                    <Draggable key={i} draggableId={'draggable-' + i} index={i}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                                 style={{ marginBottom: '24px', ...provided.draggableProps.style }}>
                                                                <div className={cx.trainingTestQuestionBodyLeftBottomSortItem}>
                                                                    <IconButton icon={<Icon type={Icons.DRAG} width={12} height={12}/>}/>
                                                                    <span>{answer['value1']}</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>}
                    </div>
                </div>
                <div className={cx.trainingTestQuestionBodyRight}>
                    <ul>
                        {storage['questions'].map((question, i) => {
                            return (
                                <li key={i} className={+questionId === i + 1
                                    ? cx.trainingTestQuestionBodyActive
                                    : question['answer'] !== null
                                        ? cx.trainingTestQuestionBodyRightCompleted
                                        : null}
                                    onClick={() => navigate('/training/test/' + testId + '/process/' + (i + 1))}>
                                    {question['answer'] === null &&
                                    <Icon type={Icons.CHECK_VOID}/>}
                                    {question['answer'] !== null &&
                                    <Icon type={Icons.CHECK_FILLED}/>}
                                    <span>Вопрос {i + 1}</span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
            <div className={cx.trainingTestQuestionFooter}>
                <div className={cx.trainingTestQuestionFooterLeft}>
                    <div className={cx.trainingTestQuestionFooterLeftContainer}>
                        <Button disabled={!canPrevious()} onClick={onPrevious}>Предыдущий вопрос</Button>
                        <Button disabled={!canNext()} onClick={onNext}>Следующий вопрос</Button>
                    </div>
                    <Button color="green" disabled={!canEnd()} onClick={onEnd}>Завершить тест</Button>
                </div>
                <div className={cx.trainingTestQuestionFooterRight}>
                    <div className={cx.trainingTestQuestionFooterRightContainer}>
                        <span>Пройдено</span>
                        <span>{Math.round((+questionId - 1) * 100 / test['questions'].length)} %</span>
                    </div>
                    <div className={cx.trainingTestQuestionFooterRightLoader}>
                        <div style={{ width: Math.round((+questionId - 1) * 100 / test['questions'].length) + '%' }}/>
                    </div>
                </div>
            </div>
        </div>
    );
};
