import React from 'react';
import { useNavigate } from 'shared/router';
import { useAuthorized } from 'lib/hooks';
import Loading from 'uikit/loading';

const PrivatePage = ({ children, hasAnyAuthorities }) => {
    const { isAuthorizedAction, sessionHasBeenFetched } = useAuthorized();
    const navigate = useNavigate();

    const _isAuthorized = isAuthorizedAction(hasAnyAuthorities);

    if (!_isAuthorized && sessionHasBeenFetched) {
        navigate('/401');
    }

    return (
        <>
            {_isAuthorized ? <>{children}</> : <Loading/>}
        </>
    );
};

export default PrivatePage;
