import React from 'react';
import classNames from 'classnames';
import { idFromString } from 'lib/helpers';
import cx from './checkbox.module.scss';

const Checkbox = ({
    id = null,
    className = null,
    label = '',
    onChange = () => {},
    disabled = false,
    indeterminate = false,
    forwardedRef,
    ...attrs
}) => {
    const key = id || idFromString(label);

    return (
        <label className={classNames(className, cx.container, { [cx.disabled]: disabled, [cx.indeterminate]: indeterminate })}
               onClick={e => { e.stopPropagation(); }}>
            {label}
            <input id={key} type="checkbox" onChange={onChange} ref={forwardedRef} disabled={disabled} {...attrs}/>
            <div className={cx.checkmark}/>
        </label>
    );
};

export default React.forwardRef((props, ref) => <Checkbox {...props} forwardedRef={ref}/>);
