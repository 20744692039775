import React, { useCallback, useMemo, useState } from 'react';
import { useGlobalContext } from 'lib/hooks';
import { TableComponent } from 'components/data-components';
import { TrainingAccessModal } from 'components/training';
import { Icons } from 'uikit/icon';
import { EditRowToolbar } from 'uikit/table';
import api from 'api/index';
import cx from './training-test-users.module.scss';
import { EmptyTrainingTestAccess } from '../../../projects/empty';

export default function TrainingTestUsers({ id }) {
    // const selector = useSelector((state) => state);
    // const [users, setUsers] = useState([]);

    const { platform } = useGlobalContext();

    const [isAccessModal, setIsAccessModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [isEmpty, setIsEmpty] = useState(false);
    const [isSearch, setIsSearch] = useState(false);

    const [tableTotal, setTableTotal] = useState(0);
    const [tableData, setTableData] = useState([]);

    const tableColumns = useMemo(() => [
        {
            Header: 'Участник',
            accessor: 'user',
            Cell: (data) =>
                <div className={cx.trainingTestViewUsersUser}>
                    <img src={data.row.original['user']['image']
                        ? api.upload.getImage(data.row.original['user']['image'], false, 128)
                        : '/img/avatar.png'}
                         alt={data.row.original['user']['firstName'] + ' ' + data.row.original['user']['lastName']}/>
                    <span>{data.row.original['user']['firstName'] + ' ' + data.row.original['user']['lastName']}</span>
                </div>
        },
        {
            Header: 'Дата открытия',
            accessor: 'openDate',
            Cell: (data) =>
                <div>
                    {data.isMobile && <div className={cx.subheader}>Дата открытия</div>}
                    {new Date(data.row.original['openDate']).toLocaleString()}
                </div>
        },
        {
            Header: 'Дата закрытия',
            accessor: 'closeDate',
            Cell: (data) =>
                <div>
                    {data.isMobile && <div className={cx.subheader}>Дата закрытия</div>}
                    {new Date(data.row.original['closeDate']).toLocaleString()}
                </div>
        },
        {
            Header: 'Осталось попыток',
            accessor: 'tryCount',
            Cell: (data) =>
                <div>
                    {data.isMobile && <div className={cx.subheader}>Осталось попыток</div>}
                    <div style={{ color: data.row.original['tryCount'] === 0 ? '#FF6973' : '#262626' }}>
                        {data.row.original['tryCount']}
                    </div>
                </div>
        },
        {
            Header: 'Статус',
            accessor: 'status',
            Cell: (data) =>
                <div>
                    {data.isMobile && <div className={cx.subheader}>Статус</div>}
                    {data.row.original['isPassed'] ? 'Пройден' : 'Не пройден'}
                </div>
        },
        {
            id: 4,
            settings: platform === 'mobile' ? ['no_td_wrap'] : [],
            Cell: function(data) {
                let toolbar = [];

                // toolbar.push({
                //     icon: Icons.EDIT_PEN,
                //     tooltip: 'Изменить',
                //     onClick: () => {}
                // });
                // toolbar.push({
                //     icon: Icons.TRASH,
                //     tooltip: 'Удалить',
                //     onClick: () => {}
                // });

                return EditRowToolbar(toolbar)(data);
            }
        }
    ], [platform]);
    const tableActions = useMemo(() => {
        const actions = [];

        actions.push({
            icon: Icons.TRASH,
            label: 'Удалить',
            onClick: () => {}
        });

        return actions;
    }, []);

    /*const tableFilters = useMemo(() => [
        {
            'label': 'Участник',
            'fields': [
                {
                    'name': 'authorLogin.in',
                    'type': 'select',
                    'default': null,
                    'isMulti': true,
                    'options': users.map((user) => {
                        return {
                            'label': user.firstName + ' ' + user.lastName,
                            'value': user.login
                        };
                    })
                }
            ]
        },
        {
            'label': 'Дата открытия',
            'fields': [
                {
                    'name': 'openTime',
                    'type': 'date-select',
                    'default': {
                        'type': {
                            label: 'Все время',
                            value: 0
                        },
                        'from': null,
                        'to': null
                    }
                }
            ]
        },
        {
            'label': 'Дата закрытия',
            'fields': [
                {
                    'name': 'closeTime',
                    'type': 'date-select',
                    'default': {
                        'type': {
                            label: 'Все время',
                            value: 0
                        },
                        'from': null,
                        'to': null
                    }
                }
            ]
        }
    ], [users]);*/

    const onTablePaginate = useCallback(async (_offset, _count, _sort = { by: '', desc: false }, _search = '', _filters = {}) => {
        setIsLoading(true);

        const response = await api.training.getTestUsers(id);

        setTableData(response);
        setTableTotal(response.length);

        setIsSearch(!!_search);
        setIsEmpty(response.length === 0);

        setIsLoading(false);
    }, [id]);
    const accessesEmptyButtons = [
        {
            icon: Icons.USER_ADD,
            title: 'Открыть доступ',
            onClick: () => setIsAccessModal(true)
        }
    ];

    const onCloseAccessModal = async () => {
        await onTablePaginate(0, 20);
        setIsAccessModal(false);
    };

    /*useEffect(() => {
        const fetchData = async () => {
            const users = await selectUsers(selector);
            setUsers(users.content);
        };
        fetchData();
    }, [selector]);*/

    return (
        <div className={cx.trainingTestViewUsers}>
            {isAccessModal && (
                <TrainingAccessModal
                    id={id}
                    accesses={tableData}
                    onClose={() => onCloseAccessModal()}
                />
            )}
            {isEmpty && !isSearch && (
                <EmptyTrainingTestAccess buttons={accessesEmptyButtons} />
            )}
            {(!isEmpty || isSearch) && (
                <TableComponent
                    isLoading={isLoading}
                    searchTitle="Поиск..."
                    addTitle="Открыть доступ"
                    columns={tableColumns}
                    actions={tableActions}
                    total={tableTotal}
                    data={tableData}
                    onPaginate={onTablePaginate}
                    onAdd={() => setIsAccessModal(true)}
                    isMobile={platform === 'mobile'}
                />
            )}
        </div>
    );
}
